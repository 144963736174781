import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Button, Divider, Modal, Row, Typography } from 'antd'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { updateReservationArea } from '../../../../API/fetch'
import CustomButton from '../../../../Components/CustomButton'
import openNotification from '../../../../Components/Notifications'
import { setBranchAction, setBranchesAction } from '../../../../redux/reducers/branchReducer'

const {  Title } = Typography

function ReservationModifier({ rtl, reservation ,type}) {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { allBranches, branch } = useSelector((state) => state.branch);
  const { role } = useSelector((state) => state.auth);
  const { reservationModifier } = useSelector((state) => state.restaurant);
  const [showReservationModifier, setShowReservationModifier] = useState(false);
  const [loadingModifiers, setLoadingModifiers] = useState(false)

  const toggleModifier = async (modifierId, method) => {
    try {
      setLoadingModifiers(true);
      const data = {
        ...reservation,
      };
      if (method === "ADD") {
        let modifierIds = [];
        if (reservation?.reservationModifiersIds) {
          modifierIds = reservation?.reservationModifiersIds.map((mod) => mod);
        }
        modifierIds.push(modifierId);
        data.reservationModifiersIds = modifierIds;
      } else if (method === "REMOVE") {
        data.reservationModifiersIds = reservation?.reservationModifiersIds
          ?.filter((mod) => mod !== modifierId)
          ?.map((mod) => mod);
      }

      const res = await updateReservationArea(branch._id, data);
      dispatch(setBranchAction(res.data.branch));
      // copy all Branches and update the one that we edited;
      const allBranchesCopy = [...allBranches];
      let index = -1;
      if (role === "super") {
        index = allBranchesCopy.findIndex(
          (b) => b.branch._id === res.data.branch._id
        );
      } else {
        index = allBranchesCopy.findIndex((b) => b._id === res.data.branch._id);
      }
      if (index !== -1) {
        allBranchesCopy[index] = {
          ...allBranchesCopy[index],
          branch: res.data.branch,
        };
      }
      // update  all Branchs in the store;
      dispatch(setBranchesAction(allBranchesCopy));
      setLoadingModifiers(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
    } catch (error) {
      setLoadingModifiers(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  return (
    <div>
      <CustomButton
        type="primary"
        className='mx-2 px-5 border-8 mt-2'
        text={intl.formatMessage({ id: "reservationModifier" })}
        loading={loadingModifiers}
        onClick={() =>
          setShowReservationModifier(true)
        }
        disabled={type==="ADD"}
      />
      <Modal
        open={showReservationModifier}
        onOk={() => setShowReservationModifier(false)}
        onCancel={() => {
          if (!loadingModifiers) {
            setShowReservationModifier(false);
          }
        }}
        centered
        visible={showReservationModifier}
        footer={[
          // <Button
          //   onClick={() => {
          //     if (!loadingModifiers) {
          //       setShowReservationModifier(false);
          //     }
          //   }}
          // >
          //   {intl.formatMessage({ id: "cancel" })}
          // </Button>,
          <Button
            // type="primary"
            loading={loadingModifiers}
            onClick={() => setShowReservationModifier(false)}
          >
            {intl.formatMessage({ id: "save" })}
          </Button>,
        ]}

      >
        <Title className='my-font' level={4}>
          {intl.formatMessage({ id: "reservationModifier" })}
        </Title>
        <Divider />
        <Row>
          {reservationModifier?.map((modifier) => {
            let select = false;
            if (reservation && reservation?.reservationModifiersIds && reservation?.reservationModifiersIds.length > 0) {
              select = reservation?.reservationModifiersIds?.includes(modifier?._id);
            }
            return (
              <CustomButton
                key={modifier._id}
                type={select ? "primary" : "secondary"}
                className='mx-2 px-5 border-8 mt-2'
                text={
                  rtl
                    ? modifier.name
                    : modifier.name_en
                }
                icon={select ? <CheckOutlined /> : <CloseOutlined />}
                loading={loadingModifiers}
                onClick={() =>
                  toggleModifier(modifier._id, select ? "REMOVE" : "ADD")
                }
                disabled={loadingModifiers}
              />
            );
          })}
        </Row>
      </Modal>
    </div>
  )
}

export default ReservationModifier