import { Result, Spin, Typography } from "antd";
import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { verifyMyFatoorahPayment } from "../../API/fetch";
import CustomButton from "../../Components/CustomButton";
import openNotification from "../../Components/Notifications";
import {
  setServicesAction,
  setSubscriptionEndAction,
  setUserAction,
} from "../../redux/reducers/authReducer";
import COLORS from "../../Style/colors";
const { Text, Title } = Typography;
const VerifyPayment = () => {
  const intl = useIntl();
  // get the qury params from the url;
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { rtl } = useSelector((state) => state.settings);
  const navigate = useNavigate();
  let paymentId = searchParams.get("paymentId");
  const [loading, setLoading] = useState(true);
  const [errorText, setErrorText] = useState("");
  useEffect(() => {
    checkMyFatoorahPayment(paymentId);
  }, [paymentId]);

  const checkMyFatoorahPayment = async (paymentId) => {
    try {
      // services, paymentId
      let servicesFormatted = localStorage.getItem("servicesFormatted");
      servicesFormatted = JSON.parse(servicesFormatted);
     if(!servicesFormatted){
      setErrorText(intl.formatMessage({ id: "paymentError" }));
      setLoading(false);
      return;
     }
      // get services from cart;
      const data = {
        paymentId,
        services: servicesFormatted,
      };
      let res = await verifyMyFatoorahPayment(data);
      if (res && res?.data?.status === "paid") {
        localStorage.removeItem("servicesFormatted");
        dispatch(setUserAction(res.data.user));
        dispatch(setServicesAction(res.data.services));
        dispatch(setSubscriptionEndAction(false));
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
        navigate("/subscription");
      } else if (res.data.statusCode == 404) {
        localStorage.removeItem("servicesFormatted");
        setLoading(false);
        setErrorText(intl.formatMessage({ id: "paymentNotFound" }));
      } else {
        localStorage.removeItem("servicesFormatted");
        let message = "";
        if (res?.data?.response?.Error) {
          message = `${intl.formatMessage({ id: "paymentFailed" })} ${
            res?.data?.response?.Error
          }`;
        } else if (res?.data?.response?.order?.status === "CANCELLED") {
          message = `${intl.formatMessage({ id: "paymentFailed" })} ${
            res?.data?.response?.order?.cancellationReason
          }`;
        } else {
          message = intl.formatMessage({ id: "paymentNotFound" });
        }
        setErrorText(message);
        setLoading(false);
      }
    } catch (error) {
      localStorage.removeItem("servicesFormatted");
      if (error?.response?.data?.payment === "Charge not found") {
        setErrorText(intl.formatMessage({ id: "paymentNotFound" }));
      } else {
        setErrorText(intl.formatMessage({ id: "serverError" }));
      }
      setLoading(false);
    }
  };

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <Title>{intl.formatMessage({ id: "payment" })}</Title>
      </div>
      <div
        style={{
          backgroundColor: COLORS.white,
          marginTop: 10,
          width: "92%",
          minHeight: "70vh",
          marginRight: "auto",
          marginLeft: "auto",
          borderRadius: 10,
          paddingBottom: 15,
        }}
        className='light-shadow'
      >
        {loading ? (
          <div
            style={{
              minHeight: "70vh",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Spin size='large' />
            <Text>Loading ...</Text>
          </div>
        ) : (
          <div
            style={{
              minHeight: "70vh",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Result status='error' title={errorText} />
            <CustomButton
              text={intl.formatMessage({ id: "back" })}
              type='primary'
              onClick={() => navigate("/subscription")}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default VerifyPayment;
