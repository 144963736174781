import React, { useState, useEffect } from "react";
import {
  Form, Input, InputNumber, Select,
  Divider, Space, DatePicker,
  Row, Col, Typography
} from "antd";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import CustomButton from "../../../Components/CustomButton";
import { getDayData, postAddManualReservationUser } from "../../../API/fetch";
import openNotification from "../../../Components/Notifications";

import phoneCodes from "../../Auth/PhoneCods.json";
import { checkIfValidPhone } from "../../../helpers";
import moment from "moment";
import COLORS from "../../../Style/colors";
import { IoArrowBackOutline, IoArrowForwardOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import ReservationModifiers from "./reservationForm/ReservationModifiers";
import { getModifiersOptions } from "../../../helpers/modifiers";
import { checkRangeReservations, formatReservations, getTheTableIdForReservation } from "../../../helpers/reservations";
const { Option } = Select;


const { Title } = Typography

const AddUserReservationManual = () => {
  const intl = useIntl();
  const history = useNavigate()
  const { branch } = useSelector((state) => state.branch);
  const { restaurant } = useSelector((state) => state.restaurant);
  const { rtl } = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [hours, setHours] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [selectedArea, setSelectedArea] = useState(null)
  const [reservations, setReservations] = useState([]);
  const [total, setTotal] = useState(0)
  const [areaId, setAreaId] = useState("")
  const [availableTables, setAvailableTables] = useState(null) // get the available tables in case of linked table rservation
  const [selectedTable, setSelectedTable] = useState("") //selectedTable in case of  linkedtable reservation
  const [selectedOptions, setSelectedOptions] = useState({}); //selectedOptions for modifier
  const [totalModifiers, setTotalModifiers] = useState(0); // the total of modifiers
  const [totalPersons, setTotalPersons] = useState(1)  //Total user (persons + child)
  const [hour, setHour] = useState("") // selected hour


  useEffect(() => {
    let area
    if (selectedArea && areaId && areaId !== selectedArea._id) {
      area = branch?.reservationSchema?.filter((area) => area?._id === areaId)[0]
    } else {
      area = branch?.reservationSchema?.filter((area) =>
        area?.status === "Active" &&
        moment().diff(area?.reservationEndDate, 'days') <= 0)[0]
    }
    setSelectedArea(area)
    setAreaId(area?._id)
    //check if start date of reservation is after today
    let selectedDate
    if (moment(area?.reservationStartDate).isAfter(moment())) {
      selectedDate = moment(area?.reservationStartDate)
    } else {
      selectedDate = moment()
    }
    setSelectedDate(selectedDate)
    setTotalModifiers(0)
    setSelectedOptions({})
    setHour("")
    setSelectedTable("")

    if (area?.isLinkTables) {
      let filterDeleted = area?.linkedTables?.filter(a =>
        a?.status !== 'deleted')
      setAvailableTables(filterDeleted)
    }
    if (area?.isReservationFree) {
      form.setFieldsValue({
        childCount: 0,
        personsCount: area?.minUsers,
        reservationTypeId: area?._id,
        numberOfHours: 1
      });
      setTotal(0)
      setTotalPersons(area?.minUsers)
    } else if (area?.isPriceForReservation) {
      form.setFieldsValue({
        childCount: 0,
        personsCount: area?.minUsers,
        reservationTypeId: area?._id,
        numberOfHours: 1
      });
      setTotalPersons(area?.minUsers)
      setTotal(area?.reservationPrice)
    } else if (area?.isHourSelection) {
      form.setFieldsValue({
        childCount: 0,
        personsCount: area?.minUsers,
        reservationTypeId: area?._id,
        numberOfHours: 1
      });
      setTotalPersons(area?.minUsers)
      setTotal(area?.hourPrice)
    } else if (!area?.isPriceForReservation) {
      form.setFieldsValue({
        childCount: 0,
        personsCount: area?.minUsers,
        reservationTypeId: area?._id,
        numberOfHours: 1
      });
      setTotalPersons(area?.minUsers)
      setTotal(area?.minUsers * area?.pricePerUser)
    }
  }, [branch, areaId])

  useEffect(() => {
    if (selectedDate) {
      getDayDetails();
    }
  }, [selectedDate]);

  useEffect(() => {
    let allHours = [];
    if (
      selectedArea &&
      selectedDate &&
      selectedArea?.status === "Active"
    ) {
      let isCountPerReservation =
        selectedArea?.isCountPerReservation;
      let islinkedToTables = selectedArea?.isLinkTables
      let day = selectedDate.format("YYYY-MM-DD");

      const { startTime, endTime, openSeatsEvery } = selectedArea;
      // set start new date with the day and start time hours;
      let start = moment(day, "YYYY-MM-DD").set({
        hour: startTime.split(":")[0],
        minute: startTime.split(":")[1],
      });
      let end = moment(day, "YYYY-MM-DD").set({
        hour: endTime.split(":")[0],
        minute: endTime.split(":")[1],
      });

      // // check if end less the start;
      if (end.isBefore(start)) {
        end = moment(end).add(1, "day");
      }
      // start loop form start to end and add empty object to hours every openSeatsEvery;
      let price = 0;
      if (selectedArea.isPriceForReservation) {
        price = selectedArea.reservationPrice;
      } else if (
        selectedArea.pricePerUser &&
        selectedArea.pricePerUser > 0
      ) {
        price = selectedArea.pricePerUser;
      }

      if (selectedArea?.onlyOneReservationADay) {
        let reservationCount = 0;
        if (reservations && reservations.length) {
          reservations.forEach(
            (one) => (reservationCount += one.takenReservationCount)
          );
        }
        let bookingDate = moment(day, "YYYY-MM-DD")
          .startOf("day")
          .add(12, "hours")
          .toDate();
        allHours.push({
          price: price,
          pricePerChild: selectedArea?.pricePerChild
            ? selectedArea?.pricePerChild
            : 0,
          time: moment(bookingDate).format("DD-MM-YYYY HH:mm"),
          timeAsDate: bookingDate,
          bookedCount: 0,
          reservationCount,
          isNextDay: false,
          available: true,
          isLinkedToTables: islinkedToTables,
          maxUsers: selectedArea.maxUsers,
          maxReservations: selectedArea.maxReservations,
          isPriceForReservation: selectedArea.isPriceForReservation,
          onlyOneReservationADay: true,
        });
        // }
      } else {
        while (start.isBefore(end)) {
          let bookedCount = 0;
          let reservationCount = 0;
          let bookedTables = []
          if (reservations && reservations.length > 0) {
            // filter if the selected
            let select = reservations.find((one) =>
              checkRangeReservations(one?.date, one?.end, start, selectedArea?.openSeatsEvery) && one?.reservationTypeId === selectedArea?._id
            );
            if (isCountPerReservation) {
              if (select && select?.takenReservationCount > 0) {
                reservationCount += select.takenReservationCount;
              }
            } else {
              if (select && select?.takenCount > 0) {
                bookedCount += select.takenCount;
              }
            }
            if (islinkedToTables) {
              bookedTables = select?.tableIds
            }
          }
          // add to hours array only if time is bigger than now;
          if (
            (start.isAfter(moment()) &&
              start.isAfter(
                moment().add(
                  selectedArea.blockReservations,
                  "minutes"
                )
              ))
          ) {
            let isNextDay = false;
            let resDay = start.format("YYYY-MM-DD").split("-")[2];
            let selDay = day.split("-")[2];
            if (parseInt(resDay) != parseInt(selDay)) {
              isNextDay = true;
            }
            allHours.push({
              price: price,
              pricePerChild: selectedArea?.pricePerChild
                ? selectedArea?.pricePerChild
                : 0,
              time: start.format("DD-MM-YYYY HH:mm"),
              timeAsDate: start.toDate(),
              bookedCount,
              reservationCount,
              isNextDay,
              available: true,
              maxUsers: selectedArea.maxUsers,
              isLinkedToTables: islinkedToTables,
              bookedTables: bookedTables,
              maxReservations: selectedArea.maxReservations,
              isPriceForReservation:
                selectedArea.isPriceForReservation,
            });
          }

          start = moment(start).add(openSeatsEvery, "minutes");
        }
      }
    }
    setHours(allHours);
    setLoading(false);
  }, [selectedArea, selectedDate, reservations]);

  
  useEffect(() => {
    if (selectedArea?.isLinkTables && hour !== "") {
      let selectedHour = hours.filter(element => element.time === hour)[0]
      setSelectedTable(getTheTableIdForReservation(availableTables, selectedHour?.bookedTables, totalPersons))
    }
  }, [selectedArea, hour])


  const getDayDetails = async () => {
    const data = {
      branchId: branch._id,
      date: selectedDate.format("YYYY-MM-DD"),
    };
    try {
      const res = await getDayData(
        data.branchId,
        data.date
        // abortController.signal
      );
      if (res?.data) {
        setReservations(formatReservations(res.data.reservations, selectedArea?.openSeatsEvery));
      }
    } catch (err) {
      console.log(err);
    }
  };


  const addUser = async (values) => {
    try {
      setLoading(true);
      const phone = `${values.prefix.substring(1)}${values.phone}`;
      const reservationDate = moment(
        values.reservationDate,
        "DD-MM-YYYY hh:mm"
      ).toDate();
      const finalTotal = total + totalModifiers
      const reason = selectedArea?.reasons?.filter(reason => reason?._id === values?.reason)?.
        map(el => { return { name: el.name, name_en: el.name_en } })[0]

      const data = {
        reservationUser: {
          ...values,
          phone,
          reservationDate,
          total: finalTotal,
          reason: reason ? reason : [],
          totalModifiers: totalModifiers,
          modifiers: getModifiersOptions(selectedOptions),
          isWithHours: selectedArea?.isHourSelection,
        },
      };
      if (selectedArea?.isLinkTables) {
        data.reservationUser.bookedTableId = selectedTable
      }
      await postAddManualReservationUser(data, branch._id);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setLoading(false);
      history("/reservation")
    } catch (error) {
      console.log(error)
      if (error?.response?.data?.error === "User already exist") {
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "userAlreadyExist" }),
          type: "error",
          rtl,
        });
      } else {
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "errorfetch" }),
          type: "error",
          rtl,
        });
      }
      setLoading(false);
    }
  };

  const prefixSelector = (
    <Form.Item name='prefix' noStyle>
      <Select
        style={{
          width: 150,
        }}
      >
        {phoneCodes.map((info) => {
          return (
            <Option
              key={`${info.dial_code}-${info.name}`}
              value={info.dial_code}
            >
              {info.dial_code} {info.name}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          marginTop: 10,
          width: "92%",
          marginRight: "auto",
          marginLeft: "auto",
        }}
      >
        <div
          style={
            rtl
              ? {
                position: "absolute",
                zIndex: 2,
              }
              : {
                position: "absolute",
                zIndex: 2,
              }
          }
        >
          <Title level={3} className='pointer' onClick={() => history(-1)}>
            {rtl ? (
              <IoArrowForwardOutline className='pointer' />
            ) : (
              <IoArrowBackOutline className='pointer' />
            )}
          </Title>
        </div>
        <Row
          style={{
            position: "relative",
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <Col span={24}>
            <Title level={2} style={{ textAlign: "center" }}>
              {intl.formatMessage({ id: "addReservation" })}
            </Title>
          </Col>
        </Row>
        <Form
          name='editArea'
          form={form}
          preserve={false}
          className='form-container'
          layout='vertical'
          onFinish={addUser}
          initialValues={
            {
              name: "",
              phone: "",
              prefix: phoneCodes[0].dial_code,
              note: "",
              numberOfHours: 1
            }
          }
        >
          {() => {
            return (
              <>
                <div style={{ backgroundColor: COLORS.gray }}>
                  <div
                    style={{
                      backgroundColor: COLORS.white,
                      padding: "10px 20px 20px",
                      borderRadius: 7,
                      padding: 25
                    }}
                    className='light-shadow'
                  >
                    <Col md={12} sm={24} xs={24}>
                      <Title className='my-font' level={4}>
                        {intl.formatMessage({ id: "basicInfo" })}
                      </Title>
                    </Col>
                    {/* Name and phone number */}
                    <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                      <Col md={12} sm={24} xs={24}>
                        <Form.Item
                          label={intl.formatMessage({ id: "justName" })}
                          className='mb-3 mt-3'
                          name='name'
                          rules={[
                            {
                              required: true,
                              message: intl.formatMessage({ id: "errorname" }),
                            },
                          ]}
                        >
                          <Input className={"input"} />
                        </Form.Item>
                      </Col>
                      <Col md={12} sm={24} xs={24}>
                        <Form.Item
                          name='phone'
                          className='mb-3 mt-3'
                          label={intl.formatMessage({ id: "phone" })}
                          rules={[
                            {
                              required: true,
                              message: intl.formatMessage({ id: "errorphone" }),
                            },
                            {
                              validator: (_, value) => {
                                const prefix = form.getFieldValue("prefix");
                                if (!prefix) {
                                  return Promise.reject(
                                    intl.formatMessage({ id: "errorphone" })
                                  );
                                }
                                let valid = checkIfValidPhone(`${prefix}${value}`);
                                if (valid) {
                                  return Promise.resolve();
                                } else {
                                  return Promise.reject(
                                    intl.formatMessage({ id: "errorphone" })
                                  );
                                }
                              },
                            },
                          ]}
                        >
                          <Input
                            addonBefore={rtl ? null : prefixSelector}
                            addonAfter={rtl ? prefixSelector : null}
                            style={{
                              width: "100%",
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    {/* Area  */}
                    <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                      <Col md={12} sm={24} xs={24}>
                        <Form.Item
                          label={intl.formatMessage({ id: "reservationType" })}
                          className='mb-3'
                          name='reservationTypeId'
                        >
                          <Select className='my-font' onChange={(e) => {
                            setAreaId(e); form.setFieldsValue({
                              reservationDate: ""
                            });
                          }}>
                            {branch?.reservationSchema?.filter((area) => area?.status === "Active" && moment().diff(area.reservationEndDate, 'days') <= 0)?.map((one) => {
                              return (
                                <Option value={one._id} key={one._id} className='my-font'>
                                  {one?.name_en
                                    ? rtl
                                      ? one?.name
                                      : one?.name_en
                                    : one?.name}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    {/* Person Count and Child Count */}
                    <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                      <Col md={12} sm={24} xs={24}>
                        <Form.Item
                          label={intl.formatMessage({ id: "persons" })}
                          className='mb-3'
                          name='personsCount'
                        >
                          <InputNumber
                            style={{
                              width: "100%",
                            }}
                            className={"input"}
                            min={1}
                            onChange={(e) => {
                              form.setFieldsValue({
                                reservationDate: ""
                              });
                              let totalPersons = e + form.getFieldValue("childCount")
                              setTotalPersons(totalPersons)
                              if (!selectedArea?.isPriceForReservation && !selectedArea?.isHourSelection) {
                                let total = e * selectedArea?.pricePerUser + form.getFieldValue("childCount") * selectedArea?.pricePerChild;
                                setTotal(total)
                              }
                              if (selectedArea?.isLinkTables) {
                                setAvailableTables(selectedArea?.linkedTables?.filter((a) => a?.numberOfChairs >= e + form.getFieldValue("childCount") && a?.status !== 'deleted'))
                              }
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} sm={24} xs={24}>
                        <Form.Item
                          label={intl.formatMessage({ id: "childCount" })}
                          className='mb-3'
                          name='childCount'
                        >
                          <InputNumber
                            disabled={!selectedArea?.isPricePerChild}
                            min={0}
                            style={{
                              width: "100%",
                            }}

                            className={"input"}
                            onChange={(e) => {
                              form.setFieldsValue({
                                reservationDate: ""
                              });
                              let totalPersons = form.getFieldValue("personsCount") + e;
                              setTotalPersons(totalPersons)
                              if (!selectedArea?.isPriceForReservation && !selectedArea?.isHourSelection) {
                                let total = form.getFieldValue("personsCount") * selectedArea?.pricePerUser + e * selectedArea?.pricePerChild;
                                setTotal(total)
                              }
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    {/* Number of hours */}
                    <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                      <Col md={12} sm={24} xs={24}>
                        <Form.Item
                          label={intl.formatMessage({ id: "hoursCount" })}
                          className='mb-3'
                          name='numberOfHours'
                        >
                          <InputNumber
                            style={{
                              width: "100%",
                            }}
                            disabled={!selectedArea?.isHourSelection}
                            className={"input"}
                            min={1}
                            onChange={(e) => {
                              form.setFieldsValue({
                                reservationDate: ""
                              });
                              if (selectedArea?.isHourSelection) {
                                let total = e * selectedArea?.hourPrice;
                                setTotal(total)
                              }
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    {/* Date and time  */}
                    <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                      <Col md={12} sm={24} xs={24}>
                        <Form.Item
                          label={intl.formatMessage({ id: "date" })}
                          className='mb-3'
                        >
                          <DatePicker
                            value={selectedDate}
                            style={{ width: "90%" }}
                            onChange={(e) => {
                              setSelectedDate(e);
                              form.setFieldsValue({
                                reservationDate: ""
                              });
                            }}
                            disabledDate={(current) => {
                              // deisable the date if it is before the now;
                              return current < moment(selectedArea.reservationStartDate) || current > moment(selectedArea.reservationEndDate);
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} sm={24} xs={24}>
                        <Form.Item
                          label={intl.formatMessage({ id: "time" })}
                          className='mb-1'
                          name='reservationDate'
                          rules={[
                            {
                              required: true,
                            },
                          ]}

                        >
                          <Select className='my-font' onChange={(e) => setHour(e)}>
                            {hours?.map((hour) => {
                              let disabled = false;
                              let noAvailableChairs = false
                              let isWithHourSelection = hour?.isLinkedToTables && selectedArea?.isHourSelection
                              let availablesHours = 0
                              let linkedTable = hour?.isLinkedToTables
                              if (!linkedTable) {
                                if (selectedArea?.isCountPerReservation) {
                                  if (hour.reservationCount >= hour.maxReservations) {
                                    disabled = true;
                                  }
                                } else {
                                  if (
                                    hour.bookedCount + (form.getFieldValue("personsCount") + form.getFieldValue("childCount")) >
                                    hour.maxUsers
                                  ) {
                                    disabled = true;
                                  }
                                }
                                if (totalPersons > selectedArea?.maxChairs || totalPersons < selectedArea?.minUsers) {
                                  disabled = true
                                }
                              }
                              else {
                                if (availableTables?.length < 1 || availableTables?.filter(t => !hour?.bookedTables?.includes(t?._id))?.length < 1) {
                                  noAvailableChairs = true
                                }
                              }
                              if (isWithHourSelection) {
                                const restOfHours = hours?.filter(element => moment(element?.timeAsDate).isSameOrAfter(hour?.timeAsDate));
                                let index = 0;
                                let isFound = false;
                                while (!isFound && index < restOfHours.length) {
                                  if (availableTables?.length < 1 || availableTables?.filter(t => !restOfHours[index]?.bookedTables?.includes(t?._id))?.length < 1) {
                                    isFound = true;
                                  } else {
                                    index++;
                                  }
                                }
                                availablesHours = index
                              }

                              return (
                                <Option className='my-font' key={hour?.time}
                                  disabled={disabled || noAvailableChairs || (isWithHourSelection && form.getFieldValue("numberOfHours") > availablesHours)} >
                                  {hour?.time?.slice(10, 16)} {disabled || noAvailableChairs ? `- ${intl.formatMessage({ id: "reserved" })}`
                                    : isWithHourSelection && form.getFieldValue("numberOfHours") > availablesHours ? `- ${intl.formatMessage({ id: "unavailable" })}` : null}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    {/* Note  and reasons if it's true*/}
                    <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                      {selectedArea?.isWithReasons ?
                        <Col md={12} sm={24} xs={24}>
                          <Form.Item
                            label={intl.formatMessage({ id: "reservationReason" })}
                            className='mb-1'
                            name='reason'
                          >
                            <Select className='my-font'>
                              {selectedArea?.reasons.filter((area) => area?.status === true)?.map((one) => {
                                return (
                                  <Option value={one._id} key={one._id} className='my-font'>
                                    {one?.name_en
                                      ? rtl
                                        ? one?.name
                                        : one?.name_en
                                      : one?.name}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col> : null
                      }
                      <Col md={12} sm={24} xs={24}>
                        <Form.Item
                          label={intl.formatMessage({ id: "note" })}
                          className='mb-1'
                          name='note'
                        >
                          <Input className={"input"} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                  {selectedArea?.reservationModifiersIds && selectedArea?.reservationModifiersIds?.length > 0 ?
                    <>
                      <Divider />
                      <div
                        style={{
                          backgroundColor: COLORS.white,
                          padding: "10px 20px 20px",
                          borderRadius: 7,
                          padding: 25
                        }}
                        className='light-shadow'
                      >
                        <Row>
                          <Col md={12} sm={24} xs={24}>
                            <Title className='my-font' level={4}>
                              {intl.formatMessage({ id: "modifiers" })}
                            </Title>
                          </Col>
                        </Row>
                        <Row >
                          <Col lg={16} md={24} sm={24} xs={24}>
                            {selectedArea?.reservationModifiersIds?.map(modifier =>
                              <ReservationModifiers
                                key={modifier._id}
                                modifier={modifier}
                                selectedOptions={selectedOptions}
                                setSelectedOptions={setSelectedOptions}
                                totalModifiers={totalModifiers}
                                setTotalModifiers={setTotalModifiers}
                              />
                            )}
                          </Col>
                        </Row>

                      </div>
                    </>
                    : null}


                  <Divider />
                  <div
                    style={{
                      backgroundColor: COLORS.white,
                      padding: "10px 20px 20px",
                      borderRadius: 7,
                      padding: 25
                    }}
                    className='light-shadow'
                  >
                    <Row>
                      <Col md={12} sm={24} xs={24}>
                        <Title className='my-font' level={4}>
                          {intl.formatMessage({ id: "total" })} : {total + totalModifiers} {restaurant?.currency}
                        </Title>
                      </Col>
                    </Row>

                  </div>
                </div >
                <Divider />
                <Space direction='horizontal'>
                  <Form.Item>
                    <CustomButton
                      htmlType='submit'
                      text={intl.formatMessage({ id: "save" })}
                      className={`btnRegister btn-text border-8`}
                      loading={loading}
                      disabled={loading}
                      type='primary'
                    />
                  </Form.Item>

                  <Form.Item>
                    <CustomButton
                      type='text'
                      text={intl.formatMessage({ id: "cancel" })}
                      disabled={loading}
                      onClick={() => {
                        history("/reservation")
                      }}
                    />
                  </Form.Item>
                </Space>
              </>
            );
          }}
        </Form >
      </div>
    </div>
  );
};

export default AddUserReservationManual;
