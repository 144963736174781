export const days = [
  { date: "Fri", quantity: 0 },
  { date: "Sat", quantity: 0 },
  { date: "Sun", quantity: 0 },
  { date: "Mon", quantity: 0 },
  { date: "Tue", quantity: 0 },
  { date: "Wed", quantity: 0 },
  { date: "Thu", quantity: 0 },
];

export const hours = [
  { date: "00", quantity: 0 },
  { date: "01", quantity: 0 },
  { date: "02", quantity: 0 },
  { date: "03", quantity: 0 },
  { date: "04", quantity: 0 },
  { date: "05", quantity: 0 },
  { date: "06", quantity: 0 },
  { date: "07", quantity: 0 },
  { date: "08", quantity: 0 },
  { date: "09", quantity: 0 },
  { date: "10", quantity: 0 },
  { date: "11", quantity: 0 },
  { date: "12", quantity: 0 },
  { date: "13", quantity: 0 },
  { date: "14", quantity: 0 },
  { date: "15", quantity: 0 },
  { date: "16", quantity: 0 },
  { date: "17", quantity: 0 },
  { date: "18", quantity: 0 },
  { date: "19", quantity: 0 },
  { date: "20", quantity: 0 },
  { date: "21", quantity: 0 },
  { date: "22", quantity: 0 },
  { date: "23", quantity: 0 },
];

export const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const monthsAR = [
  "يناير",
  "فبراير",
  "مارس",
  "إبريل",
  "مايو",
  "يونيو",
  "يوليو",
  "أغسطس",
  "سبتمبر",
  "أكتوبر",
  "نوفمبر",
  "ديسمبر",
];

export const groupByMonth = (data) => {
    const result = {};
    data.forEach((item) => {
        const date = new Date(item.createdAt);
        let month = date.getMonth();
        if (!result[month]) {
        result[month] = 0;
        }
        result[month] += 1;
    });
    return result;
}
