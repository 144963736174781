import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import AllRestaurants from "../Views/AllRestaurants";
// import { DataContext } from '../context';
import EnterCode from "../Views/Auth/Components/EnterCode";

const PrivateRoute = ({ children }) => {
  const search = useLocation();

  const { isLoggedIn, userHasLevel, user, superUser } = useSelector((state) => state.auth);
  const { restaurant } = useSelector((state) => state.restaurant);

  // let canAddRestaurant = true;
  // if (
  //   search?.pathname === "/add-restaurant" &&
  //   restaurant?._id?.trim() !== ""
  // ) {
  //   canAddRestaurant = false;
  // }

  let didAddedRestaurant = false;
  if (restaurant?._id) {
    didAddedRestaurant = true;
  }

  if (isLoggedIn === true) {
    if (didAddedRestaurant === false && !superUser) {
      if (search?.pathname === "/add-restaurant") {
        return children;
      } else {
        return <Navigate to='/add-restaurant' />;
      }
    } else if (userHasLevel === true) {
      return children;
    } else {
      return <EnterCode />;
    }
  } else {
    return <Navigate to='/login' />;
  }

  // if (userHasLevel === true && canAddRestaurant) {
  //   return children;
  // } else if (isLoggedIn === true && userHasLevel === false ) {
  //   return <EnterCode />;
  // }else if(isLoggedIn === true && userHasLevel === true && canAddRestaurant === false) {
  //   return children;
  // }else {
  //   return <Navigate to='/register' />;
  // }
};

export default PrivateRoute;
