import { Typography, Skeleton, Tag, Divider, Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import { IoArrowBackOutline, IoArrowForwardOutline } from "react-icons/io5";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import openNotification from "../../Components/Notifications";
import { getReservation } from "../../API/fetch";
const { Title, Link, Text } = Typography;
export default function OneReservationView() {
  const navigate = useNavigate();
  const { id } = useParams();
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(true);

  // edit form;
  const { waiter } = useSelector((state) => state.auth);
  const { branch } = useSelector((state) => state.branch);
  const { tables } = useSelector((state) => state.table);
  const { restaurant } = useSelector((state) => state.restaurant);
  const [reservation, setReservation] = useState(null);

  useEffect(() => {
    if (id) {
      getReservationData();
    } else {
      navigate(-1);
    }
  }, [id]);

  const getReservationData = async () => {
    try {
      setLoading(true);
      const res = await getReservation(id);
      if (res?.data?.reservation) {
        setReservation(res?.data?.reservation);
        openNotification({
          title: intl.formatMessage({ id: "successfulOrder" }),
          type: "success",
          rtl,
        });
        setLoading(false);
      } else {
        setLoading(false);
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "errorfetch" }),
          type: "error",
          rtl,
        });
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  return (
    <div style={{ padding: 10 }}>
      <div style={{ marginTop: 10, marginBottom: 10 }}>
        <div
          level={3}
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <Title
            level={3}
            style={{ margin: 0, padding: 10 }}
            className='pointer'
            onClick={() => navigate(-1)}
          >
            {rtl ? (
              <IoArrowForwardOutline style={{ fontSize: 22 }} />
            ) : (
              <IoArrowBackOutline style={{ fontSize: 22 }} />
            )}
          </Title>
          <Title
            level={4}
            className='pointer'
            style={{ margin: 0, padding: 10 }}
            onClick={() => navigate(-1)}
          >
            {intl.formatMessage({ id: "forward" })}
          </Title>
        </div>
      </div>
      {loading ? (
        <>
          <Skeleton.Button active={true} size={"large"} />
          <Skeleton active={true} size={"large"} rows={5} />
        </>
      ) : (
        <div
          className='light-shadow'
          style={{
            borderRadius: 10,
            overflow: "hidden",
            backgroundColor: "white",
            padding: "10px 30px",
          }}
        >
          <Title level={5} className='my-font'>
            {reservation?.customerId?.name}
            <Tag color='green' style={{ marginLeft: 10, marginRight: 10 }}>
              {intl.formatMessage({
                id: reservation?.status
                  ? `${reservation?.status}_Reservation`
                  : "N/A",
              })}
            </Tag>
            <Tag
              color={reservation?.paymentStatus === "paid" ? "green" : "red"}
            >
              {intl.formatMessage({
                id: reservation?.paymentStatus
                  ? reservation?.paymentStatus
                  : "N/A",
              })}
            </Tag>
          </Title>
          <Link
            href={`tel:00${reservation?.customerId?.phone}`}
            copyable
            type='secondary'
          >
            {reservation?.customerId?.phone}
          </Link>
          <Divider />
          <Row
            gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
            style={{
              justifyContent: "space-between",
            }}
          >
            <Col
              md={11}
              sm={24}
              xs={24}
              style={{
                backgroundColor: "#fafafa",
                padding: "5px 10px",
                borderRadius: 5,
                border: "1px solid #e8e8e8",
                marginTop: 10,
              }}
            >
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: 600,
                }}
                className='my-font'
                type='secondary'
              >
                {intl.formatMessage({ id: "total" })} {":  "}
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: 600,
                }}
                className='my-font'
              >
                {reservation?.total?.toFixed(2)} SAR
              </Text>
            </Col>
            <Col
              md={11}
              sm={24}
              xs={24}
              style={{
                backgroundColor: "#fafafa",
                padding: "5px 10px",
                borderRadius: 5,
                border: "1px solid #e8e8e8",
                marginTop: 10,
              }}
            >
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: 600,
                }}
                className='my-font'
                type='secondary'
              >
                {intl.formatMessage({ id: "reservationNumber" })} {":  "}
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: 600,
                }}
                className='my-font'
              >
                {reservation?._id?.slice(-5).toUpperCase()}
              </Text>
            </Col>
            <Col
              md={11}
              sm={24}
              xs={24}
              style={{
                backgroundColor: "#fafafa",
                padding: "5px 10px",
                borderRadius: 5,
                border: "1px solid #e8e8e8",
                marginTop: 10,
              }}
            >
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: 600,
                }}
                className='my-font'
                type='secondary'
              >
                {intl.formatMessage({ id: "persons" })} {":  "}
              </Text>
              <Text className='my-font'>{reservation?.personsCount}</Text>
            </Col>
            <Col
              md={11}
              sm={24}
              xs={24}
              style={{
                backgroundColor: "#fafafa",
                padding: "5px 10px",
                borderRadius: 5,
                border: "1px solid #e8e8e8",
                marginTop: 10,
              }}
            >
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: 600,
                }}
                className='my-font'
                type='secondary'
              >
                {intl.formatMessage({ id: "childCount" })} {":  "}
              </Text>
              <Text className='my-font'>{reservation?.childCount}</Text>
            </Col>
            {reservation.isWithHours ? (
              <Col
                md={12}
                sm={24}
                xs={24}
                style={{
                  backgroundColor: "#fafafa",
                  padding: "5px 10px",
                  borderRadius: 5,
                  border: "1px solid #e8e8e8",
                  marginTop: 10,
                }}
              >
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: 600,
                  }}
                  className='my-font'
                  type='secondary'
                >
                  {intl.formatMessage({ id: "hoursCount" })}
                </Text>
                <Text className='my-font'>{reservation?.numberOfHours}</Text>
              </Col>
            ) : null}
          </Row>
          <Row
            gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
            style={{
              justifyContent: "space-between",
            }}
          >
            <Col
              md={11}
              sm={24}
              xs={24}
              style={{
                backgroundColor: "#fafafa",
                padding: "5px 10px",
                borderRadius: 5,
                border: "1px solid #e8e8e8",
                marginTop: 10,
              }}
            >
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: 600,
                }}
                className='my-font'
                type='secondary'
              >
                {intl.formatMessage({ id: "reservationDate" })} {":  "}
              </Text>
              <Text className='my-font'>
                {moment(reservation?.reservationDate).format(
                  "DD-MM-YYYY HH:mm A"
                )}
              </Text>
            </Col>
            <Col
              md={11}
              sm={24}
              xs={24}
              style={{
                backgroundColor: "#fafafa",
                padding: "5px 10px",
                borderRadius: 5,
                border: "1px solid #e8e8e8",
                marginTop: 10,
              }}
            >
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: 600,
                }}
                className='my-font'
                type='secondary'
              >
                {intl.formatMessage({ id: "createdAt" })} {":  "}
              </Text>
              <Text className='my-font'>
                {moment(reservation?.createdAt).format("DD-MM-YYYY HH:mm A")}
              </Text>
            </Col>
            <Col
              md={24}
              sm={24}
              xs={24}
              style={{
                backgroundColor: "#fafafa",
                padding: "5px 10px",
                borderRadius: 5,
                border: "1px solid #e8e8e8",
                marginTop: 10,
              }}
            >
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: 600,
                }}
                className='my-font'
                type='secondary'
              >
                {/* note */}
                {intl.formatMessage({ id: "notes" })} {":  "}
              </Text>
              <Text className='my-font'>{reservation?.note}</Text>
            </Col>
          </Row>
          <Row
            gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
            style={{
              justifyContent: "space-between",
            }}
          >
            <Col
              md={11}
              sm={24}
              xs={24}
              style={{
                backgroundColor: "#fafafa",
                padding: "5px 10px",
                borderRadius: 5,
                border: "1px solid #e8e8e8",
                marginTop: 10,
              }}
            >
              {/* reservationType */}
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: 600,
                }}
                className='my-font'
                type='secondary'
              >
                {intl.formatMessage({ id: "reservationType" })} {":  "}
              </Text>
              <Text className='my-font'>
                {branch?.reservationSchema?.find(
                  (a) => a?._id === reservation?.reservationTypeId
                )?.name || "N/A"}
              </Text>
            </Col>
            <Col
              md={11}
              sm={24}
              xs={24}
              style={{
                backgroundColor: "#fafafa",
                padding: "5px 10px",
                borderRadius: 5,
                border: "1px solid #e8e8e8",
                marginTop: 10,
              }}
            >
              {/* modifiers */}
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: 600,
                }}
                className='my-font'
                type='secondary'
              >
                {intl.formatMessage({ id: "modifiers" })} {":  "}
              </Text>
              <Text className='my-font'>
                {reservation?.modifiers?.map((mod, i) => (
                  <Tag
                    key={i}
                    style={{
                      paddingTop: 5,
                      paddingBottom: 5,
                      marginTop: 7,
                    }}
                  >
                    <Tag color='gray'>{mod?.qty}</Tag>
                    <Tag color='gray'>{rtl ? mod?.name : mod?.name_en}</Tag>

                    {mod?.note ? <Tag color='gray'>{mod?.note}</Tag> : null}
                  </Tag>
                ))}
              </Text>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}
