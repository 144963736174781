import React from 'react'
import { useLocation } from 'react-router';

function UseQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

export default UseQuery
