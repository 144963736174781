import React, { useState, useEffect } from "react";
import { Table, ExportTableButton } from "ant-table-extensions";
import { Typography } from "antd";
import { FileExcelOutlined, SearchOutlined } from "@ant-design/icons";
import COLORS from "../../../Style/colors";
import { useIntl } from "react-intl";
import { customerExport } from "../../../helpers";
const { Text } = Typography;
const URLSTables = ({ loading, tables, branch, slug, domain, services }) => {
  const intl = useIntl();
  const [formattedTables, setFormattedTables] = useState([]);
  useEffect(() => {
    const CLINT_URL = `https://app.iwaiter.club`;
    let format = [];
    if (tables?.length) {
      tables.forEach((table) => {
        if (domain !== "iWaiter") {
          format.push({
            key: table._id,
            url: `${domain}/${branch}/${table._id}`,
            number: table.number,
            name: table.name,
          });
        } else {
          format.push({
            key: table._id,
            url: `${CLINT_URL}/${slug}/${branch}/${table._id}`,
            name: table.name,
            name_en: table.name_en,
          });
        }
      });
    }
    // add menu page;
    let menuUrl = `${CLINT_URL}/${slug}/menu`;
    if (domain !== "iWaiter") {
      menuUrl = `${domain}/menu`;
    }
    format.push({
      key: "menu",
      url: menuUrl,
      name: "المنيو",
      name_en: "Menu",
    });
    // pickup and waiting;
    if (services?.includes("WAITING")) {
      let waitingUrl = `${CLINT_URL}/${slug}/waiting`;
      if (domain !== "iWaiter") {
        waitingUrl = `${domain}/waiting`;
      }
      format.push({
        key: "waiting",
        url: waitingUrl,
        name: "الانتظار",
        name_en: "Waiting",
      });
    }
    if (services?.includes("PICKUP")) {
      let pickupUrl = `${CLINT_URL}/${slug}/pickup`;
      if (domain !== "iWaiter") {
        pickupUrl = `${domain}/pickup`;
      }
      format.push({
        key: "pickup",
        url: pickupUrl,
        name: "طلبات الاستلام",
        name_en: "Pickup",
      });
    }

    // add home page;
    let homeUrl = `${CLINT_URL}/${slug}`;
    if (domain !== "iWaiter") {
      homeUrl = `${domain}`;
    }
    format.push({
      key: "home",
      url: homeUrl,
      name: "الرئيسية",
      name_en: "Home",
    });

    setFormattedTables(format);
    return () => {
      setFormattedTables([]);
    };
  }, [tables, branch, slug, domain, services]);

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "name",
      key: "name",
    },
    {
      title: intl.formatMessage({ id: "nameEn" }),
      dataIndex: "name_en",
      key: "name_en",
    },
    {
      title: intl.formatMessage({ id: "url" }),
      dataIndex: "url",
      key: "url",
    },
  ];
  return (
    <div>
      <ExportTableButton
        dataSource={formattedTables}
        columns={columns}
        btnProps={{
          type: "primary",
          icon: <FileExcelOutlined />,
          style: { borderRadius: 7 },
          onClick: () =>
          customerExport({
            data: formattedTables,
            columns: columns,
            fileName: "QR File",
            loadingSetter: () => true,
          }),
        }}
      >
        <Text
          className='my-font'
          style={{
            color: formattedTables?.length === 0 ? "#dddddd" : COLORS.white,
          }}
        >
          {intl.formatMessage({ id: "export" })}
        </Text>
      </ExportTableButton>
      <div style={{ display: "none" }}>
        <Table
          columns={columns}
          className='light-shadow'
          pagination={{
            hideOnSinglePage: true,
          }}
          loading={loading}
          dataSource={formattedTables}
          scroll={{ x: 500 }}
        />
      </div>
    </div>
  );
};

export default URLSTables;
