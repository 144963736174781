import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { logOutAction } from "../redux/reducers/authReducer";


const ManagerRoute = ({ children }) => {
    const dispatch = useDispatch()
    const { isLoggedIn, superUser } = useSelector((state) => state.auth);

    if (isLoggedIn === true && superUser) {
        return children;
    } else {
        if (isLoggedIn) {
            return <Navigate to='/' />;
        }
        return <Navigate to='/login' />;
    }
};

export default ManagerRoute;
