import React, { useState, useEffect } from "react";
import "chart.js/auto";
import { Chart,  } from "react-chartjs-2";
import {  useSelector } from "react-redux";
import COLORS from "../../../Style/colors";
import { Typography, Space, Spin } from "antd";
import { useIntl } from "react-intl";
import MyTimePicker from "./MyTimePicker";
import { getDataAnalyticsByDates } from "../../../API/fetch";
import CustomButton from "../../../Components/CustomButton";
const { Title, Text } = Typography;

const TableAnalytics = ({ showAllTables, setShowAllTables }) => {
  const intl = useIntl();
  const { analyticsData } = useSelector((state) => state.analytics);
  const { restaurant } = useSelector((state) => state.restaurant);
  const { rtl } = useSelector((state) => state.settings);
  const { allBranches } = useSelector((state) => state.branch);
  const [labels, setLabels] = useState([]);
  const [data, setData] = useState([]);
  const [smallLabels, setSmallLabels] = useState([])
  const [smallData, setSmallData] = useState([])
  const [totalOrders, setTotalOrders] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (analyticsData?.ordersOnTableByDate?.length > 0) {
      let labels = [];
      let data = [];
      const allTables = allBranches?.flatMap((branch) =>
        branch?.tables?.map((t) => t)
      );
      analyticsData?.ordersOnTableByDate.forEach((one) => {
        const table = allTables?.find((t) => t?._id === one.tableId);
        labels.push(rtl ? table?.name : table?.name_en);
        data.push(one.count);
      });
      const copy = {
        labels,
        data,
      }
      setSmallLabels(copy.labels?.splice(0,3));
      setSmallData(copy.data?.splice(0,3));
      setLabels(labels);
      setData(data);
      // get sum of count inside analytics data array;
      const sum = analyticsData?.ordersOnTableByDate?.reduce(
        (acc, cur) => acc + cur.count,
        0
      );
      setTotalOrders(sum);
    }
  }, [analyticsData, rtl]);

  const getDataForTime = async ({ start, end }) => {
    try {
      setLoading(true);
      const data = {
        start,
        end,
        id: restaurant?._id,
        type: "ordersOnTableByDate",
      };
      const res = await getDataAnalyticsByDates(data);
      if (res.data?.result?.ordersOnTableByDate?.length > 0) {
        let labels = [];
        let data = [];
        const allTables = allBranches?.flatMap((branch) =>
          branch?.tables?.map((t) => t)
        );
        res.data?.result?.ordersOnTableByDate.forEach((one) => {
          const table = allTables?.find((t) => t?._id === one.tableId);
          labels.push(rtl ? table?.name : table?.name_en);
          data.push(one.count);
        });
        const copy = {
          labels,
          data,
        }
       
        setSmallLabels(copy.labels?.splice(0,3));
        setSmallData(copy.data?.splice(0,3));
        setLabels(labels);
        setData(data);
        // get sum of count inside analytics data array;
        const sum = res.data?.result?.ordersOnTableByDate?.reduce(
          (acc, cur) => acc + cur.count,
          0
        );
        setTotalOrders(sum);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div>
      <Spin spinning={loading}>
        <Space
          direction='horizontal'
          style={{ width: "100%", justifyContent: "space-between" }}
        >
          <div>
            <Title style={{ marginBottom: 0, paddingBottom: 0 }} level={4}>
              {intl.formatMessage({ id: "tablesAnalysis" })}
            </Title>
            <Space
              direction='horizontal'
              style={{ width: "100%", marginTop: 0 }}
            >
              <Text className='my-font' type='secondary'>
                {totalOrders} - {intl.formatMessage({ id: "order" })}
              </Text>
              <CustomButton
                text={intl.formatMessage({
                  id: showAllTables ? "hide" : "more",
                })}
                onClick={() => setShowAllTables(!showAllTables)}
                type={showAllTables ? "" : "primary"}
                className={`mt-1 border-8 px-3 }`}
              />
            </Space>
          </div>
          <MyTimePicker
            initialTime='last30days'
            loading={loading}
            getData={getDataForTime}
          />
        </Space>
        <Chart
          type='bar'
          data={{
            labels: showAllTables ? labels : smallLabels,
            datasets: [
              {
                label: "",
                data: showAllTables ? data : smallData,
                backgroundColor: COLORS.primary,
                hoverBackgroundColor: COLORS.primary,
              },
            ],
          }}
          options={{
            responsive: true,
            // maintainAspectRatio: true,
            plugins: {
              subtitle: {
                display: false,
              },
              legend: {
                display: false,
              },
            },
          }}
        />
      </Spin>
    </div>
  );
};

export default TableAnalytics;
