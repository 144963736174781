import React, { useState, useEffect } from "react";
import { Spin, Typography } from "antd";
import { useIntl } from "react-intl";
import COLORS from "../../Style/colors";
import {
  getRestaurantVatInvoices,
  getRestaurantVatInvoice,
} from "../../API/fetch";
import { useSelector } from "react-redux";
import { DEFAULT_WALLET_NAME } from "../../Types";
import moment from "moment";
import { Table } from "ant-table-extensions";
import CustomButton from "../../Components/CustomButton";
import openNotification from "../../Components/Notifications";

const { Title } = Typography;
const PaymentVat = () => {
  const intl = useIntl();
  const { restaurant } = useSelector((state) => state.restaurant);
  const [initialLoading, setInitialLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    if (restaurant?.wallet?.type === DEFAULT_WALLET_NAME.IWAITER_WALLET) {
      getPaymentBalance();
    }
  }, []);

  const getPaymentBalance = async () => {
    try {
      const res = await getRestaurantVatInvoices();
      let formatted = formatTransaction(res.data.invoices);
      setTransactions(formatted);
      setInitialLoading(false);
    } catch (error) {
      console.log(error);
      setInitialLoading(false);
    }
  };
  const createInvoice = async (record) => {
    if (record?._id !== "new") {
      return;
    }
    try {
      setInitialLoading(true);
      let month = record?.month.split(" - ")[0];
      const res = await getRestaurantVatInvoice(month, record?.year);
      let formatted = formatTransaction(res.data.invoices);
      setTransactions(formatted);
      setInitialLoading(false);
      openNotification({
        type: "success",
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        rtl: true,
      });
      // get last invoice;
      // and download it;
      let url = res.data.invoices?.[0]?.invoiceUrl;
      if (url) {
        window.open(url, "_blank");
      }
    } catch (error) {
      console.log(error);
      setInitialLoading(false);
      openNotification({
        type: "error",
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        rtl: true,
      });
    }
  };

  const formatTransaction = (transactions) => {
    let formatted = [];
    if (transactions?.length) {
      transactions.forEach((transaction, i) => {
        formatted.push({
          key: transaction?._id,
          number: i + 1,
          _id: transaction?._id,
          amount: transaction?.totalWithoutVAT?.toFixed(2),
          year: transaction?.year,
          status: transaction?.status,
          invoiceUrl: transaction?.invoiceUrl,
          vat: transaction?.vatAmount?.toFixed(2),
          month: `${transaction?.month} - ${transaction?.year}`,
          createdAt: moment(transaction?.createdAt).format("DD/MM/YYYY"),
          transactionObject: { ...transaction },
        });
      });
    }
    let previousMonth = moment().get("month") - 1;
    let perviousYear = moment().get("year");
    if (previousMonth < 0) {
      previousMonth = 11;
      perviousYear = perviousYear - 1;
    }
    // get month name
    const monthName = moment().month(previousMonth).format("MMMM");
    // check if the formatted includes the pervious month;
    let found = false;
    formatted.forEach((element) => {
      if (element?.month === `${monthName} - ${perviousYear}`) {
        found = true;
      }
    });
    if (!found) {
      formatted.unshift({
        key: "new",
        month: `${monthName} - ${perviousYear}`,
        year: `${perviousYear}`,
        amount: "--",
        vat: "--",
        _id: "new",
        createdAt: moment().format("DD/MM/YYYY"),
      });
    }
    return formatted;
  };

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
    },
    {
      title: intl.formatMessage({ id: "month" }),
      dataIndex: "month",
      key: "month",
    },
    {
      title: intl.formatMessage({ id: "amount" }),
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: intl.formatMessage({ id: "vat" }),
      dataIndex: "vat",
      key: "vat",
    },
    {
      title: intl.formatMessage({ id: "date" }),
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: intl.formatMessage({ id: "invoice" }),
      dataIndex: "invoiceUrl",
      key: "invoiceUrl",
      render: (invoiceUrl, record) => {
        if (record?._id !== "new") {
          return (
            <a
              style={{
                color: invoiceUrl ? "#1890ff" : "gray",
                textDecoration: invoiceUrl ? "underline" : "none",
                cursor: invoiceUrl ? "pointer" : "not-allowed",
              }}
              href={invoiceUrl}
              target='_blank'
            >
              {intl.formatMessage({ id: "invoice" })}
            </a>
          );
        } else {
          // create invoice
          return (
            <CustomButton
              type='primary'
              style={{ borderRadius: 5 }}
              text={intl.formatMessage({ id: "invoice" })}
              onClick={() => {
                createInvoice(record);
              }}
              disabled={record?._id !== "new" || initialLoading}
              loading={initialLoading}
            />
          );
        }
      },
    },
  ];
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <Title level={3}>{intl.formatMessage({ id: "allPayments" })}</Title>
      </div>
      <Spin spinning={initialLoading}>
        <div
          style={{
            backgroundColor: COLORS.white,
            marginTop: 40,
            width: "92%",
            marginRight: "auto",
            marginLeft: "auto",
            borderRadius: 10,
            padding: 15,
          }}
          className='light-shadow'
        >
          <Table
            pagination={{
              hideOnSinglePage: true,
            }}
            dataSource={transactions}
            scroll={{ x: 500 }}
            columns={columns}
            style={{ borderRadius: 10 }}
          />
        </div>
      </Spin>
    </div>
  );
};

export default PaymentVat;
