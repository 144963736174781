import React, { useEffect, useState } from "react";
import { Typography, Select, Spin, Result, Tag, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { getCampaigns } from "../../API/fetch";
import openNotification from "../../Components/Notifications";
import CustomButton from "../../Components/CustomButton";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { setCampaignsAction } from "../../redux/reducers/campaignReducer";
import { BiRefresh } from "react-icons/bi";


const { Text, Title } = Typography;
const { Option } = Select;
const MarketingSMS = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { rtl } = useSelector((state) => state.settings);
  const { campaigns } = useSelector((state) => state.campaign);
  const dispatch = useDispatch();
  // const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(false);
  console.log("campaigns", campaigns);
  useEffect(() => {
    if (campaigns.length === 0) {
      getAllMarketingSMS();
    }
  }, []);

  const getAllMarketingSMS = async () => {
    setLoading(true);
    try {
      const res = await getCampaigns();
      if (res?.data?.campaigns) {
        dispatch(setCampaignsAction(res?.data?.campaigns));
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      openNotification({
        type: "error",
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        rtl,
      });
    }
    setLoading(false);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: "campaignsName" }),
      dataIndex: "name",
      key: "name",
    },
    {
      title: intl.formatMessage({ id: "users" }),
      dataIndex: "numbersLength",
      key: "numbersLength",
      render: (text, record) => <Text>{record?.numbers?.length}</Text>,
    },
    {
      title: intl.formatMessage({ id: "status" }),
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        console.log("text", text);
        return(
        <Tag color={text === "pending" ? "red" : "green"}>
          {intl.formatMessage({ id: text === 'sent' ? 'done': text })}
        </Tag> 
      )},
    },
    {
      title: intl.formatMessage({ id: "date" }),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => (
        <Text>{moment(text).format("YYYY-MM-DD")}</Text>
      ),
    },
    {
      title: intl.formatMessage({ id: "edit" }),
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <CustomButton
            text={record.status === 'sent' ? intl.formatMessage({id: 'view'}) : intl.formatMessage({ id: "edit" })}
            onClick={() => {
              navigate(`/campaigns/${record._id}`, {
                state: { campaign: record },
              });
            }}
            type='primary'
            className={"border-8"}
          />
        </div>
      ),
    },
  ];
  
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Spin spinning={loading} tip={intl.formatMessage({ id: "loadingData" })}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <Title level={3}>{intl.formatMessage({ id: "marketing" })}</Title>
          <CustomButton
            text={intl.formatMessage({ id: "createCampaign" })}
            className={`btnRegister btn-text border-8  mx-5`}
            onClick={() => {
              navigate("/campaigns/new");
            }}
            type='primary'
          />
        </div>

        <div
          style={{
            backgroundColor: "white",
            padding: "10px 20px 20px",
            borderRadius: 7,
            marginBottom: 20,
          }}
          className='light-shadow'
        >
          {campaigns?.length === 0 && !loading ? (
            // no campaigns
            <Result
              status={"warning"}
              title={intl.formatMessage({ id: "noCampaigns" })}
              subTitle={intl.formatMessage({ id: "noCampaignsSubTitle" })}
              className='my-font'
              extra={
                <CustomButton
                  text={intl.formatMessage({ id: "createCampaign" })}
                  onClick={() => {
                    navigate("/campaigns/new");
                  }}
                  type='primary'
                  className={"border-8"}
                />
              }
            />
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 10,
                }}
              >
                <Title level={4}>
                  {intl.formatMessage({ id: "campaigns" })}
                </Title>
                <CustomButton
                  text={intl.formatMessage({ id: "refresh" })}
                  onClick={getAllMarketingSMS}
                  type='outline'
                  className={"border-8 "}
                  icon={
                    <BiRefresh
                      size={20}
                      style={{
                        top: 5,
                        position: "relative",
                        marginRight: 7,
                        marginLeft: 7,
                      }}
                    />
                  }
                />
              </div>
              <Table
                columns={columns}
                dataSource={campaigns}
                pagination={false}
              />
            </>
          )}
        </div>
      </Spin>
    </div>
  );
};

export default MarketingSMS;
