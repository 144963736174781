import { Modal } from "antd";
import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { setNotificationTextAction } from "../../redux/reducers/settingsReducer";
import CustomButton from "../CustomButton";
import { toggleAlert } from "./AlertSound";
import { getLastOrderCreated } from "../../helpers";

const NotificationModal = ({}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useNavigate();
  const { notificationText, rtl } = useSelector((state) => state.settings);
  const { tables } = useSelector((state) => state.table);
  const { waiter } = useSelector((state) => state.auth);
  const {dineInOrders,
    pickupOrders,
    iwaiterAppOrders,
    deliveryOrders} = useSelector((state) => state.order);
  const [textToShow, setTextToShow] = useState("");
  const [show, setShow] = useState(false);
  const [tableReceivedNewOrder, setTableReceivedNewOrder] = useState("");
  const [okText, setOkText] = useState("seeItNow");
  const [cancelText, setCancelText] = useState("seeItLater");
  useEffect(() => {
    if (notificationText) {
      let id = notificationText.split("-")[0];
      setTableReceivedNewOrder(id);
      let text = notificationText.split("-")[1];
      let table = {
        name: "",
        name_en: "",
      };

      if (id === "pickup") {
        table.name = " خارجي";
        table.name_en = "Pickup";
      } else if (id === "delivery") {
        table.name = " توصيل";
        table.name_en = "Delivery";
      } else if (text === "customerArrived") {
        setTableReceivedNewOrder("customerArrived");
        table.name = "customerArrived";
        table.name_en = "customerArrived";
      } else if (id === "iwaiterApp") {
        setTableReceivedNewOrder("iwaiterApp");
        table.name = "تطبيق اي ويتر";
        table.name_en = "iwaiter App";
      } else {
        table = tables.find((t) => t._id === id);
        setTableReceivedNewOrder(table);
        if (!table || Object.keys(table).length < 1) {
          table = {};
          table.name = "N/A";
          table.name_en = "N/A";
        }
      }
      if (text === "newOrder") {
        if (rtl) {
          setTextToShow(`${intl.formatMessage({ id: text })} ${table.name}`);
        } else {
          setTextToShow(`${intl.formatMessage({ id: text })} ${table.name_en}`);
        }
      } else if (text === "customerArrived") {
        if (rtl) {
          setTextToShow(`${intl.formatMessage({ id: text })}`);
        } else {
          setTextToShow(`${intl.formatMessage({ id: text })}`);
        }
      } else {
        if (rtl) {
          setTextToShow(`${table.name}  ${intl.formatMessage({ id: text })}`);
        } else {
          setTextToShow(
            `${table.name_en}  ${intl.formatMessage({ id: text })}`
          );
        }
      }
      let showAlert = true;
      if (
        (table?.name_en === "Pickup" || table?.name_en === "customerArrived") &&
        waiter?.receivePickup === false
      ) {
        showAlert = false;
      } else if (
        table?.name_en === "Delivery" &&
        waiter?.receiveDelivery === false
      ) {
        showAlert = false;
      }else if(table?.name_en === "iwaiterApp" && waiter?.receiveIWaiterApp === false){
        showAlert = false;
      }

      setShow(showAlert);
    } else {
      setShow(false);
      toggleAlert({ status: "stop" });
    }
  }, [notificationText]);
  return (
    <Modal
      title={textToShow}
      style={{ fontFamily: "Cairo" }}
      centered
      visible={show}
      destroyOnClose={true}
      footer={null}
      closable={false}
      maskClosable={false}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CustomButton
          text={intl.formatMessage({ id: okText })}
          type='primary'
          className='px-3 border-5 mx-3'
          onClick={() => {
            if (typeof tableReceivedNewOrder === "string") {
              if (
                tableReceivedNewOrder === "pickup" ||
                tableReceivedNewOrder?.includes("customerArrived")
              ) {
                let orderDetails = getLastOrderCreated(pickupOrders);
                if(orderDetails?._id){
                  history(`/pickup/${orderDetails._id}`);
                }else{
                history("/pickup");
                }
              } else if (tableReceivedNewOrder === "delivery") {
                let orderDetails = getLastOrderCreated(deliveryOrders);
                if(orderDetails?._id){
                  history(`/delivery/${orderDetails._id}`);
                }else{
                history("/delivery");
                }
              } else if (tableReceivedNewOrder === "iwaiterApp") {
                let orderDetails = getLastOrderCreated(iwaiterAppOrders);
                if(orderDetails?._id){
                  history(`/iwaiterApp/${orderDetails._id}`);
                }else{
                history("/iwaiterApp");
                }
              }
            } else {
              history(`/tables/${tableReceivedNewOrder?._id}`);
            }
            dispatch(setNotificationTextAction(""));
          }}
        />
        <CustomButton
          text={intl.formatMessage({ id: cancelText })}
          type='light'
          className='px-3 border-5'
          onClick={() => {
            dispatch(setNotificationTextAction(""));
            if (tableReceivedNewOrder === "iwaiterApp") {
              history("/iwaiterApp");
            }
          }}
        />
      </div>
    </Modal>
  );
};

export default NotificationModal;
