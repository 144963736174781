import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  campaigns: [],
};

export const CampaignSlice = createSlice({
  name: "campaign",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setCampaigns: (state, action) => {
      state.campaigns = action.payload;
    },
    updateCampaign: (state, action) => {
      const copyCampaigns = [...current(state.campaigns)];
      const index = copyCampaigns.findIndex(
        (b) => b._id === action.payload._id
      );
      if (index === -1) return;
      const copyCampaign = { ...copyCampaigns[index], ...action.payload };
      copyCampaigns.splice(index, 1, copyCampaign);
      state.campaigns = copyCampaigns;
    },
    addCampaign: (state, action) => {
      state.campaigns = [ action.payload, ...state.campaigns,];
    }
  },
});

export const {
  setCampaigns: setCampaignsAction,
  updateCampaign: updateCampaignAction,
  addCampaign: addCampaignAction,
} = CampaignSlice.actions;

export default CampaignSlice.reducer;
