import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Tag, Typography, Spin, Form, DatePicker, Button } from "antd";
import { useIntl } from "react-intl";
import { FileExcelOutlined, SearchOutlined } from "@ant-design/icons";
import CustomButton from "../../Components/CustomButton";
import COLORS from "../../Style/colors";
import dayjs from "dayjs";
import {
  getPendingPayments,
  verifyPrivatePayment,
  verifyPrivatePaymentReservation,
  verifyMyFatoorahPaymentForOrder,
  verifyMyFatoorahPaymentReservation,
} from "../../API/fetch";
import openNotification from "../../Components/Notifications";
import { Table } from "ant-table-extensions";
import { customerExport } from "../../helpers";
import { Link } from "react-router-dom";
import moment from "moment";

const { RangePicker } = DatePicker;

const { Text, Title } = Typography;
const PendingPayments = () => {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  const { restaurant } = useSelector((state) => state.restaurant);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [formattedPayments, setFormattedPayments] = useState([]);
  const getSettlementData = async (values) => {
    try {
      setLoading(true);
      let dateStart = dayjs(values.date[0]).format("YYYY-MM-DD");
      let dateEnd = dayjs(values.date[1]).format("YYYY-MM-DD");
      const query = `?dateStart=${dateStart}&dateEnd=${dateEnd}`;
      const res = await getPendingPayments(query);
      if (res?.data?.status === "success") {
        setFormatPayments({ payments: res.data.payments });
        setLoading(false);
        openNotification({
          title: "success",
          description: "payments loaded",
          type: "success",
          rtl,
        });
      } else {
        setLoading(false);
        openNotification({
          title: "error",
          description: "something went wrong",
          type: "error",
          rtl: true,
        });
      }
    } catch (error) {
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };

  const columns = [
    {
      title: "#",
      // dataIndex: "number",
      key: "index",
      width: 80,
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: "orderNumber" }),
      dataIndex: "orderNumber",
      key: "orderNumber",
    },
    {
      title: intl.formatMessage({ id: "orderDate" }),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (time) => moment(time).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: intl.formatMessage({ id: "total" }),
      dataIndex: "total",
      key: "total",
      render: (total) => total?.toFixed(2),
    },
    {
      title: intl.formatMessage({ id: "view" }),
      dataIndex: "link",
      key: "link",
      render: (link, record) => {
        return (
          <Link
            to={link}
            style={{
              color: COLORS.primary,
              textDecoration: "underline",
            }}
            target='_blank'
          >
            {intl.formatMessage({ id: "view" })}
          </Link>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "status" }),
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return (
          <Tag className='my-font'>
            {intl.formatMessage({ id: status ? status : "N/A" })}
          </Tag>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "type" }),
      dataIndex: "type",
      key: "type",
      render: (type) => intl.formatMessage({ id: type ? type : "N/A" }),
      filters: [
        {
          text: intl.formatMessage({ id: "inHouse" }),
          value: "inHouse",
        },
        {
          text: intl.formatMessage({ id: "pickup" }),
          value: "pickup",
        },
        {
          text: intl.formatMessage({ id: "reservation" }),
          value: "reservation",
        },
      ],
      onFilter: (value, record) => record.type === value,
    },
    // add action buttons;
    {
      title: intl.formatMessage({ id: "check" }),
      dataIndex: "actions",
      key: "actions",
      render: (actions, record) => {
        return (
          <div>
            <Button
              type='primary'
              disabled={
                !record.chargeId ||
                record.chargeId === "undefined" ||
                loading ||
                !record.type
              }
              onClick={() => {
                checkPayment(
                  record.chargeId,
                  record.orderId,
                  record.reservationId,
                  record.paymentCompanyName,
                  record.type
                );
              }}
            >
              {intl.formatMessage({ id: "checkPayment" })}
            </Button>
          </div>
        );
      },
    },
  ];

  const checkPayment = async (
    chargeId,
    orderId,
    reservationId,
    paymentCompanyName,
    type
  ) => {
    try {
      let restaurantId = restaurant._id;
      setLoading(true);
      let res;
      if (!type || type === "") {
        setLoading(false);
        return openNotification({
          title: intl.formatMessage({ id: "orderInvalid" }),
          description: intl.formatMessage({ id: "orderInvalid" }),
          type: "error",
          rtl: true,
        });
      }
      if (type === "reservation") {
        // check paymentCompanyName;
        if (paymentCompanyName === "MY_FATOORAH") {
          res = await verifyMyFatoorahPaymentReservation(
            chargeId,
            restaurantId,
            reservationId
          );
        } else {
          res = await verifyPrivatePaymentReservation(
            chargeId,
            restaurantId,
            reservationId
          );
        }
      } else {
        if (paymentCompanyName === "MY_FATOORAH") {
          res = await verifyMyFatoorahPaymentForOrder(chargeId, restaurantId);
        } else {
          res = await verifyPrivatePayment(chargeId, restaurantId);
        }
      }
      if (res.data.status === "notPaid") {
        openNotification({
          title: intl.formatMessage({ id: "notPaidInvoice" }),
          description: res?.data?.response?.message,
          type: "error",
          rtl: true,
        });
      } else {
        openNotification({
          title: intl.formatMessage({ id: "paidInvoice" }),
          description: res?.data?.response?.message,
          type: "success",
          rtl: true,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };

  const setFormatPayments = ({ payments }) => {
    const formatted = payments.map((payment) => {
      let link = "";
      let type = "";
      let orderNumber = "";
      if (payment.type === "Reservation") {
        link = `/reservations/${payment._id}`;
        type = "reservation";
        orderNumber = payment?._id
          ?.substring(payment?._id.length - 5)
          ?.toUpperCase();
      } else if (payment?.orderInfo?._id) {
        link = `/orders/${payment?.orderInfo?._id}`;
        type = "inHouse";
        orderNumber = `H ${payment?.orderInfo?.orderNumber}`;
      } else if (payment?.orderPickupInfo?._id) {
        type = "pickup";
        orderNumber = `P ${payment?.orderInfo?.orderNumber}`;
        if (payment?.orderPickupInfo?.type === "scheduleOrder") {
          link = `/scheduleOrder/${payment?.orderPickupInfo?._id}`;
        } else if (payment?.orderPickupInfo?.type === "customOrders") {
          link = `/customOrders/${payment?.orderPickupInfo?._id}`;
        } else if (payment?.orderPickupInfo?.type === "iwaiterApp") {
          link = `/iwaiterApp/${payment?.orderPickupInfo?._id}`;
        } else if (payment?.orderPickupInfo?.type === "delivery") {
          link = `/delivery/${payment?.orderPickupInfo?._id}`;
        } else {
          link = `/pickup/${payment?.orderPickupInfo?._id}`;
        }
      }
      return {
        key: payment._id,
        orderId: payment.orderId || payment._id,
        orderNumber,
        paymentMethod: payment.paymentMethod,
        total: payment.totalNumber,
        chargeId: payment.chargeId,
        paymentCompanyName: payment.paymentCompanyName,
        createdAt: new Date(payment.createdAt).toLocaleString(),
        status: payment?.status,
        destination_id: payment?.destination_id || payment.destination_idPickup,
        restaurantId: payment?.restaurantId || payment.restaurantIdPickup,
        reservationId: payment?._id,
        link: link,
        paymentObject: { ...payment },
        type: type,
      };
    });
    setFormattedPayments(formatted);
  };

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Spin spinning={loading}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title
            level={4}
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {intl.formatMessage({ id: "pendingPayments" })}
          </Title>

          <Form
            name='payment-data'
            form={form}
            preserve={false}
            className='form-container'
            layout='inline'
            onFinish={getSettlementData}
            initialValues={{
              date: [moment().subtract(7, "days"), moment()],
            }}
          >
            <Form.Item
              name='date'
              label={intl.formatMessage({ id: "dateRange" })}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <RangePicker
                onChange={(date, dateString) => {
                  // if the start date change reset the end date to be 50 days after start date;
                  // const lastOption = moment(date[0]).add(14, "days");
                  // let end = moment(date[1]);
                  // if (date[1].isAfter(lastOption)) {
                  //   end = moment(date[0]).add(14, "days");
                  // }
                  // form.setFieldsValue({
                  //   date: [date[0], end],
                  // });
                }}
                // disabledDate={(current) => {
                //   // disabled all dates that are after start date + 50;
                //   return (
                //     current &&
                //     current >
                //       moment(form.getFieldValue("date")[0]).add(14, "days")
                //   );
                // }}
              />
            </Form.Item>
            <Form.Item>
              <CustomButton
                htmlType='submit'
                text={intl.formatMessage({ id: "send" })}
                className={`btnRegister btn-text border-8`}
                loading={loading}
                disabled={loading}
                type='primary'
              />
            </Form.Item>
          </Form>
        </div>

        <Table
          searchable
          fuzzySearch
          searchableProps={{
            debounce: true,
            fuzzySearch: true,
            inputProps: {
              placeholder: "Search this table...",
              prefix: <SearchOutlined />,
              disabled: loading || formattedPayments?.length === 0,
            },
          }}
          pagination={{
            position: ["topLeft"],
          }}
          columns={columns}
          className='light-shadow'
          loading={loading}
          dataSource={formattedPayments}
          scroll={{ x: "max-content" }}
          //   summary={(pageData) => {
          //     let total = 0;
          //     let totalPaid = 0;
          //     let remain = 0;
          //     pageData.forEach(({ paymentObject }) => {
          //       if (typeof +paymentObject?.total === "number") {
          //         if (paymentObject.didReceiveSettlement) {
          //           totalPaid += +paymentObject.total;
          //         }
          //         total += +paymentObject?.total;
          //       }
          //     });
          //     remain = total - totalPaid;
          //     return (
          //       <MYT.Summary>
          //         <MYT.Summary.Row>
          //           <MYT.Summary.Cell className='summary-bg-gray' colSpan={2}>
          //             {intl.formatMessage({ id: "total" })}
          //           </MYT.Summary.Cell>
          //           <MYT.Summary.Cell style={{ backgroundColor: "red" }}>
          //             <Text style={{ color: COLORS.primary }}>
          //               {total?.toFixed(2)}
          //             </Text>
          //           </MYT.Summary.Cell>
          //           <MYT.Summary.Cell className='summary-bg-gray' colSpan={2}>
          //             {intl.formatMessage({ id: "settlAmount" })}
          //           </MYT.Summary.Cell>
          //           <MYT.Summary.Cell
          //             colSpan={2}
          //             style={{ backgroundColor: "red" }}
          //           >
          //             <Text type='success'>{totalPaid?.toFixed(2)}</Text>
          //           </MYT.Summary.Cell>
          //         </MYT.Summary.Row>
          //         <MYT.Summary.Row>
          //           <MYT.Summary.Cell className='summary-bg-gray' colSpan={2}>
          //             {intl.formatMessage({ id: "remainAmount" })}
          //           </MYT.Summary.Cell>
          //           <MYT.Summary.Cell style={{ backgroundColor: "red" }}>
          //             <Text type='danger'>{remain?.toFixed(2)}</Text>
          //           </MYT.Summary.Cell>
          //         </MYT.Summary.Row>
          //       </MYT.Summary>
          //     );
          //   }}
        />
      </Spin>
    </div>
  );
};

export default PendingPayments;
