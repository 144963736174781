import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17.001}
    height={28.528}
    {...props}
  >
    <g data-name="Group 2" fill={props?.fill || "#0a2240"} fillRule="evenodd">
      <path d="M9.464 14.974a14.224 14.224 0 0 0-6.463 3.15c1.1 4.887 4.642 9.351 8.005 9.358a1.287 1.287 0 0 0 1.4 1.02 1.366 1.366 0 0 0 .843-1.552 6.162 6.162 0 0 0 2.478-2.422 8.689 8.689 0 0 0 .664-1.4c.812-2.136.741-5.737.2-8.316a17.023 17.023 0 0 0-7.127.158M1.673 17.004a17.718 17.718 0 0 1 7.083-3.958 15.482 15.482 0 0 1 7.72-.251l.072-.187a6.433 6.433 0 0 0 .359-3.423c-.646-3.532-4.317-5.438-8.588-4.631A7.759 7.759 0 0 0 6.77.321a.739.739 0 0 0-1.212 0 1.283 1.283 0 0 0 0 1.526 5.366 5.366 0 0 1 1.08 3.172A10.385 10.385 0 0 0 .75 9.947a6.566 6.566 0 0 0-.652 4.113 5.452 5.452 0 0 0 1.511 2.881l.066.066" />
    </g>
  </svg>
)

export default SvgComponent
