import { Typography, Skeleton, Result, Button } from "antd";
import React, { useEffect, useState } from "react";
import { IoArrowBackOutline, IoArrowForwardOutline } from "react-icons/io5";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getCustomOrderById,
  getOrderById,
  getPickupOrderById,
} from "../../API/fetch";
import OrderCard from "../../Components/Orders/OrderCard";
import { setOrderByIdAction } from "../../redux/reducers/orderReducer";
import { ORDER_TYPES } from "../../Types";
import CustomOrderCard from "../../Components/Orders/CustomOrderCard";

const { Title } = Typography;

function OrderById() {
  const history = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  const { orderById } = useSelector((state) => state.order);
  const [loading, setLoading] = useState(true);
  const [typeFromParam, setTypeFromParam] = useState("");
  const [error, setError] = useState(null);
  //state for testing
  const getProductById = async (id) => {
    try {
      // get the first params of the url
      const param = window.location.pathname.split("/")[1];
      let firstParams = "";
      switch (param) {
        case "pickup":
          firstParams = ORDER_TYPES.PICKUP;
          setTypeFromParam(ORDER_TYPES.PICKUP);
          break;
        case "scheduleOrder":
          firstParams = ORDER_TYPES.PICKUP;
          setTypeFromParam(ORDER_TYPES.PICKUP);
          break;
        case "delivery":
          firstParams = ORDER_TYPES.PICKUP;
          setTypeFromParam(ORDER_TYPES.DELIVERY);
          break;
        case "iwaiterApp":
          firstParams = ORDER_TYPES.PICKUP;
          setTypeFromParam(ORDER_TYPES.IWAITER_APP);
          break;
        case "customOrders":
          firstParams = ORDER_TYPES.CUSTOM_ORDER;
          setTypeFromParam(ORDER_TYPES.CUSTOM_ORDER);
          break;
        case "orders":
          firstParams = ORDER_TYPES.DINE_IN;
          setTypeFromParam(ORDER_TYPES.DINE_IN);
          break;

        default:
          break;
      }
      if (
        state === ORDER_TYPES.PICKUP ||
        state === ORDER_TYPES.DELIVERY ||
        state === ORDER_TYPES.IWAITER_APP ||
        firstParams === ORDER_TYPES.PICKUP
      ) {
        const res = await getPickupOrderById(id);
        if (res?.data?.order) {
          dispatch(setOrderByIdAction(res?.data?.order));
        } else {
          setError("NOT_FOUND");
        }
      } else if (state === ORDER_TYPES.CUSTOM_ORDER) {
        const res = await getCustomOrderById(id);
        if (res?.data?.order) {
          dispatch(setOrderByIdAction(res?.data?.order));
        } else {
          setError("NOT_FOUND");
        }
      } else if (state || firstParams === ORDER_TYPES.DINE_IN) {
        const res = await getOrderById(id);
        if (res?.data?.order) {
          dispatch(setOrderByIdAction(res?.data?.order));
        } else {
          setError("NOT_FOUND");
        }
      } else {
        console.log("error");
        setError("NOT_FOUND");
        history(window.location.pathname.split("/").slice(0, -1).join("/"));
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      console.log(error.response);
      setError("NOT_FOUND");
    }
  };

  useEffect(() => {
    getProductById(id);
  }, [id]);

  return (
    <div style={{ padding: 10 }}>
      <div style={{ marginTop: 10, marginBottom: 10 }}>
        <div
          level={3}
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <Title
            level={3}
            style={{ margin: 0, padding: 10 }}
            className='pointer'
            onClick={() => history(-1)}
          >
            {rtl ? (
              <IoArrowForwardOutline style={{ fontSize: 22 }} />
            ) : (
              <IoArrowBackOutline style={{ fontSize: 22 }} />
            )}
          </Title>
          <Title
            level={4}
            className='pointer'
            style={{ margin: 0, padding: 10 }}
            onClick={() => history(-1)}
          >
            {intl.formatMessage({ id: "forward" })}
          </Title>
        </div>
      </div>
      {loading ? (
        <>
          <Skeleton.Button active={true} size={"large"} />
          <Skeleton active={true} size={"large"} rows={5} />
        </>
      ) : error ? (
        <div
          className='light-shadow'
          style={{
            borderRadius: 10,
            overflow: "hidden",
            backgroundColor: "white",
          }}
        >
          <Result
            status={"404"}
            title={"404"}
            subTitle={intl.formatMessage({ id: "not_found" })}
            extra={
              <Button
                type='primary'
                onClick={() => {
                  history(-1);
                }}
              >
                {intl.formatMessage({ id: "goBack" })}
              </Button>
            }
          />
        </div>
      ) : (
        <div
          className='light-shadow'
          style={{
            borderRadius: 10,
            overflow: "hidden",
            backgroundColor: "white",
          }}
        >
          {state === ORDER_TYPES.CUSTOM_ORDER ? (
            <CustomOrderCard
              order={orderById}
              type={state ? state : typeFromParam}
              getProductById={getProductById}
            />
          ) : (
            <OrderCard
              order={orderById}
              type={state ? state : typeFromParam}
              getProductById={getProductById}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default OrderById;
