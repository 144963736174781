import React from "react";
import { Row, Col, Space, Tag, Typography, Collapse, Select } from "antd";
import { TAG_COLORS_BY_STATUS_CUSTOM } from "../../../Types";
import { useIntl } from "react-intl";
import { CaretRightOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { cutText } from "../../../helpers";
import COLORS from "../../../Style/colors";

const { Text, Paragraph, Link } = Typography;
const { Panel } = Collapse;
const clientURL = "https://app.iwaiter.club";
function CustomOrderHeader({
  orderNumber,
  status,
  createdAt,
  total,
  userPhone,
  userName,
  role = { role },
  _id,
}) {
  const intl = useIntl();
  const { restaurant } = useSelector((state) => state.restaurant);
  const { slug, domain } = restaurant;
  return (
    <Row>
      <Col md={12}>
        <Space direction='vertical' size={0}>
          <Space direction='horizontal' size={"small"}>
            <Text className='my-font'>
              {`${intl.formatMessage({ id: "orderNo" })}: ${orderNumber}`}
            </Text>
            <Tag
              color={TAG_COLORS_BY_STATUS_CUSTOM[status]}
              className='my-font'
            >
              {intl.formatMessage({
                id: status === "close" ? "closedOrder" : status,
              })}
            </Tag>
          </Space>
          <Text type='secondary' className='my-font' style={{ fontSize: 14 }}>
            {`${intl.formatMessage({ id: "time" })}: ${new Date(
              createdAt
            ).toLocaleString()}`}
          </Text>

          {userName ? (
            <Collapse
              ghost
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
            >
              <Panel
                header={
                  <Text className='my-font' type={"primary"}>
                    {intl.formatMessage({ id: "userInfo" })}
                  </Text>
                }
              >
                <div>
                  <Text className='my-font'>
                    {intl.formatMessage({ id: "customerName" })}
                    {" : "}
                    <Tag>{userName}</Tag>
                  </Text>
                  <Paragraph
                    copyable={{
                      tooltips: false,
                    }}
                    className='my-font'
                  >
                    {intl.formatMessage({ id: "customerPhone" })}
                    {" : "}
                    <Tag> {userPhone?.length > 4 ? userPhone : ""}</Tag>
                  </Paragraph>
                </div>
              </Panel>
            </Collapse>
          ) : null}
        </Space>
      </Col>
      <Col md={12}>
        <Space direction='vertical' size={0}>
          <Text className='my-font'>
            {`${intl.formatMessage({ id: "total" })}: ${total.toFixed(2)}`}
          </Text>
          <Link
            underline
            style={{
              color: COLORS.primary,
            }}
            href={`${
              domain == "iWaiter"
                ? `${clientURL}/${slug}/customOrders`
                : `${domain}/customOrders`
            }/${_id}`}
            copyable={{
              text: `${
                domain == "iWaiter"
                  ? `${clientURL}/${slug}/customOrders`
                  : `${domain}/customOrders`
              }/${_id}`,
            }}
            className='my-font'
            target='_blank'
          >
            {`${intl.formatMessage({ id: "paymentLink" })}`}
          </Link>
        </Space>
      </Col>
    </Row>
  );
}

export default CustomOrderHeader;
