import phoneCods from "../Views/Auth/PhoneCods.json";
import moment from "moment";
import store from "../redux/store";
import { logOutAction } from "../redux/reducers/authReducer";
import tableExport from "antd-table-export";

export const checkIfValidPhone = (phone) => {
  let isValid = false;
  phone = phone.replace("+", "");
  // console.log(phone)
  if (`${phone[0]}` === "7") {
    if (`${phone.substring(1)}`.length === 9) {
      isValid = true;
    }
  } else if (`${phone[0]}` === "1") {
    if (`${phone.substring(1)}`.length === 10) {
      isValid = true;
    }
  } else {
    let towDigits = phone.substring(0, 2);
    let threeDigits = phone.substring(0, 3);
    let i = phoneCods.findIndex((a) => a.dial_code === `+${towDigits}`);
    if (i !== -1) {
      isValid = true;
    } else {
      if (`${threeDigits}` === `966`) {
        if (`${phone.substring(3)}`.length === 9 && `${phone[3]}` === "5") {
          isValid = true;
        }
      } else {
        isValid = true;
      }
    }
  }
  return isValid;
};

export const getDiscountAmount = ({ total, percentage, maximumAmount }) => {
  let totalDiscount = total * (percentage / 100);
  if (Number(maximumAmount) && maximumAmount > 0) {
    if (totalDiscount > maximumAmount) {
      totalDiscount = maximumAmount;
    }
  }
  return totalDiscount;
};

export const getOldestOpenOrder = (orders = []) => {
  let time = null;
  const openOrders = orders.filter((o) => o.status === "open");
  if (openOrders.length) {
    const sorted = openOrders.sort(
      (o1, o2) => new Date(o1.createdAt) - new Date(o2.createdAt)
    );
    time = sorted[0].createdAt;
  }
  return time;
};

export function cutText(text, length) {
  if (text == null) {
    return "";
  }
  if (text.length <= length) {
    return text;
  }
  text = text.substring(0, length);
  let last = text.lastIndexOf(" ");
  text = text.substring(0, last);
  return text + "...";
}

export function getContrastYIQ(hexcolor) {
  if (!hexcolor || !hexcolor?.trim()?.length) return "black";
  hexcolor = hexcolor.replace("#", "");
  var r = parseInt(hexcolor.substr(0, 2), 16);
  var g = parseInt(hexcolor.substr(2, 2), 16);
  var b = parseInt(hexcolor.substr(4, 2), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "black" : "white";
}

export const getCommentsFromFoods = (foods) => {
  if (!foods || foods?.length === 0) return [];
  const comments = [];
  foods?.forEach((food) => {
    if (food?.comments?.length) {
      food.comments.forEach((comment) => {
        comments.push({
          status: comment?.status,
          comment: comment?.text,
          origin: comment?.origin,
          customerName: comment?.customerId?.name,
          time: new Date(
            comment?.createdAt ? comment.createdAt : new Date()
          ).toLocaleString(),
        });
      });
    }
  });
  return comments;
};

export const getRestaurantService = ({ services }) => {
  let isActive = false;
  let servicesArray = [];
  let serviceWithEndDate = [];
  if (services && services.length > 0) {
    // get all services if the current time is between the start and end time
    let activeServices = services.filter((service) => {
      return moment().isBetween(
        moment(service.serviceStart).startOf("day"),
        moment(service.serviceEnd).endOf("day")
      );
    });
    if (activeServices && activeServices.length > 0) {
      activeServices.forEach((item) => {
        if (item.services && item.services.length > 0) {
          item.services.forEach((one) => {
            if (!servicesArray.includes(one)) {
              servicesArray.push(one);
            }
            serviceWithEndDate.push({
              service: one,
              endDate: item.serviceEnd,
            });
          });
        }
      });
      isActive = true;
    }
  }
  if (serviceWithEndDate.length > 0) {
    // sort by end date;
    serviceWithEndDate.sort(
      (a, b) => new Date(b.endDate) - new Date(a.endDate)
    );
  }
  return {
    isActive,
    services: servicesArray,
    serviceWithEndDate,
  };
};

//sort by order
export const sortByOrder = (arr) => {
  if (!arr || !arr.length) return [];
  return arr.slice().sort((a, b) => a.order - b.order);
};

// check if error status is 401;
export const checkIfUnauthorized = (error) => {
  if (error?.response?.status === 401) {
    // handle log out and clear start;
    store.dispatch(logOutAction());
  }
  return true;
};

// write a function that check if the device is mobile, Ipad, Tablet or Desktop
export const canOpenRearCamera = () => {
  // check the width of the screen;
  const width = window.innerWidth;
  // check if it small than 768px;
  if (isIOS()) return true;
  if (isIpadOS()) return true;
  if (width < 768) return true;
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator?.userAgent);
  const isTablet = /Tablet|iPad/i.test(navigator?.userAgent);
  const isDesktop = !isMobile && !isTablet;
  if (isDesktop) return false;
  return true;
};

function isIOS() {
  if (/iPad|iPhone|iPod/.test(navigator?.platform)) {
    return true;
  } else {
    return (
      navigator?.maxTouchPoints &&
      navigator?.maxTouchPoints > 2 &&
      /MacIntel/.test(navigator?.platform)
    );
  }
}

function isIpadOS() {
  return (
    navigator?.maxTouchPoints &&
    navigator?.maxTouchPoints > 2 &&
    /MacIntel/.test(navigator?.platform)
  );
}

export const customerExport = ({
  loadingSetter,
  fileName = "Report",
  data,
  columns,
}) => {
  loadingSetter(true);
  let formattedData = data.map((one) => {
    let paymentOnlineFees = one.paymentOnlineFees;
    let otherCharges = one.otherCharges;
    let total = one.total;
    if (one?.total) {
      // convert string number to number;
      total = Number(one.total);
    }
    if (one?.paymentOnlineFees) {
      // convert string number to number;
      paymentOnlineFees = Number(one.paymentOnlineFees);
    }
    if (one?.otherCharges) {
      // convert string number to number;
      otherCharges = Number(one.otherCharges);
    }
    return {
      ...one,
      paymentOnlineFees,
      otherCharges,
      total,
    };
  });
  console.log(formattedData, "SSSSS");
  const exportInstance = new tableExport(formattedData, columns);
  exportInstance.download(fileName, "xlsx");
  loadingSetter(false);
  return true;
};
export const getReportTotal = (arr) => {
  const totals = arr.map((el) => parseFloat(el?.total));
  const totalReports = totals.reduce((accumulator, value) => {
    return accumulator + value;
  }, 0);
  return totalReports;
};

export const getTotalVisits = (arr) => {
  const totals = arr.map((el) => parseFloat(el?.visitCount));
  const totalVisits = totals.reduce((accumulator, value) => {
    return accumulator + value;
  }, 0);
  return totalVisits;
};

export const getTotalPersons = (arr) => {
  const totals = arr.map((el) => parseFloat(el?.personsCount));
  const totalVisits = totals.reduce((accumulator, value) => {
    return accumulator + value;
  }, 0);
  return totalVisits;
};

export const getBranchNameById = (rtl, branches, id) => {
  const AllBranches = branches?.map((el) => el?.branch);
  const branchByID = AllBranches?.filter((el) => el?._id === id);
  let branch;
  if (branchByID?.length) {
    branch = branchByID[0];
  } else {
    branch = {};
  }

  return rtl ? branch?.name : branch?.name_en;
};
export const checkIsWithQty = (arr) => {
  const newArr = arr?.map((el) => {
    if (el?.isWithQuantity === false) {
      return {
        ...el,
        qty: 0,
      };
    } else {
      return el;
    }
  });
  return newArr;
};

export const getFormattedQuantities = ({ quantities, role, allBranches }) => {
  let formattedQty = [];
  if (role === "super") {
    // admin
    formattedQty = allBranches?.map((el) => {
      // check if we have saved qty;
      const old = quantities?.find((b) => b?.branchId === el?.branch?._id);
      if (old) {
        return old;
      }

      return {
        branchId: el?.branch?._id,
        qty: 0,
        isWithQuantity: false,
      };
    });
  } else {
    // waiter
    formattedQty = allBranches?.map((el) => {
      const old = quantities?.find((b) => b?.branchId === el?._id);
      if (old) {
        return old;
      }
      return {
        branchId: el?._id,
        qty: 0,
        isWithQuantity: false,
      };
    });
  }
  return formattedQty;
};


export const getVatAmount = (price) => {
  return price * 0.15;
}

export const getLastOrderCreated = (orders) => {
  if (!orders || !orders.length) return null;
  const sorted = [...orders]?.sort((a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt);
  });
  return sorted[0];
}