import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14.35}
    height={18.386}
    {...props}
  >
    <g data-name="Group 11">
      <path
        d="M7.174 18.386c-2.935 0-6.338-1.767-7.089-6.728A12.89 12.89 0 0 1 3.11 2.284 5.58 5.58 0 0 1 7.174 0a5.578 5.578 0 0 1 4.064 2.284 12.892 12.892 0 0 1 3.026 9.374c-.752 4.961-4.155 6.728-7.09 6.728"
        fill={props?.fill || "#0a2240"}
        fillRule="evenodd"
      />
      <path
        d="M7.174 17.639c-2.7 0-5.676-1.639-6.351-6.092-.661-4.366 2.9-10.8 6.351-10.8s7.013 6.433 6.352 10.8c-.675 4.453-3.652 6.092-6.352 6.092"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        d="M10.885 11.236a3.711 3.711 0 0 1-3.711 3.711 3.711 3.711 0 0 1-3.711-3.711 3.711 3.711 0 0 1 3.711-3.711 3.71 3.71 0 0 1 3.711 3.711"
        fill={props?.fill || "#0a2240"}
      />
    </g>
  </svg>
)

export default SvgComponent
