import React, { useState, useEffect } from "react";
import COLORS from "../../Style/colors";
import { Typography, Spin, Empty, Row,  } from "antd";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { getMenuEngineer } from "../../API/fetch";
import CustomButton from "../../Components/CustomButton";
import Classes from "./Components/Classes";
import CategoriesClasses from "./Components/CategoriesClasses";
import { IoArrowBackOutline, IoArrowForwardOutline } from "react-icons/io5";

const { Title, Text, Link } = Typography;

const MenuEngineering = () => {
  const intl = useIntl();
  const { isBigScreen, rtl } = useSelector((state) => state.settings);
  const { restaurant } = useSelector((state) => state.restaurant);
  const [loading, setLoading] = useState(true);
  const [classes, setClasses] = useState([]);
  const [details, setDetails] = useState([]);
  const [showClasses, setShowClasses] = useState(false);
  const [showCategoriesClasses, setShowCategoriesClasses] = useState(false);

  const [noCost, setNoCost] = useState(false);

  useEffect(() => {
    getEngineeringData();
  }, []);
  const getEngineeringData = async () => {
    setLoading(true);
    try {
      const res = await getMenuEngineer(restaurant._id);
      setClasses(res?.data?.classes);
      setDetails(res?.data?.details);
      setLoading(false);
    } catch (error) {
      if (error?.response?.data?.message === "No cost") {
        setNoCost(true);
        setLoading(false);
        return;
      }
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <Title>
          {showClasses || showCategoriesClasses ? (
            rtl ? (
              <IoArrowForwardOutline
                className='pointer'
                onClick={() => {
                  setShowClasses(false);
                  setShowCategoriesClasses(false);
                }}
              />
            ) : (
              <IoArrowBackOutline
                className='pointer'
                onClick={() => {
                  setShowClasses(false);
                  setShowCategoriesClasses(false);
                }}
              />
            )
          ) : (
            intl.formatMessage({ id: "menuAnalytics" })
          )}
        </Title>
      </div>

      <div
        style={{
          backgroundColor: COLORS.white,
          marginTop: 10,
          width: "92%",
          minHeight: "70vh",
          marginRight: "auto",
          marginLeft: "auto",
          borderRadius: 10,
        }}
        className='light-shadow'
      >
        <Spin spinning={loading}>
          {/* User has not enter the cost for the foods; */}
          {noCost ? (
            <Empty
              image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
              imageStyle={{
                height: isBigScreen ? 250 : 150,
                marginTop: 40,
              }}
              description={
                <>
                <h2 className='my-font'>
                  {intl.formatMessage({ id: "enterCostForAllFoods" })}
                </h2>
                <Link href={'https://blog.iwaiter.net/menu-engineering/'} target='_blank'>
                <h2 className='my-font'>
                  {intl.formatMessage({ id: "reedMore" })}
                </h2>
                </Link>
                </>

              }
            ></Empty>
          ) : showClasses ? (
            // View over view analytics;
            <Classes classes={classes}  />
          ) : showCategoriesClasses ? (
            // see table and advanced details;
            <CategoriesClasses
              details={details}
            />
          ) : (
            <div className='px-5 py-5'>
              {/* Main intro view */}
              <Title level={3}>
                {intl.formatMessage({ id: "whatIsMenuEngineering" })}
              </Title>
              <Text className='my-font'>
                {intl.formatMessage({ id: "menuEngineeringDes" })}
              </Text>
              <ul className='mt-2'>
                <li>{intl.formatMessage({ id: "starsMenu" })}</li>
                <li>{intl.formatMessage({ id: "puzzles" })}</li>
                <li>{intl.formatMessage({ id: "Workhorses" })}</li>
                <li>{intl.formatMessage({ id: "dogs" })}</li>
              </ul>
              <Row type='flex' justify='center' align='middle'>
                <CustomButton
                  onClick={() => setShowClasses(true)}
                  text={intl.formatMessage({ id: "showClasses" })}
                  type='primary'
                  className='px-5 border-8'
                />
                <CustomButton
                  onClick={() => setShowCategoriesClasses(true)}
                  text={intl.formatMessage({ id: "showClassesDetails" })}
                  type='primary'
                  className={`px-5 border-8 mx-4 ${isBigScreen ? "" : "mt-3"}`}
                />
              </Row>
            </div>
          )}
        </Spin>
      </div>
    </div>
  );
};

export default MenuEngineering;
