import { Checkbox, Col, DatePicker, Divider, Form, Row, Select, TimePicker, Typography } from 'antd'
import React from 'react'
import { useIntl } from 'react-intl'
import { ALL_DAYS } from '../../../../Types'
import { blockReservationsTypes } from '../EditReservationSchemaForm'

const { Text ,Title } = Typography
const { Option } = Select

function ReservationWorkDays({ form, rtl }) {
    const intl = useIntl()
    return (
        <div>           
            <Divider orientation={rtl ? "right" : "left"}>
                <Title  className='my-font' level={5}>
                    {intl.formatMessage({ id: "workDays" })}
                </Title>
            </Divider>
            <Row type='flex' justify='space-between' align='bottom'>
                <Col md={11} sm={24}>
                    <Form.Item
                        label={intl.formatMessage({ id: "reservationStartDate" })}
                        tooltip={
                            <Text className='my-font'>
                                {intl.formatMessage({
                                    id: "reservationStartDateTooltip",
                                })}
                            </Text>
                        }
                        style={{width:"200px"}}
                        className='mb-1'
                        name='reservationStartDate'
                    >
                        <DatePicker style={{ width: "100%" }} />
                    </Form.Item>
                </Col>
                <Col md={11} sm={24}>
                    <Form.Item
                        label={intl.formatMessage({ id: "reservationEndDate" })}
                        tooltip={
                            <Text className='my-font'>
                                {intl.formatMessage({ id: "reservationEndDateTooltip" })}
                            </Text>
                        }
                        style={{width:"200px"}}
                        className='mb-1'
                        name='reservationEndDate'
                    >
                        <DatePicker style={{ width: "100%" }} />
                    </Form.Item>
                </Col>
            </Row>
            <Row type='flex' justify='space-between'>
                <Col md={11} sm={24}>
                    <Form.Item
                        label={intl.formatMessage({ id: "startTimeReservation" })}
                        className='mb-1'
                        name='startTime'
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <TimePicker format={"HH:mm"} disabled={form.getFieldValue('onlyOneReservationADay')} />
                    </Form.Item>
                </Col>
                <Col md={11} sm={24}>
                    <Form.Item
                        label={intl.formatMessage({ id: "endTimeReservation" })}
                        className='mb-1'
                        name='endTime'
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <TimePicker format={"HH:mm"} disabled={form.getFieldValue('onlyOneReservationADay')} />
                    </Form.Item>
                </Col>
            </Row>
            <Row type='flex' justify='space-between'>
                <Col md={11} sm={24}>
                    <Form.Item
                        label={intl.formatMessage({ id: "disabledDays" })}
                        className='mb-1'
                        name='disabledDays'
                        tooltip={
                            <Text className='my-font'>
                                {intl.formatMessage({ id: "disabledDaysTooltip" })}
                            </Text>
                        }
                    >
                        <Select mode='multiple' className='my-font'>
                            {ALL_DAYS?.map((day) => (
                                <Option
                                    key={day.value}
                                    value={day.value}
                                    className='my-font'
                                >
                                    {intl.formatMessage({ id: day.value })}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col md={11} sm={24}>
                    <Form.Item
                        label={intl.formatMessage({ id: "blockReservationsTemp" })}
                        className='mb-1'
                        name='blockReservations'
                        tooltip={
                            <Text className='my-font'>
                                {intl.formatMessage({
                                    id: "blockReservationsTempTooltip",
                                })}
                            </Text>
                        }
                    >
                        <Select className='my-font'>
                            {blockReservationsTypes?.map((block) => (
                                <Option
                                    key={block.value}
                                    value={block.value}
                                    className='my-font'
                                >
                                    {rtl ? block?.name : block?.name_en}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>

            </Row>
            <Row type='flex' justify='space-between'>
                <Col md={11} sm={24}>
                    <Form.Item
                        style={{
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                        className='mb-1 mt-3'
                        valuePropName='checked'
                        name='onlyOneReservationADay'

                    >
                        <Checkbox
                            disabled={!form.getFieldValue('isCountPerReservation')}
                        >
                            <Text className='my-font'>
                                {intl.formatMessage({ id: "onlyOneReservationADay" })}
                            </Text>
                        </Checkbox>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    )
}

export default ReservationWorkDays