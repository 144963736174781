import React, { useState, useEffect } from "react";
import "chart.js/auto";
import { useDispatch, useSelector } from "react-redux";
import COLORS from "../../../Style/colors";
import moment from "moment";
import { Typography, Space, Spin, Table } from "antd";
import { useIntl } from "react-intl";
import {Link} from 'react-router-dom'
const { Title, } = Typography;

const LastFeedback = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { restaurant, feedbackForms } = useSelector(
    (state) => state.restaurant
  );
  const { rtl } = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState('')
  const [column, setColumn] = useState([]);
  const [formattedData, setFormattedData] = useState([]);
  useEffect(() => {
    if (feedbackForms?.length > 0) {
      const selected = feedbackForms.find(
        (feedback) => feedback.status === "Active"
      );
      setSelectedId(selected?._id);
      if (selected?.questions?.length) {
        let formatted = [];
        selected.questions.forEach((q) => {
          if (q?.answerType === "text") {
            formatted.unshift({
              key: q._id,
              title: rtl ? q.question : q.question_en,
              dataIndex: q._id,
            });
          } else {
            formatted.push({
              key: q._id,
              title: rtl ? q.question : q.question_en,
              dataIndex: q._id,
            });
          }
        });
        // get last three indexes element from formatted;
        // const lastThree = formatted.slice(formatted.length - 3);
        setColumn(formatted);
      }
      if (selected?.answers?.length) {
        const formatted = selected.answers.map((answer, i) => {
          const customAnswers = {};
          answer?.userAnswers?.forEach((one) => {
            let formatted = one.answer;
            if (one.answerType === "stars" || one.answerType === "faces") {
              formatted = `${one.answer} / 5`;
            }
            customAnswers[one.questionId] = formatted;
          });
          return {
            key: answer._id,
            number: i + 1,
            date: new Date(answer.createdAt).toLocaleString(),
            ...customAnswers,
          };
        });
        const lastThree = formatted.slice(formatted.length - 3)?.reverse();
        setFormattedData(lastThree);
      }
    }
  }, [feedbackForms]);

  return (
    <div>
      <Spin spinning={loading}>
        <Space
          direction='horizontal'
          style={{ width: "100%", justifyContent: "space-between" }}
        >
            <Title style={{ marginBottom: 0, paddingBottom: 0 }} level={4}>
              {intl.formatMessage({ id: "latestFeedback" })}
            </Title>
            <Link to={`/feedback/${selectedId}`}>
              <Title
                style={{
                  marginBottom: 0,
                  paddingBottom: 0,
                  marginTop: 0,
                  paddingTop: 0,
                }}
                level={5}
              >{intl.formatMessage({ id: "seeAll" })}</Title>
            </Link>
        </Space>
        <Table
          columns={column}
          pagination={{
            hideOnSinglePage: true,
          }}
          dataSource={formattedData}
          scroll={{ x: 500 }}
        />
      </Spin>
    </div>
  );
};

export default LastFeedback;
