import React, { useEffect, useState } from "react";
import { Table, Tag, Typography, DatePicker, Form } from "antd";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import {
  DINE_IN_ORDERS_STATUS,
  ORDER_TYPES,
  TAG_COLORS_BY_PAYMENT_STATUS,
  TAG_COLORS_BY_STATUS,
} from "../../Types";
import CustomButton from "../../Components/CustomButton";
import COLORS from "../../Style/colors";
import moment from "moment";
import { postSearchOrders } from "../../API/fetch";
import { getDiscountAmount } from "../../helpers";
import { useNavigate } from "react-router-dom";
const { RangePicker } = DatePicker;

const { Title } = Typography;
const Orders = () => {
  const intl = useIntl();
  const navigate = useNavigate()
  const { role, waiter } = useSelector((state) => state.auth);
  const { rtl } = useSelector((state) => state.settings);
  const { dineInOrders } = useSelector((state) => state.order);
  const { tables } = useSelector((state) => state.table);
  const { allBranches } = useSelector((state) => state.branch);
  const [formattedOrders, setFormattedOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [form] = Form.useForm();
  useEffect(() => {
    setLoading(true);
    if (dineInOrders?.length) {
      const formattedOrders = dineInOrders.map((order) => {
        const table = tables?.find((t) => t._id === order?.tableId);
        const branch = allBranches?.find(
          (b) => b?.branch?._id === order?.branchId
        );
        let finalTotal = order.total;
        if (order.couponCode && order.couponCode._id) {
          const discount = getDiscountAmount({
            total: order.total,
            percentage: order.couponCode.percentage,
            maximumAmount: order.couponCode.maximumAmount,
          });
          finalTotal -= discount;
        }
        if (order?.tableFees) {
          finalTotal += order?.tableFees
        }
        if (order?.specialOfferDiscount) {
          finalTotal -= order?.specialOfferDiscount
        }

        return {
          key: order?._id,
          _id: order?._id,
          branch: rtl ? branch?.branch?.name : branch?.branch?.name_en,
          orderNumber: "#H " + order?.orderNumber,
          table: rtl ? table?.name : table?.name_en,
          status: order?.status,
          total: finalTotal?.toFixed(2),
          paymentStatus: order?.paymentStatus,
          time: new Date(
            order?.createdAt ? order.createdAt : new Date()
          ).toLocaleString(),
          orderObject: { ...order, table },
        };
      });
      setFormattedOrders(formattedOrders);
    }
    setLoading(false);
  }, [dineInOrders, rtl]);

  const columns = [
    {
      title: intl.formatMessage({ id: "orderNumber" }),
      dataIndex: "orderNumber",
      key: "orderNumber",
    },
    {
      title: intl.formatMessage({ id: "table" }),
      dataIndex: "table",
      key: "table",
      onFilter: (value, record) => record.table.indexOf(value) === 0,
      filters: tables.map((t) => ({
        text: rtl ? t.name : t.name_en,
        value: rtl ? t.name : t.name_en,
      })),
    },
    {
      title: intl.formatMessage({ id: "orderStatus" }),
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return (
          <Tag color={TAG_COLORS_BY_STATUS[status]}>
            {intl.formatMessage({
              id:
                status === DINE_IN_ORDERS_STATUS.CLOSE
                  ? "closedOrder"
                  : status
                    ? status
                    : "n/a",
            })}
          </Tag>
        );
      },
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      filters: [
        {
          text: intl.formatMessage({ id: DINE_IN_ORDERS_STATUS.OPEN }),
          value: DINE_IN_ORDERS_STATUS.OPEN,
        },
        {
          text: intl.formatMessage({ id: DINE_IN_ORDERS_STATUS.IN_SERVER }),
          value: DINE_IN_ORDERS_STATUS.IN_SERVER,
        },
        {
          text: intl.formatMessage({ id: DINE_IN_ORDERS_STATUS.SERVED }),
          value: DINE_IN_ORDERS_STATUS.SERVED,
        },
        {
          text: intl.formatMessage({ id: "closedOrder" }),
          value: DINE_IN_ORDERS_STATUS.CLOSE,
        },
      ],
    },
    {
      title: intl.formatMessage({ id: "total" }),
      dataIndex: "total",
      key: "total",
    },
    {
      title: intl.formatMessage({ id: "paymentStatus" }),
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      render: (status) => {
        return (
          <Tag color={TAG_COLORS_BY_PAYMENT_STATUS[status]}>
            {intl.formatMessage({
              id: status ? status : "N/A",
            })}
          </Tag>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "time" }),
      dataIndex: "time",
      key: "time",
      sorter: (a, b) =>
        new Date(a.orderObject?.createdAt) - new Date(b.orderObject?.createdAt),
      showSorterTooltip: false,
    },
  ];

  if (role === "super") {
    columns.splice(1, 0, {
      title: intl.formatMessage({ id: "branch" }),
      dataIndex: "branch",
      key: "branch",
      onFilter: (value, record) => value === record.orderObject?.branchId,
      filters: allBranches?.map(({ branch }) => {
        return {
          text: rtl ? branch?.name : branch?.name_en,
          value: branch?._id,
        };
      }),
    });
  }

  const onFinish = async (values) => {
    const [start, end] = values.date;
    const startDate = moment(start).set({ hour: 0, minute: 0, second: 0 });
    const endDate = moment(end).set({ hour: 23, minute: 59, second: 59 });

    fetchOrders(startDate, endDate, 1);
  };

  const fetchOrders = async (startDate, endDate, page) => {
    try {
      setLoading(true);
      const data = {
        orderType: "inHouse",
        dateStart: startDate,
        dateEnd: endDate,
        code: waiter.code,
        paymentStatus: "all",
        page: page,
      };
      const res = await postSearchOrders(data);

      if (res.data?.orders?.length) {
        const sorted = [...res.data.orders];
        let sortedOrders = sorted.sort((a, b) => {
          return new Date(a.createdAt) - new Date(b.createdAt);
        });
        const formattedOrders = sortedOrders?.map((order) => {

          const table = tables?.find((t) => t._id === order?.tableId);
          const branch = allBranches?.find(
            (b) => b?.branch?._id === order?.branchId
          );
          let orderNumber = "#H N/A";
          if (order?.orderNumber) {
            orderNumber = "#H " + order?.orderNumber;
          }
          let finalTotal = order.total;
          if (order.couponCode && order.couponCode._id) {
            const discount = getDiscountAmount({
              total: order.total,
              percentage: order.couponCode.percentage,
              maximumAmount: order.couponCode.maximumAmount,
            });
            finalTotal -= discount;
          }
          return {
            key: order?._id,
            _id: order?._id,
            branch: rtl ? branch?.branch?.name : branch?.branch?.name_en,
            orderNumber: orderNumber,
            table: rtl ? table?.name : table?.name_en,
            status: order?.status,
            total: finalTotal?.toFixed(2),
            paymentStatus: order?.paymentStatus,
            time: new Date(
              order?.createdAt ? order.createdAt : new Date()
            ).toLocaleString(),
            orderObject: { ...order, table },
          };
        });
        setFormattedOrders(formattedOrders);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      {role === "super" ? (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 10,
            }}
          >
            <Title level={4}>{intl.formatMessage({ id: "dineIn" })}</Title>
            <CustomButton
              text={intl.formatMessage({
                id: showFilter ? "hide" : "filter",
              })}
              type='primary'
              className='border-8'
              onClick={() => setShowFilter(!showFilter)}
            />
          </div>
          {showFilter ? (
            <div>
              <div
                style={{ backgroundColor: COLORS.gray }}
                className='py-4 px-3'
              >
                <Form
                  name='food-form'
                  form={form}
                  className='form-container'
                  layout='inline'
                  onFinish={onFinish}
                >
                  <Form.Item
                    rules={[{ required: true, message: "Please select Date" }]}
                    name='date'
                    label={intl.formatMessage({ id: "date" })}
                  >
                    <RangePicker />
                  </Form.Item>

                  <Form.Item>
                    <CustomButton
                      htmlType='submit'
                      text={intl.formatMessage({ id: "search" })}
                      className={`btnRegister btn-text border-8 px-5 `}
                      loading={loading}
                      disabled={loading}
                      type='primary'
                    />
                  </Form.Item>
                </Form>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
      <Table
        columns={columns}
        className='light-shadow'
        pagination={{
          hideOnSinglePage: true,
          pageSize: 20,
        }}
        loading={loading}
        dataSource={formattedOrders}
        scroll={{ x: 500 }}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              if (record._id) {
                navigate(`/orders/${record._id}`, { state: ORDER_TYPES.DINE_IN });
              }
            }
          };
        }}
        rowClassName={() => {
          return "pointer";
        }}
      />
    </div>
  );
};

export default Orders;
