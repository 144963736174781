import React, { useState, useRef, useEffect } from "react";
import { Col, Row, Image, Form, Input, Typography, Space } from "antd";
import { useIntl } from "react-intl";
import LOGO from "../../../assets/images/main-logo.png";
import PlaceHolder from "../../../assets/images/log-in.png";
import ICON from "../../../assets/images/icon-white.png";
import CustomButton from "../../../Components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguageAction } from "../../../redux/reducers/settingsReducer";
import openNotification from "../../../Components/Notifications/index";
import { sendWhatsappSMS, signup } from "../../../API/fetch";
import { signupAction } from "../../../redux/reducers/authReducer";
import { useNavigate } from "react-router";
import { WhatsAppOutlined } from "@ant-design/icons";
const { Text, Link } = Typography;

const VerifyUser = ({ goBack, formValues }) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);

  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [code1, setCode1] = useState("");
  const [code2, setCode2] = useState("");
  const [code3, setCode3] = useState("");
  const [code4, setCode4] = useState("");
  const [timer, setTimer] = useState(30);

  const onFinish = async () => {
    try {
      let refer = "test";
      if (formValues.referralCode) {
        refer = formValues.referralCode;
      }
      const smsCode = code1 + code2 + code3 + code4;
      let data = {
        credentials: {
          phone: formValues.phone,
          name: formValues.name,
          password: formValues.password,
          code: formValues.code,
          password_confirmation: formValues.password,
          smsCode,
          referCode: refer,
          plan: { id: 3 },
        },
      };
      setLoading(true);
      const res = await signup(data);
      setLoading(false);
      dispatch(signupAction(res.data));
      history("/add-restaurant");
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error?.response?.data?.message) {
        if (error.response?.data?.message === "Wrong code number") {
          openNotification({
            title: intl.formatMessage({ id: "error" }),
            description: intl.formatMessage({ id: "errorCode" }),
            type: "error",
            rtl,
          });
        } else {
          openNotification({
            title: intl.formatMessage({ id: "error" }),
            description: error?.response.data.message,
            type: "error",
            rtl,
          });
        }
      } else {
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "errorfetch" }),
          type: "error",
          rtl,
        });
      }
    }
  };

  const sendWhatsappAuth = async () => {
    try {
      console.log(formValues.phone);
      const res = await sendWhatsappSMS({ phone: formValues.phone }, "whats");
      if (res.data.status === "success") {
        openNotification({
          title: intl.formatMessage({ id: "success" }),
          description: intl.formatMessage({ id: "codeSent" }),
          type: "success",
          rtl,
        });
        setTimer(60);
      } else {
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "errorfetch" }),
          type: "error",
          rtl,
        });
      }
    } catch (error) {
      console.log(error);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  // dicrese timer each second
  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prev) => {
        if (prev === 0) {
          clearInterval(interval);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Row style={{ minHeight: "100vh" }}>
      <Col
        xs={24}
        sm={12}
        type='flex'
        justify='center'
        align='center'
        direction='column'
        className={"authRightSide"}
      >
        <div className='changeLanguage'>
          <CustomButton
            text={intl.formatMessage({ id: "oppLanguage" })}
            className={`btn-text border-8 px-5 pb-5`}
            loading={false}
            type='primary'
            onClick={() => {
              dispatch(changeLanguageAction());
            }}
          />
        </div>
        <Row type='flex' justify='center' align='middle'>
          <Col span={18}>
            <Row justify='center'>
              <Image
                preview={false}
                width={300}
                src={LOGO}
                className='logo-Login'
              />
            </Row>

            <Form
              name='basic'
              form={form}
              className='form-container'
              layout='vertical'
              onFinish={onFinish}
            >
              <Row justify='center' align='center' style={{ width: "100%" }}>
                <Col span={24}>
                  <Form.Item
                    name='phone'
                    label={intl.formatMessage({ id: "EnterVerificationCode" })}
                  >
                    <Input.Group size='large'>
                      <Row
                        gutter={[16, 16]}
                        style={{ flexDirection: rtl ? "row-reverse" : "row" }}
                      >
                        <Col span={6}>
                          <Input
                            ref={input1Ref}
                            maxLength={1}
                            onChange={(e) => {
                              if (e.target?.value.length === 1) {
                                setCode1(e.target.value);
                                input2Ref.current.focus();
                              } else {
                                setCode1("");
                              }
                            }}
                          />
                        </Col>
                        <Col span={6}>
                          <Input
                            ref={input2Ref}
                            maxLength={1}
                            onChange={(e) => {
                              if (e.target?.value.length === 1) {
                                setCode2(e.target.value);
                                input3Ref.current.focus();
                              } else {
                                input1Ref.current.focus();
                                setCode2("");
                              }
                            }}
                          />
                        </Col>
                        <Col span={6}>
                          <Input
                            ref={input3Ref}
                            maxLength={1}
                            onChange={(e) => {
                              if (e.target?.value.length === 1) {
                                setCode3(e.target.value);
                                input4Ref.current.focus();
                              } else {
                                input2Ref.current.focus();
                                setCode3("");
                              }
                            }}
                          />
                        </Col>
                        <Col span={6}>
                          <Input
                            ref={input4Ref}
                            maxLength={1}
                            onChange={(e) => {
                              if (e.target?.value.length === 1) {
                                setCode4(e.target.value);
                              } else {
                                input3Ref.current.focus();
                                setCode4("");
                              }
                            }}
                          />
                        </Col>
                      </Row>
                    </Input.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row justify='center' align='middle'>
                <Space
                  size={"middle"}
                  direction='horizontal'
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CustomButton
                    text={intl.formatMessage({ id: "sendAgain" })}
                    className={`btnRegister btn-text border-8`}
                    loading={loading}
                    type='outline'
                    style={{
                      marginBottom: 20,
                      backgroundColor: timer > 0 ? "#ccc" : "#fff",
                    }}
                    onClick={sendWhatsappAuth}
                    disabled={timer > 0}
                    icon={<WhatsAppOutlined />}
                  />
                  <Text
                    type='secondary'
                    style={{
                      position: "relative",
                      bottom: 10,
                    }}
                    className='my-font'
                  >
                    {intl.formatMessage({ id: "resendCode" })} {timer}
                  </Text>
                </Space>
              </Row>

              <Row justify='center' align='middle'>
                <Col span={24}>
                  <Form.Item>
                    <CustomButton
                      htmlType='submit'
                      text={intl.formatMessage({ id: "check" })}
                      className={`btnRegister btn-text border-8`}
                      loading={loading}
                      type='primary'
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Text type='secondary'>
                <Link className='pointer' onClick={goBack}>
                  {intl.formatMessage({ id: "back" })}
                </Link>
              </Text>
            </Form>
          </Col>
        </Row>
      </Col>
      <Col xs={0} sm={12} type='flex' className={"authLeftSide"}>
        <Image
          preview={false}
          src={ICON}
          style={{ maxWidth: 150, marginBottom: 50, borderRadius: 30 }}
        />
        <Image preview={false} src={PlaceHolder} width={"70%"} />
      </Col>
    </Row>
  );
};

export default VerifyUser;
