import React, { useState, useEffect } from "react";
import { Space, Spin, Typography, Form, Select, DatePicker, Col } from "antd";
import { useIntl } from "react-intl";
import COLORS from "../../Style/colors";
import { getRestaurantTipsReports } from "../../API/fetch";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Table } from "ant-table-extensions";
import { FileExcelOutlined } from "@ant-design/icons";
import CustomButton from "../../Components/CustomButton";
import { customerExport } from "../../helpers";
const { Option } = Select;
const boxStyle = {
  display: "flex",
  justifyContent: "space-around",
  backgroundColor: "white",
  padding: "8px 20px",
  boxShadow: "1px 2px 3px lightgray",
  borderRadius: "7px",
};
const { RangePicker } = DatePicker;
const { Title, Text } = Typography;
const TipsReports = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const { restaurant } = useSelector((state) => state.restaurant);
  const [transactions, setTransactions] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // set the default date to be from 5 days ago to today
    const startDate = moment().startOf("day").subtract(5, "days");
    const endDate = moment().endOf("day");
    form.setFieldsValue({
      date: [startDate, endDate],
    });
    getTipsReports({ date: [startDate, endDate] });
  }, []);

  const getTipsReports = async (values) => {
    try {
      setLoading(true);
      setTransactions([]);
      const [start, end] = values.date;
      const startDate = moment(start).startOf("day").toISOString();
      const endDate = moment(end).endOf("day").toISOString();
      let data = {
        start: startDate,
        end: endDate,
      };
      const res = await getRestaurantTipsReports(data);
      if (res.data?.tips?.length) {
        let formatted = res.data.tips?.map((tip) => {
          const amount = tip?.amount;
          const share = tip?.iwaiterSharePercentage;
          const iwaiterShare = (amount * share) / 100;
          const iwaiterShareWithVat = iwaiterShare * 1.15;
          const restaurantShare = amount - iwaiterShareWithVat;
          return {
            key: tip?._id,
            total: restaurantShare?.toFixed(2),
            date: moment(tip?.createdAt).format("DD/MM/YYYY"),
          };
        });
        setTransactions(formatted);
      } else {
        setTransactions([]);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: intl.formatMessage({ id: "amount" }),
      dataIndex: "total",
      key: "total",
    },
    {
      title: intl.formatMessage({ id: "date" }),
      dataIndex: "date",
      key: "date",
      sorter: (a, b) =>
        new Date(a.transactionObject?.createdAt) -
        new Date(b.transactionObject?.createdAt),
      showSorterTooltip: false,
    },
  ];
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <Title level={3}>{intl.formatMessage({ id: "tips" })}</Title>
      </div>
      <div>
        <div style={{ backgroundColor: COLORS.gray }} className='py-4 px-3'>
          <Form
            name='food-form'
            form={form}
            className='form-container'
            layout='inline'
            onFinish={getTipsReports}
            initialValues={{ type: "all" }}
          >
            <Form.Item
              rules={[{ required: true, message: "Please select Date" }]}
              name='date'
              label={intl.formatMessage({ id: "date" })}
            >
              <RangePicker />
            </Form.Item>
            <Form.Item>
              <CustomButton
                htmlType='submit'
                text={intl.formatMessage({ id: "search" })}
                type='primary'
                style={{ borderRadius: 7 }}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
      <Spin spinning={loading}>
        <div
          style={{
            backgroundColor: COLORS.white,
            marginTop: 10,
            width: "92%",
            marginRight: "auto",
            marginLeft: "auto",
            borderRadius: 10,
            padding: 15,
          }}
          className='light-shadow'
        >
          <Space direction='horizontal' size={2}></Space>
          <Table
            title={() => {
              if (transactions.length) {
                return (
                  <Space direction='horizontal' size={"large"}>
                    <Space
                      direction='horizontal'
                      size={"middle"}
                      style={boxStyle}
                    >
                      <Text className='my-font'>
                        {intl.formatMessage({ id: "tips" })}
                      </Text>
                      <Text className='my-font'>{transactions?.length}</Text>
                    </Space>

                    <Space
                      direction='horizontal'
                      size={"middle"}
                      style={boxStyle}
                    >
                      <Text className='my-font'>
                        {intl.formatMessage({ id: "totalAmounts" })}
                      </Text>
                      <Text className='my-font'>
                        {getReportTotal(transactions)?.toFixed(2)} SAR
                      </Text>
                    </Space>
                  </Space>
                );
              }
            }}
            exportable
            exportableProps={{
              showColumnPicker: true,
              btnProps: {
                icon: <FileExcelOutlined />,
                type: "primary",
                style: { borderRadius: 7 },
                onClick: () =>
                  customerExport({
                    data: transactions,
                    columns: columns,
                    fileName: "Transactions Report",
                    loadingSetter: setLoading,
                  }),
              },
              children: (
                <Text
                  className='my-font'
                  style={{
                    color: COLORS.white,
                  }}
                >
                  {intl.formatMessage({ id: "export" })}
                </Text>
              ),
            }}
            pagination={{
              hideOnSinglePage: true,
              pageSizeOptions: [10, 20, 50, 100, 1000],
            }}
            dataSource={transactions}
            scroll={{ x: 500 }}
            columns={columns}
            style={{ borderRadius: 10 }}
          />
        </div>
      </Spin>
    </div>
  );
};

export default TipsReports;


const getReportTotal = (data) => {
  let total = 0;
  data?.forEach((item) => {
    total += parseFloat(item?.total);
  });
  return total;
}