import React, { useEffect, useState } from "react";
import {
  Badge,
  Table,
  Tabs,
  Tag,
  Form,
  DatePicker,
  Typography,
  Space,
} from "antd";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import {
  ORDER_TYPES,
  PICKUP_ORDERS_STATUS,
  PICKUP_ORDERS_STATUS_NO_PENDING,
  TAG_COLORS_BY_PAYMENT_STATUS,
  TAG_COLORS_BY_STATUS_PICKUP,
} from "../../Types";
import COLORS from "../../Style/colors";
import moment from "moment";
import { postSearchOrders } from "../../API/fetch";
import CustomButton from "../../Components/CustomButton";
import { getDiscountAmount } from "../../helpers";
import { IoCarSportOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
const { RangePicker } = DatePicker;
const { Title, Text } = Typography;
const { TabPane } = Tabs;
const ScheduleOrder = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { rtl } = useSelector((state) => state.settings);
  const { pickupOrders } = useSelector((state) => state.order);
  const { allBranches } = useSelector((state) => state.branch);
  const { role, waiter } = useSelector((state) => state.auth);
  const [formattedOrders, setFormattedOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(PICKUP_ORDERS_STATUS.OPEN);
  const [showFilter, setShowFilter] = useState(false);
  const [searchOrders, setSearchOrders] = useState([]);
  const [showPendingStatus, setShowPendingStatus] = useState(false)
  const [form] = Form.useForm();

  useEffect(() => {
    // check if we have query params;
    const searchParams = new URLSearchParams(window.location.search);
    const tap = searchParams.get("tap");
    if (tap) {
      setActiveTab(tap);
    } else {
      // add query params;
      changeQueryParams(activeTab);
    }
  }, []);

  useEffect(() => {
    if (role === "waiter") {
      let isAutoAccept = allBranches?.[0]?.autoAcceptPickup;
      if (!isAutoAccept) {
        setShowPendingStatus(true);
      }
    } else {
      setShowPendingStatus(true);
    }
  }, [allBranches]);

  const changeQueryParams = (tap) => {
    navigate(`/scheduleOrder?tap=${tap}`);
  };
  useEffect(() => {
    setLoading(true);
    if (pickupOrders?.length || searchOrders.length) {
      let sourceOrders = pickupOrders;
      if (searchOrders?.length) {
        sourceOrders = searchOrders;
      }
      // filter pickup time;
      let filteredOrders = sourceOrders?.filter((order) => {
        return order?.timeForPickup;
      });
      // sort by pickup time;
      filteredOrders = filteredOrders?.sort((a, b) => {
        return (
          new Date(a?.timeForPickup) - new Date(b?.timeForPickup) ||
          new Date(a?.createdAt) - new Date(b?.createdAt)
        );
      });
      const newFormattedOrders = filteredOrders
        .filter((o) => {
          return (
            activeTab === PICKUP_ORDERS_STATUS.ALL || o.status === activeTab
          );
        })
        .map((order) => {
          const branch = allBranches?.find(
            (b) => b?.branch?._id === order?.branchId
          );
          let orderNumber = "#P N/A";
          if (order?.orderNumber) {
            orderNumber = "#P " + order?.orderNumber;
          }
          let finalTotal = order.total;
          if (order.couponCode && order.couponCode._id) {
            const discount = getDiscountAmount({
              total: order.total,
              percentage: order.couponCode.percentage,
              maximumAmount: order.couponCode.maximumAmount,
            });
            finalTotal -= discount;
          }
          let reservationNumber = "-";
          if (order?.reservation?._id) {
            // get last 4 digits of the id and make uppercase;
            reservationNumber = order?.reservation?._id
              ?.slice(-5)
              .toUpperCase();
          }
          return {
            key: order?._id,
            _id: order?._id,
            orderNumber: orderNumber,
            branch: rtl ? branch?.branch?.name : branch?.branch?.name_en,
            status: order?.status,
            total: finalTotal?.toFixed ? finalTotal?.toFixed(2) : "N/A",
            paymentStatus: order?.paymentStatus,
            pickupTime: order?.timeForPickup,
            reservationNumber: reservationNumber,
            customerArrived:
              order?.deliverToCar?.status === "customerArrived" ? true : false,
            time: new Date(
              order?.createdAt ? order.createdAt : new Date()
            ).toLocaleString(),
            orderObject: order,
          };
        });
      setFormattedOrders(newFormattedOrders);
    }
    setLoading(false);
  }, [pickupOrders, activeTab]);

  const columns = [
    {
      title: intl.formatMessage({ id: "orderNumber" }),
      dataIndex: "orderNumber",
      key: "orderNumber",
      render: (text, record) => (
        <Space>
          <Text className='my-font'>{text}</Text>
          {record.customerArrived ? (
            <IoCarSportOutline
              className='mx-1'
              size={20}
              style={{ position: "relative", top: 5, color: COLORS.danger }}
            />
          ) : null}
        </Space>
      ),
    },
    {
      title: intl.formatMessage({ id: "orderStatus" }),
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return (
          <Tag color={TAG_COLORS_BY_STATUS_PICKUP[status]}>
            {intl.formatMessage({
              id:
                status === PICKUP_ORDERS_STATUS.CLOSE
                  ? "closedOrder"
                  : status
                  ? status
                  : "n/a",
            })}
          </Tag>
        );
      },
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      filters: [
        {
          text: intl.formatMessage({ id: PICKUP_ORDERS_STATUS.OPEN }),
          value: PICKUP_ORDERS_STATUS.OPEN,
        },
        {
          text: intl.formatMessage({ id: PICKUP_ORDERS_STATUS.IN_KITCHEN }),
          value: PICKUP_ORDERS_STATUS.IN_KITCHEN,
        },
        {
          text: intl.formatMessage({ id: PICKUP_ORDERS_STATUS.READY }),
          value: PICKUP_ORDERS_STATUS.READY,
        },
        {
          text: intl.formatMessage({ id: "closedOrder" }),
          value: PICKUP_ORDERS_STATUS.CLOSE,
        },
      ],
    },
    {
      title: intl.formatMessage({ id: "total" }),
      dataIndex: "total",
      key: "total",
    },
    {
      title: intl.formatMessage({ id: "paymentStatus" }),
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      render: (status) => {
        return (
          <Tag color={TAG_COLORS_BY_PAYMENT_STATUS[status]}>
            {intl.formatMessage({
              id: status ? status : "N/A",
            })}
          </Tag>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "pickupTime" }),
      dataIndex: "pickupTime",
      key: "pickupTime",
      render: (time) => {
        if (time) {
          return moment(time).format("YYYY-MM-DD hh:mm A");
        } else {
          return "N/A";
        }
      },
      sorter: (a, b) =>
      new Date(a?.time) - new Date(b?.time),
    showSorterTooltip: false,
    },
    {
      title: intl.formatMessage({ id: "time" }),
      dataIndex: "time",
      key: "time",
      sorter: (a, b) =>
        new Date(a.orderObject?.createdAt) - new Date(b.orderObject?.createdAt),
      showSorterTooltip: false,
    },
  ];

  if (role === "super") {
    columns.splice(1, 0, {
      title: intl.formatMessage({ id: "branch" }),
      dataIndex: "branch",
      key: "branch",
      onFilter: (value, record) => value === record.orderObject?.branchId,
      filters: allBranches?.map(({ branch }) => {
        return {
          text: rtl ? branch?.name : branch?.name_en,
          value: branch?._id,
        };
      }),
    });
  }
  if (formattedOrders?.some((order) => order?.reservationNumber)) {
    columns.splice(1, 0, {
      title: intl.formatMessage({ id: "reservationNumber" }),
      dataIndex: "reservationNumber",
      key: "reservationNumber",
      render: (text) => <Tag  className='my-font'> {text !== '-' ? `#${text}` : text}</Tag>,
    });
  }

  const onFinish = async (values) => {
    const [start, end] = values.date;
    const startDate = moment(start).set({ hour: 0, minute: 0, second: 0 });
    const endDate = moment(end).set({ hour: 23, minute: 59, second: 59 });

    fetchOrders(startDate, endDate, 1);
  };

  const fetchOrders = async (startDate, endDate, page) => {
    try {
      setLoading(true);
      const data = {
        orderType: "pickup",
        dateStart: startDate,
        dateEnd: endDate,
        code: waiter.code,
        paymentStatus: "all",
        page: page,
      };
      const res = await postSearchOrders(data);

      if (res.data?.orders?.length) {
        const sorted = [...res.data.orders];
        let sortedOrders = sorted.sort((a, b) => {
          return (
            new Date(a?.timeForPickup) - new Date(b?.timeForPickup) ||
            new Date(a?.createdAt) - new Date(b?.createdAt)
          );
        });

        const formattedOrders = sortedOrders
          .filter((o) => {
            return (
              (activeTab === PICKUP_ORDERS_STATUS.ALL ||
                o.status === activeTab) &&
              o.timeForPickup
            );
          })
          .map((order) => {
            const branch = allBranches?.find(
              (b) => b?.branch?._id === order?.branchId
            );
            let orderNumber = "#P N/A";
            if (order?.orderNumber) {
              orderNumber = "#P " + order?.orderNumber;
            }
            let finalTotal = order.total;
            if (order.couponCode && order.couponCode._id) {
              const discount = getDiscountAmount({
                total: order.total,
                percentage: order.couponCode.percentage,
                maximumAmount: order.couponCode.maximumAmount,
              });
              finalTotal -= discount;
            }
            return {
              key: order?._id,
              _id: order?._id,
              orderNumber: orderNumber,
              pickupTime: order?.timeForPickup,
              customerArrived:
                order?.deliverToCar?.status === "customerArrived"
                  ? true
                  : false,
              branch: rtl ? branch?.branch?.name : branch?.branch?.name_en,
              status: order?.status,
              total: finalTotal?.toFixed ? finalTotal?.toFixed(2) : "N/A",
              paymentStatus: order?.paymentStatus,
              time: moment(order?.createdAt).format("YYYY-MM-DD hh:mm A"),
              orderObject: order,
            };
          });
        setSearchOrders(sortedOrders);
        setFormattedOrders(formattedOrders);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      {role === "super" ? (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 10,
            }}
          >
            <Title level={4}>
              {intl.formatMessage({ id: "scheduleOrder" })}
            </Title>
            <CustomButton
              text={intl.formatMessage({
                id: showFilter ? "hide" : "filter",
              })}
              type='primary'
              className='border-8'
              onClick={() => setShowFilter(!showFilter)}
            />
          </div>
          {showFilter ? (
            <div>
              <div
                style={{ backgroundColor: COLORS.gray }}
                className='py-4 px-3'
              >
                <Form
                  name='food-form'
                  form={form}
                  className='form-container'
                  layout='inline'
                  onFinish={onFinish}
                >
                  <Form.Item
                    rules={[{ required: true, message: "Please select Date" }]}
                    name='date'
                    label={intl.formatMessage({ id: "date" })}
                  >
                    <RangePicker />
                  </Form.Item>

                  <Form.Item>
                    <CustomButton
                      htmlType='submit'
                      text={intl.formatMessage({ id: "search" })}
                      className={`btnRegister btn-text border-8 px-5 `}
                      loading={loading}
                      disabled={loading}
                      type='primary'
                    />
                  </Form.Item>
                </Form>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
      <Tabs
        centered
        activeKey={activeTab}
        onTabClick={(e) => {
          changeQueryParams(e);
          setActiveTab(e);
        }}
        type='card'
        style={{ minHeight: "75vh" }}
      >
        {Object.values(
          showPendingStatus
            ? PICKUP_ORDERS_STATUS
            : PICKUP_ORDERS_STATUS_NO_PENDING
        )?.map((status) => {
          let length = pickupOrders?.filter((o) => o.timeForPickup).length;
          if (searchOrders?.length) {
            length = searchOrders?.filter((o) => o.timeForPickup).length;
          }
          if (status !== PICKUP_ORDERS_STATUS.ALL) {
            if (searchOrders?.length) {
              length = searchOrders?.filter(
                (order) => order.status === status && order.timeForPickup
              ).length;
            } else {
              length = pickupOrders?.filter(
                (order) => order.status === status && order.timeForPickup
              ).length;
            }
          }
          return (
            <TabPane
              key={status}
              className='light-shadow'
              style={{
                minHeight: "75vh",
              }}
              tab={
                <Badge
                  count={length}
                  style={{ backgroundColor: COLORS.primary }}
                >
                  <span className='px-3 my-font'>
                    {intl.formatMessage({
                      id: status === "close" ? "completed" : status,
                    })}
                  </span>
                </Badge>
              }
            >
              <Table
                columns={columns}
                pagination={{
                  hideOnSinglePage: true,
                  pageSize: 20,
                }}
                style={{ marginTop: "-17px" }}
                loading={loading}
                dataSource={formattedOrders}
                scroll={{ x: 1000 }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: () => {
                      if (record._id) {
                        navigate(`/scheduleOrder/${record._id}`, {
                          state: ORDER_TYPES.PICKUP,
                        });
                      }
                    },
                  };
                }}
                rowClassName={() => {
                  return "pointer";
                }}
              />
            </TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};

export default ScheduleOrder;
