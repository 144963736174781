import moment from "moment";

export const formatReservations = (reservations, openSeat) => {
    const newReservations = [];
    reservations.forEach(reservation => {
        if (reservation?.isWithHours) {
            const numberOfMinutes = reservation?.numberOfHours * 60;
            const numberOfReservation = numberOfMinutes / parseInt(openSeat);
            for (let i = 0; i < numberOfReservation; i++) {
                const newReservation = {
                    ...reservation,
                    date: new Date(reservation?.date),
                    end: new Date(new Date(reservation?.date).getTime() + (openSeat - 1) * 60000),
                };
                if (i > 0) {
                    newReservation?.date?.setMinutes(newReservation.date.getMinutes() + parseInt(openSeat) * i);
                    newReservation?.end?.setMinutes(newReservation.end.getMinutes() + parseInt(openSeat) * i);
                }
                newReservations?.push(newReservation);
            }
        } else {
            const newReservation = {
                ...reservation,
                date: new Date(reservation?.date),
                end: new Date(new Date(reservation?.date).getTime() + (openSeat - 1) * 60000),
            };
            newReservations.push(newReservation);
        }
    });
    return newReservations;
};

export const getTheTableIdForReservation = (availableTables, bookedTables, personsCount) => {
    const tablesId = availableTables?.filter
      (t => !bookedTables?.includes
        (t?._id) && t.numberOfChairs >= personsCount).sort(
          (a, b) => a?.numberOfChairs - b?.numberOfChairs)
    return tablesId[0]?._id
  }
  
  export const checkRangeReservations = (checkStart, checkEnd, start, openSeat) => {
    const startDate = moment(start);
    const endDate = moment(startDate).add(openSeat - 1, 'minutes');
    const checkStartDate = moment(checkStart);
    const checkEndDate = moment(checkEnd);
    let checkAvailabe = false
    if (checkEndDate?.isBetween(startDate, endDate, null, '[]') || checkStartDate?.isBetween(startDate, endDate, null, '[]')) {
      checkAvailabe = true
    }
    return checkAvailabe
  }

 export  const getZonesForLinkToTable = (reservationSchema, zones) => {
    const schemaZones = reservationSchema.filter(res => res?.isLinkTables === true).map(element => element?.linkedZones)
    const concat = Array.from(new Set([].concat(...schemaZones)))
    const result = zones?.filter((element) => !concat?.includes(element?.name))
    return result
}


export function filterReservationsForTable(tableId, reservations) {
    const filteredReservations = [];
    reservations?.forEach((reservation) => {
      if (reservation?.bookedTableId) {
        if (reservation?.bookedTableId == tableId) {
          filteredReservations.push(reservation);
        }
      } else {
        filteredReservations.push(reservation);
      }
    });

    return filteredReservations;
  }


   // helper function
   const getNextClosestDate = (dates, selected) => {
    const sortedDates = dates?.map((item) => moment(item?.reservationDate))?.sort((a, b) => a.diff(selected) - b.diff(selected));
    const closestDate = sortedDates?.find((date) => date?.isSameOrAfter(selected));
    return dates?.find((item) => moment(item?.reservationDate)?.isSame(closestDate));
  };

  // reservation?.reservationDate
  export const getAvailableHours = (reservation, schema, allUsers, date) => {
    const closestDateElement = getNextClosestDate(allUsers?.filter(user => user?.reservationDate != reservation?.reservationDate), moment(date))
    const reservationDate = moment(reservation?.reservationDate)
    let theNextReservationDate
    if (closestDateElement) {
      theNextReservationDate = moment(closestDateElement?.reservationDate)
    } else {
      const resDate = moment(reservation?.reservationDate)
      const endTimeDate = resDate?.set({
        hours: parseInt(schema?.endTime?.split(":")[0]),
        minutes: parseInt(schema?.endTime?.split(":")[1])
      })
      theNextReservationDate = endTimeDate
    }
    const duration = moment.duration(theNextReservationDate?.diff(reservationDate));
    const hoursDiff = duration?.asHours();
    let result = 0
    if (reservation?.isWithHours) {
      result = hoursDiff - reservation?.numberOfHours
    }
    return Array.from({ length: result.toFixed(0) }, (_, index) => index)
  }