import React, { useEffect, useState } from "react";
import { Tag, Typography, DatePicker, Form, Select, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { CUSTOMER_TYPE, ORDER_TYPES, TAG_COLOR_BY_USER_TYPE } from "../../Types";
import { Table } from "ant-table-extensions";
import CustomButton from "../../Components/CustomButton";
import COLORS from "../../Style/colors";
import moment from "moment";
import { postSearchUsers } from "../../API/fetch";
import { FileExcelOutlined, SearchOutlined, TrophyFilled } from "@ant-design/icons";
import { customerExport } from "../../helpers";
import ReportSummary from "./components/ReportSummary";
import CustomerTypeSettings from "./components/CustomerTypeSettings";
import UserDetails from "./components/UserDetails";


const { RangePicker } = DatePicker;

const { Text, Title } = Typography;
const { Option } = Select
const AllUsers = () => {
  const intl = useIntl();
  const { waiter } = useSelector((state) => state.auth);
  const { rtl } = useSelector((state) => state.settings);
  const { restaurant } = useSelector((state) => state.restaurant)
  const { allBranches } = useSelector((state) => state.branch);
  const [formattedUsers, setFormattedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clientType, setClientType] = useState("all")
  const [selectedUser, setSelectedUser] = useState(null)
  const [showUserDetails, setShowUserDetails] = useState(false)
  const [form] = Form.useForm();

  useEffect(() => {
    // fetch all users starting from 5 days ago till now;
    const startDate = moment().subtract(5, "days").set({ hour: 0, minute: 0, second: 0 });
    const endDate = moment().set({ hour: 23, minute: 59, second: 59 });
    fetchUsers(startDate, endDate, 1, "all" ,"all");
    // set the form to the default values;
    form.setFieldsValue({
      date: [startDate, endDate],
      type: "all", 
      badge:"all"
    });
  }, []);


  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
    },
    {
      title: intl.formatMessage({ id: "branch" }),
      dataIndex: "branch",
      key: "branch",
      onFilter: (value, record) => value === record.userObject?.branchId,
      filters: allBranches?.map(({ branch }) => {
        return {
          text: rtl ? branch.name : branch.name_en,
          value: branch._id,
        };
      }),
    },
    {
      title: intl.formatMessage({ id: "customerName" }),
      dataIndex: "name",
      key: "name",
    },
    {
      title: intl.formatMessage({ id: "phone" }),
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: intl.formatMessage({ id: "serviceType" }),
      dataIndex: "types",
      key: "types",
      render: (types) => {
        return (
          <>
            {types.map((type) => {
              return (
                <Tag color={TAG_COLOR_BY_USER_TYPE[type]} key={type}>
                  {intl.formatMessage({ id: type ? type : "user" })}
                </Tag>
              );
            })}
          </>
        );
      },
      onFilter: (value, record) =>
        record.userObject.types.indexOf(value) !== -1,
      filters: [
        {
          text: intl.formatMessage({ id: ORDER_TYPES.DINE_IN }),
          value: ORDER_TYPES.DINE_IN,
        },
        {
          text: intl.formatMessage({ id: ORDER_TYPES.PICKUP }),
          value: ORDER_TYPES.PICKUP,
        },
        {
          text: intl.formatMessage({ id: ORDER_TYPES.DELIVERY }),
          value: ORDER_TYPES.DELIVERY,
        },
        {
          text: intl.formatMessage({ id: "waiting" }),
          value: "waiting",
        },
        {
          text: intl.formatMessage({ id: "reservation" }),
          value: "reservation",
        },
      ],
    },
    {
      title: intl.formatMessage({ id: "userType" }),
      dataIndex: "badge",
      key: "badge",
      render: (badge) => {
        return (
          <Tooltip placement="topLeft" title={intl.formatMessage({ id: badge })}>
            <TrophyFilled
              style={{
                color: badge === CUSTOMER_TYPE.BRONZE ? "#A9907E"
                  : badge === CUSTOMER_TYPE.SILVER ? "#9BA4B5"
                    : badge === CUSTOMER_TYPE.GOLD ? "#FFD93D"
                      : "#212A3E",
                fontSize: "1.3rem",
                cursor: "pointer"
              }} />
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "visitCount" }),
      dataIndex: "visitCount",
      key: "visitCount",
      sorter: (a, b) => a.visitCount - b.visitCount,
    },

    {
      title: intl.formatMessage({ id: "time" }),
      dataIndex: "time",
      key: "time",
      sorter: (a, b) =>
        new Date(a.userObject?.createdAt) - new Date(b.userObject?.createdAt),
      showSorterTooltip: false,
    },
  ];

  const onFinish = async (values) => {
    const [start, end] = values.date;
    const startDate = moment(start).set({ hour: 0, minute: 0, second: 0 });
    const endDate = moment(end).set({ hour: 23, minute: 59, second: 59 });
    let type
    if (values.type) {
      type = values.type
      setClientType(values.type)
    } else {
      type = "all"
    }
    let badge
    if (values.badge) {
      badge = values.badge
      setClientType(values.badge)
    } else {
      badge = "all"
    }
    fetchUsers(startDate, endDate, 1, type , badge);
  };


  const fetchUsers = async (startDate, endDate, page, type, badge) => {
    try {
      setLoading(true);
      const data = {
        userType: type,
        userBadge: badge,
        dateStart: startDate,
        dateEnd: endDate,
        code: waiter.code,
      };

      const res = await postSearchUsers(data);
      if (res.data?.users?.length) {
        const sorted = [...res.data.users];
        let sortedOrders = sorted.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        const formattedUsers = sortedOrders?.map((user, i) => {
          const branch = allBranches?.find(
            (b) => b?.branch?._id === user?.branchId
          );

          return {
            key: user?._id,
            number: i + 1,
            _id: user?._id,
            branch: rtl ? branch?.branch?.name : branch?.branch?.name_en,
            types: user?.types,
            visitCount: user?.visitCount,
            badge: user?.badge,
            name: user?.name,
            phone: user?.phone,
            time: new Date(
              user?.createdAt ? user.createdAt : new Date()
            ).toLocaleString(),
            userObject: {
              ...user,
              branchName: rtl ? branch?.branch?.name : branch?.branch?.name_en,
            },
          };
        });
        setFormattedUsers(formattedUsers);
      } else {
        setFormattedUsers([])
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const [showCustomerTypeSettings, setShowCustomerTypeSettings] = useState(false);

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <Title level={3}>{intl.formatMessage({ id: "users" })}</Title>
        <CustomerTypeSettings
          show={showCustomerTypeSettings}
          hide={() => {
            setShowCustomerTypeSettings(false);
          }}
          types={restaurant?.customerTypes}
          restaurant={restaurant}
          fetchUsers={fetchUsers}
          filterForm={form}
        />
        <UserDetails 
         show={showUserDetails}
         hide={() => {
           setShowUserDetails(false);
         }}
         user={selectedUser}
         
        />
        <CustomButton
          text={intl.formatMessage({
            id: "customerTypeSettings",
          })}
          type='primary'
          className='border-8'
          onClick={() => setShowCustomerTypeSettings(true)}
        />
      </div>
      <div>
        <div style={{ backgroundColor: COLORS.gray }} className='py-4 px-3'>
          <Form
            name='food-form'
            form={form}
            className='form-container'
            layout='inline'
            onFinish={onFinish}
          >
            <Form.Item
              rules={[{ required: true, message: "Please select Date" }]}
              name='date'
              label={intl.formatMessage({ id: "date" })}
              
            >
              <RangePicker
              onChange={(date, dateString) => {
                // if the start date change reset the end date to be 50 days after start date;
                const lastOption = moment(date[0]).add(50, "days");
                let end = moment(date[1]);
                if(date[1].isAfter(lastOption)){
                   end = moment(date[0]).add(50, "days");
                }
                form.setFieldsValue({
                  date: [date[0], end],
                })
              }}
                disabledDate={(current) => {
                  // disabled all dates that are after start date + 50;
                  return (
                    current &&
                    current >
                    moment(form.getFieldValue("date")[0]).add(50, "days")
                  );
                }}
              />
            </Form.Item>
            <Form.Item
              placeholder="Please select type"
              name='type'
              label={intl.formatMessage({ id: "serviceType" })}
              style={{ width: "25%" }}
            >
              <Select style={{ width: "100%" }}
                className='my-font'
                placeholder={intl.formatMessage({ id: "serviceType" })}
                defaultValue="all">
                <Option className='my-font' value='all' >
                  {intl.formatMessage({ id: "all" })}
                </Option>
                <Option className='my-font' value='inHouse'>
                  {intl.formatMessage({ id: "inHouse" })}
                </Option>
                <Option className='my-font' value='pickup'>
                  {intl.formatMessage({ id: "pickup" })}
                </Option>

                <Option className='my-font' value='delivery'>
                  {intl.formatMessage({ id: "delivery" })}
                </Option>
                <Option className='my-font' value="reservations">
                  {intl.formatMessage({ id: "reservation" })}
                </Option>
                <Option className='my-font' value="waitingList">
                  {intl.formatMessage({ id: "waiting" })}
                </Option>
                <Option className='my-font' value="CUSTOM_ORDER">
                  {intl.formatMessage({ id: "CUSTOM_ORDER" })}
                </Option>
              </Select>
            </Form.Item>
            <Form.Item
              placeholder="Please select type"
              name='badge'
              label={intl.formatMessage({ id: "userType" })}
              style={{ width: "25%" }}
            >
              <Select style={{ width: "100%" }}
                className='my-font'
                placeholder={intl.formatMessage({ id: "userType" })}
                defaultValue="">
                  <Option className='my-font' value={'all'} >
                        {intl.formatMessage({ id: 'all' })}
                      </Option>
                  {Object.keys(CUSTOMER_TYPE).map((key) => {
                    return (
                      <Option className='my-font' value={CUSTOMER_TYPE[key]} >
                        {intl.formatMessage({ id: CUSTOMER_TYPE[key] })}
                      </Option>
                    )
                  })}
              </Select>
            </Form.Item>
            <Form.Item>
              <CustomButton
                htmlType='submit'
                text={intl.formatMessage({ id: "search" })}
                className={`btnRegister btn-text border-8 px-5 `}
                loading={loading}
                disabled={loading}
                type='primary'
              />
            </Form.Item>
          </Form>
        </div>
      </div>
      <Table
        exportable
        searchable
        title={() => {
          if (formattedUsers.length) {
            return <ReportSummary amounts={formattedUsers} filter={clientType} />
          }
        }
        }
        searchableProps={{
          fuzzySearch: true,
          inputProps: {
            placeholder: "Search this table...",
            prefix: <SearchOutlined />,
            disabled: loading || formattedUsers?.length === 0,
          },
        }}
        exportableProps={{
          showColumnPicker: true,
          disabled: loading || formattedUsers?.length === 0,
          btnProps: {
            icon: <FileExcelOutlined />,
            type: "primary",
            style: { borderRadius: 7 },
            onClick: () =>
              customerExport({
                data: formattedUsers,
                columns,
                fileName: "Users Report",
                loadingSetter: setLoading,
              }),
          },
          children: (
            <Text
              className='my-font'
              style={{
                color: formattedUsers?.length === 0 ? "#dddddd" : COLORS.white,
              }}
            >
              {intl.formatMessage({ id: "export" })}
            </Text>
          ),
        }}
        columns={columns}
        className='light-shadow'
        pagination={{
          hideOnSinglePage: true,
        }}
        loading={loading}
        dataSource={formattedUsers}
        scroll={{ x: 500 }}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              setSelectedUser(record.userObject)
              setShowUserDetails(true)
            }
          };
        }}
        rowClassName={() => {
          return "pointer";
        }}
      />
    </div>
  );
};

export default AllUsers;
