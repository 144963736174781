import { audio } from "../Audion";
import openNotification from "../Notifications";

export const toggleAlert = ({
  status,
  table,
  orderId = null,
  tables,
  waiter,
  rtl,
}) => {
  let alert = false;
  let tableReceivedNewOrder = "";
  if (status === "start") {
    const waiterTables = tables
      .filter(
        (t) =>
          waiter.dineInSections?.includes(t.zone) ||
          waiter.dineInSections?.includes("All")
      )
      ?.map((t) => t._id);

    if (table === "pickup" && waiter.receivePickup === true) {
      alert = true;
      tableReceivedNewOrder = "pickup";

      audio
        .play()
        .then(() => console.log("Playing"))
        .catch((err) => {
          if (rtl) {
            openNotification({
              type: "error",
              description:
                "الرجاء الضغط على زر الاشعارات في الشريط الجانبي لاستقبال الإشعارات",
              rtl,
              title: "خطأ",
            });
          } else {
            openNotification({
              type: "error",
              description:
                "please click on the notification bill on the side menu to start receiving notifications",
              rtl,
              title: "Error",
            });
          }
        });
      audio.loop = true;
      audio.volume = 1;
      return;
    } else if (table === "customerArrived" && waiter.receivePickup === true) {
      alert = true;
      tableReceivedNewOrder = `customerArrived-${orderId}`;

      audio
        .play()
        .then(() => console.log("Playing"))
        .catch((err) => {
          if (rtl) {
            openNotification({
              type: "error",
              description:
                "الرجاء الضغط على زر الاشعارات في الشريط الجانبي لاستقبال الإشعارات",
              rtl,
              title: "خطأ",
            });
          } else {
            openNotification({
              type: "error",
              description:
                "please click on the notification bill on the side menu to start receiving notifications",
              rtl,
              title: "Error",
            });
          }
        });
      audio.loop = true;
      audio.volume = 1;
      return;
    } else if (table === "delivery" && waiter.receiveDelivery === true) {
      alert = true;
      tableReceivedNewOrder = "delivery";

      audio
        .play()
        .then(() => console.log("Playing"))
        .catch((err) => {
          if (rtl) {
            openNotification({
              type: "error",
              description:
                "الرجاء الضغط على زر الاشعارات في الشريط الجانبي لاستقبال الإشعارات",
              rtl,
              title: "خطأ",
            });
          } else {
            openNotification({
              type: "error",
              description:
                "please click on the notification bill on the side menu to start receiving notifications",
              rtl,
              title: "Error",
            });
          }
        });
      audio.loop = true;
      audio.volume = 1;
      return;
    } else if (table === "iwaiterApp" && waiter.receiveIWaiterApp === true) {
      alert = true;
      tableReceivedNewOrder = "iwaiterApp";

      audio
        .play()
        .then(() => console.log("Playing"))
        .catch((err) => {
          if (rtl) {
            openNotification({
              type: "error",
              description:
                "الرجاء الضغط على زر الاشعارات في الشريط الجانبي لاستقبال الإشعارات",
              rtl,
              title: "خطأ",
            });
          } else {
            openNotification({
              type: "error",
              description:
                "please click on the notification bill on the side menu to start receiving notifications",
              rtl,
              title: "Error",
            });
          }
        });
      audio.loop = true;
      audio.volume = 1;
      return;
    } else {
      let tableReceivedNewOrderTmp = tables.find((t) => t._id === table);
      if (waiterTables?.includes(table)) {
        alert = true;
        tableReceivedNewOrder = tableReceivedNewOrderTmp;
        try {
          audio
            .play()
            .then(() => console.log("Playing"))
            .catch((err) => {
              if (rtl) {
                openNotification({
                  type: "error",
                  description:
                    "الرجاء الضغط على زر الاشعارات في الشريط الجانبي لاستقبال الإشعارات",
                  rtl,
                  title: "خطأ",
                });
              } else {
                openNotification({
                  type: "error",
                  description:
                    "please click on the notification bill on the side menu to start receiving notifications",
                  rtl,
                  title: "Error",
                });
              }
            });
          audio.loop = true;
          audio.volume = 1;
          return;
        } catch (error) {
          console.log("Error", error);
        }
      }
    }
    // audio.play();
    // audio.loop = true;
    // audio.volume =1
  } else {
    alert = false;
    audio.pause();
  }
  return {
    alert,
    tableReceivedNewOrder,
  };
};
