import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import COLORS from "../../../Style/colors";
import moment from "moment";
import { Typography, Space, Spin, List, Avatar } from "antd";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { cutText } from "../../../helpers";
const { Title, Text } = Typography;

const CommentsCard = () => {
  const intl = useIntl();
  const { foods } = useSelector((state) => state.menu);
  const { rtl } = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState([]);
  useEffect(() => {
    if (foods.length) {
      let format = [];
      foods?.forEach((food) => {
        if (food?.comments?.length) {
          food.comments.forEach((comment) => {
            if (comment?.status === "Pending") {
              format.push({
                foodName: rtl ? food?.name : food?.name_en,
                status: comment?.status,
                key: comment?._id,
                comment: comment?.text,
                customerName: comment?.customerId?.name,
                time: new Date(
                  comment?.createdAt ? comment.createdAt : new Date()
                ).toLocaleString(),
              });
            } else {
              format.push({
                foodName: rtl ? food?.name : food?.name_en,
                status: comment?.status,
                key: comment?._id,
                comment: comment?.text,
                customerName: comment?.customerId?.name,
                time: new Date(
                  comment?.createdAt ? comment.createdAt : new Date()
                ).toLocaleString(),
              });
            }
          });
        }
      });

      let three = format.slice(format.length - 3);

      setComments(three);
    } else {
      setComments([]);
    }
  }, [foods, rtl]);
  return (
    <div>
      <Spin spinning={loading}>
        <Space
          direction='horizontal'
          style={{ width: "100%", justifyContent: "space-between" }}
        >
          <Title style={{ marginBottom: 0, paddingBottom: 0 }} level={4}>
            {intl.formatMessage({ id: "comments" })}
          </Title>
          <Link to={`/comments`}>
            <Title
              style={{
                marginBottom: 0,
                paddingBottom: 0,
                marginTop: 0,
                paddingTop: 0,
              }}
              level={5}
            >
              {intl.formatMessage({ id: "seeAll" })}
            </Title>
          </Link>
        </Space>
        <List
          itemLayout='horizontal'
          dataSource={comments}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar
                    style={{
                      verticalAlign: "middle",
                    }}
                  >
                    {item?.customerName?.charAt(0)}
                  </Avatar>
                }
                title={item?.customerName}
                description={
                  <Text className='my-font'>{cutText(item?.comment, 80)}</Text>
                }
              />
            </List.Item>
          )}
        />
      </Spin>
    </div>
  );
};

export default CommentsCard;
