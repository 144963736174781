import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  InputNumber,
  DatePicker,
  Typography,
  Divider,
  Space,
  Row,
  Col,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import CustomButton from "../../../Components/CustomButton";
import openNotification from "../../../Components/Notifications";
import moment from "moment";
import {
  getAllCouponsForRestaurant,
  postAddNewCoupon,
} from "../../../API/fetch";
import { addCouponAction, addMultiplesCouponsAction } from "../../../redux/reducers/restaurantReducer";
import { getRandomCodes } from "../../../helpers/coupons";

const { Text } = Typography;

const AddRandomCoupons = ({ show, hide }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const { waiter } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [allCodes, setAllCodes] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    getAllCoupons();
  }, [show]);

  const getAllCoupons = async () => {
    try {
      const res = await getAllCouponsForRestaurant();
      if (res.data.coupons) {
        setAllCodes(res.data.coupons.map((coupon) => coupon.code));
      }
    } catch (error) {
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };
  const addNewCoupon = async (values) => {
    try {
      let startDate = moment(values.startDate).startOf("day").toISOString();
      let endDate = moment(values.endDate).startOf("day").toISOString();
      setLoading(true);
      // format the coupons;
      let countOfCoupon = values.countOfCoupon;
      // generate random unique codes that are not in allCodes;
      let generatedCodes = getRandomCodes({
        count: countOfCoupon,
        oldCodes: allCodes,
      });
      let newFormattedCoupons = generatedCodes.map((code) => {
        return {
          ...values,
          startDate,
          endDate,
          code,
        };
      });
      const data = {
        coupons: newFormattedCoupons,
        code: waiter.code,
        isMultiple: true,
      };

      const res = await postAddNewCoupon(data);
      if (res.data.coupons) {
        dispatch(addMultiplesCouponsAction(res.data.coupons))
      }
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setLoading(false);
      hide();
    } catch (error) {
        console.log(error)
      if (error?.response?.data?.error === "duplicated") {
        setLoading(false);
        form.setFields([
          {
            name: "code",
            errors: [intl.formatMessage({ id: "duplicatedCode" })],
          },
        ]);
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "duplicatedCode" }),
          type: "error",
          rtl,
        });
      } else {
        setLoading(false);
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "errorfetch" }),
          type: "error",
          rtl,
        });
      }
    }
  };

  function disabledStartDate(current) {
    // Can not select days before today and today
    return current && current < moment().startOf("day");
  }
  function disabledEndDate(current) {
    // Can not select days before today and today
    let endDate = form.getFieldValue("startDate");
    return current && current <= moment(endDate).startOf("day");
  }

  return (
    <Modal
      title={
        <p className='my-font'>
          {intl.formatMessage({ id: "addRandomCoupons" })}
        </p>
      }
      centered
      visible={show}
      destroyOnClose={true}
      mask={true}
      getContainer={false}
      maskClosable={true}
      onCancel={() => {
        hide();
      }}
      footer={null}
    >
      <Form
        name='Add-Random-Coupon'
        form={form}
        preserve={false}
        className='form-container'
        layout='vertical'
        onFinish={addNewCoupon}
        initialValues={{
          name: "",
          percentage: "",
          maximumAmount: 0,
          numberOfUsed: 1,
          startDate: moment().startOf("day"),
          endDate: moment().add(1, "days").startOf("day"),
        }}
      >
        <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
          {/* Names */}
          <Col md={24} sm={24} xs={24}>
            <Form.Item
              label={intl.formatMessage({ id: "couponName" })}
              className='mb-1'
              tooltip={
                <Text type='primary' className='my-font'>
                  {intl.formatMessage({ id: "explainCouponName" })}
                </Text>
              }
              name='name'
              style={{ borderRadius: 20 }}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: "errorname" }),
                },
              ]}
            >
              <Input
                className={"input"}
                style={{ borderRadius: 5, width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
          <Col md={24} sm={24} xs={24}>
            <Form.Item
              label={intl.formatMessage({ id: "countOfCoupon" })}
              className='mb-1'
              name='countOfCoupon'
              rules={[
                {
                  required: true,
                },
              ]}
              tooltip={<Text className="my-font">{intl.formatMessage({id: 'countOfCouponMax'})}</Text>}
            >
              <InputNumber
                className={"input"}
                placeholder={"50"}
                min={0}
                max={399}
                style={{ borderRadius: 5, width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
          <Col md={12} sm={24} xs={24}>
            <Form.Item
              label={intl.formatMessage({ id: "percentage" })}
              className='mb-1'
              name='percentage'
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: "errorname" }),
                },
              ]}
            >
              <InputNumber
                className={"input"}
                placeholder={"10%"}
                min={0}
                style={{ borderRadius: 5, width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col md={12} sm={24} xs={24}>
            <Form.Item
              label={intl.formatMessage({ id: "maximumAmount" })}
              className='mb-1'
              name='maximumAmount'
              tooltip={
                <Text type='primary' className='my-font'>
                  {intl.formatMessage({ id: "explainCouponMax" })}
                </Text>
              }
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: "errorname" }),
                },
              ]}
            >
              <InputNumber
                className={"input"}
                placeholder={"50"}
                min={0}
                style={{ borderRadius: 5, width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
          <Col md={12} sm={24} xs={24}>
            <Form.Item
              label={intl.formatMessage({ id: "MaxNumberOfUsed" })}
              className='mb-1'
              name='numberOfUsed'
              tooltip={
                <Text type='primary' className='my-font'>
                  {intl.formatMessage({ id: "explainCouponMaxUsed" })}
                </Text>
              }
            >
              <InputNumber
                className={"input"}
                placeholder={""}
                min={0}
                style={{ borderRadius: 5, width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col md={12} sm={24} xs={24}></Col>
        </Row>
        <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
          <Col md={12} sm={24} xs={24}>
            <Form.Item
              label={intl.formatMessage({ id: "startDate" })}
              className='mb-1'
              name='startDate'
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: "errorname" }),
                },
              ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                disabledDate={disabledStartDate}
                onChange={(e) => {
                  form.setFieldsValue({
                    endDate: moment(e).add(1, "days").startOf("day"),
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col md={12} sm={24} xs={24}>
            <Form.Item
              label={intl.formatMessage({ id: "endDate" })}
              className='mb-1'
              name='endDate'
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: "errorname" }),
                },
              ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                disabledDate={disabledEndDate}
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Space direction='horizontal'>
          <Form.Item>
            <CustomButton
              htmlType='submit'
              text={intl.formatMessage({ id: "save" })}
              className={`btnRegister btn-text border-8`}
              loading={loading}
              disabled={loading}
              type='primary'
            />
          </Form.Item>

          <Form.Item>
            <CustomButton
              type='text'
              text={intl.formatMessage({ id: "cancel" })}
              disabled={loading}
              onClick={() => {
                hide();
              }}
            />
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  );
};

export default AddRandomCoupons;
