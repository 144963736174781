import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import openNotification from "../../Components/Notifications";
import moment from "moment";
import { getReservationUsers } from "../../API/fetch";
import QrReader from "react-qr-scanner";
import EditReservationModal from "../Reservation/Components/EditReservationModal";
import { useIntl } from "react-intl";
import { Typography, Table, Spin } from "antd";
import CustomButton from "../../Components/CustomButton";
import { IoQrCodeOutline } from "react-icons/io5";
import { errorAudio, successAudio } from "../../Components/Audion";
import { canOpenRearCamera } from "../../helpers";
const { Text } = Typography;

const ReservationsReader = () => {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  const { branch } = useSelector((state) => state.branch);
  const [reservations, setReservations] = useState([]);
  const [formattedReservations, setFormattedReservations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showScanner, setShowScanner] = useState(false);
  const [showEditReservation, setShowEditReservation] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  useEffect(() => {
    getTodayReservations();
  }, []);
  const getTodayReservations = async () => {
    try {
      let date = moment().startOf("day").add(12, "hours").format("YYYY-MM-DD");
      const res = await getReservationUsers(date, branch?._id);
      if (res?.data?.reservationList) {
        setReservations(res.data.reservationList);
      }
      setLoading(false);
    } catch (error) {
      openNotification({
        type: "error",
        message: "Error",
        description: "Error getting reservations",
        rtl,
      });
      setLoading(false);
    }
  };

  // set up reservation table;
  useEffect(() => {
    if (reservations?.length) {
      const active = reservations.map((user, i) => {
        let customerInfo = user?.customerId;
        // if(user.appUserId){
        //   customerInfo = user.appUserId;
        // }
        return {
          reservationNumber: user?._id
            ?.substring(user?._id.length - 5)
            ?.toUpperCase(),
          name: customerInfo?.name,
          phone: rtl
            ? `${customerInfo?.phone}+`
            : `+${customerInfo?.phone}`,
          personsCount: user?.personsCount,
          personsEntered: user?.personsEntered,
          childCount: user?.childCount,
          userObject: user,
          key: user?._id,
        };
      });
      setFormattedReservations(active);
    } else {
      setFormattedReservations([]);
    }
  }, [reservations]);

  // handle reader QR;
  const handleScan = async (data) => {
    if (data && data?.text) {
      // setAudioError(undefined);
      const reservation = reservations.find((res) => res._id === data?.text);
      // await new Promise((r) => setTimeout(r, 100));
      if (
        reservation &&
        reservation?.personsEntered < reservation?.personsCount
      ) {
        setShowScanner(false);
        let copy = { ...reservation };
        // if(copy.appUserId){
        //   copy.customerId = copy.appUserId;
        // }
        setSelectedReservation(copy);
        setShowEditReservation(true);
        successAudio
        .play()
        .then(() => console.log("Playing"))
        .catch((err) => {
          console.log(err);
        });
        successAudio.loop = false;
        successAudio.volume = 1;
  
      } else if (reservation) {
        setShowScanner(false);
        
        errorAudio
          .play()
          .then(() => console.log("Playing"))
          .catch((err) =>console.log(err));
          openNotification({
            type: "error",
            message: intl.formatMessage({ id: "error" }),
            description: intl.formatMessage({ id: "AllUsersHasEntered" }),
            rtl,
          });
          errorAudio.volume = 1;
          errorAudio.loop = false;

      } else {
        setShowScanner(false);
        errorAudio
          .play()
          .then(() => console.log("Playing"))
          .catch((err) => console.log(err));
        openNotification({
          type: "error",
          message: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "reservationNotFound" }),
          rtl,
        });
        errorAudio.volume = 1;
        errorAudio.loop = false;
      }
    }
  };
  // handle error;
  const columns = [
    {
      title: intl.formatMessage({ id: "reservationNumber" }),
      dataIndex: "reservationNumber",
      key: "reservationNumber",
      render: (text, record) => {
        // make text clickable;
        return (
          <Text
            style={{ }}
            onClick={() => {
              // setSelectedReservation(record.userObject);
              // setShowEditReservation(true);
            }}
          >
            {text}
          </Text>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        // make text clickable;
        return (
          <Text
            style={{  }}
            onClick={() => {
              // setSelectedReservation(record.userObject);
              // setShowEditReservation(true);
            }}
          >
            {text}
          </Text>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "phone" }),
      dataIndex: "phone",
      key: "phone",
      render: (phone) => {
        return <a href={`tel:00${phone}`}>{phone}</a>;
      },
    },

    {
      title: intl.formatMessage({ id: "persons" }),
      dataIndex: "personsCount",
      key: "personsCount",
      render: (personsCount, record) => {
        let total = personsCount;
        if (record.childCount) {
          total += record.childCount;
        }
        let entered = record?.personsEntered;
        return (
          <div>
            {personsCount} {" - "}
            <Text type={entered == total ? "danger" : "success"}>
              {entered}
            </Text>
          </div>
        );
      },
    },
  ];



  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        // alignItems: "center",
        flexDirection: "column",
      }}
    >
      <EditReservationModal
        show={showEditReservation}
        hide={() => {
          setShowEditReservation(false);
          setSelectedReservation(null);
        }}
        reservation={selectedReservation}
        date={moment()}
        isReader={true}
        handleUpdateLocal={(data) => {
          setReservations(data);
        }}
      />
      {showScanner ? (
        <QrReader
          delay={300}
          style={{
            width: '100%',
            maxHeight: 300,
          }}
          onError={(erro) => {
            console.log(erro);
          }}
          onScan={handleScan}
          legacyMode={true}
          constraints={
            canOpenRearCamera()
            ? {
              video: {
                  facingMode: { exact: `environment` }
              }
            }
            : undefined
        }
        />
      ) : null}
      <Spin spinning={loading}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CustomButton
            text={intl.formatMessage({ id: showScanner ? "hide" : "scan" })}
            onClick={() => {
              setShowScanner(!showScanner);
             
              successAudio.load();
              errorAudio.load();
              successAudio.addEventListener('canplaythrough', () => {
                console.log('loaded audio');
                // setAudioS(successAudio);
              });
              errorAudio.addEventListener('canplaythrough', () => {
                console.log('loaded audio');
                // setAudioE(errorAudio);
              }
              );
            }}
            icon={
              <IoQrCodeOutline
                style={{
                  marginLeft: 10,
                  fontSize: 15,
                }}
              />
            }
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            type='primary'
            className='px-3 border-5 mb-3 mt-3'
          />
          <Table
            dataSource={formattedReservations}
            columns={columns}
            pagination={false}
            scroll={{ x: 400 }}
          />
        </div>
      </Spin>
    </div>
  );
};

export default ReservationsReader;
