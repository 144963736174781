import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Space,
  Tag,
  Typography,
  Collapse,
  Select,
  Popconfirm,
  Image,
} from "antd";
import {
  ORDER_TYPES,
  TAG_COLORS_BY_PAYMENT_STATUS,
  TAG_COLORS_BY_STATUS,
  TAG_COLORS_BY_STATUS_PICKUP,
  TAG_COLORS_BY_STATUS_DELIVERY,
  TAG_COLORS_BY_PAYMENT_REFUND_STATUS,
} from "../../../Types";
import { useIntl } from "react-intl";
import { CaretRightOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../CustomButton";
import { postUpdateOrder } from "../../../API/fetch";
import { editTableAction } from "../../../redux/reducers/tableReducer";
import openNotification from "../../Notifications";
import { updateOrdersAction } from "../../../redux/reducers/orderReducer";
import { IoCarSportOutline } from "react-icons/io5";
import COLORS from "../../../Style/colors";
import moment from "moment";
import { carColors } from "../../../Types/carColors";
import CARS from '../../../Types/cars.json'

const { Text, Paragraph } = Typography;
const { Panel } = Collapse;
const { Option } = Select;

const DineInHeader = ({
  orderNumber,
  status,
  createdAt,
  total,
  discountAmount,
  finalTotal,
  paymentStatus,
  orderId,
  userPhone,
  userName,
  orderType,
  posData,
  deliverToCar,
  refundPayment,
  refundPaymentInfo,
  role,
  tableFees,
  reservationNumber,
  walletAmount,
  tip,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const { tables } = useSelector((state) => state.table);
  const { integrations } = useSelector((state) => state.restaurant);
  const [loading, setLoading] = useState(false);
  const [newTableId, setNewTableId] = useState("");
  const [carInformation, setCarInformation] = useState({});

  useEffect(() => {
      if(deliverToCar?.carColor?.length){
          const selected = CARS.find(c => c?.name_en === deliverToCar?.carName?.split('-')[1])
          if(selected){
            const selectedColor = carColors.find(c => c?.name_en === deliverToCar?.carColor?.split('-')[1])
            setCarInformation({
              colorName: rtl ? selectedColor?.name_ar : selectedColor?.name_en,
              colorCode: selectedColor?.key,
              carName: rtl ? selected?.name : selected?.name_en,
              image: selected?.imageUrl,
              carModal: deliverToCar?.carModal,
              carPlate: deliverToCar?.carPlate,
            })
          }else {
            setCarInformation({
              colorName: deliverToCar?.carColor,
              colorCode: '',
              carName: deliverToCar?.carName,
              image: '',
              carModal: '',
              carPlate: '',
            })
          }
      }
  }, [deliverToCar, rtl])

  const handleChangeTable = async () => {
    try {
      setLoading(true);
      const data = {
        order: {
          tableId: newTableId,
        },
      };
      const res = await postUpdateOrder(orderId, data, newTableId);
      if (res.data && res.data.status === "success") {
        dispatch(editTableAction(res.data.table));
        dispatch(editTableAction(res.data.otherTable));
        dispatch(
          updateOrdersAction({
            type: ORDER_TYPES.DINE_IN,
            payload: res.data.order,
          })
        );

        setLoading(false);
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // console.log(error)
    }
  };
  return (
    <Row>
      <Col md={12}>
        <Space direction='vertical' size={0}>
          <Space direction='horizontal' size={"small"}>
            <Text className='my-font'>
              {`${intl.formatMessage({ id: "orderNo" })}: ${orderNumber}`}
            </Text>
            <Tag
              color={
                orderType === ORDER_TYPES.DINE_IN
                  ? TAG_COLORS_BY_STATUS[status]
                  : orderType === ORDER_TYPES.PICKUP
                  ? TAG_COLORS_BY_STATUS_PICKUP[status]
                  : TAG_COLORS_BY_STATUS_DELIVERY[status]
              }
              className='my-font'
            >
              {intl.formatMessage({
                id: status === "close" ? "closedOrder" : status,
              })}
            </Tag>
          </Space>
          <Text type='secondary' className='my-font' style={{ fontSize: 14 }}>
            {`${intl.formatMessage({ id: "time" })}: ${moment(createdAt).format("YYYY-MM-DD HH:mm A")}`}
          </Text>
          {reservationNumber ?<Text className="my-font">
            {intl.formatMessage({id: 'reservationNumber'})} :  <Tag ># {reservationNumber} </Tag>
          </Text> : null}
          {orderType === ORDER_TYPES.DINE_IN ? (
            <Collapse
              ghost
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
            >
              <Panel header={intl.formatMessage({ id: "moveOrder" })}>
                <Space direction='horizontal' size={"small"}>
                  <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder='Select a table'
                    optionFilterProp='children'
                    onChange={(value) => {
                      setNewTableId(value);
                    }}
                  >
                    {tables?.map((table) => (
                      <Option key={table._id} value={table._id}>
                        {rtl ? table?.name : table?.name_en}
                      </Option>
                    ))}
                  </Select>
                  <CustomButton
                    type='primary'
                    text={intl.formatMessage({ id: "move" })}
                    onClick={handleChangeTable}
                    disabled={loading}
                    loading={loading}
                  />
                </Space>
              </Panel>
            </Collapse>
          ) : null}
          {userName ? (
            <Collapse
              ghost
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
            >
              <Panel
                header={
                  <Text
                    className='my-font'
                    type={
                      deliverToCar?.status === "customerArrived"
                        ? "danger"
                        : "primary"
                    }
                  >
                    {intl.formatMessage({ id: "userInfo" })}
                   
                    {deliverToCar?.status === "customerArrived" ? (
                      <IoCarSportOutline
                        className='mx-3'
                        size={20}
                        style={{ position: "relative", top: 5 }}
                      />
                    ) : null}
                  </Text>
                }
              >
                <div>
                  <Text className='my-font'>
                    {intl.formatMessage({ id: "customerName" })}
                    {" : "}
                    <Tag>{userName}</Tag>
                  </Text>
                  <Paragraph
                    copyable={{
                      tooltips: false,
                    }}
                    className='my-font'
                  >
                    {intl.formatMessage({ id: "customerPhone" })}
                    {" : "}
                    <Tag> {userPhone?.length > 4 ? userPhone : ""}</Tag>
                  </Paragraph>
                  {deliverToCar?.carColor ? (
                    <Text className='my-font'>
                      {intl.formatMessage({ id: "customerCar" })}
                      {" : "}
                      <Space style={{
                        alignItems: 'center'
                      }} direction='horizontal' size={"small"}>
                        <Text className='my-font'>
                        {carInformation?.carName} 
                        </Text>
                        {carInformation?.carModal ?
                        <Text className='my-font'>
                         - {carInformation?.carModal} 
                        </Text> : null}
                        <Text className='my-font'>
                        / {carInformation?.colorName}
                        </Text>
                        {carInformation?.carPlate ?
                        <Tag className='my-font'>
                          {carInformation?.carPlate}
                        </Tag> : null}
                        {carInformation?.image ? 
                        <Image 
                          src={carInformation?.image}
                          width={30}
                          height={30}
                          preview={false}
                          style={{
                            resizeMode: "contain",
                          }}
                        />
                        : null}
                      </Space>
                      {deliverToCar?.status === "customerArrived" ? (
                        <Text className='my-font' type='danger'>
                          {intl.formatMessage({ id: "customerArrived" })}
                        </Text>
                      ) : null}
                    </Text>
                  ) : null}
                </div>
              </Panel>
            </Collapse>
          ) : null}
        </Space>
      </Col>
      <Col md={12}>
        <Space direction='vertical' size={0} style={{marginBottom: 10,}}>
          <Text className='my-font'>
            {`${intl.formatMessage({ id: "total" })}: ${total?.toFixed(2)}`}
          </Text>
          <Text className='my-font'>
            {`${intl.formatMessage({
              id: "discount",
            })}: ${discountAmount?.toFixed(2)}`}
          </Text>
          {tableFees > 0 ? (
            <Text className='my-font'>
              {`${intl.formatMessage({
                id: "otherCharges",
              })}: ${tableFees?.toFixed(2)}`}
            </Text>
          ) : null}
          <Space direction='horizontal' size={"small"}>
            <Text className='my-font'>
              {`${intl.formatMessage({
                id: "totalAfterDiscount",
              })}: ${
                tableFees > 0 ? tableFees + parseFloat(finalTotal) : finalTotal
              }`}
            </Text>
            <Tag
              color={TAG_COLORS_BY_PAYMENT_STATUS[paymentStatus]}
              className='my-font'
            >
              {intl.formatMessage({ id: paymentStatus })}
            </Tag>
          </Space>
          {role === "super" && paymentStatus === "paid" ? (
            <Space direction='horizontal' size={"small"}>
              {refundPaymentInfo?.status === "REFUNDED" ||
              refundPaymentInfo?.status ===
                "Refunded" ? null : refundPaymentInfo?._id ? (
                <CustomButton
                  type='primary'
                  text={intl.formatMessage({ id: "refresh" })}
                  onClick={refundPayment}
                  disabled={loading}
                  loading={loading}
                />
              ) : (
                <Popconfirm
                  title={
                    <div
                      style={{
                        maxWidth: 300,
                      }}
                    >
                      <Text className='my-font'>
                        {intl.formatMessage({ id: "refundPaymentDes" })}
                      </Text>
                    </div>
                  }
                  okText={intl.formatMessage({ id: "refundPayment" })}
                  okButtonProps={{
                    style: { color: COLORS.danger, fontFamily: "Cairo" },
                  }}
                  okType='text'
                  cancelText={intl.formatMessage({ id: "cancel" })}
                  cancelButtonProps={{
                    style: { border: "none", fontFamily: "Cairo" },
                  }}
                  onConfirm={refundPayment}
                  // disabled if role is not super or the created At is more than 3 hours ago;
                  disabled={
                    role !== "super" ||
                    new Date(createdAt) < Date.now() - 1000 * 60 * 60 * 3||
                    walletAmount > 0
                  }
                >
                  <Text
                    disabled={
                      role !== "super" ||
                      new Date(createdAt) < Date.now() - 1000 * 60 * 60 * 3||
                      walletAmount > 0
                    }
                    type='danger'
                    className='pointer my-font'
                  >
                    {intl.formatMessage({
                      id: refundPaymentInfo?._id ? "refresh" : "refundPayment",
                    })}
                  </Text>
                </Popconfirm>
              )}
              {refundPaymentInfo?._id ? (
                <Tag
                  color={
                    TAG_COLORS_BY_PAYMENT_REFUND_STATUS[
                      refundPaymentInfo?.status
                    ]
                  }
                  className='my-font'
                >
                  {intl.formatMessage({ id: refundPaymentInfo?.status || "" })}
                </Tag>
              ) : null}
            </Space>
          ) : null}
          {posData && posData?.otherNu ? (
            <Text className='my-font'>
              {intl.formatMessage({ id: "orderNumberInPOS" })}{" "}
              <Text className='my-font'>
                {intl.formatMessage({
                  id: integrations?.posName ? integrations?.posName : "N/A",
                })}{" "}
                :{" "}
              </Text>
              <Tag color={"purple"} className='my-font'>
                {posData?.otherNu}
              </Tag>
            </Text>
          ) : null}
          {tip && tip > 0 ? 
          <Text className='my-font'>
            {intl.formatMessage({ id: "tip" })} :{" "}
            <Tag style={{borderRadius: 7,}}color={"purple"} className='my-font'>
              {tip} SAR
            </Tag>
          </Text> 
          : null}
        </Space>
      </Col>
    </Row>
  );
};

export default DineInHeader;
