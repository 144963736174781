import {
  Col,
  Modal,
  Row,
  Typography,
  Checkbox,
  Input,
  Divider,
  Space,
} from "antd";
import React, { useState } from "react";
import { useIntl } from "react-intl";

const { Title, Text } = Typography;
const { TextArea } = Input;
const PrintCodsModal = ({ show, coupon, hide, print, loading }) => {
  const intl = useIntl();
  const [showPrice, setShowPrice] = useState(false);
  const [terms, setTerms] = useState("");
  return (
    <Modal
      title={
        <Title level={5}>{intl.formatMessage({ id: "printCoupon" })}</Title>
      }
      visible={show}
      onOk={() => {
        print({
          terms,
          showPrice,
        });
      }}
      okButtonProps={{
        loading,
      }}
      
      onCancel={hide}
      okText={`${intl.formatMessage({ id: "download" })}`}
      cancelText={`${intl.formatMessage({ id: "cancel" })}`}
      className='my-font'
    >
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Col>
          <Checkbox onChange={(e) => setShowPrice(e.target.checked)}>
            {intl.formatMessage({ id: "showCouponPrice" })}
          </Checkbox>
        </Col>
      </Row>
      <Row className='mt-3'>
        <Space direction='vertical' size={1} className='mb-2'>
          <Text className='my-font'>
            {intl.formatMessage({ id: "instructionForCoupon" })}
          </Text>
          <Text type='secondary' className='my-font'>
            {intl.formatMessage({ id: "maxLength500" })}
          </Text>
        </Space>
        <TextArea
          value={terms}
          onChange={(e) => {
            // make sure the length is less than 500
            // and the lines are less than 3
            if (e.target.value.length <= 500) {
              // count \n lines;
              let lines = e.target.value.split("\n").length;
              if (lines <= 6) {
                setTerms(e.target.value);
              }
            }
          }}
          rows={6}
          maxLength={500}
          showCount
          style={{
            width: "100%",
          }}
        />
      </Row>
    </Modal>
  );
};

export default PrintCodsModal;
