import React, { useState, useEffect, useRef } from "react";
import { Space, Typography, Form, Row, Col, InputNumber, Select } from "antd";
import { useIntl } from "react-intl";
import COLORS from "../../Style/colors";
import CustomButton from "../../Components/CustomButton";
import moment from "moment";
import openNotification from "../../Components/Notifications";
import { useDispatch, useSelector } from "react-redux";
import { postUpdateBranch } from "../../API/fetch";
import {
  setBranchAction,
  setIsCloseTmpAction,
} from "../../redux/reducers/branchReducer";

const { Text, Title } = Typography;
const { Option } = Select

const CloseTmp = () => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const { branch, isCloseTmp } = useSelector((state) => state.branch);
  const [timer, setTimer] = useState("");
  const [loading, setLoading] = useState(false);
  let myIntervale = useRef(null);

  useEffect(() => {
    let close = false;
    if (branch?.closeUntil) {
      if (new Date(branch?.closeUntil).getTime() > new Date().getTime()) {
        close = true;
        const queuedTime = new Date(branch?.closeUntil).getTime();
        myIntervale.current = setInterval(function () {
          let millis = queuedTime - new Date().getTime();
          let minutes = Math.floor(millis / 60000);
          let seconds = ((millis % 60000) / 1000).toFixed(0);
          setTimer(minutes + ":" + (seconds < 10 ? "0" : "") + seconds);
          if (minutes <= 0 && parseInt(seconds) <= 0) {
            dispatch(setIsCloseTmpAction(false));
            clearInterval(myIntervale.current);
            return;
          }
        }, 1000);
      }
    }
    dispatch(setIsCloseTmpAction(close));
  }, [branch]);

  const onFinish = async (values) => {
    let time = 0;
    let minutes = 0;
    if (values?.time) {
      time = values.time;
    }
    if(values?.minutes){
      time = time + values.minutes
    }
    let closeTime = moment().add(time, "minutes");
    let endClose = closeTime.utc().format();
    const data = {
      branch: {
        closeUntil: endClose,
      },
    };

    try {
      setLoading(true);
      const res = await postUpdateBranch(branch._id, data);
      const newBrach = {
        ...branch,
        closeUntil: res.data.branch?.closeUntil,
      };
      clearInterval(myIntervale.current);
      dispatch(setBranchAction(newBrach));
      form.resetFields();
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
    } catch (error) {
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  return (
    <div className='tables-card-container'>
      <Space direction='vertical'>
        <Title>{intl.formatMessage({ id: "closeTemp" })}</Title>
        <Text className='my-font' style={{ marginBottom: 0, marginTop: 0 }}>
          {intl.formatMessage({ id: "closeTempDes" })}
        </Text>
      </Space>
      <div>
        <Form
          name='close-tmp-form'
          form={form}
          layout='vertical'
          onFinish={onFinish}
          initialValues={{
            time: 0,
            minutes: 0
          }}
          style={{
            backgroundColor: isCloseTmp ? COLORS.danger : COLORS.white,
          }}
          className='py-3 px-3 border-8 mt-4 light-shadow form-container'
        >
          {isCloseTmp ? (
            <Row>
              <Col span={24}>
                <Title level={5}>
                  {intl.formatMessage({ id: "branchWillOpenAfter" })}{" "}
                  {timer?.includes && timer.includes(":") ? timer : ""}{" "}
                </Title>
              </Col>
            </Row>
          ) : null}
          <Row>
            {/* Names */}
            <Col md={6} sm={24} xs={24}>
              <Form.Item
                label={intl.formatMessage({ id: "timeHours" })}
                className='mb-1'
                name='time'
                style={{ borderRadius: 20 }}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: "errorname" }),
                  },
                ]}
              >
                <Select style={{ width: "100%" }} className='my-font' disabled={isCloseTmp}>
                  {Array(24).fill(1)?.map((el, i) =>
                    <Option key={i.toString()} className='my-font' value={(i + 1) * 60}>
                      {i + 1}
                    </Option>
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6} sm={24} xs={24} className="mx-2">
              <Form.Item
                label={intl.formatMessage({ id: "timeInMinutes" })}
                className='mb-1'
                name='minutes'
                style={{ borderRadius: 20 }}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: "errorname" }),
                  },
                ]}
              >
                <Select style={{ width: "100%" }} className='my-font' disabled={isCloseTmp}>
                  {Array(60).fill(1)?.map((el, i) =>
                    <Option key={i.toString()} className='my-font' value={(i + 1)}>
                      {i + 1}
                    </Option>
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify='start' className='mt-2' align='middle'>
            <Form.Item>
              <CustomButton
                htmlType='submit'
                text={intl.formatMessage({ id: "closeBranch" })}
                className={`btnRegister btn-text border-8 px-5 `}
                loading={loading}
                disabled={loading || isCloseTmp}
                type='primary'
              />
            </Form.Item>
            {isCloseTmp ? (
              <Form.Item>
                <CustomButton
                  text={intl.formatMessage({ id: "reopenBranch" })}
                  className={`btnRegister btn-text border-8 px-5 mx-2`}
                  loading={loading}
                  disabled={loading}
                  type='success'
                  onClick={() => onFinish({ time: 0 })}
                />
              </Form.Item>
            ) : null}
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default CloseTmp;
