import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Empty, Row, Typography } from "antd";
import FoodCard from "./Foods/FoodCard";

const { Title } = Typography;
const SearchScreen = ({ categories, search }) => {
  const intl = useIntl();
  const [foods, setFoods] = useState([]);
  useEffect(() => {
    if (search && categories?.length) {
      const foods = categories.reduce((acc, category) => {
        const foods = category.foods
          ?.filter((food) => {
            if (
              food?.name?.includes(search) ||
              food?.name?.includes(search?.toLowerCase()) ||
              food?.name_en?.includes(search) ||
              food?.name_en?.includes(search?.toLowerCase())
            ) {
              return true;
            }
            return false;
          })
          ?.map((food) => {
            return {
              ...food,
              category: category,
            };
          });
        return [...acc, ...foods];
      }, []);
      setFoods(foods);
    }
  }, [categories, search]);
  return (
    <Row
      gutter={{ xs: 8, sm: 16, md: 8, lg: 8 }}
      type='flex'
      justify='space-around'
      align='middle'
      style={{ flexWrap: "wrap", marginTop: 35 }}
    >
      {foods.length > 0 ? (
        foods.map((food) => {
          return (
            <FoodCard category={food?.category} key={food.foodId} food={food} />
          );
        })
      ) : (
        <Empty
          image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
          imageStyle={{
            height: 250,
          }}
          description={
            <h2 className='my-font'>
              {intl.formatMessage({ id: "emptyFoods" })}
            </h2>
          }
        ></Empty>
      )}
    </Row>
  );
};

export default SearchScreen;
