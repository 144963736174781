import React, { useEffect, useState } from "react";
import { Form, Row, Col, Spin, Select, Typography, Input, Divider } from "antd";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../Components/CustomButton";
import COLORS from "../../Style/colors";
import openNotification from "../../Components/Notifications";
import { ALL_DAYS } from "../../Types";
import { postUpdateBranchPrize } from "../../API/fetch";
import { CheckOutlined } from "@ant-design/icons";
import {
  editBranchInAllBranchAction,
  setBranchAction,
} from "../../redux/reducers/branchReducer";

const { Option } = Select;
const { Title, Text } = Typography;

const Marketing = () => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const { rtl } = useSelector((state) => state.settings);
  const { foods } = useSelector((state) => state.menu);
  const [filteredFoods, setFilteredFoods] = useState(foods);
  const { allBranches } = useSelector((state) => state.branch);
  const [selectedBranch, setSelectedBranch] = useState(
    allBranches?.[0]?.branch
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // get the game prize from the sleected branch;
    const gamePrize = selectedBranch?.gamePrize;
    if (gamePrize) {
      form.setFieldsValue({
        foods: gamePrize?.foods,
        numberOfDailyPrizes: gamePrize?.numberOfDailyPrizes,
        disabledDays: gamePrize?.disabledDays,
        status: gamePrize?.status,
      });
    }
  }, [selectedBranch]);

  const onFinish = async (values) => {
    try {
      const data = {
        branch: {
          gamePrize: {
            ...values,
          },
        },
      };
      const res = await postUpdateBranchPrize(selectedBranch._id, data);
      const newBrach = {
        ...selectedBranch,
        ...data.branch,
      };
      dispatch(setBranchAction(newBrach));
      dispatch(editBranchInAllBranchAction(newBrach));

      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
    } catch (error) {
      console.log(error);
      setLoading(false); // hide loading;
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  return (
    <div>
      <div
        style={{
          borderRadius: 6,
          marginTop: 40,
          width: "92%",
          marginRight: "auto",
          marginLeft: "auto",
          padding: 15,
        }}
      >
        <Spin spinning={loading}>
          <Form
            name='prize-form'
            form={form}
            className='form-container'
            layout='vertical'
            onFinish={onFinish}
            initialValues={{
              status: "Disabled",
              foods: [],
              numberOfDailyPrizes: 0,
              disabledDays: [],
            }}
          >
            {() => (
              <div style={{ backgroundColor: COLORS.gray }}>
                <div
                  style={{
                    backgroundColor: COLORS.white,
                    padding: "10px 20px 20px",
                    borderRadius: 7,
                  }}
                  className='light-shadow'
                >
                  <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                    {/* Title */}
                    <Col md={12} sm={24} xs={24}>
                      <Title className='my-font' level={4}>
                        {intl.formatMessage({ id: "prizes" })}
                      </Title>
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                    {/* Title */}
                    <Col md={24} sm={24} xs={24}>
                      <Title className='my-font' level={5}>
                        {intl.formatMessage({ id: "prizesDes" })}.
                      </Title>
                    </Col>
                  </Row>
                  <Divider />
                  <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                    <Col md={24} sm={24} xs={24}>
                      <Title className='my-font' level={5}>
                        {intl.formatMessage({ id: "chooseBranch" })}
                      </Title>
                      {allBranches?.map((branch) => {
                        let selected =
                          selectedBranch?._id === branch?.branch?._id;
                        return (
                          <CustomButton
                            key={branch?.branch?._id}
                            type={selected ? "primary" : "secondary"}
                            className='mx-2 px-5 border-8 mt-2'
                            text={
                              rtl ? branch?.branch?.name : branch?.branch?.name
                            }
                            icon={selected ? <CheckOutlined /> : null}
                            onClick={() => setSelectedBranch(branch?.branch)}
                          />
                        );
                      })}
                      {/* <Select
                        mode='multiple'
                        className='my-font'
                        showArrow
                        tagRender={(p) => tagRender(p, "green")}
                        options={allBranches.map(({ branch }) => ({
                          label: branch.name,
                          value: branch._id,
                        }))}
                      /> */}
                    </Col>
                  </Row>

                  <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                    {/* Names */}
                    <Col md={24} sm={24} xs={24}>
                      <Form.Item
                        name='foods'
                        label={intl.formatMessage({ id: "freeItems" })}
                        rules={[
                          {
                            required: true,
                            message: intl.formatMessage({ id: "errorname" }),
                          },
                        ]}
                      >
                        <Select
                          allowClear
                          showSearch
                          filterOption={false}
                          onSearch={(e) => {
                            if (e || e?.trim()?.length > 0) {
                              let filtered = foods.filter((food) => {
                                
                                return (
                                  food?.name?.includes(e) ||
                                  food?.name_en?.includes(e)
                                );
                              });
                              setFilteredFoods(filtered);
                            } else {
                              setFilteredFoods(foods);
                            }
                          }}
                          className='my-font'
                          mode='multiple'
                        >
                          {filteredFoods?.map((food) => {
                            return (
                              <Option value={food?.foodId}>
                                {rtl ? food.name : food?.name_en}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row
                    className='mt-4'
                    gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
                  >
                    {/* Vat and email */}
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        label={intl.formatMessage({
                          id: "numberOfDailyPrizes",
                        })}
                        className='mb-1'
                        name='numberOfDailyPrizes'
                        style={{ borderRadius: 20 }}
                        rules={[
                          // make sure it is 0 or bigger;
                          {
                            validator: (_, value) =>
                              value >= 0
                                ? Promise.resolve()
                                : Promise.reject(
                                    intl.formatMessage({
                                      id: "errorNumber",
                                    })
                                  ),
                          },
                        ]}
                      >
                        <Input
                          className={"input"}
                          style={{ borderRadius: 5, width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        label={intl.formatMessage({ id: "disabledDays" })}
                        className='mb-1'
                        name='disabledDays'
                        tooltip={
                          <Text className='my-font'>
                            {intl.formatMessage({
                              id: "disabledDaysPromotionTooltip",
                            })}
                          </Text>
                        }
                      >
                        <Select mode='multiple' className='my-font'>
                          {ALL_DAYS?.map((day) => (
                            <Option
                              key={day.value}
                              value={day.value}
                              className='my-font'
                            >
                              {intl.formatMessage({ id: day.value })}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row
                    className='mt-4'
                    gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
                  >
                    {/* Phone*/}
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        label={intl.formatMessage({ id: "status" })}
                        className='mb-1'
                        name='status'
                        style={{ borderRadius: 20 }}
                        rules={[
                          {
                            required: true,
                            message: intl.formatMessage({ id: "errorname" }),
                          },
                        ]}
                      >
                        <Select className='my-font'>
                          <Option value='Active'>
                            {intl.formatMessage({ id: "active" })}
                          </Option>
                          <Option value='Disabled'>
                            {intl.formatMessage({ id: "disabled" })}
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>

                <Row justify='start' className='mt-5' align='middle'>
                  <Form.Item>
                    <CustomButton
                      htmlType='submit'
                      text={intl.formatMessage({ id: "save" })}
                      className={`btnRegister btn-text border-8 px-5 `}
                      loading={loading}
                      disabled={loading}
                      type='primary'
                    />
                  </Form.Item>
                </Row>
              </div>
            )}
          </Form>
        </Spin>
      </div>
    </div>
  );
};

export default Marketing;
