import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { message } from "antd";
import Home from "./Views/Home";
import Register from "./Views/Auth/index";
import Sidebar from "./Components/Sidebar";
import Onboarding from "./Views/Onboarding";
import PrivateRoute from "./Components/PrivateRoute";
import Tables from "./Views/Tables";
import OneTableView from "./Views/Tables/Components/OneTableView";
import Pickup from "./Views/Pickup";
import Orders from "./Views/Orders";
import Delivery from "./Views/Delivery";
import WaitingList from "./Views/WaitingList";
import Foods from "./Views/MenuViews/Foods";
import OneFoodView from "./Views/MenuViews/OneFoodView";
import Sort from "./Views/MenuViews/Sort";
import Modifiers from "./Views/MenuViews/Modifiers";
import CloseTmp from "./Views/CloseTmp";
import Restaurant from "./Views/Restaurant";
import Branches from "./Views/Branches";
import Waiters from "./Views/Waiters";
import PromoCods from "./Views/PromoCods";
import Promotions from "./Views/Promotions";
import Feedback from "./Views/Feedback";
import OneFeedBack from "./Views/Feedback/Components/OneFeedBack";
import AllUsers from "./Views/AllUsers";
import Reports from "./Views/Reports";
import OrderLiveCycle from "./Views/OrderLiveCycle";
import MainPage from "./Views/Design/MainPage";
// import ViewFood from "./Views/Design/ViewFood";
// import ViewCart from "./Views/Design/ViewCart";
// import ViewConfirm from "./Views/Design/ViewConfirm";
import SocialIcons from "./Views/Design/SocialIcons";
import MenuAds from "./Views/Design/MenuAds";
import QRView from "./Views/QRView";
import Integration from "./Views/Integration";
import Foodics from "./Views/Integration/Router/Foodics";
import NewFoodicsView from "./Views/Integration/NewFoodicsView";
import Subscription from "./Views/Subscription";
import MenuEngineering from "./Views/MenuEngineering";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import moment, { duration } from "moment";
import {
  logOutAction,
  setServicesAction,
  setSubscriptionEndAction,
} from "./redux/reducers/authReducer";
import Reservations from "./Views/Reservation";
import Comments from "./Views/Comments";
import PaymentRecords from "./Views/PaymentRecords";
import ReservationReports from "./Views/ReservationReports";
import PaymentRefund from "./Views/PaymentRefund";
import EditReservationView from "./Views/Reservation/Components/EditReservationView";
import PaymentsData from "./Views/PaymentsData";
import { getRestaurantService } from "./helpers";
import VerifyPayment from "./Views/Subscription/VerifyPayment";
import Payment from "./Views/Payment";
import IwaiterApp from "./Views/iwaiterApp";
import ScheduleOrder from "./Views/ScheduleOrder";
import ReservationsReader from "./Views/ReservationsReader";
import ReservationModifier from "./Views/Reservation/Components/ReservationModifier";
import AllRestaurants from "./Views/AllRestaurants";
import ManagerRoute from "./Components/ManagerRoute";

import OrderById from "./Views/OrderById";
import CustomOrders from "./Views/CustomOrders";
import AddUserReservationManual from "./Views/Reservation/Components/AddUserReservationManual";
import Categories from "./Views/Categories";
import MenuButtons from "./Views/menuButtons";
import Marketing from "./Views/Marketing";
import MarketingSMS from "./Views/MarketingSMS";
import NewCampaigns from "./Views/MarketingSMS/components/NewCampaigns";
import EditCampaigns from "./Views/MarketingSMS/components/EditCampaigns";
import TipRecords from "./Views/TipRecords";
import OneDepositView from "./Views/PaymentsData/Components/OneDepositView";
import TipsReports from "./Views/TipsReports";
import OneReservationView from "./Views/Reservation/OneReservationView";
import PaymentVat from "./Views/PaymentVat";
import CustomCategories from "./Views/CustomCategories";
import PendingPayments from "./Views/PendingPayments";
import OneIwaiterDeposit from "./Views/PaymentRecords/OneIwaiterDeposit";
import TextAds from "./Views/Design/TextAds";

const AllRoutes = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const { isOffline } = useSelector((state) => state.settings);
  const { user, services } = useSelector((state) => state.auth);
  useEffect(() => {
    if (isOffline) {
      message.error(intl.formatMessage({ id: "pleaseCheckInternet" }), 0);
    } else {
      message.destroy();
    }
  }, [isOffline]);
  useEffect(() => {
    if (user?._id) {
      let end = false;
      // check the service that are with the user;
      if (!user?.services?.length) {
        message.destroy();
        if (!user?.isSuperUser && location.pathname == "/all-restaurants") {
          message.error(intl.formatMessage({ id: "subscriptionEnded" }), 0);
        }
        end = true;
      } else {
        const { isActive, services, serviceWithEndDate } = getRestaurantService(
          { services: user?.services }
        );
        // dispatch(setServicesAction(services));
        if (!isActive) {
          message.destroy();
          message.error(intl.formatMessage({ id: "subscriptionEnded" }), 0);
          end = true;
        } else {
          if (serviceWithEndDate?.length) {
            const { endDate } = serviceWithEndDate[0];
            const diff = moment(endDate).diff(moment(), "days");
            if (diff <= 3) {
              // check if it is try;

              if (
                user?.services?.length === 1 &&
                user?.services[0].serviceType === "TRY"
              ) {
                message.destroy();
                message.info({
                  content: `${intl.formatMessage({
                    id: "freeTrialEndsIn",
                  })} ${diff} ${intl.formatMessage({
                    id: "days",
                  })}`,
                  style: {
                    maxWidth: "70%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  },
                  duration: 0,
                });
              } else {
                message.destroy();
                message.warning({
                  content: `${intl.formatMessage({
                    id: "subscriptionWillEndSoon",
                  })} ${diff} ${intl.formatMessage({
                    id: "days",
                  })}, ${intl.formatMessage({ id: "youCanRenew" })}`,
                  style: {
                    maxWidth: "70%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  },
                  duration: 0,
                });
              }
            }
          }
        }
      }
      dispatch(setSubscriptionEndAction(end));
    }
  }, [user]);
  return (
    <Routes>
      <Route
        path='/'
        element={
          <PrivateRoute>
            <Sidebar component={<Home />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/all-restaurants'
        element={
          <ManagerRoute>
            <AllRestaurants />
          </ManagerRoute>
        }
      />
      <Route
        path='/add-restaurant'
        element={
          <PrivateRoute>
            <Onboarding />
          </PrivateRoute>
        }
      />
      <Route
        path='/tables'
        element={
          <PrivateRoute>
            <Sidebar component={<Tables />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/tables/:id'
        element={
          <PrivateRoute>
            <Sidebar component={<OneTableView />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/orders'
        element={
          <PrivateRoute>
            <Sidebar component={<Orders />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/pickup'
        element={
          <PrivateRoute>
            <Sidebar component={<Pickup />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/customOrders'
        element={
          <PrivateRoute>
            <Sidebar component={<CustomOrders />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/categories'
        element={
          <PrivateRoute>
            <Sidebar component={<Categories />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/customCategories'
        element={
          <PrivateRoute>
            <Sidebar component={<CustomCategories />} />
          </PrivateRoute>
        }
      />
      {[
        "/pickup/:id",
        "/scheduleOrder/:id",
        "customOrders/:id",
        "/orders/:id",
        "/iwaiterApp/:id",
        "/delivery/:id",
      ].map((path, index) => {
        return (
          <Route
            key={index}
            path={path}
            element={
              <PrivateRoute>
                <Sidebar component={<OrderById />} />
              </PrivateRoute>
            }
          />
        );
      })}

      <Route
        path='/scheduleOrder'
        element={
          <PrivateRoute>
            <Sidebar component={<ScheduleOrder />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/iwaiterApp'
        element={
          <PrivateRoute>
            <Sidebar component={<IwaiterApp />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/delivery'
        element={
          <PrivateRoute>
            <Sidebar component={<Delivery />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/waiting'
        element={
          <PrivateRoute>
            <Sidebar component={<WaitingList />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/foods'
        element={
          <PrivateRoute>
            <Sidebar component={<Foods />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/comments'
        element={
          <PrivateRoute>
            <Sidebar component={<Comments />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/paymentRecords'
        element={
          <PrivateRoute>
            <Sidebar component={<PaymentRecords />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/paymentRecords/:id'
        element={
          <PrivateRoute>
            <Sidebar component={<OneIwaiterDeposit />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/paymentsVat'
        element={
          <PrivateRoute>
            <Sidebar component={<PaymentVat />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/tips'
        element={
          <PrivateRoute>
            <Sidebar component={<TipRecords />} />
          </PrivateRoute>
        }
      />
      {/* tips reports */}
      <Route
        path='/tip-reports'
        element={
          <PrivateRoute>
            <Sidebar component={<TipsReports />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/payments-data'
        element={
          <PrivateRoute>
            <Sidebar component={<PaymentsData />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/payments-data/:id'
        element={
          <PrivateRoute>
            <Sidebar component={<OneDepositView />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/paymentRefund'
        element={
          <PrivateRoute>
            <Sidebar component={<PaymentRefund />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/verifyPayment'
        // http://localhost:3000/subscription?paymentId=07071759534128882073&Id=07071759534128882073
        element={
          <PrivateRoute>
            <Sidebar component={<VerifyPayment />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/sort'
        element={
          <PrivateRoute>
            <Sidebar component={<Sort />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/modifiers'
        element={
          <PrivateRoute>
            <Sidebar component={<Modifiers />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/foods/:id'
        element={
          <PrivateRoute>
            <Sidebar component={<OneFoodView />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/close-tmp'
        element={
          <PrivateRoute>
            <Sidebar component={<CloseTmp />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/restaurant'
        element={
          <PrivateRoute>
            <Sidebar component={<Restaurant />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/branches'
        element={
          <PrivateRoute>
            <Sidebar component={<Branches />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/payment'
        element={
          <PrivateRoute>
            <Sidebar component={<Payment />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/waiters'
        element={
          <PrivateRoute>
            <Sidebar component={<Waiters />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/promoCode'
        element={
          <PrivateRoute>
            <Sidebar component={<PromoCods />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/promotions'
        element={
          <PrivateRoute>
            <Sidebar component={<Promotions />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/feedback'
        element={
          <PrivateRoute>
            <Sidebar component={<Feedback />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/feedback/:id'
        element={
          <PrivateRoute>
            <Sidebar component={<OneFeedBack />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/allUsers'
        element={
          <PrivateRoute>
            <Sidebar component={<AllUsers />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/reservations-reports'
        element={
          <PrivateRoute>
            <Sidebar component={<ReservationReports />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/reports'
        element={
          <PrivateRoute>
            <Sidebar component={<Reports />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/order-live-cycle'
        element={
          <PrivateRoute>
            <Sidebar component={<OrderLiveCycle />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/mainPage'
        element={
          <PrivateRoute>
            <Sidebar component={<MainPage />} />
          </PrivateRoute>
        }
      />
      {/* <Route
        path='/viewFood'
        element={
          <PrivateRoute>
            <Sidebar component={<ViewFood />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/viewCart'
        element={
          <PrivateRoute>
            <Sidebar component={<ViewCart />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/viewConfirm'
        element={
          <PrivateRoute>
            <Sidebar component={<ViewConfirm />} />
          </PrivateRoute>
        }
      /> */}
      <Route
        path='/socialIcons'
        element={
          <PrivateRoute>
            <Sidebar component={<SocialIcons />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/menuAds'
        element={
          <PrivateRoute>
            <Sidebar component={<MenuAds />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/textAds'
        element={
          <PrivateRoute>
            <Sidebar component={<TextAds />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/qrs'
        element={
          <PrivateRoute>
            <Sidebar component={<QRView />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/buttonsMenu'
        element={
          <PrivateRoute>
            <Sidebar component={<MenuButtons />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/reservation'
        element={
          <PrivateRoute>
            <Sidebar component={<Reservations />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/reservations/:id'
        element={
          <PrivateRoute>
            <Sidebar component={<OneReservationView />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/reservation/add'
        element={
          <PrivateRoute>
            <Sidebar component={<AddUserReservationManual />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/reservation-modifier'
        element={
          <PrivateRoute>
            <Sidebar component={<ReservationModifier />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/reservation-reader'
        element={
          <PrivateRoute>
            <Sidebar component={<ReservationsReader />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/edit-reservation/:id'
        element={
          <PrivateRoute>
            <Sidebar component={<EditReservationView />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/integration'
        element={
          <PrivateRoute>
            <Sidebar component={<Integration />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/foodics-success'
        element={
          <PrivateRoute>
            <Sidebar component={<Foodics />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/new-foodics'
        element={
          <PrivateRoute>
            <Sidebar component={<NewFoodicsView />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/subscription'
        element={
          <PrivateRoute>
            <Sidebar component={<Subscription />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/menu-engineer'
        element={
          <PrivateRoute>
            <Sidebar component={<MenuEngineering />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/marketing'
        element={
          <PrivateRoute>
            <Sidebar component={<Marketing />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/campaigns'
        element={
          <PrivateRoute>
            <Sidebar component={<MarketingSMS />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/campaigns/new'
        element={
          <PrivateRoute>
            <Sidebar component={<NewCampaigns />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/campaigns/:id'
        element={
          <PrivateRoute>
            <Sidebar component={<EditCampaigns />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/pendingPayments'
        element={
          <PrivateRoute>
            <Sidebar component={<PendingPayments />} />
          </PrivateRoute>
        }
      />

      {/* <PrivateRoute path="/viewFood" exact component={ViewFood} />
          <PrivateRoute path="/cartView" exact component={CartView} />
          <PrivateRoute path="/confirmView" exact component={ConfirmView} />
          <PrivateRoute path="/menuAds" exact component={MenuAds} /> */}

      {/* <Route  path="/" element={<Sidebar component={<Home />} />}/> */}
      {/* <Route  path="/home2" element={<Sidebar component={<Home2 />} />}/> */}

      {/* <PrivateRoute path="/home2"  element={<Sidebar component={<Home2 />} />} /> */}
      {/* <Route
          path="/home2"
          element={
            <PrivateRoute>
              <Home2 />
            </PrivateRoute>
          }
        /> */}
      {/* <PrivateRoute path="/foods" exact component={Foods} /> */}
      {/* 
          <PrivateRoute path="/foods/:id" exact component={FoodCategory} />
          <PrivateRoute path="/foods/meal/:id" exact component={FoodMeal} />
          <PrivateRoute path="/categories" exact component={Categories} />
          <PrivateRoute path="/modifiers" exact component={FoodModifiers} />
          <PrivateRoute path="/products" exact component={Products} />
          <PrivateRoute path="/orders" exact component={Orders} />
          <PrivateRoute
            path="/add-restaurant"
            exact
            name="add-restaurant"
            component={Index}
          />
          <PrivateRoute path="/auth-code" exact component={EnterCode} />
          <PrivateRoute path="/branches" exact component={Branches} />
          <PrivateRoute path="/waiters" exact component={Waiters} />
          <PrivateRoute path="/drivers" exact component={Drivers} />
          <PrivateRoute path="/driver-ready" exact component={DriverView} />
          <PrivateRoute path="/driver-indelivery" exact component={DriverView} />
          <PrivateRoute path="/driver-closed" exact component={DriverView} />
          <PrivateRoute path="/mainPage" exact component={MainPage} />
         
          <PrivateRoute path="/restaurant" exact component={Restaurant} />
          <PrivateRoute path="/pickup" exact component={Pickup} />
          <PrivateRoute path="/qrs" exact component={Qrs} />
          <PrivateRoute path="/feedback" exact component={Feedback} />
          <PrivateRoute path="/subscription" exact component={Subscription} />
          <PrivateRoute path="/promoCode" exact component={PromoCodes} />
          <PrivateRoute path="/promotions" exact component={Promotions} />
          <PrivateRoute path="/waiting" exact component={WaitingList} />
          <PrivateRoute path="/reports" exact component={Reports} />
          <PrivateRoute path="/order-live-cycle" exact component={TimeReports} />
          <PrivateRoute path="/delivery" exact component={Delivery} />
          <PrivateRoute path="/allUsers" exact component={UsersReporters} />
          <PrivateRoute path="/integration" exact component={Integration} />
          <PrivateRoute path="/foodics-success" exact component={Foodics} />
          <PrivateRoute path="/menu-analytics" exact component={MenuEngineering} />
          <PrivateRoute path="/settings" exact component={Settings} />
          <PrivateRoute path="/cashier" exact component={Cashier} /> */}

      {/* <Route path="/register" component={Register} />
          <Route path="/signup" component={Signup} />
          <Route component={Register} /> */}
      {/* <Route path="register"  element={<Register />}/> */}
      <Route path='*' element={<Register />} />
    </Routes>
  );
};

export default AllRoutes;
