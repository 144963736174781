import React, { useCallback, useEffect, useRef, useState } from "react";
import "./App.less";
import { ConfigProvider, notification } from "antd";
import { IntlProvider } from "react-intl";
import messages from "./messages";
import { useDispatch, useSelector } from "react-redux";
import {
  sefFullWidthAction,
  setIsOfflineAction,
  setNotificationTextAction,
} from "./redux/reducers/settingsReducer";
import { BrowserRouter as Router } from "react-router-dom";
import AllRoutes from "./AllRoutes";
import { getBranchData, getRestaurantData } from "./API/fetch";
import {
  setBranchAction,
  setBranchesAction,
} from "./redux/reducers/branchReducer";
import {
  editTableAction,
  setTablesAction,
} from "./redux/reducers/tableReducer";
import {
  addDeliveryOrderAction,
  addDineInOrderAction,
  addIwaiterAppOrderAction,
  addPickupOrderAction,
  setCustomOrdersAction,
  setDeliveryOrdersAction,
  setDineInOrdersAction,
  setIwaiterAppOrdersAction,
  setPickupOrdersAction,
  updateOrdersAction,
} from "./redux/reducers/orderReducer";
import openNotification from "./Components/Notifications";
import { setRestaurantAction } from "./redux/reducers/restaurantReducer";
import { setMenuAction } from "./redux/reducers/menuReducer";
import { streamUrl } from "./API/config";
import NotificationModal from "./Components/NotificationModal";
import { toggleAlert } from "./Components/NotificationModal/AlertSound";
import { ORDER_TYPES } from "./Types";
import LoadingScreen from "./Components/LoadingScreen";
import {
  logOutAction, setUserAction
} from "./redux/reducers/authReducer";
import { successAudio } from "./Components/Audion";
import COLORS from "./Style/colors";
import CustomButton from "./Components/CustomButton";
// Sentry.init({
//   dsn:
//     "https://0c2ab9367c9b4b3ebb5138a26b7b5f06@o461354.ingest.sentry.io/5463043",
//   integrations: [new Integrations.BrowserTracing()],

//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0
// });

const App = () => {
  const { rtl, local, fullWidth } = useSelector((state) => state.settings);
  const [api, contextHolder] = notification.useNotification();
  const { waiter, userHasLevel, isLoggedIn, user } = useSelector(
    (state) => state.auth
  );
  const { branch, allBranches } = useSelector((state) => state.branch);
  const { restaurant } = useSelector((state) => state.restaurant);
  const { tables } = useSelector((state) => state.table);
  const {
    dineInOrders: allDineInOrders,
    pickupOrders,
    deliveryOrders,
    iwaiterAppOrders,
    customOrders,
  } = useSelector((state) => state.order);
  const dispatch = useDispatch();
  const eventSource = useRef(null);
  const [loadingData, setLoadingData] = useState(true);
  const [savedOrders, setSavedOrders] = useState(allDineInOrders);
  useEffect(() => {
    setSavedOrders(allDineInOrders);
  }, [allDineInOrders]);

  // set up sentry
  // useEffect(() => {
  //   if(restaurant?.slug){
  //     Sentry.setUser({ username: restaurant?.slug });
  //   }
  // }, [restaurant?.slug])

  // handle resize the screen;
  const handleResizeScreen = useCallback(() => {
    if (fullWidth !== window.innerWidth) {
      let isBigScreen = window.innerWidth >= 600 ? true : false;
      dispatch(
        sefFullWidthAction({ isBigScreen, fullWidth: window.innerWidth })
      );
    }
  }, [dispatch, fullWidth]);
  // handle internet connections;
  const handleInternetConnection = useCallback(
    (value) => {
      dispatch(setIsOfflineAction(value));
    },
    [dispatch]
  );

  useEffect(() => {
    if (isLoggedIn && userHasLevel && waiter?.code) {
      getInitialData(waiter.code);
      setUpSSE({ waiter });
    } else {
      setLoadingData(false);
    }
    return () => {
      if (waiter?.role === "waiter") {
        eventSource?.current?.removeEventListener(
          `branch-${waiter?.branchId}`,
          updateBranch
        );
        eventSource?.current?.removeEventListener(
          `app-branch-${waiter?.branchId}`,
          appUpdateBranch
        );
      } else {
        if (allBranches?.length) {
          allBranches.forEach((branch) => {
            if (branch?._id) {
              eventSource?.current?.removeEventListener(
                `branch-${branch?._id}`,
                updateBranch
              );
            } else if (branch?.branch?._id) {
              eventSource?.current?.removeEventListener(
                `branch-${branch?._id}`,
                updateBranch
              );
            }
          });
        }
      }
    };
  }, [isLoggedIn, userHasLevel]);

  const getInitialData = async (code) => {
    try {
      const restaurantRes = await getRestaurantData();
      const branchRes = await getBranchData(code);
      if (restaurantRes.data?.noRestaurant === true) {
        // console.log("No restaurant");
        return;
      }
      if (restaurantRes.data?.user) {
        dispatch(setUserAction(restaurantRes.data.user));
      }
      dispatch(setRestaurantAction(restaurantRes.data.restaurant));
      dispatch(setMenuAction(restaurantRes.data.menu));
      dispatch(setBranchesAction(branchRes.data.branches));
      if (branchRes.data.isSuper === false) {
        dispatch(setBranchAction(branchRes.data.branches[0]));
      }
      dispatch(setTablesAction(branchRes.data.tables));
      dispatch(setDineInOrdersAction(branchRes.data.orders));
      dispatch(setCustomOrdersAction(branchRes.data.customOrders));
      dispatch(setPickupOrdersAction(branchRes.data.pickupOrders));
      dispatch(setDeliveryOrdersAction(branchRes.data.deliveryOrders));
      dispatch(setIwaiterAppOrdersAction(branchRes.data.iwaiterAppOrders));
      let copyUser = { ...user };
      let copySubscription = { ...copyUser.activeSubscription };
      // Update the subscription data;
      if (branchRes?.data?.endSubscriptionDate) {
        copySubscription.subscriptionEnd = branchRes?.data?.endSubscriptionDate;
      } else {
        // make data yesterday;
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        copySubscription.subscriptionEnd = yesterday;
      }
      // let copyUser = {...user};
      // let copySubscription = {...copyUser.activeSubscription};
      // // Update the subscription data;
      // if(branchRes?.data?.endSubscriptionDate){
      //   copySubscription.subscriptionEnd = branchRes?.data?.endSubscriptionDate;
      // }else{
      //   // make data yesterday;
      //   const yesterday = new Date();
      //   yesterday.setDate(yesterday.getDate() - 1);
      //   copySubscription.subscriptionEnd = yesterday;
      // }
      // copyUser.activeSubscription = copySubscription;
      // dispatch(loginAction({ user: copyUser }));
      setLoadingData(false);
    } catch (error) {
      console.log("error", error);
      // check if error is 401;
      if (error?.response?.status === 401) {
        dispatch(logOutAction());
        return;
      }
      setLoadingData(false);

      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };

  // set up server sent events;
  const setUpSSE = ({ waiter }) => {
    if (waiter?.role === "waiter") {
      eventSource.current = new EventSource(`${streamUrl}`);
      eventSource?.current?.addEventListener(
        `branch-${waiter?.branchId}`,
        updateBranch
      );
      if (restaurant?.doesHaveIwaiterApp) {
        eventSource?.current?.addEventListener(
          `app-branch-${waiter?.branchId}`,
          appUpdateBranch
        );
      }
    } else {
      if (allBranches?.length) {
        eventSource.current = new EventSource(`${streamUrl}`);
        allBranches.forEach(({ branch }) => {
          if (branch?._id) {
            eventSource?.current?.addEventListener(
              `branch-${branch?._id}`,
              updateBranch
            );
          }
        });
      }
    }
  };

  // get permissions for table;
  const getPermissionToTable = (tableId) => {
    return tables?.findIndex((t) => t._id === tableId) !== -1;
  };

  function updateBranch(e) {
    let data = JSON.parse(e.data);
    let parsed = JSON.parse(data);
    let isSuper = waiter?.role === "super";
    if (parsed.waitingList && !isSuper) {
      if (waiter?.receiveWaiting) {
        // update waitign just if the user has permissions to access waiting;
        const { waitingList } = parsed;
        const newBranch = { ...branch, waitingList };
        dispatch(setBranchAction(newBranch));
      }
    } else if (parsed.reservationList && !isSuper) {
      if (waiter?.receiveReservation) {
        // update reservation list just if the user has permissions to access reservation;
        const { reservationList, date } = parsed;
        const newBranch = { ...branch, reservationList };
        dispatch(setBranchAction(newBranch));
      }
    } else if (parsed.deleteOrder) {
      console.log("delete order empty");
    } else if (parsed.updatePayment) {
      console.log("Update payment empty");
    } else if (parsed.updateOrderStatus) {
      const { status, isPickup, orderId, tableId, updatedOrder, table } =
        parsed;
      if (isPickup) {
        if (updatedOrder?.type === "pickup") {
          // check if waiter has permissions to pickup;
          if (waiter?.receivePickup === true) {
            dispatch(
              updateOrdersAction({
                type: ORDER_TYPES.PICKUP,
                payload: updatedOrder,
              })
            );
          }
        } else {
          if (waiter?.receiveDelivery === true) {
            // check if waiter has permissions to delivery;
            dispatch(
              updateOrdersAction({
                type: ORDER_TYPES.DELIVERY,
                payload: updatedOrder,
              })
            );
          }
        }
      } else if (parsed.customOrder) {
        dispatch(
          updateOrdersAction({
            type: ORDER_TYPES.CUSTOM_ORDER,
            payload: updatedOrder,
          })
        );
        if(updatedOrder?.status === 'open'){
          let message = rtl ? "عملية دفع جديدة" : "New payment received";
          let description = rtl
            ? `الطلب رقم ${updatedOrder?.orderNumber || 'n/a'} تم دفعه بنجاح`
            : `Order number ${updatedOrder?.orderNumber || 'n/a'} has been paid successfully`;
          let btn = (
            <CustomButton
              onClick={() => {
                // change the url to the order;
                window.location.href = `${window.location.origin}/customOrders`;
              }}
              type='primary'
              className={"border-8"}
              text={rtl ? "مشاهدة" : "view"}
            />
          );
          api.open({
            message,
            description,
            duration: 0,
            btn,
            placement: !rtl ? "topLeft" : "topRight",
            style: {
              border: `1px solid ${COLORS.gray}`,
            },
            className: "my-font",
          });
  
          successAudio
            .play()
            .then(() => console.log("Playing"))
            .catch((err) => {
              console.log(err);
            });
        }
       
      } else {
        // Dine in update;
        const hasPermission = getPermissionToTable(table?._id);
        if (hasPermission) {
          if (!isSuper) {
            dispatch(editTableAction(table));
          }
          dispatch(
            updateOrdersAction({
              type: ORDER_TYPES.DINE_IN,
              payload: updatedOrder,
            })
          );
        }
      }
      // end update POS Number;
    } else if (parsed.updatePOS) {
      const { posData, isPickup, orderId, tableId, updatedOrder, table } =
        parsed;
      if (isPickup) {
        if (updatedOrder?.type === "pickup") {
          // check if waiter has permissions to pickup;
          if (waiter?.receivePickup === true) {
            dispatch(
              updateOrdersAction({
                type: ORDER_TYPES.PICKUP,
                payload: updatedOrder,
              })
            );
          }
        } else {
          if (waiter?.receiveDelivery === true) {
            // check if waiter has permissions to delivery;
            dispatch(
              updateOrdersAction({
                type: ORDER_TYPES.DELIVERY,
                payload: updatedOrder,
              })
            );
          }
        }
      } else {
        // Dine in orders;
        const hasPermission = getPermissionToTable(table?._id);
        if (hasPermission) {
          if (!isSuper) {
            dispatch(editTableAction(table));
          }
          dispatch(
            updateOrdersAction({
              type: ORDER_TYPES.DINE_IN,
              payload: updatedOrder,
            })
          );
        }
      }
    } else {
      // New orders order need help notification;
      const { orders, table, modifyByAdmin, pickup, orderFromCashier } = parsed;
      if (table && !isSuper) {
        // check if the user has access to the table;
        let hasPermission = getPermissionToTable(table._id);
        if (hasPermission) {
          dispatch(editTableAction(table));
          if (!modifyByAdmin && (!orders || !orders?._id)) {
            toggleAlert({
              status: "start",
              table: table._id,
              tables,
              waiter,
              rtl,
            });
            let text = table.status;
            if (table.needHelp) {
              text = "needHelp";
            } else if (table.needPayment) {
              text = "needPayment";
            }
            dispatch(setNotificationTextAction(`${table._id}-${text}`));
          }
        } // end permissions;
      }

      if (orders) {
        if (pickup) {
          // if user arrived to the restaurant;
          let myPickupOrders = [...pickupOrders, ...deliveryOrders];
          const i = myPickupOrders.findIndex((o) => o._id === orders._id);

          if (orders?.deliverToCar?.status === "customerArrived") {
            if (waiter?.receivePickup) {
              let text = "customerArrived";
              let typeAlert = "customerArrived";
              dispatch(
                updateOrdersAction({
                  type: ORDER_TYPES.PICKUP,
                  payload: orders,
                })
              );
              if (!isSuper) {
                dispatch(setNotificationTextAction(`${orders._id}-${text}`));
                toggleAlert({
                  status: "start",
                  table: typeAlert,
                  orderId: orders._id,
                  tables,
                  waiter,
                  rtl,
                });
              }
            } // end check if waiter has permissions to pickup;
          } else if (i === -1) {
            // New order pickup alert;
            let text = "pickup-newOrder";
            let typeAlert = "pickup";
            if (orders.type === "delivery") {
              text = "delivery-newOrder";
              typeAlert = "delivery";
            }
            if (typeAlert === "delivery") {
              if (waiter?.receiveDelivery) {
                dispatch(addDeliveryOrderAction(orders));
                if (!isSuper) {
                  dispatch(setNotificationTextAction(`${text}`));
                }
              }
            } else {
              if (waiter?.receivePickup) {
                dispatch(addPickupOrderAction(orders));
                if (!isSuper) {
                  dispatch(setNotificationTextAction(`${text}`));
                }
              }
            }

            if (!orderFromCashier && !isSuper) {
              toggleAlert({
                status: "start",
                table: typeAlert,
                tables,
                waiter,
                rtl,
              });
            }
          }
        } else {
          // Dine in order alert;
          const i = savedOrders.findIndex((o) => o._id === orders._id);
          if (i === -1) {
            let hasPermission = getPermissionToTable(orders.tableId);
            if (hasPermission) {
              dispatch(addDineInOrderAction(orders));
              if (!isSuper) {
                dispatch(setNotificationTextAction(`${table._id}-newOrder`));
              }
              if (!orderFromCashier && !isSuper) {
                toggleAlert({
                  status: "start",
                  table: orders.tableId,
                  tables,
                  waiter,
                  rtl,
                });
              }
            }
          }
        }
      }
    }
  }
  const appUpdateBranch = (e) => {
    // console.log("In update branch app", e);
    let data = JSON.parse(e.data);
    let parsed = JSON.parse(data);
    let isSuper = waiter?.role === "super";
    if (parsed?.orders) {
      const { orders } = parsed;
      // if user arrived to the restaurant;
      let myPickupOrders = [...iwaiterAppOrders];
      const i = myPickupOrders.findIndex((o) => o._id === orders._id);

      if (orders?.deliverToCar?.status === "customerArrived") {
        if (waiter?.receivePickup) {
          let text = "customerArrived";
          let typeAlert = "customerArrived";
          dispatch(
            updateOrdersAction({
              type: ORDER_TYPES.PICKUP,
              payload: orders,
            })
          );
          if (!isSuper) {
            dispatch(setNotificationTextAction(`${orders._id}-${text}`));
            toggleAlert({
              status: "start",
              table: typeAlert,
              orderId: orders._id,
              tables,
              waiter,
              rtl,
            });
          }
        } // end check if waiter has permissions to pickup;
      } else if (i === -1) {
        // New order pickup alert;
        let text = "iwaiterApp-newOrder";
        let typeAlert = "iwaiterApp";
        if (waiter?.receiveIWaiterApp) {
          dispatch(addIwaiterAppOrderAction(orders));
          if (!isSuper) {
            dispatch(setNotificationTextAction(`${text}`));
          }
        }

        if (!isSuper) {
          toggleAlert({
            status: "start",
            table: typeAlert,
            tables,
            waiter,
            rtl,
          });
        }
      }
    } else if (parsed.orderId) {
      // update order;
    }
  };

  // listeners for internet and resize screen;
  useEffect(() => {
    if (navigator && navigator.onLine === false) {
      handleInternetConnection(true);
    }
    window.addEventListener("online", () => handleInternetConnection(false));
    window.addEventListener("offline", () => handleInternetConnection(true));
    window.addEventListener("resize", handleResizeScreen);
    return () => {
      window.removeEventListener("online", () =>
        handleInternetConnection(false)
      );
      window.removeEventListener("offline", () =>
        handleInternetConnection(true)
      );
      window.addEventListener("resize", handleResizeScreen);
    };
  }, [handleInternetConnection, handleResizeScreen]);
  return (
    <ConfigProvider direction={rtl ? "rtl" : "ltr"}>
      {/* <Sentry.ErrorBoundary fallback={"An error has occured"}> */}
      <IntlProvider locale={local} messages={messages[local]}>
        <Router>
          {contextHolder}
          <NotificationModal />
          {loadingData ? <LoadingScreen /> : <AllRoutes />}
        </Router>
      </IntlProvider>
      {/* </Sentry.ErrorBoundary> */}
    </ConfigProvider>
  );
};

export default App;
