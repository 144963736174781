import React, { useEffect, useState } from "react";
import { Card, Typography, Image, Switch, Space } from "antd";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import imagePlaceholder from "../../../../../assets/images/empty-foods.jpg";
import { useDispatch, useSelector } from "react-redux";
import {
  IoHeartSharp,
  IoHeartOutline,
} from "react-icons/io5";
import { useIntl } from "react-intl";
import openNotification from "../../../../../Components/Notifications";
import { postUpdateFood } from "../../../../../API/fetch";
import {
  editCategoryAction,
  editFoodAction,
} from "../../../../../redux/reducers/menuReducer";
import { arrUpdateByKeyValue } from "../../../../../helpers/array";
import { useNavigate } from "react-router";
import COLORS from "../../../../../Style/colors";
const { Meta } = Card;
const cardStyle = {
  width: "250px",
  height: "340px",
  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
};

const { Text, Title } = Typography;

const FoodCard = ({ food, category }) => {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  const { role } = useSelector((state) => state.auth);
  const { allBranches, branch } = useSelector((state) => state.branch);
  const dispatch = useDispatch();
  const history = useNavigate();
  const [isSoldOut, setIsSoldOut] = useState(false);
  const [isAvailable, setIsAvailable] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let branchesIds = [];
    if (role === "super") {
      branchesIds = allBranches?.map((b) => b?.branch?._id);
    } else {
      branchesIds = allBranches?.map((b) => b?._id);
    }
    let isAvailable = branchesIds.every((id) =>
      food?.notAvailableIn?.includes(id)
    );
    let isSoldOut = branchesIds.every((id) => food?.soldOutIn?.includes(id));
    setIsAvailable(isAvailable);
    setIsSoldOut(isSoldOut);
  }, [food]);

  const toggleFood = async ({ value, type }) => {
    // If v === true add branch id to notAvailableIn array
    setLoading(true);
    let branchIds = [];
    if (role === "super") {
      if (value === true) {
        branchIds = allBranches.map((b) => b?.branch?._id);
      } else {
        branchIds = [];
      }
    } else {
      if (type === "hide") {
        branchIds = [...food.notAvailableIn];
      } else {
        branchIds = [...food.soldOutIn];
      }
      if (value === true) {
        // Add to array
        branchIds.push(branch._id);
      } else {
        // remove form array
        const i = branchIds.indexOf(branch._id);
        if (i !== -1) {
          branchIds.splice(i, 1);
        }
      }
    }
    let data = {
      food: {},
    };
    if (type === "hide") {
      data.food.notAvailableIn = branchIds;
    } else {
      data.food.soldOutIn = branchIds;
    }

    try {
      const res = await postUpdateFood(food.foodId, data);
      if (res.data?.status === "success") {
        const newFood = {
          ...food,
        };
        if (type === "hide") {
          newFood.notAvailableIn = branchIds;
        } else {
          newFood.soldOutIn = branchIds;
        }
        dispatch(editFoodAction(newFood));
        // edit the food  item inside the foods array inside the category;
        const newFoods = arrUpdateByKeyValue({
          arr: category.foods,
          key: "foodId",
          value: food.foodId,
          newElement: newFood,
        });
        const newCategory = {
          ...category,
          foods: newFoods,
        };
        dispatch(editCategoryAction(newCategory));
      }
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  return (
    <Card
      loading={false}
      style={cardStyle}
      className='my-2 my-font'
      cover={
        <Image
          width={250}
          style={{ height: 130, objectFit: "cover" }}
          preview={false}
          onClick={() => history(`/foods/${food.foodId}`)}
          className='pointer'
          placeholder={
            <Image
              preview={false}
              src={imagePlaceholder}
              width={250}
              height={130}
              onClick={() => history(`/foods/${food.foodId}`)}
            />
          }
          src={food?.images?.[0]?.url || imagePlaceholder}
          fallback={imagePlaceholder}
        />
      }
      actions={[
        <EditOutlined
          key='edit'
          onClick={() => history(`/foods/${food.foodId}`)}
        />,
      ]}
    >
      <Meta
        title={
          <Space
            direction='horizontal'
            style={{ width: "100%", justifyContent: "space-between" }}
          >
            <Title level={5}>{rtl ? food.name : food.name_en}</Title>
            <Text
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              {food?.likes?.length}
              {food?.likes?.length > 0 ?
            <IoHeartSharp
              size={18}
              color={COLORS.primary}
              style={{
                marginRight: rtl ? 5: 0,
                marginLeft: rtl ? 0: 5,
              }}
              />: 
              <IoHeartOutline
              size={18}
              color={COLORS.primary}
              style={{
                marginRight: rtl ? 5: 0,
                marginLeft: rtl ? 0: 5,
              }}
              />
              }
              </Text>
          </Space>
        }
        description={new Date(food?.createdAt).toLocaleDateString()}
      />
      <div>
        <Text className='my-font'>
          {intl.formatMessage({ id: "unAvailableFood" })}
        </Text>
        <Switch
          className='mx-3'
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={isAvailable}
          onChange={(v) => {
            toggleFood({ value: v, type: "hide" });
            // setIsAvailable(v)
          }}
          loading={loading}
          disabled={loading}
        />
      </div>
      <div>
        <Text className='my-font'>{intl.formatMessage({ id: "soldOut" })}</Text>
        <Switch
          className='mx-3'
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={isSoldOut}
          onChange={(v) => {
            toggleFood({ value: v, type: "soldOut" });
            // setIsSoldOut(v)
          }}
          loading={loading}
          disabled={loading}
        />
      </div>
    </Card>
  );
};

export default FoodCard;
