import React, { useState, useEffect } from "react";
import COLORS from "../../Style/colors";
import {
  Typography,
  Descriptions,
  Divider,
  Select,
  Row,
  Col,
  Input,
  Space,
  Checkbox,
} from "antd";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../Components/CustomButton";
import { GoSell } from "@tap-payments/gosell";
import { postUpdateUserSubscription, verifyPayment } from "../../API/fetch";
import openNotification from "../../Components/Notifications";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  loginAction,
  setServicesAction,
  setSubscriptionEndAction,
  setUserAction,
} from "../../redux/reducers/authReducer";
import UseQuery from "../../Hooks/UseQuery";
import moment from "moment";
import { periods } from "./period-data";
import { validateEmail } from "../../helpers/email";
import NewServicesView from "./NewServicesView";
import { getVatAmount } from "../../helpers";
// import { FaPercent } from "react-icons/fa";

const { Title, Text, Link } = Typography;
const { Option } = Select;
const plans = [
  {
    id: 0,
    name: "MENU",
    time: "yearly",
    total: 600,
    monthly: 50,
  },
  {
    id: 1,
    name: "BASIC",
    time: "yearly",
    total: 1792.8,
    monthly: 166,
  },
  {
    id: 2,
    name: "SILVER",
    time: "yearly",
    total: 3132,
    monthly: 290,
  },
  {
    id: 3,
    name: "GOLD",
    time: "yearly",
    total: 4946.4,
    monthly: 458,
  },
  {
    id: 4,
    name: "PAY_AS_YOU_GO",
    time: "yearly",
    total: 4946.4,
    monthly: 458,
  },
];
const oldPlanNames = ["MENU", "BASIC", "SILVER", "GOLD", "PAY_AS_YOU_GO"];

const Subscription = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { user, subscriptionEnd: subscriptionExpired } = useSelector(
    (state) => state.auth
  );

  const { rtl } = useSelector((state) => state.settings);
  const { restaurant } = useSelector((state) => state.restaurant);
  const { allBranches } = useSelector((state) => state.branch);
  const { pathname } = useLocation();
  const query = UseQuery();
  const [showPayment, setShowPayment] = useState(
    query.get("token") ? true : false
  );
  const [plan, setPlan] = useState({
    id: 0,
    name: "BASIC",
    time: "yearly",
    total: 150,
    selectedTime: 3,
  });
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [vat, setVat] = useState("");
  const [doNotHaveVat, setDoNotHaveVat] = useState(false);
  const [isOldUser, setIsOldUser] = useState(false);
  const [oldSubscription, setOldSubscription] = useState(null);
  const [vatError, setVatError] = useState(false);
  const [cart, setCart] = useState({});
  const [showPaymentView, setShowPaymentView] = useState(false);
  const [searchParams] = useSearchParams();
  const tap_id = searchParams.get("tap_id");

  useEffect(() => {
    let isOld = false;
    let planName = "";
    if (user && user?.services?.length > 0) {
      // check if user has an old service;
      const oldServices = user?.services.filter(
        (service) =>
          oldPlanNames.includes(service?.serviceType) &&
          service?.status === "Active"
      );
      if (oldServices?.length) {
        let oldService = oldServices[0];
        oldServices.forEach((service) => {
          // check if the old service is the last service end;
          if (moment(service?.serviceEnd).isAfter(oldService?.serviceEnd)) {
            oldService = service;
          }
        });
        // check if the end date is in the last 7 days;
        const endDate = moment(oldService?.serviceEnd);
        const now = moment();
        const diff = now.diff(endDate, "days");
        if (diff <= 7) {
          isOld = true;
          planName = oldService?.serviceType;
        }
        setOldSubscription(oldService);
      }
    }
    if (isOld) {
      const selected = plans.find((plan) => plan.name === planName);
      setIsOldUser(true);
      setPlan({ ...selected, selectedTime: 3, total: 3 * selected.monthly });
    }

    if (restaurant?.email) {
      setEmail(restaurant.email);
    }
    if (restaurant?.vatNumber) {
      setVat(restaurant.vatNumber);
    }
  }, [user]);

  useEffect(() => {
    if(tap_id){
      verifyPaymentFunc(tap_id)
    }
  },[tap_id])

  const handleRenew = async () => {
    try {
      const dataToSave = {
        user,
        oldSubscription,
        subscription: {
          total: plan.total,
          time: plan.time,
          id: plan.id,
          name: plan.name,
          selectedTime: plan.selectedTime,
          subscriptionExpired: subscriptionExpired,
          email,
          vatAmount: getVatAmount(plan.total),
          vat,
        },
      };
      setShowPayment(true);
      localStorage.setItem("userData", JSON.stringify(dataToSave));
      setLoading(true);
      setTimeout(() => {
        GoSell.openLightBox();
      }, 1000);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const callbackFunc = (values) => {
    if (values?.callback?.status === "CAPTURED") {
      verifyPaymentFunc(values.callback.id);
    }
  };
  const verifyPaymentFunc = async (id) => {
    if (!id) return;

    try {
      const res = await verifyPayment(id);
      if (res?.data?.status === "paid") {
        updateSubscription(id);
      } else {
        let message = "";
        if (res?.data?.response?.message) {
          message = res.data.response.message;
        } else {
          message = "حدث خطأ في الدفع نرجو المحاولة لاحقاً";
        }
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: message,
          type: "error",
          rtl,
        });

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };
 
  // console.log(data)
  const updateSubscription = async (id) => {
    try {
      let dataToSave = localStorage.getItem("userData");
      let servicesFormatted = localStorage.getItem("servicesFormatted");
      let parsedServices = JSON.parse(servicesFormatted);
      let parsed = JSON.parse(dataToSave);
      let data;
      if(parsed && parsed?.oldSubscription){
        let subscriptionStart = moment(parsed?.oldSubscription?.serviceEnd);
      // check if the end date is less than today;
      if (subscriptionStart.isBefore(moment())) {
        subscriptionStart = moment();
      }
      let months = +parsed?.subscription?.selectedTime;
      let newSubscriptionEnd = moment(subscriptionStart).add(months, "months");

      let subscriptionType = parsed.subscription.id;

       data = {
        subscription: {
          subscriptionType,
          subscriptionEnd: newSubscriptionEnd.endOf("day").toDate(),
          subscriptionStart: subscriptionStart.startOf("day").toDate(),
          cost: parsed.subscription.total,
          moyasar_id: id,
          other_payment_id: id,
          other_payment_name: "TAB",
          owner: parsed.user._id,
        },
        restaurant: {
          email: parsed.subscription.email,
          vatNumber: parsed.subscription.vat,
        },
        paymentId: id,
        services: [],
        type: "old"
      };
      }else if(parsedServices && parsedServices?.length > 0) {
         data = {
          subscription: {},
          restaurant: {
            email: email,
            vatNumber: vat,
          },
          paymentId: id,
          services: parsedServices,
          type: 'new'
        };
      }
      if(!data){
        setLoading(false);
        localStorage.removeItem("userData");
        localStorage.removeItem("servicesFormatted");
        return;
      }
      const res = await postUpdateUserSubscription(data);

      if (res.data.user) {
        localStorage.removeItem("userData");
        localStorage.removeItem("servicesFormatted");
        dispatch(setUserAction(res.data.user));
        dispatch(setServicesAction(res.data.services));
        dispatch(setSubscriptionEndAction(false));
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      localStorage.removeItem("userData");
      localStorage.removeItem("servicesFormatted");
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  // const pathname = history.location.pathname;

  // const getPlanUpdate = (e) => {
  //   const today = moment();
  //   const subscriptionEnd = moment(user.activeSubscription.subscriptionEnd);
  //   const remainingDays = subscriptionEnd.diff(today, "days");
  //   // get the price for one day;
  //   const oldPlan = plans.find(
  //     (e) => e.id === user.activeSubscription.subscriptionType
  //   );
  //   const priceForOneDay = oldPlan.monthly / 30;
  //   const paidTotal = remainingDays * priceForOneDay;
  //   if (paidTotal <= 0) {
  //     return 0;
  //   }
  //   return parseFloat(paidTotal.toFixed(2));
  // };

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <Title>{intl.formatMessage({ id: "subscription" })}</Title>
      </div>
      {isOldUser ? (
        <div
          style={{
            backgroundColor: COLORS.white,
            marginTop: 10,
            width: "92%",
            minHeight: "70vh",
            marginRight: "auto",
            marginLeft: "auto",
            borderRadius: 10,
            paddingBottom: 15,
          }}
          className='light-shadow'
        >
          <div className='px-5 py-5'>
            <Descriptions
              bordered
              className='my-font'
              title={
                <Row
                  style={{ width: "100%" }}
                  align='center'
                  justify='space-between'
                  direction='horizontal'
                >
                  <Col span={10}>
                    <Space direction='vertical'>
                      <Text style={{ marginBottom: 0, paddingBottom: 0 }}>
                        {intl.formatMessage({ id: "subscription" })}
                      </Text>
                      <Text
                        type='success'
                        style={{
                          marginBottom: 0,
                          paddingBottom: 0,
                          marginTop: 0,
                          paddingTop: 0,
                        }}
                      >
                        {intl.formatMessage({ id: "renewYearWithDiscount" })}
                        {intl.formatMessage({ id: "ten" })}
                      </Text>
                    </Space>
                  </Col>
                </Row>
              }
            >
              <Descriptions.Item
                span={3}
                className='my-font'
                label={intl.formatMessage({ id: "yourPlan" })}
              >
                {intl.formatMessage({
                  id: oldSubscription?.serviceType,
                })}
              </Descriptions.Item>
              <Descriptions.Item
                label={intl.formatMessage({ id: "subscriptionStartDate" })}
              >
                {moment(oldSubscription?.serviceStart).format("YYYY-MM-DD")}
              </Descriptions.Item>
              <Descriptions.Item
                label={intl.formatMessage({ id: "subscriptionEndDate" })}
              >
                {moment(oldSubscription?.serviceEnd).format("YYYY-MM-DD")}
              </Descriptions.Item>
            </Descriptions>
          </div>
          <Title level={5} className=' mt-3 px-5'>
            {intl.formatMessage({ id: "renewSubscription" })}
          </Title>
          <Row className='mx-2'>
            {/* {plan?.id === 3 ? null : ( */}
            <Col className='mx-2 mt-2'>
              <Text className='my-font'>
                {intl.formatMessage({ id: "choosePackage" })}
              </Text>
              <Select
                className='my-font'
                style={{ display: "flex", minWidth: 250 }}
                onChange={(e) => {
                  // let newPlan = plans.find((p) => p.id === e);
                  // newPlan.total = newPlan.monthly * plan.selectedTime;
                  // newPlan.selectedTime = 0;
                  // newPlan.total = 0;
                  // setPlan(newPlan);
                }}
                value={plan.id}
                disabled={true}
              >
                <Option
                  disabled={true}
                  className='my-font'
                  key={plan.id}
                  value={plan.id}
                >
                  {intl.formatMessage({ id: plan.name })}
                </Option>
              </Select>
            </Col>
            {/* )} */}
            <Col className='mx-2 mt-2'>
              <Text className='my-font'>
                {intl.formatMessage({ id: "periodMonth" })}
              </Text>
              <Select
                className='my-font'
                style={{ display: "flex", minWidth: 250 }}
                onChange={(e) => {
                  let isYear = e === 12;
                  // check if user did paid;
                  if (!subscriptionExpired) {
                    // const remainingAmount = getPlanUpdate(e);
                    // same package;
                    let total = e * plan.monthly;
                    if (isYear) {
                      total = e * plan.monthly - (e * plan.monthly * 10) / 100;
                    }
                    setPlan({ ...plan, selectedTime: e, total });
                  } else {
                    let total = e * plan.monthly;
                    if (isYear) {
                      total = e * plan.monthly - (e * plan.monthly * 10) / 100;
                    }
                    setPlan({ ...plan, selectedTime: e, total });
                  }
                }}
                value={plan.selectedTime}
              >
                {periods.map((period) => (
                  <Option
                    className='my-font'
                    key={period.period}
                    value={period.period}
                  >
                    {rtl ? period.ar : period.en}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
          <Row className='px-3 mt-4'>
            <Link href='https://iwaiter.net' target='_blank'>
              <Text type='secondary'>
                * {intl.formatMessage({ id: "newPackagesPlans" })}
              </Text>
            </Link>
          </Row>
          <Row
            className='mx-2 mt-3'
            style={{
              justifyContent: "space-around",
            }}
          >
            <Col sm={24} md={11} lg={11} xl={11} className='mt-2'>
              <Text className='my-font mb-1'>
                <span style={{ color: "red" }}>*</span>{" "}
                {intl.formatMessage({ id: "email" })}
              </Text>
              <Input
                className='mx-2'
                type='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Col>
            <Col sm={24} md={11} lg={11} xl={11} className='mt-2'>
              <Text className='my-font mb-1'>
                <span style={{ color: "red" }}>*</span>{" "}
                {intl.formatMessage({ id: "vatNumber" })}
              </Text>
              <Input
                className='mx-2'
                type='number'
                value={vat}
                style={{
                  border: vatError ? "1px solid red" : "1px solid #d9d9d9",
                }}
                onChange={(e) => {
                  setVat(e.target.value);
                  if (doNotHaveVat) {
                    setDoNotHaveVat(false);
                  }
                  // if the e.target.value is less than 15 and doNotHaveVat is true
                  // make the border red;
                  console.log(doNotHaveVat);
                  if (e.target.value.length !== 15 && !doNotHaveVat) {
                    setVatError(true);
                  } else {
                    setVatError(false);
                  }
                }}
                disabled={doNotHaveVat}
                required
              />
              {/* Add checkbock if the user does not have a vat number; */}
              <Checkbox
                checked={doNotHaveVat}
                onChange={(e) => {
                  setDoNotHaveVat(e.target.checked);
                  if (e.target.checked) {
                    setVat("");
                    setVatError(false);
                  }
                }}
                className='my-font mx-2 mt-2'
              >
                {intl.formatMessage({ id: "doNotHaveVat" })}
              </Checkbox>
            </Col>
          </Row>
          <Row className='mx-2 mt-3'></Row>
          <Divider />
          <div
            className='px-5 '
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Descriptions
              bordered
              className='my-font mb-5'
              title={intl.formatMessage({ id: "orderSummary" })}
            >
              <Descriptions.Item
                span={3}
                label={intl.formatMessage({ id: "subscribeType" })}
              >
                {intl.formatMessage({ id: plan.name })}
              </Descriptions.Item>
              <Descriptions.Item
                label={intl.formatMessage({ id: "period" })}
                span={3}
              >
                {plan.selectedTime} {intl.formatMessage({ id: "month" })}
              </Descriptions.Item>
              <Descriptions.Item
                label={intl.formatMessage({ id: "price" })}
                span={3}
              >
                {plan.monthly * plan.selectedTime} SAR
              </Descriptions.Item>
              <Descriptions.Item
                label={intl.formatMessage({ id: "discount" })}
                span={3}
              >
                {plan.selectedTime === 12
                  ? (plan.monthly * 12 * 10) / 100 + " SAR"
                  : 0 + " SAR"}
              </Descriptions.Item>
              <Descriptions.Item
                label={intl.formatMessage({ id: "vat" })}
                span={3}
              >
                {getVatAmount(plan?.total)?.toFixed(2)} SAR
              </Descriptions.Item>
              <Descriptions.Item
                label={intl.formatMessage({ id: "total" })}
                span={3}
              >
                {(plan.total + getVatAmount(plan?.total))?.toFixed(2)} SAR
              </Descriptions.Item>
            </Descriptions>
            <CustomButton
              text={intl.formatMessage({ id: "renew" })}
              type='primary'
              className='px-5 border-8'
              loading={loading}
              disabled={
                loading ||
                !validateEmail(email) ||
                !plan.total ||
                (vat.length !== 15 && !doNotHaveVat)
              }
              onClick={handleRenew}
            />
            {/* <Text type='primary' strong className='my-font mt-3'>
              * {intl.formatMessage({ id: "renewalFee" })} {plan?.total} +  {getVatAmount(plan?.total)} {intl.formatMessage({ id: "vat" })}
            </Text>
            {plan?.selectedTime === 12 ? (
              <Text strong className='my-font' type='success'>
                * {intl.formatMessage({ id: "youSaved" })}{" "}
                {(plan.monthly * 12 * 10) / 100} SAR
              </Text>
            ) : null} */}
          </div>
          {/* : null} */}
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "80vh",
              zIndex: showPayment ? 9999 : -1,
            }}
          >
            {showPayment ? (
              <GoSell
                gateway={{
                  publicKey: "pk_test_LKFviPZCJUSlNQwnDRO2rVMB",
                  merchantId: "5721647",
                  language: rtl ? "ar" : "en",
                  contactInfo: false,
                  supportedCurrencies: "all",
                  supportedPaymentMethods: [
                    "MADA",
                    "VISA",
                    "MASTERCARD",
                    "APPLE_PAY",
                  ],
                  saveCardOption: true,
                  customerCards: false,
                  notifications: "standard",
                  backgroundImg: {
                    url: "https://iwaiter.net/assets/img/logo/logo2-192.png",
                    opacity: "0.5",
                  },
                  callback: callbackFunc,
                  onClose: (e) => {
                    setLoading(false);
                    localStorage.removeItem("userData");
                    console.log("close", e);
                    setShowPayment(false);
                  },
                  labels: {
                    cardNumber: intl.formatMessage({ id: "cardNumber" }),
                    expirationDate: "MM/YY",
                    cvv: "CVV",
                    cardHolder: intl.formatMessage({ id: "cardName" }),
                    actionButton: intl.formatMessage({ id: "pay" }),
                  },
                }}
                customer={{
                  first_name: user && user.name ? user.name : "No name",
                  //  middle_name: new Date().toLocaleTimeString(),
                  //  last_name: "Last Name",
                  email: restaurant?.email
                    ? restaurant.email
                    : "ana.meen.game@gmail.com",
                  phone: {
                    country_code: "966",
                    number:
                      user && user.phone
                        ? user.phone.substring(3)
                        : "500937188",
                  },
                }}
                order={{
                  amount: plan.total + getVatAmount(plan?.total),
                  currency: "SAR",
                  items: [
                    {
                      id: `${plan.id}`,
                      name: plan.name,
                      description: plan.time,
                      quantity: 1,
                      amount_per_unit: plan.total,
                      total_amount: plan.total,
                    },
                  ],
                  shipping: null,
                  taxes: null,
                }}
                transaction={{
                  mode: "charge",
                  charge: {
                    saveCard: false,
                    threeDSecure: true,
                    description: `Payment online for ${plan.time} subscription on  ${plan.name} plan for ${restaurant?.name} `,
                    metadata: {
                      userId: user._id,
                      userPhone: user.phone,
                      time: plan.time,
                      subscriptionType: plan.id,
                    },
                    receipt: {
                      email: false,
                      sms: true,
                    },
                    redirect: `${window.location.origin}${pathname}`,
                    // post: `${apiUrl}/payments`,
                  },
                }}
              />
            ) : null}
          </div>
        </div>
      ) : (
        <div
          style={{
            backgroundColor: COLORS.white,
            marginTop: 10,
            width: "100%",
            minHeight: "70vh",
            marginRight: "auto",
            marginLeft: "auto",
            borderRadius: 10,
            paddingBottom: 15,
          }}
          className='light-shadow'
        >
          <GoSell
            gateway={{
              publicKey: "pk_test_LKFviPZCJUSlNQwnDRO2rVMB",
              merchantId: "5721647",
              language: rtl ? "ar" : "en",
              contactInfo: false,
              supportedCurrencies: "all",
              supportedPaymentMethods: [
                "MADA",
                "VISA",
                "MASTERCARD",
                "APPLE_PAY",
              ],
              saveCardOption: true,
              customerCards: false,
              notifications: "standard",
              backgroundImg: {
                url: "https://iwaiter.net/assets/img/logo/logo2-192.png",
                opacity: "0.5",
              },
              callback: callbackFunc,
              onClose: (e) => {
                setLoading(false);
                localStorage.removeItem("servicesFormatted");
                console.log("close", e);
                setShowPaymentView(false);
              },
              labels: {
                cardNumber: intl.formatMessage({ id: "cardNumber" }),
                expirationDate: "MM/YY",
                cvv: "CVV",
                cardHolder: intl.formatMessage({ id: "cardName" }),
                actionButton: intl.formatMessage({ id: "pay" }),
              },
            }}
            customer={{
              first_name: user && user.name ? user.name : "No name",
              email: restaurant?.email
                ? restaurant.email
                : "ana.meen.game@gmail.com",
              phone: {
                country_code: "966",
                number:
                  user && user.phone ? user.phone.substring(3) : "500937188",
              },
            }}
            order={{
              amount:
                Object.keys(cart).reduce((acc, key) => {
                  return acc + cart[key].total * allBranches.length;
                }, 0) +
                getVatAmount(
                  Object.keys(cart).reduce((acc, key) => {
                    return acc + cart[key].total * allBranches.length;
                  }, 0)
                ),
              currency: "SAR",
              items: Object.keys(cart).map((key) => {
                const plan = cart[key];
                return {
                  id: `${plan.id}`,
                  name: plan.name,
                  description: plan.time,
                  quantity: 1,
                  amount_per_unit: plan.total,
                  total_amount: plan.total,
                };
              }),
              shipping: null,
              taxes: null,
            }}
            transaction={{
              mode: "charge",
              charge: {
                saveCard: false,
                threeDSecure: true,
                description: `Payment online for ${"plan.time"} subscription on  ${"plan.name"} plan for ${"restaurant?.name"} `,
                metadata: {
                  // userId: user._id,
                  // userPhone: user.phone,
                  // time: plan.time,
                  // subscriptionType: plan.id,
                },
                receipt: {
                  email: false,
                  sms: true,
                },
                redirect: `${window.location.origin}${pathname}`,
                // post: `${apiUrl}/payments`,
              },
            }}
          />
          {showPaymentView ? null : (
            <NewServicesView
              services={user?.services}
              loading={loading}
              email={email}
              setEmail={setEmail}
              vat={vat}
              setVat={setVat}
              vatError={vatError}
              setVatError={setVatError}
              doNotHaveVat={doNotHaveVat}
              setDoNotHaveVat={setDoNotHaveVat}
              GoSell={GoSell}
              cart={cart}
              setCart={setCart}
              setShowPaymentView={setShowPaymentView}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Subscription;
