import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Tag, Table, } from "antd";
import {  useSelector } from "react-redux";
import COLORS from "../../Style/colors";


import WaitingUserActions from "./Components/WaitingUserActions";
const ServedUserTable = ({ users, startNumber }) => {
  const intl = useIntl();

  const { rtl } = useSelector((state) => state.settings);
  const [formattedUsers, setFormattedUsers] = useState([]);

  useEffect(() => {
    if (users?.length) {
      const active = users.map((user, i) => {
        return {
          number: startNumber + i + 1,
          name: user.name,
          phone: rtl ? `${user.phone}+` : `+${user.phone}`,
          time: new Date(user?.createdAt).toLocaleTimeString(),
          personsCount: user?.personsCount,
          note: user?.note,
          key: user?._id,
          userObject: user,
        };
      });
      setFormattedUsers(active);
    } else {
      setFormattedUsers([]);
    }
  }, [users]);

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
    },
    {
      title: intl.formatMessage({id: 'justName'}),
      dataIndex: "name",
      key: "name",
    },
    {
      title: intl.formatMessage({ id: "phone" }),
      dataIndex: "phone",
      key: "phone",
      render: (phone) => {
        return <a href={`tel:00${phone}`}>{phone}</a>;
      },
    },
    {
      title: intl.formatMessage({ id: "time" }),
      dataIndex: "time",
      key: "time",
      sorter: (a, b) =>
        new Date(a.userObject?.createdAt) - new Date(b.userObject?.createdAt),
      showSorterTooltip: false,
    },
    {
      title: intl.formatMessage({ id: "persons" }),
      dataIndex: "personsCount",
      key: "personsCount",
      sorter: (a, b) => a.personsCount - b.personsCount,
      showSorterTooltip: false,
    },
    {
      title: intl.formatMessage({ id: "notes" }),
      dataIndex: "note",
      key: "note",
      render: (note) => {
        return <Tag>{note}</Tag>;
      },
    },
    {
      title: intl.formatMessage({ id: "choices" }),
      dataIndex: "action",
      key: "action",
      render: (__, record) => {
        return <WaitingUserActions record={record} type='served' />;
      },
    },
  ];

  return (
    <div
      style={{
        marginTop: 30,
        backgroundColor: COLORS.white,
        textAlign: "center",
      }}
      className='border-8 light-shadow'
    >
      <Table
        columns={columns}
        pagination={{ hideOnSinglePage: true }}
        dataSource={formattedUsers}
        scroll={{ x: 500 }}
        rowClassName='row-served'
        showHeader={false}
      />
    </div>
  );
};

export default ServedUserTable;
