import React, { useState, useEffect } from "react";
import { Typography } from "antd";
import { FileExcelOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import COLORS from "../../../Style/colors";
import { Table } from "ant-table-extensions";
import { customerExport } from "../../../helpers";

const { Text } = Typography;
const AnswersDetails = ({ answers, questions }) => {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  const { allBranches } = useSelector((state) => state.branch);
  const defaultColumns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
    },
    {
      title: intl.formatMessage({ id: "date" }),
      dataIndex: "date",
      key: "date",
    },
    {
      title: intl.formatMessage({ id: "branch" }),
      dataIndex: "branch",
      key: "branch",
    },
    {
      title: intl.formatMessage({ id: "table" }),
      dataIndex: "table",
      key: "table",
    },
    {
      title: intl.formatMessage({ id: "customerName" }),
      dataIndex: "name",
      key: "name",
    },
    {
      title: intl.formatMessage({ id: "customerPhone" }),
      dataIndex: "phone",
      key: "phone",
    },
  ];
  const [column, setColumn] = useState(defaultColumns);
  const [formattedData, setFormattedData] = useState([]);

  useEffect(() => {
    if (questions?.length) {
      const formatted = questions.map((q) => {
        return {
          key: q._id,
          title: rtl ? q.question : q.question_en,
          dataIndex: q._id,
        };
      });
      setColumn([...defaultColumns, ...formatted]);
    }
    if (answers?.length) {
      const formatted = answers.map((answer, i) => {
        const branch = allBranches.find(
          (branch) => branch?.branch?._id === answer?.branch
        );
        let table = branch?.tables?.find((t) => t._id === answer?.table);
        const customAnswers = {};
        answer?.userAnswers?.forEach((one) => {
          let formatted = one.answer;
          if (one.answerType === "stars" || one.answerType === "faces") {
            formatted = `${one.answer} / 5`;
          }
          customAnswers[one.questionId] = formatted;
        });
        return {
          key: answer._id,
          number: i + 1,
          branch: rtl ? branch?.branch?.name : branch?.branch?.name_en,
          table: answer?.isPickup
            ? intl.formatMessage({ id: "pickup" })
            : rtl
            ? table?.name
            : table?.name_en,
          date: new Date(answer.createdAt).toLocaleString(),
          name: answer?.customerId?.name || "N/A",
          phone: answer?.customerId?.phone || "N/A",
          ...customAnswers,
        };
      });
      setFormattedData(formatted);
    }
  }, [answers, questions]);

  return (
    <div>
      <Table
        exportable
        exportableProps={{
          showColumnPicker: true,
          btnProps: {
            icon: <FileExcelOutlined />,
            type: "primary",
            style: { borderRadius: 7 },
            onClick: () =>
              customerExport({
                data: formattedData,
                columns: column,
                fileName: "Answer Report",
                loadingSetter: () => true,
              }),
          },
          children: (
            <Text
              className='my-font'
              style={{
                color: COLORS.white,
              }}
            >
              {intl.formatMessage({ id: "export" })}
            </Text>
          ),
        }}
        columns={column}
        pagination={{
          hideOnSinglePage: true,
        }}
        dataSource={formattedData}
        scroll={{ x: 1000 }}
      />
    </div>
  );
};

export default AnswersDetails;
