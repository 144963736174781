import { createSlice, current } from "@reduxjs/toolkit";
import { WAITING_LIST_USER_STATUS } from "../../../Types";

const initialState = {
  branch: null,
  allBranches: [],
  selectedBranch: null,
  activeWaitingList: 0,
  isCloseTmp: false,
};

export const branchSlice = createSlice({
  name: "branch",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setBranch: (state, action) => {
      state.branch = action.payload;
      // calculate the active waiting list;
      state.activeWaitingList = action.payload?.waitingList?.filter(
        (u) => u?.status === WAITING_LIST_USER_STATUS.ACTIVE
      ).length;
      // check if branch is close temporary;
      if (new Date(action.payload?.closeUntil).getTime() > new Date().getTime()) {
        state.isCloseTmp = true;
      }
    },
    setBranches: (state, action) => {
      state.allBranches = action.payload;
    },
    editBranchInAllBranch: (state, action) => {
      const copyCurrentBranches = [...current(state.allBranches)]
      const index = copyCurrentBranches.findIndex(
        (b) => b.branch._id === action.payload._id
      );
      const copyBranch = { ...copyCurrentBranches[index], branch: {...action.payload} };
      copyCurrentBranches.splice(index, 1, copyBranch);
      state.allBranches = copyCurrentBranches;
    },
    setSelectedBranch: (state, action) => {
      state.selectedBranch = action.payload;
    },
    setIsCloseTmp: (state, action) => {
      state.isCloseTmp = action.payload;
    },
  },
});

export const {
  setBranches: setBranchesAction,
  setBranch: setBranchAction,
  setSelectedBranch: setSelectedBranchAction,
  setIsCloseTmp: setIsCloseTmpAction,
  editBranchInAllBranch: editBranchInAllBranchAction,
} = branchSlice.actions;

export default branchSlice.reducer;
