import { DeleteColumnOutlined } from "@ant-design/icons";
import {
  Col,
  Descriptions,
  Divider,
  Table,
  Typography,
  Row,
  Input,
  Checkbox,
} from "antd";
import moment from "moment/moment";
import React, { useState, useEffect } from "react";
import { MdDeleteForever } from "react-icons/md";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { getPaymentLink, postUpdateRestaurant } from "../../API/fetch";
import CustomButton from "../../Components/CustomButton";
import { getVatAmount } from "../../helpers";
import { validateEmail } from "../../helpers/email";
import COLORS from "../../Style/colors";
const { Text, Title } = Typography;
function OrderSummary({
  cart,
  loading,
  setCart,
  email,
  setEmail,
  vat,
  setVat,
  vatError,
  setVatError,
  doNotHaveVat,
  setDoNotHaveVat,
  GoSell,
  setShowPaymentView,
}) {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  const { user, waiter } = useSelector((state) => state.auth);
  const { restaurant } = useSelector((state) => state.restaurant);
  const { allBranches } = useSelector((state) => state.branch);

  const [dataSource, setDataSource] = useState([]);
  const [loadingCreateLink, setLoadingCreateLink] = useState(false);

  useEffect(() => {
    if (Object.keys(cart)?.length) {
      setDataSource(
        Object.keys(cart).map((key) => {
          return {
            key,
            service: intl.formatMessage({ id: key }),
            price: cart[key].total,
            duration: cart[key].duration,
            delete: "",
          };
        })
      );
    } else {
      setDataSource([]);
    }
  }, [cart]);

  const handlePayment = async () => {
    // get the total amount;
    let total = 0;
    Object.keys(cart).forEach((key) => {
      total += cart[key].total * allBranches.length;
    });
    // check credit amount;
    // if (creditAmount > 0) {
    //   total -= creditAmount;
    // }
    if (total > 0) {
      let servicesFormatted = Object.keys(cart).map((key) => {
        // get the time that the service should be started;
        let startDate = moment();
        if (user?.services?.length) {
          user?.services?.forEach((s) => {
            if (
              s?.serviceType === key &&
              moment(s?.serviceEnd)
                .endOf("day")
                .isSameOrAfter(moment().endOf("day")) &&
              moment(s?.serviceEnd).endOf("day").isAfter(startDate)
            ) {
              startDate = moment(s?.serviceEnd);
            }
          });
        }
        let endDate = moment(startDate).add(cart[key].duration, "months");

        return {
          serviceType: key,
          services: [key],
          price: allBranches.length * cart[key].price * cart[key].duration,
          vatFees: getVatAmount(
            cart[key].price * cart[key].duration * allBranches.length
          ),
          amountPaid: cart[key].total * allBranches.length,
          serviceStart: startDate.toISOString(),
          serviceEnd: endDate.toISOString(),
        };
      });
      localStorage.setItem(
        "servicesFormatted",
        JSON.stringify(servicesFormatted)
      );
      try {
        if (!restaurant?.vatNumber || !restaurant?.email) {
          const dataRest = {
            restaurant: {
              vatNumber: vat,
              email: email,
            },
            code: waiter.code,
          };
          await postUpdateRestaurant(restaurant._id, dataRest);
        }
      } catch (error) {
        console.log("erro update restaurant", error);
      }
      // window scroll to top;
      // window.scrollTo(0, 0);
      setShowPaymentView(true);
      // createPaymentLink(total);
      setTimeout(() => {
        GoSell.openLightBox();
      }, 1000);
    }
  };

  const createPaymentLink = async (total) => {
    try {
      let servicesFormatted = Object.keys(cart).map((key) => {
        // get the time that the service should be started;
        let startDate = moment();
        if (user?.services?.length) {
          user?.services?.forEach((s) => {
            if (
              s?.serviceType === key &&
              moment(s?.serviceEnd)
                .endOf("day")
                .isSameOrAfter(moment().endOf("day")) &&
              moment(s?.serviceEnd).endOf("day").isAfter(startDate)
            ) {
              startDate = moment(s?.serviceEnd);
            }
          });
        }
        let endDate = moment(startDate).add(cart[key].duration, "months");

        return {
          serviceType: key,
          services: [key],
          price:  allBranches.length *  cart[key].price * cart[key].duration,
          vatFees: getVatAmount( allBranches.length * cart[key].price * cart[key].duration),
          amountPaid: allBranches  * cart[key].total,
          serviceStart: startDate.toISOString(),
          serviceEnd: endDate.toISOString(),
        };
      });
      console.log(servicesFormatted)
      setLoadingCreateLink(true);
      const res = await getPaymentLink(
        total + getVatAmount(total),
        rtl ? "ar" : "en"
      );
      if (res.data?.paymentLink) {
        //redirect user to payment page;
        localStorage.setItem(
          "servicesFormatted",
          JSON.stringify(servicesFormatted)
        );
        window.location.href = res.data.paymentLink;
        setLoadingCreateLink(false);
      }
    } catch (error) {
      localStorage.removeItem("servicesFormatted");
      setLoadingCreateLink(false);
      console.log("error", error);
    }
  };

  const columns = [
    {
      title: intl.formatMessage({ id: "delete" }),
      width: 50,
      dataIndex: "delete",
      key: "delete",
      render: (text, record) => {
        return (
          <MdDeleteForever
            onClick={() => {
              const copy = { ...cart };
              delete copy[record.key];
              setCart(copy);
            }}
            color={COLORS.danger}
            size={20}
            className="pointer"
          />
        );
      },
    },
    {
      title: intl.formatMessage({ id: "service" }),
      dataIndex: "service",
      key: "service",
    },
    {
      title: intl.formatMessage({ id: "period" }),
      dataIndex: "duration",
      key: "duration",
    },
    {
      title: intl.formatMessage({ id: "price" }),
      dataIndex: "price",
      key: "price",
    },
  ];

  return (
    <div className="mt-3">
      <Divider />
      <Title level={5}>
        <Text strong>{intl.formatMessage({ id: "orderSummary" })}</Text>
      </Title>
      <Table
        bordered
        pagination={false}
        dataSource={dataSource}
        columns={columns}
        summary={(pageData) => {
          let total = 0;
          pageData.forEach(({ price }) => {
            total += price;
          });
          return (
            <Table.Summary>
              <Table.Summary.Row>
                <Table.Summary.Cell className="summary-bg-gray" colSpan={3}>
                  {intl.formatMessage({ id: "servicesTotal" })}
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  {total ? (
                    <Text type="secondary">{total?.toFixed(2)}</Text>
                  ) : null}
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell className="summary-bg-gray" colSpan={3}>
                  {intl.formatMessage({ id: "branchesNb" })}
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  {total ? (
                    <Text type="secondary">{allBranches.length}</Text>
                  ) : null}
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell className="summary-bg-gray" colSpan={3}>
                  {intl.formatMessage({ id: "totalBeforeVAT" })}
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  {total ? (
                    <Text type="secondary">
                      {(total * allBranches.length).toFixed(2)}
                    </Text>
                  ) : null}
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell className="summary-bg-gray" colSpan={3}>
                  {intl.formatMessage({ id: "vat" })}
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  {total ? (
                    <Text type="secondary">
                      {getVatAmount(total * allBranches.length)?.toFixed(2)}
                    </Text>
                  ) : null}
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell className="summary-bg-gray" colSpan={3}>
                  {intl.formatMessage({ id: "finalTotal" })}
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  {total ? (
                    <Text type="success">
                      {(
                        total * allBranches.length +
                        getVatAmount(total * allBranches.length)
                      )?.toFixed(2)}
                    </Text>
                  ) : null}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      />

      <Row
        className="mx-2 mt-3"
        style={{
          justifyContent: "space-around",
        }}
      >
        <Col sm={24} md={11} lg={11} xl={11} className="mt-2">
          <Text className="my-font mb-1">
            <span style={{ color: "red" }}>*</span>{" "}
            {intl.formatMessage({ id: "email" })}
          </Text>
          <Input
            className="mx-2"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Col>
        <Col sm={24} md={11} lg={11} xl={11} className="mt-2">
          <Text className="my-font mb-1">
            <span style={{ color: "red" }}>*</span>{" "}
            {intl.formatMessage({ id: "vatNumber" })}
          </Text>
          <Input
            className="mx-2"
            type="number"
            value={vat}
            style={{
              border: vatError ? "1px solid red" : "1px solid #d9d9d9",
            }}
            onChange={(e) => {
              setVat(e.target.value);
              if (doNotHaveVat) {
                setDoNotHaveVat(false);
              }
              // if the e.target.value is less than 15 and doNotHaveVat is true
              // make the border red;
              console.log(doNotHaveVat);
              if (e.target.value.length !== 15 && !doNotHaveVat) {
                setVatError(true);
              } else {
                setVatError(false);
              }
            }}
            disabled={doNotHaveVat}
            required
          />
          {/* Add checkbock if the user does not have a vat number; */}
          <Checkbox
            checked={doNotHaveVat}
            onChange={(e) => {
              setDoNotHaveVat(e.target.checked);
              if (e.target.checked) {
                setVat("");
                setVatError(false);
              }
            }}
            className="my-font mx-2 mt-2"
          >
            {intl.formatMessage({ id: "doNotHaveVat" })}
          </Checkbox>
        </Col>
      </Row>

      <CustomButton
        text={intl.formatMessage({ id: "pay" })}
        type="primary"
        className={`btn-text border-8 px-5 pb-5 mt-5`}
        style={{ width: "100%", borderRadius: 8 }}
        disabled={
          Object.keys(cart)?.length === 0 ||
          loading ||
          loadingCreateLink ||
          !validateEmail(email) ||
          (vat.length !== 15 && !doNotHaveVat)
        }
        onClick={handlePayment}
      />
    </div>
  );
}

export default OrderSummary;
