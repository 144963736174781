import React, { useEffect, useState } from "react";
import { Tag, Typography, DatePicker, Form, Select, Space, Image } from "antd";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import {
  DINE_IN_ORDERS_STATUS,
  ORDER_TYPES,
  TAG_COLORS_BY_PAYMENT_STATUS,
  TAG_COLORS_BY_STATUS,
  TAG_COLOR_BY_USER_TYPE,
} from "../../Types";
import { Table } from "ant-table-extensions";
import CustomButton from "../../Components/CustomButton";
import COLORS from "../../Style/colors";
import moment from "moment";
import { postSearchOrders } from "../../API/fetch";
import { customerExport, getDiscountAmount } from "../../helpers";
import { FileExcelOutlined, SearchOutlined } from "@ant-design/icons";
import ReportSummary from "./components/ReportSummary";
import mada from "../../assets/images/Companies/mada.png";
import TABBY from "../../assets/images/Companies/tabby.jpg";
import { FaCcMastercard, FaCcVisa } from "react-icons/fa";
import { couponForItemsReportFormat } from "../../helpers/orders";
import { Link } from "react-router-dom";
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Text, Title } = Typography;
const Reports = () => {
  const intl = useIntl();
  const { waiter } = useSelector((state) => state.auth);
  const { rtl } = useSelector((state) => state.settings);
  const { allBranches } = useSelector((state) => state.branch);
  const { restaurant } = useSelector((state) => state.restaurant);
  const [formattedOrders, setFormattedOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orderType, setOrderType] = useState("all");

  const [form] = Form.useForm();

  useEffect(() => {
    // set the default date to be from 5 days ago to today
    const startDate = moment().startOf("day").subtract(5, "days");
    const endDate = moment().endOf("day");
    form.setFieldsValue({
      date: [startDate, endDate],
    });
    fetchOrders(startDate, endDate, "all", "all");
  }, []);

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
    },
    {
      title: intl.formatMessage({ id: "branch" }),
      dataIndex: "branch",
      key: "branch",
      onFilter: (value, record) => value === record.orderObject?.branchId,
      filters: allBranches?.map(({ branch }) => {
        return {
          text: rtl ? branch?.name : branch?.name_en,
          value: branch?._id,
        };
      }),
    },
    {
      title: intl.formatMessage({ id: "orderStatus" }),
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return (
          <Tag color={TAG_COLORS_BY_STATUS[status]}>
            {intl.formatMessage({
              id:
                status === DINE_IN_ORDERS_STATUS.CLOSE
                  ? "closedOrder"
                  : status
                  ? status
                  : "n/a",
            })}
          </Tag>
        );
      },
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      filters: [
        {
          text: intl.formatMessage({ id: DINE_IN_ORDERS_STATUS.OPEN }),
          value: DINE_IN_ORDERS_STATUS.OPEN,
        },
        {
          text: intl.formatMessage({ id: DINE_IN_ORDERS_STATUS.IN_SERVER }),
          value: DINE_IN_ORDERS_STATUS.IN_SERVER,
        },
        {
          text: intl.formatMessage({ id: DINE_IN_ORDERS_STATUS.SERVED }),
          value: DINE_IN_ORDERS_STATUS.SERVED,
        },
        {
          text: intl.formatMessage({ id: "closedOrder" }),
          value: DINE_IN_ORDERS_STATUS.CLOSE,
        },
      ],
    },
    {
      title: intl.formatMessage({ id: "time" }),
      dataIndex: "time",
      key: "time",
      sorter: (a, b) =>
        new Date(a.orderObject?.createdAt) - new Date(b.orderObject?.createdAt),
      showSorterTooltip: false,
    },
    {
      title: intl.formatMessage({ id: "orderType" }),
      dataIndex: "type",
      key: "type",
      render: (type) => {
        return (
          <Tag color={TAG_COLOR_BY_USER_TYPE[type]} key={type}>
            {intl.formatMessage({ id: type })}
          </Tag>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "table" }),
      dataIndex: "table",
      key: "table",
    },
    {
      title: intl.formatMessage({ id: "orderNumber" }),
      dataIndex: "orderNumber",
      key: "orderNumber",
    },
    {
      title: intl.formatMessage({ id: "paymentStatus" }),
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      render: (paymentStatus) => {
        return (
          <Tag
            color={TAG_COLORS_BY_PAYMENT_STATUS[paymentStatus]}
            key={paymentStatus}
          >
            {intl.formatMessage({ id: paymentStatus })}
          </Tag>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "paymentMethod" }),
      dataIndex: "paymentMethod",
      key: "paymentMethod",
      render: (paymentMethod) => {
        return (
          <Space size={"small"} direction='horizontal'>
            <Text>{intl.formatMessage({ id: paymentMethod })}</Text>
            {paymentMethod === "MADA" ? (
              <Image
                preview={false}
                src={mada}
                style={{
                  width: 20,
                  height: 10,
                }}
              />
            ) : null}
            {paymentMethod === "VISA" ? (
              <FaCcVisa color={COLORS.primary} size={20} />
            ) : null}
            {paymentMethod === "MASTERCARD" ? (
              <FaCcMastercard color={COLORS.primary} size={20} />
            ) : null}
            {paymentMethod === "TABBY_INSTALLMENT" ? (
              <Image
                preview={false}
                src={TABBY}
                style={{
                  width: 30,
                  height: 20,
                  borderRadius: 5,
                }}
              />
            ) : null}
          </Space>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "couponCodes" }),
      dataIndex: "couponCodes",
      key: "couponCodes",
      render: (couponCodes, record) => {
        return (
          <Text>
            {record?.withGeneralCoupon
              ? `${intl.formatMessage({ id: "couponGeneral" })} ${couponCodes}`
              : record?.withCouponForItems
              ? `${intl.formatMessage({ id: "couponForItems" })} ${couponCodes}`
              : `${couponCodes}`}
          </Text>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "total" }),
      dataIndex: "total",
      key: "total",
    },
    {
      title: intl.formatMessage({ id: "paymentOnlineFees" }),
      dataIndex: "paymentOnlineFees",
      key: "paymentOnlineFees",
    },
    {
      title: intl.formatMessage({ id: "refundAmount" }),
      dataIndex: "refundAmount",
      key: "refundAmount",
    },
    {
      title: intl.formatMessage({ id: "refundAmountFees" }),
      dataIndex: "refundAmountFees",
      key: "refundAmountFees",
    },
    {
      title: intl.formatMessage({ id: "otherCharges" }),
      dataIndex: "otherCharges",
      key: "otherCharges",
    },
    {
      title: intl.formatMessage({ id: "iwaiterAppCommission" }),
      dataIndex: "iwaiterAppCommission",
      key: "iwaiterAppCommission",
    },
    {
      title: intl.formatMessage({ id: "delivery" }),
      dataIndex: "delivery",
      key: "delivery",
    },
    {
      title: intl.formatMessage({ id: "customerName" }),
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: intl.formatMessage({ id: "customerPhone" }),
      dataIndex: "customerPhone",
      key: "customerPhone",
    },
    {
      title: intl.formatMessage({ id: "view" }),
      dataIndex: "key",
      key: "key",
      render: (key, record) => {
        let prefix = "";
        if (record?.orderObject?.type === "inHouse") {
          prefix = "orders";
        } else if (record?.orderObject?.type === "pickup") {
          prefix = "pickup";
        } else if (record?.orderObject?.type === "delivery") {
          prefix = "delivery";
        } else if (record?.orderObject?.type === "CUSTOM_ORDER") {
          prefix = "customOrders";
        } else if (record?.orderObject?.type === "IWAITER_APP") {
          prefix = "iwaiterApp";
        }
        return (
          <Link to={prefix ? `/${prefix}/${key}` : ""}>
            <Text
              style={{
                color: COLORS.primary,
              }}
            >
              {intl.formatMessage({ id: "view" })}
            </Text>
          </Link>
        );
      },
    },
  ];

  const onFinish = async (values) => {
    const [start, end] = values.date;
    const type = values.type;
    setOrderType(type);
    const paymentMethod = values.paymentMethod;
    const startDate = moment(start).set({ hour: 0, minute: 0, second: 0 });
    const endDate = moment(end).set({ hour: 23, minute: 59, second: 59 });
    fetchOrders(startDate, endDate, type, paymentMethod);
  };

  const fetchOrders = async (startDate, endDate, type, paymentMethod) => {
    try {
      setLoading(true);
      const data = {
        orderType: type,
        dateStart: startDate,
        dateEnd: endDate,
        paymentStatus: paymentMethod,
        code: waiter.code,
        page: 1,
        forReports: true,
      };

      const res = await postSearchOrders(data);

      if (res.data?.orders?.length) {
        const sorted = [...res.data.orders];
        let sortedOrders = sorted.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        const formattedOrders = sortedOrders?.map((order, i) => {
          const branch = allBranches?.find(
            (b) => b?.branch?._id === order?.branchId
          );
          const table = branch?.tables?.find((t) => t._id === order?.tableId);
          let orderNumber = "N/A";
          if (order?.orderNumber) {
            if (order?.type === ORDER_TYPES.DINE_IN) {
              orderNumber = "#H " + order?.orderNumber;
            } else if (order?.type === ORDER_TYPES.PICKUP) {
              orderNumber = "#P " + order?.orderNumber;
            } else if (order?.type === ORDER_TYPES.DELIVERY) {
              orderNumber = "#D " + order?.orderNumber;
            } else if (order?.type === ORDER_TYPES.IWAITER_APP) {
              orderNumber = "#A " + order?.orderNumber;
            } else if (order?.type === ORDER_TYPES.CUSTOM_ORDER) {
              orderNumber = "#C " + order?.orderNumber;
            } else {
              orderNumber = "# N/A";
            }
          }

          let total = order?.total;
          if (order?.couponCode?._id) {
            const discount = getDiscountAmount({
              total: order.total,
              percentage: order.couponCode.percentage,
              maximumAmount: order.couponCode.maximumAmount,
            });
            total = total - discount;
          }
          let paymentFees = 0;
          if (order?.paymentStatus === "paid") {
            if (order?.iwaiterShare && order?.iwaiterShare > 0) {
              paymentFees = order.iwaiterShare;
            } else {
              let percentage = 0.028;
              let visaPercentage = restaurant?.percentageForIwaiter;
              let madaPercentage = restaurant?.percentageForIWaiterMADA;
              let percentageTabby = 9;
              if (
                restaurant?.percentageForIWaiterTabby &&
                typeof restaurant?.percentageForIWaiterTabby === "number"
              ) {
                percentageTabby = restaurant?.percentageForIWaiterTabby;
              }
              if (
                order?.paymentId?.paymentCardType === "MADA" &&
                restaurant?.percentageForIWaiterMADA &&
                typeof restaurant?.percentageForIWaiterMADA === "number"
              ) {
                percentage = madaPercentage / 100;
              } else if (
                order?.paymentId?.paymentCardType === "TABBY_INSTALLMENT"
              ) {
                percentage = percentageTabby / 100;
              } else if (visaPercentage && typeof visaPercentage === "number") {
                percentage = visaPercentage / 100;
              }
              paymentFees = order.total * percentage;
              if (order?.couponCode?._id) {
                const discount = getDiscountAmount({
                  total: order.total,
                  percentage: order.couponCode.percentage,
                  maximumAmount: order.couponCode.maximumAmount,
                });
                paymentFees = (order.total - discount) * percentage;
              }
            }
          }
          let deliveryFees = 0;
          if (order?.deliveryInfo?.deliveryFees) {
            deliveryFees = order?.deliveryInfo?.deliveryFees;
          }
          let otherCharges = 0;
          if (order?.tableFees > 0) {
            otherCharges = order?.tableFees;
          }
          let refundAmount = 0;
          if (order?.refundPaymentId?._id) {
            refundAmount = order?.refundPaymentId?.totalNumber;
          }
          let refundAmountFees = 0;
          let paymentMethod = "Cash";
          if (order?.paymentId?.paymentCardType) {
            paymentMethod = order?.paymentId?.paymentCardType;
          }
          let iwaiterAppCommission = 0;
          if (order?.type === "inHouse" && order?.source === "app") {
            let percentage = 2.5;
            if (restaurant?.appPercentage) {
              percentage = restaurant.appPercentage;
            }
            iwaiterAppCommission = total * (percentage / 100);
            // iwaiterAppCommission = (iwaiterAppCommission * 1.15).toFixed(2)
          }

          // check if the order has coupon for items
          const checkCoupon = couponForItemsReportFormat(order, intl);

          return {
            number: i + 1,
            branch: rtl ? branch?.branch?.name : branch?.branch?.name_en,
            status: order?.status,
            time: new Date(
              order?.createdAt ? order.createdAt : new Date()
            ).toLocaleString(),
            type: order?.type,
            table: rtl ? table?.name : table?.name_en,
            orderNumber: orderNumber,
            paymentStatus: order?.paymentStatus,
            paymentMethod: paymentMethod,
            withCouponForItems: checkCoupon?.isWithCouponForItems,
            withGeneralCoupon: order?.couponCode ? true : false,
            couponCodes: order?.couponCode
              ? `${order?.couponCode?.percentage} % - ${order?.couponCode?.code}`
              : checkCoupon?.isWithCouponForItems
              ? checkCoupon?.message
              : "---",
            total: total?.toFixed(2),
            paymentOnlineFees: paymentFees?.toFixed(2),
            refundAmount,
            refundAmountFees,
            delivery: deliveryFees,
            customerName: order?.name || "--",
            customerPhone: order?.phone?.length > 4 ? order?.phone : "--",
            otherCharges: otherCharges?.toFixed(2),
            key: order?._id,
            iwaiterAppCommission: iwaiterAppCommission?.toFixed(2),
            orderObject: {
              ...order,
              branchName: rtl ? branch?.branch?.name : branch?.branch?.name_en,
              table,
            },
          };
        });
        setFormattedOrders(formattedOrders);
      } else {
        setFormattedOrders([]);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <Title level={4}>{intl.formatMessage({ id: "reports" })}</Title>
      </div>
      <div>
        <div style={{ backgroundColor: COLORS.gray }} className='py-4 px-3'>
          <Form
            name='food-form'
            form={form}
            className='form-container'
            layout='inline'
            onFinish={onFinish}
            initialValues={{ type: "all", paymentMethod: "all" }}
          >
            <Form.Item
              rules={[{ required: true, message: "Please select Date" }]}
              name='date'
              label={intl.formatMessage({ id: "date" })}
            >
              <RangePicker />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: "Please select Type" }]}
              name='type'
              label={intl.formatMessage({ id: "reportsType" })}
              style={{ width: "25%" }}
            >
              <Select style={{ width: "100%" }} className='my-font'>
                <Option className='my-font' value='all'>
                  {intl.formatMessage({ id: "all" })}
                </Option>
                <Option className='my-font' value='inHouse'>
                  {intl.formatMessage({ id: "inHouse" })}
                </Option>
                <Option className='my-font' value='pickup'>
                  {intl.formatMessage({ id: "pickup" })}
                </Option>
                <Option className='my-font' value='app'>
                  {intl.formatMessage({ id: "app" })}
                </Option>
                <Option className='my-font' value='delivery'>
                  {intl.formatMessage({ id: "delivery" })}
                </Option>
              </Select>
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: "Please select Type" }]}
              name='paymentMethod'
              label={intl.formatMessage({ id: "paymentMethod" })}
              style={{ width: "22%" }}
            >
              <Select style={{ width: "100%" }} className='my-font'>
                <Option className='my-font' value='all'>
                  {intl.formatMessage({ id: "all" })}
                </Option>
                <Option className='my-font' value='paid'>
                  {intl.formatMessage({ id: "paid" })}
                </Option>
                <Option className='my-font' value='notPaid'>
                  {intl.formatMessage({ id: "notPaid" })}
                </Option>
              </Select>
            </Form.Item>

            <Form.Item>
              <CustomButton
                htmlType='submit'
                text={intl.formatMessage({ id: "search" })}
                className={`btnRegister btn-text border-8 px-5 `}
                loading={loading}
                disabled={loading}
                type='primary'
              />
            </Form.Item>
          </Form>
        </div>
      </div>
      <Table
        exportable
        searchable
        title={() => {
          if (formattedOrders.length) {
            return (
              <ReportSummary amounts={formattedOrders} filter={orderType} />
            );
          }
        }}
        searchableProps={{
          fuzzySearch: true,
          inputProps: {
            placeholder: "Search this table...",
            prefix: <SearchOutlined />,
            disabled: loading || formattedOrders?.length === 0,
          },
        }}
        exportableProps={{
          showColumnPicker: true,
          disabled: loading || formattedOrders?.length === 0,
          btnProps: {
            icon: <FileExcelOutlined />,
            type: "primary",
            style: { borderRadius: 7 },
            onClick: () =>
              customerExport({
                data: formattedOrders,
                columns: columns,
                fileName: "Orders Report",
                loadingSetter: setLoading,
              }),
          },
          children: (
            <Text
              className='my-font'
              style={{
                color: formattedOrders?.length === 0 ? "#dddddd" : COLORS.white,
              }}
            >
              {intl.formatMessage({ id: "export" })}
            </Text>
          ),
        }}
        columns={columns}
        className='light-shadow'
        pagination={{
          hideOnSinglePage: true,
        }}
        loading={loading}
        dataSource={formattedOrders}
        scroll={{ x: 500 }}
      />
    </div>
  );
};

export default Reports;
