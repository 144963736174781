import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import UseQuery from "../../../Hooks/UseQuery";
import EditReservationSchemaForm from "./EditReservationSchemaForm";
import { Col, Row, Space, Typography } from "antd";
import { IoArrowBackOutline, IoArrowForwardOutline } from "react-icons/io5";
import AddReservationSchemaForm from "./AddReservationSchemaForm";

const { Title } = Typography;
const EditReservationView = () => {
  const navigate = useNavigate();
  // get params from url;
  // get reservation by id;
  const intl = useIntl();
  const { branch } = useSelector((state) => state.branch);
  const { rtl } = useSelector((state) => state.settings);
  const { id } = useParams();
  const query = UseQuery();
  const [area, setArea] = useState(null);
  const isNew = id === "newArea";
  const canDelete = query.get("canDelete") === 'true';
  useEffect(() => {
    if (branch?.reservationSchema?.length) {
      const area = branch?.reservationSchema?.find((area) => area?._id === id);
      if (area) {
        setArea(area);
      }
    }
    return () => {
      setArea(null);
    };
  }, [id, branch]);

  return (
    <div>
        <div
          style={
            rtl
              ? {
                  position: "absolute",
                  zIndex: 2,
                }
              : {
                  position: "absolute",
                  zIndex: 2,
                }
          }
        >
          <Title level={3} className='pointer' onClick={() => navigate(-1)}>
            {rtl ? (
              <IoArrowForwardOutline className='pointer' />
            ) : (
              <IoArrowBackOutline className='pointer' />
            )}
          </Title>
        </div>
      <Row
        style={{
          position: "relative",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <Col span={24}>
          <Title level={2} style={{ textAlign: "center" }}>
            {isNew
              ? intl.formatMessage({ id: "newArea" })
              : rtl
              ? area?.name
              : area?.name_en}
          </Title>
        </Col>
      </Row>
      {area && !isNew ? (
        <EditReservationSchemaForm
          area={area}
          hide={() => {
            navigate(-1);
          }}
          canDelete={canDelete}
        />
      ) : (
        <AddReservationSchemaForm
        hide={() => {
            navigate(-1);
          }}
      />
      )}
    </div>
  );
};

export default EditReservationView;
