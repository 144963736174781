import { createSlice, current } from "@reduxjs/toolkit";
import { getCommentsFromFoods, sortByOrder } from "../../../helpers";
import {
  arrAdd,
  arrRemoveByKeyValue,
  arrUpdateByKeyValue,
} from "../../../helpers/array";

const initialState = {
  categories: [],
  foods: [],
  modifiers: [],
  comments: [],
  search: "",
  customCategories: [],
};

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setMenu: (state, action) => {
      state.categories = action.payload.categories;
      state.modifiers = sortByOrder(action.payload.modifiers);
      const foods = action.payload.categories.flatMap((cat) => cat.foods);
      state.foods = foods;
      state.comments = getCommentsFromFoods(foods);
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setCategoriesAndFoods: (state, action) => {
      state.categories = action.payload;
      const foods = action.payload.flatMap((cat) => cat.foods);
      state.foods = foods;
      state.comments = getCommentsFromFoods(foods);
    },
    editCategory: (state, action) => {
      const newCategory = arrUpdateByKeyValue({
        arr: current(state.categories),
        key: "categoryId",
        value: action.payload.categoryId,
        newElement: action.payload,
      });
      state.categories = newCategory;
    },
    addCategory: (state, action) => {
      const newCategories = arrAdd({
        arr: current(state.categories),
        newElement: action.payload,
      });
      state.categories = newCategories;
    },
    deleteCategory: (state, action) => {
      const newCategories = arrRemoveByKeyValue({
        arr: current(state.categories),
        key: "categoryId",
        value: action.payload,
      });
      state.categories = newCategories;
    },

    editFood: (state, action) => {
      const newFood = arrUpdateByKeyValue({
        arr: current(state.foods),
        key: "foodId",
        value: action.payload.foodId,
        newElement: action.payload,
      });
      state.foods = newFood;
      state.comments = getCommentsFromFoods(newFood);
    },
    addFood: (state, action) => {
      const newFoods = arrAdd({
        arr: current(state.foods),
        newElement: action.payload,
      });
      state.foods = newFoods;
      state.comments = getCommentsFromFoods(newFoods);
    },
    deleteFood: (state, action) => {
      const newFoods = arrRemoveByKeyValue({
        arr: current(state.foods),
        key: "foodId",
        value: action.payload,
      });
      state.foods = newFoods;
      state.comments = getCommentsFromFoods(newFoods);
    },

    setModifiers: (state, action) => {
      state.modifiers = sortByOrder(action.payload);
    },

    editModifier: (state, action) => {
      const newModifier = arrUpdateByKeyValue({
        arr: current(state.modifiers),
        key: "_id",
        value: action.payload._id,
        newElement: action.payload,
      });
      state.modifiers = newModifier;
    },
    addModifier: (state, action) => {
      const newModifiers = arrAdd({
        arr: current(state.modifiers),
        newElement: action.payload,
      });
      state.modifiers = newModifiers;
    },
    deleteModifier: (state, action) => {
      const newModifiers = arrRemoveByKeyValue({
        arr: current(state.modifiers),
        key: "_id",
        value: action.payload,
      });
      state.modifiers = newModifiers;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setCustomCategories: (state, action) => {
      state.customCategories = action.payload;
    },
    editCustomCategory: (state, action) => {
      const newCategory = arrUpdateByKeyValue({
        arr: current(state.customCategories),
        key: "_id",
        value: action.payload._id,
        newElement: action.payload,
      });
      state.customCategories = newCategory;
    },
    addCustomCategory: (state, action) => {
      const newCategories = arrAdd({
        arr: current(state.customCategories),
        newElement: action.payload,
      });
      state.customCategories = newCategories;
    },
    deleteCustomCategory: (state, action) => {
      const newCategories = arrRemoveByKeyValue({
        arr: current(state.customCategories),
        key: "_id",
        value: action.payload,
      });
      state.customCategories = newCategories;
    },
  },
});

export const {
  setMenu: setMenuAction,
  setCategories: setCategoriesAction,
  setCategoriesAndFoods: setCategoriesAndFoodsAction,
  editCategory: editCategoryAction,
  addCategory: addCategoryAction,
  deleteCategory: deleteCategoryAction,
  editFood: editFoodAction,
  addFood: addFoodAction,
  deleteFood: deleteFoodAction,
  editModifier: editModifierAction,
  addModifier: addModifierAction,
  deleteModifier: deleteModifierAction,
  setModifiers: setModifiersAction,
  setSearch: setSearchAction,
  setCustomCategories: setCustomCategoriesAction,
  editCustomCategory: editCustomCategoryAction,
  addCustomCategory: addCustomCategoryAction,
  deleteCustomCategory: deleteCustomCategoryAction,
} = menuSlice.actions;

export default menuSlice.reducer;
