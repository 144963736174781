import React, { useState, useEffect } from "react";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";
import { useSelector } from "react-redux";
import COLORS from "../../../Style/colors";
import moment from "moment";
import { Typography, Space, Spin } from "antd";
import { useIntl } from "react-intl";
import MyTimePicker from "./MyTimePicker";
import {
    getDataAnalyticsByDates,
} from "../../../API/fetch";


const { Title, } = Typography;

function ReservationTotals() {
    const intl = useIntl();
    const { analyticsData } = useSelector((state) => state.analytics);
    const { restaurant } = useSelector((state) => state.restaurant);
    const { rtl } = useSelector((state) => state.settings);
    const [labels, setLabels] = useState([]);
    const [data, setData] = useState([]);
    const [totalOrders, setTotalOrders] = useState(0);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if (analyticsData?.reservationTotalByDate?.length > 0) {
            let labels = [];
            let data = [];
            analyticsData?.reservationTotalByDate.forEach((one) => {
                labels.push(moment(one.date).format("DD-MM"));
                data.push(one.total);
            });
            setLabels(labels);
            setData(data);
            // get sum of total inside analytics data array;
            const sum = analyticsData?.reservationTotalByDate?.reduce(
                (acc, cur) => acc + cur.total,
                0
            );
            setTotalOrders(sum?.toFixed(2));
        }
    }, [analyticsData, rtl]);

    const getDataForTime = async ({ start, end }) => {
        try {
            setLoading(true);
            const data = {
                start,
                end,
                id: restaurant?._id,
                type: "reservationTotalByDate",
            };
            const res = await getDataAnalyticsByDates(data);
            console.log(res.data.result)
            if (res.data?.result?.reservationTotalByDate?.length > 0) {
                let labels = [];
                let data = [];
                res.data?.result?.reservationTotalByDate.forEach((one) => {
                    labels.push(moment(one.date).format("DD-MM"));
                    data.push(one.total);
                });
                setLabels(labels);
                setData(data);
                // get sum of total inside analytics data array;
                const sum = res.data?.result?.reservationTotalByDate?.reduce(
                    (acc, cur) => acc + cur.total,
                    0
                );
                setTotalOrders(sum?.toFixed(2));
            }else {
                setTotalOrders(0)
                setData([0])
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    return (
        <div>
            <Spin spinning={loading}>
                <Space
                    direction='horizontal'
                    style={{ width: "100%", justifyContent: "space-between" }}
                >
                    <div>
                        <Title style={{ marginBottom: 0, paddingBottom: 0 }} level={4}>
                            {intl.formatMessage({ id: "reservationRevenues" })}
                        </Title>
                        <Title
                            style={{
                                marginBottom: 0,
                                paddingBottom: 0,
                                marginTop: 0,
                                paddingTop: 0,
                            }}
                            level={5}
                        >
                            {totalOrders} SR
                        </Title>
                    </div>
                    <MyTimePicker
                        initialTime='last30days'
                        loading={loading}
                        getData={getDataForTime}
                    />
                </Space>
                <Chart
                    type='line'
                    data={{
                        labels,
                        datasets: [
                            {
                                label: "",
                                data: data,
                                borderWidth: 2,
                                borderColor: COLORS.primary,
                                tension: 0.6,
                                pointStyle: "circle",
                                pointBackgroundColor: COLORS.primary,
                                hoverBackgroundColor: COLORS.lightBlue,
                                hoverBorderColor: COLORS.primary,
                                fill: "start",
                                backgroundColor: "rgba(255,255,255,0.7)",
                            },
                        ],
                    }}
                    options={{
                        responsive: true,
                        scales: {
                            y: {
                                beginAtZero: true,
                            },
                        },
                        plugins: {
                            subtitle: {
                                display: false,
                            },
                            legend: {
                                display: false,
                            },
                        },
                    }}
                />
            </Spin>
        </div>
    )
}

export default ReservationTotals