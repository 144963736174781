import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../Components/CustomButton";
import { Tabs, Row, Empty, Typography, Space, Input, Col } from "antd";
import COLORS from "../../../Style/colors";
import CategoryHeader from "./Components/Categoryies/CategoryHeader";
import EditCategoryModal from "./Components/Categoryies/EditCategoryModal";
import FoodCard from "./Components/Foods/FoodCard";
import { useNavigate, useParams } from "react-router";
import UseQuery from "../../../Hooks/UseQuery";
import { SUBSCRIPTION_TYPES } from "../../../Types";
import { setSearchAction } from "../../../redux/reducers/menuReducer";
import SearchScreen from "./Components/SearchScreen";

const { TabPane } = Tabs;
const { Title } = Typography;
const { Search } = Input;
const Index = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const params = useParams();
  const query = UseQuery();
  const { allBranches } = useSelector((state) => state.branch);
  const { services, role } = useSelector((state) => state.auth);
  const { categories, foods, search } = useSelector((state) => state.menu);
  const history = useNavigate();
  const [showEditCategory, setShowEditCategory] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const [filterCategories, setFilterCategories] = useState([]);
  // Selected Category;
  useEffect(() => {
    const selectedCategory = query.get("category");
    if (selectedCategory) {
      setTimeout(() => {
        setActiveTab(selectedCategory);
      }, 10);
    }
  }, [query, params]);

  useEffect(() => {
    let timer;
    if (categories.length && !activeTab) {
      setActiveTab(categories[0].categoryId);
    } else if (activeTab) {
      const queryCategory = query.get("category");
      if (queryCategory !== activeTab) {
        // check if we have category text inside the url;
        if (queryCategory) {
          timer = setTimeout(() => {
            history(`?category=${activeTab}`);
          }, 100);
        } else {
          timer = setTimeout(() => {
            history(`?category=${activeTab}`);
          }, 100);
        }
      }
    }
    return () => clearTimeout(timer);
  }, [categories, activeTab]);

  useEffect(() => {
    if (categories?.length) {
      // filter the hidden items;
      const selectedCategory = query.get("category");
      let filter = categories.filter((category) => {
        let branchIds = [];
        if (role === "super") {
          branchIds = allBranches?.map((b) => b?.branch?._id);
        } else {
          branchIds = allBranches?.map((b) => b?._id);
        }

        if (category?.categoryId === selectedCategory) {
          return true;
        }
        return !branchIds.every((id) => category?.notAvailableIn?.includes(id));
      });

      setFilterCategories(filter);
    }
  }, [categories, query]);

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      {/* Modals */}
      <EditCategoryModal
        category={null}
        show={showEditCategory}
        hide={() => setShowEditCategory(false)}
        index={categories.length}
        setActiveTab={setActiveTab}
      />

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // marginBottom: 10,
        }}
      >
        <Title>{intl.formatMessage({ id: "menu" })}</Title>

        <Space direction='horizontal'>
          <CustomButton
            text={intl.formatMessage({
              id: "addCategory",
            })}
            type='primary'
            className='border-8'
            onClick={() => setShowEditCategory(true)}
            disabled={
              services?.includes("ORDER_A") &&
              !services?.includes("ORDER_B") &&
              foods.length >= 50
            }
          />
          <CustomButton
            text={intl.formatMessage({
              id: "addNewFood",
            })}
            type='text'
            className='border-8'
            onClick={() => history("/foods/New")}
            disabled={
              services?.includes("ORDER_A") &&
              !services?.includes("ORDER_B") &&
              foods.length >= 50
            }
          />
        </Space>
      </div>
      <Row className='mb-2'>
        <Col md={7} lg={7} sm={24} xs={24}>
          <Search
            allowClear
            value={search}
            placeholder={intl.formatMessage({ id: "search" })}
            onChange={(e) => dispatch(setSearchAction(e.target.value))}
            onSearch={(value) => {
              if (value) {
                dispatch(setSearchAction(value));
              } else {
                dispatch(setSearchAction(""));
              }
            }}
            maxLength={100}
          />
        </Col>
      </Row>
      {search ? (
        <SearchScreen categories={categories} search={search} />
      ) : (
        <Tabs
          centered
          activeKey={activeTab}
          onTabClick={(e) => {
            setActiveTab(e);
          }}
          type='card'
          style={{ minHeight: "75vh" }}
        >
          {filterCategories?.map((category, index) => {
            return (
              <TabPane
                key={category.categoryId}
                style={{
                  backgroundColor: COLORS.white,
                  minHeight: "75vh",
                }}
                tab={
                  <span className='px-3 my-font'>
                    {category?.name_en
                      ? rtl
                        ? category?.name
                        : category.name_en
                      : category.name}
                  </span>
                }
              >
                <CategoryHeader
                  category={category}
                  index={index}
                  setActiveTab={setActiveTab}
                />

                <Row
                  gutter={{ xs: 8, sm: 16, md: 8, lg: 8 }}
                  type='flex'
                  justify='space-around'
                  align='middle'
                  style={{ flexWrap: "wrap", marginTop: 35 }}
                >
                  {category?.foods?.length ? (
                    category?.foods.map((food, i) => {
                      return (
                        <FoodCard
                          category={category}
                          key={food.foodId}
                          food={food}
                        />
                      );
                    })
                  ) : (
                    <Empty
                      image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
                      imageStyle={{
                        height: 250,
                      }}
                      description={
                        <h2 className='my-font'>
                          {intl.formatMessage({ id: "emptyFoods" })}
                        </h2>
                      }
                    ></Empty>
                  )}
                </Row>
              </TabPane>
            );
          })}
        </Tabs>
      )}
    </div>
  );
};

export default Index;
