import React, { useState } from "react";
import { Spin, Upload, Typography, message, Modal } from "antd";
import COLORS from "../../../Style/colors";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import apiUrl from "../../../API/config";
import openNotification from "../../../Components/Notifications";
import { useDispatch } from "react-redux";
import { InboxOutlined } from "@ant-design/icons";
import {editBranchInAllBranchAction, setBranchAction} from "../../../redux/reducers/branchReducer";
const { Text, Title } = Typography;
const { Dragger } = Upload;
const acceptedTypes = ["image/png", "image/jpg", "image/jpeg"];

const BranchImage = ({ branch }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const { user, waiter } = useSelector((state) => state.auth);
  const { restaurant, design } = useSelector((state) => state.restaurant);
  const [errorSizeImage, setErrorSizeImage] = useState(false);
  const [coverImages, setCoverImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const [errorSizeCover, setErrorSizeCover] = useState(false);

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const beforeUpload = (file) => {
    if (!acceptedTypes.includes(file?.type) || !file) {
      message.error("You can only upload JPG/PNG file!");
      return false;
    }
    if (file.type?.includes("image")) {
      if (file.size / 1000000 > 2.2) {
        message.error(intl.formatMessage({ id: "limit2Mb" }));
        return false;
      }
    }

    return true;
  };

  // handle image upload;
  const handleChangeImages = ({ fileList, file }, type) => {
    let newFileList = [...fileList];
    setErrorSizeImage(false);
    let errors = [];
    newFileList.forEach((el) => {
      if (el.size > 2000000) {
        //check if the image size is bigger than 2Mo
        errors.push(el?.uid);
      }
    });
    if (errors.length > 0) {
      //return error if there is images bigger than 2Mo

      setErrorSizeImage(true);

      return;
    }
    if (file.status === "uploading") {
      setLoading(true); // show loading;
    } else if (file.status === "done") {
      // upload success;
      setLoading(false); // hide loading;
      if (file.response?.branch?._id) {
        if (file?.response.branch?._id) {
            const newBranch = {
              ...branch,
              image: file.response.branch.image,
            };
              dispatch(setBranchAction(newBranch));
              dispatch(editBranchInAllBranchAction(newBranch));
          openNotification({
            title: intl.formatMessage({ id: "savedSuccessfully" }),
            type: "success",
            rtl,
          });
        }
      }
    } else if (file.status === "error") {
      setLoading(false); // hide loading;
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }

    setCoverImages([file]);
  };

  // handle delete image;
  const deleteImage = async (e, type) => {
    if (type === "logo") {
      // can not delete logo;
      openNotification({
        title: (
          <Text className='my-font'>{intl.formatMessage({ id: "error" })}</Text>
        ),
        description: (
          <Text className='my-font'>
            {intl.formatMessage({ id: "canNotDeleteLogo" })}
          </Text>
        ),
        type: "error",
        rtl,
      });
      return;
    }
    try {
      setLoading(true); // show loading;
      const data = {
        restaurant: {
          coverImage: null,
        },
        code: waiter.code,
        deleteCover: true,
        // coverURL: restaurant?.coverImage?.url,
      };
      //   const res = await postUpdateRestaurant(restaurant._id, data);
      //   const newRest = {
      //     ...restaurant,
      //     coverImage: null,
      //   };
      //   dispatch(setRestaurantAction(newRest));
      setTimeout(() => {
        setCoverImages([]);
      }, 500);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
    } catch (error) {
      console.log(error);
      setLoading(false); // hide loading;
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  useEffect(() => {
    if (branch?._id && branch?.image) {
      let log = branch?.image;
      setCoverImages([
        {
          uid: branch._id,
          status: "done",
          url: log,
          name: `${branch.name}`,
        },
      ]);
    }
  }, [branch]);
  return (
    <Spin spinning={loading}>
      <Modal
        visible={previewVisible}
        title={rtl ? branch?.name : branch?.name_en}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
      >
        <img alt={branch?.name} style={{ width: "100%" }} src={previewImage} />
      </Modal>
      <Title className='my-font' level={4}>
        {intl.formatMessage({ id: "photo" })}
      </Title>
      <div
        style={{
          position: "absolute",
          top: 15,
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        {errorSizeImage ? (
          <Text className='my-font' style={{ color: COLORS.danger }}>
            {intl.formatMessage({ id: "limit2Mb" })}
          </Text>
        ) : null}
      </div>
      <Dragger
        action={`${apiUrl}/branch-upload-logo/${branch?._id}?slug=${restaurant?.slug}`}
        headers={{ authorization: "Bearer " + user.token }}
        name='picture'
        fileList={coverImages}
        onRemove={(e) => {
          // deleteImage(e, "cover");
        //   updateShowCoverImageInMenu();
        }}
        listType='picture'
        onChange={(e) => handleChangeImages(e, "cover")}
        onPreview={onPreview}
        beforeUpload={beforeUpload}
        multiple={false}
        maxCount={0}
        accept='image/jpg,image/jpeg,image/png,video/mp4'
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <InboxOutlined
            style={{
              color: errorSizeCover ? COLORS.danger : COLORS.primary,
              fontSize: "2rem",
            }}
            className='mx-3'
          />
          <Text
            style={{
              fontSize: "1rem",
              color: errorSizeCover ? COLORS.danger : COLORS.primary,
            }}
            className='my-font'
          >
            {intl.formatMessage({
              id: coverImages?.length === 0 ? "addPhoto" : "change",
            })}
          </Text>
        </div>
      </Dragger>
    </Spin>
  );
};

export default BranchImage;
