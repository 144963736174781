const DEFAULT_DESIGN = {
  backgroundColor: "#f1f2f8",
  titleColor: "#0a2240",
  textColor: "#0a2240",
  backgroundCircleBG: "#ffffff",
  backgroundCircleText: "#0a2240",
  backgroundBox: "#FFFFFF",
  backgroundBoxText: "#0a2240",
  backgroundBoxDescription: "#7f879a",
  successLabel: "#82d871",
  trackLabel: "#ffca71",
  primaryButtonBG: "#afe2e3",
  primaryButtonText: "#0a2240",
  secondaryButtonBG: "#0a2240",
  secondaryButtonText: "#ffffff",
  backgroundTextFooter: "#0a2240",
  danger: "#f76877",
  dangerText: "#0a2240",
  promotionBG: "#80d371",
  promotionText: "#0a2240",
  thanksMessageAR: "شكرا لكم",
  thanksMessageEN: "Thank you ",
  display: "grid",
  showCategorySection: true,
  limitCategoryInOnePage: false,
};
const DARK_DESIGN = {
  backgroundColor: "#121212",
  titleColor: "#afe2e3",
  textColor: "#ababab",
  backgroundCircleBG: "#2e2e2e",
  backgroundCircleText: "#FFFFFF",
  backgroundBox: "#1f1f1f",
  backgroundBoxText: "#ababab",
  backgroundBoxDescription: "#5b5b5b",
  successLabel: "#82d871",
  trackLabel: "#ffca71",
  primaryButtonBG: "#afe2e3",
  primaryButtonText: "#0a2240",
  secondaryButtonBG: "#0a2240",
  secondaryButtonText: "#ffffff",
  backgroundTextFooter: "#5b5b5b",
  danger: "#f76877",
  dangerText: "#0a2240",
  promotionBG: "#80d371",
  promotionText: "#0a2240",
  thanksMessageAR: "شكرا لكم",
  thanksMessageEN: "Thank you ",
  display: "grid",
  showCategorySection: true,
  limitCategoryInOnePage: false,
};
const SAUDI_DESIGN = {
  theme: "SAUDI",
  backgroundColor: "#f4f4f4",
  titleColor: "#02573b",
  textColor: "#194760",
  backgroundCircleBG: "#ffffff",
  backgroundCircleText: "#194760",
  backgroundBox: "#ffffff",
  backgroundBoxText: "#194760",
  backgroundBoxDescription: "#3eb2b2",
  successLabel: "#82d871",
  trackLabel: "#ffca71",
  backgroundTextFooter: "#a07950",
  danger: "#f76877",
  dangerText: "#0a2240",
  promotionBG: "#0a1808",
  promotionText: "#dfe7f1",
  primaryButtonBG: "#f05816",
  primaryButtonText: "#ffffff",
  secondaryButtonBG: "#ffd041",
  secondaryButtonText: "#000000",
  thanksMessageAR: "شكرا لكم",
  thanksMessageEN: "Thank you ",
  display: "grid",
  showCategorySection: true,
  limitCategoryInOnePage: false,
};

export { DEFAULT_DESIGN, DARK_DESIGN, SAUDI_DESIGN };
