import React, { useEffect, useState } from "react";
import COLORS from "../../Style/colors";
import { Space, Spin, Tag, Typography } from "antd";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "ant-table-extensions";
import { FileExcelOutlined } from "@ant-design/icons";
import { patchComment } from "../../API/fetch";
import openNotification from "../../Components/Notifications";
import {
  editCategoryAction,
  editFoodAction,
} from "../../redux/reducers/menuReducer";
import { arrUpdateByKeyValue } from "../../helpers/array";
import { customerExport } from "../../helpers";

const { Title, Text } = Typography;
const Comments = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { foods, categories } = useSelector((state) => state.menu);
  const { rtl } = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState([]);
  useEffect(() => {
    if (foods.length) {
      let format = [];
      let sorted = [];
      foods?.forEach((food) => {
        if (food?.comments?.length) {
          food.comments.forEach((comment) => {
            format.push({
              number: format?.length + 1,
              foodName: rtl ? food?.name : food?.name_en,
              status: comment?.status,
              edit: comment?.status,
              key: comment?._id,
              comment: comment?.text,
              origin: comment?.origin,
              customerName: comment?.customerId?.name,
              time: new Date(
                comment?.createdAt ? comment.createdAt : new Date()
              ).toLocaleString(),
              commentObject: { ...comment },
              food: food,
              //   answersCount: form?.answers.length,
              //   feedbackInfo: { ...form },
            });
          });
        }
      });
      if (format.length) {
        sorted = format.sort((a, b) => {
          return new Date(b.time) - new Date(a.time);
        });
      }

      setComments(sorted);
    } else {
      setComments([]);
    }
  }, [foods, rtl]);

  const editComment = async (type, commentId, food) => {
    try {
      setLoading(true);
      let data = {
        status: "Confirmed",
      };
      if (type === "DELETE") {
        data.status = "deleted";
      }
      const res = await patchComment(data, commentId);
      const newComments = [];
      food.comments.forEach((comment) => {
        if (comment?._id === commentId) {
          newComments.push({
            ...comment,
            status: data.status,
          });
        } else {
          newComments.push({
            ...comment,
          });
        }
      });

      const newFood = {
        ...food,
        comments: newComments,
      };
      const category = categories.find(
        (c) => c.categoryId === newFood.categoryId
      );

      dispatch(editFoodAction(newFood));
      // edit the food  item inside the foods array inside the category;
      const newFoods = arrUpdateByKeyValue({
        arr: category.foods,
        key: "foodId",
        value: food.foodId,
        newElement: newFood,
      });
      const newCategory = {
        ...category,
        foods: newFoods,
      };
      dispatch(editCategoryAction(newCategory));

      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
    },
    {
      title: intl.formatMessage({ id: "foods" }),
      dataIndex: "foodName",
      key: "foodName",
    },
    {
      title: intl.formatMessage({ id: "comment" }),
      dataIndex: "comment",
      key: "comment",
      width: 200,
    },
    {
      title: intl.formatMessage({ id: "customerName" }),
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: intl.formatMessage({ id: "origin" }),
      dataIndex: "origin",
      key: "origin",
      render: (origin) => {
        return <Text>{intl.formatMessage({ id: `${origin}` })}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: "status" }),
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return (
          <Tag
            color={
              status === "Confirmed"
                ? "green"
                : status === "deleted"
                ? "red"
                : "orange"
            }
          >
            {intl.formatMessage({ id: `${status}` })}
          </Tag>
        );
      },
      onFilter: (value, record) => record.commentObject.status === value,
      filters: [
        {
          text: intl.formatMessage({ id: "published" }),
          value: "Confirmed",
        },
        {
          text: intl.formatMessage({ id: "Pending" }),
          value: "Pending",
        },
        {
          text: intl.formatMessage({ id: "deleted" }),
          value: "deleted",
        },
      ],
    },
    {
      title: intl.formatMessage({ id: "time" }),
      dataIndex: "time",
      key: "time",
      sorter: (a, b) =>
        new Date(a.commentObject?.createdAt) -
        new Date(b.commentObject?.createdAt),
      showSorterTooltip: false,
    },
    {
      title: intl.formatMessage({ id: "edit" }),
      dataIndex: "edit",
      render: (status, record) => {
        return (
          <Space direction='horizontal'>
            {status === "Confirmed" ? null : (
              <Tag
                onClick={() =>
                  editComment(
                    "CONFIRM",
                    record?.commentObject?._id,
                    record?.food
                  )
                }
                color={"green"}
                className='px-4 border-8 pointer'
              >
                {intl.formatMessage({ id: "publish" })}
              </Tag>
            )}
            {status === "deleted" ? null : (
              <Tag
                onClick={() =>
                  editComment(
                    "DELETE",
                    record?.commentObject?._id,
                    record?.food
                  )
                }
                color={"red"}
                className='px-3 border-8 pointer'
              >
                {intl.formatMessage({ id: "delete" })}
              </Tag>
            )}
          </Space>
        );
      },
      fixed: "right",
      width: 140,
    },
  ];
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <Title level={3}>{intl.formatMessage({ id: "comments" })}</Title>
      </div>
      <Text className='my-font'>
        {intl.formatMessage({ id: "commentsDes" })}
      </Text>

      <div
        style={{
          backgroundColor: COLORS.white,
          marginTop: 40,
          width: "99%",
          marginRight: "auto",
          marginLeft: "auto",
          borderRadius: 10,
          padding: 15,
        }}
        className='light-shadow'
      >
        <Spin spinning={loading}>
          <Table
            exportable
            exportableProps={{
              showColumnPicker: true,
              btnProps: {
                icon: <FileExcelOutlined />,
                type: "primary",
                style: { borderRadius: 7 },
                onClick: () =>
                customerExport({
                  data: comments,
                  columns,
                  fileName: "Comment Report",
                  loadingSetter: setLoading,
                }),
              },
              children: (
                <Text
                  className='my-font'
                  style={{
                    color: COLORS.white,
                  }}
                >
                  {intl.formatMessage({ id: "export" })}
                </Text>
              ),
            }}
            pagination={{
              hideOnSinglePage: true,
            }}
            dataSource={comments}
            scroll={{ x: 1000 }}
            columns={columns}
            style={{ borderRadius: 10 }}
          />
        </Spin>
      </div>
    </div>
  );
};

export default Comments;
