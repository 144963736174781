import { useState, useEffect } from "react";
import { Tabs } from "antd";
import React from "react";
import COLORS from "../../Style/colors";
import { useSelector } from "react-redux";
import OneBranchTab from "./Components/OneBranchTab";

const { TabPane } = Tabs;

const Branches = () => {
  const [activeTab, setActiveTab] = useState("");
  const { allBranches } = useSelector((state) => state.branch);
  const [branches, setBranches] = useState([]);

  useEffect(() => {
    if (allBranches.length) {
      const copyBranches = [...allBranches];
      const sorted = copyBranches.map((b) => b.branch).sort((a,b) => new Date(a.createdAt) - new Date(b.createdAt));
      setBranches(sorted);
      if (activeTab === "") {
        setActiveTab(sorted?.[0]?.branch?._id);
      }
    }
  }, [allBranches, activeTab]);

  return (
    <div>
      <Tabs
        centered
        activeKey={activeTab}
        onTabClick={(e) => setActiveTab(e)}
        type='card'
        style={{ minHeight: "75vh" }}
      >
        {branches?.map((branch) => {
          return (
            <TabPane
              key={branch?._id}
              style={{
                minHeight: "75vh",
              }}
              tab={branch?.name}
              className='light-shadow my-font'
            >
              <div
                style={{
                  backgroundColor: COLORS.gray,
                  borderRadius: 6,
                  marginTop: -16,
                  width: "92%",
                  marginRight: "auto",
                  marginLeft: "auto",
                  // padding: 15,
                }}
                // className='light-shadow'
              >
                <OneBranchTab branch={branch} />
              </div>
            </TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};

export default Branches;
