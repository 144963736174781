import { Card, Popconfirm, Spin, Table, Typography } from 'antd'
import React, { useState } from 'react'
import CustomOrderHeader from './Components/CustomOrderHeader'
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useNavigate } from "react-router-dom"
import { CUSTOM_ORDERS_STATUS, ORDER_TYPES } from '../../Types';
import { deleteOrderAction, setOrderByIdAction, updateOrdersAction } from '../../redux/reducers/orderReducer';
import openNotification from '../Notifications';
import CustomOrderTag from './Components/CustomOrderTag';
import COLORS from '../../Style/colors';
import { cancelCustomOrder, postUpdateCustomOrder } from '../../API/fetch';

const { Text } = Typography

function CustomOrderCard({ order, type = "inHouse", getProductById }) {
    const intl = useIntl()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { role } = useSelector((state) => state.auth);
    const { rtl } = useSelector((state) => state.settings);
    const [loading, setLoading] = useState(false);

    const columns = [
        {
            title: intl.formatMessage({ id: "item" }),
            dataIndex: "orderDetails",
            render: (text) => <p className='my-font'>{text}</p>,
        },
        {
            title: intl.formatMessage({ id: "total" }),
            key: "total",
            width: 150,
            dataIndex: "total",
        },
        {
            title: intl.formatMessage({ id: "sent" }),
            key: "createdAt",
            width: 250,
            dataIndex: "createdAt",
        },
    ];

    const changeStatus = async (oldStatus, type) => {
        try {
            setLoading(true);
            let status =
                oldStatus === CUSTOM_ORDERS_STATUS.OPEN
                    ? CUSTOM_ORDERS_STATUS.IN_KITCHEN
                    : oldStatus === CUSTOM_ORDERS_STATUS.IN_KITCHEN
                        ? CUSTOM_ORDERS_STATUS.READY
                        : oldStatus === CUSTOM_ORDERS_STATUS.READY
                            ? CUSTOM_ORDERS_STATUS.CLOSE
                            : oldStatus === CUSTOM_ORDERS_STATUS.CLOSE
                                ? CUSTOM_ORDERS_STATUS.OPEN
                                : oldStatus;

            let data
            if (type === "delete") {
                data = {
                    order: {
                        status: "deleted",
                    },
                }
            } else {
                data = {
                    order: {
                        status,
                    },
                };
            }

            const res = await postUpdateCustomOrder(order?._id, data);
            dispatch(setOrderByIdAction(res.data.order));
            if (type === "delete") {
                dispatch(
                    deleteOrderAction({
                        type: ORDER_TYPES.CUSTOM_ORDER,
                        payload: order._id,
                    })
                );
                navigate(`/customOrders`);
            } else {
                dispatch(
                    updateOrdersAction({
                        type: ORDER_TYPES.CUSTOM_ORDER,
                        payload: res.data.order,
                    })
                );
            }
            openNotification({
                title: intl.formatMessage({ id: "savedSuccessfully" }),
                type: "success",
                rtl,
            });
            setLoading(false);
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.newStatus === 'timeout') {
                getProductById(order?._id)
            }

            setLoading(false);
            openNotification({
                title: intl.formatMessage({ id: "error" }),
                description: intl.formatMessage({ id: "errorfetch" }),
                type: "error",
                rtl,
            });
        }
    };

    const cancelOrder = async () => {
        try {
            setLoading(true)
            const res = await cancelCustomOrder(order?._id)
            if (res?.data?.status === "success") {
                dispatch(
                    updateOrdersAction({
                        type: ORDER_TYPES.CUSTOM_ORDER,
                        payload: res.data.order,
                    })
                );
                dispatch(setOrderByIdAction(res.data.order));
                openNotification({
                    title: intl.formatMessage({ id: "savedSuccessfully" }),
                    type: "success",
                    rtl,
                });
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            openNotification({
                title: intl.formatMessage({ id: "error" }),
                description: intl.formatMessage({ id: "errorfetch" }),
                type: "error",
                rtl,
            });
        }
    }

    return (
        <Card
            title={
                <CustomOrderHeader
                    orderNumber={order?.orderNumber}
                    status={order?.status}
                    createdAt={order?.createdAt}
                    total={order?.total}
                    orderId={order?._id}
                    userPhone={order?.customerId?.phone}
                    userName={order?.customerId?.name}
                    orderType={type}
                    role={role}
                    _id={order?._id}
                />
            }
            bordered={false}
            style={{ width: "100%", borderRadius: 10, marginBottom: 10 }}
            actions={[
                <Spin spinning={loading}>
                    {order?.status === CUSTOM_ORDERS_STATUS.PENDING ?
                        <CustomOrderTag status={order?.status} changeStatus={cancelOrder} />
                        : order?.status === CUSTOM_ORDERS_STATUS.CANCELLED || order?.status === CUSTOM_ORDERS_STATUS.CLOSE ? null :
                            <CustomOrderTag status={order?.status} changeStatus={changeStatus} />
                    }
                    {role === "super" ? (
                        <Popconfirm
                            title={
                                <Text className='my-font'>
                                    {intl.formatMessage({ id: "confirmDelete" })}
                                </Text>
                            }
                            okText={intl.formatMessage({ id: "delete" })}
                            okButtonProps={{
                                style: { color: COLORS.danger, fontFamily: "Cairo" },
                            }}
                            okType='text'
                            cancelText={intl.formatMessage({ id: "cancel" })}
                            cancelButtonProps={{
                                style: { border: "none", fontFamily: "Cairo" },
                            }}
                            onConfirm={() => changeStatus(order?.status, "delete")}
                            disabled={role !== "super"}
                        >
                            <Text
                                disabled={role !== "super"}
                                type='danger'
                                className='pointer my-font'
                            >
                                {intl.formatMessage({ id: "delete" })}
                            </Text>
                        </Popconfirm>
                    ) : null}

                </Spin>,
            ]}
        >
            <Table
                scroll={{ x: 500 }}
                pagination={{ hideOnSinglePage: true }}
                columns={columns}
                dataSource={[{...order, key: order?._id}]}
            />
        </Card>
    )
}

export default CustomOrderCard