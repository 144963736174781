import React, { useRef, useState, useEffect } from "react";
import { Col, Typography, Tag, Badge } from "antd";
import Draggable from "react-draggable"; // The default
import { setTablePositionAction } from "../../../redux/reducers/tableReducer";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import COLORS from "../../../Style/colors";
import {
  DINE_IN_ORDERS_STATUS,
  TABLE_SHAPES,
  TAG_COLORS_BY_STATUS,
} from "../../../Types";
import { useIntl } from "react-intl";
import { postUpdateTableStatus } from "../../../API/fetch";
import openNotification from "../../../Components/Notifications";
import Timer from "../../../Components/Timer";
import { getOldestOpenOrder } from "../../../helpers";
import moment from "moment";

const { Text, Title } = Typography;
const TableCard = ({ table, canEditPosition, setCanEditPosition, index }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const { positions } = useSelector((state) => state.table);
  const { isBigScreen } = useSelector((state) => state.settings);
  const [newOrders, setNewOrders] = useState(0);
  const [inServiceOrders, setInServiceOrders] = useState(0);
  const [servedOrders, setServedOrders] = useState(0);
  const [customerInfo, setCustomerInfo] = useState({});
  const cardRef = useRef();
  useEffect(() => {
    if (table?.customerId?._id || table?.customerCanSetForMinutes > 0) {
      let name = table?.customerId?.name;
      let phone = table?.customerId?.phone;
      // check how many time that customer has been here;
      let startTime = moment(table?.startSeTime);
      //customerCanSetForMinutes
      let endTime = moment(startTime).add(
        table?.customerCanSetForMinutes,
        "minutes"
      );
      let now = moment();
      let diff = endTime.diff(now, "minutes");
      // get the remaining time;
      let isTimeAboutToEnd = diff <= 10 && diff > 0;
      let isTimeEnded = diff <= 0;
      let isTimeNotStarted = startTime.isAfter(now);
      setCustomerInfo({
        name,
        phone,
        isTimeEnded,
        isTimeAboutToEnd,
        startTime,
        isTimeNotStarted,
        _id: 'customerInfo',
      });
    }
    return () => {
      setCustomerInfo({});
    };
  }, [table]);

  let isLarge = table?.numberOfChairs > 8;
  let isBig = table?.numberOfChairs > 4;
  let isCircle = table?.shape === TABLE_SHAPES.CIRCLE;
  let isRectangle = table?.shape === TABLE_SHAPES.RECTANGLE;

  let isLookingToMenu =
    table.scanStatus === "Active" && table?.orders?.length < 1 ? true : false;

  let oldestOrderTimer = getOldestOpenOrder(table?.orders);

  let width = 160;
  let height = 160;

  if (isLarge) {
    width = 250;
    height = isRectangle ? 150 : 250;
  } else if (isBig) {
    width = 200;
    height = isRectangle ? 120 : 200;
  } else if (isRectangle) {
    height = 115;
  }

  useEffect(() => {
    // get number of orders;
    let open = 0;
    let inService = 0;
    let served = 0;
    table?.orders?.forEach((o) => {
      if (o.status === DINE_IN_ORDERS_STATUS.OPEN) {
        open++;
      } else if (o.status === DINE_IN_ORDERS_STATUS.IN_SERVER) {
        inService++;
      } else if (o.status === DINE_IN_ORDERS_STATUS.SERVED) {
        served++;
      }
    });
    setNewOrders(open);
    setInServiceOrders(inService);
    setServedOrders(served);
  }, [table]);

  const handleStop = (e, data) => {
    dispatch(
      setTablePositionAction({
        tableId: table._id,
        position: { x: data.x, y: data.y },
      })
    );
  };
  const updateTableStatus = async ({ status }) => {
    let data;
    if (status === "needHelp") {
      data = {
        table: {
          needHelp: false,
          modifyByAdmin: true,
        },
      };
    } else if (status === "needPayment") {
      data = {
        table: {
          needPayment: false,
          modifyByAdmin: true,
        },
      };
    } else if (status === "scanMenu") {
      data = {
        table: {
          scanStatus: status,
        },
      };
    } else {
      data = {
        table: {
          status: status,
          modifyByAdmin: true,
        },
      };
    }
    try {
      const res = await postUpdateTableStatus(table._id, data);
      // dispatch(editTableAction(res.data.table));
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
    } catch (error) {
      console.log(error);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };
  return (
    <Draggable
      axis='both'
      disabled={!canEditPosition}
      defaultPosition={
        !positions ||
          !positions[table?._id] ||
          positions[table?._id] === null ||
          !isBigScreen
          ? { x: 0, y: 0 }
          : { x: positions[table?._id]?.x, y: positions[table?._id]?.y }
      }
      grid={[50, 50]}
      scale={1}
      positionOffset={{ x: 0, y: 0 }}
      position={
        !positions || !positions?.[table?._id]?.x ? { x: 0, y: 0 } : null
      }
      onStop={handleStop}
    >
      <Col style={{ marginBottom: 15, marginLeft: 7, marginRight: 8 }}>
        <Badge.Ribbon
          text={
            <Text
              style={{ color: COLORS.white }}
              onClick={() =>
                updateTableStatus({
                  status: table?.needHelp
                    ? "needHelp"
                    : table?.needPayment
                      ? "needPayment"
                      : "scanMenu",
                })
              }
            >
              {intl.formatMessage({
                id: table?.needHelp
                  ? "needHelp"
                  : table?.needPayment
                    ? "needPayment"
                    : "scanned",
              })}
            </Text>
          }
          placement={rtl ? "start" : "end"}
          color={table.needHelp ? "red" : table.needPayment ? "cyan" : "orange"}
          style={{
            top: 30,
            display:
              table?.needHelp || table?.needPayment || isLookingToMenu
                ? "flex"
                : "none",
          }}
          className='pointer'
        >
          <Link to={canEditPosition ? "" : `/tables/${table?._id}`}>
            <div
              ref={cardRef}
              className='light-shadow'
              style={{
                cursor: canEditPosition ? "move" : "pointer",
                backgroundColor: canEditPosition
                  ? COLORS.danger
                  : COLORS.lightGray,
                width,
                height,
                border: customerInfo?._id
                  ? customerInfo?.isTimeAboutToEnd
                    ? "1px orange solid"
                    : customerInfo?.isTimeEnded
                      ? "1px red solid"
                      : "1px green solid"
                  : "none",
                borderRadius: isCircle ? "50%" : 10,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                paddingTop: 10,
                alignItems: "center",
                position: "relative"
              }}
            >
              <Title level={5} className='my-0'>
                {" "}
                {table?.name_en
                  ? rtl
                    ? table?.name
                    : table.name_en
                  : table.name}
              </Title>

              {customerInfo?._id ? (
                <Text className='my-font'>
                  {intl.formatMessage({ id: "sittingTime" })}:
                  <Tag className='mx-1' style={{ borderRadius: 4 }}>
                    {customerInfo?.isTimeNotStarted ?
                      intl.formatMessage({ id: 'notStarted' })
                      :
                      <Timer
                        setCustomerInfo={setCustomerInfo}
                        customerInfo={customerInfo}
                        table={table}
                        type={customerInfo?.isTimeAboutToEnd ? "warning" : customerInfo?.isTimeEnded ? "danger" : "success"}
                        className='mt-4'
                        callQueuedTime={customerInfo?.startTime ?? new Date()}
                      />}
                  </Tag>
                </Text>
              ) : null}
              {isLookingToMenu ? (
                <Timer
                  type='warning'
                  className='mt-4'
                  callQueuedTime={table?.timeFromScan ?? new Date()}
                />
              ) : null}

              {newOrders ? (
                <Badge
                  size='default'
                  offset={rtl ? [117, 10] : [-118, 10]}
                  count={newOrders}
                  style={{
                    backgroundColor: "#f6ffed",
                    color: "#389e0d",
                    borderColor: "#b7eb8f",
                  }}
                >
                  <Tag color={TAG_COLORS_BY_STATUS.open}>
                    {" "}
                    {newOrders ? `${intl.formatMessage({ id: "open" })}` : ""}
                    {" - "}
                    {oldestOrderTimer ? (
                      <Timer type='success' callQueuedTime={oldestOrderTimer} />
                    ) : null}
                  </Tag>
                </Badge>
              ) : null}
              {inServiceOrders ? (
                <Badge
                  size='default'
                  offset={rtl ? [88, 15] : [-84, 15]}
                  count={inServiceOrders}
                  style={{
                    backgroundColor: "#fff7e6",
                    color: "#d46b08",
                    borderColor: "#ffd591",
                  }}
                >
                  <Tag className='my-1' color={TAG_COLORS_BY_STATUS.inService}>
                    {" "}
                    {inServiceOrders
                      ? `${intl.formatMessage({ id: "inService" })}`
                      : ""}
                  </Tag>
                </Badge>
              ) : null}
              {servedOrders ? (
                <Badge
                  size='default'
                  offset={rtl ? [94, 10] : [-109, 10]}
                  count={servedOrders}
                  style={{
                    backgroundColor: "#e6fffb",
                    color: "#08979c",
                    borderColor: "#87e8de",
                  }}
                >
                  <Tag color={TAG_COLORS_BY_STATUS.served}>
                    {" "}
                    {servedOrders
                      ? `${intl.formatMessage({ id: "served" })}`
                      : ""}
                  </Tag>
                </Badge>
              ) : null}
              <div
                style={{ position: "absolute", bottom: 10 }}
              >
                <Text style={{padding:"5px 10px", fontSize:"13px"}}>
                  {`${intl.formatMessage({ id: "chairsNumber" })} : ${table?.numberOfChairs}`}
                </Text>
              </div>
            </div>
          </Link>
        </Badge.Ribbon>
      </Col>
    </Draggable>
  );
};

export default TableCard;
