import React, { useState, useEffect } from "react";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import COLORS from "../../../Style/colors";
import { Typography, Space, Spin } from "antd";
import { useIntl } from "react-intl";
import MyTimePicker from "./MyTimePicker";
import { getAnalyticsFoodAnalyticsItemsByDate } from "../../../API/fetch";
import { setGoogleDataAction } from "../../../redux/reducers/analyticsReducer";
import CustomButton from "../../../Components/CustomButton";
const { Title } = Typography;

const FoodAnalytics = ({ showAllFoods, setShowAllFoods }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const { googleData } = useSelector((state) => state.analytics);
  const { restaurant } = useSelector((state) => state.restaurant);
  const { foods } = useSelector((state) => state.menu);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    labels: [],
    itemsViewed: [],
    addToCarts: [],
    ecommercePurchases: [],
  });

  const [smallData, setSmallData] = useState({
    labels: [],
    itemsViewed: [],
    addToCarts: [],
    ecommercePurchases: [],
  });

  useEffect(() => {
    if (googleData?.foods?.length && foods?.length) {
      let sortData = [...googleData?.foods];
      sortData?.sort((a, b) => b.ecommercePurchases - a.ecommercePurchases);
      let small = {
        labels: [],
        itemsViewed: [],
        addToCarts: [],
        ecommercePurchases: [],
      };
      let all = {
        labels: [],
        itemsViewed: [],
        addToCarts: [],
        ecommercePurchases: [],
      };
      sortData.forEach((one, i) => {
        if (i < 3) {
          small.labels.push(one.foodName);
          small.itemsViewed.push(one.itemsViewed);
          small.addToCarts.push(one.addToCarts);
          small.ecommercePurchases.push(one.ecommercePurchases);
        }
        if(i < 40){

          all.labels.push(one.foodName);
          all.itemsViewed.push(one.itemsViewed);
          all.addToCarts.push(one.addToCarts);
          all.ecommercePurchases.push(one.ecommercePurchases);
        }
      });
      setSmallData(small);
      setData(all);
    }
  }, [googleData, rtl, showAllFoods]);

  const getDataForTime = async ({ start, end }) => {
    try {
      setLoading(true);
      const data = {
        start,
        end,
        id: restaurant?._id,
      };
      const res = await getAnalyticsFoodAnalyticsItemsByDate(data);
      if (res.data?.status === 'success') {
        const copy = {
          ...googleData,
          foods: res.data.foods,
        };
        dispatch(setGoogleDataAction(copy));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div>
      <Spin spinning={loading}>
        <Space
          direction='horizontal'
          style={{ width: "100%", justifyContent: "space-between" }}
        >
          <div>
            <Title style={{ marginBottom: 0, paddingBottom: 0 }} level={4}>
              {intl.formatMessage({ id: "mostVisited" })}
            </Title>
            <CustomButton
              text={intl.formatMessage({ id: showAllFoods ? "hide" : "more" })}
              onClick={() => setShowAllFoods(!showAllFoods)}
              type={showAllFoods ? "" : "primary"}
              className={`mt-1 border-8 px-3 }`}
            />
          </div>
          <MyTimePicker
            initialTime='last30days'
            loading={loading}
            getData={getDataForTime}
          />
        </Space>
        <Chart
          type='bar'
          data={{
            labels: showAllFoods ? data.labels : smallData.labels,
            datasets: [
              {
                label: `${intl.formatMessage({ id: "views" })}`,
                data: showAllFoods ? data?.itemsViewed : smallData?.itemsViewed,
                backgroundColor: [COLORS.gray],
                hoverBackgroundColor: [COLORS.gray],
                borderColor: COLORS.primary,
                borderWidth: 1,
              },
              {
                label: `${intl.formatMessage({ id: "addToCart" })}`,
                data: showAllFoods ? data?.addToCarts : smallData?.addToCarts,
                backgroundColor: [COLORS.lightBlue],
                hoverBackgroundColor: [COLORS.lightBlue],
              },
              {
                label: `${intl.formatMessage({ id: "payers" })}`,
                data: showAllFoods
                  ? data?.ecommercePurchases
                  : smallData?.ecommercePurchases,
                backgroundColor: [COLORS.primary],
                hoverBackgroundColor: [COLORS.primary],
              },
            ],
          }}
          options={{
            responsive: true,
          }}
        />
      </Spin>
    </div>
  );
};

export default FoodAnalytics;
