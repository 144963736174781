import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import {
  Form,
  Spin,
  Row,
  Col,
  Typography,
  Select,
  Divider,
  Input,
  InputNumber,
  Space,
  TimePicker,
  Checkbox,
  Switch,
  Button,
} from "antd";
import CustomButton from "../../../Components/CustomButton";
import MapModal from "./MapModal";
import DEFAULT_DAYS from "./DEFAULT_DAYS";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setTablesAction } from "../../../redux/reducers/tableReducer";
import {
  editBranchInAllBranchAction,
  setBranchAction,
} from "../../../redux/reducers/branchReducer";
import { postUpdateBranch } from "../../../API/fetch";
import openNotification from "../../../Components/Notifications";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  convertDefaultOpeningHours,
  getEveryDayLength,
  getOpeningHoursLength,
  getOpeningHoursValues,
} from "../../../helpers/openingHours";
import phoneCodes from "../../Auth/PhoneCods.json";
import { checkIfValidPhone } from "../../../helpers/index";
import citiesKSA from "../../Onboarding/Components/cities.json";
import COLORS from "../../../Style/colors";
import OrderTerms from "./OrderTerms";
import BranchImage from "./BranchImage";
const { Text, Title } = Typography;
const { Option } = Select;

const prefixSelector = (
  <Form.Item name='branchDialCode' noStyle>
    <Select
      style={{
        width: 150,
      }}
    >
      {phoneCodes.map((info) => {
        return (
          <Option key={`${info.dial_code}-${info.name}`} value={info.dial_code}>
            {info.dial_code} {info.name}
          </Option>
        );
      })}
    </Select>
  </Form.Item>
);

const OneBranchTab = ({ branch }) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const { rtl } = useSelector((state) => state.settings);
  const { allBranches } = useSelector((state) => state.branch);
  const { services } = useSelector((state) => state.auth);
  const { design, integrations } = useSelector((state) => state.restaurant);
  const history = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showSelectMap, setShowSelectMap] = useState(false);
  const [location, setLocation] = useState({});
  const [showAllDays, setShowAllDays] = useState(false);
  const [showDeliveryPrices, setShowDeliveryPrices] = useState(false);
  const [maximumKMToDeliver, setMaximumKMToDeliver] = useState("");
  const [showTerms, setShowTerms] = useState(false);
  const [disableTimes, setDisableTimes] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
  });
  const [everyDayLength, setEveryDayLength] = useState(1);
  const [openingHoursLength, setOpeningHoursLength] = useState([]);
  const [urlLocation, setUrlLocation] = useState("");

  useEffect(() => {
    if (branch?.workingHours?.length) {
      setOpeningHoursLength(getOpeningHoursLength(branch?.workingHours));
      setEveryDayLength(getEveryDayLength(branch?.workingHours));
    } else {
      setOpeningHoursLength(getOpeningHoursLength(DEFAULT_DAYS));
      setEveryDayLength(getEveryDayLength(DEFAULT_DAYS));
    }
  }, [branch?.workingHours, DEFAULT_DAYS]);
  // sort the cities
  const [sortedCities, setSortedCities] = useState(citiesKSA);
  useEffect(() => {
    if (citiesKSA.length > 0) {
      let copy = [...citiesKSA];
      setSortedCities(copy.sort((a, b) => a.name_ar.localeCompare(b.name_ar)));
    }
  }, [citiesKSA]);

  // useEffect(() => {
  //   if(branch && branch?.maximumDeliveryDistance){
  //     setMaximumDeliveryDistance(branch.maximumDeliveryDistance)
  //   }
  // }, [branch])
  useEffect(() => {
    if (branch?.isOrderWithTerms) {
      setShowTerms(branch?.isOrderWithTerms);
    }
  }, [branch]);

  const goToTables = () => {
    const tables = allBranches.find(
      (br) => br.branch?._id === branch._id
    )?.tables;
    dispatch(setTablesAction(tables));
    dispatch(setBranchAction(branch));
    history("/tables");
  };
  const goToWaitingsArea = () => {
    dispatch(setBranchAction(branch));
    history("/waiting");
  };
  const goToReservationArea = () => {
    const tables = allBranches.find(
      (br) => br.branch?._id === branch._id
    )?.tables;
    dispatch(setTablesAction(tables));
    dispatch(setBranchAction(branch));
    history("/reservation");
  };

  // const goToReservationModiferArea = () => {
  //   dispatch(setBranchAction(branch));
  //   history("/reservation-modifier");
  // }

  useEffect(() => {
    if (branch?.lat && branch?.lng && !location?.lat && !location?.lng) {
      setLocation({ lat: branch.lat, lng: branch.lng });
    }
    form.resetFields();
  }, [branch]);

  const onFinish = async (values) => {
    if (values.isOrderWithTerms && !values.orderTerms.trim().length) {
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "termsError" }),
        type: "error",
        rtl,
      });
      return;
    }
    if (values.isOrderWithTerms && !values?.orderTerms_en?.trim()?.length) {
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "termsError_en" }),
        type: "error",
        rtl,
      });
      return;
    }
    let lat = location?.lat;
    let lng = location?.lng;
    const workingHours = getOpeningHoursValues(
      values?.workingHours,
      values?.everyDay
    );
    let branchDialCode = values?.branchDialCode?.substring(1);
    let sendWhatsappData = {
      status: values?.whatSappStatus,
    };

    let maximumDeliveryDistance = branch?.maximumDeliveryDistance;
    if (maximumDeliveryDistance) {
      maximumDeliveryDistance = maximumKMToDeliver;
    }

    setLoading(true);
    try {
      let status = "Active";
      if (values.status === false) {
        status = "notActive";
      }
      const statusStrings = {
        true: "Active",
        false: "Disabled",
      };
      const data = {
        branch: {
          ...values,
          appWaitingStatus: statusStrings[values.appWaitingStatus],
          appReservationStatus: statusStrings[values.appReservationStatus],
          appPickupStatus: statusStrings[values.appPickupStatus],
          appDeliveryStatus: statusStrings[values.appDeliveryStatus],
          status,
          workingHours,
          showMenu: values?.showMenu || false,
          maximumDeliveryDistance,
          sendWhatsappData,
          branchDialCode,
          orderTerms: values.isOrderWithTerms ? values?.orderTerms : " ",
          orderTerms_en: values.isOrderWithTerms ? values?.orderTerms_en : " ",
          applyOrderTerms: values?.isOrderWithTerms
            ? values?.applyOrderTerms
            : ["All"],
        },
      };
      if (lat && lng) {
        data.branch.lat = lat;
        data.branch.lng = lng;
        data.branch.location = {
          type: "Point",
          coordinates: [lng, lat],
        };
        if (urlLocation?.trim().length) {
          data.branch.urlLocation = urlLocation;
        }
      }
      if (values?.text || values?.text_en) {
        data.branch.cartMessage = {
          text: values?.text,
          text_en: values?.text_en,
          colorSchema: values?.colorSchema,
        };
      } else {
        data.branch.cartMessage = {
          text: "",
          text_en: "",
          colorSchema: "primary",
        };
      }
      if (values?.noteTitle || values?.noteTitle_en) {
        data.branch.cartNote = {
          noteTitle: values?.noteTitle,
          noteTitle_en: values?.noteTitle_en,
          noteRequired: values?.noteRequired,
          // noteColorSchema: values?.noteColorSchema,
        };
      } else {
        data.branch.cartNote = {
          noteTitle: "",
          noteTitle_en: "",
          noteRequired: "Optional",
          noteColorSchema: "primary",
        };
      }
      const res = await postUpdateBranch(branch._id, data);
      const newBrach = {
        ...branch,
        ...data.branch,
      };
      dispatch(setBranchAction(newBrach));
      dispatch(editBranchInAllBranchAction(newBrach));

      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
    } catch (error) {
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };
  return (
    <div>
      {/* Map Modal */}
      <MapModal
        show={showSelectMap}
        hide={() => setShowSelectMap(false)}
        location={location}
        setLocation={setLocation}
        setCityName={(city) => {
          form.setFieldsValue({ city: city });
        }}
        urlLocation={urlLocation}
        setUrlLocation={setUrlLocation}
      />
      <Spin spinning={loading}>
        <Form
          name='branch-form'
          form={form}
          className='form-container'
          layout='vertical'
          onFinish={onFinish}
          initialValues={{
            name: branch?.name,
            name_en: branch?.name_en,
            description: branch?.description,
            description_en: branch?.description_en,
            noteLanguage: branch?.noteLanguage,
            showNote: branch?.showNote,
            text: branch?.cartMessage?.text,
            text_en: branch?.cartMessage?.text_en,
            colorSchema: branch?.cartMessage?.colorSchema,
            noteTitle: branch?.cartNote?.noteTitle,
            noteTitle_en: branch?.cartNote?.noteTitle_en,
            // noteColorSchema: branch?.cartNote?.noteColorSchema,
            noteRequired: branch?.cartNote?.noteRequired,
            cityName: branch?.cityName,
            status: branch?.status === "Active",
            maximumMinutesForSeeMenu: branch?.maximumMinutesForSeeMenu,
            appPickupStatus:
              branch?.appPickupStatus === "Active" ? true : false,
            appDeliveryStatus:
              branch?.appDeliveryStatus === "Active" ? true : false,
            appWaitingStatus:
              branch?.appWaitingStatus === "Active" ? true : false,
            appReservationStatus:
              branch?.appReservationStatus === "Active" ? true : false,
            DoesDeliveryActive: branch?.DoesDeliveryActive,
            showMenu: branch?.showMenu,
            DoesPickupActive: branch?.DoesPickupActive,
            DoesPickupCarActive: branch?.DoesPickupCarActive,
            DoesPickupScheduleActive: branch?.DoesPickupScheduleActive,
            maximumDeliveryDistance: branch?.maximumDeliveryDistance,
            minimumOrder: branch?.minimumOrder,
            DoesCustomOrderActive: branch?.DoesCustomOrderActive,
            everyDay: branch?.workingHours?.length
              ? convertDefaultOpeningHours(branch?.workingHours, "EVERYDAY")
              : convertDefaultOpeningHours(DEFAULT_DAYS, "EVERYDAY"),
            workingHours: branch?.workingHours?.length
              ? convertDefaultOpeningHours(
                  branch?.workingHours,
                  "OPENING_HOURS"
                )
              : convertDefaultOpeningHours(DEFAULT_DAYS, "OPENING_HOURS"),

            deliveryPrices: branch?.deliveryPrices?.length
              ? branch?.deliveryPrices
              : [
                  { from: 1, to: 5, price: 12 },
                  { from: 6, to: 10, price: 15 },
                  { from: 11, to: 15, price: 18 },
                  { from: 16, to: 20, price: 20 },
                  { from: 21, to: 25, price: 22 },
                  { from: 26, to: 30, price: 26 },
                  { from: 31, to: 35, price: 28 },
                  { from: 36, to: 40, price: 30 },
                  { from: 41, to: 45, price: 32 },
                  { from: 46, to: 50, price: 34 },
                ],
            branchDialCode: branch?.branchDialCode
              ? `+${branch?.branchDialCode}`
              : "",
            branchPhone: branch?.branchPhone,
            whatSappStatus: branch?.sendWhatsappData?.status,
            isOrderWithTerms: branch?.isOrderWithTerms,
            orderTerms: branch?.orderTerms,
            orderTerms_en: branch?.orderTerms_en,
            applyOrderTerms: branch?.applyOrderTerms
              ? branch?.applyOrderTerms
              : ["All"],
            autoAcceptAppOrders: branch?.autoAcceptAppOrders,
            autoAcceptDelivery: branch?.autoAcceptDelivery,
            autoAcceptPickup: branch?.autoAcceptPickup,
            doesHaveTobaccoTax: branch?.doesHaveTobaccoTax,
          }}
        >
          {() => (
            <div style={{ backgroundColor: COLORS.gray }}>
              <div
                style={{
                  backgroundColor: COLORS.white,
                  padding: "10px 20px 20px",
                  borderRadius: 7,
                }}
                className='light-shadow'
              >
                <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                  {/* Title */}
                  <Col md={12} sm={24} xs={24}>
                    <Title className='my-font' level={5}>
                      {intl.formatMessage({ id: "basicInfo" })}
                    </Title>
                  </Col>
                </Row>
                <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                  {/* Names */}
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "nameAr" })}
                      className='mb-1'
                      name='name'
                      style={{ borderRadius: 20 }}
                      rules={[
                        {
                          required: true,
                          message: intl.formatMessage({ id: "errorname" }),
                        },
                      ]}
                    >
                      <Input
                        className={"input"}
                        style={{ borderRadius: 5, width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "nameEn" })}
                      className='mb-1'
                      name='name_en'
                      rules={[
                        {
                          required: true,
                          message: intl.formatMessage({ id: "errorname" }),
                        },
                      ]}
                    >
                      <Input className={"input"} style={{ borderRadius: 5 }} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                  {/* Names */}
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "descriptionAr" })}
                      className='mb-1'
                      name='description'
                      style={{ borderRadius: 20 }}
                    >
                      <Input.TextArea
                        className={"input"}
                        style={{ borderRadius: 5, width: "100%" }}
                        rows={3}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "descriptionEn" })}
                      className='mb-1'
                      name='description_en'
                    >
                      <Input.TextArea
                        className={"input"}
                        style={{ borderRadius: 5 }}
                        rows={3}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  className='mt-4'
                  gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
                >
                  {/* Language */}
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "showNoteExplain" })}
                      className='mb-1'
                      name='showNote'
                      style={{ borderRadius: 20 }}
                      valuePropName='checked'
                    >
                      <Switch
                        checkedChildren={
                          <p>
                            {intl.formatMessage({
                              id: "active",
                            })}
                          </p>
                        }
                        unCheckedChildren={
                          <p>
                            {intl.formatMessage({
                              id: "disabled",
                            })}
                          </p>
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      tooltip={
                        <Text type='primary' className='my-font'>
                          {intl.formatMessage({ id: "explainNoteLanguage" })}
                        </Text>
                      }
                      label={intl.formatMessage({ id: "noteLanguage" })}
                      className='mb-1'
                      name='noteLanguage'
                      style={{ borderRadius: 20 }}
                    >
                      <Select>
                        <Option value='AR'>
                          {intl.formatMessage({ id: "arabic" })}
                        </Option>
                        <Option value='EN'>
                          {intl.formatMessage({ id: "english" })}
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                  <Col md={12} sm={12} xs={12}>
                    <Form.Item
                      label={intl.formatMessage({ id: "doesHaveTobaccoTax" })}
                      className='mb-1'
                      name='doesHaveTobaccoTax'
                      style={{ borderRadius: 20 }}
                      valuePropName='checked'
                      tooltip={
                        <Text className='my-font'>
                          {intl.formatMessage({
                            id: "doesHaveTobaccoTaxExplain",
                          })}
                        </Text>
                      }
                    >
                      <Switch
                        checkedChildren={
                          <p>
                            {intl.formatMessage({
                              id: "active",
                            })}
                          </p>
                        }
                        unCheckedChildren={
                          <p>
                            {intl.formatMessage({
                              id: "disabled",
                            })}
                          </p>
                        }
                      />
                    </Form.Item>
                  </Col>
                  {/* time to block users */}
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "maxMinutesToSeeMenu" })}
                      tooltip={
                        <Text className='my-font' type='primary'>
                          {intl.formatMessage({ id: "maximumTimeForSeeMenu" })}
                        </Text>
                      }
                      className='mb-1'
                      name='maximumMinutesForSeeMenu'
                      style={{ borderRadius: 20 }}
                    >
                      <InputNumber
                        className={"input"}
                        style={{ borderRadius: 5, width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <Divider />
              <div
                style={{
                  backgroundColor: COLORS.white,
                  padding: "10px 20px 20px",
                  borderRadius: 7,
                }}
                className='light-shadow'
              >
                <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                  {/* Title */}
                  <Col md={12} sm={24} xs={24}>
                    <Title className='my-font' level={5}>
                      {intl.formatMessage({ id: "branchInfo" })}
                    </Title>
                  </Col>
                </Row>
                <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                  <Col md={8} sm={24} xs={24}>
                    <CustomButton
                      type='primary'
                      text={intl.formatMessage({ id: "tables" })}
                      className={`btnRegister btn-text border-8 px-5 mt-2`}
                      onClick={goToTables}
                      disabled={
                        !services?.length ||
                        (!services?.includes("ORDER_A") &&
                          !services?.includes("ORDER_B") &&
                          !services?.includes("RESERVATION"))
                      }
                    />
                  </Col>
                  <Col md={8} sm={24} xs={24}>
                    <CustomButton
                      type='primary'
                      text={intl.formatMessage({ id: "waitingAreas" })}
                      className={`btnRegister btn-text border-8 px-5 mt-2`}
                      onClick={goToWaitingsArea}
                      disabled={
                        !services?.length || !services?.includes("WAITING")
                      }
                    />
                  </Col>
                  <Col md={8} sm={24} xs={24}>
                    <CustomButton
                      type='primary'
                      text={intl.formatMessage({ id: "reservation" })}
                      // icon={<GiBarracksTent className='mx-2' />}
                      className={`btnRegister btn-text border-8 px-5 mt-2`}
                      onClick={goToReservationArea}
                      disabled={
                        !services?.length || !services?.includes("RESERVATION")
                      }
                    />
                  </Col>
                </Row>
              </div>
              <Divider />
              <div
                style={{
                  backgroundColor: COLORS.white,
                  padding: "10px 20px 20px",
                  borderRadius: 7,
                }}
                className='light-shadow'
              >
                <Row
                  className='mb-0'
                  gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
                >
                  {/* Title */}
                  <Col md={24} sm={24} xs={24}>
                    <Title className='my-font' level={5}>
                      {intl.formatMessage({ id: "workDays" })}
                    </Title>
                  </Col>
                </Row>
                <Row
                  className='mb-0'
                  gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
                >
                  <Col md={24} sm={24} xs={24}>
                    <Form.List name={"everyDay"}>
                      {(fields) => {
                        return (
                          <>
                            {fields.map(
                              ({ key, name, fieldKey, ...restField }) => (
                                <Space
                                  key={key}
                                  direction='horizontal'
                                  style={{
                                    display: "flex",
                                    marginBottom: 8,
                                    marginTop: 8,
                                  }}
                                  align='middle'
                                >
                                  <Text className='my-font mx-3'>
                                    {intl.formatMessage({ id: "everyDay" })}
                                  </Text>

                                  <Form.List name={[name, "hours"]}>
                                    {(hours, { add, remove }) => {
                                      return (
                                        <Row>
                                          <>
                                            {hours.map(
                                              ({
                                                key,
                                                name,
                                                fieldKey,
                                                ...restField
                                              }) => (
                                                <Col
                                                  xs={24}
                                                  key={key}
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <Form.Item
                                                    {...restField}
                                                    name={[name, "from"]}
                                                    fieldKey={[
                                                      fieldKey,
                                                      "from",
                                                    ]}
                                                    label={intl.formatMessage({
                                                      id: "from",
                                                    })}
                                                    style={{
                                                      flexDirection: "row",
                                                      alignItems: "center",
                                                    }}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message:
                                                          intl.formatMessage({
                                                            id: "errorFieldEmpty",
                                                          }),
                                                      },
                                                    ]}
                                                  >
                                                    <TimePicker
                                                      className={"mx-3"}
                                                      okText={intl.formatMessage(
                                                        { id: "ok" }
                                                      )}
                                                      placeholder={intl.formatMessage(
                                                        {
                                                          id: "from",
                                                        }
                                                      )}
                                                      format={"HH:mm"}
                                                      onChange={(e) => {
                                                        const {
                                                          workingHours:
                                                            oldHours,
                                                        } =
                                                          form.getFieldsValue(
                                                            "workingHours"
                                                          );
                                                        const { everyDay } =
                                                          form.getFieldsValue(
                                                            "everyDay"
                                                          );
                                                        form.setFieldsValue({
                                                          workingHours:
                                                            oldHours.map(
                                                              (day) => {
                                                                let newHours = [
                                                                  ...day?.hours,
                                                                ];
                                                                if (
                                                                  newHours?.length >
                                                                  everyDay[0]
                                                                    ?.hours
                                                                    ?.length
                                                                ) {
                                                                  newHours =
                                                                    newHours?.slice(
                                                                      0,
                                                                      -1
                                                                    );
                                                                  setOpeningHoursLength(
                                                                    [
                                                                      1, 1, 1,
                                                                      1, 1, 1,
                                                                      1,
                                                                    ]
                                                                  );
                                                                }
                                                                if (
                                                                  newHours?.length <
                                                                  everyDay[0]
                                                                    ?.hours
                                                                    ?.length
                                                                ) {
                                                                  newHours.push(
                                                                    {
                                                                      from: "",
                                                                      to: "",
                                                                    }
                                                                  );
                                                                }
                                                                return {
                                                                  ...day,
                                                                  hours:
                                                                    newHours?.map(
                                                                      (
                                                                        schift,
                                                                        i
                                                                      ) => {
                                                                        if (
                                                                          name ===
                                                                          i
                                                                        ) {
                                                                          return {
                                                                            ...schift,
                                                                            from: e,
                                                                          };
                                                                        } else {
                                                                          return schift;
                                                                        }
                                                                      }
                                                                    ),
                                                                };
                                                              }
                                                            ),
                                                        });
                                                      }}
                                                    />
                                                  </Form.Item>
                                                  <Form.Item
                                                    {...restField}
                                                    name={[name, "to"]}
                                                    fieldKey={[fieldKey, "to"]}
                                                    label={intl.formatMessage({
                                                      id: "to",
                                                    })}
                                                    style={{
                                                      flexDirection: "row",
                                                      alignItems: "center",
                                                    }}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message:
                                                          intl.formatMessage({
                                                            id: "errorFieldEmpty",
                                                          }),
                                                      },
                                                    ]}
                                                  >
                                                    <TimePicker
                                                      className={"mx-3"}
                                                      okText={intl.formatMessage(
                                                        { id: "ok" }
                                                      )}
                                                      placeholder={intl.formatMessage(
                                                        {
                                                          id: "to",
                                                        }
                                                      )}
                                                      format={"HH:mm"}
                                                      onChange={(e) => {
                                                        const {
                                                          workingHours:
                                                            oldHours,
                                                        } =
                                                          form.getFieldsValue(
                                                            "workingHours"
                                                          );
                                                        const { everyDay } =
                                                          form.getFieldsValue(
                                                            "everyDay"
                                                          );
                                                        form.setFieldsValue({
                                                          workingHours:
                                                            oldHours.map(
                                                              (day) => {
                                                                let newHours = [
                                                                  ...day?.hours,
                                                                ];
                                                                if (
                                                                  newHours?.length >
                                                                  everyDay[0]
                                                                    ?.hours
                                                                    ?.length
                                                                ) {
                                                                  newHours =
                                                                    newHours?.slice(
                                                                      0,
                                                                      -1
                                                                    );
                                                                  setOpeningHoursLength(
                                                                    [
                                                                      1, 1, 1,
                                                                      1, 1, 1,
                                                                      1,
                                                                    ]
                                                                  );
                                                                }
                                                                if (
                                                                  newHours?.length <
                                                                  everyDay[0]
                                                                    ?.hours
                                                                    ?.length
                                                                ) {
                                                                  newHours.push(
                                                                    {
                                                                      from: "",
                                                                      to: "",
                                                                    }
                                                                  );
                                                                  setOpeningHoursLength(
                                                                    [
                                                                      2, 2, 2,
                                                                      2, 2, 2,
                                                                      2,
                                                                    ]
                                                                  );
                                                                }
                                                                return {
                                                                  ...day,
                                                                  hours:
                                                                    newHours?.map(
                                                                      (
                                                                        schift,
                                                                        i
                                                                      ) => {
                                                                        if (
                                                                          name ===
                                                                          i
                                                                        ) {
                                                                          return {
                                                                            ...schift,
                                                                            to: e,
                                                                          };
                                                                        } else {
                                                                          return schift;
                                                                        }
                                                                      }
                                                                    ),
                                                                };
                                                              }
                                                            ),
                                                        });
                                                      }}
                                                    />
                                                  </Form.Item>
                                                  <Form.Item>
                                                    {name !== 0 && (
                                                      <Button
                                                        onClick={() => {
                                                          const { everyDay } =
                                                            form.getFieldsValue(
                                                              "everyDay"
                                                            );
                                                          setEveryDayLength(
                                                            everyDayLength - 1
                                                          );
                                                          setOpeningHoursLength(
                                                            [
                                                              1, 1, 1, 1, 1, 1,
                                                              1,
                                                            ]
                                                          );
                                                          remove(name);
                                                          const {
                                                            workingHours:
                                                              oldHours,
                                                          } =
                                                            form.getFieldsValue(
                                                              "workingHours"
                                                            );
                                                          form.setFieldsValue({
                                                            workingHours:
                                                              oldHours.map(
                                                                (day) => {
                                                                  let newHours =
                                                                    [
                                                                      ...day?.hours,
                                                                    ];
                                                                  return {
                                                                    ...day,
                                                                    hours:
                                                                      everyDay[0]
                                                                        ?.hours
                                                                        ?.length <=
                                                                      newHours?.length
                                                                        ? newHours.slice(
                                                                            0,
                                                                            -1
                                                                          )
                                                                        : newHours,
                                                                  };
                                                                }
                                                              ),
                                                          });
                                                        }}
                                                      >
                                                        <MinusCircleOutlined
                                                          style={{
                                                            color: "#f76877",
                                                          }}
                                                        />
                                                      </Button>
                                                    )}
                                                  </Form.Item>
                                                </Col>
                                              )
                                            )}
                                          </>
                                          <Col>
                                            <Form.Item>
                                              <Button
                                                type='dashed'
                                                disabled={
                                                  everyDayLength > 1 && true
                                                }
                                                onClick={() => {
                                                  add({ from: "", to: "" });
                                                  setOpeningHoursLength([
                                                    2, 2, 2, 2, 2, 2, 2,
                                                  ]);
                                                  setEveryDayLength(
                                                    everyDayLength + 1
                                                  );
                                                  const {
                                                    workingHours: oldHours,
                                                  } =
                                                    form.getFieldsValue(
                                                      "workingHours"
                                                    );
                                                  const { everyDay } =
                                                    form.getFieldsValue(
                                                      "everyDay"
                                                    );
                                                  form.setFieldsValue({
                                                    workingHours: oldHours.map(
                                                      (day) => {
                                                        let newHours = [
                                                          ...day.hours,
                                                        ];
                                                        if (
                                                          newHours.length <
                                                          everyDay[0]?.hours
                                                            ?.length
                                                        ) {
                                                          newHours.push({
                                                            from: "",
                                                            to: "",
                                                          });
                                                        }
                                                        return {
                                                          ...day,
                                                          hours: newHours,
                                                        };
                                                      }
                                                    ),
                                                  });
                                                }}
                                                block
                                              >
                                                <PlusOutlined />{" "}
                                                {intl.formatMessage({
                                                  id: "addShift",
                                                })}
                                              </Button>
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                      );
                                    }}
                                  </Form.List>
                                </Space>
                              )
                            )}
                          </>
                        );
                      }}
                    </Form.List>
                  </Col>
                </Row>
                <Row
                  className='mb-0'
                  gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
                >
                  <Col md={24} sm={24} xs={24}>
                    <CustomButton
                      text={intl.formatMessage({
                        id: showAllDays ? "hideDetails" : "showDetails",
                      })}
                      type={showAllDays ? "primary" : "secondary"}
                      className='border-8'
                      onClick={() => setShowAllDays(!showAllDays)}
                    />
                  </Col>
                </Row>
                {/* */}

                <Row
                  className='mb-0 mt-5'
                  gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
                  style={{
                    position: showAllDays ? "relative" : "absolute",
                    zIndex: showAllDays ? 0 : -999,
                    top: showAllDays ? 0 : -2000,
                  }}
                >
                  <Col md={24} sm={24} xs={24}>
                    <Form.List name={"workingHours"}>
                      {(fields) => {
                        return (
                          <>
                            {fields.map(
                              ({ key, name, fieldKey, ...restField }) => {
                                return (
                                  <Space
                                    key={key}
                                    direction='horizontal'
                                    style={{
                                      display: "flex",
                                      marginBottom: 8,
                                      marginTop: 8,
                                    }}
                                    align='middle'
                                  >
                                    <Form.Item
                                      {...restField}
                                      name={[name, "isOpen"]}
                                      fieldKey={[fieldKey, "isOpen"]}
                                      style={{
                                        flexDirection: "row",
                                        alignItems: "center",
                                      }}
                                      valuePropName='checked'
                                    >
                                      <Checkbox
                                        onChange={(e) => {
                                          if (e.target.checked === false) {
                                            setDisableTimes({
                                              ...disableTimes,
                                              [`${name}`]: true,
                                            });
                                          } else {
                                            setDisableTimes({
                                              ...disableTimes,
                                              [`${name}`]: false,
                                            });
                                          }
                                        }}
                                      />
                                    </Form.Item>
                                    <Text
                                      disabled={disableTimes[`${name}`]}
                                      className='my-font mx-3'
                                    >
                                      {rtl
                                        ? DEFAULT_DAYS[name].name
                                        : DEFAULT_DAYS[name].name_en}
                                    </Text>
                                    <Form.List name={[name, "hours"]}>
                                      {(hours, { add, remove }) => {
                                        const disabled =
                                          disableTimes[`${name}`];
                                        const removeKey = name;
                                        return (
                                          <Row>
                                            <>
                                              {hours.map(
                                                ({
                                                  key,
                                                  name,
                                                  fieldKey,
                                                  ...restField
                                                }) => (
                                                  <Col
                                                    xs={24}
                                                    key={key}
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <Form.Item
                                                      {...restField}
                                                      name={[name, "from"]}
                                                      fieldKey={[
                                                        fieldKey,
                                                        "from",
                                                      ]}
                                                      label={intl.formatMessage(
                                                        { id: "from" }
                                                      )}
                                                      style={{
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                      }}
                                                      rules={[
                                                        {
                                                          required: true,
                                                          message:
                                                            intl.formatMessage({
                                                              id: "errorFieldEmpty",
                                                            }),
                                                        },
                                                      ]}
                                                    >
                                                      <TimePicker
                                                        disabled={disabled}
                                                        className={"mx-3"}
                                                        okText={intl.formatMessage(
                                                          { id: "ok" }
                                                        )}
                                                        placeholder={intl.formatMessage(
                                                          {
                                                            id: "from",
                                                          }
                                                        )}
                                                        format={"HH:mm"}
                                                      />
                                                    </Form.Item>
                                                    <Form.Item
                                                      {...restField}
                                                      name={[name, "to"]}
                                                      fieldKey={[
                                                        fieldKey,
                                                        "to",
                                                      ]}
                                                      label={intl.formatMessage(
                                                        { id: "to" }
                                                      )}
                                                      style={{
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                      }}
                                                      rules={[
                                                        {
                                                          required: true,
                                                          message:
                                                            intl.formatMessage({
                                                              id: "errorFieldEmpty",
                                                            }),
                                                        },
                                                      ]}
                                                    >
                                                      <TimePicker
                                                        disabled={disabled}
                                                        className={"mx-3"}
                                                        okText={intl.formatMessage(
                                                          { id: "ok" }
                                                        )}
                                                        placeholder={intl.formatMessage(
                                                          {
                                                            id: "from",
                                                          }
                                                        )}
                                                        format={"HH:mm"}
                                                      />
                                                    </Form.Item>
                                                    <Form.Item>
                                                      {name !== 0 && (
                                                        <Button
                                                          disabled={disabled}
                                                          onClick={() => {
                                                            remove(name);
                                                            setOpeningHoursLength(
                                                              openingHoursLength.map(
                                                                (el, i) => {
                                                                  if (
                                                                    i ===
                                                                    removeKey
                                                                  ) {
                                                                    el = el - 1;
                                                                  }
                                                                  return el;
                                                                }
                                                              )
                                                            );
                                                          }}
                                                        >
                                                          <MinusCircleOutlined
                                                            style={{
                                                              color: "#f76877",
                                                            }}
                                                          />
                                                        </Button>
                                                      )}
                                                    </Form.Item>
                                                  </Col>
                                                )
                                              )}
                                            </>
                                            <Col>
                                              <Form.Item>
                                                <Button
                                                  disabled={
                                                    (disableTimes[`${name}`] ||
                                                      openingHoursLength[name] >
                                                        1) &&
                                                    true
                                                  }
                                                  type='dashed'
                                                  onClick={() => {
                                                    add();
                                                    setOpeningHoursLength(
                                                      openingHoursLength.map(
                                                        (el, i) => {
                                                          if (i === name) {
                                                            el = el + 1;
                                                          }
                                                          return el;
                                                        }
                                                      )
                                                    );
                                                  }}
                                                  block
                                                >
                                                  <PlusOutlined />{" "}
                                                  {intl.formatMessage({
                                                    id: "addShift",
                                                  })}
                                                </Button>
                                              </Form.Item>
                                            </Col>
                                          </Row>
                                        );
                                      }}
                                    </Form.List>
                                  </Space>
                                );
                              }
                            )}
                          </>
                        );
                      }}
                    </Form.List>
                  </Col>
                </Row>
              </div>
              <Divider />
              <div
                style={{
                  backgroundColor: COLORS.white,
                  padding: "10px 20px 20px",
                  borderRadius: 7,
                }}
                className='light-shadow'
              >
                <Row
                  className='mb-0'
                  gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
                >
                  {/* Title */}
                  <Col md={24} sm={24} xs={24}>
                    <Title className='my-font' level={5}>
                      {intl.formatMessage({ id: "otherInfo" })}
                    </Title>
                    {integrations?.deliveryCompanies?.some(
                      (c) =>
                        c?.name === "iwaiter Delivery" &&
                        c?.linkedBranches?.findIndex(
                          (item) => item?.localId === branch?._id
                        ) !== -1
                    ) ? (
                      <Text className='my-font'>
                        {intl.formatMessage({ id: "deliveryOnIntegrationTab" })}
                      </Text>
                    ) : null}
                  </Col>
                </Row>
                <Row
                  className='mt-4'
                  gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
                >
                  {/* Locations */}
                  <Col md={8} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "cityName" })}
                      className='mb-1'
                      name='cityName'
                    >
                      <Select
                        showSearch
                        filterOption={(input, option) =>
                          (option?.value ?? "").includes(input)
                        }
                        className='my-font'
                        disabled={integrations?.deliveryCompanies?.some(
                          (c) =>
                            c?.name === "iwaiter Delivery" &&
                            c?.linkedBranches?.findIndex(
                              (item) => item?.localId === branch?._id
                            ) !== -1
                        )}
                      >
                        {sortedCities.map((info) => {
                          return (
                            <Option
                              key={info?.city_id}
                              value={`${info?.name_en} - ${info?.name_ar}`}
                              className='my-font'
                            >
                              {info.name_en} - {info.name_ar}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={8} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "minimumOrderPrice" })}
                      className='mb-1'
                      name='minimumOrder'
                    >
                      <InputNumber
                        className={"input"}
                        style={{ borderRadius: 5, width: "100%" }}
                        disabled={integrations?.deliveryCompanies?.some(
                          (c) =>
                            c?.name === "iwaiter Delivery" &&
                            c?.linkedBranches?.findIndex(
                              (item) => item?.localId === branch?._id
                            ) !== -1
                        )}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={4} sm={24} xs={24}>
                    <Space direction='vertical'>
                      <Text>{intl.formatMessage({ id: "location" })}</Text>
                      <CustomButton
                        onClick={() => setShowSelectMap(true)}
                        text={intl.formatMessage({ id: "chooseLocation" })}
                        type='primary'
                        className={`btnRegister btn-text border-8 px-5 `}
                        disabled={integrations?.deliveryCompanies?.some(
                          (c) =>
                            c?.name === "iwaiter Delivery" &&
                            c?.linkedBranches?.findIndex(
                              (item) => item?.localId === branch?._id
                            ) !== -1
                        )}
                      />
                    </Space>
                  </Col>
                </Row>
                <Divider orientation={rtl ? "right" : "left"}>
                  <Text className='my-font'>
                    {intl.formatMessage({ id: "notifications" })}
                  </Text>
                </Divider>
                <Row
                  className='mt-4'
                  gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
                >
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      tooltip={
                        <Text type='primary' className='my-font'>
                          {intl.formatMessage({
                            id: "explainWhatsappNotification",
                          })}
                        </Text>
                      }
                      label={intl.formatMessage({ id: "whatsappNotification" })}
                      className='mb-1'
                      name='whatSappStatus'
                      style={{ borderRadius: 20 }}
                    >
                      <Select className='my-font'>
                        <Option
                          disabled={!form.getFieldValue("branchPhone")}
                          value='Active'
                          className='my-font'
                        >
                          {intl.formatMessage({ id: "active" })}{" "}
                          {!form.getFieldValue("branchPhone") ? (
                            <Text type='danger'>
                              {intl.formatMessage({
                                id: "mustActivePhoneFirst",
                              })}
                            </Text>
                          ) : null}
                        </Option>
                        <Option value='notActive' className='my-font'>
                          {intl.formatMessage({ id: "disabled" })}
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      name='branchPhone'
                      label={intl.formatMessage({ id: "branchPhone" })}
                      rules={[
                        {
                          validator: (_, value) => {
                            if (!value) return Promise.resolve();
                            const branchDialCode =
                              form.getFieldValue("branchDialCode");
                            if (!branchDialCode) {
                              return Promise.reject(
                                intl.formatMessage({ id: "errorphone" })
                              );
                            }
                            let valid = checkIfValidPhone(
                              `${branchDialCode}${value}`
                            );
                            if (valid) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(
                                intl.formatMessage({ id: "errorphone" })
                              );
                            }
                          },
                        },
                      ]}
                    >
                      <Input
                        addonBefore={rtl ? null : prefixSelector}
                        addonAfter={rtl ? prefixSelector : null}
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <Divider />
              <div
                style={{
                  backgroundColor: COLORS.white,
                  padding: "10px 20px 20px",
                  borderRadius: 7,
                }}
                className='light-shadow'
              >
                {/* <Divider orientation={rtl ? "right" : "left"}> */}
                <Title level={5} className='my-font'>
                  {intl.formatMessage({ id: "cartMessage" })}
                </Title>
                {/* </Divider> */}
                <Row
                  className='mt-3'
                  gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
                >
                  {/* Cart messgate AR */}
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "textMessage" })}
                      className='mb-1'
                      name='text'
                      style={{ borderRadius: 20 }}
                    >
                      <Input maxLength={100} />
                    </Form.Item>
                  </Col>
                  {/* time to block users */}
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "textMessage_en" })}
                      className='mb-1'
                      name='text_en'
                      style={{ borderRadius: 20 }}
                    >
                      <Input maxLength={100} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  className='mt-3'
                  gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
                >
                  {/* Cart messgate AR */}
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      className='mb-1'
                      label={intl.formatMessage({ id: "colorSchema" })}
                      name='colorSchema'
                      style={{ borderRadius: 20 }}
                    >
                      <Select className='my-font'>
                        <Option value='primary' className='my-font'>
                          {intl.formatMessage({ id: "primaryColor" })}
                        </Option>
                        <Option value={"danger"} className='my-font'>
                          {intl.formatMessage({ id: "dangerColor" })}
                        </Option>
                        <Option value={"success"} className='my-font'>
                          {intl.formatMessage({ id: "successColor" })}
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  {/* time to block users */}
                  <Col
                    style={{
                      alignItems: "center",
                      display: "flex",
                    }}
                    md={12}
                    sm={24}
                    xs={24}
                  >
                    <Text
                      style={{
                        color:
                          form?.getFieldValue("colorSchema") === "primary"
                            ? design?.primaryButtonBG
                            : form?.getFieldValue("colorSchema") === "danger"
                            ? "#f76877"
                            : "#82d871",
                      }}
                    >
                      {rtl
                        ? form?.getFieldValue("text")
                        : form?.getFieldValue("text_en")}
                    </Text>
                  </Col>
                </Row>
              </div>
              <Divider />
              <div
                style={{
                  backgroundColor: COLORS.white,
                  padding: "10px 20px 20px",
                  borderRadius: 7,
                }}
                className='light-shadow'
              >
                <Title level={5} className='my-font'>
                  {intl.formatMessage({ id: "cartNote" })}
                </Title>
                {/* </Divider> */}
                <Row
                  className='mt-3'
                  gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
                >
                  {/* Cart messgate AR */}
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "noteTitle" })}
                      className='mb-1'
                      name='noteTitle'
                      style={{ borderRadius: 20 }}
                    >
                      <Input maxLength={100} />
                    </Form.Item>
                  </Col>
                  {/* time to block users */}
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "noteTitle_en" })}
                      className='mb-1'
                      name='noteTitle_en'
                      style={{ borderRadius: 20 }}
                    >
                      <Input maxLength={100} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  className='mt-3'
                  gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
                >
                  {/* Cart messgate AR */}
                  {/* <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      className='mb-1'
                      label={intl.formatMessage({ id: "colorSchema" })}
                      name='noteColorSchema'
                      style={{ borderRadius: 20 }}
                    >
                      <Select className='my-font'>
                        <Option value='primary' className='my-font'>
                          {intl.formatMessage({ id: "primaryColor" })}
                        </Option>
                        <Option value={"danger"} className='my-font'>
                          {intl.formatMessage({ id: "dangerColor" })}
                        </Option>
                        <Option value={"success"} className='my-font'>
                          {intl.formatMessage({ id: "successColor" })}
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col> */}

                  {/* time to block users */}
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      className='mb-1'
                      label={intl.formatMessage({ id: "requiredNote" })}
                      name='noteRequired'
                      style={{ borderRadius: 20 }}
                    >
                      <Select className='my-font'>
                        <Option value='Required' className='my-font'>
                          {intl.formatMessage({ id: "Required" })}
                        </Option>
                        <Option value={"Optional"} className='my-font'>
                          {intl.formatMessage({ id: "Optional" })}
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <Divider />
              <div
                style={{
                  backgroundColor: COLORS.white,
                  padding: "10px 20px 20px",
                  borderRadius: 7,
                }}
                className='light-shadow'
              >
                {/* <Divider orientation={rtl ? "right" : "left"}> */}
                <Title level={5} className='my-font'>
                  {intl.formatMessage({ id: "terms_order" })}
                </Title>
                {/* </Divider> */}
                <OrderTerms
                  intl={intl}
                  form={form}
                  setShowTerms={setShowTerms}
                  showTerms={showTerms}
                />
              </div>
              <Divider />
              <div
                style={{
                  backgroundColor: COLORS.white,
                  padding: "10px 20px 20px",
                  borderRadius: 7,
                }}
                className='light-shadow'
              >
                {/* <Divider orientation={rtl ? "right" : "left"}> */}
                <Title level={5} className='my-font'>
                  {intl.formatMessage({ id: "photo" })}
                </Title>
                {/* </Divider> */}
                <BranchImage form={form} branch={branch} />
              </div>
              <Divider />
              <div
                style={{
                  backgroundColor: COLORS.white,
                  padding: "10px 20px 20px",
                  borderRadius: 7,
                }}
                className='light-shadow'
              >
                <Row
                  className='mt-3'
                  gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
                >
                  <Col md={12} sm={24} xs={24}>
                    <Title level={5} className='my-font'>
                      {intl.formatMessage({ id: "branchStatus" })}
                    </Title>
                  </Col>
                </Row>

                <Row
                  className='mt-5'
                  gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
                >
                  <Col md={6} sm={12} xs={12}>
                    <Form.Item
                      label={intl.formatMessage({ id: "branchStatus" })}
                      className='mb-1'
                      name='status'
                      style={{ borderRadius: 20 }}
                      valuePropName='checked'
                    >
                      <Switch
                        checkedChildren={
                          <p>
                            {intl.formatMessage({
                              id: "active",
                            })}
                          </p>
                        }
                        unCheckedChildren={
                          <p>
                            {intl.formatMessage({
                              id: "disabled",
                            })}
                          </p>
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col md={6} sm={12} xs={12}>
                    <Form.Item
                      label={intl.formatMessage({ id: "deliveryStatus" })}
                      className='mb-1'
                      name='DoesDeliveryActive'
                      style={{ borderRadius: 20 }}
                      valuePropName='checked'
                      tooltip={
                        (!branch?.lat && !location.lat) ||
                        (!branch?.lng && !location.lng) ? (
                          <Text>
                            {intl.formatMessage({ id: "setLocation" })}
                          </Text>
                        ) : null
                      }
                    >
                      <Switch
                        disabled={
                          (!branch?.lat && !location.lat) ||
                          (!branch?.lng && !location.lng) ||
                          !services?.includes("PICKUP")
                        }
                        checkedChildren={
                          <p>
                            {intl.formatMessage({
                              id: "active",
                            })}
                          </p>
                        }
                        unCheckedChildren={
                          <p>
                            {intl.formatMessage({
                              id: "disabled",
                            })}
                          </p>
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col md={6} sm={12} xs={12}>
                    <Form.Item
                      label={intl.formatMessage({ id: "showRestaurantMenu" })}
                      className='mb-1'
                      name='showMenu'
                      style={{ borderRadius: 20 }}
                      valuePropName='checked'
                      tooltip={
                        <Text className='my-font'>
                          {intl.formatMessage({
                            id: "showRestaurantMenuExplain",
                          })}
                        </Text>
                      }
                    >
                      <Switch
                        checkedChildren={
                          <p>
                            {intl.formatMessage({
                              id: "active",
                            })}
                          </p>
                        }
                        unCheckedChildren={
                          <p>
                            {intl.formatMessage({
                              id: "disabled",
                            })}
                          </p>
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col md={6} sm={12} xs={12}>
                    <Form.Item
                      label={intl.formatMessage({ id: "pickupStatus" })}
                      className='mb-1'
                      name='DoesPickupActive'
                      style={{ borderRadius: 20 }}
                      valuePropName='checked'
                    >
                      <Switch
                        disabled={!services?.includes("PICKUP")}
                        checkedChildren={
                          <p>
                            {intl.formatMessage({
                              id: "active",
                            })}
                          </p>
                        }
                        unCheckedChildren={
                          <p>
                            {intl.formatMessage({
                              id: "disabled",
                            })}
                          </p>
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col md={6} sm={12} xs={12}>
                    <Form.Item
                      label={intl.formatMessage({ id: "pickupCarStatus" })}
                      className='mb-1'
                      name='DoesPickupCarActive'
                      style={{ borderRadius: 20 }}
                      valuePropName='checked'
                    >
                      <Switch
                        disabled={!services?.includes("PICKUP")}
                        checkedChildren={
                          <p>
                            {intl.formatMessage({
                              id: "active",
                            })}
                          </p>
                        }
                        unCheckedChildren={
                          <p>
                            {intl.formatMessage({
                              id: "disabled",
                            })}
                          </p>
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col md={6} sm={12} xs={12}>
                    <Form.Item
                      label={intl.formatMessage({ id: "pickupSchedule" })}
                      className='mb-1'
                      name='DoesPickupScheduleActive'
                      style={{ borderRadius: 20 }}
                      valuePropName='checked'
                    >
                      <Switch
                        disabled={!services?.includes("PICKUP")}
                        checkedChildren={
                          <p>
                            {intl.formatMessage({
                              id: "active",
                            })}
                          </p>
                        }
                        unCheckedChildren={
                          <p>
                            {intl.formatMessage({
                              id: "disabled",
                            })}
                          </p>
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col md={6} sm={12} xs={12}>
                    <Form.Item
                      label={intl.formatMessage({ id: "autoAcceptPickup" })}
                      className='mb-1'
                      name='autoAcceptPickup'
                      style={{ borderRadius: 20 }}
                      valuePropName='checked'
                      tooltip={
                        <Text className='my-font'>
                          {intl.formatMessage({
                            id: "autoAcceptPickupExplain",
                          })}
                        </Text>
                      }
                    >
                      <Switch
                        disabled={!services?.includes("PICKUP")}
                        checkedChildren={
                          <p>
                            {intl.formatMessage({
                              id: "active",
                            })}
                          </p>
                        }
                        unCheckedChildren={
                          <p>
                            {intl.formatMessage({
                              id: "disabled",
                            })}
                          </p>
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col md={6} sm={12} xs={12}>
                    <Form.Item
                      label={intl.formatMessage({ id: "autoAcceptDelivery" })}
                      className='mb-1'
                      name='autoAcceptDelivery'
                      style={{ borderRadius: 20 }}
                      valuePropName='checked'
                      tooltip={
                        <Text className='my-font'>
                          {intl.formatMessage({
                            id: "autoAcceptDeliveryExplain",
                          })}
                        </Text>
                      }
                    >
                      <Switch
                        disabled={!services?.includes("PICKUP")}
                        checkedChildren={
                          <p>
                            {intl.formatMessage({
                              id: "active",
                            })}
                          </p>
                        }
                        unCheckedChildren={
                          <p>
                            {intl.formatMessage({
                              id: "disabled",
                            })}
                          </p>
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col md={6} sm={12} xs={12}>
                    <Form.Item
                      label={intl.formatMessage({ id: "customOrders" })}
                      className='mb-1'
                      name='DoesCustomOrderActive'
                      style={{ borderRadius: 20 }}
                      valuePropName='checked'
                      tooltip={
                        <Text className='my-font'>
                          {intl.formatMessage({
                            id: "customOrdersExplain",
                          })}
                        </Text>
                      }
                    >
                      <Switch
                        disabled={!services?.includes("PICKUP")}
                        checkedChildren={
                          <p>
                            {intl.formatMessage({
                              id: "active",
                            })}
                          </p>
                        }
                        unCheckedChildren={
                          <p>
                            {intl.formatMessage({
                              id: "disabled",
                            })}
                          </p>
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>

                {/* Add Terms and conditions for order */}

                <Divider orientation={rtl ? "right" : "left"}>
                  <Text className='text-2xl font-bold'>
                    {intl.formatMessage({ id: "app" })}
                  </Text>
                </Divider>
                <Row
                  className='mt-5'
                  gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
                >
                  <Col md={8} sm={12} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "appPickupStatus" })}
                      className='mb-1'
                      name='appPickupStatus'
                      style={{ borderRadius: 20 }}
                      valuePropName='checked'
                    >
                      <Switch
                        disabled={!services?.includes("PICKUP")}
                        checkedChildren={
                          <p>
                            {intl.formatMessage({
                              id: "active",
                            })}
                          </p>
                        }
                        unCheckedChildren={
                          <p>
                            {intl.formatMessage({
                              id: "disabled",
                            })}
                          </p>
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col md={8} sm={12} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "appDeliveryStatus" })}
                      className='mb-1'
                      name='appDeliveryStatus'
                      style={{ borderRadius: 20 }}
                      valuePropName='checked'
                    >
                      <Switch
                        disabled={!services?.includes("PICKUP")}
                        checkedChildren={
                          <p>
                            {intl.formatMessage({
                              id: "active",
                            })}
                          </p>
                        }
                        unCheckedChildren={
                          <p>
                            {intl.formatMessage({
                              id: "disabled",
                            })}
                          </p>
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col md={8} sm={12} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "appWaitingStatus" })}
                      className='mb-1'
                      // appReservationStatus
                      name='appWaitingStatus'
                      style={{ borderRadius: 20 }}
                      valuePropName='checked'
                    >
                      <Switch
                        disabled={!services?.includes("WAITING")}
                        checkedChildren={
                          <p>
                            {intl.formatMessage({
                              id: "active",
                            })}
                          </p>
                        }
                        unCheckedChildren={
                          <p>
                            {intl.formatMessage({
                              id: "disabled",
                            })}
                          </p>
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col md={8} sm={12} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "appReservationStatus" })}
                      className='mb-1'
                      name='appReservationStatus'
                      style={{ borderRadius: 20 }}
                      valuePropName='checked'
                    >
                      <Switch
                        disabled={!services?.includes("RESERVATION")}
                        checkedChildren={
                          <p>
                            {intl.formatMessage({
                              id: "active",
                            })}
                          </p>
                        }
                        unCheckedChildren={
                          <p>
                            {intl.formatMessage({
                              id: "disabled",
                            })}
                          </p>
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col md={8} sm={12} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "autoAcceptAppOrders" })}
                      className='mb-1'
                      name='autoAcceptAppOrders'
                      style={{ borderRadius: 20 }}
                      valuePropName='checked'
                      tooltip={
                        <Text className='my-font'>
                          {intl.formatMessage({
                            id: "autoAcceptAppOrdersExplain",
                          })}
                        </Text>
                      }
                    >
                      <Switch
                        disabled={!services?.includes("PICKUP")}
                        checkedChildren={
                          <p>
                            {intl.formatMessage({
                              id: "active",
                            })}
                          </p>
                        }
                        unCheckedChildren={
                          <p>
                            {intl.formatMessage({
                              id: "disabled",
                            })}
                          </p>
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <Divider />
              <div
                style={{
                  backgroundColor: COLORS.white,
                  padding: "10px 20px 20px",
                  borderRadius: 7,
                }}
                className='light-shadow'
              >
                <Row
                  className='mb-0'
                  gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
                >
                  <Col md={24} sm={24} xs={24}>
                    <Title level={5} className='my-font'>
                      {intl.formatMessage({ id: "delivery" })}
                    </Title>
                    {integrations?.deliveryCompanies?.some(
                      (c) =>
                        c?.name === "iwaiter Delivery" &&
                        c?.linkedBranches?.findIndex(
                          (item) => item?.localId === branch?._id
                        ) !== -1
                    ) ? (
                      <Text className='my-font'>
                        {intl.formatMessage({ id: "deliveryOnIntegrationTab" })}
                      </Text>
                    ) : null}
                  </Col>
                </Row>
                <Row
                  className='mb-0'
                  gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
                >
                  <Col md={24} sm={24} xs={24}>
                    <CustomButton
                      text={intl.formatMessage({
                        id: showDeliveryPrices ? "hideDetails" : "showDetails",
                      })}
                      type={showDeliveryPrices ? "secondary" : "primary"}
                      className='border-8'
                      onClick={() => setShowDeliveryPrices(!showDeliveryPrices)}
                      disabled={
                        !services?.includes("PICKUP") ||
                        integrations?.deliveryCompanies?.some(
                          (c) =>
                            c?.name === "iwaiter Delivery" &&
                            c?.linkedBranches?.findIndex(
                              (item) => item?.localId === branch?._id
                            ) !== -1
                        )
                      }
                    />
                  </Col>
                </Row>
                <Row
                  className='mb-0 mt-5'
                  gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
                  style={{
                    position: showDeliveryPrices ? "relative" : "absolute",
                    zIndex: showDeliveryPrices ? 0 : -999,
                    top: showDeliveryPrices ? 0 : -2000,
                  }}
                >
                  <Col md={24} sm={24} xs={24}>
                    <Form.List name={"deliveryPrices"}>
                      {(fields) => {
                        return (
                          <>
                            {fields.map(
                              ({ key, name, fieldKey, ...restField }) => {
                                return (
                                  <Space
                                    key={key}
                                    direction='horizontal'
                                    style={{
                                      display: "flex",
                                      marginBottom: 8,
                                      marginTop: 8,
                                    }}
                                    align='middle'
                                  >
                                    <Form.Item
                                      {...restField}
                                      name={[name, "from"]}
                                      fieldKey={[fieldKey, "from"]}
                                      label={intl.formatMessage({ id: "from" })}
                                      style={{
                                        flexDirection: "row",
                                        alignItems: "center",
                                      }}
                                    >
                                      <InputNumber
                                        placeholder={intl.formatMessage({
                                          id: "from",
                                        })}
                                        parser={(value) =>
                                          value.replace(" KM", "")
                                        }
                                        formatter={(value) => `${value} KM`}
                                        className={"mx-3"}
                                        disabled={
                                          form.getFieldValue("deliveryPrices")[
                                            key
                                          ].from >=
                                          form.getFieldValue(
                                            "maximumDeliveryDistance"
                                          )
                                        }
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "to"]}
                                      fieldKey={[fieldKey, "to"]}
                                      label={intl.formatMessage({ id: "to" })}
                                      style={{
                                        flexDirection: "row",
                                        alignItems: "center",
                                      }}
                                    >
                                      <InputNumber
                                        placeholder={intl.formatMessage({
                                          id: "from",
                                        })}
                                        className={"mx-3"}
                                        parser={(value) =>
                                          value.replace(" KM", "")
                                        }
                                        formatter={(value) => `${value} KM`}
                                        disabled={
                                          form.getFieldValue("deliveryPrices")[
                                            key
                                          ].from >=
                                          form.getFieldValue(
                                            "maximumDeliveryDistance"
                                          )
                                        }
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "price"]}
                                      fieldKey={[fieldKey, "price"]}
                                      label={intl.formatMessage({
                                        id: "price",
                                      })}
                                      style={{
                                        flexDirection: "row",
                                        alignItems: "center",
                                      }}
                                    >
                                      <InputNumber
                                        placeholder={intl.formatMessage({
                                          id: "from",
                                        })}
                                        className={"mx-3"}
                                        disabled={
                                          form.getFieldValue("deliveryPrices")[
                                            key
                                          ].from >=
                                          form.getFieldValue(
                                            "maximumDeliveryDistance"
                                          )
                                        }
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      {...restField}
                                      tooltip={
                                        <Text className='my-font'>
                                          {intl.formatMessage({
                                            id: "distanceDes",
                                          })}
                                        </Text>
                                      }
                                      style={{
                                        flexDirection: "row",
                                        alignItems: "center",
                                      }}
                                    >
                                      <CustomButton
                                        text={intl.formatMessage({
                                          id: "makeMaximum",
                                        })}
                                        type='primary'
                                        onClick={() => {
                                          setMaximumKMToDeliver(
                                            form.getFieldValue(
                                              "deliveryPrices"
                                            )[key].to
                                          );
                                          form.setFieldsValue({
                                            maximumDeliveryDistance:
                                              form.getFieldValue(
                                                "deliveryPrices"
                                              )[key].to,
                                          });
                                        }}
                                      />
                                    </Form.Item>
                                  </Space>
                                );
                              }
                            )}
                          </>
                        );
                      }}
                    </Form.List>
                  </Col>
                </Row>
              </div>
              <Divider />

              <Row justify='start' className='mt-5' align='middle'>
                <Form.Item>
                  <CustomButton
                    htmlType='submit'
                    text={intl.formatMessage({ id: "save" })}
                    className={`btnRegister btn-text border-8 px-5 `}
                    loading={loading}
                    disabled={loading}
                    type='primary'
                  />
                </Form.Item>
                <Form.Item>
                  <CustomButton
                    type='secondary'
                    text={intl.formatMessage({ id: "back" })}
                    className={` border-8 mx-5 px-5`}
                    loading={loading}
                    disabled={loading}
                  />
                </Form.Item>
              </Row>
            </div>
          )}
        </Form>
      </Spin>
    </div>
  );
};

export default OneBranchTab;
