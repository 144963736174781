import React, { useRef, useState } from "react";
import { Col, Row, Image, Form, Input, Typography, Select } from "antd";
import { useIntl } from "react-intl";
import LOGO from "../../../assets/images/main-logo.png";
import PlaceHolder from "../../../assets/images/log-in.png";
import ICON from "../../../assets/images/icon-white.png";
import CustomButton from "../../../Components/CustomButton";
import phoneCodes from "../PhoneCods.json";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguageAction } from "../../../redux/reducers/settingsReducer";
import { checkIfValidPhone } from "../../../helpers/index";
import { postSendVerificationCode } from "../../../API/fetch";
import openNotification from "../../../Components/Notifications/index";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

const { Text, Link } = Typography;
const { Option } = Select;

const SignUp = ({ showSignIn, formValues, setFormValues, showVerify }) => {
  const intl = useIntl();
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");
  const onFinish = async (val) => {
    setLoading(true);
    const phone = `${val.prefix.substring(1)}${val.phone}`;
    try {
      const res = await postSendVerificationCode({
        phone,
        name: val?.name ? val.name : "",
        token,
      });
      if (res.data.status === "success") {
        setFormValues({ ...val, phone });
        setLoading(false);
        showVerify();
      } else {
        if (res.data.message === "User exist") {
          openNotification({
            title: intl.formatMessage({ id: "error" }),
            description: intl.formatMessage({ id: "userExist" }),
            type: "error",
            rtl,
          });
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const prefixSelector = (
    <Form.Item name='prefix' noStyle>
      <Select
        style={{
          width: 150,
        }}
      >
        {phoneCodes.map((info) => {
          return (
            <Option
              key={`${info.dial_code}-${info.name}`}
              value={info.dial_code}
            >
              {info.dial_code} {info.name}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );

  return (
    <Row style={{ minHeight: "100vh" }}>
      <Col
        xs={24}
        sm={12}
        type='flex'
        justify='center'
        align='center'
        direction='column'
        className={"authRightSide"}
      >
        <div className='changeLanguage'>
          <CustomButton
            text={intl.formatMessage({ id: "oppLanguage" })}
            className={`btn-text border-8 px-5 pb-5`}
            loading={false}
            type='primary'
            onClick={() => {
              dispatch(changeLanguageAction());
            }}
          />
        </div>
        <Row type='flex' justify='center' align='middle'>
          <Col span={18}>
            <Row justify='center'>
              <Image
                preview={false}
                width={300}
                src={LOGO}
                className='logo-Login'
              />
            </Row>

            <Form
              name='basic'
              form={form}
              className='form-container'
              layout='vertical'
              onFinish={onFinish}
              initialValues={{
                prefix: phoneCodes[0].dial_code,
              }}
            >
              <Row justify='space-around' align='middle'>
                <Col span={23}>
                  <Form.Item
                    label={intl.formatMessage({ id: "name" })}
                    className='mb-1'
                    name='name'
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "errorname" }),
                      },
                    ]}
                  >
                    <Input className={"input"} />
                  </Form.Item>
                </Col>

                <Col span={23}>
                  <Form.Item
                    name='phone'
                    label={intl.formatMessage({ id: "phone" })}
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "errorphone" }),
                      },
                      {
                        validator: (_, value) => {
                          const prefix = form.getFieldValue("prefix");
                          if (!prefix) {
                            return Promise.reject(
                              intl.formatMessage({ id: "errorphone" })
                            );
                          }
                          let valid = checkIfValidPhone(`${prefix}${value}`);
                          if (valid) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(
                              intl.formatMessage({ id: "errorphone" })
                            );
                          }
                        },
                      },
                    ]}
                  >
                    <Input
                      addonBefore={rtl ? null : prefixSelector}
                      addonAfter={rtl ? prefixSelector : null}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={23}>
                  <Form.Item
                    label={intl.formatMessage({ id: "password" })}
                    className='mb-1'
                    name='password'
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "errorpassword" }),
                      },
                    ]}
                  >
                    <Input.Password className={"input"} />
                  </Form.Item>
                </Col>
                <Col span={23}>
                  <Form.Item
                    label={intl.formatMessage({ id: "CodeDes" })}
                    className='mb-1'
                    name='code'
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "errorCodeDes" }),
                      },
                    ]}
                  >
                    <Input className={"input"} />
                  </Form.Item>
                </Col>
                <Col span={23}>
                  <Form.Item
                    label={intl.formatMessage({ id: "referralCode" })}
                    className='mb-1'
                    name='referralCode'
                  >
                    <Input className={"input"} />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify='center' align='middle'>
                <Col span={24}>
                  <Form.Item>
                    <CustomButton
                      htmlType='submit'
                      text={intl.formatMessage({ id: "signup" })}
                      className={`btnRegister btn-text border-8`}
                      loading={loading}
                      type='primary'
                    />
                  </Form.Item>
                </Col>
              </Row>
              <GoogleReCaptcha
                onVerify={(token) => {
                  setToken(token);
                }}
              />
              <Text type='secondary'>
                <Link className='pointer' onClick={showSignIn}>
                  {intl.formatMessage({ id: "signin" })}
                </Link>
              </Text>
            </Form>
          </Col>
        </Row>
      </Col>
      <Col xs={0} sm={12} type='flex' className={"authLeftSide"}>
        <Image
          preview={false}
          src={ICON}
          style={{ maxWidth: 150, marginBottom: 50, borderRadius: 30 }}
        />
        <Image preview={false} src={PlaceHolder} width={"70%"} />
      </Col>
    </Row>
  );
};

export default SignUp;
