export const BANKS = [
  {
    value: 51,
    text_en: "Saudi Arabia - Al Rajhi Bank(ARB)",
    text: "السعودية - مصرف الراجحي",
  },
  {
    value: 75,
    text_en: "Saudi Arabia - Alinma Bank",
    text: "السعودية - بنك الانماء",
  },
  {
    value: 73,
    text_en: "Saudi Arabia - Al Ahli Bank (NCB)",
    text: "السعودية - بنك الأهلي الوطني",
  },
  {
    value: 48,
    text_en: "Saudi Arabia - Riyad Bank(RB)",
    text: "السعودية - بنك الرياض",
  },
  {
    value: 52,
    text_en: "Saudi Arabia - Arab National Bank(ANB)",
    text: "السعودية - البنك العربي الوطني",
  },
  {
    value: 54,
    text_en: "Saudi Arabia - Bank AlJazira(BAJ)",
    text: "السعودية - بنك الجزيرة",
  },

  {
    value: 74,
    text_en: "Saudi Arabia - Albilad Bank",
    text: "السعودية - بنك البلاد",
  },
  {
    value: 14,
    text_en: "Saudi Arabia - National Bank of Kuwait ",
    text: "السعودية - بنك الكويت الوطني",
  },
  {
    value: 43,
    text_en: "Saudi Arabia - The National Commercial Bank(TNCB)",
    text: "السعودية - البنك التجاري الوطني",
  },
  {
    value: 44,
    text_en: "Saudi Arabia - The Saudi British Bank(TSBB)",
    text: "السعودية - البنك السعودي البريطاني",
  },
  {
    value: 45,
    text_en: "Saudi Arabia - Saudi Investment Bank(SIB)",
    text: "السعودية - بنك الاستثمار السعودي",
  },
  {
    value: 47,
    text_en: "Saudi Arabia - Banque Saudi Fransi(BSF)",
    text: "السعودية - البنك السعودي الفرنسي",
  },
  
  {
    value: 49,
    text_en: "Saudi Arabia - Samba Financial Group(SFG)",
    text: "السعودية - بنك سامبا",
  },
  {
    value: 50,
    text_en: "Saudi Arabia - Saudi Hollandi Bank(SHB)",
    text: "السعودية - البنك الهولندي السعودي",
  },

  

  {
    value: 78,
    text_en: "Saudi Arabia - Alawwal Bank",
    text: "السعودية - البنك الأول",
  },
  {
    value: 79,
    text_en: "Saudi Arabia - EMIRATES NBD",
    text: "السعودية - بنك الإمارات الوطني",
  },
  {
    value: 80,
    text_en: "Saudi Arabia - Gulf international bank",
    text: "السعودية - بنك الخليج الدولي",
  },
  {
    value: 81,
    text_en: "Saudi Arabia - MEEM Bank",
    text: "السعودية - بنك ميم",
  },
  {
    value: 99,
    text_en: "Other bank",
    text: "بنك آخر",
  },
];
