import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { getAddressText } from "../../../API/fetch";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Modal } from "antd";
import DRIVER from "../../../assets/images/driver.png";

const containerStyle = {
  width: "90%",
  height: "40vh",
};

function MapModalTrack({
  restaurant,
  driver,
  customer,
  logo,
  show,
  hide,
  isTracking,
  locationInfo,
}) {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);
  const [isReady, setIsReady] = useState(false);
  const [map, setMap] = useState(null);
  const [location, setLocation] = useState({
    center: {
      lat: 24.761654,
      lng: 46.657367,
    },
    zoom: 15,
  });
  useEffect(() => {
    if (customer?.lat) {
      setLocation({
        center: {
          lat: customer?.lat,
          lng: customer?.lng,
        },
        zoom: 15,
      });
    }else if(locationInfo?.lat){
      setLocation({
        center: {
          lat: locationInfo?.lat,
          lng: locationInfo?.lng,
        },
        zoom: 15,
      });
    }
  }, [customer, locationInfo]);

  return (
    <Modal
      title={intl.formatMessage({ id: "track" })}
      centered
      visible={show}
      destroyOnClose={true}
      mask={true}
      maskClosable={true}
      onCancel={() => hide()}
      disabled={true}
      okText={intl.formatMessage({ id: "back" })}
      cancelText={intl.formatMessage({ id: "cancel" })}
      onOk={() => {
        hide();
      }}
    >
      <LoadScript
        googleMapsApiKey='AIzaSyC5XYdsll36U6sANfeX22b-d8L8bgbXLC8'
        language={rtl ? "ar" : "en"}
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          clickableIcons={false}
          onLoad={() => setIsReady(true)}
          onUnmount={onUnmount}
          center={location.center}
          zoom={location.zoom}
          onClick={(e, b) => {
            const lat = e.latLng.lat();
            const lng = e.latLng.lng();
            console.log(lat, lng);
          }}
        >
          {restaurant?.lat ? (
            <Marker
              key={"restaurant"}
              icon={{
                url: logo,
                size: { width: 35, height: 35 },
                scaledSize: { width: 35, height: 35 },
                // make the image circle
              }}
              position={{ lat: restaurant.lat, lng: restaurant.lng }}
            ></Marker>
          ) : null}
          {customer?.lat ? (
            <Marker
              key={"customer"}
              position={{ lat: customer.lat, lng: customer.lng }}
            ></Marker>
          ) : locationInfo?.lat ? (
            <Marker
              key={"customer"}
              position={{ lat: locationInfo.lat, lng: locationInfo.lng }}
            ></Marker>
          ) : null}
          {driver?.lat ? (
            <Marker
              icon={{
                url: DRIVER,
                size: { width: 35, height: 35 },
                scaledSize: { width: 35, height: 35 },
                borderRadius: 10,
                overflow: "hidden",

                // make the image circle
              }}
              key='driver'
              position={{ lat: driver.lat, lng: driver.lng }}
            ></Marker>
          ) : null}
        </GoogleMap>
      </LoadScript>
    </Modal>
  );
}

export default MapModalTrack;
