import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Popconfirm,
  Typography,
  Divider,
  Space,
  Checkbox,
  Row,
  Col,
} from "antd";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import COLORS from "../../../Style/colors";
import CustomButton from "../../../Components/CustomButton";

import openNotification from "../../../Components/Notifications";
import {
  postAddWaiter,
  postDeleteWaiter,
  postUpdateWaiter,
} from "../../../API/fetch";

const { Option } = Select;
const { Text, Title } = Typography;

const WaiterModal = ({ show, hide, waiter, codes, setWaiters, waiters }) => {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  const { allBranches } = useSelector((state) => state.branch);
  const [isNew, setIsNew] = useState(true);
  const [loading, setLoading] = useState(false);
  const [branches, setBranches] = useState([]);
  const [zones, setZones] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    if (allBranches.length) {
      setBranches(allBranches.map((b) => b.branch));
    }
  }, [allBranches]);

  useEffect(() => {
    if (waiter?._id) {
      setIsNew(false);
      const tables = allBranches?.find(
        (b) => b?.branch?._id === waiter?.branchId?._id
      )?.tables;
      const zones = [];
      tables?.forEach((t) => {
        if (!zones.includes(t.zone) && t?.zone) {
          zones.push(t.zone);
        }
      });
      setZones(zones);

      setTimeout(() => {
        form.resetFields();
      }, 100);
    } else {
      setIsNew(true);
      setTimeout(() => {
        form.resetFields();
      }, 100);
    }
  }, [waiter]);

  const addWaiter = async (values) => {
    try {
      setLoading(true);
      const data = {
        waiter: { ...values },
      };
      const res = await postAddWaiter(data);
      setWaiters((waiters) => [...waiters, res.data.waiter]);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setLoading(false);
      hide();
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const editWaiter = async (values) => {
    try {
      setLoading(true);
      const data = {
        waiter: { ...values },
      };
      const res = await postUpdateWaiter(waiter._id, data);
      const copyWaiters = waiters.map((a) => ({ ...a }));
      const i = copyWaiters.findIndex((w) => w._id === waiter._id);
      copyWaiters.splice(i, 1, res.data.waiter);
      setWaiters(copyWaiters);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setLoading(false);
      hide();
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const deleteWaiter = async () => {
    try {
      setLoading(true);
      const res = await postDeleteWaiter(waiter?._id);
      if (res.data.status === "success") {
        setWaiters((waiters) => waiters.filter((w) => w._id !== waiter?._id));
      }
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setLoading(false);
      hide();
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  return (
    <Modal
      title={<p className='my-font'>{intl.formatMessage({ id: "waiter" })}</p>}
      centered
      visible={show}
      destroyOnClose={true}
      mask={true}
      getContainer={false}
      maskClosable={true}
      onCancel={() => {
        hide();
      }}
      footer={null}
    >
      <Form
        name='Add-Waiter'
        form={form}
        preserve={false}
        className='form-container'
        layout='vertical'
        onFinish={isNew ? addWaiter : editWaiter}
        initialValues={
          isNew
            ? {
                name: "",
                code: "",
                branchId: "",
                receivePickup: false,
                receiveDelivery: false,
                receiveReservation: false,
                isDriver: false,
                isCashier: false,
                receiveDineIn: true,
                receiveWaiting: true,
                canEditFoods: true,
                canEditTables: true,
                dineInSections: [],
              }
            : {
                name: waiter?.name,
                code: waiter?.code,
                branchId: waiter?.branchId?._id,
                receivePickup: waiter?.receivePickup,
                receiveDelivery: waiter?.receiveDelivery,
                isDriver: waiter?.isDriver,
                isCashier: waiter?.isCashier,
                receiveWaiting: waiter?.receiveWaiting,
                receiveDineIn: waiter?.receiveDineIn,
                dineInSections: waiter?.dineInSections,
                canEditTables: waiter?.canEditTables,
                canEditFoods: waiter?.canEditFoods,
                receiveReservation: waiter?.receiveReservation,
              }
        }
      >
        {() => (
          <div>
            <Form.Item
              label={intl.formatMessage({ id: "justName" })}
              className='mb-1'
              name='name'
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: "errorname" }),
                },
              ]}
            >
              <Input className={"input"} />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({ id: "code" })}
              className='mb-1'
              name='code'
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: "sameCode" }),
                },
                {
                  validator: (rule, value) => {
                    let originalCode = "";
                    if (!isNew) {
                      originalCode = waiter?.code;
                    }
                    if (
                      !value ||
                      (codes?.includes(`${value}`) &&
                        `${value}` !== originalCode)
                    ) {
                      return Promise.reject();
                    } else {
                      return Promise.resolve();
                    }
                  },
                  message: intl.formatMessage({ id: "sameCode" }),
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} className={"input"} />
            </Form.Item>
            <Divider />
            
            <Form.Item
              className='mb-1'
              label={intl.formatMessage({ id: "branch" })}
              name='branchId'
              style={{ borderRadius: 20 }}
            >
              <Select
                className='my-font'
                onChange={(value) => {
                  const tables = allBranches?.find(
                    (b) => b?.branch?._id === value
                  )?.tables;
                  const zones = [];
                  tables?.forEach((t) => {
                    if (!zones.includes(t.zone)) {
                      zones.push(t.zone);
                    }
                  });
                  setZones(zones);
                  form.setFieldsValue({
                    receiveDineIn: false,
                  });
                  form.setFieldsValue({
                    dineInSections: [],
                  });
                }}
              >
                {branches?.map((branch) => {
                  return (
                    <Option
                      className='my-font'
                      key={branch._id}
                      value={branch._id}
                    >
                      {rtl ? branch?.name : branch?.name_en}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Divider />
            <Row>
            <Title className={"my-font"} level={4}>
              {intl.formatMessage({ id: "permissions" })}
            </Title>
            </Row>
            <Row>
              <Col>
                <Form.Item
                  className='mb-1'
                  name='receivePickup'
                  valuePropName='checked'
                  style={{ borderRadius: 20 }}
                >
                  <Checkbox>
                    {intl.formatMessage({ id: "receivePickup" })}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  className='mb-1'
                  name='receiveDelivery'
                  valuePropName='checked'
                  style={{ borderRadius: 20 }}
                >
                  <Checkbox>
                    {intl.formatMessage({ id: "receiveDelivery" })}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  className='mb-1'
                  name='receiveWaiting'
                  valuePropName='checked'
                  style={{ borderRadius: 20 }}
                >
                  <Checkbox>
                    {intl.formatMessage({ id: "waitingList" })}
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item
                  className='mb-1'
                  name='receiveReservation'
                  valuePropName='checked'
                  style={{ borderRadius: 20 }}
                >
                  <Checkbox>
                    {intl.formatMessage({ id: "receiveReservation" })}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  className='mb-1'
                  name='receiveDineIn'
                  valuePropName='checked'
                  style={{ borderRadius: 20 }}
                >
                  <Checkbox
                    onChange={(e) => {
                      if (e.target.checked) {
                        form.setFieldsValue({
                          dineInSections: ["All"],
                        });
                      } else {
                        form.setFieldsValue({
                          dineInSections: [],
                        });
                      }
                    }}
                  >
                    {intl.formatMessage({ id: "receiveDinein" })}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  className='mb-1'
                  name='canEditFoods'
                  valuePropName='checked'
                  style={{ borderRadius: 20 }}
                >
                  <Checkbox>
                    {intl.formatMessage({ id: "canEditFoods" })}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  className='mb-1'
                  name='canEditTables'
                  valuePropName='checked'
                  style={{ borderRadius: 20 }}
                >
                  <Checkbox>
                    {intl.formatMessage({ id: "canEditTables" })}
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              className='mb-1'
              label={intl.formatMessage({ id: "pleaseSelectArea" })}
              name='dineInSections'
              style={{ borderRadius: 20 }}
            >
              <Select
                className='my-font'
                mode='multiple'
                onChange={(e) => {
                  if (e?.length === 0) {
                    form.setFieldsValue({
                      receiveDineIn: false,
                    });
                  } else {
                    form.setFieldsValue({
                      receiveDineIn: true,
                    });
                  }
                }}
              >
                <Option className='my-font' value='All'>
                  {intl.formatMessage({ id: "all" })}
                </Option>
                {zones?.length
                  ? zones?.map((z) => {
                      return (
                        <Option className='my-font' key={z} value={z}>
                          {z}
                        </Option>
                      );
                    })
                  : null}
              </Select>
            </Form.Item>
            <Divider />
            <Form.Item>
              <Popconfirm
                title={
                  <Text className='my-font'>
                    {intl.formatMessage({ id: "confirmDeleteChoiceTitle" })}
                  </Text>
                }
                okText={intl.formatMessage({ id: "delete" })}
                okButtonProps={{
                  style: { color: COLORS.danger, fontFamily: "Cairo" },
                }}
                okType='text'
                cancelText={intl.formatMessage({ id: "cancel" })}
                cancelButtonProps={{
                  style: { border: "none", fontFamily: "Cairo" },
                }}
                onConfirm={deleteWaiter}
                disabled={isNew}
              >
                <Text
                  type='danger'
                  disabled={isNew}
                  className='pointer my-font'
                >
                  {intl.formatMessage({ id: "delete" })}
                </Text>
              </Popconfirm>
            </Form.Item>
            <Space direction='horizontal'>
              <Form.Item>
                <CustomButton
                  htmlType='submit'
                  text={intl.formatMessage({ id: "save" })}
                  className={`btnRegister btn-text border-8`}
                  loading={loading}
                  disabled={loading}
                  type='primary'
                />
              </Form.Item>

              <Form.Item>
                <CustomButton
                  type='text'
                  text={intl.formatMessage({ id: "cancel" })}
                  disabled={loading}
                  onClick={() => {
                    hide();
                  }}
                />
              </Form.Item>
            </Space>
          </div>
        )}
      </Form>
    </Modal>
  );
};

export default WaiterModal;
