import React, { useState, useEffect } from "react";
import { Col, Row, Image, Form, Input, Typography, Select, Radio } from "antd";
import { useIntl } from "react-intl";
import ICON from "../../../assets/images/icon-white.png";
import CustomButton from "../../../Components/CustomButton";
import phoneCodes from "../../Auth/PhoneCods.json";
import { useDispatch, useSelector } from "react-redux";
import {
  lookForRestaurant,
  postAddNewRestaurant,
  signOutServer,
} from "../../../API/fetch";
import { useNavigate } from "react-router-dom";
import openNotification from "../../../Components/Notifications/index";
import { setRestaurantAction } from "../../../redux/reducers/restaurantReducer";
import {
  setBranchAction,
  setBranchesAction,
} from "../../../redux/reducers/branchReducer";
import { setTablesAction } from "../../../redux/reducers/tableReducer";
import { foodicsClientKey, foodicsURL } from "../../../API/config";
import { logOutAction } from "../../../redux/reducers/authReducer";
import COLORS from "../../../Style/colors";
import citiesKSA from "./cities.json";

const { Title } = Typography;
const { Option } = Select;

const RestaurantForm = ({
  showIWHelper,
  setResult,
  loading,
  setLoading,
  addManualRestaurant,
  setFormValues,
}) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const history = useNavigate();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  // sort the cities
  const [sortedCities, setSortedCities] = useState(citiesKSA);
  useEffect(() => {
    if (citiesKSA.length > 0) {
      let copy = [...citiesKSA]
      setSortedCities(copy.sort((a, b) => a.name_ar.localeCompare(b.name_ar)));
    }
  }, [citiesKSA]);

  // const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    try {
      let city = "Riyadh";
      if (values.selectCity && values.selectCity !== "other") {
        city = values.selectCity;
      }
      const tmp = {
        name: values.nameAr,
        nameEn: values.nameEn,
        city,
        email: values.email,
      };
      setLoading(true);
      setFormValues(tmp);
      if (!values.grabFoodics || values.grabFoodics === "NO") {
        const res = await lookForRestaurant({
          name: values.nameAr,
          nameEn: values.nameEn,
          city,
        });
        if (res.data.error) {
          addManualRestaurant(tmp.name, tmp.nameEn, tmp.city, tmp.email);
          // Mixpanel.track("can not found restaurant", {
          //   restaurantName: values.nameAr,
          // });
          return;
        }
        setResult(res.data.result);
        showIWHelper();
        //   Mixpanel.track("Found result", {
        //     resultCount: res.data.result.count,
        //     restaurantName: e.nameAr,
        //   });

        setLoading(false);
      } else {
        // link with Foodics directly;
        const data = {
          restaurant: {
            name: values.nameAr,
            name_en: values.nameEn,
            city,
            email: values.email,
          },
        };
        let trimName = `${values.nameEn}`.trim();
        const res = await postAddNewRestaurant(data);
        dispatch(setRestaurantAction(res.data.restaurant));
        dispatch(setBranchesAction(res.data.branches));
        dispatch(setBranchAction(res.data?.branches?.[0]));
        dispatch(setTablesAction(res.data.tables));
        localStorage.setItem("slug", trimName);
        localStorage.setItem("onBoardingWithFoodics", trimName);
        const client = foodicsClientKey;
        let url = `${foodicsURL}/authorize?client_id=${client}&state=${trimName}`;
        window.location.href = url;
      }
      // setLoading(false);
    } catch (error) {
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
      dispatch(logOutAction());
      history("/");
      console.log("Error on restaurant form", error);
    }
  };

  return (
    <Row
      style={{ minHeight: "100vh", paddingTop: 40 }}
      className='add-restaurant-container'
      justify='center'
      align='top'
    >
      <Col
        xs={24}
        sm={12}
        type='flex'
        justify='center'
        align='center'
        direction='column'
        className={"authRightSide"}
      >
        <Row justify='center'>
          <Image
            preview={false}
            src={ICON}
            style={{ maxWidth: 50, marginBottom: 50, borderRadius: 30 }}
          />
        </Row>
        <Title>{intl.formatMessage({ id: "addRestaurant" })}</Title>

        <Form
          name='basic'
          form={form}
          className='form-container'
          layout='vertical'
          onFinish={onFinish}
          initialValues={{
            prefix: phoneCodes[0].dial_code,
          }}
        >
          <Row justify='space-around' align='middle'>
            <Col span={23}>
              <Form.Item
                name='nameAr'
                label={intl.formatMessage({ id: "nameAr" })}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: "errorname" }),
                  },
                ]}
              >
                <Input
                  placeholder={intl.formatMessage({ id: "nameArPlaceholder" })}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={23}>
              <Form.Item
                name='nameEn'
                label={intl.formatMessage({ id: "nameEn" })}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: "errorname" }),
                  },
                ]}
              >
                <Input
                  placeholder={intl.formatMessage({ id: "nameEnPlaceholder" })}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={23}>
              <Form.Item
                name='email'
                label={intl.formatMessage({ id: "email" })}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: "erroremail" }),
                  },
                  {
                    type: "email",
                    message: intl.formatMessage({ id: "erroremail" }),
                  },
                ]}
              >
                <Input
                  placeholder={intl.formatMessage({ id: "emailPlaceholder" })}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={23}>
              <Form.Item
                name='selectCity'
                label={intl.formatMessage({ id: "selectCity" })}
              >
                {/* <Select className="my-font">
                  <Option value={"other"} className='my-font'>
                    {intl.formatMessage({ id: "other" })}
                  </Option>
                  {cities.map((ct) => {
                    return (
                      <Option className='my-font' key={ct.name_en} value={ct.name_en}>
                        {rtl ? ct.name : ct.name_en}
                      </Option>
                    );
                  })}
                </Select> */}
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    (option?.value ?? "").includes(input)
                  }
                  className='my-font'
                >
                  {sortedCities.map((info) => {
                    return (
                      <Option
                        key={info?.city_id}
                        value={`${info?.name_en} - ${info?.name_ar}`}
                        className='my-font'
                      >
                        {info.name_en} - {info.name_ar}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={23}>
              <Form.Item
                name='grabFoodics'
                label={intl.formatMessage({ id: "doYouHavePOS" })}
                style={{ display: "flex", alignItems: "flex-start" }}
              >
                <Radio.Group>
                  <Radio value='YES'>{intl.formatMessage({ id: "yes" })}</Radio>
                  <Radio value='NO'>{intl.formatMessage({ id: "no" })}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row justify='center' align='middle'>
            <Col span={24}>
              <Form.Item>
                <CustomButton
                  htmlType='submit'
                  text={intl.formatMessage({ id: "next" })}
                  className={`btnRegister btn-text border-8`}
                  loading={loading}
                  type='primary'
                />
              </Form.Item>
            </Col>
          </Row>
          <CustomButton
            style={{ color: COLORS.danger }}
            text={intl.formatMessage({ id: "signout" })}
            className={`btnRegister btn-text border-8`}
            loading={loading}
            type='text'
            onClick={async () => {
              try {
                await signOutServer();
                dispatch(logOutAction());
              } catch (error) {
                dispatch(logOutAction());
              }
            }}
          />
        </Form>
      </Col>
    </Row>
  );
};

export default RestaurantForm;
