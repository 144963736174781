import React, { useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Switch,
  Divider,
  Typography,
  Input,
  InputNumber,
  Checkbox,
  Space,
  Button,
  Spin,
  Popconfirm,
} from "antd";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../Components/CustomButton";
import COLORS from "../../../Style/colors";
import {
  postAddReservationModifier,
  postDeleteReservationModifier,
  postEditReservationModifier,
} from "../../../API/fetch";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import openNotification from "../../../Components/Notifications";
import {
  addReservationModifierAction,
  deleteReservationModifierAction,
  editReservationModifierAction,
} from "../../../redux/reducers/restaurantReducer";

const { Title, Text } = Typography;

const ModifierCard = ({ modifier }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const { rtl } = useSelector((state) => state.settings);
  const { restaurant } = useSelector((state) => state.restaurant);
  const [loading, setLoading] = useState(false);
  const [optionsLength, setOptionsLength] = useState(modifier?.options?.length);
  const [isNew, setIsNew] = useState(true);

  useEffect(() => {
    if (modifier && modifier?._id) {
      form.resetFields();
      setIsNew(false);
    }
  }, [modifier]);

  // edit reservation modifier;
  const editModifier = async (values) => {
    try {
      setLoading(false);
      setLoading(true);
      // get new options that does not have _id;
      const newOptions = values.options
        .filter((option) => {
          return !option._id;
        })
        ?.map((option) => {
          let price = 0;
          let quantity = 1;
          if (option.price) {
            price = option.price;
          }
          if (option.quantity) {
            quantity = option.quantity;
          }
          return {
            ...option,
            price,
            quantity,
          };
        });
      // get all options and edit the status;
      const modifiedOptions = modifier?.options?.map((oldOption) => {
        let status = "Active";
        let currentOption = { ...oldOption };
        const newOption = values.options.find(
          (option) => option?._id === oldOption?._id
        );
        if (newOption?._id) {
          currentOption = { ...newOption };
        }
        // if we don't find the option that means user deleted it;
        if (!newOption) {
          status = "deleted";
        } else if (currentOption?.isActive === false) {
          // if the value.isActive === false then we set the status to notActive;
          status = "notActive";
        }
        return {
          ...currentOption,
          status,
        };
      });

      // check multiSelection;
      let multiSelection = false;
      if (values.maxSelection && values.maxSelection !== "1") {
        multiSelection = true;
      }

      const data = {
        modifier: {
          ...values,
          multiSelection,
          options: [...modifiedOptions, ...newOptions],
        },
      };
      const res = await postEditReservationModifier(modifier._id, data);
      // update the modifier array;
      dispatch(editReservationModifierAction(res.data.modifier));

      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      form.resetFields();
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  // add new reservation modifier;
  const addNewModifier = async (values) => {
    try {
      setLoading(true);
      let multiSelection = false;
      if (values.maxSelection !== 1) {
        multiSelection = true;
      }
      const options = values.options.map((option, index) => {
        let price = 0;
        let quantity = 1;
        let status = "Active";
        if (option.price) {
          price = option.price;
        }
        if (option.isActive === false) {
          status = "notActive";
        }
        if (option.quantity) {
          quantity = option.quantity;
        }
        return {
          ...option,
          price,
          quantity,
          status,
        };
      });

      const data = {
        modifier: {
          ...values,
          multiSelection,
          options: options,
          restaurantId: restaurant?._id,
        },
      };

      const res = await postAddReservationModifier(data);
      // update the modifier array(redux);
      dispatch(addReservationModifierAction(res.data.modifier));
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      form.resetFields();
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  //delete reservation modifier
  const deleteModifier = async () => {
    if (!modifier?._id) {
      return;
    }
    setLoading(true);
    try {
      const res = await postDeleteReservationModifier(modifier?._id);
      if (res.data?.status === "success") {
        dispatch(deleteReservationModifierAction(modifier?._id));
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  return (
    <Form
      name='modifiers-form'
      form={form}
      className='form-container'
      layout='vertical'
      onFinish={isNew ? addNewModifier : editModifier}
      initialValues={{
        name: modifier?.name || "",
        name_en: modifier?.name_en || "",
        multiSelection: modifier?.multiSelection || false,
        maxSelection: modifier?.maxSelection || "",
        options:
          modifier?.options?.map((option) => {
            return {
              ...option,
              isActive: option.status === "Active",
            };
          }) || [],
      }}
    >
      {() => (
        <Spin spinning={loading}>
          <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
            {/* Names */}
            <Col md={12} sm={24} xs={24}>
              <Form.Item
                label={intl.formatMessage({ id: "nameAr" })}
                className='mb-1'
                name='name'
                style={{ borderRadius: 20 }}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: "errorname" }),
                  },
                ]}
              >
                <Input
                  className={"input"}
                  style={{ borderRadius: 5, width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col md={12} sm={24} xs={24}>
              <Form.Item
                label={intl.formatMessage({ id: "nameEn" })}
                className='mb-1'
                name='name_en'
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: "errorname" }),
                  },
                ]}
              >
                <Input className={"input"} style={{ borderRadius: 5 }} />
              </Form.Item>
            </Col>
          </Row>
          <Row className='mt-4' gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
            {/* Can choose multiple */}
            <Col md={6} sm={24} xs={24}>
              <Form.Item
                className='mb-1'
                name='multiSelection'
                valuePropName='checked'
                style={{ borderRadius: 20 }}
              >
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked === false) {
                      form.setFieldsValue({ maxSelection: 0 });
                    }
                  }}
                >
                  {intl.formatMessage({ id: "canChooseMulti" })}{" "}
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Row className='mt-4' gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            {/* Prices */}
            <Col md={6} sm={24} xs={24}>
              <Form.Item
                label={intl.formatMessage({ id: "maxSelection" })}
                className='mb-1'
                name='maxSelection'
                style={{ borderRadius: 20 }}
                rules={[
                  {
                    required: form.getFieldValue("multiSelection"),
                    message: intl.formatMessage({ id: "maxError" }),
                  },
                ]}
              >
                <InputNumber
                  className={"input"}
                  placeholder={"1"}
                  min={1}
                  max={optionsLength}
                  style={{ borderRadius: 5, width: "100%" }}
                  disabled={!form.getFieldValue("multiSelection")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
            {/* Title */}
            <Col md={12} sm={24} xs={24}>
              <Title className='my-font' level={4}>
                {intl.formatMessage({ id: "options" })}
              </Title>
              {form.getFieldError("options")?.length ? (
                <Text>{form.getFieldError("options")[0]}</Text>
              ) : null}
            </Col>
          </Row>

          <Form.List
            hasFeedback
            name='options'
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: "errorOptions" }),
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => {
              let errorNoOptions = errors?.length > 0;
              return (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }) => {
                    return (
                      <Row
                        key={key}
                        style={{
                          display: "flex",
                          marginBottom: 8,
                          flexWrap: "wrap",
                          borderBottom: "1px solid #e8e8e8",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Col
                          flex={1}
                          style={{
                            marginLeft: rtl ? 8 : 0,
                            marginRight: rtl ? 0 : 8,
                          }}
                        >
                          <Form.Item
                            {...restField}
                            label={intl.formatMessage({ id: "nameAr" })}
                            name={[name, "name"]}
                            fieldKey={[fieldKey, "name"]}
                            rules={[
                              {
                                required: true,
                                message: intl.formatMessage({
                                  id: "errorname",
                                }),
                              },
                            ]}
                          >
                            <Input
                              placeholder={intl.formatMessage({ id: "nameAr" })}
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          flex={1}
                          style={{
                            marginLeft: rtl ? 8 : 0,
                            marginRight: rtl ? 0 : 8,
                          }}
                        >
                          <Form.Item
                            {...restField}
                            label={intl.formatMessage({ id: "nameEn" })}
                            name={[name, "name_en"]}
                            fieldKey={[fieldKey, "name_en"]}
                            rules={[
                              {
                                required: true,
                                message: intl.formatMessage({
                                  id: "errorname",
                                }),
                              },
                            ]}
                          >
                            <Input
                              placeholder={intl.formatMessage({ id: "nameEn" })}
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          flex={1}
                          style={{
                            marginLeft: rtl ? 8 : 0,
                            marginRight: rtl ? 0 : 8,
                          }}
                        >
                          <Form.Item
                            {...restField}
                            label={intl.formatMessage({ id: "price" })}
                            name={[name, "price"]}
                            fieldKey={[fieldKey, "price"]}
                          >
                            <InputNumber placeholder='0' min={0} />
                          </Form.Item>
                        </Col>
                        <Form.Item
                          {...restField}
                          label={intl.formatMessage({ id: "isWithQuantity" })}
                          name={[name, "enableQuantity"]}
                          fieldKey={[fieldKey, "enableQuantity"]}
                          valuePropName='checked'
                        >
                          <Checkbox>
                            {intl.formatMessage({ id: "enable" })}{" "}
                          </Checkbox>
                        </Form.Item>
                        <Col
                          flex={1}
                          style={{
                            marginLeft: rtl ? 8 : 0,
                            marginRight: rtl ? 0 : 8,
                          }}
                        >
                          <Form.Item
                            {...restField}
                            label={intl.formatMessage({ id: "quantity" })}
                            name={[name, "quantity"]}
                            fieldKey={[fieldKey, "quantity"]}
                          >
                            <InputNumber
                              placeholder='1'
                              min={1}
                              disabled={
                                !form.getFieldValue([
                                  "options",
                                  name,
                                  "enableQuantity",
                                ])
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          flex={1}
                          style={{
                            marginLeft: rtl ? 8 : 0,
                            marginRight: rtl ? 0 : 8,
                          }}
                        >
                          <Form.Item
                            {...restField}
                            label={intl.formatMessage({ id: "enableNote" })}
                            name={[name, "enableNote"]}
                            fieldKey={[fieldKey, "enableNote"]}
                            valuePropName='checked'
                          >
                            <Switch
                              onChange={(value) => {
                                if (value === false) {
                                  form.setFields([
                                    {
                                      name: ["options", name, "noteIsRequired"],
                                      value: false,
                                    },
                                  ]);
                                }
                              }}
                              style={{ marginBottom: 0 }}
                              checkedChildren={
                                <p>
                                  {intl.formatMessage({
                                    id: "active",
                                  })}
                                </p>
                              }
                              unCheckedChildren={
                                <p>
                                  {intl.formatMessage({
                                    id: "disabled",
                                  })}
                                </p>
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          flex={1}
                          style={{
                            marginLeft: rtl ? 8 : 0,
                            marginRight: rtl ? 0 : 8,
                          }}
                        >
                          <Form.Item
                            {...restField}
                            label={intl.formatMessage({ id: "noteIsRequired" })}
                            name={[name, "noteIsRequired"]}
                            fieldKey={[fieldKey, "noteIsRequired"]}
                            valuePropName='checked'
                          >
                            <Switch
                              disabled={
                                form.getFieldValue([
                                  "options",
                                  name,
                                  "enableNote",
                                ])
                                  ? false
                                  : true
                              }
                              style={{ marginBottom: 0 }}
                              checkedChildren={
                                <p>
                                  {intl.formatMessage({
                                    id: "active",
                                  })}
                                </p>
                              }
                              unCheckedChildren={
                                <p>
                                  {intl.formatMessage({
                                    id: "disabled",
                                  })}
                                </p>
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          flex={1}
                          style={{
                            marginLeft: rtl ? 8 : 0,
                            marginRight: rtl ? 0 : 8,
                          }}
                        >
                          <Form.Item
                            {...restField}
                            label={intl.formatMessage({ id: "status" })}
                            name={[name, "isActive"]}
                            fieldKey={[fieldKey, "isActive"]}
                            valuePropName='checked'
                          >
                            <Switch
                              style={{ marginBottom: 0 }}
                              checkedChildren={
                                <p>
                                  {intl.formatMessage({
                                    id: "active",
                                  })}
                                </p>
                              }
                              unCheckedChildren={
                                <p>
                                  {intl.formatMessage({
                                    id: "disabled",
                                  })}
                                </p>
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          flex={1}
                          style={{
                            marginLeft: rtl ? 8 : 0,
                            marginRight: rtl ? 0 : 8,
                          }}
                        >
                          <Popconfirm
                            title={
                              <Text className='my-font'>
                                {intl.formatMessage({
                                  id: "confirmDeleteChoiceTitle",
                                })}
                              </Text>
                            }
                            okText={intl.formatMessage({ id: "delete" })}
                            okButtonProps={{
                              style: {
                                color: COLORS.danger,
                                fontFamily: "Cairo",
                              },
                            }}
                            okType='text'
                            cancelText={intl.formatMessage({ id: "cancel" })}
                            cancelButtonProps={{
                              style: { border: "none", fontFamily: "Cairo" },
                            }}
                            onConfirm={() => {
                              setOptionsLength(optionsLength - 1);
                              remove(name);
                            }}
                          >
                            <DeleteOutlined
                              style={{ color: COLORS.danger, fontSize: 15 }}
                              className='mx-3'
                            />
                          </Popconfirm>
                        </Col>
                      </Row>
                    );
                  })}

                  <Form.Item>
                    <Button
                      className='my-font'
                      type='dashed'
                      onClick={() => {
                        add({});
                        setOptionsLength(optionsLength + 1);
                      }}
                      block
                      style={{
                        color: errorNoOptions
                          ? COLORS?.danger
                          : COLORS?.primary,
                        borderColor: errorNoOptions
                          ? COLORS?.danger
                          : "#d9d9d9",
                      }}
                      icon={<PlusOutlined />}
                    >
                      {intl.formatMessage({ id: "addOption" })}
                    </Button>
                  </Form.Item>
                </>
              );
            }}
          </Form.List>

          <Divider />
          <Row justify='start' className='mt-5' align='middle'>
            <Form.Item>
              <CustomButton
                htmlType='submit'
                text={intl.formatMessage({ id: "save" })}
                className={`btnRegister btn-text border-8 px-5 `}
                loading={loading}
                disabled={loading}
                type='primary'
              />
            </Form.Item>
            <Form.Item className='mx-3'>
              <Popconfirm
                title={
                  <Text className='my-font'>
                    {intl.formatMessage({ id: "confirmDeleteModifierTitle" })}
                  </Text>
                }
                okText={intl.formatMessage({ id: "delete" })}
                okButtonProps={{
                  style: { color: COLORS.danger, fontFamily: "Cairo" },
                }}
                okType='text'
                cancelText={intl.formatMessage({ id: "cancel" })}
                cancelButtonProps={{
                  style: { border: "none", fontFamily: "Cairo" },
                }}
                onConfirm={deleteModifier}
                disabled={!modifier?._id}
              >
                <Text
                  disabled={!modifier?._id}
                  type='danger'
                  className='pointer my-font'
                >
                  {intl.formatMessage({ id: "delete" })}
                </Text>
              </Popconfirm>
            </Form.Item>
          </Row>
        </Spin>
      )}
    </Form>
  );
};

export default ModifierCard;
