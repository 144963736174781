import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Divider,
  Space,
  DatePicker,
  Row,
  Col,
  Typography,
  Tag,
  TimePicker,
  Popconfirm,
  Descriptions,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import CustomButton from "../../../Components/CustomButton";
import {
  getDayData,
  postUpdateUserReservation,
  refundPaymentReservation,
} from "../../../API/fetch";
import openNotification from "../../../Components/Notifications";
import moment from "moment";
import { setBranchAction } from "../../../redux/reducers/branchReducer";
import COLORS from "../../../Style/colors";
import { editTableAction } from "../../../redux/reducers/tableReducer";
import { MinusCircleFilled, PlusCircleFilled } from "@ant-design/icons";
import { TAG_COLORS_BY_PAYMENT_REFUND_STATUS } from "../../../Types";
import { useNavigate } from "react-router-dom";
import { checkRangeReservations, formatReservations, getAvailableHours, getTheTableIdForReservation } from "../../../helpers/reservations";
const { Option } = Select;
const { Text, Title, Link } = Typography;
const EditReservationModal = ({
  show,
  hide,
  reservation,
  date,
  isReader = false,
  handleUpdateLocal,
  onSelectDate,
  allDays,
  allUsers,
  seletedReservationSchema
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { waiter } = useSelector((state) => state.auth);
  const { branch } = useSelector((state) => state.branch);
  const { tables } = useSelector((state) => state.table);
  const { restaurant } = useSelector((state) => state.restaurant);
  const { rtl } = useSelector((state) => state.settings);
  const [area, setArea] = useState(null);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [hours, setHours] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [canEdit, setCanEdit] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [newEnterPersons, setNewEnterPersons] = useState(1);
  const [reservations, setReservations] = useState([]);
  const [totalPersons, setTotalPersons] = useState(1)  //Total user (persons + child)
  const [availableTables, setAvailableTables] = useState(null)
  const [isForView, setIsForView] = useState(false)
  const [hour, setHour] = useState("") // selected hour

  useEffect(() => {
    if (waiter?.role === "super" || waiter?.canEditReservation) {
      setCanEdit(true);
    }
  }, [waiter]);

  useEffect(() => {
    let area;
    if (branch) {
      area = branch?.reservationSchema?.find(
        (area) => area._id === reservation?.reservationTypeId
      );
      if (area && area?.startTime && area?.endTime && selectedDate) {
        let allHours = [];
        let startTime = area?.startTime;
        let endTime = area?.endTime;
        let openSeatsEvery = area?.openSeatsEvery;
        let start = moment(selectedDate).set({
          hour: startTime.split(":")[0],
          minute: startTime.split(":")[1],
        });
        let end = moment(selectedDate).set({
          hour: endTime.split(":")[0],
          minute: endTime.split(":")[1],
        });
        // check if end less the start;
        if (end.isBefore(start)) {
          end = moment(end).add(1, "day");
        }
        while (start.isBefore(end)) {
          allHours.push({
            time: start.format("DD-MM-YYYY HH:mm"),
            timeAsDate: start.toDate(),
          });
          start = moment(start).add(openSeatsEvery, "minutes");
        }
        setHours(allHours);
      }
      setArea(area);
    }
    if (reservation && reservation?.orders?.length) {
      // filter where the order pick time same as the reservation time;
      let orders = reservation?.orders?.filter((order) =>
        moment(order?.timeForPickup).isSame(
          moment(reservation?.reservationDate),
          "day"
        )
      );
      setSelectedOrders(orders);
    }
    if (reservation) {
      setTotalPersons(reservation?.childCount + reservation?.personsCount)

      if (reservation.bookedTableId) {
        setAvailableTables(seletedReservationSchema?.linkedTables?.filter(a =>
          a?.status !== 'deleted'))
      }
    }
  }, [reservation, selectedDate]);

  useEffect(() => {
    setTimeout(() => {
      form?.resetFields();
    }, 250);
  }, [reservation]);


  useEffect(() => {
    if (selectedDate && show) {
      getDayDetails();
    }
  }, [selectedDate, show]);

  useEffect(() => {
    let allHours = [];
    if (
      seletedReservationSchema &&
      selectedDate &&
      seletedReservationSchema?.status === "Active"
    ) {
      let isCountPerReservation =
        seletedReservationSchema?.isCountPerReservation;
      let islinkedToTables = seletedReservationSchema?.isLinkTables
      let day = selectedDate.format("YYYY-MM-DD");

      const { startTime, endTime, openSeatsEvery } = seletedReservationSchema;
      // set start new date with the day and start time hours;
      let start = moment(day, "YYYY-MM-DD").set({
        hour: startTime.split(":")[0],
        minute: startTime.split(":")[1],
      });
      let end = moment(day, "YYYY-MM-DD").set({
        hour: endTime.split(":")[0],
        minute: endTime.split(":")[1],
      });

      // // check if end less the start;
      if (end.isBefore(start)) {
        end = moment(end).add(1, "day");
      }
      // start loop form start to end and add empty object to hours every openSeatsEvery;
      let price = 0;
      if (seletedReservationSchema.isPriceForReservation) {
        price = seletedReservationSchema.reservationPrice;
      } else if (
        seletedReservationSchema.pricePerUser &&
        seletedReservationSchema.pricePerUser > 0
      ) {
        price = seletedReservationSchema.pricePerUser;
      }

      if (seletedReservationSchema?.onlyOneReservationADay) {
        let reservationCount = 0;
        if (reservations && reservations.length) {
          reservations.forEach(
            (one) => (reservationCount += one.takenReservationCount)
          );
        }
        let bookingDate = moment(day, "YYYY-MM-DD")
          .startOf("day")
          .add(12, "hours")
          .toDate();
        allHours.push({
          price: price,
          pricePerChild: seletedReservationSchema?.pricePerChild
            ? seletedReservationSchema?.pricePerChild
            : 0,
          time: moment(bookingDate).format("DD-MM-YYYY HH:mm"),
          timeAsDate: bookingDate,
          bookedCount: 0,
          reservationCount,
          isNextDay: false,
          available: true,
          isLinkedToTables: islinkedToTables,
          maxUsers: seletedReservationSchema.maxUsers,
          maxReservations: seletedReservationSchema.maxReservations,
          isPriceForReservation: seletedReservationSchema.isPriceForReservation,
          onlyOneReservationADay: true,
        });
        // }
      } else {
        while (start.isBefore(end)) {
          let bookedCount = 0;
          let reservationCount = 0;
          let bookedTables = []
          if (reservations && reservations.length > 0) {
            // filter if the selected
            let select = reservations.find((one) =>
              checkRangeReservations(one?.date, one?.end, start, seletedReservationSchema?.openSeatsEvery) && one?.reservationTypeId === seletedReservationSchema?._id
            );
            if (isCountPerReservation) {
              if (select && select?.takenReservationCount > 0) {
                reservationCount += select.takenReservationCount;
              }
            } else {
              if (select && select?.takenCount > 0) {
                bookedCount += select.takenCount;
              }
            }
            if (islinkedToTables) {
              bookedTables = select?.tableIds
            }
          }
          // add to hours array only if time is bigger than now;
          if (
            (start.isAfter(moment()) &&
              start.isAfter(
                moment().add(
                  seletedReservationSchema.blockReservations,
                  "minutes"
                )
              ))
          ) {
            let isNextDay = false;
            let resDay = start.format("YYYY-MM-DD").split("-")[2];
            let selDay = day.split("-")[2];
            if (parseInt(resDay) != parseInt(selDay)) {
              isNextDay = true;
            }
            allHours.push({
              price: price,
              pricePerChild: seletedReservationSchema?.pricePerChild
                ? seletedReservationSchema?.pricePerChild
                : 0,
              time: start.format("DD-MM-YYYY HH:mm"),
              timeAsDate: start.toDate(),
              bookedCount,
              reservationCount,
              isNextDay,
              available: true,
              maxUsers: seletedReservationSchema.maxUsers,
              isLinkedToTables: islinkedToTables,
              bookedTables: bookedTables,
              maxReservations: seletedReservationSchema.maxReservations,
              isPriceForReservation:
                seletedReservationSchema.isPriceForReservation,
            });
          }

          start = moment(start).add(openSeatsEvery, "minutes");
        }
      }
    }
    setHours(allHours);
    setLoading(false);
  }, [seletedReservationSchema, selectedDate, reservations]);

  //once the client is set , Form can't be editable (just for view)
  useEffect(() => {
    if (reservation?.tableId) {
      setIsForView(true)
    }
  }, [reservation])


  const handelEditUser = async ({ id, status }) => {
    try {
      setLoading(true);
      const dateFormat = moment(date);
      const data = {
        reservation: {
          status,
          date: dateFormat,
        },
        table: {},
      };
      const res = await postUpdateUserReservation(id, data);
      const newBranch = {
        ...branch,
        reservationList: res.data.reservationList,
      };
      dispatch(setBranchAction(newBranch));
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setLoading(false);
      setNewEnterPersons(1);
      setHour("")
      hide();
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const onFinish = async (values) => {
    try {
      let customerCanSetForMinutes
      if (reservation?.isWithHours) {
        //get the number of minutes of customers if he is late 
        const reservationDate = moment(reservation?.reservationDate)
        const reservationAttendance = moment()
        const lateMinutes = reservationAttendance.diff(reservationDate, 'minutes');
        //check if reservation attendance is beofre the reservation date 
        //isBefore = sitting time is reservation duration
        //otherwise = substract lateTimes from reservation duration
        if (reservationAttendance.isBefore(reservationDate)) {
          customerCanSetForMinutes = 60 * (reservation?.numberOfHours + values?.addHours)
        } else {
          customerCanSetForMinutes = (60 * (reservation?.numberOfHours + values?.addHours)) - lateMinutes
        }
      } else {
        customerCanSetForMinutes = values?.customerCanSetForMinutes
      }
      setLoading(true);
      const dateFormat = moment(date);
      const reservationDate = moment(
        values.reservationDate,
        "DD-MM-YYYY hh:mm"
      ).toDate();
      const data = {
        reservation: {
          ...values,
          reservationDate,
          status: reservation?.status,
          date: dateFormat,
          hourAddsOn: values?.addHours,
          totalHoursAddsOn: seletedReservationSchema?.hourPrice * values?.addHours
        },
        table: {
          _id: values.tableId,
          startSeTime: values?.startSeTime.toDate(),
          customerCanSetForMinutes: customerCanSetForMinutes,
          reservationId: reservation._id,
          customerId: reservation?.customerId?._id,
        },
      };
      // check if reservations linked with tables;
      if (seletedReservationSchema?.isLinkTables) {
        // get the selected table id;
        let selectedHour = hours.filter(element => element.time === hour)[0]
        const selectedId = getTheTableIdForReservation(availableTables, selectedHour?.bookedTables, totalPersons)
        if(selectedId){
          data.reservation.bookedTableId = selectedId
        }
      }
      if (isReader) {
        data.reservation = {
          personsEntered: reservation?.personsEntered + newEnterPersons,
          customerCanSetForMinutes: customerCanSetForMinutes,
          startSeTime: values?.startSeTime,
        };
        if (values?.tableId) {
          data.reservation.tableId = values?.tableId;
        }

        if (data.reservation.personsEntered === reservation?.personsCount) {
          data.reservation.status = "served";
        }
      }
      const res = await postUpdateUserReservation(reservation?._id, data);
      if (isReader) {
        handleUpdateLocal(res.data?.reservationList);
      }
      const newBranch = {
        ...branch,
        reservationList: res.data.reservationList,
      };
      if (res?.data?.table) {
        // update table;
        dispatch(editTableAction(res?.data?.table));
        // remove the customer from the old table if exist;
        if (reservation?.tableId?._id && reservation?.tableId?._id !== values?.tableId) {
          const oldTable = tables.find(
            (table) => table._id === reservation?.tableId?._id
          );
          if (oldTable) {
            const newTable = {
              ...oldTable,
              reservationId: null,
              customerId: null,
              customerCanSetForMinutes: 0,
            };
            dispatch(editTableAction(newTable));
          }
        }
      }
      dispatch(setBranchAction(newBranch));
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });

      setLoading(false);
      setNewEnterPersons(1);
      setHour("")
      hide();
    } catch (err) {
      console.log(err);
      setLoading(false);
      openNotification({
        type: "error",
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        rtl,
      });
    }
  };

  const refundPayment = async () => {
    try {
      setLoading(true);
      const data = {
        reservationId: reservation?._id,
      };
      const res = await refundPaymentReservation(data);
      if (res?.data?.refund) {
        // update the reservation;
        onSelectDate({ date: date, allDays });
      }
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setLoading(false);
      setHour("")
      hide();
    } catch (error) {
      console.log(error);
      let message = intl.formatMessage({ id: "errorfetch" });
      if (error?.response?.data?.error) {
        message = error.response.data.error;
      }
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: message,
        type: "error",
        rtl,
      });
    }
  };

  const getDayDetails = async () => {
    const data = {
      branchId: branch._id,
      date: selectedDate.format("YYYY-MM-DD"),
    };
    try {
      const res = await getDayData(
        data.branchId,
        data.date
        // abortController.signal
      );
      if (res?.data) {
        setReservations(formatReservations(res.data.reservations, seletedReservationSchema?.openSeatsEvery));
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      title={
        <>
          <Title level={5} className='my-font'>
            {reservation?.customerId?.name}
            <Tag color='green' style={{ marginLeft: 10, marginRight: 10 }}>
              {intl.formatMessage({
                id: reservation?.status ? reservation?.status : "N/A",
              })}
            </Tag>
            - {/* Display the reservation time */}
            <Tag
              color={
                moment(reservation?.reservationDate).isBefore(moment())
                  ? "red"
                  : moment(reservation?.reservationDate).isAfter(
                    moment().add(1, "hours")
                  )
                    ? "red"
                    : "green"
              }
            >
              {moment(reservation?.reservationDate).format(
                "DD/MM/YYYY HH:mm A"
              )}
            </Tag>
          </Title>
          <Link
            href={`tel:00${reservation?.customerId?.phone}`}
            copyable
            type='secondary'
          >
            {reservation?.customerId?.phone}
          </Link>
        </>
      }
      centered
      //   width={"90%"}
      visible={show}
      destroyOnClose={true}
      mask={true}
      maskClosable={true}
      onCancel={() => {
        setNewEnterPersons(1);
        setHour("")
        hide();
      }}
      footer={null}
      style={{
        minWidth: "80%",
      }}
    >
      <Form
        name='editArea'
        form={form}
        preserve={false}
        className='form-container'
        layout='vertical'
        onFinish={onFinish}
        initialValues={{
          name: reservation?.customerId?.name,
          phone: reservation?.customerId?.phone?.slice(3),
          prefix: "+" + reservation?.customerId?.phone?.slice(0, 3),
          personsCount: reservation?.personsCount,
          childCount: reservation?.childCount,
          personsEntered: reservation?.personsEntered || 0,
          note: reservation?.note,
          reservationTypeId: reservation?.reservationTypeId,
          total: reservation?.total,
          reservationDate: moment(reservation?.reservationDate)?.format(
            "DD-MM-YYYY HH:mm"
          ),
          tableId: reservation?.tableId?._id || "",
          startSeTime: reservation?.tableId?.startSeTime
            ? moment(reservation?.tableId?.startSeTime)
            : moment(),
          customerCanSetForMinutes:
            reservation?.tableId?.customerCanSetForMinutes || 60,
          addHours: reservation?.hourAddsOn ? reservation?.hourAddsOn : 0,
          numberOfHours: reservation?.numberOfHours,
        }}
      >
        {() => {
          return (
            <>
              {isReader ? null : (
                <>
                  {/* Reservation CountPersons (person and child ) , hours count (if reservation isWithHour) and Total */}
                  <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        label={intl.formatMessage({ id: "persons" })}
                        className='mb-1'
                        name='personsCount'
                      >
                        <InputNumber
                          style={{
                            width: "100%",
                          }}
                          className={"input"}
                          min={0}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        label={intl.formatMessage({ id: "childCount" })}
                        className='mb-1'
                        name='childCount'
                      >
                        <InputNumber
                          style={{
                            width: "100%",
                          }}
                          className={"input"}
                          min={0}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    {reservation.isWithHours ?
                      <Col md={12} sm={24} xs={24}>
                        <Form.Item
                          label={intl.formatMessage({
                            id: "hoursCount",
                          })}
                          className='mb-1'
                          name='numberOfHours'
                        >
                          <InputNumber
                            style={{
                              width: "100%",
                            }}
                            className={"input"}
                            min={0}
                            disabled
                          />
                        </Form.Item>
                      </Col> : null}
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        label={intl.formatMessage({ id: "total" })}
                        className='mb-1'
                        name='total'
                      >
                        <Input className={"input"} disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* Reservation Date and time : Editable in case of owner*/}
                  <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        label={intl.formatMessage({ id: "date" })}
                        className='mb-1'
                      >
                        <DatePicker
                          disabled={!canEdit || isForView}
                          value={selectedDate}
                          style={{ width: "100%" }}
                          onChange={(e) => {
                            setSelectedDate(e);
                            form.setFieldsValue({
                              reservationDate:
                                moment(e).format("DD-MM-YYYY HH:mm"),
                            });
                          }}
                          disabledDate={(current) => {
                            //deisable the date if it is before the now;
                            return current < moment().subtract(1, "day");
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        label={intl.formatMessage({ id: "time" })}
                        className='mb-1'
                        name='reservationDate'
                        rules={[
                          {
                            required: true,
                          },
                        ]}

                      >
                        <Select className='my-font' disabled={isForView || !canEdit} onChange={(e) => setHour(e)}>
                          {hours?.map((hour) => {
                            let disabled = false;
                            let noAvailableChairs = false
                            let isWithHourSelection = hour?.isLinkedToTables && seletedReservationSchema?.isHourSelection
                            let availablesHours = 0
                            let linkedTable = hour?.isLinkedToTables
                            if (!linkedTable) {
                              if (seletedReservationSchema?.isCountPerReservation) {
                                if (hour.reservationCount >= hour.maxReservations) {
                                  disabled = true;
                                }
                              } else {
                                if (
                                  hour.bookedCount + (form.getFieldValue("personsCount") + form.getFieldValue("childCount")) >
                                  hour.maxUsers
                                ) {
                                  disabled = true;
                                }
                              }
                              if (totalPersons > seletedReservationSchema?.maxChairs || totalPersons < seletedReservationSchema?.minUsers) {
                                disabled = true
                              }
                            }
                            else {
                              if (availableTables?.length < 1 || availableTables?.filter(t => !hour?.bookedTables?.includes(t?._id))?.length < 1) {
                                noAvailableChairs = true
                              }
                            }
                            if (isWithHourSelection) {
                              const restOfHours = hours?.filter(element => moment(element?.timeAsDate).isSameOrAfter(hour?.timeAsDate));
                              let index = 0;
                              let isFound = false;
                              while (!isFound && index < restOfHours.length) {
                                if (availableTables?.length < 1 || availableTables?.filter(t => !restOfHours[index]?.bookedTables?.includes(t?._id))?.length < 1) {
                                  isFound = true;
                                } else {
                                  index++;
                                }
                              }
                              availablesHours = index
                            }

                            return (
                              <Option className='my-font' key={hour?.time}
                                disabled={disabled || noAvailableChairs || (isWithHourSelection && form.getFieldValue("numberOfHours") > availablesHours)} >
                                {hour?.time?.slice(10, 16)} {disabled || noAvailableChairs ? `- ${intl.formatMessage({ id: "reserved" })}`
                                  : isWithHourSelection && form.getFieldValue("numberOfHours") > availablesHours ? `- ${intl.formatMessage({ id: "unavailable" })}` : null}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* Reservation Note and SchemaType  */}
                  <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        label={intl.formatMessage({ id: "note" })}
                        className='mb-1'
                        name='note'
                      >
                        <Input className={"input"} disabled={!canEdit || isForView} />
                      </Form.Item>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        label={intl.formatMessage({ id: "reservationType" })}
                        className='mb-1'
                        name='reservationTypeId'
                      >
                        <Select disabled className='my-font'>
                          {branch?.reservationSchema?.map((one) => {
                            return (
                              <Option
                                value={one._id}
                                key={one._id}
                                className='my-font'
                              >
                                {one?.name_en
                                  ? rtl
                                    ? one?.name
                                    : one?.name_en
                                  : one?.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* Reservation Modifiers */}
                  <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                    {reservation.modifiers &&
                      reservation.modifiers.length > 0 ? (
                      <Col md={12} sm={24} xs={24}>
                        <Form.Item
                          label={intl.formatMessage({ id: "modifiers" })}
                          className='mb-1'
                          name='notes'
                        >
                          {reservation?.modifiers?.map((mod, i) => (
                            <Tag
                              key={i}
                              style={{
                                paddingTop: 5,
                                paddingBottom: 5,
                                marginTop: 7,
                              }}
                            >
                              <Tag color='gray'>{mod?.qty}</Tag>
                              <Tag color='gray'>
                                {rtl ? mod?.name : mod?.name_en}
                              </Tag>

                              {mod?.note ? (
                                <Tag color='gray'>{mod?.note}</Tag>
                              ) : null}
                            </Tag>
                          ))}
                        </Form.Item>
                      </Col>
                    ) : null}
                    {reservation?.note ? (
                      <Col md={12} sm={24} xs={24}>
                        <Form.Item
                          label={intl.formatMessage({ id: "notes" })}
                          className='mb-1'
                          name='note'
                        >
                          {reservation?.note ? (
                            <Tag>{reservation?.note}</Tag>
                          ) : null}
                        </Form.Item>
                      </Col>
                    ) : null}
                  </Row>

                  {waiter?.role === "super" &&
                    reservation?.paymentStatus === "paid" ? (
                    // show refund reservation button;
                    <Space direction='horizontal' size={"small"}>
                      {reservation?.refundPaymentInfo?.refundStatus ===
                        "REFUNDED" ||
                        reservation?.refundPaymentInfo?.refundStatus ===
                        "Refunded" ? null : reservation?.refundPaymentInfo
                          ?.refundId ? (
                        <CustomButton
                          type='primary'
                          text={intl.formatMessage({ id: "refresh" })}
                          onClick={refundPayment}
                          disabled={loading}
                          loading={loading}
                        />
                      ) : (
                        <Popconfirm
                          title={
                            <div
                              style={{
                                maxWidth: 300,
                              }}
                            >
                              <Text className='my-font'>
                                {intl.formatMessage({ id: "refundPaymentDes" })}
                              </Text>
                            </div>
                          }
                          okText={intl.formatMessage({ id: "refundPayment" })}
                          okButtonProps={{
                            style: {
                              color: COLORS.danger,
                              fontFamily: "Cairo",
                            },
                          }}
                          okType='text'
                          cancelText={intl.formatMessage({ id: "cancel" })}
                          cancelButtonProps={{
                            style: { border: "none", fontFamily: "Cairo" },
                          }}
                          onConfirm={refundPayment}
                          // disabled if role is not super or the created At is more than 3 days ago;
                          disabled={
                            waiter?.role !== "super" ||
                            new Date(reservation?.createdAt) <
                            Date.now() - 1000 * 60 * 60 * 12
                          }
                        >
                          <Text
                            disabled={
                              waiter?.role !== "super" ||
                              new Date(reservation?.createdAt) <
                              Date.now() - 1000 * 60 * 60 * 12
                            }
                            type='danger'
                            className='pointer my-font'
                          >
                            {intl.formatMessage({
                              id: reservation?.refundPaymentInfo?.refundId
                                ? "refresh"
                                : "refundPayment",
                            })}
                          </Text>
                        </Popconfirm>
                      )}
                      {reservation?.refundPaymentInfo?.refundId ? (
                        <Tag
                          color={
                            TAG_COLORS_BY_PAYMENT_REFUND_STATUS[
                            reservation?.refundPaymentInfo?.refundStatus
                            ]
                          }
                          className='my-font'
                        >
                          {intl.formatMessage({
                            id:
                              reservation?.refundPaymentInfo?.refundStatus ||
                              "",
                          })}
                        </Tag>
                      ) : null}
                    </Space>
                  ) : null}
                  <Divider orientation={rtl ? "right" : "left"}>
                    <Title level={5} className='my-font'>
                      {intl.formatMessage({ id: "linkToTable" })}
                    </Title>
                  </Divider>
                </>
              )}
              {/* Reservation Link Table And Sitting Start*/}
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "table" })}
                    className='mb-1'
                    name='tableId'
                  >
                    <Select
                      showSearch
                      // disabled={isForView}
                      filterOption={(input, option) => {
                        return (
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                      className='my-font'
                      disabled={reservation.isDone}
                    >
                      {tables?.filter(table => {
                        if (reservation && reservation.bookedTableId) {
                          return table?.zone === tables?.find(el => el?._id === reservation?.bookedTableId)?.zone && table?.numberOfChairs >= (reservation?.personsCount + reservation?.childCount)
                            ;
                        }
                        return true;
                      }).map((one) => {
                        let isAvailable = true;
                        if (one?.customerId?._id) {
                          // check if it is in last 12 hours;
                          let now = moment();
                          let startTime = moment(one?.startSeTime);
                          // get the difference in hours
                          let diff = now.diff(startTime, "hours");
                          // check it the startTime in last 12 hours;
                          if (diff <= 12) {
                            isAvailable = false;
                          }
                        }
                        return (
                          <Option
                            value={one._id}
                            key={one._id}
                            className='my-font'
                            disabled={!isAvailable}
                            style={{
                              backgroundColor: !isAvailable
                                ? COLORS.danger
                                : COLORS.white,
                            }}
                          >
                            {one?.name_en
                              ? rtl
                                ? one?.name
                                : one?.name_en
                              : one?.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "sittingStart" })}
                    className='mb-1'
                    name='startSeTime'
                  >
                    <TimePicker
                      disabled={isForView}
                      format={"hh:mm"}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {/* Reservation Time Sitting and personsEntered */}
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                {reservation?.isWithHours ?
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({
                        id: "addMoreHours",
                      })}
                      className='mb-1'
                      name='addHours'
                    >
                      <Select
                        showSearch
                        disabled={reservation?.isDone}
                        filterOption={(input, option) => {
                          return (
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                        className='my-font'
                      >
                        {getAvailableHours(reservation, seletedReservationSchema, allUsers, reservation?.reservationDate)?.map((one, index) => {
                          return (
                            <Option
                              value={index + 1}
                              key={index}
                              className='my-font'
                            >

                              {index > 1 ? index + 1 : null}  {intl.formatMessage({ id: index === 0 ? "oneHour" : index === 1 ? "twoHour" : "Hours" })}{" "}
                              -{" "}{(index + 1) * seletedReservationSchema?.hourPrice} {restaurant.currency}

                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  :
                  <Col md={12} sm={24} xs={24}>
                    {/*  */}
                    <Form.Item
                      label={intl.formatMessage({
                        id: "customerCanSetForMinutes",
                      })}
                      className='mb-1'
                      name='customerCanSetForMinutes'
                    >
                      <Input
                        disabled={reservation.isDone}
                        addonBefore={
                          rtl ? null : (
                            <Text className='my-font'>
                              {intl.formatMessage({ id: "minutes" })}
                            </Text>
                          )
                        }
                        type='number'
                        addonAfter={
                          rtl ? (
                            <Text className='my-font'>
                              {intl.formatMessage({ id: "minutes" })}
                            </Text>
                          ) : null
                        }
                        style={{ width: "100%" }}
                        min={0}
                      />
                    </Form.Item>
                  </Col>
                }
                {isReader ? (
                  <Col md={12} sm={24} xs={24}>
                    <Text
                      className='my-font'
                      style={{
                        fontSize: 16,
                      }}
                    >
                      {intl.formatMessage({ id: "personsEntered" })}
                    </Text>
                    <Row
                      justify='flex-start'
                      align='middle'
                      style={{
                        width: "100%",
                        marginTop: 10,
                      }}
                    >
                      <PlusCircleFilled
                        onClick={() => {
                          // check if can add more persons Entered;
                          if (
                            reservation?.personsEntered + newEnterPersons <
                            reservation?.personsCount
                          ) {
                            setNewEnterPersons(newEnterPersons + 1);
                          }
                        }}
                        style={{
                          fontSize: 25,
                          cursor: "pointer",
                          color:
                            reservation?.personsCount -
                              reservation?.personsEntered <=
                              newEnterPersons
                              ? COLORS.gray
                              : COLORS.primary,
                        }}
                      />
                      <div
                        style={{
                          width: 35,
                          height: 35,
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          display: "flex",
                          backgroundColor: COLORS.gray,
                          marginRight: 10,
                          marginLeft: 10,
                          borderRadius: 10,
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 20,
                          }}
                          className='my-font'
                        >
                          {newEnterPersons}
                        </Text>
                      </div>
                      <MinusCircleFilled
                        onClick={() => {
                          if (newEnterPersons > 1) {
                            setNewEnterPersons(newEnterPersons - 1);
                          }
                        }}
                        style={{
                          fontSize: 25,
                          cursor: "pointer",
                          color:
                            newEnterPersons === 1 ? COLORS.gray : COLORS.danger,
                        }}
                      />
                    </Row>
                  </Col>
                ) : (
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "personsEntered" })}
                      className='mb-1'
                      name='personsEntered'
                    >
                      <InputNumber
                        min={0}
                        style={{ width: "100%" }}
                        className='my-font'
                        max={form.getFieldValue("personsCount")}
                        type='number'
                        disabled={reservation.isDone}
                      />
                    </Form.Item>
                    <Text type='secondary' className='my-font'>
                      {intl.formatMessage({ id: "remain" })}{" "}
                      {form.getFieldValue("personsCount") -
                        form.getFieldValue("personsEntered")}{" "}
                      {intl.formatMessage({ id: "person" })}
                    </Text>
                  </Col>
                )}
              </Row>
              {/* reservation Modifiers for is Reader */}
              {reservation.modifiers &&
                reservation.modifiers.length > 0 &&
                isReader ? (
                <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "modifiers" })}
                      className='mb-1'
                      name='note'
                    >
                      {reservation?.modifiers?.map((mod) => (
                        <Tag>
                          {rtl ? mod?.name : mod?.name_en} - {mod?.qty}
                        </Tag>
                      ))}
                    </Form.Item>
                  </Col>
                  <Col md={12} sm={24} xs={24}></Col>
                </Row>
              ) : null}
              {/* End reservation Modifiers for is Reader */}
              {isReader ? null : selectedOrders?.length > 0 ? (
                <>
                  <Divider orientation={rtl ? "right" : "left"}>
                    <Title level={5} className='my-font'>
                      {intl.formatMessage({ id: "orders" })}
                    </Title>
                  </Divider>
                  <Descriptions bordered>
                    {selectedOrders?.map((oneOrder) => {
                      return oneOrder?.orders?.map((one) => {
                        return (
                          <Descriptions.Item
                            label={
                              <Text
                                style={{
                                  textDecoration: "underline",
                                  textUnderlineOffset: 5,
                                }}
                                onClick={() => {
                                  if (oneOrder?._id) {
                                    navigate(`/scheduleOrder/${oneOrder?._id}`)
                                  }
                                }}
                                className="my-font pointer">{rtl ? one?.foodId?.name : one?.foodId?.name_en}</Text>
                            }
                            key={one?._id}
                            span={3}
                          >
                            {one?.qty}
                          </Descriptions.Item>
                        );
                      });
                    })}
                  </Descriptions>
                  <Divider />
                </>
              ) : null}
              <Space
                size='small'
                style={{
                  minWidth: 100,
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  marginTop: 10,
                }}
              >
                {reservation?.status === "Active" ? (
                  <Popconfirm
                    title={
                      <Text className='my-font'>
                        {intl.formatMessage({ id: "confirmDelete" })}
                      </Text>
                    }
                    okText={intl.formatMessage({ id: "delete" })}
                    okButtonProps={{
                      style: { color: COLORS.danger, fontFamily: "Cairo" },
                    }}
                    okType='text'
                    cancelText={intl.formatMessage({ id: "cancel" })}
                    cancelButtonProps={{
                      style: { border: "none", fontFamily: "Cairo" },
                    }}
                    onConfirm={() =>
                      handelEditUser({
                        id: reservation?._id,
                        status: "deleted",
                      })
                    }
                    disabled={loading || reservation?.paymentStatus === "paid"}
                    loading={loading}
                  >
                    <Text
                      type='danger'
                      className='pointer my-font'
                      disabled={
                        loading || reservation?.paymentStatus === "paid"
                      }
                    >
                      {intl.formatMessage({ id: "delete" })}
                    </Text>
                  </Popconfirm>
                ) : null}
              </Space>
              <Divider />
              <Space direction='horizontal'>
                <Form.Item>
                  <CustomButton
                    htmlType='submit'
                    text={intl.formatMessage({ id: "save" })}
                    className={`btnRegister btn-text border-8`}
                    loading={loading}
                    disabled={loading || reservation.isDone || (moment().diff(moment(reservation?.reservationDate), 'days') !== 0 && !canEdit)}
                    type='primary'
                  />
                </Form.Item>

                <Form.Item>
                  <CustomButton
                    type='text'
                    text={intl.formatMessage({ id: "cancel" })}
                    disabled={loading}
                    onClick={() => {
                      setNewEnterPersons(1);
                      setHour("")
                      hide();
                    }}
                  />
                </Form.Item>
              </Space>
            </>
          );
        }}
      </Form>
    </Modal>
  );
};

export default EditReservationModal;
