import { Button, Divider, Form, Input, Modal, Typography } from 'antd'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { DefaultEditor } from 'react-simple-wysiwyg'
import CustomButton from '../../../../Components/CustomButton'

const { Title } = Typography

function ReservationTerms() {
    const intl = useIntl()
    const [showReservationTerms, setShowReservationTerms] = useState(false);

    return (
        <>
            <CustomButton
                type="primary"
                className='mx-2 px-5 border-8 mt-2'
                text={intl.formatMessage({ id: "termsAndDescription" })}
                onClick={() =>
                    setShowReservationTerms(true)
                }
            />
            <Modal
                open={showReservationTerms}
                onOk={() => setShowReservationTerms(false)}
                onCancel={() => {
                    setShowReservationTerms(false);
                }}
                centered
                visible={showReservationTerms}
                footer={[
                    <Button
                      onClick={() => setShowReservationTerms(false)}
                    >
                      {intl.formatMessage({ id: "save" })}
                    </Button>,
                  ]}
            >
                <Title className='my-font' level={4}>
                    {intl.formatMessage({ id: "terms" })}
                </Title>
                <Divider />
                <Form.Item
                    label={intl.formatMessage({ id: "descriptionAr" })}
                    className='mb-1'
                    name='description'
                >
                    <DefaultEditor />
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ id: "descriptionEn" })}
                    className='mb-1'
                    name='description_en'
                >
                    <DefaultEditor />
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ id: "terms" })}
                    className='mb-1'
                    name='terms'
                >
                    <DefaultEditor />
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ id: "terms_en" })}
                    className='mb-1'
                    name='terms_en'
                >
                    <DefaultEditor />
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ id: "whatsappMessage" })}
                    className='mb-1'
                    name='whatsappMessage'
                    style={{flexDirection: 'column'}}
                >
                    <Input.TextArea
                        rows={4}
                    />
                </Form.Item>
            </Modal>
        </>

    )
}

export default ReservationTerms