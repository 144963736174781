import {
  Col,
  Row,
  Form,
  Input,
  InputNumber,
  Typography,
  Select,
  Spin,
  Switch,
  Divider,
  Upload,
  Modal,
  message,
  Tag,
  Popconfirm,
  Space,
  TreeSelect,
} from "antd";
import React, { useEffect, useState } from "react";
import { IoArrowBackOutline, IoArrowForwardOutline } from "react-icons/io5";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import COLORS from "../../../Style/colors";
import CustomButton from "../../../Components/CustomButton";
import openNotification from "../../../Components/Notifications";
import {
  postAddFood,
  postDeleteFood,
  postUpdateFood,
} from "../../../API/fetch";
import {
  addFoodAction,
  deleteFoodAction,
  editCategoryAction,
  editFoodAction,
} from "../../../redux/reducers/menuReducer";
import {
  arrAdd,
  arrRemoveByKeyValue,
  arrUpdateByKeyValue,
} from "../../../helpers/array";
import {
  checkIsWithQty,
  getBranchNameById,
  getFormattedQuantities
} from "../../../helpers";
import {
  CheckOutlined,
  CloseOutlined,
  ContactsOutlined,
  DeleteOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import apiUrl from "../../../API/config";
import { FoodAllergyTYPES } from "../../../Types";
import { getFoodAllergyIcon } from "../../../assets/food";
import MenuIsSync from "../../../Components/MenuIsSync";

const acceptedTypes = ["image/png", "image/jpg", "image/jpeg", "video/mp4"];
const { Dragger } = Upload;
const { Title, Text } = Typography;
const { Option } = Select;
const { SHOW_ALL } = TreeSelect;

const OneFoodView = () => {
  const intl = useIntl();
  const params = useParams();
  const history = useNavigate();
  const [form] = Form.useForm();
  const { rtl } = useSelector((state) => state.settings);
  const { user, role } = useSelector((state) => state.auth);
  const { restaurant, integrations } = useSelector((state) => state.restaurant);
  const { allBranches } = useSelector((state) => state.branch);
  const { categories, foods, modifiers } = useSelector((state) => state.menu);
  const dispatch = useDispatch();
  const [food, setFood] = useState(null);
  const [isNew, setIsNew] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingModifiers, setLoadingModifiers] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [errorSize, setErrorSize] = useState(false)
  const [suggestedFoods, setSuggestedFoods] = useState([]);

  // Selected food;
  useEffect(() => {
    if (params?.id) {
      const selected = foods.find((t) => t.foodId === params.id);
      if (selected) {
        setFood(selected);
        setInitialLoading(false);
      }
      if (params?.id === "New") {
        setIsNew(true);
        setInitialLoading(false);
      }
    }
  }, [params, foods, categories]);

  useEffect(() => {
    // set the values;
    if (food && food?.foodId) {
      form.resetFields();
      setFileList(
        food.images?.map((image, i) => {
          return {
            uid: image._id,
            status: "done",
            url: image.url,
            name: `${food.name} - ${i + 1}`,
          };
        })
      );
    }
  }, [food]);

  useEffect(() => {
    if (food?.suggestedIds && food?.suggestedIds?.length) {
      setSuggestedFoods(food.suggestedIds);
    }
  }, [food]);

  // handle image upload;
  const handleChangeImages = ({ fileList, file }) => {
    let newFileList = [...fileList];
    setErrorSize(false)
    let errors = []
    newFileList.forEach(el => {
      if (el.size > 2000000) { //check if the image size is bigger than 2Mo
        errors.push(el?.uid)
      }
    })
    if (errors.length > 0) { //return error if there is images bigger than 2Mo
      setErrorSize(true)
      return
    }
    if (file.status === "uploading") {
      setInitialLoading(true); // show loading;
    } else if (file.status === "done") {
      // upload success;
      setInitialLoading(false); // hide loading;
      if (file.response?.food?._id) {
        newFileList = file.response.food.images.map((image, i) => {
          return {
            uid: image._id,
            status: "done",
            url: image.url,
            name: `${file.response?.food?.name} - ${i + 1}`,
          };
        });
        // Edit the food locally;
        const newFood = {
          ...food,
          images: file.response.food.images,
        };

        const category = categories.find(
          (c) => c.categoryId === newFood.categoryId
        );
        dispatch(editFoodAction(newFood));

        // edit the food  item inside the foods array inside the category;
        const newFoods = arrUpdateByKeyValue({
          arr: category.foods,
          key: "foodId",
          value: food.foodId,
          newElement: newFood,
        });
        const newCategory = {
          ...category,
          foods: newFoods,
        };
        dispatch(editCategoryAction(newCategory));

        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
      }
    } else if (file.status === "error") {
      setInitialLoading(false); // hide loading;
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
    if (fileList.length <= 3) {
      setFileList(newFileList);
    } else {
      message.error(intl.formatMessage({ id: "max3Images" }));
    }
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const beforeUpload = (file) => {
    if (!acceptedTypes.includes(file?.type) || !file) {
      message.error("You can only upload JPG/PNG file!");
      return false;
    }
    if (file.type?.includes("image")) {
      if (file.size / 1000000 > 2.2) {
        message.error(intl.formatMessage({ id: "limit2Mb" }));
        return false;
      }
    }
    if (fileList.length >= 3) {
      return false;
    }
    return true;
  };

  // Delete image;
  const deleteImage = async (e) => {
    setInitialLoading(true);
    try {
      const data = {
        food: {
          images: food.images.filter((img) => img.url !== e.url),
        },
      };
      const res = await postUpdateFood(food.foodId, data);
      if (res.data?.status === "success") {
        const newFood = {
          ...food,
          ...data.food,
        };
        const category = categories.find(
          (c) => c.categoryId === newFood.categoryId
        );

        dispatch(editFoodAction(newFood));
        // edit the food  item inside the foods array inside the category;
        const newFoods = arrUpdateByKeyValue({
          arr: category.foods,
          key: "foodId",
          value: food.foodId,
          newElement: newFood,
        });
        const newCategory = {
          ...category,
          foods: newFoods,
        };
        dispatch(editCategoryAction(newCategory));
      }
      setInitialLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
    } catch (error) {
      setInitialLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      setInitialLoading(true);
      let sticker = "NON";
      if (values.isFoodNew) {
        sticker = "New";
      } else if (values.isChef) {
        sticker = "Chef";
      } else if (values.isPopular) {
        sticker = "Papular";
      }
      //check if it waiter or owner to update the foodQuantities
      let newQuantities
      if (role === "super") {
        newQuantities = checkIsWithQty(values?.quantities)
      } else {
        if (food?.quantities?.length === 0 || !food?.quantities) {
          const updatedQty = [{ ...values?.quantities[0] }]
          newQuantities = checkIsWithQty(updatedQty)
        } else {
          const updatedQty = arrUpdateByKeyValue({
            arr: food?.quantities,
            key: "branchId",
            value: allBranches[0]?._id,
            newElement: values?.quantities[0]
          });
          newQuantities = checkIsWithQty(updatedQty)
        }
      }
      const data = {
        food: {
          ...values,
          quantities: newQuantities,
          sticker,
          suggestedIds: suggestedFoods
        },
      };
      if (!values?.description) {
        data.food.description = 'NON'
      }
      if (!values?.description_en) {
        data.food.description_en = 'NON'
      }
      if (!sticker) {
        delete data.food.sticker;
      }
      if (food?.foodId) {
        // edit food;
        const res = await postUpdateFood(food.foodId, data);
        if (res.data?.status === "success") {
          const newFood = {
            ...food,
            ...data.food,
          };

          const category = categories.find(
            (c) => c.categoryId === newFood.categoryId
          );

          dispatch(editFoodAction(newFood));
          // check if the category had changed;
          if (food?.categoryId !== newFood?.categoryId) {
            // first remove the food from the old category;
            const oldCategory = categories.find(
              (c) => c.categoryId === food.categoryId
            );
            // remove this food from the old category;
            const newFoods = arrRemoveByKeyValue({
              arr: oldCategory.foods,
              key: "foodId",
              value: food.foodId,
            });
            const newCategory = {
              ...oldCategory,
              foods: newFoods,
            };
            dispatch(editCategoryAction(newCategory));

            // second add the food to the new category;
            const newFoods1 = arrAdd({
              arr: category.foods,
              newElement: newFood,
            });
            const newCategory1 = {
              ...category,
              foods: newFoods1,
            };
            dispatch(editCategoryAction(newCategory1));
          } else {
            // if the category has not changed;
            // edit the food  item inside the foods array inside the category;
            const newFoods = arrUpdateByKeyValue({
              arr: category.foods,
              key: "foodId",
              value: food.foodId,
              newElement: newFood,
            });
            const newCategory = {
              ...category,
              foods: newFoods,
            };
            dispatch(editCategoryAction(newCategory));
          }
        }
      } else {
        // add new Food;
        data.food.restaurantId = restaurant._id;

        const res = await postAddFood(data);
        if (res?.data?.food?._id) {
          const newFood = {
            ...res.data.food,
            foodId: res.data.food._id,
          };
          const category = categories.find(
            (c) => c.categoryId === newFood.categoryId
          );

          dispatch(addFoodAction(newFood));
          // edit the food  item inside the foods array inside the category;
          const newFoods = arrAdd({
            arr: category.foods,
            newElement: newFood,
          });
          const newCategory = {
            ...category,
            foods: newFoods,
          };
          dispatch(editCategoryAction(newCategory));
          setIsNew(false);
          history(`/foods/${newFood?.foodId}`);
          setFood(newFood);
        }
      }

      setLoading(false);
      setInitialLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
    } catch (error) {
      console.log(error)
      setLoading(false);
      setInitialLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const toggleModifier = async (modifierId, method) => {
    try {
      setLoadingModifiers(true);
      setInitialLoading(true);

      const data = {
        food: {},
      };
      if (method === "ADD") {
        let modifierIds = [];
        if (food.modifiers) {
          modifierIds = food.modifiers.map((mod) => mod._id);
        }
        modifierIds.push(modifierId);
        data.food.modifierIds = modifierIds;
      } else if (method === "REMOVE") {
        data.food.modifierIds = food.modifiers
          .filter((mod) => mod._id !== modifierId)
          .map((mod) => mod._id);
      }

      const res = await postUpdateFood(food.foodId, data);
      if (res.data?.status === "success") {
        const newModifiers = modifiers.filter((mod) =>
          data.food.modifierIds.includes(mod._id)
        );
        const newFood = {
          ...food,
          modifiers: newModifiers,
        };
        const category = categories.find(
          (c) => c.categoryId === newFood.categoryId
        );

        dispatch(editFoodAction(newFood));
        // edit the food  item inside the foods array inside the category;
        const newFoods = arrUpdateByKeyValue({
          arr: category.foods,
          key: "foodId",
          value: food.foodId,
          newElement: newFood,
        });
        const newCategory = {
          ...category,
          foods: newFoods,
        };
        dispatch(editCategoryAction(newCategory));
      }

      setLoadingModifiers(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
    } catch (error) {
      setLoadingModifiers(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  function tagRender(props, color) {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={color}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
        className='my-font'
      >
        {label}
      </Tag>
    );
  }

  // delete food;
  const deleteFood = async () => {
    try {
      setLoading(true);
      setInitialLoading(true);
      const res = await postDeleteFood(food.foodId);
      const category = categories.find((c) => c.categoryId === food.categoryId);
      if (res.data?.status === "success") {
        // update food array;
        dispatch(deleteFoodAction(food.foodId));

        const newFoods = arrRemoveByKeyValue({
          arr: category.foods,
          key: "foodId",
          value: food.foodId,
        });
        const newCategory = {
          ...category,
          foods: newFoods,
        };
        dispatch(editCategoryAction(newCategory));
      }

      setLoading(false);
      setInitialLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      history(-1);
    } catch (error) {
      setLoading(false);
      setInitialLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };


  const FoodsTree = categories.map((cat) => {
    return {
      title: rtl ? cat?.name : cat?.name_en,
      value: cat?.categoryId,
      key: cat?.categoryId,
      children: foods
        ?.filter(
          (fd) =>
            fd?.categoryId === cat?.categoryId &&
            fd?.foodId !== food?.foodId &&
            !suggestedFoods.map((el) => el?.foodId).includes(fd?.foodId)
        )
        .map((food) => {
          return {
            title: rtl ? food?.name : food?.name_en,
            value: food?.foodId,
            key: food?.foodId,
          };
        }),
    };
  });
  const handleChangeFoodTrees = (newValue) => {
    setSuggestedFoods(newValue);
  };
  const tProps = {
    treeData: FoodsTree,
    value: suggestedFoods,
    onChange: handleChangeFoodTrees,
    treeCheckable: true,
    showCheckedStrategy: SHOW_ALL,
    placeholder: intl.formatMessage({ id: "suggestedFood" }),
    style: {
      width: "100%",
    },
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        flexDirection: "column",
      }}
    >
      {/* Image preview */}
      <Modal
        visible={previewVisible}
        title={rtl ? food?.name : food?.name_en}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
      >
        <img alt={food?.name} style={{ width: "100%" }} src={previewImage} />
      </Modal>
      <div
        style={{
          backgroundColor: COLORS.white,
          // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          borderRadius: 6,
          marginTop: 50,
          width: "92%",
          marginRight: "auto",
          marginLeft: "auto",
          padding: 15,
        }}
        className='light-shadow'
      >
        <Row
          style={{
            position: "relative",
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <Title
            level={3}
            className='pointer'
            onClick={() =>
              isNew
                ? history(`/foods`)
                : history(`/foods?category=${food?.categoryId}`)
            }
            style={{ position: "absolute", zIndex: 0, right: -20 }}
          >
            {rtl ? (
              <IoArrowForwardOutline className='pointer' />
            ) : (
              <IoArrowBackOutline className='pointer' />
            )}
          </Title>
        </Row>
        <Spin spinning={initialLoading}>
          <Form
            name='food-form'
            form={form}
            className='form-container'
            layout='vertical'
            onFinish={onFinish}
            initialValues={{
              name: food?.name,
              price: food?.price || "",
              name_en: food?.name_en || "",
              description: food?.description && food?.description !== 'NON' ? food.description : "",
              description_en: food?.description_en && food?.description_en !== 'NON' ? food.description_en : "",
              cost: food?.cost || "",
              code: food?.code || "",
              categoryId: food?.categoryId || "",
              calories: food?.calories || "",
              isFoodNew: food?.sticker === "New",
              isFoodStar: food?.isFoodStar,
              isFoodSoon: food?.isFoodSoon,
              isBorder: food?.isBorder || false,
              quantities: getFormattedQuantities({ quantities: food?.quantities, role: role, allBranches })
              ,
              isBigFont: food?.isBigFont || false,
              isSpecial: food?.isSpecial || false,
              isChef: food?.sticker === "Chef",
              isPopular: food?.sticker === "Papular",
              notAvailableIn: food?.notAvailableIn || [],
              allergies: food?.allergies || [],
              suggestedIds: food?.suggestedIds || [],
            }}
          >
            {() => {
              return (
                <>


                  <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                    {/* Title */}
                    <Col md={12} sm={24} xs={24}>
                      <Title className='my-font' level={4}>
                        {intl.formatMessage({ id: "basicInfo" })}
                      </Title>
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                    {/* Names */}
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        label={intl.formatMessage({ id: "nameAr" })}
                        className='mb-1'
                        name='name'
                        style={{ borderRadius: 20 }}
                        rules={[
                          {
                            required: true,
                            message: intl.formatMessage({ id: "errorname" }),
                          },
                        ]}
                      >
                        <Input
                          className={"input"}
                          style={{ borderRadius: 5, width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        label={intl.formatMessage({ id: "nameEn" })}
                        className='mb-1'
                        name='name_en'
                        rules={[
                          {
                            required: true,
                            message: intl.formatMessage({ id: "errorname" }),
                          },
                        ]}
                      >
                        <Input className={"input"} style={{ borderRadius: 5 }} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className='mt-4' gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                    {/* Descriptions */}
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        label={intl.formatMessage({ id: "descriptionAr" })}
                        className='mb-1'
                        name='description'
                        style={{ borderRadius: 20 }}
                      >
                        <Input.TextArea
                          className={"input"}
                          placeholder={intl.formatMessage({ id: "descriptionAr" })}
                          style={{ borderRadius: 5 }}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        label={intl.formatMessage({ id: "descriptionEn" })}
                        className='mb-1'
                        name='description_en'
                      >
                        <Input.TextArea
                          className={"input"}
                          placeholder={intl.formatMessage({ id: "descriptionEn" })}
                          style={{ borderRadius: 5 }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider />

                  <Row className='mt-4' gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    {/* Prices */}
                    <Col md={6} sm={24} xs={24}>
                      <Form.Item
                        label={intl.formatMessage({ id: "price" })}
                        className='mb-1'
                        name='price'
                        style={{ borderRadius: 20 }}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <InputNumber
                          className={"input"}
                          placeholder={"9.9"}
                          min={0}
                          style={{ borderRadius: 5, width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={6} sm={24} xs={24}>
                      <Form.Item
                        label={intl.formatMessage({ id: "calories" })}
                        className='mb-1'
                        name='calories'
                      >
                        <InputNumber
                          className={"input"}
                          placeholder='99'
                          min={0}
                          style={{ borderRadius: 5, width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        label={intl.formatMessage({ id: "specialCode" })}
                        className='mb-1'
                        name='code'
                      >
                        <Input
                          placeholder={"#C5"}
                          className={"input"}
                          style={{ borderRadius: 5, width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className='mt-4' gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    {/* cost & category */}
                    <Col md={6} sm={24} xs={24}>
                      {role === "super" ? (
                        <Form.Item
                          label={intl.formatMessage({ id: "cost" })}
                          className='mb-1'
                          name='cost'
                        >
                          <InputNumber
                            className={"input"}
                            placeholder={"7"}
                            min={0}
                            style={{ borderRadius: 5, width: "100%" }}
                          />
                        </Form.Item>
                      ) : null}
                    </Col>
                    <Col md={6} sm={24} xs={24}>
                      <Form.Item
                        label={intl.formatMessage({ id: "categoryy" })}
                        className='mb-1'
                        name='categoryId'
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select className='my-font'>
                          {categories.map((category) => {
                            return (
                              <Option
                                key={category.categoryId}
                                value={category.categoryId}
                                className='my-font'
                              >
                                {category?.name_en
                                  ? rtl
                                    ? category.name
                                    : category.name_en
                                  : category.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        label={intl.formatMessage({ id: "foodAllergies" })}
                        className='mb-1'
                        name='allergies'
                      >
                        <Select
                          size='large'
                          className='my-font'
                          allowClear
                          mode='multiple'
                        >
                          {Object.values(FoodAllergyTYPES).map((allergy) => {
                            return (
                              <Option
                                key={allergy}
                                value={allergy}
                                className='my-font'
                              >
                                {intl.formatMessage({ id: allergy })}
                                <span className='mx-2'>
                                  {getFoodAllergyIcon(allergy, COLORS.primary)}
                                </span>
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider />
                  <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                    {/* Title */}
                    <Col md={12} sm={24} xs={24}>
                      <Title className='my-font' level={4}>
                        {intl.formatMessage({ id: "quantity" })}
                      </Title>
                    </Col>
                  </Row>

                  <Form.List hasFeedback name='quantities' rules={[{ required: true }]}>
                    {(fields) => {
                      return (
                        <>
                          {fields.map(({ key, name, fieldKey, ...restField }) => {
                            return (
                              <Row key={key}>
                                <Col md={6} sm={24} xs={24}>
                                  <Form.Item
                                    {...restField}
                                    label={<label >{intl.formatMessage({ id: "branchName" })}</label>}
                                    name={[name, "branchId"]}
                                    fieldKey={[fieldKey, "branchId"]}

                                  >
                                    <Text >
                                      {role === "super" ?
                                        getBranchNameById(rtl, allBranches, form.getFieldValue(['quantities', name, 'branchId'])) :
                                        rtl ? allBranches[0]?.name : allBranches[0]?.name_en
                                      } </Text>
                                  </Form.Item>
                                </Col>
                                <Col md={6} sm={24} xs={24}>
                                  <Form.Item
                                    {...restField}
                                    label={<label >{intl.formatMessage({ id: "isWithQuantity" })}</label>}
                                    name={[name, "isWithQuantity"]}
                                    fieldKey={[fieldKey, "isWithQuantity"]}
                                    valuePropName='checked'
                                  >
                                    <Switch
                                      style={{ marginBottom: 0 }}
                                      checkedChildren={
                                        <p>
                                          {intl.formatMessage({
                                            id: "yes",
                                          })}
                                        </p>
                                      }
                                      unCheckedChildren={
                                        <p>
                                          {intl.formatMessage({
                                            id: "no",
                                          })}
                                        </p>
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                                <Col md={6} sm={24} xs={24} >
                                  <Form.Item
                                    {...restField}
                                    label={<label >{intl.formatMessage({ id: "quantity" })}</label>}
                                    name={[name, "qty"]}
                                    fieldKey={[fieldKey, "qty"]}
                                    rules={[
                                      {
                                        required: form.getFieldValue(['quantities', name, 'isWithQuantity']),
                                        message: intl.formatMessage({ id: "errorQty" }),
                                      },
                                    ]}
                                  >
                                    <Input
                                      style={{ width: "80%" }}
                                      type="number"
                                      min={0}
                                      disabled={!form.getFieldValue(['quantities', name, 'isWithQuantity'])}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col md={6} sm={24} xs={24}>
                                  {form.getFieldValue(['quantities', name, 'isWithQuantity']) &&
                                    <Form.Item
                                      {...restField}
                                      label={<label >{intl.formatMessage({ id: "status" })}</label>}
                                    >
                                      {form.getFieldValue(['quantities', name, 'qty']) > 0 ?
                                        <Tag color="green">{intl.formatMessage({ id: "inStock" })}</Tag> :
                                        <Tag color="red">{intl.formatMessage({ id: "soldOut" })}</Tag>
                                      }
                                    </Form.Item>
                                  }
                                </Col>
                              </Row>
                            );
                          })}
                        </>
                      );
                    }}
                  </Form.List>



                  <Divider />

                  <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                    {/* Menu engineer */}
                    <Col md={12} sm={24} xs={24}>
                      <Title className='my-font' level={4}>
                        {intl.formatMessage({ id: "menuAnalyticsTech" })}
                      </Title>
                    </Col>
                  </Row>
                  <Row className='mt-4' gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col md={24} sm={24} xs={24}>
                      <Text type='secondary' className='my-font'>
                        {intl.formatMessage({ id: "suggestedFoodDes" })}
                      </Text>
                      {/* <Form.Item
                        label={intl.formatMessage({ id: "suggestedFood" })}
                        className='mb-1'
                        name='suggestedIds'
                        style={{ borderRadius: 20 }}
                      >
                        <Select
                          mode='multiple'
                          className='my-font'
                          showArrow
                          allowClear
                          tagRender={(p) => tagRender(p, "green")}
                          options={foods
                            ?.filter((newFood) => food?.foodId !== newFood?.foodId)
                            ?.map((newFood) => {
                              return {
                                label: newFood.name,
                                value: newFood?.foodId,
                                color: "green",
                              };
                            })}
                        />
                      </Form.Item> */}
                    </Col>
                  </Row>
                  <Row className='mt-4' gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col xs={24}>
                      <TreeSelect {...tProps} />
                    </Col>
                  </Row>

                  {/* <Divider /> */}
                  <Title level={4} className='my-font mt-4'>
                    {intl.formatMessage({ id: "stickers" })}
                  </Title>
                  <Row className='' gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    {/* Stickers */}
                    <Col md={4} sm={12} xs={12}>
                      <Form.Item
                        label={intl.formatMessage({ id: "new" })}
                        className='mb-1'
                        name='isFoodNew'
                        valuePropName='checked'
                        style={{ borderRadius: 20 }}
                      >
                        <Switch
                          onChange={(e) => {
                            if (e) {
                              form.setFieldsValue({
                                isChef: false,
                                isPopular: false,
                              });
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col md={4} sm={12} xs={12}>
                      <Form.Item
                        label={intl.formatMessage({ id: "chief" })}
                        className='mb-1'
                        name='isChef'
                        valuePropName='checked'
                        style={{ borderRadius: 20 }}
                      >
                        <Switch
                          onChange={(e) => {
                            if (e) {
                              form.setFieldsValue({
                                isFoodNew: false,
                                isPopular: false,
                              });
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={4} sm={12} xs={12}>
                      <Form.Item
                        label={intl.formatMessage({ id: "papular" })}
                        className='mb-1'
                        name='isPopular'
                        valuePropName='checked'
                        style={{ borderRadius: 20 }}
                      >
                        <Switch
                          onChange={(e) => {
                            if (e) {
                              form.setFieldsValue({
                                isFoodNew: false,
                                isChef: false,
                              });
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={6} sm={12} xs={12}>
                      <Form.Item
                        label={intl.formatMessage({ id: "border" })}
                        className='mb-1'
                        name='isBorder'
                        valuePropName='checked'
                        style={{ borderRadius: 20 }}
                      >
                        <Switch />
                      </Form.Item>
                    </Col>
                    <Col md={6} sm={12} xs={12}>
                      <Form.Item
                        label={intl.formatMessage({ id: "bigFont" })}
                        className='mb-1'
                        name='isBigFont'
                        valuePropName='checked'
                        style={{ borderRadius: 20 }}
                      >
                        <Switch />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className='mt-1' gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col md={4} sm={12} xs={12}>
                      <Form.Item
                        label={intl.formatMessage({ id: "isFoodStar" })}
                        className='mb-1'
                        name='isFoodStar'
                        valuePropName='checked'
                        style={{ borderRadius: 20 }}
                      >
                        <Switch />
                      </Form.Item>
                    </Col>
                    <Col md={4} sm={12} xs={12}>
                      <Form.Item
                        label={intl.formatMessage({ id: "isFoodSoon" })}
                        className='mb-1'
                        tooltip={<Text className="my-font">{intl.formatMessage({id: 'soonExplain'})}</Text>}
                        name='isFoodSoon'
                        valuePropName='checked'
                        style={{ borderRadius: 20 }}
                      >
                        <Switch />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider />
                  {role === "super" ? (
                    <>
                      <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                        {/* Menu engineer */}
                        <Col md={12} sm={24} xs={24}>
                          <Title className='my-font' level={4}>
                            {intl.formatMessage({ id: "notInBranches" })}
                          </Title>
                          <Text className='my-font'>
                            {intl.formatMessage({ id: "chooseBranchesToHide" })}
                          </Text>
                        </Col>
                      </Row>
                      <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                        <Col md={24} sm={24} xs={24}>
                          <Form.Item className='mb-1 mt-1' name='notAvailableIn'>
                            <Select
                              mode='multiple'
                              className='my-font'
                              showArrow
                              tagRender={(p) => tagRender(p, "red")}
                              options={allBranches.map(({ branch }) => ({
                                label: branch.name,
                                value: branch._id,
                              }))}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                    </>
                  ) : null}
                  {integrations?.posName && integrations?.posName !== "NON" ? (
                    <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                      {/* Title */}
                      <Col md={24} sm={24} xs={24}>
                        <MenuIsSync />
                      </Col>
                    </Row>
                  ) : null}
                  <Row justify='start' className='mt-5' align='middle'>
                    <Form.Item>
                      <CustomButton
                        htmlType='submit'
                        text={intl.formatMessage({ id: "save" })}
                        className={`btnRegister btn-text border-8 px-5 `}
                        loading={loading}
                        disabled={loading || (isNew && integrations?.posName &&
                          integrations?.posName !== "NON" &&
                          integrations?.posSyncMenu === true
                            ? true
                            : false)}
                        type='primary'
                      />
                    </Form.Item>
                    <Form.Item>
                      <CustomButton
                        type='secondary'
                        text={intl.formatMessage({ id: "back" })}
                        className={` border-8 mx-5 px-5`}
                        loading={loading}
                        disabled={loading}
                        onClick={() => history(-1)}
                      />
                    </Form.Item>
                    <Form.Item>
                      <Popconfirm
                        title={
                          <Text className='my-font'>
                            {intl.formatMessage({ id: "confirmDeleteFoodTitle" })}
                          </Text>
                        }
                        okText={intl.formatMessage({ id: "delete" })}
                        okButtonProps={{
                          style: { color: COLORS.danger, fontFamily: "Cairo" },
                        }}
                        okType='text'
                        cancelText={intl.formatMessage({ id: "cancel" })}
                        cancelButtonProps={{
                          style: { border: "none", fontFamily: "Cairo" },
                        }}
                        onConfirm={deleteFood}
                        disabled={role !== "super" || !food?.foodId}
                      >
                        <Text
                          disabled={role !== "super" || !food?.foodId}
                          type='danger'
                          className='pointer my-font'
                        >
                          {intl.formatMessage({ id: "delete" })}
                        </Text>
                      </Popconfirm>
                    </Form.Item>
                  </Row>
                </>
              )
            }}
          </Form>

        </Spin>
      </div>
      <div
        style={{
          backgroundColor: COLORS.white,
          // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          borderRadius: 6,
          marginTop: 40,
          width: "92%",
          marginRight: "auto",
          marginLeft: "auto",
          padding: 15,
        }}
        className='light-shadow'
      >
        <Spin spinning={initialLoading}>
          <Title className='my-font' level={4}>
            {intl.formatMessage({ id: "modifiers" })}
          </Title>
          {integrations?.posName && integrations?.posName !== "NON" ? <MenuIsSync /> : null}
          <Row>
            {modifiers.map((modifier) => {
              let select = null;
              if (food && food.modifiers && food.modifiers.length > 0) {
                select = food.modifiers.find((mod) => mod._id === modifier._id);
              }
              return (
                <CustomButton
                  key={modifier._id}
                  type={select?._id ? "primary" : "secondary"}
                  className='mx-2 px-5 border-8 mt-2'
                  text={
                    modifier.privateName
                      ? modifier.privateName
                      : rtl
                        ? modifier.name
                        : modifier.name_en
                  }
                  icon={select?._id ? <CheckOutlined /> : <CloseOutlined />}
                  loading={loadingModifiers}
                  onClick={() =>
                    toggleModifier(modifier._id, select?._id ? "REMOVE" : "ADD")
                  }
                  disabled={loadingModifiers || isNew || integrations?.posName &&
                    integrations?.posName !== "NON" &&
                    integrations?.posSyncMenu === true
                      ? true
                      : false}
                  
                />
              );
            })}
          </Row>
        </Spin>
      </div>
      <div
        style={{
          backgroundColor: COLORS.white,
          // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          borderRadius: 6,
          marginTop: 40,
          width: "92%",
          marginRight: "auto",
          marginLeft: "auto",
          padding: 15,
          position: "relative"
        }}
        className='light-shadow'
      >
        <Spin spinning={initialLoading}>
          <Title className='my-font' level={4}>
            {intl.formatMessage({ id: "photos" })}
          </Title>
          <div style={{ position: "absolute", top: 15, display: "flex", justifyContent: "center", width: "100%" }}>
            {errorSize ? <Text className='my-font' style={{ color: COLORS.danger }}>
              {intl.formatMessage({ id: "limit2Mb" })}
            </Text> : null}
          </div>
          <Dragger
            action={`${apiUrl}/foods-upload-image?restaurantId=${restaurant._id}&slug=${restaurant.slug}`}
            headers={{ authorization: "Bearer " + user.token }}
            name='picture'
            listType='picture'
            fileList={fileList}
            onChange={handleChangeImages}
            onPreview={onPreview}
            beforeUpload={beforeUpload}
            multiple={false}
            disabled={isNew}
            accept='image/jpg,image/jpeg,image/png,video/mp4'
            onRemove={deleteImage}
            data={{ id: food?.foodId }}
            isImageUrl={(e) => {
              let isVideo =
                e?.url?.split(".")[e?.url?.split(".")?.length - 1] === "mp4"
                  ? true
                  : false;
              return !isVideo;
            }}
            itemRender={(defaultEle, file, fileList, fun) => {
              let isVideo =
                file?.url?.split(".")[file?.url?.split(".")?.length - 1] ===
                  "mp4"
                  ? true
                  : false;
              if (isVideo) {
                return (
                  <div
                    style={{
                      border: "1px solid #d9d9d9",
                      height: "120px",
                      padding: "8px",
                      borderRadius: "2px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    className='mt-2'
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <video width='200' height='100' controls>
                        <source src={`${file.url}`} type='video/mp4' />
                        Your browser does not support the video tag.
                      </video>
                      <Text className='my-font mx-5'>{file?.name}</Text>
                    </div>
                    <DeleteOutlined
                      onClick={() => fun.remove()}
                      style={{ color: "rgba(0, 0, 0, 0.45)" }}
                    />
                  </div>
                );
              } else {
                return defaultEle;
              }
            }}
          >

            {fileList.length < 3 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <InboxOutlined
                  style={{ color: errorSize ? COLORS.danger : COLORS.primary, fontSize: "2rem" }}
                  className='mx-3'
                />
                <Text
                  style={{ fontSize: "1rem", color: errorSize ? COLORS.danger : COLORS.primary }}
                  className='my-font'
                >
                  {intl.formatMessage({ id: "addPhoto" })}
                </Text>
              </div>
            ) : (
              <Text className='my-font'>
                {intl.formatMessage({ id: "max3Images" })}
              </Text>
            )}
          </Dragger>
        </Spin>
      </div>

    </div>
  );
};

export default OneFoodView;
