import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  Row,
  Col,
  Typography,
  Switch,
  Divider,
  Image,
  Space,
  InputNumber,
} from "antd";
import CustomButton from "../../../Components/CustomButton";
import { postUpdateRestaurant } from "../../../API/fetch";
import openNotification from "../../../Components/Notifications";
import { setRestaurantAction } from "../../../redux/reducers/restaurantReducer";
import TABBY from "../../../assets/images/Companies/tabby.jpg";
import TAMARA from "../../../assets/images/Companies/tamara.jpg";
const { Title, Text } = Typography;
const PaymentOptions = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { restaurant } = useSelector((state) => state.restaurant);
  const { rtl } = useSelector((state) => state.settings);
  const { waiter } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const data = {
        restaurant: {
          ...values,
        },
        code: waiter.code,
      };
      const res = await postUpdateRestaurant(restaurant._id, data);
      const newRest = {
        ...restaurant,
        ...values,
      };
      dispatch(setRestaurantAction(newRest));
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
    } catch (error) {
      console.log(error);
      setLoading(false); // hide loading;
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };
  return (
    <div
      style={{
        paddingRight: 20,
        paddingLeft: 20,
        paddingTop: 20,
        paddingBottom: 20,
      }}
    >
      <Form
        name='food-form'
        form={form}
        className='form-container'
        layout='vertical'
        onFinish={onFinish}
        initialValues={{
          payOnlineDineIn: restaurant?.payOnlineDineIn,
          payOnlinePickup: restaurant?.payOnlinePickup,
          payOnlineDelivery: restaurant?.payOnlineDelivery,
          payCashDineIn: restaurant?.payCashDineIn,
          payCashPickup: restaurant?.payCashPickup,
          payCashDelivery: restaurant?.payCashDelivery,
          splitPaymentChoice: restaurant?.splitPaymentChoice,
          doesTabbyActive: restaurant?.doesTabbyActive,
          minimumTabbyAmount: restaurant?.minimumTabbyAmount,
        }}
        style={{
          marginRight: 10,
          marginLeft: 10,
        }}
      >
        {() => {
          return (
            <>
              <Row className='mb-5' gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                {/* Title */}
                <Col md={24} sm={24} xs={24}>
                  <Title className='my-font' level={4}>
                    {intl.formatMessage({ id: "paymentOptions" })}
                  </Title>

                  <Text className='my-font' type='secondary'>
                    *{" "}
                    {intl.formatMessage(
                      {
                        id: "paymentPercentageFatoorah",
                      },
                      {
                        MADA: restaurant?.percentageForIWaiterMADA || "1.9",
                        VISA: restaurant?.percentageForIwaiter || "2.4",
                      }
                    )}
                  </Text>
                </Col>
                <Col md={24} sm={24} xs={24}>
                  <Text className='my-font' type='secondary'>
                    *{" "}
                    {intl.formatMessage({
                      id: "tabbyDescription",
                    })}
                  </Text>
                </Col>
              </Row>
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                <Col span={8}>
                  <Text></Text>
                </Col>
                <Col span={8}>
                  <Title level={5} className='my-font'>
                    {" "}
                    {intl.formatMessage({ id: "onlinePayment" })}
                  </Title>
                </Col>
                <Col span={8}>
                  <Title level={5} className='my-font'>
                    {" "}
                    {intl.formatMessage({ id: "cashPayment" })}
                  </Title>
                </Col>
              </Row>
              <Row
                style={{ borderBottom: "2px solid #f0f2f5" }}
                gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
              >
                <Col span={8}>
                  <Title level={5} className='my-font'>
                    {intl.formatMessage({ id: "dineIn" })}
                  </Title>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className='mb-1'
                    name='payOnlineDineIn'
                    valuePropName='checked'
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Switch
                      className='mb-1 mx-2'
                      disabled={
                        !restaurant?.wallet ||
                        (restaurant?.hasOwnProperty("canEditPaymentType") &&
                          !restaurant?.canEditPaymentType)
                      }
                      onChange={(e) => {
                        if (!e) {
                          form.setFieldsValue({
                            payCashDineIn: true,
                          });
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className='mb-1'
                    name='payCashDineIn'
                    valuePropName='checked'
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Switch
                      disabled={
                        restaurant?.hasOwnProperty("canEditPaymentType") &&
                        !restaurant?.canEditPaymentType
                      }
                      className='mb-1 mx-2'
                      onChange={(e) => {
                        if (!e && restaurant?.wallet) {
                          form.setFieldsValue({
                            payOnlineDineIn: true,
                          });
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row
                style={{ borderBottom: "2px solid #f0f2f5" }}
                gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
              >
                <Col span={8}>
                  <Title level={5} className='my-font'>
                    {intl.formatMessage({ id: "pickupOrder" })}
                  </Title>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className='mb-1'
                    name='payOnlinePickup'
                    valuePropName='checked'
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Switch
                      className='mb-1 mx-2'
                      disabled={
                        !restaurant?.wallet ||
                        (restaurant?.hasOwnProperty("canEditPaymentType") &&
                          !restaurant?.canEditPaymentType)
                      }
                      onChange={(e) => {
                        if (!e) {
                          form.setFieldsValue({
                            payCashPickup: true,
                          });
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className='mb-1'
                    name='payCashPickup'
                    valuePropName='checked'
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Switch
                      className='mb-1 mx-2'
                      disabled={
                        restaurant?.hasOwnProperty("canEditPaymentType") &&
                        !restaurant?.canEditPaymentType
                      }
                      onChange={(e) => {
                        if (!e && restaurant?.wallet) {
                          form.setFieldsValue({
                            payOnlinePickup: true,
                          });
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row
                style={{ borderBottom: "8px solid #f0f2f5" }}
                gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
              >
                <Col span={8}>
                  <Title level={5} className='my-font'>
                    {intl.formatMessage({ id: "delivery" })}
                  </Title>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className='mb-1'
                    name='payOnlineDelivery'
                    valuePropName='checked'
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Switch
                      className='mb-1 mx-2'
                      disabled={
                        !restaurant?.wallet ||
                        (restaurant?.hasOwnProperty("canEditPaymentType") &&
                          !restaurant?.canEditPaymentType)
                      }
                      onChange={(e) => {
                        if (!e && restaurant?.wallet) {
                          form.setFieldsValue({
                            payCashDelivery: true,
                          });
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className='mb-1'
                    name='payCashDelivery'
                    valuePropName='checked'
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Switch
                      className='mb-1 mx-2'
                      disabled={
                        restaurant?.hasOwnProperty("canEditPaymentType") &&
                        !restaurant?.canEditPaymentType
                      }
                      onChange={(e) => {
                        if (!e) {
                          form.setFieldsValue({
                            payOnlineDelivery: true,
                          });
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row
                style={{ marginTop: 15 }}
                gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
              >
                <Col span={8}>
                  <Title level={5} className='my-font'>
                    {intl.formatMessage({ id: "orderNowPayLater" })}
                  </Title>
                </Col>
                <Col span={8}>
                  <Title level={5} className='my-font'>
                    {intl.formatMessage({ id: "status" })}
                  </Title>
                </Col>
                <Col span={8}>
                  <Title level={5} className='my-font'>
                    {intl.formatMessage({ id: "minimumAmountInvoice" })}
                  </Title>
                </Col>
              </Row>
              <Row
                style={{ borderBottom: "2px solid #f0f2f5", marginTop: 10 }}
                gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
              >
                <Col
                  span={8}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Space direction='horizontal' size='middle'>
                    <Image src={TABBY} width={20} height={20} preview={false} />
                    <Title level={5} className='my-font'>
                      {intl.formatMessage({ id: "tabby" })}
                    </Title>
                  </Space>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className='mb-1'
                    name='doesTabbyActive'
                    valuePropName='checked'
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Switch
                      className='mb-1 mx-2'
                      disabled={!restaurant?.wallet}
                      onChange={(e) => {
                        if (
                          e &&
                          parseInt(form.getFieldValue("minimumTabbyAmount")) ===
                            0
                        ) {
                          form.setFieldsValue({
                            minimumTabbyAmount: 1500,
                          });
                        } else if (!e) {
                          form.setFieldsValue({
                            minimumTabbyAmount: 0,
                          });
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className='mb-1'
                    name='minimumTabbyAmount'
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <InputNumber
                      className='mb-1 mx-2'
                      disabled={
                        !restaurant?.wallet ||
                        !form.getFieldValue("doesTabbyActive")
                      }
                      min={0}
                      max={1500}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row
                style={{ borderBottom: "2px solid #f0f2f5", marginTop: 10 }}
                gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
              >
                <Col
                  span={8}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Space direction='horizontal' size='middle'>
                    <Image
                      src={TAMARA}
                      width={20}
                      height={20}
                      preview={false}
                    />
                    <Title level={5} className='my-font'>
                      {intl.formatMessage({ id: "tamara" })}{" "}
                      <span
                        style={{
                          color: "#FF0000",
                          fontSize: 12,
                        }}
                      >
                        {intl.formatMessage({ id: "comingSoon" })}
                      </span>
                    </Title>
                  </Space>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className='mb-1'
                    name='doesTamaraActive'
                    valuePropName='checked'
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Switch className='mb-1 mx-2' disabled={true} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className='mb-1'
                    name='minimumTamaraAmount'
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <InputNumber
                      className='mb-1 mx-2'
                      disabled={true}
                      min={0}
                      max={1500}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {/* <Divider />
            <Row
              style={{ borderBottom: "2px solid #f0f2f5" }}
              gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
            >
              <Col span={8}>
                <Title level={5} className='my-font'>
                  {intl.formatMessage({ id: "splitPayments" })}
                </Title>
              </Col>
            <Col span={8}>
                <Form.Item
                  className='mb-1'
                  name='splitPaymentChoice'
                  valuePropName='checked'
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Switch
                    className='mb-1 mx-2'
                    disabled={!restaurant?.wallet}
                    
                  />
                </Form.Item>
              </Col>
              </Row> */}
              <Row justify='start' className='mt-5' align='middle'>
                <Form.Item>
                  <CustomButton
                    htmlType='submit'
                    text={intl.formatMessage({ id: "save" })}
                    className={`btnRegister btn-text border-8 px-5 `}
                    loading={loading}
                    disabled={loading}
                    type='primary'
                  />
                </Form.Item>
              </Row>
            </>
          );
        }}
      </Form>
    </div>
  );
};

export default PaymentOptions;
