

export const getRandomCodes = ({count, oldCodes}) => {
    let codes = [];
    for (let i = 0; i < count; i++) {
        let code = Math.random().toString(36).substring(2, 8).toUpperCase();
        if (oldCodes?.includes(code)) {
        code = Math.random().toString(36).substring(2, 8).toUpperCase();
        }
        codes.push(code);
    }
    return codes;
}