import React from "react";
import { useIntl } from "react-intl";
import { Col, Card, Typography, Statistic } from "antd";
import { IoTimeOutline } from "react-icons/io5";
const { Text } = Typography;

const CardTime = ({ myKey, averages }) => {
  const intl = useIntl();
  return (
    <Col md={6} sm={12} xs={24} className={`mx-2 my-2 border-8`}>
      <Card
        actions={[
          <Text className='my-font' type='secondary'>
            {averages[myKey]?.count} {intl.formatMessage({ id: "order" })}
          </Text>,
        ]}
        className='border-8 light-shadow'
      >
        <Statistic
          title={
            <Text className='my-font' type='secondary'>
              {intl.formatMessage({ id: myKey })}
            </Text>
          }
          value={averages[myKey]?.value?.toFixed(2)}
          precision={2}
          prefix={<IoTimeOutline style={{ position: "relative", top: 3 }} />}
          suffix={
            <Text style={{ fontSize: 14 }} className='my-font' type='secondary'>
              {intl.formatMessage({ id: "minutes" })}
            </Text>
          }
        />
      </Card>
    </Col>
  );
};

export default CardTime;
