const COLORS = {
    primary: '#0a2240',
    lightBlue: '#afe2e3',
    gray: '#EFF2F5',
    white: "#FFFFFF",
    black: '#212121',
    danger: '#FC4C3A',
    darkGray: '#dddddd',
    lightGray: '#f0f2f5',

}

export default COLORS;