import { FREE_OFFER_TYPES, PROMOTIONS_TYPES } from "../Types";

export const getPromotion = ({ promotion, foodPrice, specialPromotion }) => {
  // let promotion = '-';
  let hasPromotion = false;
  let hasSpecialPromotion = false;
  let promotionAmount = 0;
  let mark = "";
  let name = promotion ? promotion?.name : "";
  let specialPromotionAmount = 0;
  let markSpecial = "";
  let nameSpecial = specialPromotion ? specialPromotion?.name : "";
  let specialType = "";
  let roundDismal = false;
  if (promotion?.roundDismal) {
    roundDismal = true;
  }
  // let promotionValue ='0';
  if (promotion) {
    hasPromotion = true;
    switch (promotion?.promotionType) {
      case PROMOTIONS_TYPES.FIXED_PRICE:
        promotionAmount = promotion?.fixedPrice;
        mark = "=";
        break;
      case PROMOTIONS_TYPES.FIXED_DISCOUNT:
        promotionAmount = promotion?.fixedPrice;
        mark = "-";
        break;
      case PROMOTIONS_TYPES.FIXED_INCREASE:
        promotionAmount = promotion?.fixedPrice;
        mark = "+";
        break;
      case PROMOTIONS_TYPES.PERCENTAGE_DISCOUNT:
        if (roundDismal) {
          promotionAmount = parseFloat(
            (foodPrice * (promotion?.percentage / 100)).toFixed(0)
          );
        } else {
          promotionAmount = foodPrice * (promotion?.percentage / 100);
        }
        mark = "-";
        break;
      case PROMOTIONS_TYPES.PERCENTAGE_INCREASE:
        if (roundDismal) {
          promotionAmount = parseFloat(
            (foodPrice * (promotion?.percentage / 100)).toFixed(0)
          );
        } else {
          promotionAmount = foodPrice * (promotion?.percentage / 100);
        }
        mark = "+";
        break;
      default:
        promotionAmount = 0;
        break;
    }
  }

  if (specialPromotion) {
    hasSpecialPromotion = true;
    let newFoodPrice =
      mark === "-"
        ? foodPrice - promotionAmount
        : mark === "+"
        ? foodPrice + promotionAmount
        : mark === "="
        ? promotion?.fixedPrice
        : foodPrice;
    switch (specialPromotion?.specialOffer?.freeOfferType) {
      case FREE_OFFER_TYPES.PERCENTAGE_DISCOUNT:
        specialPromotionAmount =
          newFoodPrice *
          (parseFloat(specialPromotion?.specialOffer?.percentage) / 100);
        markSpecial = "-";
        specialType = FREE_OFFER_TYPES.PERCENTAGE_DISCOUNT;

        break;
      case FREE_OFFER_TYPES.FIXED_DISCOUNT:
        specialPromotionAmount = parseFloat(
          specialPromotion?.specialOffer?.fixedPrice
        );
        markSpecial = "-";
        specialType = FREE_OFFER_TYPES.FIXED_DISCOUNT;
        break;
      case FREE_OFFER_TYPES.FREE_ITEMS:
        specialPromotionAmount = newFoodPrice;
        markSpecial = "-";
        specialType = FREE_OFFER_TYPES.FREE_ITEMS;
        break;
      default:
        specialPromotionAmount = 0;
        break;
    }
  }

  let textSpecial = `${nameSpecial} (${markSpecial}${specialPromotionAmount?.toFixed(
    2
  )})`;
  let text = `${name} (${mark}${promotionAmount?.toFixed(2)})`;
  return {
    promotionText: text,
    amount: promotionAmount,
    finalMark: mark,
    specialPromotionText: textSpecial,
    amountSpecial: specialPromotionAmount,
    finalSpecialMark: markSpecial,
    hasPromotion,
    hasSpecialPromotion,
    specialType,
  };
};

//get promotions type values and label to use it in selectOptions
export const getPromotionTypesValues = (promotions, intl) => {
  let newPromotions = Object.values(PROMOTIONS_TYPES).map((type) => {
    return {
      value: type,
      label: intl.formatMessage({ id: type }),
    };
  });
  newPromotions?.unshift({
    value: "ALL",
    label: intl.formatMessage({ id: "all" }),
  });
  return newPromotions;
};

export const getPromotionsByType = (promotions, type) => {
  let newPromotions;
  switch (type) {
    case PROMOTIONS_TYPES.FIXED_PRICE:
      newPromotions = promotions?.filter(
        (promotion) => promotion?.promotionType === PROMOTIONS_TYPES.FIXED_PRICE
      );
      break;
    case PROMOTIONS_TYPES.FIXED_DISCOUNT:
      newPromotions = promotions?.filter(
        (promotion) =>
          promotion?.promotionType === PROMOTIONS_TYPES.FIXED_DISCOUNT
      );
      break;
    case PROMOTIONS_TYPES.PERCENTAGE_DISCOUNT:
      newPromotions = promotions?.filter(
        (promotion) =>
          promotion?.promotionType === PROMOTIONS_TYPES.PERCENTAGE_DISCOUNT
      );

      break;
    case PROMOTIONS_TYPES.FIXED_INCREASE:
      newPromotions = promotions?.filter(
        (promotion) =>
          promotion?.promotionType === PROMOTIONS_TYPES.FIXED_INCREASE
      );

      break;
    case PROMOTIONS_TYPES.PERCENTAGE_INCREASE:
      newPromotions = promotions?.filter(
        (promotion) =>
          promotion?.promotionType === PROMOTIONS_TYPES.PERCENTAGE_INCREASE
      );

      break;
    case PROMOTIONS_TYPES.SPECIAL_OFFER:
      newPromotions = promotions?.filter(
        (promotion) =>
          promotion?.promotionType === PROMOTIONS_TYPES.SPECIAL_OFFER
      );

      break;
    default:
      newPromotions = promotions;
  }
  return newPromotions;
};
