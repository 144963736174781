import React, { useState, useEffect } from "react";
import {
  Form,
  Select,
  Popconfirm,
  Typography,
  Divider,
  Space,
  Row,
  Col,
  Checkbox,
  message,
  Upload,
  Spin,
  Modal,
  InputNumber,
  Switch,

} from "antd";
import { InboxOutlined } from "@ant-design/icons";

import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import COLORS from "../../../Style/colors";
import CustomButton from "../../../Components/CustomButton";

import openNotification from "../../../Components/Notifications";

import { deleteAreaPhoto, updateReservationArea } from "../../../API/fetch";
import {
  setBranchAction,
  setBranchesAction,
} from "../../../redux/reducers/branchReducer";
import moment from "moment";
import apiUrl from "../../../API/config";
//components
import ReservationTerms from "./reservationForm/ReservationTerms";
import ReservationGeneral from "./reservationForm/ReservationGeneral";
import ReservationWorkDays from "./reservationForm/ReservationWorkDays";
import ReservationPrices from "./reservationForm/ReservationPrices";
import ReservationReasons from "./reservationForm/ReservationReasons";
import ReservationModifier from "./reservationForm/ReservationModifier";

const { Option } = Select;
const { Text, Title } = Typography;
const acceptedTypes = ["image/png", "image/jpg", "image/jpeg", "video/mp4"];
const { Dragger } = Upload;

export const openSeatsTileTypes = [
  {
    name: "كل ١٥ دقيقة",
    name_en: "Every 15 minutes",
    value: "15",
  },
  {
    name: "كل ٣٠ دقيقة",
    name_en: "Every 30 minutes",
    value: "30",
  },
  {
    name: "كل ٤٥ دقيقة",
    name_en: "Every 45 minutes",
    value: "45",
  },
  {
    name: "كل ساعة",
    name_en: "Every hour",
    value: "60",
  },
  {
    name: "كل ساعة ونصف",
    name_en: "Every hour and a half",
    value: "90",
  },
  {
    name: "كل ساعتين",
    name_en: "Every 2 hours",
    value: "120",
  },
  {
    name: "كل ساعتين ونصف",
    name_en: "Every 2 and a half hours",
    value: "150",
  },
  {
    name: "كل ٣ ساعات",
    name_en: "Every 3 hours",
    value: "180",
  },
  {
    name: "كل ٣ ساعات ونصف",
    name_en: "Every 3 and a half hours",
    value: "210",
  },
  {
    name: "كل ٤ ساعات",
    name_en: "Every 4 hours",
    value: "240",
  },
  {
    name: "كل ٤ ساعات ونصف",
    name_en: "Every 4 and a half hours",
    value: "270",
  },
  {
    name: "كل ٥ ساعات ",
    name_en: "Every 5 hours",
    value: "300",
  },
];

export const blockReservationsTypes = [
  {
    name: "لا يوجد حظر مؤقت",
    name_en: "No temporary block",
    value: "0",
  },
  {
    name: "ساعة من الآن",
    name_en: "Hour from now",
    value: "60",
  },
  {
    name: "ساعتين من الآن",
    name_en: "Two hours from now",
    value: "120",
  },
  {
    name: "ثلاث ساعات من الآن",
    name_en: "Three hours from now",
    value: "180",
  },
  {
    name: "ست ساعات من الآن",
    name_en: "Six hours from now",
    value: "360",
  },
  {
    name: "تسع ساعات من الآن",
    name_en: "Nine hours from now",
    value: "540",
  },
  {
    name: "اثنتا عشرة ساعة من الآن",
    name_en: "Twelve hours from now",
    value: "720",
  },
  {
    name: "أربع وعشرين ساعة من الآن",
    name_en: "Twenty four hours from now",
    value: "1440",
  },
];

const EditReservationSchemaForm = ({ hide, area, canDelete }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { branch, allBranches } = useSelector((state) => state.branch);
  const { role, user } = useSelector((state) => state.auth);
  const { tables } = useSelector((state) => state.table);
  const { rtl } = useSelector((state) => state.settings);
  const { restaurant } = useSelector((state) => state.restaurant);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [disabledValue, setDisabledValue] = useState("reservationPrice");
  const [fileList, setFileList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [initialLoading, setInitialLoading] = useState(false);
  const [blockAmmariyah, setBlockAmmariyah] = useState(false);
  const [reasonsLength, setReasonsLength] = useState(area?.reasons?.length);
  const [errorSize, setErrorSize] = useState(false)


  useEffect(() => {
    if (restaurant?.slug === 'Winter-Amariahhr7md7') {
      setBlockAmmariyah(true)
    }
  }, [restaurant])

  useEffect(() => {
    setTimeout(() => {
      form.resetFields();
      if (area?.isPriceForReservation) {
        setDisabledValue("pricePerUser");
      }
    }, 300);
    if (area?.images?.length > 0) {
      setFileList(
        area.images?.map((image, i) => {
          return {
            uid: image,
            status: "done",
            url: image,
            name: `${area?.name} - ${i + 1}`,
          };
        })
      );
    }
  }, [area?._id]);


  const editReservationArea = async (values) => {
    try {
      setLoading(true);
      let status = "Active";
      if (!values?.status) {
        status = "disabled";
      }
      let reservationFeeStandAlone = !values?.reservationFeeStandAlone
      let start = moment(values.startTime).format("HH:mm");
      let end = moment(values.endTime).format("HH:mm");
      let endInNextDay = false;
      // check if end less than start;
      if (moment(end, "HH:mm").isBefore(moment(start, "HH:mm"))) {
        endInNextDay = true;
      }
      let disabled = [];
      if (values.disabledDays.length > 0) {
        disabled = values.disabledDays.map((day) => {
          return {
            value: day,
            status: "Disabled",
          };
        });
      }
      let newReasons
      if (values.isWithReasons) {
        newReasons = values.reasons
      } else {
        newReasons = area?.reasons
      }
      let tableIds = []
      if (values.isLinkTables) {
        const selectedZones = values.linkedZones
        tableIds = tables.filter(t =>
          selectedZones.includes(t.zone)).map(tab =>
            tab._id)
      }
      const data = {
        ...values,
        _id: area._id,
        startTime: start,
        endTime: end,
        endInNextDay,
        disabledDays: disabled,
        reasons: newReasons,
        linkedTables: tableIds,
        reservationFeeStandAlone,
        status
      };

      const res = await updateReservationArea(branch._id, data);
      dispatch(setBranchAction(res.data.branch));
      // copy all Branches and update the one that we edited;
      const allBranchesCopy = [...allBranches];
      let index = -1;
      if (role === "super") {
        index = allBranchesCopy.findIndex(
          (b) => b.branch._id === res.data.branch._id
        );
      } else {
        index = allBranchesCopy.findIndex((b) => b._id === res.data.branch._id);
      }
      if (index !== -1) {
        allBranchesCopy[index] = {
          ...allBranchesCopy[index],
          branch: res.data.branch,
        };
      }
      // update  all Branchs in the store;
      dispatch(setBranchesAction(allBranchesCopy));
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setLoading(false);
      hide();
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const handleDeleteArea = async () => {
    try {
      setLoading(true);
      if (branch?.reservationSchema.length === 1) {
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "canNotDeleteLastOne" }),
          type: "error",
          rtl,
        });

        return;
      }
      const data = {
        status: "deleted",
        _id: area._id,
      };
      const res = await updateReservationArea(branch._id, data);
      dispatch(setBranchAction(res.data.branch));
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setLoading(false);
      hide();
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  // handle image upload;
  const handleChangeImages = ({ fileList, file }) => {
    let newFileList = [...fileList];
    setErrorSize(false)
    let errors = []
    newFileList.forEach(el => {
      if (el.size > 2000000) { //check if the image size is bigger than 2Mo
        errors.push(el?.uid)
      }
    })
    if (errors.length > 0) { //return error if there is images bigger than 2Mo
      setErrorSize(true)
      return
    }
    if (file.status === "uploading") {
      setInitialLoading(true); // show loading;
    } else if (file.status === "done") {
      // upload success;
      setInitialLoading(false); // hide loading;
      if (file.response?.branch?._id) {
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
      }
    } else if (file.status === "error") {
      setInitialLoading(false); // hide loading;
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
    if (fileList.length <= 4) {
      setFileList(newFileList);
    } else {
      message.error(intl.formatMessage({ id: "max3Images" }));
    }
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const beforeUpload = (file) => {
    if (!acceptedTypes.includes(file?.type) || !file) {
      message.error("You can only upload JPG/PNG file!");
      return false;
    }
    if (file.type?.includes("image")) {
      if (file.size / 1000000 > 2.2) {
        message.error(intl.formatMessage({ id: "limit2Mb" }));
        return false;
      }
    }
    if (fileList.length >= 4) {
      return false;
    }
    return true;
  };

  // Delete image;
  const deleteImage = async (e) => {
    setInitialLoading(true);
    try {
      const data = {
        image: e.url,
      };
      const res = await deleteAreaPhoto(branch._id, area?._id, data);
      setInitialLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
    } catch (error) {
      setInitialLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };


  return (
    <div
      style={{
        borderRadius: 6,
        marginTop: 10,
        width: "92%",
        marginRight: "auto",
        marginLeft: "auto",
        padding: 15,
      }}
    >
      <Modal
        visible={previewVisible}
        title={rtl ? area?.name : area?.name_en}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
      >
        <img alt={area?.name} style={{ width: "100%" }} src={previewImage} />
      </Modal>
      <Form
        name='editReservationArea'
        form={form}
        preserve={false}
        className='form-container'
        layout='vertical'
        onFinish={editReservationArea}
        scrollToFirstError={{ behavior: "smooth", block: "center" }}
        initialValues={{
          name: area?.name,
          name_en: area?.name_en,
          maxChairs: area?.maxChairs,
          maxUsers: area?.maxUsers,
          startTime: moment().set({
            hour: area?.startTime.split(":")[0],
            minutes: area?.startTime.split(":")[1],
          }),
          endTime: moment().set({
            hour: area?.endTime.split(":")[0],
            minutes: area?.endTime.split(":")[1],
          }),
          openSeatsEvery: `${area?.openSeatsEvery}`,
          blockReservations: `${area?.blockReservations}`,
          isWithReasons: area?.isWithReasons || false,
          reasons: area?.reasons?.map(el => { return { name: el?.name, name_en: el?.name_en, status: el?.status } }),
          sendNotification: area?.sendNotification,
          status: area?.status === "Active" ? true : false,
          pricePerUser: area?.pricePerUser,
          isReservationFree: area?.isReservationFree,
          reservationFeeStandAlone: !area?.reservationFeeStandAlone,
          isPriceForReservation: area?.isPriceForReservation,
          isPricePerUser: area?.isPriceForReservation || area?.isReservationFree || area?.isHourSelection ? false : true,
          reservationPrice: area?.reservationPrice,
          isCountPerReservation: area?.isCountPerReservation,
          isCountPerPerson: area?.isCountPerReservation || area?.isLinkTables ? false : true,
          maxReservations: area?.maxReservations,
          onlyOneReservationADay: area?.onlyOneReservationADay,
          order: area?.order,
          terms: area?.terms,
          terms_en: area?.terms_en,
          whatsappMessage: area?.whatsappMessage,
          description: area?.description,
          description_en: area?.description_en,
          reservationEndDate: area?.reservationEndDate
            ? moment(area?.reservationEndDate)
            : null,
          reservationStartDate: area?.reservationStartDate
            ? moment(area?.reservationStartDate)
            : null,
          disabledDays: area?.disabledDays
            ? area?.disabledDays?.map((d) => d?.value)
            : [],
          pricePerChild: area?.pricePerChild,
          isPricePerChild: area?.isPricePerChild,
          canPreOrder: area?.canPreOrder,
          makeChooseTableRequired: area?.makeChooseTableRequired,
          minUsers: area?.minUsers,
          enableNote: area?.enableNote,
          isLinkTables: area?.isLinkTables,
          linkedZones: area?.linkedZones,
          isHourSelection: area?.isHourSelection,
          hourPrice: area?.hourPrice
        }}
      >
        {() => (
          <>
            <div
              style={{
                backgroundColor: COLORS.white,
                padding: "10px 20px 20px",
                borderRadius: 7,
              }}
              className='light-shadow'
            >
              <ReservationGeneral
                form={form}
                rtl={rtl}
                area={area}
              />
            </div>
            <Divider />
            <div
              style={{
                backgroundColor: COLORS.white,
                padding: "10px 20px 20px",
                borderRadius: 7,
              }}
              className='light-shadow'
            >

              <ReservationWorkDays
                form={form}
                rtl={rtl}
              />
            </div>
            <Divider />
            <div
              style={{
                backgroundColor: COLORS.white,
                padding: "10px 20px 20px",
                borderRadius: 7,
              }}
              className='light-shadow'
            >
              <ReservationPrices
                rtl={rtl}
                form={form}
                setDisabledValue={setDisabledValue}
                disabledValue={disabledValue}
                area={area}
              />
            </div>
            <Divider />

            <Divider />
            <div
              style={{
                backgroundColor: COLORS.white,
                padding: "10px 20px 20px",
                borderRadius: 7,
              }}
              className='light-shadow'
            >
              {/* Additional Settings Section */}
              <Divider orientation={rtl ? "right" : "left"}>
                <Text className='my-font'>
                  {intl.formatMessage({ id: "AdditionalSettings" })}
                </Text>
              </Divider>
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "sortReservation" })}
                    className='mb-1'
                    name='order'
                  >
                    <InputNumber
                      style={{
                        width: "100%",
                      }}
                      className={"input"}
                      min={0}
                    />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}></Col>
                <Divider />
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    className='mb-1'
                    name='makeChooseTableRequired'
                    valuePropName='checked'
                  >
                    <Checkbox>
                      <Text className='my-font'>
                        {intl.formatMessage({ id: "makeChooseTableRequired" })}
                      </Text>
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    className='mb-1'
                    name='canPreOrder'
                    valuePropName='checked'
                  >
                    <Checkbox>
                      <Text className='my-font'>
                        {intl.formatMessage({ id: "canPreOrder" })}
                      </Text>
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                    className='mb-1'
                    valuePropName='checked'
                    name='sendNotification'
                  >
                    <Checkbox>
                      <Text className='my-font'>
                        {intl.formatMessage({
                          id: "sendNotificationReservation",
                        })}
                      </Text>
                    </Checkbox>

                  </Form.Item>

                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    className='mb-1'
                    name='enableNote'
                    valuePropName='checked'
                  >
                    <Checkbox>
                      <Text className='my-font'>
                        {intl.formatMessage({ id: "enableNote" })}
                      </Text>
                    </Checkbox>
                  </Form.Item>
                </Col>

              </Row>
              <Divider />
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                <Col md={6} sm={24} xs={24}>
                  <ReservationReasons
                    rtl={rtl}
                    form={form}
                    reasons={area?.reasons?.map(el => { return { name: el?.name, name_en: el?.name_en, status: el?.status } })}
                    isWithReasons={area?.isWithReasons}
                    setReasonsLength={setReasonsLength}
                    reasonsLength={reasonsLength}
                  />
                </Col>
                <Col md={8} sm={24} xs={24}>
                  <ReservationTerms
                    rtl={rtl}
                  />
                </Col>
                <Col md={8} sm={24} xs={24}>
                  <ReservationModifier
                    rtl={rtl}
                    form={form}
                    reservation={area}
                  />
                </Col>
              </Row>
            </div>
            <Divider />
            <div
              style={{
                backgroundColor: COLORS.white,
                padding: "10px 20px 20px",
                borderRadius: 7,
              }}
              className='light-shadow'
            >

              <Spin spinning={initialLoading}>
                <Title className='my-font' level={4}>
                  {intl.formatMessage({ id: "photos" })}
                </Title>
                <div style={{ position: "absolute", top: 15, display: "flex", justifyContent: "center", width: "100%" }}>
                  {errorSize ? <Text className='my-font' style={{ color: COLORS.danger }}>
                    {intl.formatMessage({ id: "limit2Mb" })}
                  </Text> : null}
                </div>
                <Dragger
                  action={`${apiUrl}/reservation-upload-image?restaurantId=${restaurant._id}&slug=${restaurant.slug}&branchId=${branch._id}&areaId=${area._id}`}
                  headers={{ authorization: "Bearer " + user.token }}
                  name='picture'
                  listType='picture'
                  fileList={fileList}
                  onChange={handleChangeImages}
                  onPreview={onPreview}
                  beforeUpload={beforeUpload}
                  multiple={false}
                  accept='image/jpg,image/jpeg,image/png,video/mp4'
                  onRemove={deleteImage}
                  data={{ id: area?._id }}
                >
                  {fileList.length < 4 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <InboxOutlined
                        style={{ color: errorSize ? COLORS.danger : COLORS.primary, fontSize: "2rem" }}
                        className='mx-3'
                      />
                      <Text
                        style={{ fontSize: "1rem", color: errorSize ? COLORS.danger : COLORS.primary }}
                        className='my-font'
                      >
                        {intl.formatMessage({ id: "addPhoto" })}
                      </Text>
                    </div>
                  ) : (
                    <Text className='my-font'>
                      {intl.formatMessage({ id: "max3Images" })}
                    </Text>
                  )}
                </Dragger>
              </Spin>
            </div>
            <Divider />
            <div
              style={{
                backgroundColor: COLORS.white,
                padding: "10px 20px 20px",
                borderRadius: 7,
              }}
              className='light-shadow'
            >
              <Row type='flex' justify='space-between'>
                <Col md={11} sm={24} xs={24}>
                  <Form.Item
                    label={`${intl.formatMessage({
                      id: "ReservationStatus",
                    })}`}
                    className='mb-1'
                    name='status'
                    valuePropName='checked'
                  >
                    <Switch
                      checkedChildren={
                        <p>
                          {intl.formatMessage({
                            id: "active",
                          })}
                        </p>
                      }
                      unCheckedChildren={
                        <p>
                          {intl.formatMessage({
                            id: "disabled",
                          })}
                        </p>
                      }
                    />
                  </Form.Item>
                </Col>
                <Col md={11} sm={24} xs={24} >
                  <Form.Item
                    label={`${intl.formatMessage({
                      id: "deleteZone",
                    })}`}
                  >
                    <Popconfirm
                      title={
                        <Text className='my-font'>
                          {intl.formatMessage({ id: "deleteZoneConfirm" })}
                        </Text>
                      }
                      okText={intl.formatMessage({ id: "delete" })}
                      okButtonProps={{
                        style: { color: COLORS.danger, fontFamily: "Cairo" },
                      }}
                      okType='text'
                      cancelText={intl.formatMessage({ id: "cancel" })}
                      cancelButtonProps={{
                        style: { border: "none", fontFamily: "Cairo" },
                      }}
                      onConfirm={handleDeleteArea}
                      disabled={!canDelete}
                    >
                      <CustomButton
                        type='danger'
                        className={`btnRegister btn-text border-8`}
                        text={intl.formatMessage({ id: "delete" })}
                      />
                    </Popconfirm>
                  </Form.Item>
                </Col>
              </Row>
            </div>

            <Divider />
            <Space direction='horizontal'>
              <Form.Item>
                <CustomButton
                  htmlType='submit'
                  text={intl.formatMessage({ id: "save" })}
                  className={`btnRegister btn-text border-8`}
                  loading={loading}
                  disabled={loading}
                  type='primary'
                />
              </Form.Item>

              <Form.Item>
                <CustomButton
                  type='text'
                  text={intl.formatMessage({ id: "cancel" })}
                  disabled={loading}
                  onClick={() => {
                    hide();
                  }}
                />
              </Form.Item>
            </Space>
          </>
        )}
      </Form>
    </div >
  );
};

export default EditReservationSchemaForm;
