import { Layout, Space, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import Search from "antd/lib/transfer/search";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getBranchData,
  getRestaurantData,
  getSuperUserRestaurants,
  postCode,
  superJoinRestaurant,
} from "../../API/fetch";
import LoadingScreen from "../../Components/LoadingScreen";
import {
  loginAction,
  logOutAction,
  setServicesAction,
  setWaiterAction,
} from "../../redux/reducers/authReducer";
import {
  setBranchAction,
  setBranchesAction,
} from "../../redux/reducers/branchReducer";
import { setMenuAction } from "../../redux/reducers/menuReducer";
import {
  setDeliveryOrdersAction,
  setDineInOrdersAction,
  setIwaiterAppOrdersAction,
  setPickupOrdersAction,
} from "../../redux/reducers/orderReducer";
import { setRestaurantAction } from "../../redux/reducers/restaurantReducer";
import { setTablesAction } from "../../redux/reducers/tableReducer";
import Header from "./components/Header";
import RestaurantInfo from "./components/RestaurantInfo";

const { Title } = Typography;
function AllRestaurants() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();

  const { superUser } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [allRestaurants, setAllRestaurants] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    getRestaurants();
  }, [superUser]);

  const getRestaurants = async () => {
    try {
      setLoading(true);
      const data = {
        userId: superUser?._id,
      };
      const res = await getSuperUserRestaurants(data, superUser.token);
      setAllRestaurants(res?.data?.restaurants);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const joinRestaurant = async (data) => {
    try {
      setLoading(true);
      const response = await superJoinRestaurant(data, superUser.token);
      dispatch(loginAction(response.data));
      const restaurantRes = await getRestaurantData();
      dispatch(setRestaurantAction(restaurantRes.data.restaurant));
      dispatch(setTablesAction(restaurantRes.data.tables));
      dispatch(setMenuAction(restaurantRes.data.menu));
      const code = { code: response.data.user.code };
      const res = await postCode(code);
      const branchRes = await getBranchData(res.data.waiter.code);
      dispatch(setBranchesAction(branchRes.data.branches));
      dispatch(setBranchAction(branchRes.data.branches[0]));
      dispatch(setDineInOrdersAction(branchRes.data.orders));
      dispatch(setPickupOrdersAction(branchRes.data.pickupOrders));
      dispatch(setDeliveryOrdersAction(branchRes.data.deliveryOrders));
      dispatch(setIwaiterAppOrdersAction(branchRes.data.iwaiterAppOrders));
      dispatch(setWaiterAction(res.data));
      if (branchRes?.data?.services) {
        dispatch(setServicesAction(branchRes?.data?.services));
      }
      navigate("/");
      setLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch(logOutAction());
        return;
      }
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Layout>
          <Header />
          <Content>
            <div
              className='tables-card-container'
              style={{
                position: "relative",
                padding: 20,
                minHeight: "90vh",
              }}
            >
              <div
                className='mb-5'
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <Space size='small' direction='vertical'>
                  <Title level={4}>
                    {intl.formatMessage({ id: "hello" })} {superUser?.name}
                  </Title>
                  <Title level={5}>
                    {intl.formatMessage({ id: "youHave" })}{" "}
                    {allRestaurants?.length}{" "}
                    {intl.formatMessage({ id: "restaurants" })}
                  </Title>
                </Space>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Search
                    allowClear
                    value={search}
                    placeholder={intl.formatMessage({ id: "search" })}
                    onChange={(e) => setSearch(e.target.value)}
                    maxLength={100}
                  />
                </div>
              </div>
              <div>
                <div
                  style={{
                    padding: 24,
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  {allRestaurants
                    ?.filter(
                      (el) =>
                        el?.name?.includes(search) ||
                        el?.name_en
                          ?.toUpperCase()
                          .includes(search.toUpperCase())
                    )
                    ?.map((restaurant) => (
                      <RestaurantInfo
                        key={restaurant?._id}
                        restaurant={restaurant}
                        joinRestaurant={joinRestaurant}
                      />
                    ))}
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      )}
    </>
  );
}

export default AllRestaurants;
