import React, { useEffect, useState } from "react";
import {
  Badge,
  Table,
  Tabs,
  Tag,
  Form,
  DatePicker,
  Typography,
  Space,
} from "antd";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import {
  CUSTOM_ORDERS_STATUS,
  TAG_COLORS_BY_STATUS_CUSTOM,
  ORDER_TYPES,
} from "../../Types";
import COLORS from "../../Style/colors";
import moment from "moment";
import { postSearchOrders } from "../../API/fetch";
import CustomButton from "../../Components/CustomButton";
import { IoCarSportOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import AddCustomOrder from "./components/AddCustomOrder";
import openNotification from "../../Components/Notifications";

const { RangePicker } = DatePicker;
const { Title, Text } = Typography;
const { TabPane } = Tabs;

function CustomOrders() {

  const intl = useIntl();
  const navigate = useNavigate();
  const { rtl } = useSelector((state) => state.settings);
  const { customOrders } = useSelector((state) => state.order);
  const { allBranches } = useSelector((state) => state.branch);
  const { role, waiter } = useSelector((state) => state.auth);
  const [formattedOrders, setFormattedOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(CUSTOM_ORDERS_STATUS.OPEN);
  const [searchOrders, setSearchOrders] = useState([]);
  const [showPendingStatus, setShowPendingStatus] = useState(false);
  const [showAdd, setShowAdd] = useState(false)


  const [form] = Form.useForm();

  useEffect(() => {
    // check if we have query params;
    const searchParams = new URLSearchParams(window.location.search);
    const tap = searchParams.get("tap");
    if (tap) {
      setActiveTab(tap);
    } else {
      // add query params;
      changeQueryParams(activeTab);
    }
  }, []);

  useEffect(() => {
    if (role === "waiter") {
      let isAutoAccept = allBranches?.[0]?.autoAcceptPickup;
      if (!isAutoAccept) {
        setShowPendingStatus(true);
      }
    } else {
      setShowPendingStatus(true);
    }
  }, [allBranches]);


  useEffect(() => {
    setLoading(true);
    if (customOrders?.length || searchOrders.length) {
      let sourceOrders = customOrders;
      if (searchOrders?.length) {
        sourceOrders = searchOrders;
      }
      // filter pickup time;
      const filteredOrders = sourceOrders

      const newFormattedOrders = filteredOrders
        .filter((o) => {
          return (
            activeTab === CUSTOM_ORDERS_STATUS.ALL || o.status === activeTab
          );
        })
        .map((order) => {
          const branch = allBranches?.find(
            (b) => b?.branch?._id === order?.branchId
          );
          let orderNumber = "#P N/A";
          if (order?.orderNumber) {
            orderNumber = "#P " + order?.orderNumber;
          }
          let finalTotal = order.total;
          return {
            key: order?._id,
            _id: order?._id,
            orderNumber: orderNumber,
            branch: rtl ? branch?.branch?.name : branch?.branch?.name_en,
            status: order?.status,
            total: finalTotal?.toFixed ? finalTotal?.toFixed(2) : "N/A",
            time: new Date(
              order?.createdAt ? order.createdAt : new Date()
            ).toLocaleString(),
            orderObject: order,
          };
        });
      setFormattedOrders(newFormattedOrders);
    }
    setLoading(false);
  }, [customOrders, activeTab]);

  const columns = [
    {
      title: intl.formatMessage({ id: "orderNumber" }),
      dataIndex: "orderNumber",
      key: "orderNumber",
      render: (text, record) => (
        <Space>
          <Text className='my-font'>{text}</Text>
          {record.customerArrived && (
            <IoCarSportOutline
              className='mx-1'
              size={20}
              style={{ position: "relative", top: 5, color: COLORS.danger }}
            />
          )}
        </Space>
      ),
    },
    {
      title: intl.formatMessage({ id: "orderStatus" }),
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return (
          <Tag color={TAG_COLORS_BY_STATUS_CUSTOM[status]}>
            {intl.formatMessage({
              id:
                status === CUSTOM_ORDERS_STATUS.CLOSE
                  ? "closedOrder"
                  : status
                    ? status
                    : "n/a",
            })}
          </Tag>
        );
      },
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      filters: [
        {
          text: intl.formatMessage({ id: CUSTOM_ORDERS_STATUS.OPEN }),
          value: CUSTOM_ORDERS_STATUS.OPEN,
        },
        {
          text: intl.formatMessage({ id: CUSTOM_ORDERS_STATUS.IN_KITCHEN }),
          value: CUSTOM_ORDERS_STATUS.IN_KITCHEN,
        },
        {
          text: intl.formatMessage({ id: CUSTOM_ORDERS_STATUS.READY }),
          value: CUSTOM_ORDERS_STATUS.READY,
        },
        {
          text: intl.formatMessage({ id: "closedOrder" }),
          value: CUSTOM_ORDERS_STATUS.CLOSE,
        },
      ],
    },
    {
      title: intl.formatMessage({ id: "total" }),
      dataIndex: "total",
      key: "total",
    },
    {
      title: intl.formatMessage({ id: "time" }),
      dataIndex: "time",
      key: "time",
      sorter: (a, b) =>
        new Date(a.orderObject?.createdAt) - new Date(b.orderObject?.createdAt),
      showSorterTooltip: false,
    },
  ];

  if (role === "super") {
    columns.splice(1, 0, {
      title: intl.formatMessage({ id: "branch" }),
      dataIndex: "branch",
      key: "branch",
      onFilter: (value, record) => value === record.orderObject?.branchId,
      filters: allBranches?.map(({ branch }) => {
        return {
          text: rtl ? branch?.name : branch?.name_en,
          value: branch?._id,
        };
      }),
    });
  }

  const changeQueryParams = (tap) => {
    navigate(`/customOrders?tap=${tap}`);
  };


  const onFinish = async (values) => {
    const [start, end] = values.date;
    const startDate = moment(start).set({ hour: 0, minute: 0, second: 0 });
    const endDate = moment(end).set({ hour: 23, minute: 59, second: 59 });
    fetchOrders(startDate, endDate, 1);
  };

  const fetchOrders = async (startDate, endDate, page) => {
    try {
      setLoading(true);
      const data = {
        orderType: "CUSTOM_ORDER",
        dateStart: startDate,
        dateEnd: endDate,
        code: waiter.code,
        paymentStatus: "all",
        page: page,
        forReports: false
      };
      const res = await postSearchOrders(data);
      if (res.data?.orders?.length) {
        const sorted = [...res.data.orders];
        let sortedOrders = sorted.sort((a, b) => {
          return new Date(a.createdAt) - new Date(b.createdAt);
        });

        const formattedOrders = sortedOrders
          .filter((o) => {
            return (
              (activeTab === CUSTOM_ORDERS_STATUS.ALL ||
                o.status === activeTab) &&
              !o.timeForPickup
            );
          })
          .map((order) => {
            const branch = allBranches?.find(
              (b) => b?.branch?._id === order?.branchId
            );
            let orderNumber = "#P N/A";
            if (order?.orderNumber) {
              orderNumber = "#P " + order?.orderNumber;
            }
            let finalTotal = order.total;
            return {
              key: order?._id,
              _id: order?._id,
              orderNumber: orderNumber,
              customerArrived:
                order?.deliverToCar?.status === "customerArrived"
                  ? true
                  : false,
              branch: rtl ? branch?.branch?.name : branch?.branch?.name_en,
              status: order?.status,
              total: finalTotal?.toFixed ? finalTotal?.toFixed(2) : "N/A",
              time: moment(order?.createdAt).format("YYYY-MM-DD hh:mm A"),
              orderObject: order,
            };
          });
        setSearchOrders(sortedOrders);
        setFormattedOrders(formattedOrders);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };


  return (
    <div style={{ position: "relative" }}>
      <>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <Title level={4}>{intl.formatMessage({ id: "customOrders" })}</Title>
          {role === "waiter" ? (
            <CustomButton
              text={intl.formatMessage({
                id: "addOrder",
              })}
              type='primary'
              className='border-8'
              onClick={() => setShowAdd(true)}
            />) : null}
        </div>
        {role === "super" ? (
          <div
            style={{ backgroundColor: COLORS.gray }}
            className='py-4 px-3'
          >
            <Form
              name='food-form'
              form={form}
              className='form-container'
              layout='inline'
              onFinish={onFinish}
            >
              <Form.Item
                rules={[{ required: true, message: "Please select Date" }]}
                name='date'
                label={intl.formatMessage({ id: "date" })}
              >
                <RangePicker />
              </Form.Item>

              <Form.Item>
                <CustomButton
                  htmlType='submit'
                  text={intl.formatMessage({ id: "search" })}
                  className={`btnRegister btn-text border-8 px-5 `}
                  loading={loading}
                  disabled={loading}
                  type='primary'
                />
              </Form.Item>
            </Form>
          </div>
        ) : null}
      </>
      <AddCustomOrder
        show={showAdd}
        hide={() => {
          setShowAdd(false);
        }}
      />
      <Tabs
        centered
        activeKey={activeTab}
        onTabClick={(e) => {
          changeQueryParams(e);
          setActiveTab(e);
        }}
        type='card'
        style={{ minHeight: "75vh" }}
      >

        {Object.values(
          CUSTOM_ORDERS_STATUS
        )?.map((status) => {
          let length = customOrders?.length;
          if (searchOrders?.length) {
            length = searchOrders?.length;
          }
          if (status !== CUSTOM_ORDERS_STATUS.ALL) {
            if (searchOrders?.length) {
              length = searchOrders?.filter(
                (order) => order.status === status
              ).length;
            } else {
              length = customOrders?.filter(
                (order) => order.status === status
              ).length;
            }
          }

          return (
            <TabPane
              key={status}
              className='light-shadow'
              style={{
                minHeight: "75vh",
              }}
              tab={
                <Badge
                  count={length}
                  style={{ backgroundColor: COLORS.primary }}
                >
                  <span className='px-3 my-font'>
                    {intl.formatMessage({
                      id: status === "close" ? "completed" : status,
                    })}
                  </span>
                </Badge>
              }
            >
              <Table
                columns={columns}
                pagination={{
                  hideOnSinglePage: true,
                  pageSize: 20,
                }}
                style={{ marginTop: "-17px" }}
                loading={loading}
                dataSource={formattedOrders}
                scroll={{ x: 500 }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: () => {
                      if (record._id) {
                        navigate(`/customOrders/${record?._id}`, {
                          state: ORDER_TYPES.CUSTOM_ORDER,
                        });
                      }
                    },
                  };
                }}
                rowClassName={() => {
                  return "pointer";
                }}
              />
            </TabPane>
          );
        })}
      </Tabs>
    </div>
  )
}

export default CustomOrders