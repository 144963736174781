import React, { useState, useEffect } from "react";
import { Descriptions, Space, Spin, Tag, Typography } from "antd";
import { useIntl } from "react-intl";
import COLORS from "../../Style/colors";
import { getRestaurantPaymentData } from "../../API/fetch";
import { useSelector } from "react-redux";
import { DEFAULT_WALLET_NAME } from "../../Types";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../Components/CustomButton";
import { FileExcelOutlined } from "@ant-design/icons";
import { customerExport } from "../../helpers";
import { Table } from "ant-table-extensions";

const { Title, Text, Link } = Typography;
const PaymentsData = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { restaurant } = useSelector((state) => state.restaurant);
  const [initialLoading, setInitialLoading] = useState(true);
  const [account, setAccount] = useState({});
  const [deposits, setDeposits] = useState([]);
  const [iwaiterDebt, setIwaiterDebt] = useState(null);
  const [isOldData, setIsOldData] = useState(false);

  useEffect(() => {
    if (restaurant?.wallet?.type === DEFAULT_WALLET_NAME.IWAITER_WALLET) {
      getPaymentData();
    }
  }, []);

  const getPaymentData = async (getOld) => {
    try {
      let old = false;
      if (getOld) {
        old = true;
      }
      setInitialLoading(true);
      const res = await getRestaurantPaymentData(old);
      setIsOldData(old);
      setAccount(res.data?.account);
      setDeposits(res.data?.deposits);
      if (res.data?.iwaiterDebt) {
        setIwaiterDebt(res.data?.iwaiterDebt);
      }
      setInitialLoading(false);
    } catch (error) {
      console.log(error);
      setInitialLoading(false);
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: "transferTime" }),
      dataIndex: "DepositDate",
      key: "DepositDate",
    },
    {
      title: intl.formatMessage({ id: "transferNumber" }),
      dataIndex: "DepositReference",
      key: "DepositReference",
      render: (text, record) => {
        return (
          <Text
            className='my-font pointer'
            underline
            onClick={() => {
              navigate("/payments-data/" + text);
            }}
            style={{
              color: COLORS.primary,
              fontWeight: "bold",
            }}
          >
            {text}
          </Text>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "bankName" }),
      dataIndex: "BankName",
      key: "BankName",
    },
    {
      title: intl.formatMessage({ id: "amountPaid" }),
      dataIndex: "TotalValue",
      key: "TotalValue",
    },
    {
      title: intl.formatMessage({ id: "view" }),
      dataIndex: "orderId",
      key: "orderId",
      render: (text, record, index) => {
        return (
          <CustomButton
            type='text'
            text={intl.formatMessage({ id: "view" })}
            onClick={() => {
              navigate("/payments-data/" + record?.DepositReference);
            }}
          />
        );
      },
    },
  ];

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <Title level={3}>{intl.formatMessage({ id: "allPayments" })}</Title>
        {restaurant?.old_destination_code ? (
          <CustomButton
            text={intl.formatMessage({
              id: isOldData ? "getNewInfoBankInfo" : "getOldInfoBankInfo",
            })}
            type={isOldData ? "primary" : "default"}
            onClick={() => {
              getPaymentData(true);
            }}
            disabled={initialLoading}
            loading={initialLoading}
            style={{
              borderRadius: 10,
            }}
          />
        ) : null}
      </div>
      <Spin spinning={initialLoading}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <Space direction='horizontal' justify='center' align='start'>
            <Title level={5} className='my-font'>
              {intl.formatMessage({ id: "yourBalance" })}
            </Title>
            <Tag color={"green"}>{account?.TotalAwaitingBalance}</Tag>
          </Space>
          <Space direction='horizontal' justify='center' align='start'>
            <Text
              className='my-font pointer'
              underline
              onClick={() => {
                navigate("/paymentRecords");
              }}
              style={{
                color: COLORS.primary,
                fontWeight: "bold",
              }}
            >
              {intl.formatMessage({ id: "seeOldRecords" })}
            </Text>
          </Space>
        </div>

        <div
          style={{
            backgroundColor: COLORS.white,
            marginTop: 40,
            width: "92%",
            marginRight: "auto",
            marginLeft: "auto",
            borderRadius: 10,
            padding: 15,
          }}
          className='light-shadow'
        >
          <Space
            style={{
              width: "100%",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <CustomButton
              style={{
                justifyContent: "flex-end",
                alignItems: "center",
                alignSelf: "flex-end",
                borderRadius: 7,
              }}
              onClick={() => {
                navigate("/paymentsVat");
              }}
              text={intl.formatMessage({ id: "vatInvoices" })}
            />
          </Space>
          {Object.keys(account)?.length ? (
            <Descriptions
              title={intl.formatMessage({ id: "paymentsData" })}
              bordered
              className='my-font'
              style={{
                textAlign: "center",
              }}
              column={1}
            >
              <Descriptions.Item
                labelStyle={{ backgroundColor: COLORS.gray }}
                contentStyle={{ backgroundColor: COLORS.white }}
                label={intl.formatMessage({ id: "status" })}
              >
                <Tag color={account?.IsActive ? "green" : "gray"}>
                  {intl.formatMessage({
                    id: account?.IsActive ? "active" : "notActive",
                  })}
                </Tag>
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ backgroundColor: COLORS.gray }}
                contentStyle={{ backgroundColor: COLORS.white }}
                label={intl.formatMessage({ id: "approvedStatus" })}
              >
                <Tag color={account?.IsApproved ? "green" : "gray"}>
                  {intl.formatMessage({
                    id: account?.IsApproved ? "approved" : "notApproved",
                  })}
                </Tag>
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ backgroundColor: COLORS.gray }}
                contentStyle={{ backgroundColor: COLORS.white }}
                label={intl.formatMessage({ id: "TotalAwaitingBalance" })}
              >
                {account?.TotalAwaitingBalance}
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ backgroundColor: COLORS.gray }}
                contentStyle={{ backgroundColor: COLORS.white }}
                label={intl.formatMessage({ id: "TotalAwaitingToTransfer" })}
              >
                {account?.TotalAwaitingToTransfer}
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ backgroundColor: COLORS.gray }}
                contentStyle={{ backgroundColor: COLORS.white }}
                label={intl.formatMessage({ id: "TotalDepositedAmount" })}
              >
                {account?.TotalDepositedAmount}
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ backgroundColor: COLORS.gray }}
                contentStyle={{ backgroundColor: COLORS.white }}
                label={intl.formatMessage({ id: "TotalNumberOfTransactions" })}
              >
                {account?.TotalNumberOfTransactions}
              </Descriptions.Item>
              {iwaiterDebt ? (
                <>
                  <Descriptions.Item
                    labelStyle={{ backgroundColor: `#fff1f0` }}
                    contentStyle={{ backgroundColor: `#fff1f0` }}
                    label={intl.formatMessage({ id: "totalDebt" })}
                  >
                    {iwaiterDebt?.totalDebt}
                  </Descriptions.Item>
                  <Descriptions.Item
                    labelStyle={{ backgroundColor: "#d9f7be" }}
                    contentStyle={{ backgroundColor: "#d9f7be" }}
                    label={intl.formatMessage({ id: "paidDebt" })}
                  >
                    {iwaiterDebt?.paidAmount}
                  </Descriptions.Item>
                  <Descriptions.Item
                    labelStyle={{ backgroundColor: COLORS.gray }}
                    contentStyle={{ backgroundColor: COLORS.gray }}
                    label={intl.formatMessage({ id: "remainingDebt" })}
                  >
                    {iwaiterDebt?.remainingDebt}
                  </Descriptions.Item>
                </>
              ) : null}
            </Descriptions>
          ) : null}
        </div>
        <div
          style={{
            backgroundColor: COLORS.white,
            marginTop: 40,
            width: "92%",
            marginRight: "auto",
            marginLeft: "auto",
            borderRadius: 10,
            padding: 15,
            textAlign: "center",
          }}
          className='light-shadow'
        >
          <Title level={5}>{intl.formatMessage({ id: "settlements" })}</Title>
          {deposits?.length ? (
            <Table
              scroll={{ x: 500 }}
              pagination={{ hideOnSinglePage: true }}
              columns={columns}
              dataSource={deposits}
              exportable
              exportableProps={{
                showColumnPicker: true,
                btnProps: {
                  icon: <FileExcelOutlined />,
                  type: "primary",
                  style: { borderRadius: 7 },
                  onClick: () =>
                    customerExport({
                      data: deposits?.map((d, index) => {
                        return {
                          ...d,
                          number: index + 1,
                          orderId:
                            "https://board.iwaiter.net/payments-data/" +
                            d?.DepositReference,
                        };
                      }),
                      columns,
                      fileName: "Transactions Report",
                      loadingSetter: setInitialLoading,
                    }),
                },
                children: (
                  <Text
                    className='my-font'
                    style={{
                      color: COLORS.white,
                    }}
                  >
                    {intl.formatMessage({ id: "export" })}
                  </Text>
                ),
              }}
            />
          ) : (
            <Text>{intl.formatMessage({ id: "noData" })}</Text>
          )}
        </div>
      </Spin>
    </div>
  );
};

export default PaymentsData;
