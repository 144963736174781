
import moment from "moment";

export const getOpeningHoursValues = (arrOpeningHours, arrEveryday) => {
    // add everyDay to openning hours values 
    let newArr = arrOpeningHours
    if (arrEveryday.length > 0) {
        newArr?.push(arrEveryday[0])
    }
    let openingHours = []
    //convert openingHours to new values
    newArr?.forEach(day => {
        let result = []
        day?.hours?.map(shift => {
            let from = moment(shift?.from)?.format("HH:mm")
            let to = moment(shift?.to)?.format("HH:mm")
            let isNextDay = false
            if (shift?.to?.isBefore(shift?.from)) {
                isNextDay = true
            }
            const midNightStart = shift?.from?.clone()?.startOf("day");
            from = shift?.from?.diff(midNightStart, "seconds");
            to = shift?.to?.diff(midNightStart, "seconds");
            result?.push({ from, to, isNextDay })
        })
        openingHours?.push({
            name: day?.name,
            name_en: day?.name_en,
            id: day?.id,
            isClose: !day?.isOpen,
            hours: result
        })
    })
    //return the final result
    return openingHours
}


export const convertDefaultOpeningHours = (arr, type) => {
    const days = arr?.map(day => {
        const newHours = []
        for (let i = 0; i < day?.hours?.length; i++) {
            newHours.push({
                from: moment()?.startOf("day")?.add(day?.hours[i]?.from, "seconds"),
                to: moment()?.startOf("day")?.add(day?.hours[i]?.to, "seconds")
            })
        }
        return { hours: newHours, name: day?.name, name_en: day?.name_en, id: day?.id, isOpen: !day?.isClose }
    })
    if (type === "EVERYDAY") {
        return days?.filter((day) => day?.name_en === "everyDay")
    } else {
        return days?.filter((day) => day?.name_en !== "everyDay")
    }
}


export const getOpeningHoursLength = (arr) => {
    let tab = []
    arr?.filter(arr => arr?.name_en !== "everyDay")?.map((arr) =>
        tab.push(arr?.hours?.length)
    )
    return tab
}

export const getEveryDayLength = (arr) => {
    let tab = []
    arr?.filter(arr => arr?.name_en === "everyDay")?.map((arr) =>
        tab.push(arr?.hours?.length)
    )
    return tab[0]
}