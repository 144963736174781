import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Typography,
  Form,
  Collapse,
  Divider,
  Tag,
  Input,
  Space,
} from "antd";
import COLORS from "../../Style/colors";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../Components/CustomButton";
import { editDesign } from "../../API/fetch";
import { setSocialLinkAction } from "../../redux/reducers/restaurantReducer";
import openNotification from "../../Components/Notifications";
import {
  InstagramOutlined,
  TwitterOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { FaSnapchatSquare, FaTiktok } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { IoArrowBackOutline, IoArrowForwardOutline } from "react-icons/io5";

const { Title, Text } = Typography;

const clineURL = "https://app.iwaiter.club";

const SocialIcons = () => {
  const intl = useIntl();
  const history = useNavigate();
  const dispatch = useDispatch();
  const { socialLink, restaurant } = useSelector((state) => state.restaurant);
  const { allBranches } = useSelector((state) => state.branch);
  const { rtl } = useSelector((state) => state.settings);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.resetFields();
  }, [socialLink]);

  const onFinish = async (values) => {
    setLoading(true);
    let googleMapLinks = [];
    for (const key in values) {
      if (Object.hasOwnProperty.call(values, key)) {
        const element = values[key];
        const branchId = key.split("_")[1];
        if (element && key?.includes("googleMap")) {
          googleMapLinks.push({
            branchId,
            link: element,
          });
        }
      }
    }
    try {
      const data = {
        restaurant: {
          socialLink: {
            ...socialLink,
            ...values,
            googleMapLinks,
          },
        },
      };
      const res = await editDesign(data, restaurant._id, "type=social");
      if (res.data?.result?.socialLink) {
        dispatch(setSocialLinkAction(res.data.result.socialLink));
      }
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
    } catch (error) {
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const getGoogleMapLinks = () => {
    // format the key of each link;
    let result = {};
    if (socialLink?.googleMapLinks) {
      socialLink.googleMapLinks.forEach((link) => {
        result[`googleMapLinks_${link.branchId}`] = link.link;
      });
    }
    return result;
  };

  return (
    <div>
      <div
        style={{
          marginTop: 20,
          width: "92%",
          marginRight: "auto",
          marginLeft: "auto",
        }}
      >
        <Row>
          <Col
            span={24}
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "space-between",
              width: "100%"
            }}>
            <Title level={3} className='pointer' onClick={() => history("/restaurant")} style={{ margin: 0 }}>
              {rtl ? (
                <IoArrowForwardOutline className='pointer' />
              ) : (
                <IoArrowBackOutline className='pointer' />
              )}
            </Title>
            <Title level={3} style={{ margin: 0 }}>{intl.formatMessage({ id: "socialIcons" })}</Title>
            <div></div>
          </Col>
        </Row>
      </div>
      <div
        style={{
          backgroundColor: COLORS.white,
          borderRadius: 6,
          width: "92%",
          marginRight: "auto",
          marginLeft: "auto",
          marginTop: 40,
          padding: 15,
        }}
        className='light-shadow'
      >
        <Form
          name='viewCart-form'
          form={form}
          className='form-container'
          layout='vertical'
          onFinish={onFinish}
          initialValues={{
            ...socialLink,
            ...getGoogleMapLinks(),
          }}
        >
          {() => {
            return (
              <div>
                <Row type='flex'>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "twitter" })}
                      className='mb-1'
                      name='twitter'
                      style={{ borderRadius: 20 }}
                    >
                      <Input
                        addonBefore={rtl ? null : <TwitterOutlined />}
                        addonAfter={rtl ? <TwitterOutlined /> : null}
                        className={"input"}
                        style={{ borderRadius: 5, width: "95%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "instagram" })}
                      className='mb-1'
                      name='instagram'
                      style={{ borderRadius: 20 }}
                    >
                      <Input
                        addonBefore={rtl ? null : <InstagramOutlined />}
                        addonAfter={rtl ? <InstagramOutlined /> : null}
                        className={"input"}
                        style={{ borderRadius: 5, width: "95%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "snapchat" })}
                      className='mb-1'
                      name='snapchat'
                      style={{ borderRadius: 20 }}
                    >
                      <Input
                        addonBefore={rtl ? null : <FaSnapchatSquare />}
                        addonAfter={rtl ? <FaSnapchatSquare /> : null}
                        className={"input"}
                        style={{ borderRadius: 5, width: "95%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "tikTok" })}
                      className='mb-1'
                      name='tikTok'
                      style={{ borderRadius: 20 }}
                    >
                      <Input
                        addonBefore={rtl ? null : <FaTiktok />}
                        addonAfter={rtl ? <FaTiktok /> : null}
                        className={"input"}
                        style={{ borderRadius: 5, width: "95%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "whatsapp" })}
                      className='mb-1'
                      name='whatsapp'
                      style={{ borderRadius: 20 }}
                    >
                      <Input
                        addonBefore={
                          rtl ? null : (
                            <>
                              <WhatsAppOutlined />
                              <Text className='my-font mx-1'>+966</Text>
                            </>
                          )
                        }
                        addonAfter={
                          rtl ? (
                            <>
                              <Text className='my-font mx-1'>966+</Text>
                              <WhatsAppOutlined />
                            </>
                          ) : null
                        }
                        className={"input"}
                        style={{ borderRadius: 5, width: "95%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Divider />
                  <Col md={24} sm={24} xs={24}>
                    <Title level={5}>
                      {intl.formatMessage({ id: "googleMapLink" })}
                    </Title>
                  </Col>

                  {allBranches?.map((branch) => {
                    return (
                      <Col md={12} sm={24} xs={24} key={branch.branch._id}>
                        <Form.Item
                          label={
                            rtl ? branch?.branch?.name : branch?.branch?.name_en
                          }
                          className='mb-1'
                          tooltip={<Text className="my-font">{intl.formatMessage({ id: "googleMapLinkTooltip" })}</
                          Text>}
                          name={`googleMapLinks_${branch?.branch?._id}`}
                          style={{ borderRadius: 20 }}
                          rules={[
                            {
                              type: "url",
                              message: intl.formatMessage({ id: "invalidURL" }),
                            },
                          ]}
                        >
                          <Input
                            className={"input"}
                            style={{ borderRadius: 5, width: "95%" }}
                          />
                        </Form.Item>
                      </Col>
                    );
                  })}

                  <Divider />
                  <Space direction='vertical'>
                    <Text className='my-font'>
                      {intl.formatMessage({ id: "linkToAll" })} :{" "}
                    </Text>
                    <Text copyable>
                      {restaurant?.domain == "iWaiter"
                        ? `${clineURL}/${restaurant.slug}`
                        : restaurant.domain}
                    </Text>
                  </Space>

                  <Divider />
                  <Row justify='start' className='mt-5' align='middle'>
                    <Form.Item>
                      <CustomButton
                        htmlType='submit'
                        text={intl.formatMessage({ id: "save" })}
                        className={`btnRegister btn-text border-8 px-5 `}
                        loading={loading}
                        disabled={loading}
                        type='primary'
                      />
                    </Form.Item>
                  </Row>
                </Row>
              </div>
            );
          }}
        </Form>
      </div>
    </div>
  );
};

export default SocialIcons;
