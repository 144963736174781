import React from "react";
import {
  PICKUP_ORDERS_STATUS,
  TAG_COLORS_BY_STATUS_ALL_TYPES,
  TAG_COLORS_BY_STATUS_PICKUP,
} from "../../../Types";
import { Tag, Space } from "antd";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { toggleAlert } from "../../NotificationModal/AlertSound";
const PickupTag = ({ status, changeStatus }) => {
  const intl = useIntl();

  return (
    <Space direction='horizontal' size={"large"}>
      {status === PICKUP_ORDERS_STATUS.PENDING ? (
        <>
          <Tag
            onClick={() => {
              toggleAlert({ status: "stop" });
              changeStatus(status, false);
            }}
            color={"green"}
            className='my-font px-5 py-1'
          >
            {intl.formatMessage({ id: "accept" })}
          </Tag>
          <Tag
            onClick={() => {
              toggleAlert({ status: "stop" });
              changeStatus(status, true);
            }}
            color={"red"}
            className='my-font px-5 py-1'
          >
            {intl.formatMessage({ id: "reject" })}
          </Tag>
        </>
      ) : null}
      {status === PICKUP_ORDERS_STATUS.PENDING ? null : (
        <Tag
          onClick={() => {
            if (changeStatus) {
              changeStatus(status, status === PICKUP_ORDERS_STATUS.PENDING);
              if (status === PICKUP_ORDERS_STATUS.PENDING) {
                toggleAlert({ status: "stop" });
              }
            }
          }}
          color={
            status === PICKUP_ORDERS_STATUS.OPEN
              ? TAG_COLORS_BY_STATUS_ALL_TYPES.inKitchen
              : status === PICKUP_ORDERS_STATUS.IN_KITCHEN
              ? TAG_COLORS_BY_STATUS_ALL_TYPES.ready
              : status === PICKUP_ORDERS_STATUS.READY
              ? TAG_COLORS_BY_STATUS_ALL_TYPES.open
              : status === PICKUP_ORDERS_STATUS.CLOSE
              ? "red"
              : ""
          }
          className={"my-font px-5 py-1"}
        >
          {status === PICKUP_ORDERS_STATUS.OPEN
            ? intl.formatMessage({ id: "inKitchenButtonText" })
            : status === PICKUP_ORDERS_STATUS.IN_KITCHEN
            ? intl.formatMessage({ id: "readyButtonText" })
            : status === PICKUP_ORDERS_STATUS.CLOSE
            ? intl.formatMessage({ id: "openButtonText" })
            : intl.formatMessage({ id: "closeAction" })}
        </Tag>
      )}
    </Space>
  );
};

export default PickupTag;
