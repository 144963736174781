import React, { useState } from "react";
import {
    Modal,
    Form,
    InputNumber,
    Typography,
    Divider,
    Space,
    Row,
    Col,

} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import CustomButton from "../../../Components/CustomButton";
import { CUSTOMER_TYPE } from "../../../Types";
import { TrophyFilled } from "@ant-design/icons";
import { postUpdateRestaurant } from "../../../API/fetch";
import { setRestaurantAction } from "../../../redux/reducers/restaurantReducer";
import openNotification from "../../../Components/Notifications";
import moment from "moment";


const { Text, Title } = Typography;

const CustomerTypeSettings = ({ show, hide, types, restaurant ,fetchUsers,filterForm}) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { rtl } = useSelector((state) => state.settings);
    const { waiter } = useSelector((state) => state.auth);

    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const editCustomerType = async (values) => {
        try {
            const customerTypes = [
                { name: CUSTOMER_TYPE.BRONZE, value: values.bronze },
                { name: CUSTOMER_TYPE.SILVER, value: values.silver },
                { name: CUSTOMER_TYPE.GOLD, value: values.gold },
                { name: CUSTOMER_TYPE.PLATINUM, value: values.platinum }
            ]

            setLoading(true);
            const data = {
                restaurant: {
                    customerTypes: customerTypes,
                },
                code: waiter.code,
            };
            const res = await postUpdateRestaurant(restaurant._id, data);
            const newRest = {
                ...restaurant,
                customerTypes: customerTypes,
            };
            dispatch(setRestaurantAction(newRest));
            setLoading(false);
            openNotification({
                title: intl.formatMessage({ id: "savedSuccessfully" }),
                type: "success",
                rtl,
            });
            const startDate = moment().subtract(5, "days").set({ hour: 0, minute: 0, second: 0 });
            const endDate = moment().set({ hour: 23, minute: 59, second: 59 });
            fetchUsers(startDate, endDate, 1, "all" ,"all");
            // set the form to the default values;
            filterForm.setFieldsValue({
              date: [startDate, endDate],
              type: "all", 
              badge:"all"
            });
            hide()
        } catch (error) {
            console.log(error);
            setLoading(false); // hide loading;
            openNotification({
                title: intl.formatMessage({ id: "error" }),
                description: intl.formatMessage({ id: "errorfetch" }),
                type: "error",
                rtl,
            });
        }
    };


    return (
        <Modal
            title={
                <p className='my-font'>{intl.formatMessage({ id: "customerTypeSettings" })}</p>
            }
            centered
            visible={show}
            destroyOnClose={true}
            mask={true}
            getContainer={false}
            maskClosable={true}
            onCancel={() => {
                hide();
            }}
            footer={null}
        >
            <Form
                name='Add-Coupon'
                form={form}
                preserve={false}
                className='form-container'
                layout='vertical'
                onFinish={editCustomerType}
                initialValues={{
                    bronze:1,
                    silver: types?.filter(el => el?.name === CUSTOMER_TYPE.SILVER)[0]?.value,
                    gold: types?.filter(el => el?.name === CUSTOMER_TYPE.GOLD)[0]?.value,
                    platinum: types?.filter(el => el?.name === CUSTOMER_TYPE.PLATINUM)[0]?.value,
                }}
            >
                {() => {
                    return (
                        <>
                            <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                                <Col md={24} sm={24} xs={24} style={{ display: "flex", alignItems: "center" }}>
                                    <Title level={5}>{intl.formatMessage({ id: "BRONZE_BAGDE" })}</Title>
                                    <TrophyFilled
                                        style={{
                                            color: "#A9907E",
                                            fontSize: "1.3rem",
                                            cursor: "pointer",
                                            marginRight: "1rem",
                                            marginLeft: "1rem"
                                        }} />
                                </Col>
                                <Col md={12} sm={12} xs={12}>
                                    <Form.Item
                                        label={intl.formatMessage({ id: "from" })}
                                        className='mb-1'
                                        name="bronze"
                                        style={{ borderRadius: 20 }}
                                        rules={[
                                            {
                                                required: true,
                                                message: intl.formatMessage({ id: "errorCustomerType" }),
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            disabled={true}
                                            className={"input"}
                                            style={{ borderRadius: 5, width: "100%" }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col md={12} sm={12} xs={12}>
                                    <Form.Item
                                        label={intl.formatMessage({ id: "to" })}
                                        className='mb-1'
                                        name='silver'
                                        style={{ borderRadius: 20 }}
                                        rules={[
                                            {
                                                required: true,
                                                message: intl.formatMessage({ id: "errorCustomerType" }),
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            min={form.getFieldValue("bronze") + 1}
                                            className={"input"}
                                            style={{ borderRadius: 5, width: "100%" }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider style={{ margin: 5 }} />
                            <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                                <Col md={24} sm={24} xs={24} style={{ display: "flex", alignItems: "center" }}>
                                    <Title level={5}>{intl.formatMessage({ id: "SILVER_BAGDE" })}</Title>
                                    <TrophyFilled
                                        style={{
                                            color: "#9BA4B5",
                                            fontSize: "1.3rem",
                                            cursor: "pointer",
                                            marginRight: "1rem",
                                            marginLeft: "1rem"
                                        }} />
                                </Col>
                                <Col md={12} sm={12} xs={12}>
                                    <Form.Item
                                        label={intl.formatMessage({ id: "from" })}
                                        className='mb-1'
                                        style={{ borderRadius: 20 }}
                                        rules={[
                                            {
                                                required: true,
                                                message: intl.formatMessage({ id: "errorCustomerType" }),
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            value={form.getFieldValue("silver") + 1}
                                            disabled={true}
                                            className={"input"}
                                            style={{ borderRadius: 5, width: "100%" }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col md={12} sm={12} xs={12}>
                                    <Form.Item
                                        label={intl.formatMessage({ id: "to" })}
                                        className='mb-1'
                                        name='gold'
                                        style={{ borderRadius: 20 }}
                                        rules={[
                                            {
                                                required: true,
                                                message: intl.formatMessage({ id: "errorCustomerType" }),
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            min={form.getFieldValue("silver") + 2}
                                            className={"input"}
                                            style={{ borderRadius: 5, width: "100%" }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider style={{ margin: 5 }} />
                            <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                                <Col md={24} sm={24} xs={24} style={{ display: "flex", alignItems: "center" }}>
                                    <Title level={5}>{intl.formatMessage({ id: "GOLD_BAGDE" })}</Title>
                                    <TrophyFilled
                                        style={{
                                            color: "#FFD93D",
                                            fontSize: "1.3rem",
                                            cursor: "pointer",
                                            marginRight: "1rem",
                                            marginLeft: "1rem"
                                        }} />
                                </Col>
                                <Col md={12} sm={12} xs={12}>
                                    <Form.Item
                                        label={intl.formatMessage({ id: "from" })}
                                        className='mb-1'
                                        style={{ borderRadius: 20 }}
                                        rules={[
                                            {
                                                required: true,
                                                message: intl.formatMessage({ id: "errorCustomerType" }),
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            value={form.getFieldValue("gold") + 1}
                                            disabled={true}
                                            className={"input"}
                                            style={{ borderRadius: 5, width: "100%" }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col md={12} sm={12} xs={12}>
                                    <Form.Item
                                        label={intl.formatMessage({ id: "to" })}
                                        className='mb-1'
                                        name='platinum'
                                        style={{ borderRadius: 20 }}
                                        rules={[
                                            {
                                                required: true,
                                                message: intl.formatMessage({ id: "errorCustomerType" }),
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            min={form.getFieldValue("gold") + 2}
                                            className={"input"}
                                            style={{ borderRadius: 5, width: "100%" }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider style={{ margin: 5 }} />
                            <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                                <Col md={24} sm={24} xs={24} style={{ display: "flex", alignItems: "center" }}>
                                    <Title level={5}>{intl.formatMessage({ id: "PLATINUM_BAGDE" })}</Title>
                                    <TrophyFilled
                                        style={{
                                            color: "#212A3E",
                                            fontSize: "1.3rem",
                                            cursor: "pointer",
                                            marginRight: "1rem",
                                            marginLeft: "1rem"
                                        }} />
                                </Col>
                                <Col md={12} sm={12} xs={12}>
                                    <Form.Item
                                        label={intl.formatMessage({ id: "from" })}
                                        className='mb-1'
                                        style={{ borderRadius: 20 }}
                                        rules={[
                                            {
                                                required: true,
                                                message: intl.formatMessage({ id: "errorCustomerType" }),
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            value={form.getFieldValue("platinum") + 1}
                                            disabled={true}
                                            className={"input"}
                                            style={{ borderRadius: 5, width: "100%" }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col md={12} sm={12} xs={12} style={{ display: "flex", alignItems: "center" }}>
                                    <Text style={{ marginTop: "1rem" }}>{intl.formatMessage({ id: "orMore" })}</Text>
                                </Col>
                            </Row>

                            <Divider />
                            <Space direction='horizontal'>
                                <Form.Item>
                                    <CustomButton
                                        htmlType='submit'
                                        text={intl.formatMessage({ id: "save" })}
                                        className={`btnRegister btn-text border-8`}
                                        loading={loading}
                                        disabled={loading}
                                        type='primary'
                                    />
                                </Form.Item>

                                <Form.Item>
                                    <CustomButton
                                        type='text'
                                        text={intl.formatMessage({ id: "cancel" })}
                                        disabled={loading}
                                        onClick={() => {
                                            hide();
                                        }}
                                    />
                                </Form.Item>
                            </Space>
                        </>
                    );
                }}
            </Form>
        </Modal>
    );
};

export default CustomerTypeSettings;
