import React, { useState, useEffect } from "react";
import {
  Form,
  Space,
  Divider,
  Modal,
  Typography,
  Switch,
  Row,
  Col,
} from "antd";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../Components/CustomButton";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import openNotification from "../../../Components/Notifications";
import { postUpdateIntegrations } from "../../../API/fetch";
import { setIntegrationsAction } from "../../../redux/reducers/restaurantReducer";

const { Title, Text } = Typography;

const MarnModal = ({ show, hide }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { rtl, isBigScreen } = useSelector((state) => state.settings);
  const { integrations } = useSelector((state) => state.restaurant);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      form.resetFields();
    }, 400);
  });

  const onFinish = async (values) => {
    try {
      setLoading(true);
      let posStatus = values.status ? "active" : "ready";
      const data = {
        type: "POSEdit",
        integration: {
          name: "marn",
          syncOrders: values.posSyncOrders,
          syncMenu: values.posSyncMenu,
          posSyncOptions: {
            syncMenu: values.posSyncMenu,
            syncOrders: values.posSyncOrders,
            syncNames: values.syncNames,
            syncModifiersNames: values.syncModifiersNames,
            syncPrices: values.syncPrices,
            syncPhotos: values.syncPhotos,
            syncCategory: values.syncCategory,
            syncAvailability: values.syncAvailability,
          },
          posStatus,
        },
      };

      const res = await postUpdateIntegrations(data);
      if (res?.data?.integration) {
        const newIntegration = {
          ...res.data.integration,
        };
        dispatch(setIntegrationsAction(newIntegration));
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
      }
      setLoading(false);
      hide();
    } catch (error) {
      console.log("error", error);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
      setLoading(false);
    }
  };

  return (
    <Modal
      title={
        <Title level={4} className='my-font'>
          {intl.formatMessage({ id: "marn" })}
        </Title>
      }
      centered
      visible={show}
      destroyOnClose={true}
      mask={true}
      maskClosable={true}
      onCancel={() => hide()}
      footer={null}
    >
      <Form
        name='editTableMarn'
        form={form}
        preserve={false}
        className='form-container'
        layout='vertical'
        onFinish={onFinish}
        initialValues={{
          posSyncMenu: integrations?.posSyncMenu,
          posSyncOrders: integrations?.posSyncOrders,
          syncNames: integrations?.posSyncOptions?.syncNames,
          syncModifiersNames: integrations?.posSyncOptions?.syncModifiersNames,
          syncPrices: integrations?.posSyncOptions?.syncPrices,
          syncPhotos: integrations?.posSyncOptions?.syncPhotos,
          syncCategory: integrations?.posSyncOptions?.syncCategory,
          // syncAvailability: integrations?.posSyncOptions?.syncAvailability,
          syncAvailability: false,
          status: integrations?.posStatus === "active",
        }}
      >
        <div className={isBigScreen ? "px-4" : "px-2"}>
          <Row justify='space-between' align='middle' type='flex'>
            <Col md={11} sm={24} xs={24}>
              <Form.Item
                label={intl.formatMessage({ id: "menuSyncedMarn" })}
                className='mb-1'
                name='posSyncMenu'
                valuePropName='checked'
                tooltip={
                  <Text className='my-font'>
                    {intl.formatMessage({ id: "menuSyncExplainMarn" })}
                  </Text>
                }
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  disabled={loading}
                  loading={loading}
                />
              </Form.Item>
            </Col>
            <Col md={11} sm={24} xs={24}>
              <Form.Item
                label={intl.formatMessage({ id: "ordersSyncedMarn" })}
                className='mb-1'
                name='posSyncOrders'
                valuePropName='checked'
                tooltip={
                  <Text className='my-font'>
                    {intl.formatMessage({ id: "orderSyncExplainMarn" })}
                  </Text>
                }
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  disabled={loading}
                  loading={loading}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify='space-between' align='middle' type='flex'>
            <Col md={11} sm={24} xs={24}>
              <Form.Item
                label={intl.formatMessage({ id: "syncNames" })}
                className='mb-1'
                name='syncNames'
                valuePropName='checked'
                tooltip={
                  <Text className='my-font'>
                    {intl.formatMessage({ id: "syncOptionsDis" })}
                  </Text>
                }
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  disabled={loading}
                  loading={loading}
                />
              </Form.Item>
            </Col>
            <Col md={11} sm={24} xs={24}>
              <Form.Item
                label={intl.formatMessage({ id: "syncPrices" })}
                className='mb-1'
                name='syncPrices'
                valuePropName='checked'
                tooltip={
                  <Text className='my-font'>
                    {intl.formatMessage({ id: "syncOptionsDis" })}
                  </Text>
                }
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  disabled={loading}
                  loading={loading}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify='space-between' align='middle' type='flex'>
            <Col md={11} sm={24} xs={24}>
              <Form.Item
                label={intl.formatMessage({ id: "syncPhotos" })}
                className='mb-1'
                name='syncPhotos'
                valuePropName='checked'
                tooltip={
                  <Text className='my-font'>
                    {intl.formatMessage({ id: "syncOptionsDis" })}
                  </Text>
                }
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  disabled={loading}
                  loading={loading}
                />
              </Form.Item>
            </Col>
            {/* <Col md={11} sm={24} xs={24}>
              <Form.Item
                label={intl.formatMessage({ id: "syncAvailability" })}
                className='mb-1'
                name='syncAvailability'
                valuePropName='checked'
                tooltip={
                  <Text className='my-font'>
                    {intl.formatMessage({ id: "syncOptionsDis" })}
                  </Text>
                }
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  disabled={true}
                  loading={loading}
                />
              </Form.Item>
            </Col> */}
          </Row>
          {/* <Row justify='space-between' align='middle' type='flex'>
            <Col md={11} sm={24} xs={24}>
              <Form.Item
                label={intl.formatMessage({ id: "syncCategory" })}
                className='mb-1'
                name='syncCategory'
                valuePropName='checked'
                tooltip={
                  <Text className='my-font'>
                    {intl.formatMessage({ id: "syncOptionsDis" })}
                  </Text>
                }
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  disabled={loading}
                  loading={loading}
                />
              </Form.Item>
            </Col>
            <Col md={11} sm={24} xs={24}>
            <Form.Item
                label={intl.formatMessage({ id: "syncModifiersNames" })}
                className='mb-1'
                name='syncModifiersNames'
                valuePropName='checked'
                tooltip={
                  <Text className='my-font'>
                    {intl.formatMessage({ id: "syncOptionsDis" })}
                  </Text>
                }
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  disabled={loading}
                  loading={loading}
                />
              </Form.Item>
            </Col>
          </Row> */}

          <Divider />
          <Form.Item
            label={intl.formatMessage({ id: "integrationStatus" })}
            className='mb-1'
            name='status'
            valuePropName='checked'
          >
            <Switch
              checkedChildren={
                <p>
                  {intl.formatMessage({
                    id: "active",
                  })}
                </p>
              }
              unCheckedChildren={
                <p>
                  {intl.formatMessage({
                    id: "disabled",
                  })}
                </p>
              }
              disabled={loading}
              loading={loading}
            />
          </Form.Item>

          <Divider />
          <Space direction='horizontal'>
            <Form.Item>
              <CustomButton
                htmlType='submit'
                text={intl.formatMessage({ id: "save" })}
                className={`btnRegister btn-text border-8`}
                loading={loading}
                disabled={loading}
                type='primary'
              />
            </Form.Item>

            <Form.Item>
              <CustomButton
                type='text'
                text={intl.formatMessage({ id: "cancel" })}
                disabled={loading}
                onClick={() => {
                  hide();
                }}
              />
            </Form.Item>
          </Space>
        </div>
      </Form>
    </Modal>
  );
};

export default MarnModal;
