import React, { useState, useEffect } from "react";
import "chart.js/auto";
import { Chart,  } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import COLORS from "../../../Style/colors";
import moment from "moment";
import { Typography, Space, Spin } from "antd";
import { useIntl } from "react-intl";
import MyTimePicker from "./MyTimePicker";
import { getAnalyticsVisitForTime } from "../../../API/fetch";
import { setGoogleDataAction } from "../../../redux/reducers/analyticsReducer";

const { Title,  } = Typography;

const UserVisits = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { googleData } = useSelector((state) => state.analytics);
  const { restaurant } = useSelector((state) => state.restaurant);
  const { rtl } = useSelector((state) => state.settings);
  const [labels, setLabels] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (googleData?.usersByDates?.length > 0) {
      let labels = [];
      let data = [];
      googleData?.usersByDates.forEach((one) => {
        labels.push(moment(one?.date, "YYYY-MM-DD").format("DD-MM"));
        data.push(one?.views);
      });
      setLabels(labels);
      setData(data);
    }
  }, [googleData, rtl]);

  const getDataForTime = async ({ start, end }) => {
    try {
      setLoading(true);
      const data = {
        start,
        end,
        id: restaurant?._id,
      };
      const res = await getAnalyticsVisitForTime(data);
      if (res.data?.status === 'success') {
        const copy = {
          ...googleData,
          usersByDates: res.data.usersByDates,
        };
        dispatch(setGoogleDataAction(copy));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div>
      <Spin spinning={loading}>
        <Space
          direction='horizontal'
          style={{ width: "100%", justifyContent: "space-between" }}
        >
          <div>
            <Title style={{ marginBottom: 0, paddingBottom: 0 }} level={4}>
              {intl.formatMessage({ id: "allUsers" })}
            </Title>
            <Title
              style={{
                marginBottom: 0,
                paddingBottom: 0,
                marginTop: 0,
                paddingTop: 0,
              }}
              level={5}
            >
              {googleData?.usersByDates?.length ?  new Intl.NumberFormat().format(googleData?.usersByDates?.reduce((a,b) => a +b.views,0)) : null} -{" "}
              {intl.formatMessage({ id: "visit" })}
            </Title>
          </div>
          <MyTimePicker loading={loading} getData={getDataForTime} />
        </Space>
        <Chart
          type='line'
          data={{
            labels,
            datasets: [
              {
                label: "",
                data: data,
                borderWidth: 2,
                borderColor: COLORS.primary,
                tension: 0.6,
                pointStyle: "circle",
                pointBackgroundColor: COLORS.primary,
                hoverBackgroundColor: COLORS.lightBlue,
                hoverBorderColor: COLORS.primary,
                fill: "start",
                backgroundColor: "rgba(255,255,255,0.7)",
              },
            ],
          }}
          options={{
            responsive: true,
            scales: {
              y: {
                beginAtZero: true,
              },
            },
            plugins: {
              subtitle: {
                display: false,
              },
              legend: {
                display: false,
              },
            },
          }}
        />
      </Spin>
    </div>
  );
};

export default UserVisits;
