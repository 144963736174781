import {
  Row,
  Card,
  Col,
  Descriptions,
  Tag,
  Space,
  Typography,
  Divider,
  Select,
} from "antd";
import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import COLORS from "../../Style/colors";
import moment from "moment";
import { SERVICES_DURATION, SERVICES_INFO } from "./period-data";
import { useSelector } from "react-redux";
import CustomButton from "../../Components/CustomButton";
import { MdVerified } from "react-icons/md";
import OrderSummary from "./OrderSummary";
import { getVatAmount } from "../../helpers";

const { Title, Text, Link } = Typography;
const { Meta } = Card;

const NewServicesView = ({
  services,
  onPayment,
  loading,
  email,
  setEmail,
  vat,
  setVat,
  vatError,
  setVatError,
  doNotHaveVat,
  setDoNotHaveVat,
  cart,
  setCart,
  GoSell,
  setShowPaymentView,
}) => {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  const { user } = useSelector((state) => state.auth);
  const { tables } = useSelector((state) => state.table);
  const { allBranches } = useSelector((state) => state.branch);
  const [showAllPayments, setShowAllPayments] = useState(false)

  const [plan, setPlan] = useState({});
  const [isNewSub, setIsNewSub] = useState(false);
  useEffect(() => {
    if (user?.services?.length === 0) {
      setIsNewSub(true);
      return;
    }
    if (
      user?.services?.length === 1 &&
      user?.services[0]?.serviceType === "TRY"
    ) {
      setIsNewSub(true);
      return;
    }
    let totalPaid = 0;
    user?.services?.forEach((service) => {
      totalPaid += service?.amountPaid;
    });
    if (totalPaid === 0) {
      setIsNewSub(true);
    }
  }, [user]);
  // const [creditAmount, setCreditAmount] = useState(0);

  // useEffect(() => {
  //   if (services?.length) {
  //     // loop through the services and check if it is not expired;
  //     let remainingMoney = 0;
  //     const newServices = services.forEach((service) => {
  //       const today = moment().startOf("day");
  //       const serviceEnd = moment(service.serviceEnd).endOf("day");
  //       const remainingDays = serviceEnd.diff(today, "days");
  //       if (remainingDays >= 0) {
  //         // get the price for one day;
  //         // calculate the days between serviceStart and serviceEnd;
  //         const days = serviceEnd.diff(
  //           moment(service.serviceStart).startOf("day"),
  //           "days"
  //         );
  //         const priceForOneDay = service?.amountPaid / days;
  //         const paidTotal = remainingDays * priceForOneDay;
  //         remainingMoney += paidTotal;
  //       }
  //     });
  //     setCreditAmount(remainingMoney);
  //   }
  // }, []);

  function serviceTags(tags) {
    if (!tags?.length) return <div />;
    return tags.map((tag, index) => {
      return (
        <Tag key={index} color={COLORS.primary} style={{ borderRadius: 5 }}>
          {intl.formatMessage({ id: tag })}
        </Tag>
      );
    });
  }

  return (
    <div
      style={
        {
          // backgroundColor: COLORS.white,
          // marginTop: 10,
          // width: "92%",
          // minHeight: "70vh",
          // marginRight: "auto",
          // marginLeft: "auto",
          // borderRadius: 10,
          // paddingBottom: 15,
        }
      }
      // className='light-shadow'
    >
      <div className='px-5 py-5'>
        <Descriptions className='my-font' bordered column={2}>
          <Descriptions.Item
            label={
              <Space direction="horizontal" size={10}>
                <Text className='my-font'>
                  {intl.formatMessage({ id: "service" })}{" "}
                </Text>{" "}
                <Text className='my-font' type="secondary" underline onClick={() => {
                  setShowAllPayments(i => !i)
                }}>
                  {intl.formatMessage({ id:showAllPayments ? "hideAll" :  "showAll" })}{" "}
                </Text>{" "}
              </Space>
            }
          >
            {intl.formatMessage({ id: "dateStart" })}
          </Descriptions.Item>
          <Descriptions.Item
            label={intl.formatMessage({ id: "dateEnd" })}
            labelStyle={{ backgroundColor: COLORS.white }}
          >
            {intl.formatMessage({ id: "amountPaid" })}
          </Descriptions.Item>
          {services?.length
            ? services?.map((service) => {
                let isBeforeNow = moment(service.serviceEnd).isBefore(moment());
                let isPaymentFromOrder = service?.paymentType === "FROM_ORDERS";
                const today = moment().startOf("day");
                const serviceEnd = moment(service.serviceEnd).endOf("day");
                let show = true;
                if(showAllPayments === false && serviceEnd.isBefore(today)){
                  show = false;
                }
                if(show === false){
                  return null
                }
                return (
                  <React.Fragment key={service?.serviceType}>
                    <Descriptions.Item label={serviceTags(service?.services)}>
                      {moment(service?.serviceStart)?.format("YYYY-MM-DD")}
                    </Descriptions.Item>
                    <Descriptions.Item
                      labelStyle={{
                        color: isBeforeNow ? "red" : "green",
                        backgroundColor: "white",
                      }}
                      label={moment(service?.serviceEnd)?.format("YYYY-MM-DD")}
                    >
                      {service?.amountPaid === 0
                        ? isPaymentFromOrder
                          ? null
                          : intl.formatMessage({ id: "freee" })
                        : service?.amountPaid}{" "}
                      {isPaymentFromOrder ? (
                        <Tag color='red'>
                          {service?.price}{" "}
                          {intl.formatMessage({ id: "cutFromPayments" })}
                        </Tag>
                      ) : null}
                      {service?.amountPaid === 0 && !isPaymentFromOrder ? (
                        <MdVerified />
                      ) : null}
                    </Descriptions.Item>
                  </React.Fragment>
                );
              })
            : null}
        </Descriptions>
        <Divider />
        <Title level={5} type='primary'>
          {intl.formatMessage({ id: "chooseService" })}:
        </Title>
        <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }} justify='center'>
          {Object.keys(SERVICES_INFO).map((item) => {
            let isDisabled = false;
            if (
              SERVICES_INFO[item].Name === "ORDER_A" &&
              tables.length / allBranches.length > 20
            ) {
              isDisabled = true;
            }

            return (
              <Col
                md={8}
                sm={12}
                xs={12}
                style={{
                  marginTop: 18,
                }}
                key={item}
              >
                <Card
                  hoverable
                  style={{
                    width: 240,
                    borderRadius: 10,
                    textAlign: "center",
                  }}
                >
                  <Meta
                    title={
                      <Title
                        level={5}
                        style={{ marginBottom: 0, paddingBottom: 0 }}
                      >
                        {plan[SERVICES_INFO[item].Name] ? (
                          <MdVerified
                            style={{ marginLeft: 10 }}
                            size={18}
                            color={COLORS.primary}
                          />
                        ) : null}
                        {intl.formatMessage({ id: SERVICES_INFO[item].Name })}
                      </Title>
                    }
                    description={
                      SERVICES_INFO[item].price +
                      " / " +
                      intl.formatMessage({ id: "month" })
                    }
                  />
                  <div
                    style={{
                      textAlign: rtl ? "right" : "left",
                      marginTop: 10,
                    }}
                  >
                    <Text className='my-font'>
                      {intl.formatMessage({ id: "chooseDuration" })}
                    </Text>
                    <Select
                      style={{
                        width: "100%",
                      }}
                      disabled={isDisabled}
                      className='my-font'
                      onChange={(value) => {
                        let copy = { ...plan };
                        if (value) {
                          if (copy[SERVICES_INFO[item].Name]) {
                            copy[SERVICES_INFO[item].Name].duration = value;
                            copy[SERVICES_INFO[item].Name].total =
                              value * SERVICES_INFO[item].price;
                          } else {
                            copy[SERVICES_INFO[item].Name] = {
                              price: SERVICES_INFO[item].price,
                              duration: value,
                              total: SERVICES_INFO[item].price * value,
                            };
                          }
                        } else {
                          delete copy[SERVICES_INFO[item].Name];
                        }
                        setPlan(copy);
                      }}
                    >
                      {SERVICES_DURATION.filter((du) => {
                        if (isNewSub) {
                          return du.period !== 1;
                        }
                        return true;
                      }).map((duration) => {
                        return (
                          <Select.Option
                            className='my-font'
                            value={duration.period}
                            key={duration.period}
                          >
                            {rtl ? duration.ar : duration.en}
                          </Select.Option>
                        );
                      })}
                    </Select>
                    {/* Display supTotal of this plan */}
                    {plan[SERVICES_INFO[item].Name] ? (
                      <div>
                        <Space
                          direction='horizontal'
                          style={{
                            width: "100%",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: 10,
                            marginBottom: 10,
                          }}
                        >
                          <Text className='my-font'>
                            {intl.formatMessage({ id: "price" })}:
                          </Text>
                          <Text className='my-font'>
                            {plan[SERVICES_INFO[item].Name].total} SAR
                          </Text>
                        </Space>
                        <Space
                          direction='horizontal'
                          style={{
                            width: "100%",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: 10,
                            marginBottom: 10,
                          }}
                        >
                          <Text className='my-font'>
                            {intl.formatMessage({ id: "vat" })}:
                          </Text>
                          <Text className='my-font'>
                            {getVatAmount(
                              plan[SERVICES_INFO[item].Name].total
                            )?.toFixed(2)}{" "}
                            SAR
                          </Text>
                        </Space>
                      </div>
                    ) : null}
                  </div>
                  <CustomButton
                    text={intl.formatMessage({ id: "addition" })}
                    disabled={isDisabled}
                    style={{
                      width: "90%",
                      borderRadius: 10,
                      alignSelf: "center",
                      marginBottom: 10,
                      marginTop: 5,
                    }}
                    type='primary'
                    onClick={() => {
                      // setCart(plan);
                      // check if cart has the same service;
                      let copy = { ...cart };
                      if (plan[SERVICES_INFO[item].Name]) {
                        copy[SERVICES_INFO[item].Name] = {
                          ...plan[SERVICES_INFO[item].Name],
                        };
                      }
                      setCart(copy);
                    }}
                  />
                  <Link href='https://iwaiter.net' target='_blank'>
                    <Text type='secondary' className='mx-2 mt-5'>
                      {intl.formatMessage({ id: "checkServiceDetails" })}
                    </Text>
                  </Link>
                </Card>
              </Col>
            );
          })}
        </Row>
        <OrderSummary
          cart={cart}
          setCart={setCart}
          loading={loading}
          email={email}
          setEmail={setEmail}
          vat={vat}
          setVat={setVat}
          vatError={vatError}
          setVatError={setVatError}
          doNotHaveVat={doNotHaveVat}
          setDoNotHaveVat={setDoNotHaveVat}
          GoSell={GoSell}
          setShowPaymentView={setShowPaymentView}
        />
      </div>
    </div>
  );
};

export default NewServicesView;
