import React, { useEffect, useState } from "react";
import { Space, Typography, Empty, Collapse, Table } from "antd";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import ModifierCard from "./Components/ModifierCard";
import { MinusCircleTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import MenuIsSync from "../../../Components/MenuIsSync";
//Sort Modifier
import { MenuOutlined } from "@ant-design/icons";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import CustomButton from "../../../Components/CustomButton";
import { postModifiersSort } from "../../../API/fetch";
import openNotification from "../../../Components/Notifications";
import { setModifiersAction } from "../../../redux/reducers/menuReducer";

const { Text, Title } = Typography;
const { Panel } = Collapse;

//Sorted Modifier
const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
));
const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

const Modifiers = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  const { modifiers } = useSelector((state) => state.menu);
  const { integrations } = useSelector((state) => state.restaurant);
  const [activeKey, setActiveKey] = useState("");
  //sorting modifiers
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [didSortChange, setDidSortChange] = useState(false);
  useEffect(() => {
    if (modifiers?.length) {
      setDataSource(
        modifiers?.map((mod, index) => {
          return {
            key: mod?._id,
            _id: mod?._id,
            name: mod?.privateName,
            index: index,
            order: mod?.order,
            modifier: mod,
          };
        })
      );
    }
  }, [modifiers]);
  const columns = [
    {
      title: intl.formatMessage({ id: "sort" }),
      dataIndex: "sort",
      width: 30,
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "name",
      key: "name",
    },
  ];

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        [].concat(dataSource),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      setDataSource(newData);
      setDidSortChange(true);
    }
  };
  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass='row-dragging sort-row'
      style={{ flexDirection: rtl ? "row-reverse" : "row" }}
      onSortEnd={onSortEnd}
      {...props}
    />
  );
  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = dataSource.findIndex(
      (x) => x.index === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };
  //Save the sort in the backend
  const saveSortModifier = async () => {
    setLoading(true);
    const modifiersAll = [];
    dataSource?.forEach((mod, i) => {
      modifiersAll?.push({
        _id: mod?._id,
        order: i,
      });
    });
    const data = {
      modifiers: modifiersAll,
    };
    try {
      const res = await postModifiersSort(data);
      if (res?.data?.status === "success") {
        setLoading(false);
        const newModifers = modifiers?.map((mod) => {
          const order = dataSource?.findIndex((el) => mod?._id === el?._id);
          return {
            ...mod,
            order,
          };
        });
        dispatch(setModifiersAction(newModifers));
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
        setDidSortChange(false);
      }
    } catch (error) {
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Space direction='vertical'>
        <Title>{intl.formatMessage({ id: "modifiers" })}</Title>
        {integrations?.posName && integrations?.posName !== "NON" ? (
          <MenuIsSync />
        ) : null}
        <Text className='my-font' style={{ marginBottom: 0, marginTop: 0 }}>
          {intl.formatMessage({ id: "modifiersDes" })}
        </Text>
      </Space>
      <div style={{ width: "100%", textAlign: "center" }} className='my-3'>
        <CustomButton
          text={intl.formatMessage({ id: "saveSort" })}
          type='primary'
          className={`btnRegister btn-text border-8 px-5 `}
          loading={loading}
          disabled={!didSortChange}
          onClick={saveSortModifier}
        />
      </div>
      {modifiers?.length ? (
        <div className='mb-3 mt-5'>
          <Table
            className='components-table-demo-nested light-shadow'
            pagination={false}
            dataSource={dataSource}
            columns={columns}
            expandable={{
              expandedRowRender: (data, index, _, expanded) =>
                expanded ? (
                  <ModifierCard
                    modifier={data?.modifier}
                    order={index}
                    isDisabled={
                      integrations?.posName &&
                      integrations?.posName !== "NON" &&
                      integrations?.posSyncMenu === true
                        ? true
                        : false
                    }
                  />
                ) : null,
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <MinusCircleTwoTone onClick={(e) => onExpand(record, e)} />
                ) : (
                  <PlusCircleTwoTone onClick={(e) => onExpand(record, e)} />
                ),
            }}
            rowKey='index'
            components={{
              body: {
                wrapper: DraggableContainer,
                row: DraggableBodyRow,
              },
            }}
          />
          <Collapse
            bordered={false}
            accordion={true}
            onChange={(key) => setActiveKey(key)}
            className='border-8 light-shadow'
            destroyInactivePanel={false}
          >
            <Panel
              showArrow={false}
              collapsible={
                integrations?.posName &&
                integrations?.posName !== "NON" &&
                integrations?.posSyncMenu === true
                  ? "disabled"
                  : "header"
              }
              style={{ marginTop: 10 }}
              header={
                <Space direction='horizontal' align='center' size='middle'>
                  {activeKey === "New" ? (
                    <MinusCircleTwoTone />
                  ) : (
                    <PlusCircleTwoTone />
                  )}

                  <Title level={5} className='my-font'>
                    {intl.formatMessage({ id: "addNewModifier" })}
                  </Title>
                </Space>
              }
              key={"New"}
            >
              <ModifierCard modifier={null} order={modifiers.length}  isDisabled={
                      integrations?.posName &&
                      integrations?.posName !== "NON" &&
                      integrations?.posSyncMenu === true
                        ? true
                        : false
                    }  />
            </Panel>
          </Collapse>
        </div>
      ) : (
        <div className='mt-4'>
          <Collapse
            bordered={false}
            accordion={true}
            onChange={(key) => setActiveKey(key)}
            className='border-8 light-shadow'
            destroyInactivePanel={false}
          >
            <Panel
              showArrow={false}
              style={{ marginTop: 10 }}
              collapsible={
                integrations?.posName &&
                integrations?.posName !== "NON" &&
                integrations?.posSyncMenu === true
                  ? "disabled"
                  : "header"
              }
              header={
                <Space direction='horizontal' align='center' size='middle'>
                  {activeKey === "New" ? (
                    <MinusCircleTwoTone />
                  ) : (
                    <PlusCircleTwoTone />
                  )}

                  <Title level={5} className='my-font'>
                    {intl.formatMessage({ id: "addNewModifier" })}
                  </Title>
                </Space>
              }
              key={"New"}
            >
              <ModifierCard modifier={null}  isDisabled={
                      integrations?.posName &&
                      integrations?.posName !== "NON" &&
                      integrations?.posSyncMenu === true
                        ? true
                        : false
                    } />
            </Panel>
          </Collapse>
          <Empty
            image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
            imageStyle={{
              height: 250,
            }}
            description={
              <h2 className='my-font'>
                {intl.formatMessage({ id: "noModifiers" })}
              </h2>
            }
          ></Empty>
        </div>
      )}
    </div>
  );
};

export default Modifiers;
