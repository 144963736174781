import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Typography } from "antd";

const { Text } = Typography;
const MenuIsSync = () => {
  const intl = useIntl();
  const { integrations } = useSelector((state) => state.restaurant);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",

      }}
      className="mb-3"
    >
      <Text type='danger' className="my-font">
        {intl.formatMessage({ id: integrations?.posName === 'foodics' ? `menu_is_sync_foodisc` : 'menu_is_sync_Marn', })}
      </Text>
    </div>
  );
};

export default MenuIsSync;
