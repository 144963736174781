import React, { useState } from "react";
import { Col, Row, Image, Form, Input, Typography, Select } from "antd";
import { useIntl } from "react-intl";
import LOGO from "../../../assets/images/main-logo.png";
import PlaceHolder from "../../../assets/images/log-in.png";
import ICON from "../../../assets/images/icon-white.png";
import CustomButton from "../../../Components/CustomButton";
import phoneCodes from "../PhoneCods.json";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguageAction } from "../../../redux/reducers/settingsReducer";
import { checkIfValidPhone } from "../../../helpers/index";
import { postForgetPassword, postResetPassword } from "../../../API/fetch";
import openNotification from "../../../Components/Notifications/index";
const { Text, Link } = Typography;
const { Option } = Select;

const ForgetPassword = ({ goBack }) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(false);
  const [sentCode, setSentCode] = useState(false);
  const [fullPhone, setFullPhone] = useState("");
  const onFinish = async (values) => {
    setLoading(true);
    const fullPhone = `${values.prefix.substring(1)}${values.phone}`;
    try {
      const res = await postForgetPassword({ phone: fullPhone });
      if (res.data.status === "success") {
        setLoading(false);
        setFullPhone(fullPhone);
        openNotification({
          title: intl.formatMessage({ id: "done" }),
          description: intl.formatMessage({ id: "sentVerification" }),
          type: "success",
          rtl,
        });
        setSentCode(true);
      } else {
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "errorfetch" }),
          type: "error",
          rtl,
        });
        setLoading(false);
      }
    } catch (error) {
      if (error?.response?.data?.message === "User does not exist") {
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "userNotExist" }),
          type: "error",
          rtl,
        });
      } else {
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "errorfetch" }),
          type: "error",
          rtl,
        });
      }
      setLoading(false);
    }
  };

  const resetPassword = async (values) => {
    setLoading(true);
    const data = {
      smsPassword: values.smsPassword,
      newPassword: values.newPassword,
      newPinCode: values.newPinCode,
      phone: fullPhone,
    };
    try {
      const res = await postResetPassword(data);
      if (res.data.status === "success") {
        openNotification({
          title: intl.formatMessage({ id: "done" }),
          description: intl.formatMessage({ id: "passwordChanged" }),
          type: "success",
          rtl,
        });
        setLoading(false);
        goBack();
      } else {
        setLoading(false);
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "errorfetch" }),
          type: "error",
          rtl,
        });
      }
    } catch (error) {
      if (error?.response?.data?.message === "Verification Code error") {
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "errorCode" }),
          type: "error",
          rtl,
        });
      } else if (
        error?.response?.data?.message === "Verification code expired"
      ) {
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "verificationExpired" }),
          type: "error",
          rtl,
        });
      }else if(error?.response?.data?.message === "Pin code is already associated with another waiter") {
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "pinCodeIsDuplicated" }),
          type: "error",
          rtl,
        });
      } else {
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "errorfetch" }),
          type: "error",
          rtl,
        });
      }
      setLoading(false);
    }
  };

  const prefixSelector = (
    <Form.Item name='prefix' noStyle>
      <Select
        style={
          {
            // width: 150,
          }
        }
      >
        {phoneCodes.map((info) => {
          return (
            <Option
              key={`${info.dial_code}-${info.name}`}
              value={info.dial_code}
            >
              {info.dial_code} {info.name}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );

  return (
    <Row style={{ minHeight: "100vh" }}>
      <Col
        xs={24}
        sm={12}
        type='flex'
        justify='center'
        align='center'
        direction='column'
        className={"authRightSide"}
      >
        <div className='changeLanguage'>
          <CustomButton
            text={intl.formatMessage({ id: "oppLanguage" })}
            className={`btn-text border-8 px-5 pb-5`}
            loading={false}
            type='primary'
            onClick={() => {
              dispatch(changeLanguageAction());
            }}
          />
        </div>
        <Row type='flex' justify='center' align='middle'>
          <Col span={18}>
            <Row justify='center'>
              <Image
                preview={false}
                width={300}
                src={LOGO}
                className='logo-Login'
              />
            </Row>

            <Form
              name='basic'
              form={form}
              className='form-container'
              layout='vertical'
              onFinish={sentCode ? resetPassword : onFinish}
              initialValues={{
                prefix: phoneCodes[0].dial_code,
              }}
            >
              <Row justify='space-around' align='middle'>
                {!sentCode ? (
                  <Col span={24}>
                    <Form.Item
                      name='phone'
                      label={intl.formatMessage({ id: "phone" })}
                      rules={
                        sentCode
                          ? []
                          : [
                              {
                                required: true,
                                message: intl.formatMessage({
                                  id: "errorphone",
                                }),
                              },
                              {
                                validator: (_, value) => {
                                  const prefix = form.getFieldValue("prefix");
                                  if (!prefix) {
                                    return Promise.reject(
                                      intl.formatMessage({ id: "errorphone" })
                                    );
                                  }
                                  let valid = checkIfValidPhone(
                                    `${prefix}${value}`
                                  );
                                  if (valid) {
                                    return Promise.resolve();
                                  } else {
                                    return Promise.reject(
                                      intl.formatMessage({ id: "errorphone" })
                                    );
                                  }
                                },
                              },
                            ]
                      }
                    >
                      <Input
                        addonBefore={rtl ? null : prefixSelector}
                        addonAfter={rtl ? prefixSelector : null}
                        style={{
                          minWidth: 280,
                        }}
                      />
                    </Form.Item>
                  </Col>
                ) : (
                  <>
                    <Col span={23}>
                      <Form.Item
                        label={intl.formatMessage({ id: "smsPassword" })}
                        rules={
                          sentCode
                            ? [
                                {
                                  required: true,
                                  message: intl.formatMessage({
                                    id: "errorCode",
                                  }),
                                },
                              ]
                            : []
                        }
                        name='smsPassword'
                      >
                        <Input.Password className={"input"} />
                      </Form.Item>
                    </Col>
                    <Col span={23}>
                      <Form.Item
                        label={intl.formatMessage({ id: "newPassword" })}
                        className=''
                        rules={
                          sentCode
                            ? [
                                {
                                  required: true,
                                  message: intl.formatMessage({
                                    id: "errorpassword",
                                  }),
                                },
                              ]
                            : []
                        }
                        name='newPassword'
                      >
                        <Input.Password className={"input"} />
                      </Form.Item>
                    </Col>
                    <Col span={23}>
                      <Form.Item
                        label={intl.formatMessage({ id: "newPinCode" })}
                        className=''
                        rules={
                          sentCode
                            ? [
                                {
                                  required: true,
                                  message: intl.formatMessage({
                                    id: "errornewpincode",
                                  }),
                                },
                              ]
                            : []
                        }
                        name='newPinCode'
                      >
                        <Input.Password className={"input"} />
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Row>

              <Row justify='center' align='middle'>
                <Col span={24}>
                  <Form.Item>
                    <CustomButton
                      htmlType='submit'
                      text={intl.formatMessage({ id: "send" })}
                      className={`btnRegister btn-text border-8`}
                      loading={loading}
                      type='primary'
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Text type='secondary'>
                <Link className='pointer' onClick={goBack}>
                  {intl.formatMessage({ id: "back" })}
                </Link>
              </Text>
            </Form>
          </Col>
        </Row>
      </Col>
      <Col xs={0} sm={12} type='flex' className={"authLeftSide"}>
        <Image
          preview={false}
          src={ICON}
          style={{ maxWidth: 150, marginBottom: 50, borderRadius: 30 }}
        />
        <Image preview={false} src={PlaceHolder} width={"70%"} />
      </Col>
    </Row>
  );
};

export default ForgetPassword;
