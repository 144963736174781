import React, { useEffect, useState } from "react";
import {
  Typography,
  DatePicker,
  Form,
  Select,
  Spin,
  Result,
  Input,
  Row,
  Col,
  Divider,
  Descriptions,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { createCampaigns, postSearchUsers } from "../../../API/fetch";
import openNotification from "../../../Components/Notifications";
import CustomButton from "../../../Components/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { CUSTOMER_TYPE } from "../../../Types";
import {addCampaignAction} from '../../../redux/reducers/campaignReducer'

const { RangePicker } = DatePicker;

const { Text, Title, Link } = Typography;
const { Option } = Select;
const NewCampaigns = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const { waiter } = useSelector((state) => state.auth);
  const { restaurant } = useSelector((state) => state.restaurant);
  const { allBranches } = useSelector((state) => state.branch);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [formattedUsers, setFormattedUsers] = useState([]);

  const onFinish = async (values) => {
    console.log("e", values);
    setLoading(true);
    try {
      const messageCount = getSMSCount(
        formattedUsers?.length,
        values.message?.length
      );
      const data = {
        marketing: {
          name: values.name,
          cost: (messageCount * 0.17).toFixed(2),
          message: values.message,
          senderName: values.senderName,
          numbers: formattedUsers?.map((user) => user.phone),
          countOfMessages: messageCount,
          status: "pending",
        },
      };
      const res = await createCampaigns(data);
      if (res?.data?.campaign) {
        dispatch(addCampaignAction(res?.data?.campaign));
        openNotification({
          type: "success",
          title: intl.formatMessage({ id: "success" }),
          description: intl.formatMessage({ id: "successCreate" }),
          rtl,
        });
        navigate("/campaigns");
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      openNotification({
        type: "error",
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        rtl,
      });
    }
  };

  const fetchUsers = async (startDate, endDate, page, type, badge) => {
    try {
      setLoading(true);
      const data = {
        userType: type,
        userBadge: badge,
        dateStart: startDate,
        dateEnd: endDate,
        code: waiter.code,
      };

      const res = await postSearchUsers(data);

      if (res.data?.users?.length) {
        const formattedUsers = res.data.users?.map((user, i) => {
          return {
            key: user?._id,
            badge: user?.badge,
            name: user?.name,
            phone: user?.phone,
          };
        });
        setFormattedUsers(formattedUsers);
      } else {
        setFormattedUsers([]);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getSMSCount = (usersCount, messageLength) => {
    let total = 0;
    // get the one message for a user,
    // each 70 characters is one message
    // each message costs 0.2
    const messagesCount = Math.ceil(messageLength / 70);
    total = usersCount * messagesCount;
    return total;
  };

  const getVatAmount = (amount) => {
    if (typeof amount !== "number") {
      parseFloat(amount);
    }
    if (isNaN(amount)) {
      return 0;
    }
    return amount * 0.15;
  };

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Spin spinning={loading} tip={intl.formatMessage({ id: "loadingData" })}>
        <div
          style={{
            width: "100%",
            marginBottom: 10,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Title level={3}>{intl.formatMessage({ id: "marketing" })}</Title>
          <CustomButton
            text={intl.formatMessage({ id: "goBack" })}
            className={`btnRegister btn-text border-8  mx-5`}
            onClick={() => {
              navigate("/campaigns");
            }}
            type='secondary'
          />
        </div>

        <div
          style={{
            backgroundColor: "white",
            padding: "10px 20px 20px",
            borderRadius: 7,
            marginBottom: 20,
          }}
          className='light-shadow'
        >
          <Form
            form={form}
            layout='vertical'
            onFinish={onFinish}
            initialValues={{
              name: "",
              senderName: "iWaiter-AD",
              message: "",
              type: "all",
              badge: "all",
            }}
          >
            {() => {
              return (
                <>
                  <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        label={intl.formatMessage({ id: "campaignsName" })}
                        name='name'
                        rules={[
                          {
                            required: true,
                            message: intl.formatMessage({ id: "required" }),
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        label={intl.formatMessage({ id: "senderName" })}
                        name='senderName'
                        rules={[
                          {
                            required: true,
                            message: intl.formatMessage({ id: "required" }),
                          },
                        ]}
                      >
                        <Input disabled value={"iWaiter-AD"} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                    <Col md={24} sm={24} xs={24}>
                      <Form.Item
                        label={intl.formatMessage({ id: "message" })}
                        name='message'
                        rules={[
                          {
                            required: true,
                            message: intl.formatMessage({ id: "required" }),
                          },
                        ]}
                      >
                        <Input.TextArea showCount rows={5} />
                      </Form.Item>
                      <Text className="my-font" >{intl.formatMessage({id: 'smsDes'})}</Text>
                    </Col>
                  </Row>
                  <Divider />
                  <Row
                    gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
                    className='mx-1'
                  >
                    <Title level={4}>
                      {intl.formatMessage({ id: "target" })}
                    </Title>
                  </Row>
                  <Row
                    gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
                    className='mx-1'
                  >
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        rules={[
                          { required: true, message: "Please select Date" },
                        ]}
                        name='date'
                        label={intl.formatMessage({ id: "date" })}
                      >
                        <RangePicker style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        placeholder='Please select type'
                        name='type'
                        label={intl.formatMessage({ id: "serviceType" })}
                      >
                        <Select
                          style={{ width: "100%" }}
                          className='my-font'
                          placeholder={intl.formatMessage({
                            id: "serviceType",
                          })}
                          defaultValue='all'
                        >
                          <Option className='my-font' value='all'>
                            {intl.formatMessage({ id: "all" })}
                          </Option>
                          <Option className='my-font' value='inHouse'>
                            {intl.formatMessage({ id: "inHouse" })}
                          </Option>
                          <Option className='my-font' value='pickup'>
                            {intl.formatMessage({ id: "pickup" })}
                          </Option>

                          <Option className='my-font' value='delivery'>
                            {intl.formatMessage({ id: "delivery" })}
                          </Option>
                          <Option className='my-font' value='reservations'>
                            {intl.formatMessage({ id: "reservation" })}
                          </Option>
                          <Option className='my-font' value='waitingList'>
                            {intl.formatMessage({ id: "waiting" })}
                          </Option>
                          <Option className='my-font' value='CUSTOM_ORDER'>
                            {intl.formatMessage({ id: "CUSTOM_ORDER" })}
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row
                    gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
                    className='mx-1'
                  >
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        placeholder='Please select type'
                        name='badge'
                        label={intl.formatMessage({ id: "userType" })}
                      >
                        <Select
                          style={{ width: "100%" }}
                          className='my-font'
                          placeholder={intl.formatMessage({ id: "userType" })}
                          defaultValue='all'
                        >
                          <Option className='my-font' value={"all"}>
                            {intl.formatMessage({ id: "all" })}
                          </Option>
                          {Object.keys(CUSTOMER_TYPE).map((key) => {
                            return (
                              <Option
                                className='my-font'
                                value={CUSTOMER_TYPE[key]}
                              >
                                {intl.formatMessage({ id: CUSTOMER_TYPE[key] })}
                              </Option>
                            );
                          })}
                        </Select>
                        <Text 
                        onClick={() => {
                          navigate('/allUsers')
                        }}
                        underline className="my-font pointer">
                          {intl.formatMessage({id: 'controlUserTypes'})}
                        </Text>
                      </Form.Item>
                    </Col>
                    <Col md={6} sm={24} xs={24}>
                      <Form.Item>
                        <CustomButton
                          text={intl.formatMessage({ id: "search" })}
                          className={`btnRegister btn-text border-8 px-5 mt-5`}
                          loading={loading}
                          disabled={loading}
                          onClick={() => {
                            const startDate = form.getFieldValue("date")[0];
                            const endDate = form.getFieldValue("date")[1];
                            const type = form.getFieldValue("type");
                            const badge = form.getFieldValue("badge");

                            fetchUsers(startDate, endDate, 1, type, badge);
                          }}
                          type='primary'
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row
                    gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
                    className='mx-1'
                  >
                    <Col md={24} sm={24} xs={24}>
                      <Title level={4}>
                        {intl.formatMessage(
                          { id: "countInMarketingCampaigns" },
                          { count: formattedUsers?.length }
                        )}
                      </Title>
                    </Col>
                  </Row>

                  <Divider />
                  <Row
                    gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
                    className='mx-1'
                  >
                    <Title level={4}>
                      {intl.formatMessage({ id: "cost" })}
                    </Title>
                  </Row>
                  <Descriptions bordered className='my-font'>
                    <Descriptions.Item
                      label={intl.formatMessage({ id: "totalUsers" })}
                    >
                      {formattedUsers?.length}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={intl.formatMessage({ id: "totalLetters" })}
                    >
                      {form.getFieldValue("message")?.length}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={intl.formatMessage({ id: "smsCount" })}
                    >
                      {getSMSCount(
                        formattedUsers?.length,
                        form.getFieldValue("message")?.length
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={intl.formatMessage({ id: "smsCost" })}
                    >
                      20 {intl.formatMessage({ id: "fils" })}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={intl.formatMessage({ id: "cost" })}
                    >
                      {(
                        getSMSCount(
                          formattedUsers?.length,
                          form.getFieldValue("message")?.length
                        ) * 0.17
                      )?.toFixed(2)}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={intl.formatMessage({ id: "vat" })}
                    >
                      {(getVatAmount(
                        getSMSCount(
                          formattedUsers?.length,
                          form.getFieldValue("message")?.length
                        ) * 0.17
                      ))?.toFixed(2)}
                    
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={intl.formatMessage({ id: "totalCost" })}
                      labelStyle={{ fontWeight: "bold" }}
                    >
                      <Text className="my-font" style={{
                        fontWeight: "bold"
                      }}>
                      {
                      ((getVatAmount(
                        getSMSCount(
                          formattedUsers?.length,
                          form.getFieldValue("message")?.length
                        ) * 0.17
                      )) + (getSMSCount(
                        formattedUsers?.length,
                        form.getFieldValue("message")?.length
                      ) * 0.17))?.toFixed(2)
                      } SAR
                      </Text>
                    
                    </Descriptions.Item>
                  </Descriptions>
                  <Form.Item>
                    <CustomButton
                      htmlType='submit'
                      text={intl.formatMessage({ id: "save" })}
                      className={`btnRegister btn-text border-8 mt-5`}
                      loading={loading}
                      disabled={
                        loading ||
                        formattedUsers?.length === 0 ||
                        form.getFieldValue("message")?.length === 0
                      }
                      type='primary'
                    />
                    <CustomButton
                      text={intl.formatMessage({ id: "goBack" })}
                      className={`btnRegister btn-text border-8 mt-5 mx-5`}
                      onClick={() => {
                        navigate("/campaigns");
                      }}
                      type='secondary'
                    />
                  </Form.Item>
                  <Text className='my-font' type='primary'>
                    * {intl.formatMessage({ id: "saveCampaignsBeforeSend" })}
                  </Text>
                </>
              );
            }}
          </Form>
        </div>
      </Spin>
    </div>
  );
};

export default NewCampaigns;
