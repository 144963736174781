import moment from "moment";


export const getTimeForOrder = (order) => {
  let timeFromScan = "-";
  let timeToInService = "-";
  let timeToServed = "-";
  let timeToClose = "-";
  let timeFromOrderToClose = "-";
  let timeFromScanToClose = "-";
  if (order.timeFromScan) {
    timeFromScan = `${Math.abs(
      moment
        .duration(
          moment(order.timeFromScan).diff(
            moment(order.createdAt)
          )
        )
        .asMinutes()
    )?.toFixed(2)} `;
  }
  if (order.inServiceTime) {
    timeToInService = `${Math.abs(
      moment
        .duration(
          moment(order.createdAt).diff(
            moment(order.inServiceTime)
          )
        )
        .asMinutes()
    )?.toFixed(2)} `;
  }
  if (order.inServiceTime && order.servedTime) {
    timeToServed = `${Math.abs(
      moment
        .duration(
          moment(order.inServiceTime).diff(
            moment(order.servedTime)
          )
        )
        .asMinutes()
    )?.toFixed(2)} `;
  }
  if (order.servedTime && order.closedTime) {
    timeToClose = `${Math.abs(
      moment
        .duration(
          moment(order.servedTime).diff(
            moment(order.closedTime)
          )
        )
        .asMinutes()
    )?.toFixed(2)} `;
  }
  if (order.closedTime) {
    timeFromOrderToClose = `${Math.abs(
      moment
        .duration(
          moment(order.createdAt).diff(
            moment(order.closedTime)
          )
        )
        .asMinutes()
    )?.toFixed(2)} `;
  }
  if (order.timeFromScan && order.closedTime) {
    timeFromScanToClose = `${Math.abs(
      moment
        .duration(
          moment(order.timeFromScan).diff(
            moment(order.closedTime)
          )
        )
        .asMinutes()
    )?.toFixed(2)} `;
  }


  return {
    timeFromScan,
    timeToInService,
    timeToServed,
    timeToClose,
    timeFromOrderToClose,
    timeFromScanToClose,
  };
};



export function getAverages(orders) {
  let result = {
    avToOrder: {},
    avOrderInService: {},
    avFromInServiceToServed: {},
    avFromServedToClose: {},
    avFromOrderToClose: {},
    avFromScanToClose: {},
  };
  if (!orders || !orders?.length) return [];

  let avToOrder = [];
  let avOrderInService = [];
  let avFromInServiceToServed = [];
  let avFromServedToClose = [];
  let avFromOrderToClose = [];
  let avFromScanToClose = [];

  orders.forEach((order) => {
    if (order.timeFromScan) {
      avToOrder.push(
        Math.abs(
          moment
            .duration(moment(order.timeFromScan).diff(moment(order.createdAt)))
            .asMinutes()
        )
      );
    }
    if (order.inServiceTime) {
      avOrderInService.push(
        Math.abs(
          moment
            .duration(moment(order.createdAt).diff(moment(order.inServiceTime)))
            .asMinutes()
        )
      );
    }
    if (order.servedTime && order.inServiceTime) {
      avFromInServiceToServed.push(
        Math.abs(
          moment
            .duration(moment(order.inServiceTime).diff(moment(order.servedTime)))
            .asMinutes()
        )
      );
    }
    if (order.servedTime && order.closedTime) {
      avFromServedToClose.push(
        Math.abs(
          moment
            .duration(
              moment(order.servedTime).diff(moment(order.closedTime))
            )
            .asMinutes()
        )
      );
    }
    if (order.closedTime) {
      avFromOrderToClose.push(
        Math.abs(
          moment
            .duration(moment(order.createdAt).diff(moment(order.closedTime)))
            .asMinutes()
        )
      );
    }
    if (order.timeFromScan && order.closedTime) {
      avFromScanToClose.push(
        Math.abs(
          moment
            .duration(moment(order.timeFromScan).diff(moment(order.closedTime)))
            .asMinutes()
        )
      );
    }
  });
  if (avToOrder?.length) {
    result.avToOrder.value = avToOrder.reduce(function (sum, timeInMinutes) {
      return sum + parseFloat(timeInMinutes);
    }, 0) / avToOrder.length;
    result.avToOrder.count = avToOrder.length;
  }
  if (avOrderInService?.length) {
    result.avOrderInService.value = avOrderInService.reduce(function (sum, timeInMinutes) {
      return sum + parseFloat(timeInMinutes);
    }, 0) / avOrderInService.length;
    result.avOrderInService.count = avOrderInService.length;
  }
  if (avFromInServiceToServed?.length) {
    result.avFromInServiceToServed.value = avFromInServiceToServed.reduce(function (sum, timeInMinutes) {
      return sum + parseFloat(timeInMinutes);
    }, 0) / avFromInServiceToServed.length;
    result.avFromInServiceToServed.count = avFromInServiceToServed.length;
  }
  if (avFromServedToClose?.length) {
    result.avFromServedToClose.value = avFromServedToClose.reduce(function (sum, timeInMinutes) {
      return sum + parseFloat(timeInMinutes);
    }, 0) / avFromServedToClose.length;
    result.avFromServedToClose.count = avFromServedToClose.length;
  }
  if (avFromOrderToClose?.length) {
    result.avFromOrderToClose.value = avFromOrderToClose.reduce(function (sum, timeInMinutes) {
      return sum + parseFloat(timeInMinutes);
    }, 0) / avFromOrderToClose.length;
    result.avFromOrderToClose.count = avFromOrderToClose.length;
  }
  if (avFromScanToClose?.length) {
    result.avFromScanToClose.value = avFromScanToClose.reduce(function (sum, timeInMinutes) {
      return sum + parseFloat(timeInMinutes);
    }, 0) / avFromScanToClose.length;
    result.avFromScanToClose.count = avFromScanToClose.length;
  }

  return result

}


export const couponForItemsReportFormat = (order, intl) => {
  let exists
  let message
  order?.orders.map(order => {
    if (order?.appliedCoupon && order?.appliedCoupon?.percentage) {
      exists = true
      message = `${order?.appliedCoupon?.percentage} % - ${order?.appliedCoupon?.code}`
    }
  })
  return {
    isWithCouponForItems: exists,
    message: message
  }
}