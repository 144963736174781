import React, { useState } from "react";
import {
  Modal,
  Form,
  Input,
  InputNumber,
  DatePicker,
  Divider,
  Space,
  Row,
  Col,
  Select,
  Checkbox,
  Typography,
  Tooltip,
  TimePicker,
  Tag,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import CustomButton from "../../../Components/CustomButton";
import openNotification from "../../../Components/Notifications";
import moment from "moment";
import { addPromotion } from "../../../API/fetch";
import { addPromotionAction } from "../../../redux/reducers/restaurantReducer";
import {
  ALL_DAYS,
  CATEGORIES_TYPES,
  FREE_OFFER_TYPES,
  PROMOTIONS_TYPES,
  SPECIAL_OFFER_TYPES,
  categoriesTypesTag,
} from "../../../Types";
import { QuestionCircleOutlined } from "@ant-design/icons";

const { Option } = Select;
const { Text } = Typography;
const AddPromotionModal = ({ show, hide }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const { waiter } = useSelector((state) => state.auth);
  const { categories, foods } = useSelector((state) => state.menu);
  const { allBranches } = useSelector((state) => state.branch);

  const [loading, setLoading] = useState(false);
  // const [isPercentage, setIsPercentage] = useState(false);
  // const [isSpecialOffer, setIsSpecialOffer] = useState(false)
  // const [offerType, setOfferType] = useState("")
  // const [freeOfferType, setFreeOfferType] = useState("")
  const [form] = Form.useForm();
  const addNewPromotion = async (values) => {
    try {
      const specialOffer = {
        offerType: values.specialOfferType,
        price: values.priceOffer,
        food: values.foodOffer,
        qty: values.qtyOffer,
        freeOfferType: values.freeOfferType,
        percentage: values.percentageOffer,
        fixedPrice: values.fixedPriceOffer,
        freeOffer: values.freeItemsOffer,
        applyToFoods: values.applyOffertoFoods,
      };
      let startDate = moment(values.startDate).startOf("day").toISOString();
      let endDate = moment(values.endDate).startOf("day").toISOString();
      setLoading(true);
      let percentageFinal, priceFinal;
      if (values.promotionType?.includes("PERCENTAGE")) {
        percentageFinal = values.percentage;
        priceFinal = 0;
      } else {
        percentageFinal = 0;
        priceFinal = values.fixedPrice;
      }
      let allFoods = values?.foods ? [...values.foods] : [];
      if (values.categories?.length) {
        categories.forEach((category) => {
          if (values.categories.includes(category.categoryId)) {
            category.foods.forEach((food) => {
              if (!allFoods.includes(food.foodId)) {
                allFoods.push(food.foodId);
              }
            });
          }
        });
      }
      let start = moment(values.startTime).format("HH:mm");
      let end = moment(values.endTime).format("HH:mm");
      let disabled = [];
      if (values.disabledDays.length > 0) {
        disabled = values.disabledDays.map((day) => {
          return {
            value: day,
            status: "Disabled",
          };
        });
      }

      const data = {
        promotion: {
          name: values.name,
          name_en: values.name_en,
          promotionType: values.promotionType,
          priority: values.priority,
          percentage: percentageFinal,
          fixedPrice: priceFinal,
          roundDismal: values.roundDismal,
          foods: allFoods,
          categories: values.categories,
          startDate,
          endDate,
          showPromotionName:
            values.promotionType === "SPECIAL_OFFER"
              ? true
              : values.showPromotionName,
          applyOnDayTime: values.applyOnDayTime,
          startTime: start,
          endTime: end,
          disabledDays: disabled,
          specialOffer,
          maximumAmount: values.maximumAmount,
          appliedTo:
            values.appliedTo.length > 0
              ? values.appliedTo
              : [CATEGORIES_TYPES.ALL],
          notAvailableIn:
            values.notAvailableIn.length > 0 ? values.notAvailableIn : [],
        },
        code: waiter.code,
      };

      const res = await addPromotion(data);
      dispatch(addPromotionAction(res.data.promotion));
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setLoading(false);
      // setIsSpecialOffer(false)
      // setOfferType("")
      // setFreeOfferType("")
      hide();
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  function disabledStartDate(current) {
    // Can not select days before today and today
    return current && current < moment().startOf("day");
  }
  function disabledEndDate(current) {
    // Can not select days before today and today
    let endDate = form.getFieldValue("startDate");
    return current && current <= moment(endDate).startOf("day");
  }

  function tagRender(props, color) {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={color}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
        className='my-font'
      >
        {label}
      </Tag>
    );
  }

  return (
    <Modal
      title={
        <p className='my-font'>{intl.formatMessage({ id: "addPromotion" })}</p>
      }
      centered
      visible={show}
      destroyOnClose={true}
      mask={true}
      getContainer={false}
      maskClosable={true}
      onCancel={() => {
        hide();
      }}
      footer={null}
    >
      <Form
        name='NewPromotion'
        form={form}
        preserve={false}
        className='form-container'
        layout='vertical'
        onFinish={addNewPromotion}
        initialValues={{
          name: "",
          name_en: "",
          promotionType: "FIXED_PRICE",
          percentage: 0,
          fixedPrice: 0,
          priority: 0,
          categories: [],
          foods: [],
          startDate: moment().startOf("day"),
          endDate: moment().add(1, "days").startOf("day"),
          showPromotionName: true,
          status: "Active",
          disabledDays: [],
          applyOnDayTime: false,
          startTime: moment(),
          endTime: moment(),
          specialOfferType: "",
          priceOffer: 0,
          foodOffer: "",
          qtyOffer: 1,
          freeOfferType: "",
          fixedPriceOffer: 0,
          percentageOffer: 0,
          freeItemsOffer: [],
          applyOffertoFoods: [],
          maximumAmount: 0,
          appliedTo: ["ALL"],
          notAvailableIn: [],
        }}
      >
        {() => {
          return (
            <>
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                {/* Names */}
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "justName" })}
                    className='mb-1'
                    name='name'
                    style={{ borderRadius: 20 }}
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "errorname" }),
                      },
                    ]}
                  >
                    <Input
                      className={"input"}
                      style={{ borderRadius: 5, width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "nameEn" })}
                    className='mb-1'
                    name='name_en'
                    style={{ borderRadius: 20 }}
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "errorname" }),
                      },
                    ]}
                  >
                    <Input
                      className={"input"}
                      style={{ borderRadius: 5, width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row className='mt-3' gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                {/* Price and type */}
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "promotionType" })}
                    className='mb-1'
                    name='promotionType'
                    style={{ borderRadius: 20 }}
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "errorname" }),
                      },
                    ]}
                  >
                    <Select className='my-font'>
                      {Object.values(PROMOTIONS_TYPES).map((type) => {
                        return (
                          <Option className='my-font' key={type} value={type}>
                            {intl.formatMessage({ id: type })}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  {form.getFieldValue("promotionType") === "SPECIAL_OFFER" ? (
                    <Form.Item
                      label={intl.formatMessage({ id: "specialOfferType" })}
                      className='mb-1'
                      name='specialOfferType'
                      style={{ borderRadius: 20 }}
                      rules={[
                        {
                          required: true,
                          message: intl.formatMessage({ id: "errorname" }),
                        },
                      ]}
                    >
                      <Select className='my-font'>
                        {Object.values(SPECIAL_OFFER_TYPES).map((type) => {
                          return (
                            <Option className='my-font' key={type} value={type}>
                              {intl.formatMessage({ id: type })}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  ) : form
                      .getFieldValue("promotionType")
                      ?.includes("PERCENTAGE") ? (
                    <Form.Item
                      label={intl.formatMessage({ id: "percentage" })}
                      className='mb-1'
                      name='percentage'
                      style={{ borderRadius: 20 }}
                    >
                      <Input
                        addonBefore={rtl ? null : "%"}
                        addonAfter={rtl ? "%" : null}
                        className={"input"}
                        style={{ borderRadius: 5, width: "100%" }}
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      label={intl.formatMessage({ id: "amount" })}
                      className='mb-1'
                      name='fixedPrice'
                      style={{ borderRadius: 20 }}
                    >
                      <Input
                        addonBefore={rtl ? null : "SAR"}
                        addonAfter={rtl ? "SAR" : null}
                        className={"input"}
                        style={{ borderRadius: 5, width: "100%" }}
                      />
                    </Form.Item>
                  )}
                </Col>
                {form.getFieldValue("promotionType") ===
                "SPECIAL_OFFER" ? null : (
                  <Col md={24} sm={24} xs={24}>
                    <Form.Item
                      className='mb-1 mt-2'
                      name='roundDismal'
                      valuePropName='checked'
                      style={{ borderRadius: 20 }}
                    >
                      <Checkbox>
                        {intl.formatMessage({ id: "roundDismal" })}{" "}
                      </Checkbox>
                    </Form.Item>
                  </Col>
                )}
              </Row>
              {form.getFieldValue("promotionType") ===
                PROMOTIONS_TYPES.PERCENTAGE_DISCOUNT && (
                <Row>
                  <Col md={24} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "maximumAmount" })}
                      className='mb-1'
                      name='maximumAmount'
                      tooltip={
                        <Text type='primary' className='my-font'>
                          {intl.formatMessage({ id: "explainCouponMax" })}
                        </Text>
                      }
                      rules={[
                        {
                          required: true,
                          message: intl.formatMessage({ id: "errorname" }),
                        },
                      ]}
                    >
                      <InputNumber
                        className={"input"}
                        placeholder={"50"}
                        min={0}
                        style={{ borderRadius: 5, width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}
              {form.getFieldValue("promotionType") === "SPECIAL_OFFER" &&
              form.getFieldValue("specialOfferType") === "PRICE_RELATED" ? (
                <Row
                  className='mt-3'
                  gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
                >
                  <Col md={24} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "price" })}
                      className='mb-1'
                      name='priceOffer'
                      style={{ borderRadius: 20 }}
                      tooltip={
                        <Text className='my-font'>
                          {intl.formatMessage({
                            id: "specialOfferPriceTip",
                          })}
                        </Text>
                      }
                    >
                      <Input
                        addonBefore={rtl ? null : "SAR"}
                        addonAfter={rtl ? "SAR" : null}
                        className={"input"}
                        style={{ borderRadius: 5, width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              ) : form.getFieldValue("promotionType") === "SPECIAL_OFFER" &&
                form.getFieldValue("specialOfferType") === "FOOD_RELATED" ? (
                <Row
                  className='mt-3'
                  gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
                >
                  {/* Foods   */}
                  <Col md={24} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "applyForFood" })}
                      className='mb-1'
                      name='foodOffer'
                      style={{ borderRadius: 20 }}
                    >
                      <Select
                        filterOption={(input, option) => {
                          return (
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                        className='my-font'
                      >
                        {foods.map((food, i) => {
                          return (
                            <Option
                              className='my-font'
                              key={`${food.foodId}${i}`}
                              value={food.foodId}
                            >
                              {rtl ? food.name : food.name_en}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  {/* <Col md={12} sm={12} xs={12}>
                      <Form.Item
                        label={intl.formatMessage({ id: "NumberOfSelection" })}
                        className='mb-1'
                        name='qtyOffer'
                      >
                        <InputNumber
                          className={"input"}
                          placeholder={"3"}
                          style={{ borderRadius: 5, width: "100%" }}
                        />
                      </Form.Item>
                    </Col> */}
                </Row>
              ) : (
                <Row
                  className='mt-3'
                  gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
                >
                  {/* categories */}
                  <Col md={24} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "applyForCategories" })}
                      className='mb-1'
                      name='categories'
                      style={{ borderRadius: 20 }}
                    >
                      <Select
                        filterOption={(input, option) => {
                          return (
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                        mode='multiple'
                        className='my-font'
                      >
                        {categories.map((category, i) => {
                          return (
                            <Option
                              className='my-font'
                              key={`${category.categoryId}${i}`}
                              value={category.categoryId}
                            >
                              {rtl ? category.name : category.name_en}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              )}
              {form.getFieldValue("promotionType") === "SPECIAL_OFFER" ? (
                <>
                  <Row
                    className='mt-3'
                    gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
                  >
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        label={intl.formatMessage({ id: "FreeOfferType" })}
                        className='mb-1'
                        name='freeOfferType'
                        style={{ borderRadius: 20 }}
                      >
                        <Select className='my-font'>
                          {Object.values(FREE_OFFER_TYPES).map((type) => {
                            return (
                              <Option
                                className='my-font'
                                key={type}
                                value={type}
                              >
                                {intl.formatMessage({ id: type })}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                      {form.getFieldValue("freeOfferType") ===
                      "FIXED_DISCOUNT" ? (
                        <Form.Item
                          label={intl.formatMessage({ id: "amount" })}
                          className='mb-1'
                          name='fixedPriceOffer'
                          style={{ borderRadius: 20 }}
                        >
                          <Input
                            addonBefore={rtl ? null : "SAR"}
                            addonAfter={rtl ? "SAR" : null}
                            className={"input"}
                            style={{ borderRadius: 5, width: "100%" }}
                          />
                        </Form.Item>
                      ) : form.getFieldValue("freeOfferType") ===
                        "PERCENTAGE_DISCOUNT" ? (
                        <Form.Item
                          label={intl.formatMessage({ id: "percentage" })}
                          className='mb-1'
                          name='percentageOffer'
                          style={{ borderRadius: 20 }}
                        >
                          <Input
                            addonBefore={rtl ? null : "%"}
                            addonAfter={rtl ? "%" : null}
                            className={"input"}
                            style={{ borderRadius: 5, width: "100%" }}
                          />
                        </Form.Item>
                      ) : (
                        <Form.Item
                          label={intl.formatMessage({ id: "freeItems" })}
                          className='mb-1'
                          name='freeItemsOffer'
                          style={{ borderRadius: 20 }}
                        >
                          <Select
                            filterOption={(input, option) => {
                              return (
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              );
                            }}
                            mode='multiple'
                            className='my-font'
                          >
                            {foods.map((food, i) => {
                              return (
                                <Option
                                  className='my-font'
                                  key={`${food.foodId}${i}`}
                                  value={food.foodId}
                                >
                                  {rtl ? food.name : food.name_en}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      )}
                    </Col>
                  </Row>
                  <Row
                    className='mt-3'
                    gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
                  >
                    <Col md={24} sm={24} xs={24}>
                      {(form.getFieldValue("freeOfferType") ===
                        "FIXED_DISCOUNT" ||
                        form.getFieldValue("freeOfferType") ===
                          "PERCENTAGE_DISCOUNT") &&
                      form.getFieldValue("specialOfferType") ===
                        "FOOD_RELATED" ? (
                        <Form.Item
                          label={intl.formatMessage({
                            id: "applyOfferForFoods",
                          })}
                          className='mb-1'
                          name='applyOffertoFoods'
                          style={{ borderRadius: 20 }}
                        >
                          <Select
                            filterOption={(input, option) => {
                              return (
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              );
                            }}
                            mode='multiple'
                            className='my-font'
                          >
                            {foods.map((food, i) => {
                              return (
                                <Option
                                  className='my-font'
                                  key={`${food.foodId}${i}`}
                                  value={food.foodId}
                                >
                                  {rtl ? food.name : food.name_en}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      ) : null}
                    </Col>
                  </Row>
                </>
              ) : (
                <Row
                  className='mt-3'
                  gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
                >
                  {/* Foods   */}
                  <Col md={24} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "applyForFoods" })}
                      className='mb-1'
                      name='foods'
                      style={{ borderRadius: 20 }}
                    >
                      <Select
                        filterOption={(input, option) => {
                          return (
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                        mode='multiple'
                        className='my-font'
                      >
                        {foods.map((food, i) => {
                          return (
                            <Option
                              className='my-font'
                              key={`${food.foodId}${i}`}
                              value={food.foodId}
                            >
                              {rtl ? food.name : food.name_en}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              )}
              <Divider />
              <Col span={24}>
                <Form.Item
                  label={intl.formatMessage({ id: "applyPromotionToType" })}
                  className='mb-3'
                  name='appliedTo'
                >
                  <Select
                    className='my-font'
                    mode='multiple'
                    onChange={(e) => {
                      if (e[e.length - 1] === CATEGORIES_TYPES.ALL) {
                        form.setFieldsValue({
                          appliedTo: [CATEGORIES_TYPES.ALL],
                        });
                        return;
                      }
                      if (
                        e.includes(CATEGORIES_TYPES.DELIVERY) &&
                        e.includes(CATEGORIES_TYPES.PICKUP) &&
                        e.includes(CATEGORIES_TYPES.DINEIN)
                      ) {
                        form.setFieldsValue({
                          appliedTo: [CATEGORIES_TYPES.ALL],
                        });
                      } else {
                        form.setFieldsValue({
                          appliedTo: e.filter(
                            (el) => el !== CATEGORIES_TYPES.ALL
                          ),
                        });
                      }
                    }}
                  >
                    {Object.keys(categoriesTypesTag).map((key) => {
                      return (
                        <Select.Option
                          key={key}
                          value={key}
                          className='my-font'
                        >
                          <Tag color={categoriesTypesTag[key].color}>
                            {intl.formatMessage({
                              id: categoriesTypesTag[key].text,
                            })}
                          </Tag>
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={24} sm={24} xs={24}>
                <Form.Item
                  className='mb-1 mt-1'
                  name='notAvailableIn'
                  label={intl.formatMessage({
                    id: "chooseBranchesToHidePromotion",
                  })}
                >
                  <Select
                    mode='multiple'
                    className='my-font'
                    showArrow
                    tagRender={(p) => tagRender(p, "red")}
                    options={allBranches.map(({ branch }) => ({
                      label: branch.name,
                      value: branch._id,
                    }))}
                  />
                </Form.Item>
              </Col>

              <Divider orientation={rtl ? "right" : "left"}>
                <Text className='my-font'>
                  {intl.formatMessage({ id: "date" })}
                </Text>
              </Divider>
              <Row className='mt-3' gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "startDatePromotion" })}
                    className='mb-1'
                    name='startDate'
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "errorname" }),
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      disabledDate={disabledStartDate}
                      onChange={(e) => {
                        form.setFieldsValue({
                          endDate: moment(e).add(1, "days").startOf("day"),
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "endDatePromotion" })}
                    className='mb-1'
                    name='endDate'
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "errorname" }),
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      disabledDate={disabledEndDate}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row className='mt-3' gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "disabledDays" })}
                    className='mb-1'
                    name='disabledDays'
                    tooltip={
                      <Text className='my-font'>
                        {intl.formatMessage({
                          id: "disabledDaysPromotionTooltip",
                        })}
                      </Text>
                    }
                  >
                    <Select mode='multiple' className='my-font'>
                      {ALL_DAYS?.map((day) => (
                        <Option
                          key={day.value}
                          value={day.value}
                          className='my-font'
                        >
                          {intl.formatMessage({ id: day.value })}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    className='mb-1 mt-5'
                    name='applyOnDayTime'
                    valuePropName='checked'
                  >
                    <Checkbox>
                      {intl.formatMessage({ id: "applyOnDayTime" })}{" "}
                      <Tooltip
                        title={
                          <Text className='my-font'>
                            {intl.formatMessage({
                              id: "applyOnDayTimeExplain",
                            })}
                          </Text>
                        }
                      >
                        <QuestionCircleOutlined
                          color='#eeeeee'
                          className='mx-1'
                        />
                      </Tooltip>
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Row type='flex' justify='space-between' className='mt-2'>
                <Col md={11} sm={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "startTimePromotion" })}
                    className='mb-1'
                    name='startTime'
                    dependencies={["applyOnDayTime"]}
                  >
                    <TimePicker
                      disabled={!form.getFieldValue("applyOnDayTime")}
                      format={"HH:mm"}
                    />
                  </Form.Item>
                </Col>
                <Col md={11} sm={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "endTimePromotion" })}
                    className='mb-1'
                    name='endTime'
                    dependencies={["applyOnDayTime"]}
                  >
                    <TimePicker
                      disabled={!form.getFieldValue("applyOnDayTime")}
                      format={"HH:mm"}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row className='mt-3' gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                <Col md={12} sm={24} xs={24}>
                  {form.getFieldValue("promotionType") === "SPECIAL_OFFER" ? (
                    <Text className='my-font'>
                      {intl.formatMessage({
                        id: "showPromotionNameSpecialOffer",
                      })}
                    </Text>
                  ) : (
                    <Form.Item
                      className='mb-1 mt-5'
                      name='showPromotionName'
                      valuePropName='checked'
                    >
                      <Checkbox>
                        {intl.formatMessage({ id: "showPromotionName" })}
                      </Checkbox>
                    </Form.Item>
                  )}
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "priority" })}
                    className='mb-1'
                    name='priority'
                    tooltip={
                      <Text className='my-font'>
                        {intl.formatMessage({ id: "priorityExp" })}
                      </Text>
                    }
                  >
                    <InputNumber
                      className={"input"}
                      placeholder={"3"}
                      style={{ borderRadius: 5, width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Divider />
              <Space direction='horizontal'>
                <Form.Item>
                  <CustomButton
                    htmlType='submit'
                    text={intl.formatMessage({ id: "save" })}
                    className={`btnRegister btn-text border-8`}
                    loading={loading}
                    disabled={loading}
                    type='primary'
                  />
                </Form.Item>

                <Form.Item>
                  <CustomButton
                    type='text'
                    text={intl.formatMessage({ id: "cancel" })}
                    disabled={loading}
                    onClick={() => {
                      hide();
                    }}
                  />
                </Form.Item>
              </Space>
            </>
          );
        }}
      </Form>
    </Modal>
  );
};

export default AddPromotionModal;
