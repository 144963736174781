import React, { useState, useEffect } from "react";
import { Col, Row, Card, Typography, Space, Switch } from "antd";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { postUpdateCategory } from "../../../../../API/fetch";
import openNotification from "../../../../../Components/Notifications";
import { editCategoryAction } from "../../../../../redux/reducers/menuReducer";
import CustomButton from "../../../../../Components/CustomButton";
import EditCategoryModal from "./EditCategoryModal";
import moment from "moment";
const { Text, Title } = Typography;

const CategoryHeader = ({ category, index, setActiveTab }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const { role } = useSelector((state) => state.auth);
  const { allBranches, branch } = useSelector((state) => state.branch);

  const [loading, setLoading] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [showEditCategory, setShowEditCategory] = useState(false);

  useEffect(() => {
    let branchIds =[];
    if(role === 'super'){
      branchIds = allBranches?.map((b) => b?.branch?._id);
    }else {
       branchIds = allBranches?.map((b) => b?._id);
    }
    let isHide = branchIds.every((id) =>
      category?.notAvailableIn?.includes(id)
    );
    setIsHidden(isHide);
  }, [category]);

  const handelIsAvailable = async (v, category) => {
    // If v === true add branch id to notAvailableIn array
    setLoading(true);
    let branchIds = [];
    if (role === "super") {
      if (v === true) {
        branchIds = allBranches.map((b) => b?.branch?._id);
      } else {
        branchIds = [];
      }
    } else {
      branchIds = [...category.notAvailableIn];
      if (v === true) {
        // Add to array
        branchIds.push(branch._id);
      } else {
        // remove form array
        const i = branchIds.indexOf(branch._id);
        if (i !== -1) {
          branchIds.splice(i, 1);
        }
      }
    }
    const data = {
      category: { notAvailableIn: branchIds },
    };
    try {
      const res = await postUpdateCategory(category.categoryId, data);
      if (res.data?.status === "success") {
        const newCategory = {
          ...category,
          notAvailableIn: branchIds,
        };
        dispatch(editCategoryAction(newCategory));
      }
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  return (
    <Card style={{ width: "100%" }}>
      <Row>
        {/* Modals */}
        <EditCategoryModal
          category={category}
          show={showEditCategory}
          hide={() => setShowEditCategory(false)}
          index={index}
          setActiveTab={setActiveTab}
        />
        <Col sm={12}>
          <Title level={4} className='my-font'>
            {rtl ? category?.name : category?.name_en}
          </Title>
          <Space direction='vertical'>
            <Text>
              {category?.foods?.length} {intl.formatMessage({ id: "category" })}
            </Text>
            <Text type="secondary">{moment(category?.createdAt).format('DD/MM/YYYY')}</Text>
          </Space>
        </Col>
        <Col sm={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title level={5} className='my-font'>
              {intl.formatMessage({ id: "choices" })}
            </Title>
            <div>
              <Text className='my-font'>
                {intl.formatMessage({ id: "unAvailableCategory" })}
              </Text>
              <Switch
                className='mx-3'
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={isHidden}
                onChange={(v) => handelIsAvailable(v, category)}
                loading={loading}
                disabled={loading}
              />
            </div>

            <CustomButton
              text={intl.formatMessage({ id: "editCategory" })}
              type='text'
              className='mt-2'
              onClick={() => setShowEditCategory(true)}
            />
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default CategoryHeader;
