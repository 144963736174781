import React, { useState, useEffect } from "react";
import { Result, Button } from "antd";
import Login from "./Components/Login";
import SignUp from "./Components/SignUp";
import VerifyUser from "./Components/VerifyUser";
import ForgetPassword from "./Components/ForgetPassword";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { changeLanguageAction } from "../../redux/reducers/settingsReducer";
import EnterCode from "./Components/EnterCode";
import { useIntl } from "react-intl";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const Register = () => {
  const navigate = useNavigate();
  const [screen, setScreen] = useState("SignIn");
  const intl = useIntl();
  const [formValues, setFormValues] = useState(null);
  const dispatch = useDispatch();
  const { search } = useLocation();
  const params = useParams();

  const { rtl } = useSelector((state) => state.settings);

  useEffect(() => {
    if (search?.includes("lang=")) {
      let isRtl = search.split("=")[1] === "ar";
      if (isRtl && !rtl) {
        dispatch(changeLanguageAction());
      }
    }
    if (
      params &&
      typeof params === "object" &&
      Object?.values(params)?.length
    ) {
      if (
        Object.values(params)?.includes("register") ||
        Object.values(params)?.includes("signup")
      ) {
        setScreen("SignUp");
      }
    }
  }, [search, dispatch, rtl, params]);

  return (
    <div>
      <GoogleReCaptchaProvider
    reCaptchaKey="6LewHQYlAAAAAHs5YmLNQWkkQ2nUaF07ZTEUYxis"
    language={rtl ? "ar" : "en"}
    
  >
      {screen === "SignIn" ? (
        <Login
          showSignUp={() => {
            // change the params to register;
            navigate("/login");
            setScreen("SignUp");
          }}
          setShowForgetPassword={() => {
            navigate("/forgetPassword");
            setScreen("Forget_Password");
          }}
          setShowPinCode={() => {
            navigate("/showPinCode");
            setScreen("ShowPinCode");
          }}
        />
      ) : screen === "SignUp" ? (
        <SignUp
          showSignIn={() => {
            navigate("/singin");
            setScreen("SignIn");
          }}
          formValues={formValues}
          setFormValues={setFormValues}
          showVerify={() => {
            navigate("/verifyUser");
            setScreen("VerifyUser");
          }}
          setShowPinCode={() => {
            navigate("/showPinCode");
            setScreen("ShowPinCode");
          }}
        />
      ) : screen === "VerifyUser" ? (
        <VerifyUser
          formValues={formValues}
          goBack={() => {
            navigate("/singin");
            setScreen("SignIn");
          }}
        />
      ) : screen === "ShowPinCode" ? (
        <EnterCode />
      ) : screen === "Forget_Password" ? (
        <ForgetPassword
          goBack={() => {
            navigate("/singin");
            setScreen("SignIn");
          }}
        />
      ) : (
        <Result
          status='404'
          title='404'
          subTitle={intl.formatMessage({ id: "pageError" })}
          extra={
            <Button
              type='primary'
              onClick={() => {
                navigate("/singin");
                setScreen("SignIn");
              }}
            >
              {intl.formatMessage({ id: "goBack" })}
            </Button>
          }
        />
      )}
      </GoogleReCaptchaProvider>
    </div>
  );
};

export default Register;
