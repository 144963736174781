import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Typography, Table, Tag, Space } from "antd";
import CustomButton from "../../Components/CustomButton";
import { useIntl } from "react-intl";
import COLORS from "../../Style/colors";
import { EditTwoTone } from "@ant-design/icons";
import { categoriesTypesTag } from "../../Types";
import { useNavigate } from "react-router-dom";
import EditCategoryModal from "../MenuViews/Foods/Components/Categoryies/EditCategoryModal";

const { Title, Text } = Typography;

const Categories = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { rtl } = useSelector((state) => state.settings);
  const { role } = useSelector((state) => state.auth);
  const { allBranches } = useSelector((state) => state.branch);
  const { categories } = useSelector((state) => state.menu);
  const [formattedCategories, setFormattedCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showCategoryModal, setShowCategoryModal] = useState(false);

  useEffect(() => {
    if (categories.length) {
      const format = categories.map((category) => {
        let types = ["ALL"];
        if (category.types && category.types.length > 0) {
          types = category.types;
        }
        let status = "Active";
        // check if the category is hidden;
        let branchIds = [];
        if (role === "super") {
          branchIds = allBranches?.map((b) => b?.branch?._id);
        } else {
          branchIds = allBranches?.map((b) => b?._id);
        }
        let isHide = branchIds.every((id) =>
          category?.notAvailableIn?.includes(id)
        );
        if (isHide) {
          status = "disabled";
        }
        return {
          ...category,
          key: category.categoryId,

          displayName: rtl ? category?.name : category?.name_en,
          foodsCount: category?.foods?.length || 0,
          types,
          index: category.order,
          status,
        };
      });
      setFormattedCategories(format);
    } else {
      setFormattedCategories([]);
    }
  }, [categories, rtl]);

  const columns = [
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "displayName",
      key: "displayName",
      render: (name, record) => {
        return (
          <Space
            direction='vertical'
            className={"pointer"}
            onClick={() => {
              navigate(`/foods?category=${record.categoryId}`);
            }}
          >
            <Text>{name}</Text>
            <Text type='secondary' underline>
              {record?.foodsCount} {intl.formatMessage({ id: "products" })}
            </Text>
          </Space>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "type" }),
      dataIndex: "types",
      key: "types",
      render: (types, record) => {
        return (
          <Space direction='vertical'>
            {types?.map((type) => {
              return (
                <Tag key={categoriesTypesTag[type].text}>
                  {intl.formatMessage({ id: categoriesTypesTag[type].text })}
                </Tag>
              );
            })}
          </Space>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "status" }),
      dataIndex: "status",
      key: "status",
      render: (status, record) => {
        return (
          <Tag color={status === "Active" ? "green" : "red"}>
            {intl.formatMessage({ id: status })}
          </Tag>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "edit" }),
      dataIndex: "edit",
      render: (status, record) => {
        return (
          <EditTwoTone
            onClick={() => {
              setSelectedCategory(record);
              setShowCategoryModal(true);
            }}
            className={"pointer"}
          />
        );
      },
    },
  ];

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      {/* Modals */}

      <EditCategoryModal
        category={selectedCategory}
        show={showCategoryModal}
        hide={() => {
          setSelectedCategory(null);
          setShowCategoryModal(false);
        }}
        index={selectedCategory?.index}
        setActiveTab={() => true}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <Title>{intl.formatMessage({ id: "categories" })}</Title>
        <Space direction='vertical' size='middle'>
          <CustomButton
            text={intl.formatMessage({
              id: "addCategory",
            })}
            type='primary'
            className='border-8'
            onClick={() => setShowCategoryModal(true)}
          />
          {role === "super" ? (
            <CustomButton
              text={intl.formatMessage({ id: "customCategories" })}
              type='primary'
              className='border-8'
              onClick={() => navigate("/customCategories")}
            />
          ) : null}
        </Space>
      </div>
      <div
        style={{
          backgroundColor: COLORS.white,
          marginTop: 40,
          width: "92%",
          marginRight: "auto",
          marginLeft: "auto",
          borderRadius: 10,
        }}
        className='light-shadow'
      >
        <Table
          columns={columns}
          style={{ borderRadius: 10 }}
          dataSource={formattedCategories}
          scroll={{ x: 500 }}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default Categories;
