import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Typography, Table } from "antd";
import CustomButton from "../../Components/CustomButton";
import { useIntl } from "react-intl";
import COLORS from "../../Style/colors";
import openNotification from "../../Components/Notifications";
import { getAllWaiters } from "../../API/fetch";
import { EditTwoTone } from "@ant-design/icons";
import WaiterModal from "./Components/WaiterModal";
import { SUBSCRIPTION_TYPES } from "../../Types";

const { Title, Text } = Typography;

const Waiters = () => {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  const { waiter, services } = useSelector((state) => state.auth);

  const [formattedWaiters, setFormattedWaiters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [waiters, setWaiters] = useState([]);
  const [selectedWaiter, setSelectedWaiter] = useState(null);
  const [showWaiterModal, setShowWaiterModal] = useState(false);
  const [codes, setCodes] = useState([]);

  useEffect(() => {
    getWaitersData();
  }, []);

  const getWaitersData = async () => {
    try {
      const res = await getAllWaiters();
      setWaiters(res.data.waiters);
      const format = res.data.waiters.map((waiter) => {
        return {
          key: waiter._id,
          name: waiter.name,
          code: waiter.code,
          branchName: rtl ? waiter?.branchId?.name : waiter?.branchId?.name_en,
          waiterInfo: { ...waiter },
        };
      });
      let allCodes = [waiter.code];
      if (res?.data?.waiters?.length > 0) {
        res?.data?.waiters.forEach((w) => allCodes.push(w.code));
      }
      setFormattedWaiters(format);
      setCodes(allCodes);
      setInitialLoading(false);
      setLoading(false);
    } catch (error) {
      setInitialLoading(false);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  useEffect(() => {
    if (waiters.length) {
      const format = waiters.map((waiter) => {
        return {
          key: waiter._id,
          name: waiter.name,
          code: waiter.code,
          branchName: rtl ? waiter?.branchId?.name : waiter?.branchId?.name_en,
          waiterInfo: { ...waiter },
        };
      });
      let allCodes = [waiter.code];
      waiters.forEach((w) => allCodes.push(w.code));
      setFormattedWaiters(format);
      setCodes(allCodes);
    } else {
      setFormattedWaiters([]);
      setCodes([]);
    }
  }, [waiters]);

  const columns = [
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "name",
      key: "name",
    },
    {
      title: intl.formatMessage({ id: "branch" }),
      dataIndex: "branchName",
      key: "branchName",
    },
    {
      title: intl.formatMessage({ id: "code" }),
      dataIndex: "code",
      key: "code",
    },
    {
      title: intl.formatMessage({ id: "edit" }),
      dataIndex: "edit",
      render: (status, record) => {
        return (
          <EditTwoTone
            onClick={() => {
              setSelectedWaiter(record.waiterInfo);
              setShowWaiterModal(true);
            }}
            className={"pointer"}
          />
        );
      },
    },
  ];

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      {/* Modals */}

      <WaiterModal
        show={showWaiterModal}
        hide={() => {
          setShowWaiterModal(false);
          setSelectedWaiter(null);
        }}
        waiter={selectedWaiter}
        codes={codes}
        setWaiters={setWaiters}
        waiters={waiters}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Title>{intl.formatMessage({ id: "waiters" })}</Title>
        <CustomButton
          text={intl.formatMessage({
            id: "addWaiter",
          })}
          type='primary'
          className='border-8'
          onClick={() => setShowWaiterModal(true)}
          disabled={services?.includes("ORDER_A") && waiters?.length >= 1}
        />
      </div>
      <Text className='my-font'>
        {intl.formatMessage({ id: "downloadWaiterApp" })},
        {intl.formatMessage({ id: "fromThisLink" })}{": "}
        <Text copyable={{
          text: "https://iwaiter.net/waiterAppLink.html"
        }}>
          <a
            href={"https://iwaiter.net/waiterAppLink.html"}
            target='_blank'
            rel='noopener noreferrer'
          >
            https://iwaiter.net/waiterAppLink.html
          </a>
        </Text>
      </Text>
      <div
        style={{
          backgroundColor: COLORS.white,
          marginTop: 40,
          width: "92%",
          marginRight: "auto",
          marginLeft: "auto",
          borderRadius: 10,
        }}
        className='light-shadow'
      >
        <Table
          columns={columns}
          style={{ borderRadius: 10 }}
          loading={initialLoading || loading}
          dataSource={formattedWaiters}
          scroll={{ x: 500 }}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default Waiters;
