import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { Typography, Row, Col, Spin } from "antd";
import { useIntl } from "react-intl";
import COLORS from "../../Style/colors";
import {
  setAnalyticsDataAction,
  setGoogleDataAction,
} from "../../redux/reducers/analyticsReducer";
import { postGetOwnerData } from "../../API/fetch";
import UserVisits from "./Components/UserVisits";
import RetentionRate from "./Components/RetentionRate";
import FoodAnalytics from "./Components/FoodAnalytics";
import DineInOrders from "./Components/DineInOrders";
import PickupOrders from "./Components/PickupOrders";
import DeliveryOrders from "./Components/DeliveryOrders";
import DineInTotals from "./Components/DineInTotals";
import PickupTotals from "./Components/PickupTotals";
import DeliveryTotals from "./Components/DeliveryTotals";
import TableAnalytics from "./Components/TableAnalytics";
import LastFeedback from "./Components/LastFeedback";
import CommentsCard from "./Components/CommentsCard";
import { checkIfUnauthorized } from "../../helpers";
import ReservationOrders from "./Components/ReservationOrders";
import ReservationTotals from "./Components/ReservationTotals";
const { Title } = Typography;

const Home = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { analyticsData } = useSelector((state) => state.analytics);
  const { role, services } = useSelector((state) => state.auth);
  const [loadingData, setLoadingData] = useState(true);
  const [showAllFoods, setShowAllFoods] = useState(false);
  const [showAllTables, setShowAllTables] = useState(false);

  useEffect(() => {
    if (
      !analyticsData?.dineInOrdersByDate?.length ||
      !analyticsData?.pickupOrdersByDate?.length
    ) {
      getOwnerData();
    } else {
      setLoadingData(false);
    }
  }, []);

  const getOwnerData = async () => {
    try {
      const res = await postGetOwnerData();
      if (res.data) {
        dispatch(setAnalyticsDataAction(res.data.result));
        dispatch(setGoogleDataAction(res.data.gaData));
        setLoadingData(false);
      }
    } catch (error) {
      checkIfUnauthorized(error);
      console.log(error);
      setLoadingData(false);
    }
  };

  if (role !== "super") {

    if (services?.includes('ORDER_A') || services?.includes('ORDER_B')) {
      return <Navigate to='/tables' />;
    } else if (services?.includes('RESERVATION')) {
      return <Navigate to='/reservation' />;
    } else if (services?.includes('WAITING')) {
      return <Navigate to='/waiting' />;
    } else if (services?.includes('PICKUP')) {
      return <Navigate to='/pickup' />;
    } else {
      return <Navigate to='/tables' />;
    }
  }
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <Title>{intl.formatMessage({ id: "home" })}</Title>
      </div>
      <div
        style={{
          marginTop: 10,
          width: "100%",
          minHeight: "70vh",
          marginRight: "auto",
          marginLeft: "auto",
        }}
      >
        {/* First Row */}
        <Row justify='space-between'>
          <Col
            style={{ backgroundColor: COLORS.white }}
            className={`light-shadow border-8 py-2 px-2 mt-3`}
            md={12}
            sm={24}
            xs={24}
          >
            <Spin spinning={loadingData}>
              <LastFeedback />
            </Spin>
          </Col>
          <Col
            style={{ backgroundColor: COLORS.white }}
            className={`light-shadow border-8 py-2 px-2 mt-3`}
            md={11}
            sm={24}
            xs={24}
          >
            <Spin spinning={loadingData}>
              <CommentsCard />
            </Spin>
          </Col>
        </Row>
        {/*  Second row*/}
        <Row justify='space-between'>
          <Col
            style={{ backgroundColor: COLORS.white }}
            className={`light-shadow border-8 py-2 px-2 mt-3`}
            md={12}
            sm={24}
            xs={24}
          >
            <Spin spinning={loadingData}>
              <DineInOrders />
            </Spin>
          </Col>
          <Col
            style={{ backgroundColor: COLORS.white }}
            className={`light-shadow border-8 py-2 px-2 mt-3`}
            md={11}
            sm={24}
            xs={24}
          >
            <Spin spinning={loadingData}>
              <DineInTotals />
            </Spin>
          </Col>
        </Row>
        {/* Thride row */}
        <Row justify='space-between'>
          <Col
            style={{ backgroundColor: COLORS.white }}
            className={`light-shadow border-8 py-2 px-2 mt-3`}
            md={12}
            sm={24}
            xs={24}
          >
            <Spin spinning={loadingData}>
              <UserVisits />
            </Spin>
          </Col>
          <Col
            style={{ backgroundColor: COLORS.white }}
            className={`light-shadow border-8 py-2 px-2 mt-3`}
            md={11}
            sm={24}
            xs={24}
          >
            <Spin spinning={loadingData}>
              <RetentionRate />
            </Spin>
          </Col>
        </Row>
        {/* Fourth row */}
        <Row justify='space-between'>
          <Col
            style={{ backgroundColor: COLORS.white }}
            className={`light-shadow border-8 py-2 px-2 mt-3`}
            md={showAllFoods ? 24 : 12}
            sm={24}
            xs={24}
          >
            <Spin spinning={loadingData}>
              <FoodAnalytics
                showAllFoods={showAllFoods}
                setShowAllFoods={setShowAllFoods}
              />
            </Spin>
          </Col>
          <Col
            style={{ backgroundColor: COLORS.white }}
            className={`light-shadow border-8 py-2 px-2 mt-3`}
            md={showAllTables ? 24 : 11}
            sm={24}
            xs={24}
          >
            <Spin spinning={loadingData}>
              <TableAnalytics
                showAllTables={showAllTables}
                setShowAllTables={setShowAllTables}
              />
            </Spin>
          </Col>
        </Row>
        {/* Fifth row */}
        <Row justify='space-between'>
          <Col
            style={{ backgroundColor: COLORS.white }}
            className={`light-shadow border-8 py-2 px-2 mt-3`}
            md={12}
            sm={24}
            xs={24}
          >
            <Spin spinning={loadingData}>
              <PickupOrders />
            </Spin>
          </Col>
          <Col
            style={{ backgroundColor: COLORS.white }}
            className={`light-shadow border-8 py-2 px-2 mt-3`}
            md={11}
            sm={24}
            xs={24}
          >
            <Spin spinning={loadingData}>
              <PickupTotals />
            </Spin>
          </Col>
        </Row>
        {/* Sixth row */}
        <Row justify='space-between'>
          <Col
            style={{ backgroundColor: COLORS.white }}
            className={`light-shadow border-8 py-2 px-2 mt-3`}
            md={12}
            sm={24}
            xs={24}
          >
            <Spin spinning={loadingData}>
              <DeliveryOrders />
            </Spin>
          </Col>
          <Col
            style={{ backgroundColor: COLORS.white }}
            className={`light-shadow border-8 py-2 px-2 mt-3`}
            md={11}
            sm={24}
            xs={24}
          >
            <Spin spinning={loadingData}>
              <DeliveryTotals />
            </Spin>
          </Col>
        </Row>

        <Row justify='space-between'>
          <Col
            style={{ backgroundColor: COLORS.white }}
            className={`light-shadow border-8 py-2 px-2 mt-3`}
            md={12}
            sm={24}
            xs={24}
          >
            <Spin spinning={loadingData}>
              <ReservationOrders />
            </Spin>
          </Col>
          <Col
            style={{ backgroundColor: COLORS.white }}
            className={`light-shadow border-8 py-2 px-2 mt-3`}
            md={11}
            sm={24}
            xs={24}
          >
            <Spin spinning={loadingData}>
              <ReservationTotals />
            </Spin>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Home;
