import React, { useEffect, useState } from "react";
import { Button, Descriptions, Modal, Result, Spin, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { getCustomerDataForRestaurant } from "../../../API/fetch";
import openNotification from "../../../Components/Notifications";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";
import moment from "moment";
import COLORS from "../../../Style/colors";

const UserDetails = ({ show, hide, user }) => {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [labels, setLabels] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (user?._id && user?._id !== userData?._id && show) {
      getCustomerData();
    } else {
      setLoading(false);
    }
  }, [user]);

  const getCustomerData = async () => {
    try {
        setLoading(true);
      const res = await getCustomerDataForRestaurant(
        user.customerId?._id,
        user?.phone
      );
      if (res?.data?.user) {
        const { first, last, firstType, lastType } = getDates(res.data.user);
        setUserData({
          ...res.data.user,
          first: moment(first).format("YYYY-MM-DD"),
          last: moment(last).format("YYYY-MM-DD"),
          firstType: intl.formatMessage({ id: firstType }),
          lastType: intl.formatMessage({ id: lastType }),
        });
        // the labels should be from start month to end month as three letters of the month and the year;
        let labels = [];
        let ordersDataSet = [];
        let pickupDataSet = [];
        let reservationsDataSet = [];
        let waitingDataSet = [];
        let allData = [];
        res?.data?.user?.orders?.forEach((one) => {
          allData.push({
            createdAt: one?.createdAt,
            type: "inHouse",
          });
        });
        res?.data?.user?.pickupOrders?.forEach((one) => {
          allData.push({
            createdAt: one?.createdAt,
            type: "pickup",
          });
        });
        res?.data?.user?.reservations?.forEach((one) => {
          allData.push({
            createdAt: one?.createdAt,
            type: "reservations",
          });
        });
        res?.data?.user?.waitingList?.forEach((one) => {
          allData.push({
            createdAt: one?.createdAt,
            type: "waiting",
          });
        });
        let s = moment(first).startOf("month").startOf("day");
        let e = moment(last).endOf("month").endOf("day");
        while (s.isSameOrBefore(e)) {
          labels.push(s.format("MMM-YY"));
          // get all data in that month;
          let monthData = allData.filter((one) => {
            return moment(one.createdAt).isBetween(
              s,
              moment(s).add(1, "month")
            );
          });
          // get orders;
          let ordersTotal = monthData.filter(
            (one) => one?.type === "inHouse"
          ).length;
          ordersDataSet.push(ordersTotal);
          // get pickup;
          let pickupTotal = monthData.filter(
            (one) => one?.type === "pickup"
          ).length;
          // console.log("pikcup total", pickupTotal)
          pickupDataSet.push(pickupTotal);
          // get reservations;
          let reservationsTotal = monthData.filter(
            (one) => one?.type === "reservations"
          ).length;
          reservationsDataSet.push(reservationsTotal);
          // get waiting;
          let waitingTotal = monthData.filter(
            (one) => one?.type === "waiting"
          ).length;
          waitingDataSet.push(waitingTotal);
          //end of the loop;
          s = s.add(1, "month");
        }

        setLabels(labels);
        setData([
          {
            label: intl.formatMessage({ id: "inHouse" }),
            data: ordersDataSet,
            fill: false,
            backgroundColor: COLORS.primary,
            borderColor: COLORS.primary,
            borderWidth: 2,
          },
          {
            label: intl.formatMessage({ id: "pickup" }),
            data: pickupDataSet,
            fill: false,
            backgroundColor: COLORS.darkGray,
            borderColor: COLORS.darkGray,
            borderWidth: 2,
          },
          {
            label: intl.formatMessage({ id: "reservations" }),
            data: reservationsDataSet,
            fill: false,
            backgroundColor: COLORS.lightBlue,
            borderColor: COLORS.lightBlue,
            borderWidth: 2,
          },
          {
            label: intl.formatMessage({ id: "waiting" }),
            data: waitingDataSet,
            fill: false,
            backgroundColor: COLORS.danger,
            borderColor: COLORS.danger,
            borderWidth: 2,
          },
        ]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const getDates = (user) => {
    let result = {
      first: "",
      last: "",
      firstType: "",
      lastType: "",
    };

    let first = "";
    let last = "";
    let firstType = "";
    let lastType = "";
    // check orders;
    user?.orders?.forEach((o) => {
      if (first === "") {
        // first loop;
        firstType = "inHouse";
        lastType = "inHouse";
        first = moment(o.createdAt);
        last = moment(o.createdAt);
      } else {
        // check if the o created at is before the first;
        if (moment(o.createdAt).isBefore(first)) {
          first = moment(o.createdAt).format("YYYY-MM-DD");
          firstType = "inHouse";
        }
        if (moment(o.createdAt).isAfter(last)) {
          last = moment(o.createdAt).format("YYYY-MM-DD");
          lastType = "inHouse";
        }
      }
    });
    // check pickup orders;
    user?.pickupOrders?.forEach((o) => {
      if (first === "") {
        // first loop;
        firstType = "pickup";
        lastType = "pickup";
        first = moment(o.createdAt);
        last = moment(o.createdAt);
      } else {
        // check if the o created at is before the first;
        if (moment(o.createdAt).isBefore(first)) {
          first = moment(o.createdAt).format("YYYY-MM-DD");
          firstType = "pickup";
        }
        if (moment(o.createdAt).isAfter(last)) {
          last = moment(o.createdAt).format("YYYY-MM-DD");
          lastType = "pickup";
        }
      }
    });
    // check reservations;
    user?.reservations?.forEach((o) => {
      if (first === "") {
        // first loop;
        firstType = "reservation";
        lastType = "reservation";
        first = moment(o.createdAt);
        last = moment(o.createdAt);
      } else {
        // check if the o created at is before the first;
        if (moment(o.createdAt).isBefore(first)) {
          first = moment(o.createdAt).format("YYYY-MM-DD");
          firstType = "reservation";
        }
        if (moment(o.createdAt).isAfter(last)) {
          last = moment(o.createdAt).format("YYYY-MM-DD");
          lastType = "reservation";
        }
      }
    });
    // check waitingList;
    user?.waitingList?.forEach((o) => {
      if (first === "") {
        // first loop;
        firstType = "waitingList";
        lastType = "waitingList";
        first = moment(o.createdAt);
        last = moment(o.createdAt);
      } else {
        // check if the o created at is before the first;
        if (moment(o.createdAt).isBefore(first)) {
          first = moment(o.createdAt).format("YYYY-MM-DD");
          firstType = "waitingList";
        }
        if (moment(o.createdAt).isAfter(last)) {
          last = moment(o.createdAt).format("YYYY-MM-DD");
          lastType = "waitingList";
        }
      }
    });
    result.first = first;
    result.last = last;
    result.firstType = firstType;
    result.lastType = lastType;
    return result;
  };

  return (
    <Modal
      title={<p className='my-font'>{user?.name}</p>}
      centered
      visible={show}
      destroyOnClose={true}
      mask={true}
      getContainer={false}
      maskClosable={true}
      onCancel={() => {
        hide();
      }}
      footer={null}
      width={"100%"}
      style={{
        borderRadius: 20,
        border: "1px solid #e8e8e8",
        backgroundColor: "#fff",
        paddingBottom: 0,
        overflow: "hidden",
      }}
      bodyStyle={{
        minHeight: "80vh",
        height: "100%",
      }}
    >
      <Spin spinning={loading}>
        {userData ? (
          <div>
            <Descriptions
              bordered
              className='my-font'
              title={intl.formatMessage({ id: "userInfo" })}
            >
              <Descriptions.Item label={intl.formatMessage({ id: "justName" })}>
                {user?.name}
              </Descriptions.Item>
              <Descriptions.Item label={intl.formatMessage({ id: "phone" })}>
                {user?.phone}
              </Descriptions.Item>
              <Descriptions.Item label={intl.formatMessage({ id: "email" })}>
                {user?.email || "N/A"}
              </Descriptions.Item>
              <Descriptions.Item
                label={intl.formatMessage({ id: "totalSpent" })}
              >
                <Tag
                  color='success'
                  style={{
                    borderRadius: 5,
                  }}
                >
                  {userData?.totalSpent?.toFixed(2) || 0}
                </Tag>
              </Descriptions.Item>
            </Descriptions>
            <Descriptions
              bordered
              className='my-font mt-3'
              title={intl.formatMessage({ id: "analytics" })}
            >
              <Descriptions.Item label={intl.formatMessage({ id: "inHouse" })}>
                {userData?.orders?.length}{" "}
                {intl.formatMessage({ id: "justOrders" })} -{" "}
                <Tag
                  color='success'
                  style={{
                    borderRadius: 5,
                  }}
                >
                  {userData?.totalOrders?.toFixed(2) || 0} SAR{" "}
                </Tag>
              </Descriptions.Item>
              <Descriptions.Item label={intl.formatMessage({ id: "pickup" })}>
                {userData?.pickupOrders?.length}{" "}
                {intl.formatMessage({ id: "justOrders" })} -{" "}
                <Tag
                  color='success'
                  style={{
                    borderRadius: 5,
                  }}
                >
                  {userData?.totalPickupOrders?.toFixed(2) || 0} SAR{" "}
                </Tag>
              </Descriptions.Item>
              <Descriptions.Item
                label={intl.formatMessage({ id: "justReservations" })}
              >
                {userData?.reservations?.length}{" "}
                {intl.formatMessage({ id: "reservations" })} -{" "}
                <Tag
                  color='success'
                  style={{
                    borderRadius: 5,
                  }}
                >
                  {userData?.totalReservations?.toFixed(2) || 0} SAR{" "}
                </Tag>
              </Descriptions.Item>
              <Descriptions.Item label={intl.formatMessage({ id: "waiting" })}>
                {userData?.waitingList?.length}
              </Descriptions.Item>
            </Descriptions>
            <div className='my-5'>
              <Tag
                style={{
                  fontSize: 16,
                  padding: "5px 10px",
                  borderRadius: 10,
                  whiteSpace: "normal",
                }}
              >
                {intl.formatMessage(
                  { id: "startEndUserActionTypeDate" },
                  {
                    startDate: userData?.first,
                    startType: userData?.firstType,
                    lastDate: userData?.last,
                    lastType: userData?.lastType,
                  }
                )}
              </Tag>
            </div>
            <div
              style={{
                direction: "ltr",
                width: "85%",
                marginRight: "auto",
                marginLeft: "auto",
                padding: "10px 10px 0px 10px",
                borderRadius: 10,
              }}
              className='light-shadow'
            >
              <Chart
                type='line'
                data={{
                  labels,
                  datasets: data,
                }}
                options={{
                  responsive: true,
                  interaction: {
                    mode: "index",
                    intersect: false,
                  },
                  scales: {
                    y: {
                      beginAtZero: true,
                    },
                  },
                  plugins: {
                    subtitle: {
                      display: false,
                    },
                  },
                }}
              />
            </div>
          </div>
        ) : null}
      </Spin>
      {!loading  && !userData?.status ? (
        <Result
          status='404'
          title='404'
          subTitle={intl.formatMessage({ id: "pageError" })}
          extra={
            <Button type='primary' onClick={hide}>
              {intl.formatMessage({ id: "goBack" })}
            </Button>
          }
        />
      ) : null}
    </Modal>
  );
};

export default UserDetails;
