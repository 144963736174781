import React, { useState, useEffect } from "react";
import { Badge, Menu, Typography } from "antd";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import {
  changeUserAction,
  logOutAction,
} from "../../redux/reducers/authReducer";
import { useDispatch, useSelector } from "react-redux";
import { GiBarracksTent, GiRoundTable } from "react-icons/gi";
import { AiOutlineCar } from "react-icons/ai";
import {
  IoBagHandleOutline,
  IoFastFoodOutline,
  IoGridOutline,
  IoLanguageOutline,
  IoListCircleOutline,
  IoListOutline,
  IoLockClosedOutline,
  IoLogoBuffer,
  IoLogOut,
  IoLogOutOutline,
  IoNotificationsOffOutline,
  IoNotificationsOutline,
  IoQrCodeOutline,
  IoTicketOutline,
  IoTimeOutline,
} from "react-icons/io5";
import { BiSortAlt2 } from "react-icons/bi";
import { changeLanguageAction } from "../../redux/reducers/settingsReducer";
import COLORS from "../../Style/colors";
import openNotification from "../Notifications";
import {
  DELIVERY_ORDERS_STATUS,
  DINE_IN_ORDERS_STATUS,
  IWAITER_APP_ORDERS_STATUS,
  PICKUP_ORDERS_STATUS,
  SUBSCRIPTION_TYPES,
} from "../../Types";
import { Mixpanel } from "../../Mixpanel";
import { audio } from "../Audion";
import { signOutServer } from "../../API/fetch";
import { BankOutlined, BookOutlined } from "@ant-design/icons";

const { Text } = Typography;
const { SubMenu } = Menu;

const WaiterSide = ({ toggleSideBar }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const { tables } = useSelector((state) => state.table);
  const { branch } = useSelector((state) => state.branch);
  const { dineInOrders, pickupOrders, deliveryOrders, iwaiterAppOrders, customOrders } = useSelector(
    (state) => state.order
  );
  const { activeWaitingList, isCloseTmp } = useSelector(
    (state) => state.branch
  );
  const { restaurant } = useSelector((state) => state.restaurant)
  const { waiter, subscriptionEnd, services } = useSelector((state) => state.auth);
  const [notificationOn, setNotificationOn] = useState(false);
  const [numberOfOpenTables, setNumberOfOpenTables] = useState(0);
  const [numberOfOpenOrders, setNumberOfOpenOrders] = useState(0);
  const [numberOfOpenPickupOrders, setNumberOfOpenPickupOrders] = useState(0);
  const [numberOfScheduleOrder, setNumberOfScheduleOrder] = useState(0)
  const [numberOfPendingIwaiterAppOrders, setNumberOfPendingIwaiterAppOrders] = useState(0);
  const [numberOfCustomOrders, setNumberOfCustomOrders] = useState(0)
  const [numberOfOpenDeliveryOrders, setNumberOfOpenDeliveryOrders] =
    useState(0);

  const handelStartNotifications = () => {
    toggleSideBar()
    audio
      .play()
      .then(() => console.log("Playing"))
      .catch((err) => {
        if (rtl) {
          openNotification({
            type: "error",
            description:
              "الرجاء الضغط على زر الاشعارات في الشريط الجانبي لاستقبال الإشعارات",
            rtl,
            title: "خطأ",
          });
        } else {
          openNotification({
            type: "error",
            description:
              "please click on the notification bill on the side menu to start receiving notifications",
            rtl,
            title: "Error",
          });
        }
      });
    audio.loop = false;

    setNotificationOn(true);
  };

  useEffect(() => {
    if (tables?.length) {
      const openTable = tables?.filter((t) => {
        return t.orders?.length || t?.needHelp || t?.needPayment;
      })?.length;
      setNumberOfOpenTables(openTable);
    }
  }, [tables]);
  useEffect(() => {
    if (dineInOrders?.length) {
      const openOrders = dineInOrders?.filter((o) => {
        return o.status === DINE_IN_ORDERS_STATUS.OPEN;
      })?.length;
      setNumberOfOpenOrders(openOrders);
    }
    if (pickupOrders?.length) {
      const openOrders = pickupOrders?.filter((o) => {
        return o.status === PICKUP_ORDERS_STATUS.OPEN && !o.timeForPickup
      })?.length;
      setNumberOfOpenPickupOrders(openOrders);
      const openOrdersSchedule = pickupOrders?.filter((o) => {
        return o.status === PICKUP_ORDERS_STATUS.OPEN && o.timeForPickup
      })?.length;
      setNumberOfOpenPickupOrders(openOrders);
      setNumberOfScheduleOrder(openOrdersSchedule)
    }
    if (deliveryOrders?.length) {
      const openOrders = deliveryOrders?.filter((o) => {
        return o.status === DELIVERY_ORDERS_STATUS.OPEN;
      })?.length;
      setNumberOfOpenDeliveryOrders(openOrders);
    }
    if (customOrders.length) {
      const openOrders = customOrders?.filter((o) => {
        return o.status === DINE_IN_ORDERS_STATUS.OPEN;
      })?.length;
      setNumberOfCustomOrders(openOrders);
    }
    if (iwaiterAppOrders?.length) {
      const pendingOrders = iwaiterAppOrders?.filter((o) => {
        return o.status === IWAITER_APP_ORDERS_STATUS.OPEN || o.status === IWAITER_APP_ORDERS_STATUS.PENDING;
      })?.length;
      setNumberOfPendingIwaiterAppOrders(pendingOrders)
    }
  }, [dineInOrders, pickupOrders, deliveryOrders, iwaiterAppOrders]);

  return (
    <Menu theme='light' mode='inline' defaultSelectedKeys={["1"]}>
      <Menu.Item
        onClick={() => {
          Mixpanel.track("Waiter Tables Screen");
          toggleSideBar()
        }} key='tables' disabled={waiter?.dineInSections?.length === 0 || subscriptionEnd || !services?.length || (!services?.includes('ORDER_A') && !services?.includes('ORDER_B') && !services?.includes('RESERVATION'))} icon={<GiRoundTable size={20} />}>
        <Link to='/tables' >
          <Badge
            count={numberOfOpenTables}
            style={{ backgroundColor: COLORS.primary }}
            offset={rtl ? [-20, 7] : [14, 7]}
          >
            {intl.formatMessage({ id: "tables" })}
          </Badge>
        </Link>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          Mixpanel.track("Waiter orders Screen");
          toggleSideBar()
        }} key='orders' disabled={waiter?.dineInSections?.length === 0 || subscriptionEnd || !services?.length || (!services?.includes('ORDER_A') && !services?.includes('ORDER_B'))} icon={<IoListOutline size={20} />}>
        <Link to='/orders'>
          <Badge
            count={numberOfOpenOrders}
            style={{ backgroundColor: COLORS.primary }}
            offset={rtl ? [-20, 7] : [14, 7]}
          >
            {intl.formatMessage({ id: "orders" })}
          </Badge>
        </Link>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          Mixpanel.track("Waiter pickup Screen");
          toggleSideBar()
        }} key='pickup' disabled={!waiter?.receivePickup || subscriptionEnd || !services?.length || !services?.includes('PICKUP')} icon={<IoBagHandleOutline size={20} />}>
        <Link to='/pickup'>
          <Badge
            count={numberOfOpenPickupOrders}
            style={{ backgroundColor: COLORS.primary }}
            offset={rtl ? [-20, 7] : [14, 7]}
          >
            {intl.formatMessage({ id: "pickup" })}
          </Badge>
        </Link>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          Mixpanel.track("Waiter scheduleOrder Screen");
          toggleSideBar()
        }} key='scheduleOrder' disabled={!waiter?.receivePickup || subscriptionEnd || !services?.length || !services?.includes('PICKUP')} icon={<IoTimeOutline size={20} />}>
        <Link to='/scheduleOrder'>
          <Badge
            count={numberOfScheduleOrder}
            style={{ backgroundColor: COLORS.primary }}
            offset={rtl ? [-20, 7] : [14, 7]}
          >
            {intl.formatMessage({ id: "scheduleOrder" })}
          </Badge>
        </Link>
      </Menu.Item>


      <Menu.Item
        onClick={() => {
          Mixpanel.track("Waiter delivery Screen");
          toggleSideBar()
        }} key='delivery' disabled={!waiter?.receiveDelivery || subscriptionEnd || !services?.length || !services?.includes('PICKUP')} icon={<AiOutlineCar size={20} />}>
        <Link to='/delivery'>
          <Badge
            count={numberOfOpenDeliveryOrders}
            style={{ backgroundColor: COLORS.primary }}
            offset={rtl ? [-20, 7] : [14, 7]}
          >
            {intl.formatMessage({ id: "delivery" })}
          </Badge>
        </Link>
      </Menu.Item>
      {branch.DoesCustomOrderActive ?
        <Menu.Item
          disabled={!waiter?.receiveDelivery || subscriptionEnd || !services?.length || !services?.includes('PICKUP')}
          onClick={() => {
            Mixpanel.track("Custom Order");
            toggleSideBar();
          }}
          key='customOrders'
          icon={<BookOutlined size={20} />}
        >
          <Link to='/customOrders'>
            <Badge
              count={numberOfCustomOrders}
              style={{ backgroundColor: COLORS.primary }}
              offset={rtl ? [-20, 7] : [14, 7]}
            >
              {intl.formatMessage({ id: "customOrders" })}
            </Badge>
          </Link>
        </Menu.Item> : null}
      <Menu.Item
        onClick={() => {
          Mixpanel.track("Waiter iwaiterApp Screen");
          toggleSideBar()
        }} key='iwaiterApp' disabled={!waiter?.receiveIWaiterApp || subscriptionEnd || !services?.length || !services?.includes('PICKUP')} icon={<IoBagHandleOutline size={20} />}>
        <Link to='/iwaiterApp'>
          <Badge
            count={numberOfPendingIwaiterAppOrders}
            style={{ backgroundColor: COLORS.primary }}
            offset={rtl ? [-20, 7] : [14, 7]}
          >
            {intl.formatMessage({ id: "iwaiterAppOrders" })}
          </Badge>
        </Link>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          Mixpanel.track("Reservation Screen");
          toggleSideBar()
        }} key='reservation' disabled={!waiter?.receiveReservation || subscriptionEnd || !services?.length || !services?.includes('RESERVATION')} icon={<IoTicketOutline size={20} />}>
        <Link to='/reservation' >
          {intl.formatMessage({ id: "reservation" })}

        </Link>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          Mixpanel.track("Reservation reader Screen");
          toggleSideBar()
        }} key='reservation-reader' disabled={!waiter?.receiveReservation || subscriptionEnd || !services?.length || !services?.includes('RESERVATION')} icon={<IoQrCodeOutline size={20} />}>
        <Link to='/reservation-reader' >
          {intl.formatMessage({ id: "reservationReader" })}

        </Link>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          Mixpanel.track("Waiter waiting Screen");
          toggleSideBar()
        }} key='waiting' disabled={!waiter?.receiveWaiting || subscriptionEnd || !services?.length || !services?.includes('WAITING')} icon={<IoListCircleOutline size={20} />}>
        <Link to='/waiting' >
          <Badge
            count={activeWaitingList}
            style={{ backgroundColor: COLORS.primary }}
            offset={rtl ? [-20, 7] : [14, 7]}
          >
            {intl.formatMessage({ id: "waiting" })}
          </Badge>
        </Link>
      </Menu.Item>
      <SubMenu
        key='menu'
        icon={<IoFastFoodOutline size={20} />}
        title={intl.formatMessage({ id: "menu" })}
        disabled={!waiter?.canEditFoods || subscriptionEnd}
      >
         <Menu.Item
          disabled={
            subscriptionEnd ||
            !services?.length 
           
          }
          onClick={() => {
            Mixpanel.track("categories Screen");
            toggleSideBar();
          }}
          key='categories'
          icon={<IoGridOutline size={20} />}
        >
          <Link to='/categories'>
            {intl.formatMessage({ id: "categories" })}
          </Link>
        </Menu.Item>
        <Menu.Item
          disabled={subscriptionEnd || subscriptionEnd} onClick={() => {
            Mixpanel.track("Foods Screen");
            toggleSideBar()
          }} key='foods' icon={<IoFastFoodOutline size={20} />}>
          <Link to='/foods'>{intl.formatMessage({ id: "foods" })}</Link>
        </Menu.Item>
        <Menu.Item
          disabled={subscriptionEnd || subscriptionEnd} onClick={() => {
            Mixpanel.track("Sort Screen");
            toggleSideBar()
          }} key='sort' icon={<BiSortAlt2 size={20} />}>
          <Link to='/sort'>{intl.formatMessage({ id: "sort" })}</Link>
        </Menu.Item>
        <Menu.Item
          disabled={subscriptionEnd || subscriptionEnd} onClick={() => {
            Mixpanel.track("Modifiers Screen");
            toggleSideBar()
          }} key='modifiers' icon={<IoLogoBuffer size={20} />}>
          <Link to='/modifiers'>{intl.formatMessage({ id: "modifiers" })}</Link>
        </Menu.Item>
       
      </SubMenu>

      <Menu.Item
        disabled={subscriptionEnd || subscriptionEnd}
        key='notifications'
        onClick={handelStartNotifications}
        icon={
          notificationOn ? (
            <IoNotificationsOutline size={20} />
          ) : (
            <IoNotificationsOffOutline size={20} />
          )
        }
      >
        <Text disabled={subscriptionEnd} type='primary' className='my-font'>
          {" "}
          {intl.formatMessage({ id: "notifications" })}
        </Text>
      </Menu.Item>
      <Menu.Item
        disabled={subscriptionEnd || subscriptionEnd}
        key='close-tmp'
        onClick={() => {
          Mixpanel.track("Waiter close temporary Screen");
          toggleSideBar()
        }}
        icon={
          <IoLockClosedOutline
            size={20}
            color={isCloseTmp ? COLORS.danger : COLORS.primary}
          />
        }
      >
        <Link
          to='/close-tmp'
          style={{ color: isCloseTmp ? COLORS.danger : COLORS.primary }}
        >
          {intl.formatMessage({ id: "closeTmp" })}
        </Link>
      </Menu.Item>
      {/* <Menu.Item
        onClick={() => {
          dispatch(changeLanguageAction())
          toggleSideBar()
        }}
        key='language'
        icon={<IoLanguageOutline size={20} />}
      >
        <Text  type='primary' className='my-font'>
          {" "}
          {intl.formatMessage({ id: "oppLanguage" })}
        </Text>
      </Menu.Item> */}
      <Menu.Item
        disabled={subscriptionEnd || subscriptionEnd}
        key='changeUser'
        onClick={() => {
          dispatch(changeUserAction())
          toggleSideBar()
        }}
        icon={<IoLogOutOutline size={20} />}
      >
        <Text disabled={subscriptionEnd} type='primary' className='my-font'>
          {" "}
          {intl.formatMessage({ id: "changeUser" })}
        </Text>
      </Menu.Item>

      <Menu.Item
        key='signOut'
        onClick={async () => {
          try {
            await signOutServer()
            Mixpanel.track("Sign out");
            dispatch(logOutAction());
            toggleSideBar();
          } catch (error) {
            dispatch(logOutAction());

            toggleSideBar();
          }
        }}
        icon={<IoLogOut size={20} />}
      >
        <Text type='primary' className='my-font'>
          {" "}
          {intl.formatMessage({ id: "signout" })}
        </Text>
      </Menu.Item>
    </Menu>
  );
};

export default WaiterSide;
