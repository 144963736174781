import React, { useState, useEffect } from "react";
import { Card, Typography, Row, Col, Space, Checkbox } from "antd";
import { BsFillPuzzleFill } from "react-icons/bs";
import { GiBalloonDog, GiHorseHead } from "react-icons/gi";
import { IoStar } from "react-icons/io5";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../Components/CustomButton";
import openNotification from "../../../Components/Notifications";
import { postUpdateMultiFoods } from "../../../API/fetch";
import { setCategoriesAndFoodsAction } from "../../../redux/reducers/menuReducer";

const { Text } = Typography;
const Classes = ({ classes }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const { foods } = useSelector((state) => state.menu);

  const [foodsToPromote, setFoodsToPromote] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (classes && classes.length) {
      const puz = classes?.find((c) => c.name === "Puzzles");
      if (puz && puz?.foods?.length) {
        setFoodsToPromote(puz.foods?.map((f) => f._id));
      }
    }
  }, [classes]);

  const handelPromotion = async () => {
    try {
      setLoading(true);
      const foodIds = foodsToPromote;
      const stickers = ["New", "Chef"];
      const design = ["isBorder", "isBigFont"];
      const editFoods = foodIds.map((id, i) => {
        let isEven = i % 2 === 0;
        const selectedFood = foods.find((f) => f.foodId === id);
        let stickersIndex = Math.floor(Math.random() * 2);
        let designIndex = Math.floor(Math.random() * 2);
        return {
          _id: id,
          isBorder: selectedFood?.isBorder,
          isBigFont: selectedFood?.isBigFont,
          sticker: isEven ? stickers[stickersIndex] : selectedFood.sticker,
          [design[designIndex]]: true,
        };
      });
      const data = {
        foods: editFoods,
      };
      const res = await postUpdateMultiFoods(data);
      if (res.data?.categories) {
        dispatch(setCategoriesAndFoodsAction(res.data.categories));
      }

      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };
  return (
    <Row className={"pb-5"}>
      {classes.length > 0
        ? classes.map((cat) => {
            return (
              <Col
                key={cat.name}
                md={12}
                sm={24}
                xs={24}
                className='mb-3 mt-1'
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Card
                  key={cat.name}
                  style={{
                    width: "18rem",
                    textAlign: "center",
                    borderRadius: 8,
                  }}
                  title={cat.name}
                  extra={
                    cat.name === "Stars" ? (
                      <IoStar
                        size={20}
                        style={{ position: "relative", top: 5 }}
                      />
                    ) : cat.name === "Dogs" ? (
                      <GiBalloonDog
                        size={20}
                        style={{ position: "relative", top: 5 }}
                      />
                    ) : cat.name === "Puzzles" ? (
                      <BsFillPuzzleFill
                        size={20}
                        style={{ position: "relative", top: 5 }}
                      />
                    ) : cat.name === "Workhorses" ? (
                      <GiHorseHead
                        size={20}
                        style={{ position: "relative", top: 5 }}
                      />
                    ) : null
                  }
                  actions={[
                    cat.name === "Stars" ? (
                      <Text className='my-font' key={cat.name}>
                        {intl.formatMessage({ id: "starsMenuAction" })}
                      </Text>
                    ) : cat.name === "Dogs" ? (
                      <Text className='my-font' key={cat.name}>
                        {intl.formatMessage({ id: "dogsAction" })}
                      </Text>
                    ) : cat.name === "Puzzles" ? (
                      <Space direction='vertical' key={cat.name}>
                        <Text className='my-font'>
                          {intl.formatMessage({ id: "puzzlesAction" })}
                        </Text>
                        <CustomButton
                          type='primary'
                          text={intl.formatMessage({ id: "promote" })}
                          onClick={handelPromotion}
                          className='px-5 border-8'
                          disabled={loading || foodsToPromote?.length === 0}
                          loading={loading}
                        />
                      </Space>
                    ) : cat.name === "Workhorses" ? (
                      <Text className='my-font' key={cat.name}>
                        {intl.formatMessage({ id: "WorkhorsesAction" })}
                      </Text>
                    ) : null,
                  ]}
                >
                  {cat.name === "Puzzles" && cat.foods?.length ? (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {cat.foods.map((food) => {
                        return (
                          <Checkbox
                            key={food._id}
                            checked={foodsToPromote?.includes(food._id)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setFoodsToPromote([
                                  ...foodsToPromote,
                                  food._id,
                                ]);
                              } else {
                                setFoodsToPromote(
                                  foodsToPromote?.filter((f) => f !== food._id)
                                );
                              }
                            }}
                          >
                            {food?.name_en
                              ? rtl
                                ? food.name
                                : food.name_en
                              : food.name}
                          </Checkbox>
                        );
                      })}
                    </div>
                  ) : (
                    <ul>
                      {cat.foods.length > 0 ? (
                        cat.foods.map((food) => {
                          return (
                            <li key={food._id}>
                              {food.name_en
                                ? rtl
                                  ? food.name
                                  : food.name_en
                                : food.name}
                            </li>
                          );
                        })
                      ) : (
                        <p className='text-center'>
                          {intl.formatMessage({ id: "noEnoughData" })}
                        </p>
                      )}
                    </ul>
                  )}

                  {/* <Card.Footer>
                  {cat.name === "Stars" ? (
                    <p>{intl.formatMessage({ id: "starsMenuAction" })}</p>
                  ) : cat.name === "Dogs" ? (
                    <p>{intl.formatMessage({ id: "dogsAction" })}</p>
                  ) : cat.name === "Puzzles" ? (
                    <p>{intl.formatMessage({ id: "puzzlesAction" })}</p>
                  ) : cat.name === "Workhorses" ? (
                    <p>{intl.formatMessage({ id: "WorkhorsesAction" })}</p>
                  ) : null}

                  {cat.name === "Puzzles" ? (
                    <Button
                      disabled={cat?.foods?.length < 1}
                      variant='primary'
                      onClick={handelPromotion}
                    >
                      {intl.formatMessage({ id: "promote" })}
                    </Button>
                  ) : null}
                </Card.Footer> */}
                </Card>
              </Col>
            );
          })
        : null}
    </Row>
  );
};

export default Classes;
