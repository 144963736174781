import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17.19}
    height={19.864}
    {...props}
  >
    <g data-name="Group 12">
      <path
        d="M15.476 19.865H1.713C.721 19.865 0 19.297 0 18.514a2.312 2.312 0 0 1 .36-1.272l5.614-9.636V1.36C5.463.838 5.279.495 5.38.252c.035-.086.139-.231.436-.231h.061a1.3 1.3 0 0 1 .261-.023A2.8 2.8 0 0 1 6.5.025h4.191a2.783 2.783 0 0 1 .362-.027 1.3 1.3 0 0 1 .26.023h.061a.419.419 0 0 1 .437.231c.1.244-.083.587-.594 1.108v6.246l5.614 9.636a2.318 2.318 0 0 1 .36 1.272c0 .783-.721 1.351-1.714 1.351"
        fill={props?.fill || "#0a2240"}
      />
      <path
        d="m4.983 11.353-3.467 5.75a1.848 1.848 0 0 0-.2 1.215c.178.513.663.688 1.885.666 1.592-.028 11.416 0 11.416 0s1.055.113 1.247-.625c.214-.823-.115-1.226-.744-2.209s-2.7-4.379-2.7-4.379a3.242 3.242 0 0 1-1.585.723 5.81 5.81 0 0 1-2.971-.293c-.737-.27-2.587-1.194-2.88-.849"
        fill="#fff"
      />
    </g>
  </svg>
)

export default SvgComponent
