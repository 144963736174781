import { SendOutlined } from "@ant-design/icons";
import { Card, Typography, Avatar, Space } from "antd";
import Meta from "antd/lib/card/Meta";
import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import LOGO from "../../../assets/images/main-logo.png";
import COLORS from "../../../Style/colors";

const { Text, Title } = Typography;

function RestaurantInfo({ restaurant, joinRestaurant }) {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  return (
    <Card
      style={{
        maxWidth: 300,
        margin: 10,
        borderRadius: 10,
        overflow: "hidden",
      }}
      actions={[
        <div
          className='cursor-pointer'
          onClick={() =>
            joinRestaurant({
              credentials: {
                phone: restaurant?.owner?.phone,
              },
            })
          }
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: COLORS.lightBlue,
            padding: 5,
            width: "80%",
            marginRight: "auto",
            marginLeft: "auto",
            borderRadius: 10,
          }}
        >
          <SendOutlined
            key='setting'
            style={{ fontSize: 20, color: COLORS.primary }}
          />
          <Title
            level={5}
            className='my-font mx-4'
            style={{ color: COLORS.primary }}
          >
            {intl.formatMessage({ id: "manage" })}
          </Title>
        </div>,
      ]}
      bordered={false}
      className='light-shadow my-font'
    >
      <Meta
        avatar={
          <Avatar
            src={restaurant?.logo?.length ? restaurant?.logo[0]?.url : LOGO}
          />
        }
        title={rtl ? restaurant?.name : restaurant.name_en}
        description={
          <Space direction='vertical' size={"small"}>
            <span style={{ fontWeight: "400", margin: 5 }}>
              {intl.formatMessage({ id: "phone" })} : {restaurant?.owner?.phone}{" "}
            </span>
            <span style={{ fontWeight: "400", margin: 5 }}>
              {intl.formatMessage({ id: "branchesNb" })} :{" "}
              {restaurant?.branches?.length}{" "}
            </span>
          </Space>
        }
      />
    </Card>
  );
}

export default RestaurantInfo;
