import * as React from "react"

const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} {...props}>
    <path
      d="M15.917 13.009a2.073 2.073 0 0 0-1.853 1.272l-2.862-1.989a3.289 3.289 0 0 0 .176-1.036 2.744 2.744 0 0 0-1.985-2.819l.028-3.486a2.408 2.408 0 0 0 1.741-2.432A2.333 2.333 0 0 0 9.079 0a2.315 2.315 0 0 0-2.124 2.471 2.428 2.428 0 0 0 1.7 2.472l-.028 3.486a2.721 2.721 0 0 0-2.03 2.772 3.329 3.329 0 0 0 .06.648L3.611 13.61a1.955 1.955 0 0 0-1.489-.759 2.315 2.315 0 0 0-2.123 2.471 2.334 2.334 0 0 0 2.083 2.52 2.315 2.315 0 0 0 2.124-2.471 2.891 2.891 0 0 0-.167-1l2.905-1.68a2.346 2.346 0 0 0 2.021 1.371 2.251 2.251 0 0 0 1.841-1l3.009 2.091a2.914 2.914 0 0 0-.022.321 2.334 2.334 0 0 0 2.083 2.52A2.315 2.315 0 0 0 18 15.523a2.335 2.335 0 0 0-2.083-2.52"
      fill={props?.fill || "#0a2240"}
    />
  </svg>
)

export default SvgComponent
