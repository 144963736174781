import {
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Typography,
  Divider,
  Space,
} from "antd";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { useIntl } from "react-intl";
import CustomButton from "../../../Components/CustomButton";
import {
  editGroupCoupons,
  getAllCouponsForRestaurant,
} from "../../../API/fetch";
import { useDispatch, useSelector } from "react-redux";
import openNotification from "../../../Components/Notifications";
import { getRandomCodes } from "../../../helpers/coupons";
import { addMultiplesCouponsAction } from "../../../redux/reducers/restaurantReducer";

const { Text, Title } = Typography;
const EditGroupCoupons = ({ show, hide, couponType, allCoupons }) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const { rtl } = useSelector((state) => state.settings);
  const { waiter } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [allCodes, setAllCodes] = useState([]);
  const dispatch = useDispatch();

  // get all coupons
  useEffect(() => {
    getAllCoupons();
  }, []);

  // get the group data;
  useEffect(() => {
    if (couponType && allCoupons) {
      if (allCoupons.length > 0 && allCoupons[0].couponInfo) {
        const { couponInfo } = allCoupons[0];

        form.setFieldsValue({
          name: couponInfo.name,
          percentage: couponInfo.percentage,
          maximumAmount: couponInfo.maximumAmount,
          numberOfUsed: couponInfo.numberOfUsed,
          startDate: moment(couponInfo.startDate),
          endDate: moment(couponInfo.endDate),
          countOfCoupon: allCoupons.length,
        });
      }
    }
  }, [allCoupons, show, hide]);

  const getAllCoupons = async () => {
    try {
      const res = await getAllCouponsForRestaurant();
      if (res.data.coupons) {
        setAllCodes(res.data.coupons.map((coupon) => coupon.code));
      }
    } catch (error) {
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  function disabledStartDate(current) {
    // Can not select days before today and today
    return current && current < moment().startOf("day");
  }
  function disabledEndDate(current) {
    // Can not select days before today and today
    let endDate = form.getFieldValue("startDate");
    return current && current <= moment(endDate).startOf("day");
  }

  const editGroup = async (values) => {
    try {
      setLoading(true);
      const groupId = allCoupons[0].couponInfo?.groupId;
      let endDate = moment(values.endDate).startOf("day").toISOString();
      let startDate = moment(values.startDate).startOf("day").toISOString();
      const data = {
        groupId,
        name: values.name,
        endDate,
        code: waiter.code,
        coupons: [],
        isMultiple: true,
      };
      if (values?.countOfCoupon > allCoupons.length) {
        const codes = getRandomCodes({
          count: values.countOfCoupon - allCoupons.length,
          oldCodes: allCodes,
        });
        const newFormattedCoupons = codes.map((code) => {
          return {
            ...values,
            startDate,
            endDate,
            code,
          };
        });
        data.coupons = newFormattedCoupons;
      }
      const res = await editGroupCoupons(data);
      if (res.data.coupons) {
        dispatch(addMultiplesCouponsAction(res.data.coupons));
      }
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      hide();
    } catch (error) {
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
      setLoading(false);
    }
  };
  return (
    <Modal
      title={
        <p className='my-font'>
          {intl.formatMessage({ id: "editRandomCoupons" })}
        </p>
      }
      centered
      visible={show}
      destroyOnClose={true}
      mask={true}
      getContainer={false}
      maskClosable={true}
      onCancel={() => {
        hide();
      }}
      footer={null}
    >
      <Form
        name='Edit-Random-Coupon'
        form={form}
        preserve={false}
        className='form-container'
        layout='vertical'
        onFinish={editGroup}
        initialValues={{
          name: "",
          percentage: "",
          maximumAmount: 0,
          numberOfUsed: 1,
          startDate: moment().startOf("day"),
          endDate: moment().add(1, "days").startOf("day"),
        }}
      >
        <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
          {/* Names */}
          <Col md={24} sm={24} xs={24}>
            <Form.Item
              label={intl.formatMessage({ id: "couponName" })}
              className='mb-1'
              tooltip={
                <Text type='primary' className='my-font'>
                  {intl.formatMessage({ id: "explainCouponName" })}
                </Text>
              }
              name='name'
              style={{ borderRadius: 20 }}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: "errorname" }),
                },
              ]}
            >
              <Input
                className={"input"}
                style={{ borderRadius: 5, width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
          <Col md={24} sm={24} xs={24}>
            <Form.Item
              label={intl.formatMessage({ id: "countOfCoupon" })}
              className='mb-1'
              name='countOfCoupon'
              rules={[
                {
                  required: true,
                },
              ]}
              tooltip={<Text className="my-font">{intl.formatMessage({id: 'countOfCouponMax'})}</Text>}
            >
              <InputNumber
                className={"input"}
                placeholder={"50"}
                max={399}
                min={form.getFieldValue("countOfCoupon")}
                style={{ borderRadius: 5, width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
          <Col md={12} sm={24} xs={24}>
            <Form.Item
              label={intl.formatMessage({ id: "percentage" })}
              className='mb-1'
              name='percentage'
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: "errorname" }),
                },
              ]}
            >
              <InputNumber
                className={"input"}
                placeholder={"10%"}
                min={0}
                disabled={true}
                style={{ borderRadius: 5, width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col md={12} sm={24} xs={24}>
            <Form.Item
              label={intl.formatMessage({ id: "maximumAmount" })}
              className='mb-1'
              name='maximumAmount'
              tooltip={
                <Text type='primary' className='my-font'>
                  {intl.formatMessage({ id: "explainCouponMax" })}
                </Text>
              }
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: "errorname" }),
                },
              ]}
            >
              <InputNumber
                className={"input"}
                placeholder={"50"}
                min={0}
                disabled={true}
                style={{ borderRadius: 5, width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
          <Col md={12} sm={24} xs={24}>
            <Form.Item
              label={intl.formatMessage({ id: "MaxNumberOfUsed" })}
              className='mb-1'
              name='numberOfUsed'
              tooltip={
                <Text type='primary' className='my-font'>
                  {intl.formatMessage({ id: "explainCouponMaxUsed" })}
                </Text>
              }
            >
              <InputNumber
                className={"input"}
                placeholder={""}
                min={0}
                disabled={true}
                style={{ borderRadius: 5, width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col md={12} sm={24} xs={24}></Col>
        </Row>
        <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
          <Col md={12} sm={24} xs={24}>
            <Form.Item
              label={intl.formatMessage({ id: "startDate" })}
              className='mb-1'
              name='startDate'
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: "errorname" }),
                },
              ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                disabledDate={disabledStartDate}
                disabled={true}
                onChange={(e) => {
                  form.setFieldsValue({
                    endDate: moment(e).add(1, "days").startOf("day"),
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col md={12} sm={24} xs={24}>
            <Form.Item
              label={intl.formatMessage({ id: "endDate" })}
              className='mb-1'
              name='endDate'
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: "errorname" }),
                },
              ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                disabledDate={disabledEndDate}
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Space direction='horizontal'>
          <Form.Item>
            <CustomButton
              htmlType='submit'
              text={intl.formatMessage({ id: "save" })}
              className={`btnRegister btn-text border-8`}
              loading={loading}
              disabled={loading}
              type='primary'
            />
          </Form.Item>

          <Form.Item>
            <CustomButton
              type='text'
              text={intl.formatMessage({ id: "cancel" })}
              disabled={loading}
              onClick={() => {
                hide();
              }}
            />
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  );
};

export default EditGroupCoupons;
