import React, { useState, useEffect } from "react";
import {
  Form,
  Space,
  Divider,
  Modal,
  Typography,
  Row,
  Select,
  Input,
  Radio,
  Col,
  Steps,
} from "antd";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../Components/CustomButton";
import openNotification from "../../../Components/Notifications";
import {
  addMarnInformation,
  getBranchData,
  getRestaurantData,
  postMarnIntegration,
} from "../../../API/fetch";
import {
  setIntegrationsAction,
  setRestaurantAction,
} from "../../../redux/reducers/restaurantReducer";
import { logOutAction } from "../../../redux/reducers/authReducer";
import { setMenuAction } from "../../../redux/reducers/menuReducer";
import {
  setBranchAction,
  setBranchesAction,
} from "../../../redux/reducers/branchReducer";
import { setTablesAction } from "../../../redux/reducers/tableReducer";
import {
  setDeliveryOrdersAction,
  setDineInOrdersAction,
  setIwaiterAppOrdersAction,
  setPickupOrdersAction,
} from "../../../redux/reducers/orderReducer";

const { Option } = Select;
const { Title, Text } = Typography;
const { Step } = Steps;

const MarnFirstTimeModal = ({ show, hide }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { rtl } = useSelector((state) => state.settings);
  const { allBranches } = useSelector((state) => state.branch);
  const { waiter } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [showBranch, setShowBranch] = useState(false);
  const [marnBranches, setMarnBranches] = useState([]);
  const [tablesLength, setTablesLength] = useState(0);

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        form.resetFields();
      }, 400);
    }
  }, [show]);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      if (showBranch) {
        // Handeld selected branch and tables;
        handleSelectedBranch(values);
      } else {
        // first time
        if (!values.brandKey) {
          form.setFields([
            {
              name: "brandKey",
              errors: [intl.formatMessage({ id: "errorname" })],
            },
          ]);
          setLoading(false);
          return;
        }
        let withMenu = true;
        if (values.withMenu === "FALSE") {
          withMenu = false;
        }
        const data = {
          brandKey: values.brandKey,
          withMenu,
        };

        const res = await postMarnIntegration(data);
        dispatch(setIntegrationsAction(res.data.integration));
        setShowBranch(true);
        setMarnBranches(res.data.marnBranches);
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
        setLoading(false);
      }
      // hide();
    } catch (error) {
      console.log("error", error);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
      setLoading(false);
    }
  };

  const handleSelectedBranch = async (values) => {
    try {
      if (!values.branch) {
        form.setFields([
          {
            name: "branch",
            errors: [intl.formatMessage({ id: "errorname" })],
          },
        ]);
        setLoading(false);
        return;
      }

      const newTables = [];
      let marnTables = [];
      const selected = marnBranches?.find(
        (b) => b.marnLocationKey === values.branch
      );
      if (selected && selected?.tables?.length) {
        selected.tables.forEach((t) => {
          const oneTable = {
            name: t.marnTableName,
            name_en: t.marnTableName,
            zone: t.marnSectionName,
            owner: allBranches[0].branch._id,
          };
          newTables.push(oneTable);
          marnTables.push({
            name: t.marnTableName,
            marnId: t.marnTableId,
            sectionId: t.marnSectionId,
          });
        });
      }

      const data = {
        addTables: values.addTables,
        marnBranch: values.branch,
        newTables,
        localBranch: allBranches[0].branch._id,
        marnTables,
      };
      const res = await addMarnInformation(data);

      if (res.data.status === "success") {
        getInitialData();
      } else {
        dispatch(logOutAction());
      }
    } catch (error) {
      setLoading(false);
      hide();
    }
  };

  const getInitialData = async () => {
    try {
      const restaurantRes = await getRestaurantData();
      const branchRes = await getBranchData(waiter.code);
      dispatch(setRestaurantAction(restaurantRes.data.restaurant));
      dispatch(setMenuAction(restaurantRes.data.menu));
      dispatch(setBranchesAction(branchRes.data.branches));
      if (branchRes.data.isSuper === false) {
        dispatch(setBranchAction(branchRes.data.branches[0]));
      }
      dispatch(setTablesAction(branchRes.data.tables));
      dispatch(setDineInOrdersAction(branchRes.data.orders));
      dispatch(setPickupOrdersAction(branchRes.data.pickupOrders));
      dispatch(setDeliveryOrdersAction(branchRes.data.deliveryOrders));
      dispatch(setIwaiterAppOrdersAction(branchRes.data.iwaiterAppOrders));
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      hide();
    } catch (error) {
      console.log("error", error);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
      dispatch(logOutAction());
      setLoading(false);
    }
  };

  return (
    <Modal
      title={
        <Title level={4} className='my-font'>
          {intl.formatMessage({ id: "marn" })}
        </Title>
      }
      centered
      visible={show}
      destroyOnClose={true}
      mask={true}
      maskClosable={true}
      onCancel={() => hide()}
      footer={null}
    >
      <div className='mb-3'>
        <Steps current={showBranch ? 1 : 0}>
          <Step title={intl.formatMessage({ id: "start" })} />
          <Step title={intl.formatMessage({ id: "chooseBranch" })} />
        </Steps>
      </div>
      <Form
        name='editTable'
        form={form}
        preserve={false}
        className='form-container'
        layout='vertical'
        onFinish={onFinish}
        initialValues={{
          brandKey: "",
          withMenu: "TRUE",
          branch: "",
          addTables: "TRUE",
        }}
      >
        {() => {
          return (
            <>
              {showBranch ? (
                <>
                  {/* After link branch , select the branch and ask if client wanter to add the tables as Marn */}
                  <Row>
                    <Title level={5}>
                      {intl.formatMessage({ id: "marnInfo" })}
                    </Title>
                  </Row>
                  <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                    <Col md={24} sm={24} xs={24}>
                      <Form.Item
                        label={intl.formatMessage({ id: "manrBranches" })}
                        className='mb-1'
                        name='branch'
                      >
                        <Select
                          className='my-font'
                          onChange={(id) => {
                            const bra = marnBranches.find(
                              (b) => b.marnLocationKey === id
                            );
                            let tableLength = 0;
                            if (bra && bra?.tables?.length) {
                              tableLength += bra.tables.length;
                            }
                            setTablesLength(tableLength);
                          }}
                        >
                          <Option className='my-font' value=''>
                            {intl.formatMessage({ id: "choose" })}
                          </Option>
                          {marnBranches?.map((branch) => {
                            return (
                              <Option
                                key={branch.marnLocationKey}
                                className='my-font'
                                value={branch.marnLocationKey}
                              >
                                {branch.marnName}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={24} sm={24} xs={24}>
                      <Form.Item
                        name='addTables'
                        label={`${intl.formatMessage({
                          id: "youHaveTableMarn",
                        })} - ${
                          tablesLength ? tablesLength : "x"
                        } ${intl.formatMessage({
                          id: "table",
                        })} ${intl.formatMessage({
                          id: "deleteAllTableAndAddMarn",
                        })}`}
                        disabled={form.getFieldValue("branch") === ""}
                      >
                        <Radio.Group>
                          <Radio value='TRUE' disabled={tablesLength === 0}>
                            {intl.formatMessage({ id: "yes" })}
                          </Radio>
                          <Radio value='FALSE' disabled={tablesLength === 0}>
                            {intl.formatMessage({ id: "no" })}
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  {/* new link Enter brand key */}
                  <Row>
                    <Title level={5}>
                      {intl.formatMessage({ id: "marnDes" })}
                    </Title>
                  </Row>
                  <Form.Item
                    label={"Brand key"}
                    className='mb-1'
                    name='brandKey'
                    tooltip={
                      <Text className='my-font'>
                        {intl.formatMessage({ id: "brandKeyExplain" })}
                      </Text>
                    }
                  >
                    <Input
                      className='input'
                      placeholder='xxxxx-xxxx-xxxx-xxxx'
                    />
                  </Form.Item>
                  <Form.Item
                    name='withMenu'
                    label={intl.formatMessage({ id: "doYouWantBringMenu" })}
                  >
                    <Radio.Group>
                      <Radio value='TRUE'>
                        {intl.formatMessage({ id: "yes" })}
                      </Radio>
                      <Radio value='FALSE'>
                        {intl.formatMessage({ id: "no" })}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </>
              )}

              <Divider />
              <Space direction='horizontal'>
                <Form.Item>
                  <CustomButton
                    htmlType='submit'
                    text={intl.formatMessage({ id: "save" })}
                    className={`btnRegister btn-text border-8`}
                    loading={loading}
                    disabled={
                      loading ||
                      (showBranch && form.getFieldValue("branch") === "") ||
                      (!showBranch && form.getFieldValue("brandKey") === "")
                    }
                    type='primary'
                  />
                </Form.Item>

                <Form.Item>
                  <CustomButton
                    type='text'
                    text={intl.formatMessage({ id: "cancel" })}
                    disabled={loading}
                    onClick={() => {
                      hide();
                    }}
                  />
                </Form.Item>
              </Space>
            </>
          );
        }}
      </Form>
    </Modal>
  );
};

export default MarnFirstTimeModal;
