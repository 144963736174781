import * as React from "react"

const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} {...props}>
    <g data-name="Group 1">
      <path
        d="M12.321 7.342a5.161 5.161 0 0 0-2.965-1.953c-.648-.194-2.182-.416-2.337-1.617C6.813 2.183 6.1 1.303 4.073.637 2.911.255.94-.356.273.267c-.478.444-.237 2.725 0 3.919a3.539 3.539 0 0 0 3.184 2.912c1.243.2 1.5 1.646 1.619 2.286a3.725 3.725 0 0 0 1.807 2.945c1.272.864 2.942.667 3.834 1.547s.711 2.176 2.155 3.35a3.048 3.048 0 0 0 4.445-4.145c-1.064-1.5-2.539-1.279-3.432-2.159s-.154-1.761-1.558-3.581"
        fill={props?.fill || "#0a2240"}
      />
      <path
        d="M1.613 1.598a2.35 2.35 0 0 0 .259 3.4 2.576 2.576 0 0 0 3.563.474 2.583 2.583 0 0 0-.372-3.579 2.4 2.4 0 0 0-3.449-.3"
        fill="#fff"
      />
      <path
        data-name="path180"
        d="M7.113 7.098a2.35 2.35 0 0 0 .259 3.4 2.576 2.576 0 0 0 3.563.474 2.583 2.583 0 0 0-.372-3.579 2.4 2.4 0 0 0-3.449-.3M13.077 13.028a1.983 1.983 0 0 0 .243 3 2.537 2.537 0 0 0 3.352.417c.829-.755.672-2.168-.35-3.154a2.366 2.366 0 0 0-3.245-.261"
        fill="#fff"
      />
    </g>
  </svg>
)

export default SvgComponent
