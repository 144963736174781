import React, { useState } from "react";
import CustomButton from "../../Components/CustomButton";
import { Typography, Card, Row, Col, Image } from "antd";
import { useIntl } from "react-intl";
import COLORS from "../../Style/colors";
import FOODICS from "../../assets/images/Companies/foodics.png";
import MARN from "../../assets/images/Companies/marnPOS.png";
import ALSHARQ from "../../assets/images/Companies/alshroq.png";
import { SettingOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import FoodicsModal from "./Components/FoodicsModal";
import { foodicsClientKey, foodicsURL } from "../../API/config";
import MarnFirstTimeModal from "./Components/MarnFirstTimeModal";
import MarnModal from "./Components/MarnModal";
import LOGO from "../../assets/images/main-logo.png";
import DeliveryModal from "./Delivery/DeliveryModal";
const { Title, Text, Link } = Typography;

const COMPANIES = [
  {
    name: "فودكس",
    name_en: "Foodics",
    logo: FOODICS,
    title: "foodics",
    description: "foodicsDes",
    link: "https://www.foodics.com",
  },
  {
    name: "مرن",
    name_en: "Marn",
    logo: MARN,
    title: "marn",
    description: "marnDescription",
    link: "https://marn.com/",
  },
  {
    name: "اي ويتر ديليفري",
    name_en: "iwaiter Delivery",
    logo: LOGO,
    title: "iwaiterDelivery",
    description: "iwaiterDeliveryDes",
    link: "https://board.iwaiter.net/integration",
  },
];

const { Meta } = Card;
const cardStyle = {
  width: "330px",
  height: "322px",
  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  marginRight: "auto",
  marginLeft: "auto",
  textAlign: "center",
};
const cardStyleForMobile = {
  width: "280px",
  height: "322px",
  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  marginRight: "auto",
  marginLeft: "auto",
  textAlign: "center",
};

const Integration = () => {
  const intl = useIntl();
  const { integrations, restaurant } = useSelector((state) => state.restaurant);
  const { isBigScreen } = useSelector((state) => state.settings);
  const [showFoodicsModal, setShowFoodicsModal] = useState(false);
  const [showMarnModal, setShowMarnModal] = useState(false);
  const [showMarnFirstTime, setShowMarnFirstTime] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDelivery, setShowDelivery] = useState(false);
  const handleShowModal = (company) => {
    if (
      company.name_en === "Foodics" &&
      (integrations?.posStatus === "active" ||
        integrations?.posStatus === "request" ||
        integrations.posStatus === "ready")
    ) {
      setShowFoodicsModal(true);
    } else if (
      company.name_en === "Foodics" &&
      (integrations?.posStatus === "NON" ||
        integrations?.posStatus === "deleted")
    ) {
      connectFoodics();
    } else if (
      company.name_en === "Marn" &&
      (integrations?.posStatus === "active" ||
        integrations?.posStatus === "request" ||
        integrations.posStatus === "ready")
    ) {
      setShowMarnModal(true);
    } else if (
      company.name_en === "Marn" &&
      (integrations?.posStatus === "NON" ||
        integrations?.posStatus === "deleted")
    ) {
      setShowMarnFirstTime(true);
    } else if (company.name_en === "iwaiter Delivery") {
      console.log(integrations.deliveryCompanies[0]);
      // check if we have integrated with same company before;
      if (
        integrations?.deliveryCompanies?.length &&
        integrations.deliveryCompanies?.[0]?.name === "iwaiter Delivery" &&
        company.name_en === "iwaiter Delivery"
      ) {
        // we have integrated with same company before
        console.log("we have integrated with same company before");
        setShowDelivery(true);
      } else {
        console.log("iwaiter Delivery");
        setShowDelivery(true);
      }
    } else {
      console.log("Elese");
    }
  };

  const connectFoodics = () => {
    setLoading(true);
    const client = foodicsClientKey;
    let url = `${foodicsURL}/authorize?client_id=${client}&state=${restaurant.slug}`;
    localStorage.setItem("slug", restaurant.slug);
    window.location.href = url;
  };

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      {/* Foodics Modal */}
      <FoodicsModal
        show={showFoodicsModal}
        hide={() => setShowFoodicsModal(false)}
      />
      {/* Marn First time */}
      <MarnFirstTimeModal
        show={showMarnFirstTime}
        hide={() => setShowMarnFirstTime(false)}
      />
      {/* Marn Edit Modal */}
      <MarnModal show={showMarnModal} hide={() => setShowMarnModal(false)} />
      {/* End--- */}
      {/* Delivery Modal */}
      <DeliveryModal
        deliveryCompanies={integrations?.deliveryCompanies}
        show={showDelivery}
        hide={() => setShowDelivery(false)}
      />

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <Title>{intl.formatMessage({ id: "integration" })}</Title>
      </div>
      <div
        style={{ maxWidth: "100%", marginRight: "auto", marginLeft: "auto" }}
      >
        <Text className='my-font'>
          {intl.formatMessage({ id: "integrationDes" })}
        </Text>
      </div>
      <div
        style={{
          backgroundColor: COLORS.white,
          marginTop: 40,
          width: "92%",
          marginRight: "auto",
          marginLeft: "auto",
          borderRadius: 10,
        }}
        className='light-shadow'
      >
        <Row className='px-2 py-5'>
          {COMPANIES.map((company, index) => {
            let isFoodicsDisabled =
              integrations?.posName === "Marn" && company.name_en === "Foodics"
                ? true
                : false;
            let isMarnDisabled =
              integrations?.posName === "foodics" && company.name_en === "Marn"
                ? true
                : false;
            let isAhsroqDisabled =
              integrations?.deliveryCompanies?.length &&
              integrations.deliveryCompanies?.[0]?.name_en ===
                "iwaiter Delivery" &&
              company.name_en === "iwaiter Delivery"
                ? true
                : false;

            let doesHasIntegration = false;
            if (
              (integrations?.posStatus !== "NON" &&
                company?.name_en?.toLocaleLowerCase() ===
                  integrations.posName?.toLocaleLowerCase()) ||
              (integrations?.deliveryCompanies?.length &&
                integrations.deliveryCompanies?.[0]?.name === company.name_en &&
                company.name_en === "iwaiter Delivery")
            ) {
              doesHasIntegration = true;
            }
            let isDelivery = company.name_en === "iwaiter Delivery";

            return (
              <Col
                key={company.name_en}
                md={12}
                sm={24}
                xs={24}
                className='mb-3'
              >
                <Card
                  loading={false}
                  style={isBigScreen ? cardStyle : cardStyleForMobile}
                  className='my-2 my-font'
                  cover={
                    <Image
                      width={isBigScreen ? 330 : 200}
                      style={{ height: 130, objectFit: "contain" }}
                      preview={false}
                      src={company.logo}
                    />
                  }
                  actions={[
                    <CustomButton
                      text={intl.formatMessage({
                        id: doesHasIntegration ? "manage" : "integrate",
                      })}
                      type='primary'
                      className='border-8 px-5'
                      disabled={
                        isFoodicsDisabled ||
                        isMarnDisabled ||
                        isAhsroqDisabled ||
                        loading ||
                        (isDelivery && !doesHasIntegration)
                      }
                      loading={loading}
                      onClick={() => handleShowModal(company)}
                      icon={doesHasIntegration ? <SettingOutlined /> : null}
                    />,
                    <Link
                      href={company.link}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {intl.formatMessage({ id: "website" })}
                    </Link>,
                  ]}
                >
                  <Meta
                    title={intl.formatMessage({ id: company.title })}
                    description={intl.formatMessage({
                      id: company.description,
                    })}
                  />
                </Card>
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
};

export default Integration;
