import React, { useState } from "react";
import {
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Divider,
  Space,
  Typography,
  Row,
  Col,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import CustomButton from "../../../Components/CustomButton";

import openNotification from "../../../Components/Notifications";

import { postAddWaitingArea } from "../../../API/fetch";
import {
  editBranchInAllBranchAction,
  setBranchAction,
} from "../../../redux/reducers/branchReducer";
const { Text } = Typography;
const { Option } = Select;

const AddWaitingAreaModal = ({ show, hide, setActiveTab }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { branch } = useSelector((state) => state.branch);
  const { waiter } = useSelector((state) => state.auth);
  const { rtl } = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const addArea = async (values) => {
    try {
      setLoading(true);
      const data = {
        ...values,
        owner: branch._id,
        code: waiter.code,
      };

      const res = await postAddWaitingArea(branch._id, data);
      dispatch(setBranchAction(res.data.branch));
      dispatch(editBranchInAllBranchAction(res.data.branch));
      // dispatch(addNewAreaAction(res.data.area));
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setLoading(false);
      hide();
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  return (
    <Modal
      title={
        <p className='my-font'>{intl.formatMessage({ id: "waitingArea" })}</p>
      }
      centered
      visible={show}
      destroyOnClose={true}
      mask={true}
      maskClosable={true}
      onCancel={() => {
        hide();
      }}
      footer={null}
    >
      <Form
        name='Add-Area'
        form={form}
        preserve={false}
        className='form-container'
        layout='vertical'
        onFinish={addArea}
        initialValues={{
          name: "",
          name_en: "",
          maxChairs: 12,
          waitingTimePerUser: 0,
          maxUsers: 4,
          status: "Active",
          waitingNote: "",
          waitingNote_en: "",
        }}
      >
        <Form.Item
          label={intl.formatMessage({ id: "nameAr" })}
          className='mb-1'
          name='name'
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: "errorname" }),
            },
          ]}
        >
          <Input className={"input"} />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: "nameEn" })}
          className='mb-1'
          name='name_en'
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: "errorname" }),
            },
          ]}
        >
          <Input className={"input"} />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: "maxUsers" })}
          className='mb-1'
          name='maxUsers'
        >
          <InputNumber
            style={{
              width: "100%",
            }}
            className={"input"}
            min={0}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: "maxChairs" })}
          className='mb-1'
          name='maxChairs'
        >
          <InputNumber
            style={{
              width: "100%",
            }}
            className={"input"}
            min={0}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: "waitingTimePerUser" })}
          tooltip={
            <Text className='my-font'>
              {intl.formatMessage({ id: "waitingTimePerUserExplain" })}
            </Text>
          }
          className='mb-1'
          name='waitingTimePerUser'
        >
          <InputNumber
            style={{
              width: "100%",
            }}
            className={"input"}
            min={0}
          />
        </Form.Item>
        <Row gutter={{ xs: 16, sm: 16, md: 32, lg: 40 }}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Form.Item
              label={intl.formatMessage({ id: "noteAr" })}
              className='mb-1'
              name='waitingNote'
              tooltip={
                <Text className='my-font'>
                  {intl.formatMessage({ id: "waitingNote" })}
                </Text>
              }
            >
              <Input className={"input"} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Form.Item
              label={intl.formatMessage({ id: "noteEn" })}
              className='mb-1'
              name='waitingNote_en'
              tooltip={
                <Text className='my-font'>
                  {intl.formatMessage({ id: "waitingNote" })}
                </Text>
              }
            >
              <Input className={"input"} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label={intl.formatMessage({ id: "status" })}
          className='mb-1'
          name='status'
        >
          <Select className='my-font'>
            <Option value='Active' className='my-font'>
              {intl.formatMessage({ id: "Active" })}
            </Option>
            <Option value='disabled' className='my-font'>
              {intl.formatMessage({ id: "disabled" })}
            </Option>
          </Select>
        </Form.Item>

        <Divider />
        <Space direction='horizontal'>
          <Form.Item>
            <CustomButton
              htmlType='submit'
              text={intl.formatMessage({ id: "save" })}
              className={`btnRegister btn-text border-8`}
              loading={loading}
              disabled={loading}
              type='primary'
            />
          </Form.Item>

          <Form.Item>
            <CustomButton
              type='text'
              text={intl.formatMessage({ id: "cancel" })}
              disabled={loading}
              onClick={() => {
                hide();
              }}
            />
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  );
};

export default AddWaitingAreaModal;
