import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, Row, Typography, Badge, Empty } from "antd";
import TableCard from "./Components/TableCard";
import CustomButton from "../../Components/CustomButton";
import {
  IoArrowBackOutline,
  IoArrowForwardOutline,
  IoReloadOutline,
  IoSettingsOutline,
} from "react-icons/io5";
import { setDefaultPositionAction } from "../../redux/reducers/tableReducer";
import COLORS from "../../Style/colors";
import EditTableModal from "./Components/OneTableView/Components/EditTableModal";
import { useNavigate } from "react-router";
import { SUBSCRIPTION_TYPES } from "../../Types";
import EditTablesModal from "./Components/EditTablesModal";
const { TabPane } = Tabs;
const {Title} = Typography;
const Tables = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useNavigate();
  const { rtl, isBigScreen } = useSelector((state) => state.settings);
  const { tables, zones } = useSelector((state) => state.table);
  const { role , waiter, services} = useSelector((state) => state.auth);
  const [canEditPosition, setCanEditPosition] = useState(false);
  const [showAddTable, setShowAddTable] = useState(false);
  const [showEditTables, setShowEditTables] = useState(false)
  const [tabHeight, setTabHeight] = useState("75vh");
  const [activeTab, setActiveTab] = useState("0");


  useEffect(() => {
    // set tab height;
    const zoneName = zones?.find((z) => z?.id === activeTab)?.name;
    const filterTables = tables?.filter((t) => t?.zone === zoneName);
    if (filterTables?.length > 0) {
      let larger = 0;
      let big = 0;
      let small = 0;
      filterTables.forEach((table) => {
        if (table?.numberOfChairs > 8) {
          larger++;
        } else if (table?.numberOfChairs > 4) {
          big++;
        } else {
          small++;
        }
      });
      let largerHeight = larger * 250;
      let biggerHeight = big * 200;
      let smallHeight = small * 160;
      let margin = (filterTables.length / 4) * 20;
      if (isBigScreen) {
        setTabHeight(
          (largerHeight + biggerHeight + smallHeight + margin) / 1.9 + "px"
        );
      } else {
        setTabHeight(
          (largerHeight + biggerHeight + smallHeight + margin) * 1.1 + "px"
        );
      }
    } else {
      setTabHeight("75vh");
    }
  }, [tables, activeTab]);

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      {/* Modals */}
      {/* Add new table */}
      <EditTableModal
        show={showAddTable}
        hide={() => setShowAddTable(false)}
        table={null}
      />
      {/* Edit  tables */}
      <EditTablesModal
        show={showEditTables}
        hide={() => setShowEditTables(false)}
      />
      {/* Edit position tables */}
      {isBigScreen && role === "waiter" ? (
        <div
          style={
            rtl
              ? {
                  position: "absolute",
                  top: 0,
                  right: 0,
                  zIndex: 2,
                  display: "flex",
                  alignItems: "center",
                }
              : {
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 2,
                  display: "flex",
                  alignItems: "center",
                }
          }
        >
          <CustomButton
            text={intl.formatMessage({
              id: canEditPosition ? "save" : "editPosition",
            })}
            type='light'
            className='border-8'
            onClick={() => setCanEditPosition(!canEditPosition)}
          />
          {canEditPosition ? (
            <IoReloadOutline
              onClick={() => dispatch(setDefaultPositionAction())}
              className='mx-2'
              style={{ fontSize: 20 }}
            />
          ) : null}
        </div>
      ) : role === "super" ? (
        <div
          style={
            rtl
              ? {
                  position: "absolute",
                  top: 0,
                  right: 0,
                  zIndex: 2,
                  display: "flex",
                  alignItems: "center",
                }
              : {
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 2,
                  display: "flex",
                  alignItems: "center",
                }
          }
        >
          <Title
            level={3}
            className='pointer'
            onClick={() => history(-1)}
            // style={{ position: "absolute", zIndex: 1 }}
          >
            {rtl ? (
              <IoArrowForwardOutline className='pointer' />
            ) : (
              <IoArrowBackOutline className='pointer' />
            )}
          </Title>
        </div>
      ) : null}
      {/* Add new tables; */}
      {isBigScreen ? (
        <div
          style={
            rtl
              ? {
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 2,
                  display: "flex",
                  alignItems: "center",
                }
              : {
                  position: "absolute",
                  top: 0,
                  right: 0,
                  zIndex: 2,
                  display: "flex",
                  alignItems: "center",
                }
          }
        >
          <CustomButton
            text={intl.formatMessage({
              id: "addTable",
            })}
            type='primary'
            className='border-8'
            onClick={() => setShowAddTable(true)}
            disabled={(!waiter?.canEditTables && role !== 'super' || services?.includes('ORDER_A') && tables?.length > 20
            )}
            />
            {role === 'super' ? 
          <CustomButton
            text={intl.formatMessage({
              id: "editTables",
            })}
            type='secondary'
            className='border-8 mx-2'
            style={{display: 'inline-flex', justifyContent: 'center', alignItems: 'center'}}
            onClick={() => setShowEditTables(true)}
            icon={<IoSettingsOutline size={14} className="mx-1" />}
            />
            : null}
        </div>
      ) : null}
      <Tabs
        centered
        activeKey={activeTab}
        onTabClick={(e) => setActiveTab(e)}
        type='card'
        style={{ minHeight: "75vh", paddingTop: isBigScreen ? 60 : 40 }}
      >
        {zones?.map((zone) => {
          const openTable = tables
            ?.filter((t) => t.zone === zone.name)
            ?.filter((t) => {
              return t.orders?.length || t?.needHelp || t?.needPayment;
            })?.length;
          return (
            <TabPane
              key={zone.id}
              style={{
                backgroundColor: canEditPosition ? "#DDDDDD" : COLORS.white,
                minHeight: "75vh",
                height: tabHeight,
              }}
              className='light-shadow'
              tab={
                <Badge
                  count={openTable}
                  style={{ backgroundColor: COLORS.primary }}
                >
                  <span className='px-3 my-font'>{zone?.name ? zone.name : 'General'}</span>
                </Badge>
              }
            >
              <Row
                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                type='flex'
                style={{ flexWrap: "wrap" }}
              >
                {tables?.length ? (
                  tables
                    .filter((t) => t.zone === zone.name)
                    .map((table, i) => {
                      return (
                        <TableCard
                          key={`${table?._id}-${i}`}
                          table={table}
                          index={i}
                          canEditPosition={canEditPosition}
                          setCanEditPosition={setCanEditPosition}
                        />
                      );
                    })
                ) : (
                  <div
                    style={{
                      width: "100%",
                      marginTop: "10vh",
                    }}
                  >
                    {" "}
                    <Empty
                      image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
                      imageStyle={{
                        height: 250,
                      }}
                      description={
                        <h2 className='my-font'>
                          {intl.formatMessage({ id: "noTableOnBranch" })}
                        </h2>
                      }
                    ></Empty>
                  </div>
                )}
              </Row>
            </TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};

export default Tables;
