import React, { useState, useEffect } from "react";
import { Card, Space, Typography, Table, Tag, Spin, Popconfirm } from "antd";
import { useIntl } from "react-intl";
import {
  DELIVERY_ORDERS_STATUS,
  DINE_IN_ORDERS_STATUS,
  FREE_OFFER_TYPES,
  IWAITER_APP_ORDERS_STATUS,
  ORDER_TYPES,
  PICKUP_ORDERS_STATUS,
} from "../../Types";
import { getDiscountAmount } from "../../helpers";
import DineInHeader from "./Components/DineInHeader";
import { useDispatch, useSelector } from "react-redux";
import { getPromotion } from "../../helpers/promotions";
import { formatModifiers } from "../../helpers/modifiers";
import { calculateSubTotal } from "../../helpers/total";
import {
  postUpdateOrder,
  postUpdatePickupOrder,
  refundPaymentOrder,
  sendOrderWhatsapp,
} from "../../API/fetch";
import { editTableAction } from "../../redux/reducers/tableReducer";
import openNotification from "../Notifications";
import {
  deleteOrderAction,
  setOrderByIdAction,
  updateOrdersAction,
} from "../../redux/reducers/orderReducer";
import DineInTag from "./Components/DineInTag";
import DeliveryTag from "./Components/DeliveryTag";
import PickupTag from "./Components/PickupTag";
import COLORS from "../../Style/colors";
import DeliveryHeader from "./Components/DeliveryHeader";
import IWaiterAppHeader from "./Components/IWaiterAppHeader";
import IwaiterAppTag from "./Components/IwaiterAppTag";
import { IoNotificationsOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;

const OrderCard = ({ order, type = "inHouse", getProductById }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { rtl } = useSelector((state) => state.settings);
  const { role } = useSelector((state) => state.auth);
  const { branch } = useSelector((state) => state.branch);
  const [finalTotal, setFinalTotal] = useState(0);
  const [orderNumber, setOrderNumber] = useState("");
  const [discountAmount, setDiscountAmount] = useState(0);
  const [ordersDetails, setOrdersDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [canSendWhatsapp, setCanSendWhatsapp] = useState(false);

  useEffect(() => {
    if (
      role !== "super" &&
      order?.phone &&
      order?.name &&
      order?.phone?.length > 3 &&
      branch?.sendWhatsappData?.status === "Active" &&
      branch?.branchDialCode &&
      branch?.branchPhone &&
      order?.status !== "close"
    ) {
      setCanSendWhatsapp(true);
    }
    return () => setCanSendWhatsapp(false);
  }, [role, order, branch]);

  useEffect(() => {
    // set Order number;
    let orderNumber = order?.orderNumber;
    if (order?.type === ORDER_TYPES.DELIVERY) {
      orderNumber = `# D ${orderNumber}`;
    } else if (order?.type === ORDER_TYPES.DINE_IN) {
      orderNumber = `# H ${orderNumber}`;
    } else if (order?.type === ORDER_TYPES.PICKUP) {
      orderNumber = `# P ${orderNumber}`;
    } else if (order?.type === ORDER_TYPES.IWAITER_APP) {
      orderNumber = `# A ${orderNumber}`;
    }
    setOrderNumber(orderNumber);

    // ser discounts;
    let finalTotal = order?.total;
    let discount = 0;
    if (order?.specialOfferDiscount) {
      discount += order?.specialOfferDiscount;
    }
    if (order?.couponCode && order?.couponCode?._id) {
      discount += getDiscountAmount({
        total: order.total,
        percentage: order.couponCode.percentage,
        maximumAmount: order.couponCode.maximumAmount,
      });
      setDiscountAmount(discount);
      finalTotal -= discount;
    } else {
      setDiscountAmount(discount);
      finalTotal -= discount;
    }

    // set delivery fee;
    if (order?.type === ORDER_TYPES.DELIVERY) {
      finalTotal += order.deliveryInfo?.deliveryFees;
    }

    setFinalTotal(finalTotal.toFixed(2));

    setOrdersStructures();
    // check if the order is iwaiterApp and the createdAt time is bigger than 5 minutes;
    if (
      order?.type !== ORDER_TYPES.DINE_IN &&
      order?.createdAt &&
      new Date().getTime() - new Date(order.createdAt).getTime() > 300000 &&
      order?.status === IWAITER_APP_ORDERS_STATUS.PENDING
    ) {
      // send a request to update the order status;
      makeOrderExpired();
    }
  }, [order]);

  // set orders details;
  const setOrdersStructures = () => {
    const ordersDetails = [];
    if (order?.orders?.length) {
      // set modifiers format;
      order.orders.forEach((o, index) => {
        let extraPrice = 0;
        let modifiers = [];
        // let subTotal = 0;
        if (o?.modifierIds?.length) {
          const { modifiersResult, addedPrice } = formatModifiers({
            modifierIds: o.modifierIds,
            rtl,
            note: o.note,
            noteTitle: intl.formatMessage({ id: "notes" }),
          });
          modifiers = modifiersResult;
          extraPrice = addedPrice;
        } else if (o?.note) {
          modifiers.push({
            groupName: intl.formatMessage({ id: "notes" }),
            selected: [o.note],
          });
        }
        // check for promotion and special offer
        let promotion = "-";
        let promotionAmount = 0;
        let mark = "";
        let markSpecial = "";
        let specialPromotion = "";
        let specialPromotionAmount = 0;
        let specialPromotionType = "";
        let withPromotion;
        let withSpecialPromotion;

        if (o?.promotion?._id || o?.specialOffer?._id) {
          const {
            promotionText,
            finalMark,
            amount,
            specialPromotionText,
            amountSpecial,
            finalSpecialMark,
            hasPromotion,
            hasSpecialPromotion,
            specialType,
          } = getPromotion({
            promotion: o?.promotion,
            specialPromotion: o?.specialOffer,
            foodPrice: o?.foodId?.price,
          });
          //promotion
          mark = finalMark;
          promotionAmount = amount;
          promotion = promotionText;
          withPromotion = hasPromotion;
          //special offer
          markSpecial = finalSpecialMark;
          specialPromotionAmount = amountSpecial;
          specialPromotion = specialPromotionText;
          withSpecialPromotion = hasSpecialPromotion;
          specialPromotionType = specialType;
        }

        // check if there is a coupon for items
        let isWithCoupon = false;
        let couponPercentage;
        if (o?.appliedCoupon && o?.appliedCoupon?._id) {
          isWithCoupon = true;
          couponPercentage = o?.appliedCoupon?.percentage;
        }

        // format item;
        let item = {
          name: o?.foodId?.name_en
            ? rtl
              ? o?.foodId?.name
              : o?.foodId?.name_en
            : o?.foodId?.name,
          code: "",
          modifiers: modifiers,
        };
        if (o?.foodId?.code) {
          item.code = o.foodId.code;
        }
        // calculate total;
        let total = calculateSubTotal({
          mark,
          foodPrice: o?.foodId?.price,
          extraPrice:
            specialPromotionType === FREE_OFFER_TYPES.FREE_ITEMS
              ? 0
              : extraPrice,
          specialPromotionAmount: specialPromotionAmount,
          promotionAmount: promotionAmount,
          quantity: o.qty,
          isWithCoupon,
          couponPercentage,
        });

        ordersDetails.push({
          key: `${o._id}-${index}`,
          quantity: o.qty,
          item,
          promotion: {
            promotion,
            specialOffer: specialPromotion,
            withPromotion,
            withSpecialPromotion,
          },
          appliedCoupon: isWithCoupon
            ? `${intl.formatMessage({
                id: "justCouponCode",
              })} ${couponPercentage}% 
          (${(
            ((total / (1 - couponPercentage / 100)) * couponPercentage) /
            100
          )?.toFixed(2)})`
            : "",
          unitPrice: o?.foodId?.price?.toFixed(2),
          addedChoices: extraPrice?.toFixed(2),
          total: total?.toFixed(2),
          createdAt: new Date(order?.createdAt).toLocaleTimeString(),
        });
      });
    }
    setOrdersDetails(ordersDetails);
  };

  const columns = [
    {
      title: intl.formatMessage({ id: "quantity" }),
      dataIndex: "quantity",
      key: "_id",
      render: (text) => <p className='my-font'>{text}</p>,
    },
    {
      title: intl.formatMessage({ id: "item" }),
      dataIndex: "item",
      key: "item",
      render: ({ name, code, modifiers, code: specialCode }) => {
        return (
          <Space direction='vertical'>
            <Text>
              {name}{" "}
              {specialCode ? (
                <Tag
                  style={{
                    minWidth: 40,
                    textAlign: "center",
                    borderRadius: 5,
                  }}
                  color='darkBlue'
                >
                  {specialCode}
                </Tag>
              ) : null}
            </Text>
            {modifiers?.length ? (
              <Space direction='vertical'>
                {modifiers.map(({ groupName, selected }) => {
                  return (
                    <Space direction='horizontal' size='small' key={groupName}>
                      <Text>{groupName}: </Text>
                      {selected.map((s, i) => (
                        <Tag key={`${s}-${i}`}>{s}</Tag>
                      ))}
                    </Space>
                  );
                })}
              </Space>
            ) : null}
          </Space>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "unitPrice" }),
      dataIndex: "unitPrice",
      key: "unitPrice",
    },
    {
      title: intl.formatMessage({ id: "addedChoices" }),
      key: "addedChoices",
      dataIndex: "addedChoices",
    },
    {
      title: intl.formatMessage({ id: "total" }),
      key: "total",
      dataIndex: "total",
    },
    {
      title: intl.formatMessage({ id: "sent" }),
      key: "createdAt",
      dataIndex: "createdAt",
    },
  ];
  // if there is promotion add column;
  if (order?.orders?.some((o) => o?.promotion?._id || o?.specialOffer?._id)) {
    const newItemColumn = {
      title: intl.formatMessage({ id: "promotion" }),
      key: "promotion",
      dataIndex: "promotion",
      render: ({
        promotion,
        specialOffer,
        withPromotion,
        withSpecialPromotion,
      }) => {
        return (
          <Space direction='vertical'>
            {withPromotion && <Text>{promotion}</Text>}
            {withSpecialPromotion && <Text>{specialOffer}</Text>}
          </Space>
        );
      },
    };
    columns.splice(4, 0, newItemColumn);
  }

  //if there is coupon add column
  if (order?.orders?.some((o) => o?.appliedCoupon?._id)) {
    const newItemColumn = {
      title: intl.formatMessage({ id: "coupon" }),
      key: "appliedCoupon",
      dataIndex: "appliedCoupon",
      render: (record) => {
        return (
          <Space direction='vertical'>
            <Text>{record}</Text>
          </Space>
        );
      },
    };
    columns.splice(5, 0, newItemColumn);
  }

  const changeStatus = async (oldStatus, isReject = false) => {
    try {
      setLoading(true);
      let status =
        oldStatus === DINE_IN_ORDERS_STATUS.OPEN
          ? DINE_IN_ORDERS_STATUS.IN_SERVER
          : oldStatus === DINE_IN_ORDERS_STATUS.IN_SERVER
          ? DINE_IN_ORDERS_STATUS.SERVED
          : oldStatus === DINE_IN_ORDERS_STATUS.SERVED
          ? DINE_IN_ORDERS_STATUS.CLOSE
          : oldStatus === DINE_IN_ORDERS_STATUS.CLOSE
          ? DINE_IN_ORDERS_STATUS.OPEN
          : oldStatus;
      if (type === ORDER_TYPES.DELIVERY) {
        status =
          oldStatus === DELIVERY_ORDERS_STATUS.PENDING
            ? isReject
              ? DELIVERY_ORDERS_STATUS.REJECTED
              : DELIVERY_ORDERS_STATUS.OPEN
            : oldStatus === DELIVERY_ORDERS_STATUS.OPEN
            ? DELIVERY_ORDERS_STATUS.IN_KITCHEN
            : oldStatus === DELIVERY_ORDERS_STATUS.IN_KITCHEN
            ? DELIVERY_ORDERS_STATUS.READY
            : oldStatus === DELIVERY_ORDERS_STATUS.READY
            ? DELIVERY_ORDERS_STATUS.IN_DELIVERY
            : oldStatus === DELIVERY_ORDERS_STATUS.IN_DELIVERY
            ? DELIVERY_ORDERS_STATUS.CLOSE
            : oldStatus === DELIVERY_ORDERS_STATUS.CLOSE
            ? DELIVERY_ORDERS_STATUS.OPEN
            : oldStatus;
      } else if (type === ORDER_TYPES.PICKUP) {
        status =
          oldStatus === PICKUP_ORDERS_STATUS.PENDING
            ? isReject
              ? PICKUP_ORDERS_STATUS.REJECTED
              : PICKUP_ORDERS_STATUS.OPEN
            : oldStatus === PICKUP_ORDERS_STATUS.OPEN
            ? PICKUP_ORDERS_STATUS.IN_KITCHEN
            : oldStatus === PICKUP_ORDERS_STATUS.IN_KITCHEN
            ? PICKUP_ORDERS_STATUS.READY
            : oldStatus === PICKUP_ORDERS_STATUS.READY
            ? PICKUP_ORDERS_STATUS.CLOSE
            : oldStatus === PICKUP_ORDERS_STATUS.CLOSE
            ? PICKUP_ORDERS_STATUS.OPEN
            : oldStatus;
      } else if (type === ORDER_TYPES.IWAITER_APP) {
        // update order status;
        status =
          oldStatus === IWAITER_APP_ORDERS_STATUS.PENDING
            ? isReject
              ? IWAITER_APP_ORDERS_STATUS.REJECTED
              : IWAITER_APP_ORDERS_STATUS.OPEN
            : oldStatus === IWAITER_APP_ORDERS_STATUS.OPEN
            ? IWAITER_APP_ORDERS_STATUS.IN_KITCHEN
            : oldStatus === IWAITER_APP_ORDERS_STATUS.IN_KITCHEN
            ? IWAITER_APP_ORDERS_STATUS.READY
            : oldStatus === IWAITER_APP_ORDERS_STATUS.READY
            ? IWAITER_APP_ORDERS_STATUS.CLOSE
            : oldStatus === IWAITER_APP_ORDERS_STATUS.CLOSE
            ? IWAITER_APP_ORDERS_STATUS.OPEN
            : oldStatus;
      }

      const data = {
        order: {
          status,
        },
        type: ORDER_TYPES.DINE_IN,
      };
      if (type === ORDER_TYPES.DINE_IN) {
        const res = await postUpdateOrder(order?._id, data);
        dispatch(editTableAction(res.data.table));
        dispatch(setOrderByIdAction(res.data.order));
        dispatch(
          updateOrdersAction({
            type: ORDER_TYPES.DINE_IN,
            payload: res.data.order,
          })
        );
      } else if (type === ORDER_TYPES.PICKUP) {
        // Pickup order status;
        data.type = ORDER_TYPES.PICKUP;
        const res = await postUpdatePickupOrder(order?._id, data);
        dispatch(setOrderByIdAction(res.data.order));
        dispatch(
          updateOrdersAction({
            type: ORDER_TYPES.PICKUP,
            payload: res.data.order,
          })
        );
      } else if (type === ORDER_TYPES.DELIVERY) {
        data.type = ORDER_TYPES.DELIVERY;
        const res = await postUpdatePickupOrder(order?._id, data);
        dispatch(setOrderByIdAction(res.data.order));
        dispatch(
          updateOrdersAction({
            type: ORDER_TYPES.DELIVERY,
            payload: res.data.order,
          })
        );
      } else if (type === ORDER_TYPES.IWAITER_APP) {
        data.type = ORDER_TYPES.IWAITER_APP;
        const res = await postUpdatePickupOrder(order?._id, data);
        dispatch(setOrderByIdAction(res.data.order));
        dispatch(
          updateOrdersAction({
            type: ORDER_TYPES.IWAITER_APP,
            payload: res.data.order,
          })
        );
      }

      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.newStatus === "timeout") {
        getProductById(order?._id);
      }

      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const makeOrderExpired = async () => {
    try {
      setLoading(true);
      const data = {
        order: {
          status: "timeout",
        },
        type: order?.type,
      };
      const res = await postUpdatePickupOrder(order?._id, data);
      dispatch(
        updateOrdersAction({
          type: order?.type,
          payload: res.data.order,
        })
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const deleteOrder = async () => {
    try {
      setLoading(true);
      const data = {
        order: {
          status: "deleted",
        },
      };
      if (type === ORDER_TYPES.DINE_IN) {
        const res = await postUpdateOrder(order?._id, data);
        dispatch(
          deleteOrderAction({
            type: ORDER_TYPES.DINE_IN,
            payload: order._id,
          })
        );
        navigate("/orders");
      } else if (type === ORDER_TYPES.PICKUP) {
        // Pickup order status;
        const res = await postUpdatePickupOrder(order?._id, data);
        dispatch(
          deleteOrderAction({
            type: ORDER_TYPES.PICKUP,
            payload: order._id,
          })
        );
        navigate("/pickup");
      } else if (type === ORDER_TYPES.DELIVERY) {
        const res = await postUpdatePickupOrder(order?._id, data);
        dispatch(
          deleteOrderAction({
            type: ORDER_TYPES.DELIVERY,
            payload: order._id,
          })
        );
        navigate("/delivery");
      } else if (type === ORDER_TYPES.IWAITER_APP) {
        const res = await postUpdatePickupOrder(order?._id, data);
        dispatch(
          deleteOrderAction({
            type: ORDER_TYPES.IWAITER_APP,
            payload: order._id,
          })
        );
        navigate("/iwaiterApp");
      }

      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const handleSendWhatsappOrder = async () => {
    try {
      setLoading(true);

      const data = {
        order,
      };
      const res = await sendOrderWhatsapp(data);

      if (res.data.status === "success") {
        openNotification({
          title: (
            <Text className='my-font'>
              {intl.formatMessage({ id: "sentNotificationSuccessfully" })}
            </Text>
          ),
          type: "success",
          rtl,
        });
      } else {
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "errorfetch" }),
          type: "error",
          rtl,
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };
  const refundPayment = async () => {
    try {
      setLoading(true);
      const data = {
        orderId: order?._id,
        orderType: type,
      };
      const res = await refundPaymentOrder(data);
      if (res?.data?.refund) {
        // update order with refund payment id;
        let updatedOrder = {
          ...order,
          refundPaymentId: res.data.refund,
        };
        if (type === ORDER_TYPES.DINE_IN) {
          dispatch(
            updateOrdersAction({
              type: ORDER_TYPES.DINE_IN,
              payload: updatedOrder,
            })
          );
        } else if (type === ORDER_TYPES.PICKUP) {
          dispatch(
            updateOrdersAction({
              type: ORDER_TYPES.PICKUP,
              payload: updatedOrder,
            })
          );
        } else if (type === ORDER_TYPES.DELIVERY) {
          dispatch(
            updateOrdersAction({
              type: ORDER_TYPES.DELIVERY,
              payload: updatedOrder,
            })
          );
        } else if (type === ORDER_TYPES.IWAITER_APP) {
          dispatch(
            updateOrdersAction({
              type: ORDER_TYPES.IWAITER_APP,
              payload: updatedOrder,
            })
          );
        }
      }
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      let message = intl.formatMessage({ id: "errorfetch" });
      if (error?.response?.data?.error) {
        message = error.response.data.error;
      }
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: message,
        type: "error",
        rtl,
      });
    }
  };

  return (
    <Card
      title={
        type === ORDER_TYPES.DINE_IN || type === ORDER_TYPES.PICKUP ? (
          <DineInHeader
            orderNumber={orderNumber}
            status={order?.status}
            tip={order?.tipId?.paymentStatus === 'paid' ? order?.tipId?.amount  : 0}
            createdAt={order?.createdAt}
            total={order?.total}
            discountAmount={discountAmount}
            finalTotal={finalTotal}
            paymentStatus={order?.paymentStatus}
            orderId={order?._id}
            userPhone={order?.phone}
            userName={order?.name}
            orderType={type}
            posData={order?.posData}
            deliverToCar={order?.deliverToCar}
            refundPayment={refundPayment}
            refundPaymentInfo={order?.refundPaymentId}
            role={role}
            tableFees={order?.tableFees}
            reservationNumber={
              order?.reservation?._id
                ? order?.reservation?._id?.slice(-5).toUpperCase()
                : null
            }
            walletAmount={order?.walletAmount}
          />
        ) : type === ORDER_TYPES.DELIVERY ? (
          <DeliveryHeader
            orderNumber={orderNumber}
            status={order?.status}
            createdAt={order?.createdAt}
            total={order?.total}
            discountAmount={discountAmount}
            finalTotal={finalTotal}
            paymentStatus={order?.paymentStatus}
            orderId={order?._id}
            userPhone={order?.phone}
            userName={order?.name}
            orderType={type}
            deliveryFees={order?.deliveryInfo?.deliveryFees}
            location={{
              lat: order?.deliveryInfo?.lat,
              lng: order?.deliveryInfo?.lng,
            }}
            addressName={order?.deliveryInfo?.addressName}
            address={order?.deliveryInfo?.address}
            posData={order?.posData}
            refundPayment={refundPayment}
            refundPaymentInfo={order?.refundPaymentId}
            role={role}
            deliveryId={order?.deliveryId}
            walletAmount={order?.walletAmount}
          />
        ) : type === ORDER_TYPES.IWAITER_APP ? (
          <IWaiterAppHeader
            orderNumber={orderNumber}
            status={order?.status}
            createdAt={order?.createdAt}
            total={order?.total}
            discountAmount={discountAmount}
            finalTotal={finalTotal}
            paymentStatus={order?.paymentStatus}
            orderId={order?._id}
            userPhone={order?.phone}
            userName={order?.name}
            orderType={type}
            posData={order?.posData}
            deliverToCar={order?.deliverToCar}
            refundPayment={refundPayment}
            refundPaymentInfo={order?.refundPaymentId}
            role={role}
            walletAmount={order?.walletAmount}
          />
        ) : null
      }
      bordered={false}
      style={{ width: "100%", borderRadius: 10, marginBottom: 10 }}
      actions={[
        <Spin spinning={loading}>
          {type === ORDER_TYPES.DINE_IN ? (
            <DineInTag status={order?.status} changeStatus={changeStatus} />
          ) : type === ORDER_TYPES.PICKUP &&
            order?.status !== PICKUP_ORDERS_STATUS.TIMEOUT &&
            order?.status !== PICKUP_ORDERS_STATUS.REJECTED ? (
            <PickupTag status={order?.status} changeStatus={changeStatus} />
          ) : type === ORDER_TYPES.DELIVERY &&
            order?.status !== DELIVERY_ORDERS_STATUS.TIMEOUT &&
            order?.status !== DELIVERY_ORDERS_STATUS.REJECTED ? (
            <DeliveryTag status={order?.status} changeStatus={changeStatus} />
          ) : type === ORDER_TYPES.IWAITER_APP &&
            order?.status !== IWAITER_APP_ORDERS_STATUS.TIMEOUT &&
            order?.status !== IWAITER_APP_ORDERS_STATUS.REJECTED ? (
            <IwaiterAppTag status={order?.status} changeStatus={changeStatus} />
          ) : null}
          {role === "super" ? (
            <Popconfirm
              title={
                <Text className='my-font'>
                  {intl.formatMessage({ id: "confirmDelete" })}
                </Text>
              }
              okText={intl.formatMessage({ id: "delete" })}
              okButtonProps={{
                style: { color: COLORS.danger, fontFamily: "Cairo" },
              }}
              okType='text'
              cancelText={intl.formatMessage({ id: "cancel" })}
              cancelButtonProps={{
                style: { border: "none", fontFamily: "Cairo" },
              }}
              onConfirm={deleteOrder}
              disabled={role !== "super"}
            >
              <Text
                disabled={role !== "super"}
                type='danger'
                className='pointer my-font'
              >
                {intl.formatMessage({ id: "delete" })}
              </Text>
            </Popconfirm>
          ) : null}
          {canSendWhatsapp &&
          order?.status !== IWAITER_APP_ORDERS_STATUS.TIMEOUT &&
          order?.status !== IWAITER_APP_ORDERS_STATUS.REJECTED &&
          order?.status !== IWAITER_APP_ORDERS_STATUS.PENDING ? (
            <Popconfirm
              title={
                <Text className='my-font'>
                  {intl.formatMessage({ id: "areYouSureSendWhatsappOrder" })}
                </Text>
              }
              okText={
                <Tag
                  color={COLORS.primary}
                  style={{ paddingTop: 2, paddingBottom: 2 }}
                  className='pointer my-font border-5 '
                >
                  {intl.formatMessage({ id: "yesSend" })}
                </Tag>
              }
              okButtonProps={{
                style: { color: COLORS.danger, fontFamily: "Cairo" },
              }}
              okType='text'
              cancelText={intl.formatMessage({ id: "cancel" })}
              cancelButtonProps={{
                style: { border: "none", fontFamily: "Cairo" },
              }}
              onConfirm={handleSendWhatsappOrder}
              disabled={loading}
              loading={loading}
            >
              <Tag
                color={COLORS.primary}
                style={{
                  paddingTop: 5,

                  paddingBottom: 5,

                  paddingRight: 15,

                  paddingLeft: 15,
                }}
                className='pointer my-font border-5 '
              >
                {intl.formatMessage({ id: "send" })}
                <IoNotificationsOutline
                  size={15}
                  style={{
                    marginRight: rtl ? 5 : 0,
                    marginLeft: rtl ? 0 : 5,
                    position: "relative",
                    top: 4,
                  }}
                />
              </Tag>
            </Popconfirm>
          ) : null}
        </Spin>,
      ]}
    >
      {order?.orderNote?.length > 0 ? (
        <div className='mb-3'>
          <Tag
            style={{
              fontSize: 14,
              padding: "5px 10px",
              borderRadius: 8,
            }}
            className='my-font'
          >
            {intl.formatMessage({ id: "orderNote" })}:{" "}
            <Text
              style={{
                fontWeight: "bold",
              }}
            >
              {order?.orderNote}
            </Text>
          </Tag>
        </div>
      ) : null}
      <Table
        scroll={{ x: 500 }}
        pagination={{ hideOnSinglePage: true }}
        columns={columns}
        dataSource={ordersDetails}
      />
    </Card>
  );
};

export default OrderCard;
