import React, { useState, useEffect } from "react";
import {
  Form,
  Space,
  Divider,
  Modal,
  Typography,
  Row,
  Select,
  Input,
  Radio,
  Col,
  Steps,
  InputNumber,
  Tag,
} from "antd";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../Components/CustomButton";
import openNotification from "../../../Components/Notifications";
import { createDeliveryLocation, getAddressText } from "../../../API/fetch";
import phoneCodes from "../../Auth/PhoneCods.json";
import { checkIfValidPhone } from "../../../helpers";
import MapModal from "./Components/MapModal";
import citiesKSA from "../../Onboarding/Components/cities.json";
import { setIntegrationsAction } from "../../../redux/reducers/restaurantReducer";
import { COVERED_CITIES } from "../../../Types";

const { Option } = Select;
const { Title, Text } = Typography;
const { Step } = Steps;

const DeliveryModal = ({ show, hide, deliveryCompanies }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { rtl } = useSelector((state) => state.settings);
  const { allBranches } = useSelector((state) => state.branch);
  const { waiter, user } = useSelector((state) => state.auth);
  const { restaurant } = useSelector((state) => state.restaurant);
  const [loading, setLoading] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(
    allBranches?.[0]?.branch
  );
  const [center, setCenter] = useState({
    lat:
      allBranches?.[0]?.branch?.location &&
      allBranches?.[0]?.branch?.location.lat
        ? allBranches?.[0]?.branch?.location.lat
        : 24.761654,
    lng:
      allBranches?.[0]?.branch?.location &&
      allBranches?.[0]?.branch?.location.lng
        ? allBranches?.[0]?.branch?.location.lng
        : 46.657367,
  });
  const [deliveryInfo, setDeliveryInfo] = useState(null);

  // useEffect(() => {
  //   if (show) {
  //     // setTimeout(() => {
  //     //   form.resetFields();
  //     // }, 400);
  //   }
  // }, [show]);
  useEffect(() => {
    if (selectedBranch?._id) {
      // get delivery info
      const found = deliveryCompanies?.find(
        (item) => item?.name === "iwaiter Delivery"
      );
      if (found) {
        const deliveryInfo = found?.linkedBranches?.find(
          (item) => item?.localId === selectedBranch?._id
        );
        setDeliveryInfo(deliveryInfo);
        if (deliveryInfo) {
          form.setFieldsValue({
            cityName: deliveryInfo?.city,
            contactEmail: deliveryInfo?.contactEmail,
            contactName: deliveryInfo?.contactName,
            mobile: deliveryInfo?.mobile.slice(4),
            prefix: deliveryInfo?.mobile.slice(0, 4),
            minimumTicketSize: selectedBranch?.minimumOrder,
            deliveryCoverage: selectedBranch?.deliveryCoverage,
            servingRadius: deliveryInfo?.servingRadius,
          });
        } else {
          form.resetFields();
        }
      }
    }
  }, [selectedBranch, deliveryCompanies, show]);
  const onFinish = async (values) => {
    try {
      setLoading(true);
      // update local branch location;
      if (!center?.lat || !center?.lng) {
        openNotification({
          title: "error",
          description: "please select location",
          type: "error",
          rtl: true,
        });
        return;
      }
      const cords = [center.lng, center.lat];
      const localData = {
        branch: {
          location: {
            type: "Point",
            coordinates: cords,
          },
          lat: center.lat,
          lng: center.lng,
          cityName: values.cityName,
          minimumOrder: values.minimumTicketSize,
          deliveryCoverage: values.deliveryCoverage,
          servingRadius: values.servingRadius,
        },
      };

      // get address text;
      let address = "";
      let postcode = "";
      const resAddress = await getAddressText(center.lat, center.lng);
      if (resAddress?.data?.results?.length > 0) {
        address = resAddress?.data?.results?.[0]?.formatted_address;
        if (address) {
          const res = address.match(/\d{5}/);
          if (res) {
            postcode = res[0];
          }
        }
      }

      const data = {
        deliveryCompanyData: {
          name: selectedBranch?.name_en,
          type: "branch",
          code: selectedBranch?._id,
          mobile: `${values.prefix.slice(0)}${values.mobile}`,
          contactName: values.contactName,
          contactEmail: values.contactEmail,
          lat: center?.lat,
          lon: center?.lng,
          city: values?.cityName,
          servingRadius: values?.servingRadius,
          brandName: restaurant?.name_en,
          postcode: postcode,
          address: address,
        },
        branchId: selectedBranch?._id,
        restaurantId: restaurant?._id,
        companyName: "iwaiter Delivery",
        localData,
        isUpdate: deliveryInfo?.otherId ? true : false,
        otherId: deliveryInfo?.otherId ? deliveryInfo?.otherId : "",
      };

      const res = await createDeliveryLocation(data);
      if (res?.data?.status === "success") {
        dispatch(setIntegrationsAction(res.data.integration));
        openNotification({
          title: intl.formatMessage({ id: "successfulOrder" }),
          description: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl: rtl,
        });
        setDeliveryInfo(null);
        hide();
      } else {
        openNotification({
          title: "error",
          description: "something went wrong",
          type: "error",
          rtl: rtl,
        });
      }
      setLoading(false);
      // hide();
    } catch (error) {
      console.log("error", error);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
      setLoading(false);
    }
  };
  const prefixSelector = (
    <Form.Item name='prefix' noStyle>
      <Select
        style={{
          width: 100,
        }}
      >
        {phoneCodes.map((info) => {
          return (
            <Option
              key={`${info.dial_code}-${info.name}`}
              value={info.dial_code}
            >
              {info.dial_code} {info.name}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );
  return (
    <Modal
      title={
        <Title level={4} className='my-font'>
          {intl.formatMessage({ id: "iwaiterDelivery" })}
        </Title>
      }
      centered
      visible={show}
      destroyOnClose={true}
      mask={true}
      maskClosable={true}
      onCancel={() => {
        setDeliveryInfo(null);
        hide();
      }}
      footer={null}
    >
      {/* <div className='mb-3'>
        <Steps current={3}>
          <Step title={intl.formatMessage({ id: "start" })} />
          <Step title={intl.formatMessage({ id: "chooseBranch" })} />
          <Step title={intl.formatMessage({ id: "chooseBranch" })} />
        </Steps>
      </div> */}

      <Form
        name='addDelivery'
        form={form}
        preserve={false}
        className='form-container'
        layout='vertical'
        onFinish={onFinish}
        initialValues={{
          branchId: selectedBranch?._id,
          brandName: restaurant?.name_en,
          mobile: user?.phone?.slice(3),
          prefix: `+${user?.phone?.slice(0, 3)}`,
          contactName: user?.name,
          contactEmail: restaurant?.email,
          cityName: selectedBranch?.cityName,
          servingRadius: 10,
          deliveryCoverage: 0,
          minimumTicketSize: 0,
        }}
      >
        {() => {
          return (
            <>
              <Row
                style={{
                  flexDirection: "column",
                }}
              >
                <Title level={5}>
                  {intl.formatMessage({ id: "deliveryInfo" })}
                </Title>
                <Text className='my-font'>
                  {intl.formatMessage({ id: "deliveryInfoText" })}
                </Text>
              </Row>
              <Row
                style={{
                  flexDirection: "column",
                }}
              >
                <Title level={5}>
                  {intl.formatMessage({ id: "availableCities" })}:
                </Title>
                <Text className='my-font' type='danger'>
                  {intl.formatMessage({ id: "availableCitiesDes" })}
                </Text>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginTop: 2,
                  }}
                >
                  {citiesKSA
                    ?.filter((c) => COVERED_CITIES.includes(c.name_en))
                    .map((city) => {
                      return (
                        <Tag
                          style={{
                            marginRight: 2,
                            borderRadius: 5,
                            marginTop: 5,
                          }}
                        >
                          {rtl ? city.name_ar : city.name_en}
                        </Tag>
                      );
                    })}
                </div>
              </Row>
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "chooseBranch" })}
                    className='mb-1'
                    name='branchId'
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "required" }),
                      },
                    ]}
                  >
                    <Select
                      onChange={(value) => {
                        if (value !== selectedBranch?._id) {
                          const newSelected = allBranches?.find(
                            (branch) => branch.branch._id === value
                          )?.branch;
                          if (newSelected) {
                            setSelectedBranch(newSelected);

                            // form.setFieldsValue({
                            //   cityName: newSelected?.cityName,
                            //   mobile: user?.phone?.slice(3),
                            //   prefix: `+${user?.phone?.slice(0, 3)}`,
                            //   contactName: user?.name,
                            //   contactEmail: restaurant?.email,
                            //   deliveryCoverage: 0,
                            //   minimumTicketSize: 0,
                            // });
                            if (newSelected?.location?.coordinates?.length) {
                              setCenter({
                                lat: newSelected?.location?.coordinates[1],
                                lng: newSelected?.location?.coordinates[0],
                              });
                            }
                          }
                        }
                      }}
                      className='my-font'
                    >
                      {allBranches?.map(({ branch }) => (
                        <Option value={branch?._id} key={branch?._id}>
                          {rtl ? branch?.name : branch?.name_en}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "nameEn" })}
                    className='mb-1'
                    name='brandName'
                  >
                    <Input className='input' disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "contactName" })}
                    className='mb-1'
                    name='contactName'
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "required" }),
                      },
                    ]}
                  >
                    <Input className='input' />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    name='mobile'
                    label={intl.formatMessage({ id: "contactPhone" })}
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "errorphone" }),
                      },
                      {
                        validator: (_, value) => {
                          const prefix = form.getFieldValue("prefix");
                          if (!prefix) {
                            return Promise.reject(
                              intl.formatMessage({ id: "errorphone" })
                            );
                          }
                          let valid = checkIfValidPhone(`${prefix}${value}`);
                          if (valid) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(
                              intl.formatMessage({ id: "errorphone" })
                            );
                          }
                        },
                      },
                    ]}
                  >
                    <Input
                      addonBefore={rtl ? null : prefixSelector}
                      addonAfter={rtl ? prefixSelector : null}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "contactEmail" })}
                    className='mb-1'
                    name='contactEmail'
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "required" }),
                      },
                      {
                        type: "email",
                        message: intl.formatMessage({ id: "erroremail" }),
                      },
                    ]}
                  >
                    <Input className='input' />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "cityName" })}
                    className='mb-1'
                    name='cityName'
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "required" }),
                      },
                    ]}
                  >
                    <Select
                      style={{
                        width: 180,
                        marginBottom: 10,
                      }}
                      showSearch
                      filterOption={(input, option) =>
                        (option?.value ?? "").includes(input)
                      }
                      required
                    >
                      {citiesKSA
                        .filter((c) => COVERED_CITIES.includes(c.name_en))
                        .map((info) => {
                          return (
                            <Option
                              key={info?.city_id}
                              value={`${info?.name_en} - ${info?.name_ar}`}
                            >
                              {info.name_en} - {info.name_ar}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                  {form?.getFieldValue('cityName') === 'Riyadh - الرياض' ? 
                    <Text type="danger" className="my-font">
                      {intl.formatMessage({ id: "riyadhWarning" })}
                    </Text>
                  : null}
                </Col>
              </Row>
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "deliveryCoverage" })}
                    className='mb-1'
                    name='deliveryCoverage'
                    
                    tooltip={
                      <Text className='my-font'>
                        {intl.formatMessage({ id: "deliveryCoverageExplain" })}
                      </Text>
                    }
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "required" }),
                      },
                    ]}
                  >
                    <InputNumber
                    onChange={(value) => {
                      if (value <= 5) {
                        form.setFieldsValue({
                          minimumTicketSize: 0,
                        });
                      }
                    }}
                      min={0}
                      max={18}
                      step={0.5}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "minimumTicketSize" })}
                    className='mb-1'
                    name='minimumTicketSize'
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "required" }),
                      },
                    ]}
                  >
                    <InputNumber
                      disabled={form?.getFieldValue("deliveryCoverage") <= 5}
                      min={0}
                      max={22}
                      step={0.5}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "servingRadius" })}
                    className='mb-1'
                    name='servingRadius'
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "required" }),
                      },
                      // make sure it is number and between 1 and 10;
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (value >= 1 && value <= 10) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            intl.formatMessage({ id: "servingRadiusError" })
                          );
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                    step={0.1}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                <Col md={24} sm={24} xs={24}>
                  <MapModal center={center} setCenter={setCenter} />
                </Col>
              </Row>

              <Divider />
              <Space direction='horizontal'>
                <Form.Item>
                  <CustomButton
                    htmlType='submit'
                    text={intl.formatMessage({ id: "save" })}
                    className={`btnRegister btn-text border-8`}
                    loading={loading}
                    disabled={loading}
                    type='primary'
                  />
                </Form.Item>

                <Form.Item>
                  <CustomButton
                    type='text'
                    text={intl.formatMessage({ id: "cancel" })}
                    disabled={loading}
                    onClick={() => {
                      setDeliveryInfo(null);
                      hide();
                    }}
                  />
                </Form.Item>
              </Space>
            </>
          );
        }}
      </Form>
    </Modal>
  );
};

export default DeliveryModal;
