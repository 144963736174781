import React, { useState, useEffect } from "react";
import { Space, Spin, Tag, Typography } from "antd";
import { useIntl } from "react-intl";
import COLORS from "../../Style/colors";
import { getRestaurantTipsBalance } from "../../API/fetch";
import { useSelector } from "react-redux";
import { DEFAULT_WALLET_NAME } from "../../Types";
import moment from "moment";
import { Table } from "ant-table-extensions";
import { FileExcelOutlined } from "@ant-design/icons";
import { customerExport } from "../../helpers";

const { Title, Text, Link } = Typography;
const TipRecords = () => {
  const intl = useIntl();
  const { restaurant } = useSelector((state) => state.restaurant);
  const [initialLoading, setInitialLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [amountToTransfer, setAmountToTransfer] = useState(0);
  const [serviceData, setServiceData] = useState(null);
  const [walletInfo, setWalletInfo] = useState(null);

  useEffect(() => {
    getTipsBalance();
  }, []);

  const getTipsBalance = async () => {
    try {
      const res = await getRestaurantTipsBalance();
      setAmountToTransfer(res.data.amountToTransfer);
      let formatted = formatTransaction(res.data.transactions);
      setTransactions(formatted);
      setInitialLoading(false);
    } catch (error) {
      console.log(error);
      setInitialLoading(false);
    }
  };

  const formatTransaction = (transactions) => {
    let formatted = [];
    if (transactions?.length) {
      transactions.forEach((transaction, i) => {
        let amountPaidForServices = 0;
        if (transaction?.amountPaidForServices > 0) {
          amountPaidForServices =
            transaction?.amountPaidForServices?.toFixed(2);
        }
        let total = transaction?.amount - amountPaidForServices;
        let forOrdersBetween = `${moment(transaction?.startDate).format(
          "YYYY/MM/DD hh:mm"
        )} - ${moment(transaction?.endDate).format("YYYY/MM/DD hh:mm")}`;
        formatted.push({
          key: transaction?._id,
          number: i + 1,
          _id: transaction?._id,
          amount: transaction?.amount?.toFixed(2),
          total: total?.toFixed(2),
          transactionNumber: transaction?.transactionNumber,
          status: transaction?.status,
          invoiceUrl: transaction?.invoiceUrl,
          transferTime: moment(transaction?.createdAt).format("DD/MM/YYYY"),
          forOrdersBetween,
          amountPaidForServices,
          transactionObject: { ...transaction },
        });
      });
    }
    return formatted;
  };

  const getNextTransfer = () => {
    // get then next 7th day ;
    const now = moment();
    // check if today date is 7th day
    const thisMonth = now.month();
    const newDate = moment(`${now.year()}-${thisMonth + 1}-07`, "YYYY-MM-DD");
    if (now.isSameOrBefore(newDate)) {
      return newDate.format("YYYY-MM-DD");
    } else {
      return moment(newDate).add(1, "months").format("YYYY-MM-DD");
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
    },
    {
      title: intl.formatMessage({ id: "transferNumber" }),
      dataIndex: "transactionNumber",
      key: "transactionNumber",
    },
    {
      title: intl.formatMessage({ id: "total" }),
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: intl.formatMessage({ id: "status" }),
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return <Tag color={"green"}>{status}</Tag>;
      },
    },
    {
      title: intl.formatMessage({ id: "transferTime" }),
      dataIndex: "transferTime",
      key: "transferTime",
      sorter: (a, b) =>
        new Date(a.transactionObject?.createdAt) -
        new Date(b.transactionObject?.createdAt),
      showSorterTooltip: false,
    },
  ];

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <Title level={3}>{intl.formatMessage({ id: "allPayments" })}</Title>
      </div>
      <Spin spinning={initialLoading}>
        <Space
          direction='vertical'
          justify='center'
          align='center'
          style={{ width: "100%" }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // marginBottom: 10,
            }}
          >
            {walletInfo?.tapWalletInfo?.status === "ACTIVE" &&
            walletInfo?.tapWalletInfo?.startActivationDate ? (
              <Space direction='vertical' size={2}>
                <Space direction='horizontal' justify='center' align='start'>
                  <Title level={5} className='my-font'>
                    {intl.formatMessage({ id: "fromThisDate" })}
                  </Title>
                  <Tag color={"green"}>
                    {moment(
                      walletInfo?.tapWalletInfo?.startActivationDate
                    ).format("YYYY-MM-DD")}
                  </Tag>
                  <Title level={5} className='my-font'>
                    {intl.formatMessage({
                      id: "youCanCheckYourPaymentWithTap",
                    })}
                  </Title>
                </Space>
                <Title level={5} className='my-font'>
                  {intl.formatMessage({ id: "loginWay" })}:
                </Title>
                <ul
                  style={{
                    backgroundColor: COLORS.white,
                  }}
                  className='py-2 border-8'
                >
                  <li>
                    {intl.formatMessage({ id: "useThisEmail" })}:{" "}
                    <Text copyable>{restaurant?.email}</Text>
                  </li>
                  <li>
                    {intl.formatMessage({ id: "goToThisLink" })}:{" "}
                    <Link href='https://businesses.tap.company' target='_blank'>
                      businesses.tap.company
                    </Link>
                  </li>
                  <li>{intl.formatMessage({ id: "clickOnForgetPassword" })}</li>
                  <li>
                    {intl.formatMessage({
                      id: "checkYourEmailAndSetNewPassword",
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: "youCanNowSeeAllYourPaymentsAndSettlements",
                    })}
                  </li>
                </ul>
              </Space>
            ) : (
              <>
                <Space direction='horizontal' justify='center' align='start'>
                  <Title level={5} className='my-font'>
                    {intl.formatMessage({ id: "yourBalance" })}
                  </Title>
                  <Tag color={"green"}>{amountToTransfer?.toFixed(2)}</Tag>
                </Space>
                <Space direction='horizontal' justify='center' align='start'>
                  <Title level={5} className='my-font'>
                    {intl.formatMessage({ id: "nextDateForTransaction" })}
                  </Title>
                  <Text keyboard>{getNextTransfer()}</Text>
                </Space>
              </>
            )}
          </div>
          {/* Check if we have service data that need to be cut from payment */}

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text className='my-font'>
              {intl.formatMessage({ id: "transferEvery7th" })}
            </Text>
          </div>
        </Space>
        <div
          style={{
            backgroundColor: COLORS.white,
            marginTop: 40,
            width: "92%",
            marginRight: "auto",
            marginLeft: "auto",
            borderRadius: 10,
            padding: 15,
          }}
          className='light-shadow'
        >
          <Table
            exportable
            exportableProps={{
              showColumnPicker: true,
              btnProps: {
                icon: <FileExcelOutlined />,
                type: "primary",
                style: { borderRadius: 7 },
                onClick: () =>
                  customerExport({
                    data: transactions,
                    columns,
                    fileName: "Transactions Report",
                    loadingSetter: setInitialLoading,
                  }),
              },
              children: (
                <Text
                  className='my-font'
                  style={{
                    color: COLORS.white,
                  }}
                >
                  {intl.formatMessage({ id: "export" })}
                </Text>
              ),
            }}
            pagination={{
              hideOnSinglePage: true,
            }}
            dataSource={transactions}
            scroll={{ x: 500 }}
            columns={columns}
            style={{ borderRadius: 10 }}
          />
        </div>
      </Spin>
    </div>
  );
};

export default TipRecords;
