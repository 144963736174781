import { createSlice, current } from "@reduxjs/toolkit";
import {
  arrAdd,
  arrRemoveByKeyValue,
  arrUpdateByKeyValue,
  concatArr,
} from "../../../helpers/array";

const initialState = {
  restaurant: null,
  coupons: [],
  promotions: [],
  feedbackForms: [],
  reservationModifier: [],
  design: {},
  adds: {},
  qr: {},
  integrations: {},
  socialLink: {},
};

export const restaurantSlice = createSlice({
  name: "restaurant",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setRestaurant: (state, action) => {
      state.restaurant = action.payload;
      if (action.payload?.coupons) {
        state.coupons = action.payload.coupons;
      }
      if (action.payload?.promotions) {
        state.promotions = action.payload.promotions;
      }
      if (action.payload?.reservationModifier) {
        state.reservationModifier = action.payload.reservationModifiers;
      }
      if (action.payload?.design) {
        state.design = action.payload.design;
      }
      if (action.payload?.adds) {
        state.adds = action.payload.adds;
      }
      if (action.payload?.qr) {
        state.qr = action.payload.qr;
      }
      if (action.payload?.socialLink) {
        state.socialLink = action.payload.socialLink;
      }
      if (action.payload?.integrations) {
        state.integrations = action.payload.integrations;
      }
    },
    editCoupon: (state, action) => {
      const newCoupons = arrUpdateByKeyValue({
        arr: [...current(state.coupons)],
        key: "_id",
        value: action.payload._id,
        newElement: action.payload,
      });
      state.coupons = newCoupons;
    },
    addCoupon: (state, action) => {
      const newCoupons = arrAdd({
        arr: [...current(state.coupons)],
        newElement: action.payload,
      });
      state.coupons = newCoupons;
    },
    addMultiplesCoupons: (state, action) => {
      const newCoupons = concatArr({
        arr: [...current(state.coupons)],
        newArr: action.payload,
      });
      state.coupons = newCoupons;
    },
    editPromotion: (state, action) => {
      const newPromotions = arrUpdateByKeyValue({
        arr: [...current(state.promotions)],
        key: "_id",
        value: action.payload._id,
        newElement: action.payload,
      });
      state.promotions = newPromotions;
    },
    addPromotion: (state, action) => {
      const newPromotions = arrAdd({
        arr: [...current(state.promotions)],
        newElement: action.payload,
      });
      state.promotions = newPromotions;
    },
    setFeedback: (state, action) => {
      state.feedbackForms = action.payload;
    },
    editFeedback: (state, action) => {
      const newFeedbacks = arrUpdateByKeyValue({
        arr: [...current(state.feedbackForms)],
        key: "_id",
        value: action.payload._id,
        newElement: action.payload,
      });
      state.feedbackForms = newFeedbacks;
    },
    addFeedback: (state, action) => {
      const newFeedbacks = arrAdd({
        arr: [...current(state.feedbackForms)],
        newElement: action.payload,
      });
      state.feedbackForms = newFeedbacks;
    },
    setDesign: (state, action) => {
      state.design = action.payload;
    },
    setAdds: (state, action) => {
      state.adds = action.payload;
    },
    setQr: (state, action) => {
      state.qr = action.payload;
    },
    setSocialLink: (state, action) => {
      state.socialLink = action.payload;
    },
    setIntegrations: (state, action) => {
      state.integrations = action.payload;
    },
    setReservationModifiers: (state, action) => {
      state.reservationModifier = action.payload;
    }, //to do
    editReservationModifier: (state, action) => {
      const newReservationModifier = arrUpdateByKeyValue({
        arr: current(state.reservationModifier),
        key: "_id",
        value: action.payload._id,
        newElement: action.payload,
      });
      state.reservationModifier = newReservationModifier;
    }, //to do
    addReservationModifier: (state, action) => {
      const newReservationModifiers = arrAdd({
        arr: current(state.reservationModifier),
        newElement: action.payload,
      });
      state.reservationModifier = newReservationModifiers;
    }, //done
    deleteReservationModifier: (state, action) => {
      const newReservationModifiers = arrRemoveByKeyValue({
        arr: current(state.reservationModifier),
        key: "_id",
        value: action.payload,
      });
      state.reservationModifier = newReservationModifiers;
    }, //To do
  },
});

export const {
  setRestaurant: setRestaurantAction,
  editCoupon: editCouponAction,
  addCoupon: addCouponAction,
  editPromotion: editPromotionAction,
  addPromotion: addPromotionAction,
  editFeedback: editFeedbackAction,
  addFeedback: addFeedbackAction,
  setFeedback: setFeedbackAction,
  setDesign: setDesignAction,
  setAdds: setAddsAction,
  setQr: setQrAction,
  setSocialLink: setSocialLinkAction,
  setIntegrations: setIntegrationsAction,
  setReservationModifiers: setReservationModifiersAction,
  addReservationModifier: addReservationModifierAction,
  editReservationModifier: editReservationModifierAction,
  deleteReservationModifier: deleteReservationModifierAction,
  addMultiplesCoupons: addMultiplesCouponsAction,
} = restaurantSlice.actions;

export default restaurantSlice.reducer;
