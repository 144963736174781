import React, { useRef } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Typography } from "antd";

const containerStyle = {
  width: "90%",
  height: "40vh",
};
const { Text } = Typography;

function MapModal({ center, setCenter }) {
  const intl = useIntl();
  const searchBoxRef = useRef(null);
  const { rtl } = useSelector((state) => state.settings);

  return (
    <div>
      {/* Add select city name */}
      <Text className='my-font'>
        {intl.formatMessage({ id: "chooseLocation" })}
      </Text>
      <br />
      <LoadScript
        googleMapsApiKey='AIzaSyDR59W-TaZEyeJY7aNcXZfULvShQO3RzM0'
        language={rtl ? "ar" : "en"}
        libraries={["places"]}
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          clickableIcons={false}
          center={center}
          zoom={18}
          onClick={(e) => {
            setCenter({ lat: e.latLng.lat(), lng: e.latLng.lng() });
          }}
        >
          <StandaloneSearchBox
            onLoad={(ref) => (searchBoxRef.current = ref)}
            onPlacesChanged={() => {
              if (searchBoxRef.current) {
                const places = searchBoxRef.current.getPlaces();
                if (places.length === 0) {
                  return;
                }
                let lat = places[0].geometry.location.lat();
                let lng = places[0].geometry.location.lng();
                setCenter({ lat, lng });
              }
            }}
          >
            <input
              type='text'
              placeholder='Search for an address .. ابحث عن مطعمك'
              style={{
                boxSizing: `border-box`,
                border: `1px solid #cccccc`,
                width: `95%`,
                marginRight: "auto",
                marginLeft: "auto",
                marginTop: 2,
                height: `32px`,
                padding: `0 12px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                textOverflow: `ellipses`,
                position: "absolute",
              }}
            />
          </StandaloneSearchBox>
          <Marker position={{ lat: center.lat, lng: center.lng }}></Marker>
        </GoogleMap>
      </LoadScript>
    </div>
  );
}

export default MapModal;
