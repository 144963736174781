import React, { useEffect, useState } from "react";
import {
  Tag,
  Typography,
  DatePicker,
  Form,
  Space,
  Image,
  TimePicker,
} from "antd";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { TAG_COLORS_BY_PAYMENT_STATUS } from "../../Types";
import { Table } from "ant-table-extensions";
import CustomButton from "../../Components/CustomButton";
import COLORS from "../../Style/colors";
import moment from "moment";
import { postSearchReservation } from "../../API/fetch";
import { FileExcelOutlined, SearchOutlined } from "@ant-design/icons";
import { Table as MYT } from "antd";
import { customerExport } from "../../helpers";
import ReportSummary from "./components/ReportSummary";
import { IoRemoveOutline } from "react-icons/io5";
import mada from "../../assets/images/Companies/mada.png";
import TABBY from "../../assets/images/Companies/tabby.jpg";
import { FaCcMastercard, FaCcVisa } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
const { RangePicker } = DatePicker;

const { Text, Title } = Typography;
const ReservationReports = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { waiter } = useSelector((state) => state.auth);
  const { rtl } = useSelector((state) => state.settings);
  const { restaurant } = useSelector((state) => state.restaurant);
  const { allBranches } = useSelector((state) => state.branch);
  const [formattedUsers, setFormattedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    // get all reservation from 5 days ago till now;
    const startDate = moment()
      .subtract(5, "days")
      .set({ hour: 0, minute: 0, second: 0 });
    const endDate = moment().set({ hour: 23, minute: 59, second: 59 });
    fetchUsers(startDate, endDate, 1);
    // set the form date to 5 days ago till now;
    form.setFieldsValue({
      date: [startDate, endDate],
    });
  }, []);

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      // render the index;
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: "branch" }),
      dataIndex: "branch",
      key: "branch",
      onFilter: (value, record) => value === record?.branchId,
      filters: allBranches?.map(({ branch }) => {
        return {
          text: rtl ? branch.name : branch.name_en,
          value: branch._id,
        };
      }),
    },
    {
      title: intl.formatMessage({ id: "customerName" }),
      dataIndex: "name",
      key: "name",
    },
    {
      title: intl.formatMessage({ id: "phone" }),
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: intl.formatMessage({ id: "status" }),
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return <Tag>{intl.formatMessage({ id: `${status}_Reservation` })}</Tag>;
      },
      onFilter: (value, record) => value === record.status,
      filters: [
        {
          text: intl.formatMessage({ id: "Active_Reservation" }),
          value: "Active",
        },
        {
          text: intl.formatMessage({ id: "deleted_Reservation" }),
          value: "deleted",
        },
        {
          text: intl.formatMessage({ id: "served_Reservation" }),
          value: "served",
        },
      ],
    },
    {
      title: intl.formatMessage({ id: "persons" }),
      dataIndex: "personsCount",
      key: "personsCount",
    },
    {
      title: intl.formatMessage({ id: "paymentStatus" }),
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      onFilter: (value, record) => value === record.paymentStatus,
      filters: [
        {
          text: intl.formatMessage({ id: "paid" }),
          value: "paid",
        },
        {
          text: intl.formatMessage({ id: "notPaid" }),
          value: "notPaid",
        },
      ],
      render: (paymentStatus) => {
        return (
          <Tag color={TAG_COLORS_BY_PAYMENT_STATUS[paymentStatus]}>
            {intl.formatMessage({ id: paymentStatus })}
          </Tag>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "paymentMethod" }),
      dataIndex: "paymentMethod",
      key: "paymentMethod",
      render: (paymentMethod) => {
        return (
          <Space size={"small"} direction='horizontal'>
            <Text>{intl.formatMessage({ id: paymentMethod })}</Text>
            {paymentMethod === "MADA" ? (
              <Image
                preview={false}
                src={mada}
                style={{
                  width: 20,
                  height: 10,
                }}
              />
            ) : null}
            {paymentMethod === "VISA" ? (
              <FaCcVisa color={COLORS.primary} size={20} />
            ) : null}
            {paymentMethod === "MASTERCARD" ? (
              <FaCcMastercard color={COLORS.primary} size={20} />
            ) : null}
            {paymentMethod === "TABBY_INSTALLMENT" ? (
              <Image
                preview={false}
                src={TABBY}
                style={{
                  width: 30,
                  height: 20,
                  borderRadius: 5,
                }}
              />
            ) : null}
          </Space>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "hoursCount" }),
      dataIndex: "isWithHours",
      key: "isWithHours",
      render: (isWithHours, record) => {
        return (
          <>
            {isWithHours ? (
              <Text>{record?.numberOfHours}</Text>
            ) : (
              <Space direction='horizontal' style={{ alignItems: "center" }}>
                <div>
                  <IoRemoveOutline size={15} />
                </div>
              </Space>
            )}
          </>
        );
      },
    },

    {
      title: intl.formatMessage({ id: "total" }),
      dataIndex: "total",
      key: "total",
    },
    {
      title: intl.formatMessage({ id: "paymentOnlineFees" }),
      dataIndex: "paymentOnlineFees",
      key: "paymentOnlineFees",
    },
    {
      title: intl.formatMessage({ id: "hoursAddsOn" }),
      dataIndex: "isWithHours",
      key: "isWithHours",
      render: (isWithHours, record) => {
        return (
          <>
            {isWithHours && record?.totalHoursAddsOn > 0 ? (
              <Text>{record?.hoursAddsOn}</Text>
            ) : (
              <Space direction='horizontal' style={{ alignItems: "center" }}>
                <div>
                  <IoRemoveOutline size={15} />
                </div>
              </Space>
            )}
          </>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "hoursAddsOnTotal" }),
      dataIndex: "isWithHours",
      key: "isWithHours",
      render: (isWithHours, record) => {
        return (
          <>
            {isWithHours && record?.totalHoursAddsOn > 0 ? (
              <Text>{record?.totalHoursAddsOn}</Text>
            ) : (
              <Space direction='horizontal' style={{ alignItems: "center" }}>
                <div>
                  <IoRemoveOutline size={15} />
                </div>
              </Space>
            )}
          </>
        );
      },
    },

    {
      title: intl.formatMessage({ id: "reservationDate" }),
      dataIndex: "reservationDate",
      key: "reservationDate",
      sorter: (a, b) =>
        new Date(a.reservationObject?.reservationDate) -
        new Date(b.reservationObject?.reservationDate),
      showSorterTooltip: false,
    },
    {
      title: intl.formatMessage({ id: "createdAt" }),
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) =>
        new Date(a.reservationObject?.createdAt) -
        new Date(b.reservationObject?.createdAt),
      showSorterTooltip: false,
    },
    {
      title: intl.formatMessage({ id: "view" }),
      dataIndex: "view",
      key: "view",
      render: (text, record) => {
        return (
          <CustomButton
            text={intl.formatMessage({ id: "view" })}
            className='btnRegister btn-text border-8 px-5'
            onClick={() => {
              navigate(`/reservations/${record._id}`);
            }}
            type='primary'
          />
        );
      },
    },
  ];

  const onFinish = async (values) => {
    const [start, end] = values.date;
    const startDate = moment(start).set({ hour: 0, minute: 0, second: 0 });
    const endDate = moment(end).set({ hour: 23, minute: 59, second: 59 });
    console.log(values.startTime);
    let startTime = undefined;
    let endTime = undefined;
    if (values?.startTime && values?.endTime) {
      startTime = moment(values.startTime);
      endTime = moment(values.endTime);
    }
    if (values.startTime && values.endTime && startTime && endTime) {
      // check if the start date and end date are the same;
      if (!startDate.isSame(endDate, "day")) {
        startTime = undefined;
        endTime = undefined;
      }
    }
    fetchUsers(startDate, endDate, 1, startTime, endTime);
  };

  const fetchUsers = async (startDate, endDate, page, startTime, endTime) => {
    try {
      setLoading(true);
      const data = {
        dateStart: startDate,
        dateEnd: endDate,
        code: waiter.code,
      };
      const res = await postSearchReservation(data);
      if (res.data?.reservations?.length) {
        const sorted = [...res.data.reservations];

        let sortedReservations = sorted.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        // if there are start time and end time filter the reservations;
        if (startTime && endTime) {
          sortedReservations = sortedReservations.filter((reservation) => {
            // console.log(moment(reservation.reservationDate).format('HH:mm') , startTime.format('HH:mm') , endTime.format('HH:mm'))
            // if the reservation time is between the start time and end time return true;
            if (
              moment(reservation.reservationDate).format("HH:mm") >=
                startTime.format("HH:mm") &&
              moment(reservation.reservationDate).format("HH:mm") <=
                endTime.format("HH:mm")
            ) {
              return true;
            }
            return false;
          });
        }
        const formattedReservations = sortedReservations?.map(
          (reservation, i) => {
            let paymentFees = 0;
            if (reservation?.paymentStatus === "paid") {
              if (reservation?.iwaiterShare && reservation?.iwaiterShare > 0) {
                paymentFees = reservation.iwaiterShare;
              } else {
                let percentage = 0.028;
                let visaPercentage = restaurant?.percentageForIwaiter;
                let madaPercentage = restaurant?.percentageForIWaiterMADA;
                let percentageTabby = 9;
                if (
                  restaurant?.percentageForIWaiterTabby &&
                  typeof restaurant?.percentageForIWaiterTabby === "number"
                ) {
                  percentageTabby = restaurant?.percentageForIWaiterTabby;
                }
                if (
                  reservation?.paymentCardType === "MADA" &&
                  restaurant?.percentageForIWaiterMADA &&
                  typeof restaurant?.percentageForIWaiterMADA === "number"
                ) {
                  percentage = madaPercentage / 100;
                } else if (
                  reservation?.paymentCardType === "TABBY_INSTALLMENT"
                ) {
                  percentage = percentageTabby / 100;
                } else if (
                  visaPercentage &&
                  typeof visaPercentage === "number"
                ) {
                  percentage = visaPercentage / 100;
                }
                paymentFees = reservation?.total * percentage;
              }
            }
            let phone = reservation?.customerId?.phone;
            let name = reservation?.customerId?.name;
            if (!reservation?.customerId) {
              phone = reservation?.appUserId?.phone;
              name = reservation?.appUserId?.name;
            }
            let paymentMethod = "Cash";
            if (reservation?.paymentCardType) {
              paymentMethod = reservation?.paymentCardType;
            }
            return {
              key: reservation?._id,
              _id: reservation?._id,
              branch: rtl
                ? reservation?.owner?.name
                : reservation?.owner?.name_en,
              branchId: reservation?.owner?._id,
              paymentStatus: reservation?.paymentStatus,
              personsCount: reservation?.personsCount,
              paymentMethod: paymentMethod,
              isWithHours: reservation?.isWithHours,
              numberOfHours: reservation?.numberOfHours,
              hoursAddsOn: reservation?.hourAddsOn,
              totalHoursAddsOn: reservation?.totalHoursAddsOn,
              name: name,
              phone: phone,
              total: reservation?.total,
              paymentOnlineFees: paymentFees?.toFixed(2),
              status: reservation?.status,
              createdAt: moment(reservation?.createdAt).format("YYYY/MM/DD"),
              reservationDate: reservation?.reservationDate
                ? moment(reservation?.reservationDate).format(
                    "YYYY/MM/DD hh:mm A"
                  )
                : moment(),
              // reservationDate: new Date(
              //   reservation?.reservationDate
              //     ? reservation.reservationDate
              //     : new Date()
              // ).toLocaleString(),
              reservationObject: {
                ...reservation,
              },
            };
          }
        );
        setFormattedUsers(formattedReservations);
      } else {
        setFormattedUsers([]);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <Title level={4}>{intl.formatMessage({ id: "reservations" })}</Title>
      </div>
      <div>
        <div style={{ backgroundColor: COLORS.gray }} className='py-4 px-3'>
          <Text className='my-font' type='secondary'>
            * {intl.formatMessage({ id: "maxIsTwoWeek" })}
          </Text>
          <Form
            name='food-form'
            form={form}
            className='form-container mt-2'
            layout='inline'
            onFinish={onFinish}
          >
            {() => (
              <>
                <Form.Item
                  rules={[{ required: true, message: "Please select Date" }]}
                  name='date'
                  label={intl.formatMessage({ id: "date" })}
                >
                  <RangePicker
                    onChange={(e) => {
                      if (e) {
                        const [start, end] = e;
                        // get the differnce between start and end;
                        const diff = end.diff(start, "days");
                        // check if the difference between start and end is bigger than 7 days;
                        if (diff > 30 || diff < 0 || start.isAfter(end)) {
                          form.setFieldsValue({
                            date: [
                              moment(start),
                              moment(start).add(30, "days"),
                            ],
                          });
                        }
                        // if the start not equal to end, set start time and end time to undefined;
                        if (!start.isSame(end, "day")) {
                          form.setFieldsValue({
                            startTime: undefined,
                            endTime: undefined,
                          });
                        }
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name='startTime'
                  label={intl.formatMessage({ id: "startTime" })}
                >
                  <TimePicker
                    format={"HH:mm"}
                    // if the start start date and end date are the same show the time picker;
                    disabled={
                      !form.getFieldValue("date") ||
                      form
                        .getFieldValue("date")[0]
                        .isSame(form.getFieldValue("date")[1], "day")
                        ? false
                        : true
                    }
                  />
                </Form.Item>
                <Form.Item
                  name='endTime'
                  label={intl.formatMessage({ id: "endTime" })}
                >
                  <TimePicker
                    format={"HH:mm"}
                    disabled={
                      !form.getFieldValue("date") ||
                      form
                        .getFieldValue("date")[0]
                        .isSame(form.getFieldValue("date")[1], "day")
                        ? false
                        : true
                    }
                  />
                </Form.Item>

                <Form.Item>
                  <CustomButton
                    htmlType='submit'
                    text={intl.formatMessage({ id: "search" })}
                    className={`btnRegister btn-text border-8 px-5 `}
                    loading={loading}
                    disabled={loading}
                    type='primary'
                  />
                </Form.Item>
              </>
            )}
          </Form>
        </div>
      </div>

      <Table
        exportable
        searchable
        title={() => {
          if (formattedUsers.length) {
            return <ReportSummary amounts={formattedUsers} />;
          }
        }}
        searchableProps={{
          fuzzySearch: true,
          inputProps: {
            placeholder: "Search this table...",
            prefix: <SearchOutlined />,
            disabled: loading || formattedUsers?.length === 0,
          },
        }}
        exportableProps={{
          showColumnPicker: true,
          disabled: loading || formattedUsers?.length === 0,
          btnProps: {
            icon: <FileExcelOutlined />,
            type: "primary",
            style: { borderRadius: 7 },
            onClick: () =>
              customerExport({
                data: formattedUsers,
                columns: columns,
                fileName: "Reservations Report",
                loadingSetter: setLoading,
              }),
          },
          children: (
            <Text
              className='my-font'
              style={{
                color: formattedUsers?.length === 0 ? "#dddddd" : COLORS.white,
              }}
            >
              {intl.formatMessage({ id: "export" })}
            </Text>
          ),
        }}
        columns={columns}
        className='light-shadow'
        pagination={{
          hideOnSinglePage: true,
        }}
        loading={loading}
        dataSource={formattedUsers}
        scroll={{ x: 1000 }}
        summary={(pageData) => {
          let reservationCount = 0;
          let personsCount = 0;
          let total = 0;
          pageData.forEach(({ reservationObject }) => {
            if (typeof +reservationObject?.total === "number") {
              if (reservationObject?.paymentStatus === "paid") {
                total +=
                  +reservationObject?.total +
                  reservationObject?.totalHoursAddsOn;
              }
            }
            personsCount += +reservationObject?.personsCount;
            reservationCount++;
          });

          return (
            <MYT.Summary>
              <MYT.Summary.Row>
                <MYT.Summary.Cell className='summary-bg-gray' colSpan={2}>
                  {intl.formatMessage({ id: "reservationsCount" })}
                </MYT.Summary.Cell>
                <MYT.Summary.Cell style={{ backgroundColor: "red" }}>
                  <Text style={{ color: COLORS.primary }}>
                    {reservationCount}
                  </Text>
                </MYT.Summary.Cell>
                <MYT.Summary.Cell className='summary-bg-gray' colSpan={2}>
                  {intl.formatMessage({ id: "persons" })}
                </MYT.Summary.Cell>
                <MYT.Summary.Cell colSpan={2}>
                  <Text>{personsCount}</Text>
                </MYT.Summary.Cell>
              </MYT.Summary.Row>
              <MYT.Summary.Row>
                <MYT.Summary.Cell className='summary-bg-gray' colSpan={2}>
                  {intl.formatMessage({ id: "total" })}
                </MYT.Summary.Cell>
                <MYT.Summary.Cell>
                  <Text type='success'>{total}</Text>
                </MYT.Summary.Cell>
              </MYT.Summary.Row>
            </MYT.Summary>
          );
        }}
      />
    </div>
  );
};

export default ReservationReports;
