import { api } from "./index";
import axios from "axios";
import apiUrl from "./config";

const GOOGLE_TOKEN = "AIzaSyDR59W-TaZEyeJY7aNcXZfULvShQO3RzM0";
const YELLOW_TOKEN =
  "dd24246e7ecf8304b8fb088ce41e8376be881d8919e577390a0841733d0c1346";
const YELLOW_URL = "staging.yallow.com";

export const signup = (data) => {
  return api.post(`/sign-up`, data);
};
export const checkReferCode = (code) => {
  return api.get(`/refers/check/${code}`);
};
export const signin = (data) => {
  return api.post(`/sign-in`, data);
};

export const postSendVerificationCode = (data) => {
  return api.post(`/send-verification`, data);
};

export const postForgetPassword = (data) => {
  return api.post(`/forget-password`, data);
};

export const postResetPassword = (data) => {
  return api.patch(`/reset-password`, data);
};

export const lookForRestaurant = ({ name, nameEn, city }) => {
  return api({
    method: "POST",
    url: `/search/${nameEn}`,
    data: {
      name,
      city,
    },
    timeout: 100000,
  });
};

export const postAddNewRestaurant = (data) => {
  return api({
    method: "POST",
    url: `/restaurants`,

    data,
  });
};

export const addAutoRestaurant = (data) => {
  return api({
    method: "POST",
    url: `/auto/restaurant`,
    data,
    timeout: 100000,
  });
};

export const postUpdateRestaurant = (id, data) => {
  return api({
    method: "PATCH",
    url: `/restaurants/${id}`,

    data,
  });
};

export const getRestaurantData = () => {
  return api({
    method: "GET",
    url: `/owner-restaurants`,
  });
};

export const getBranchData = (code) => {
  return api({
    method: "GET",
    url: `/branch-data?code=${code}`,
  });
};

export const postUpdateTableStatus = (id, data) => {
  return api({
    method: "PATCH",
    url: `/tables/${id}`,

    data,
  });
};

export const getOrderById = (id) => {
  return api({
    method: "GET",
    url: `/orders/${id}`,
  });
};

export const getPickupOrderById = (id) => {
  return api({
    method: "GET",
    url: `/orders-pickup/${id}`,
  });
};

export const getCustomOrderById = (id) => {
  return api({
    method: "GET",
    url: `/custom-orders/${id}`,
  });
};

export const postDeleteOrder = (id) => {
  return api({
    method: "DELETE",
    url: `/orders/${id}`,
  });
};

export const postDeletePickupOrder = (id) => {
  return api({
    method: "DELETE",
    url: `/orders-pickup/${id}`,
  });
};

export const postUpdateOrder = (id, data, otherTableId = null) => {
  if (otherTableId) {
    return api({
      method: "PATCH",
      url: `/orders/${id}?other=${otherTableId}`,
      data,
    });
  } else {
    return api({
      method: "PATCH",
      url: `/orders/${id}`,

      data,
    });
  }
};

export const postUpdatePickupOrder = (id, data) => {
  return api({
    method: "PATCH",
    url: `/orders-pickup/${id}`,

    data,
  });
};

export const postUpdateCustomOrder = (id, data) => {
  return api({
    method: "PATCH",
    url: `/custom-orders/${id}`,

    data,
  });
};

export const postUpdateCategory = (id, data) => {
  return api({
    method: "PATCH",
    url: `/categories/${id}`,

    data,
  });
};

export const postDeleteCategory = (id, data) => {
  return api({
    method: "DELETE",
    url: `/categories/${id}`,

    data,
  });
};

export const postCategorySort = (data) => {
  return api({
    method: "PATCH",
    url: `/sort-categories`,
    data,
  });
};

export const postNewCategory = (data) => {
  return api({
    method: "POST",
    url: `/categories`,
    data,
  });
};

// Food
export const postUpdateFood = (id, data) => {
  return api({
    method: "PATCH",
    url: `/foods/${id}`,

    data,
  });
};
export const postUpdateMultiFoods = (data) => {
  return api({
    method: "PATCH",
    url: `/multi-foods`,

    data,
  });
};

export const postAddFood = (data) => {
  return api({
    method: "POST",
    url: `/foods`,
    data,
  });
};

export const postDeleteFood = (id) => {
  return api({
    method: "DELETE",
    url: `/foods/${id}`,
  });
};

// export const postDeleteChoice = (id, data, token) => {
//     return api({
//         method: 'DELETE',
//         url: `/choices/${id}`,
//         headers: {
//             'Authorization': `Bearer ${token}`
//           },
//           data
//     })
// }

// export const postAddChoice = (data,  token) => {
//     return api({
//         method: 'POST',
//         url: `/choices`,
//         headers: {
//             'Authorization': `Bearer ${token}`
//           },
//           data
//     })
// }

// export const postEditChoice = (id ,data,  token) => {
//     return api({
//         method: 'PATCH',
//         url: `/choices/${id}`,
//         headers: {
//             'Authorization': `Bearer ${token}`
//           },
//           data
//     })
// }

export const postAddModifier = (data) => {
  return api({
    method: "POST",
    url: `/modifiers`,

    data,
  });
};

export const postEditModifier = (id, data) => {
  return api({
    method: "PATCH",
    url: `/modifiers/${id}`,

    data,
  });
};

export const postDeleteModifier = (id, data) => {
  return api({
    method: "DELETE",
    url: `/modifiers/${id}`,

    data,
  });
};

export const postModifiersSort = (data) => {
  return api({
    method: "PATCH",
    url: `/sort-modifiers`,

    data,
  });
};

export const postCode = (data, token) => {
  return api({
    method: "POST",
    url: `/code`,

    data,
  });
};

// export const postAddNewBranch = (data,  token) => {
//     return api({
//         method: 'POST',
//         url: `/branches`,

//           data
//     })
// }

export const postUpdateBranch = (id, data) => {
  return api({
    method: "PATCH",
    url: `/branches/${id}`,

    data,
  });
};

export const getAllWaiters = () => {
  return api({
    method: "GET",
    url: `/all-waiters`,
  });
};

export const postUpdateWaiter = (id, data) => {
  return api({
    method: "PATCH",
    url: `/waiters/${id}`,

    data,
  });
};

export const postAddWaiter = (data, token) => {
  return api({
    method: "POST",
    url: `/waiters`,

    data,
  });
};

export const postDeleteWaiter = (id) => {
  return api({
    method: "DELETE",
    url: `/waiters/${id}`,
  });
};

// Tables

export const postAddTable = (data) => {
  return api({
    method: "POST",
    url: `/tables`,
    data,
  });
};
export const postUpdateTable = (id, data) => {
  return api({
    method: "PATCH",
    url: `/edit-tables/${id}`,

    data,
  });
};

export const postDeleteTable = (id) => {
  return api({
    method: "DELETE",
    url: `/tables/${id}`,
  });
};

/// Feedback forms

export const postAddFeedback = (data) => {
  return api({
    method: "POST",
    url: `/feedbacks`,

    data,
  });
};

export const postUpdateForm = (id, data) => {
  return api({
    method: "PATCH",
    url: `/feedbacks/${id}`,

    data,
  });
};

export const postChangeFeedbackStatus = (data) => {
  return api({
    method: "PATCH",
    url: `/feedbacks-status`,

    data,
  });
};

export const postDeleteForm = (id, token) => {
  return api({
    method: "DELETE",
    url: `/feedbacks/${id}`,
  });
};

export const postGetOwnerData = (token) => {
  return api({
    method: "GET",
    url: `/owner-data`,
  });
};
export const verifyPayment = (id) => {
  return api.get(`/verify-payment-subscription/${id}`);
};

export const postUpdateUserSubscription = (data) => {
  return api({
    method: "POST",
    url: `/subscriptions`,
    data,
  });
};

export const postAddNewCoupon = (data) => {
  return api({
    method: "POST",
    url: `/coupons`,

    data,
  });
};

export const postAddNewCustomOrder = (data) => {
  return api({
    method: "POST",
    url: `/custom-orders`,

    data,
  });
};

export const cancelCustomOrder = (orderId) => {
  return api.patch(`/cancel-custom-orders/${orderId}`);
};

export const postStopCoupon = (id, data) => {
  return api({
    method: "PATCH",
    url: `/coupons/${id}`,

    data,
  });
};

export const postStopMultiCoupons = (data) => {
  return api({
    method: "POST",
    url: "/disable-multi-coupons",
    data,
  });
};

export const patchCoupon = (id, data) => {
  return api({
    method: "PATCH",
    url: `/coupons/${id}`,
    data,
  });
};
export const postAddWaitingArea = (branchId, data) => {
  return api({
    method: "POST",
    url: `/add-waiting/${branchId}`,
    data,
  });
};
export const updateWaitingArea = (id, data) => {
  return api({
    method: "PATCH",
    url: `/add-waiting/${id}`,

    data,
  });
};
export const postUpdateUser = (id, data) => {
  return api({
    method: "PATCH",
    url: `/waitingUsers/${id}`,

    data,
  });
};

// Payment status

export const postSearchOrders = (data) => {
  return api({
    method: "GET",
    url: `/orders?orderType=${data.orderType}&paymentStatus=${data.paymentStatus}&start=${data.dateStart}&end=${data.dateEnd}&code=${data.code}&page=${data.page}&forReports=${data.forReports}`,
  });
};

export const getAddressText = (lat, lng) => {
  return axios({
    method: "GET",
    url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_TOKEN}`,
  });
};

export const requestDriverFromSH = (data) => {
  return axios({
    method: "POST",
    url: `https://${YELLOW_URL}/a/${YELLOW_TOKEN}/order/add`,
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    data,
  });
};
export const postAddManualUser = (data, id) => {
  return api({
    method: "POST",
    url: `/waitingUsers-admin/${id}`,

    data,
  });
};

export const trackOrderDelivery = (id) => {
  return axios({
    method: "GET",
    url: `https://${YELLOW_URL}/a/${YELLOW_TOKEN}/order/track/${id}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  });
};
export const postSearchUsers = (data) => {
  return api({
    method: "GET",
    url: `/users-restaurants?userType=${data.userType}&start=${data.dateStart}&end=${data.dateEnd}&code=${data.code}&userBadge=${data.userBadge}`,
  });
};

export const getDayData = (branchId, date) => {
  return api.get(`/reservations-data/${branchId}?date=${date}`);
};

export const postUpdateIntegrations = (data) => {
  return api({
    method: "POST",
    url: `/integrations`,

    data,
  });
};

export const getMenuEngineer = (id, token) => {
  return api({
    method: "GET",
    url: `/menu-engineer?id=${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const editAds = (id, data) => {
  return api({
    method: "PATCH",
    url: `/ads/${id}`,
    data,
  });
};

export const postMarnIntegration = (data) => {
  return api({
    method: "POST",
    url: `/add-marn-integration`,
    data,
    timeout: 100000,
  });
};

// Make order;
export const postDintInOrder = (data) => {
  return api.post(`/orders`, data);
};
export const postPickupOrder = (data) => {
  return api.post(`/orders-pickup`, data);
};

//Promotions;
export const addPromotion = (data) => {
  return api({
    method: "POST",
    url: `/promotions`,
    data,
  });
};
export const editPromotion = (data, id) => {
  return api({
    method: "PATCH",
    url: `/promotions/${id}`,
    data,
  });
};

export const editDesign = (data, restaurantId, query) => {
  return api({
    method: "PATCH",
    url: `/design/${restaurantId}?${query}`,
    data,
  });
};

export const getFoodicsInformation = () => {
  return api({
    method: "GET",
    url: `/foodics-information`,
  });
};
export const addFoodicsInformation = (data) => {
  return api({
    method: "POST",
    url: `/add-foodics-info`,
    data,
  });
};
export const addMarnInformation = (data) => {
  return api({
    method: "POST",
    url: `/add-marn-info`,
    data,
  });
};

export const getAnalyticsVisitForTime = (data) => {
  return api({
    method: "GET",
    url: `/analytics-visits-ga?start=${data.start}&end=${data.end}&id=${data.id}`,
  });
};
export const getAnalyticsRetentionRateByDate = (data) => {
  return api({
    method: "GET",
    url: `/analytics-retention-ga?start=${data.start}&end=${data.end}&id=${data.id}`,
  });
};
export const getAnalyticsFoodAnalyticsItemsByDate = (data) => {
  return api({
    method: "GET",
    url: `/analytics-foods-ga?start=${data.start}&end=${data.end}&id=${data.id}`,
  });
};
export const getDataAnalyticsByDates = (data) => {
  return api({
    method: "GET",
    url: `/data-analytics?start=${data.start}&end=${data.end}&id=${data.id}&type=${data.type}`,
  });
};

export const updateReservationArea = (id, data) => {
  return api({
    method: "PATCH",
    url: `/add-reservation/${id}`,

    data,
  });
};

export const postAddReservationArea = (branchId, data) => {
  return api({
    method: "POST",
    url: `/add-reservation/${branchId}`,
    data,
  });
};

export const postAddManualReservationUser = (data, id) => {
  return api({
    method: "POST",
    url: `/reservationUsers-admin/${id}`,

    data,
  });
};
export const getReservationUsers = (date, branchId) => {
  return api({
    method: "GET",
    url: `/reservationUsers-admin/${branchId}?date=${date}`,
  });
};

export const postUpdateUserReservation = (id, data) => {
  return api({
    method: "PATCH",
    url: `/reservationUsers/${id}`,

    data,
  });
};

export const postAddReservationModifier = (data) => {
  return api({
    method: "POST",
    url: "/reservation-modifier",

    data,
  });
};
export const postEditReservationModifier = (id, data) => {
  return api({
    method: "PATCH",
    url: `/reservation-modifier/${id}`,

    data,
  });
};

export const postDeleteReservationModifier = (id) => {
  return api({
    method: "DELETE",
    url: `/reservation-modifier/${id}`,
  });
};

// export const postRegisterFoodics = (data, token) => {
//     return api({
//         method: 'POST',
//         url: `/integrations`,
//         headers: {
//             'Authorization': `Bearer ${token}`
//           },
//           data
//     })
// }

export const patchComment = (data, commentId) => {
  return api({
    method: "PATCH",
    url: `/comments/${commentId}`,
    data,
  });
};

export const getRestaurantPaymentBalance = () => {
  return api({
    method: "GET",
    url: `/restaurant-payment-info`,
  });
};

export const createWalletRestaurant = () => {
  return api({
    method: "POST",
    url: `/create-wallet-restaurant`,
  });
};

export const addMultiTables = (data) => {
  return api({
    method: "POST",
    url: `/multi-tables`,
    data,
  });
};

export const postDeleteMultiTables = (data) => {
  return api({
    method: "POST",
    url: `/delete-multi-tables`,
    data,
  });
};

export const sendWaitingWhatsapp = (data) => {
  return api({
    method: "POST",
    url: `/waiting-whatsapp`,
    data,
  });
};
export const sendOrderWhatsapp = (data) => {
  return api({
    method: "POST",
    url: `/orders-whatsapp`,
    data,
  });
};

export const getPDFFile = (data) => {
  return axios({
    method: "POST",
    url: `https://api.game.iwaiter.net/v1/qr-generator`,
    headers: {
      Authorization: `Bearer 57170456948868AE094B967`,
    },
    data,
    responseType: "blob",
  });
};

export const resetPinCode = (data) => {
  return api({
    method: "POST",
    url: `/reset-pin-code`,
    data,
  });
};

export const signOutServer = () => {
  return api({
    method: "DELETE",
    url: `/sign-out`,
  });
};

export const postSearchReservation = (data) => {
  return api({
    method: "GET",
    url: `/reservations-restaurants?start=${data.dateStart}&end=${data.dateEnd}&code=${data.code}`,
  });
};

export const refundPaymentOrder = (data) => {
  return api({
    method: "POST",
    url: `/refund-request`,
    data,
  });
};

export const getRestaurantPaymentRefund = (data) => {
  return api({
    method: "GET",
    url: `/restaurant-payment-refund?start=${data.start}&end=${data.end}`,
  });
};

export const editMultiTables = (data) => {
  return api.patch("/tables", data);
};

export const deleteAreaPhoto = (branchId, areaId, data) => {
  return api({
    method: "DELETE",
    url: `/reservation-delete-image?branchId=${branchId}&areaId=${areaId}`,
    data,
  });
};

export const getRestaurantPaymentData = (getOld) => {
  return api({
    method: "GET",
    url: `/restaurant-payment-info-data?getOld=${getOld}`,
  });
};

export const getSessionId = () => {
  return api.get(`/my-fatoorah-session-dash`);
};

export const getMyFatoorahPaymentLik = (data) => {
  return api.post(`/my-fatoorah-payment-dash`, data);
};

export const getPaymentLink = (total, lang) => {
  return api.get(`/my-fatoorah-payment-link?total=${total}&lang=${lang}`);
};

export const verifyMyFatoorahPayment = (data) => {
  return api.post(`/my-fatoorah-verify-payment`, data);
};

export const getBanks = () => {
  return api.get(`/my-fatoorah-banks`);
};

export const editAddWallet = (data) => {
  return api.post(`/wallets`, data);
};

export const getWalletInformation = () => {
  return api.get(`/wallets`);
};

export const clearTableCustomer = (id) => {
  return api.patch(`/clear-table-customer/${id}`);
};

export const getAllCouponsForRestaurant = () => {
  return api.get(`/coupons`);
};

export const editGroupCoupons = (data) => {
  return api.patch(`/coupons`, data);
};

export const getPDFCouponFile = (data) => {
  return axios({
    method: "POST",
    url: `https://api.game.iwaiter.net/v1/qr-coupon-generator`,
    headers: {
      Authorization: `Bearer 57170456948868AE094B967`,
    },
    data,
    responseType: "blob",
  });
};

export const getSuperUserRestaurants = (data, token) => {
  return axios({
    method: "POST",
    url: `${apiUrl}/super-user-restaurants`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const superJoinRestaurant = (data, token) => {
  return axios({
    method: "POST",
    url: `${apiUrl}/join-restaurant`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};
export const refundPaymentReservation = (data) => {
  return api.post(`/refund-reservation`, data);
};

export const getOrderCoupon = (couponId) => {
  return api.get(`/orders-coupons/${couponId}`);
};

export const createDeliveryLocation = (data) => {
  return api.post(`/delivery-location`, data);
};

export const trackOrder = (otherOrderId, localOrderId) => {
  return api.get(`/track-order/${localOrderId}?otherOrderId=${otherOrderId}`);
};

export const getCustomerDataForRestaurant = (customerId, userPhone) => {
  return api.get(`/customer-data/${customerId}?phone=${userPhone}`);
};

export const postUpdateBranchPrize = (id, data) => {
  return api({
    method: "PATCH",
    url: `/branches-prize/${id}`,
    data,
  });
};

export const getCampaigns = () => {
  return api.get(`/campaigns`);
};

export const createCampaigns = (data) => {
  return api.post(`/campaigns`, data);
};

export const verifyCampaignPayment = (tapId, restaurantId) => {
  return api.get(
    `/verify-campaigns-payment/${tapId}?restaurantId=${restaurantId}`
  );
};

export const sendCampaign = (campaignId) => {
  return api.post(`/send-campaign/${campaignId}`);
};

export const getRestaurantTipsBalance = () => {
  return api({
    method: "GET",
    url: `/restaurant-payment-info-tips`,
  });
};

export const getOneDetailsDeposit = (id) => {
  return api({
    method: "GET",
    url: `/one-deposit/${id}`,
  });
};

export const getRestaurantTipsReports = (data) => {
  return api({
    method: "GET",
    url: `/restaurant-payment-tips-reports?start=${data.start}&end=${data.end}`,
  });
};

export const getReservation = (id) => {
  return api.get(`/reservationDetails/${id}`);
};

export const getRestaurantVatInvoices = () => {
  return api.get(`/restaurant-vat-invoices`);
};

export const getRestaurantVatInvoice = (month, year) => {
  return api.get(`/restaurant-vat-invoice?month=${month}&year=${year}`, {
    // make the timeout 5 minutes
    timeout: 600000,
  });
};

export const getFeedbacks = () => {
  return api.get(`/feedbacks`);
};

export const getWalletAppBalance = () => {
  return api.get(`/wallet-app-balance`);
};

export const sendWhatsappSMS = (data, type) => {
  return api.post(`/send-verification?type=${type}`, data);
};

export const getCustomCategories = () => {
  return api({
    method: "GET",
    url: `/customCategories`,
  });
};
export const postNewCustomCategory = (data) => {
  return api({
    method: "POST",
    url: `/customCategories`,
    data,
  });
};

export const postUpdateCustomCategory = (id, data) => {
  return api({
    method: "PATCH",
    url: `/customCategories/${id}`,

    data,
  });
};
export const postDeleteCustomCategory = (id) => {
  return api({
    method: "DELETE",
    url: `/customCategories/${id}`,
  });
};

export const getPendingPayments = (query) => {
  return api.get(`/pending-payments-restaurant/${query}`);
};

export const verifyMyFatoorahPaymentReservation = (
  id,
  restaurantId,
  reservationId
) => {
  return api.get(
    `/verify-my-fatoorah-payment/${id}?restaurantId=${restaurantId}&reservationId=${reservationId}`
  );
};

export const verifyPrivatePaymentReservation = (
  id,
  restaurantId,
  reservationId
) => {
  return api.get(
    `/verify-payment/${id}?restaurantId=${restaurantId}&reservationId=${reservationId}`
  );
};

export const verifyMyFatoorahPaymentForOrder = (id, restaurantId) => {
  return api.get(
    `/verify-my-fatoorah-payment/${id}?restaurantId=${restaurantId}`
  );
};

export const verifyPrivatePayment = (id, restaurantId) => {
  return api.get(`/verify-payment/${id}?restaurantId=${restaurantId}`);
};

export const getIwaiterDetailsDeposit = (id) => {
  return api.get(`/one-iwaiter-deposit/${id}`);
};
