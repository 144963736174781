import React, {  useState } from "react";
import {
  Tag,
  Typography,
  DatePicker,
  Form,
  Row,
} from "antd";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import {
  DINE_IN_ORDERS_STATUS,
  TAG_COLORS_BY_STATUS,
} from "../../Types";
import { Table } from "ant-table-extensions";
import CustomButton from "../../Components/CustomButton";
import COLORS from "../../Style/colors";
import moment from "moment";
import { postSearchOrders } from "../../API/fetch";

import { getAverages, getTimeForOrder } from "../../helpers/orders";
import CardTime from "./CardTime";
const { RangePicker } = DatePicker;
const {  Title } = Typography;
const OrderLiveCycle = () => {
  const intl = useIntl();
  const {  waiter } = useSelector((state) => state.auth);
  const { rtl } = useSelector((state) => state.settings);
  const { allBranches } = useSelector((state) => state.branch);
  const [formattedOrders, setFormattedOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [averages, setAverages] = useState({});

  const [form] = Form.useForm();

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
    },
    {
      title: intl.formatMessage({ id: "branch" }),
      dataIndex: "branch",
      key: "branch",
      onFilter: (value, record) => value === record.orderObject?.branchId,
      filters: allBranches?.map(({ branch }) => {
        return {
          text: rtl ? branch.name : branch.name_en,
          value: branch._id,
        };
      }),
    },
    {
      title: intl.formatMessage({ id: "orderStatus" }),
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return (
          <Tag color={TAG_COLORS_BY_STATUS[status]}>
            {intl.formatMessage({
              id:
                status === DINE_IN_ORDERS_STATUS.CLOSE
                  ? "closedOrder"
                  : status
                  ? status
                  : "n/a",
            })}
          </Tag>
        );
      },
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      filters: [
        {
          text: intl.formatMessage({ id: DINE_IN_ORDERS_STATUS.OPEN }),
          value: DINE_IN_ORDERS_STATUS.OPEN,
        },
        {
          text: intl.formatMessage({ id: DINE_IN_ORDERS_STATUS.IN_SERVER }),
          value: DINE_IN_ORDERS_STATUS.IN_SERVER,
        },
        {
          text: intl.formatMessage({ id: DINE_IN_ORDERS_STATUS.SERVED }),
          value: DINE_IN_ORDERS_STATUS.SERVED,
        },
        {
          text: intl.formatMessage({ id: "closedOrder" }),
          value: DINE_IN_ORDERS_STATUS.CLOSE,
        },
      ],
    },
    {
      title: intl.formatMessage({ id: "time" }),
      dataIndex: "time",
      key: "time",
      sorter: (a, b) =>
        new Date(a.orderObject?.createdAt) - new Date(b.orderObject?.createdAt),
      showSorterTooltip: false,
    },
    {
      title: intl.formatMessage({ id: "table" }),
      dataIndex: "table",
      key: "table",
    },
    {
      title: intl.formatMessage({ id: "orderNumber" }),
      dataIndex: "orderNumber",
      key: "orderNumber",
    },

    {
      title: intl.formatMessage({ id: "timeToOrder" }),
      dataIndex: "timeToOrder",
      key: "timeToOrder",
    },
    {
      title: intl.formatMessage({ id: "fromCreateToInService" }),
      dataIndex: "fromCreateToInService",
      key: "fromCreateToInService",
    },
    {
      title: intl.formatMessage({ id: "fromInServiceToServed" }),
      dataIndex: "fromInServiceToServed",
      key: "fromInServiceToServed",
    },
    {
      title: intl.formatMessage({ id: "fromServedToClose" }),
      dataIndex: "fromServedToClose",
      key: "fromServedToClose",
    },
    {
      title: intl.formatMessage({ id: "fromOrderToClose" }),
      dataIndex: "fromOrderToClose",
      key: "fromOrderToClose",
    },
    {
      title: intl.formatMessage({ id: "fromScanToClose" }),
      dataIndex: "fromScanToClose",
      key: "fromScanToClose",
    },
  ];

  const onFinish = async (values) => {
    const [start, end] = values.date;
    const startDate = moment(start).set({ hour: 0, minute: 0, second: 0 });
    const endDate = moment(end).set({ hour: 23, minute: 59, second: 59 });
    fetchOrders(startDate, endDate);
  };

  const fetchOrders = async (startDate, endDate) => {
    try {
      setLoading(true);
      const data = {
        orderType: "inHouse",
        paymentStatus: "all",
        dateStart: startDate,
        dateEnd: endDate,
        code: waiter.code,
      };

      const res = await postSearchOrders(data);
      if (res.data?.orders?.length) {
        const sorted = [...res.data.orders];
        let sortedOrders = sorted.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        const formattedOrders = sortedOrders?.map((order, i) => {
          const branch = allBranches?.find(
            (b) => b?.branch?._id === order?.branchId
          );
          const table = branch?.tables?.find((t) => t._id === order?.tableId);
          let orderNumber = "N/A";
          if (order?.orderNumber) {
            orderNumber = "#H " + order?.orderNumber;
          }
          const {
            timeFromScan,
            timeToInService,
            timeToServed,
            timeToClose,
            timeFromOrderToClose,
            timeFromScanToClose,
          } = getTimeForOrder(order);

          return {
            number: i + 1,
            branch: rtl ? branch?.branch?.name : branch?.branch?.name_en,
            status: order?.status,
            time: new Date(
              order?.createdAt ? order.createdAt : new Date()
            ).toLocaleString(),
            table: rtl ? table?.name : table?.name_en,
            type: order?.type,
            orderNumber: orderNumber,
            timeToOrder: timeFromScan,
            fromCreateToInService: timeToInService,
            fromInServiceToServed: timeToServed,
            fromServedToClose: timeToClose,
            fromOrderToClose: timeFromOrderToClose,
            fromScanToClose: timeFromScanToClose,
            key: order?._id,
            orderObject: {
              ...order,
              branchName: rtl ? branch?.branch?.name : branch?.branch?.name_en,
              table,
            },
          };
        });
        const averages = getAverages(sortedOrders);
        setAverages(averages);
        setFormattedOrders(formattedOrders);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <Title level={4}>{intl.formatMessage({ id: "reports" })}</Title>
        <CustomButton
          text={intl.formatMessage({
            id: showFilter ? "hide" : "filter",
          })}
          type='primary'
          className='border-8'
          onClick={() => setShowFilter(!showFilter)}
        />
      </div>
      {showFilter ? (
        <div>
          <div style={{ backgroundColor: COLORS.gray }} className='py-4 px-3'>
            <Form
              name='food-form'
              form={form}
              className='form-container'
              layout='inline'
              onFinish={onFinish}
              initialValues={{ type: "all" }}
            >
              <Form.Item
                rules={[{ required: true, message: "Please select Date" }]}
                name='date'
                label={intl.formatMessage({ id: "date" })}
              >
                <RangePicker />
              </Form.Item>

              <Form.Item>
                <CustomButton
                  htmlType='submit'
                  text={intl.formatMessage({ id: "search" })}
                  className={`btnRegister btn-text border-8 px-5 `}
                  loading={loading}
                  disabled={loading}
                  type='primary'
                />
              </Form.Item>
            </Form>
          </div>
        </div>
      ) : null}

      <div>
        <Row type='flex' justify='center' align='middle'>
          {Object.keys(averages)?.length
            ? Object.keys(averages).map((key, i) => {
                return (
                 <CardTime key={key} myKey={key} averages={averages} />
                );
              })
            : null}
        </Row>
      </div>

      <Table
        columns={columns}
        className='light-shadow'
        pagination={{
          hideOnSinglePage: true,
        }}
        loading={loading}
        dataSource={formattedOrders}
        scroll={{ x: 1700 }}
      />
    </div>
  );
};

export default OrderLiveCycle;
