import React, { useState, useEffect } from "react";
import {
  Typography,
  Space,
  Table,
} from "antd";
import { useIntl } from "react-intl";
import {
  MenuOutlined,
  MinusCircleTwoTone,
  PlusCircleTwoTone,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { arrayMoveImmutable } from "array-move";
import { setCategoriesAction } from "../../../redux/reducers/menuReducer";
import { postCategorySort } from "../../../API/fetch";
import openNotification from "../../../Components/Notifications";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import CustomButton from "../../../Components/CustomButton";
import FoodsSort from "./Components/FoodsSort";
import { arrUpdateByKeyValue } from "../../../helpers/array";

const { Title, Text } = Typography;

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
));

const SortableItem = sortableElement((props) => {
  return <tr  {...props} />

});
const SortableContainer = sortableContainer((props) => <tbody {...props} />);



const Sort = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { rtl, isBigScreen } = useSelector((state) => state.settings);
  const { categories } = useSelector((state) => state.menu);
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [didSortChange, setDidSortChange] = useState(false);

  // set the format for data;
  useEffect(() => {
    if (categories.length) {
      setDataSource(
        categories.map((cat, index) => {
          return {
            key: cat.categoryId,
            categoryId: cat.categoryId,
            name: rtl ? cat.name : cat.name_en,
            foodsLength: cat?.foods?.length,
            foods: cat.foods,
            index: index,
            order: cat.order,
          };
        })
      );
    }
  }, [categories]);

  // data columns;
  const columns = [
    {
      title: intl.formatMessage({ id: "sort" }),
      dataIndex: "sort",
      width: 30,
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "name",
      key: "name",
    },
    {
      title: intl.formatMessage({ id: "foodCount" }),
      dataIndex: "foodsLength",
      key: "foodsLength",
    },
  ];

// on End sorting;
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        [].concat(dataSource),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      setDataSource(newData);
      setDidSortChange(true);
    }
  };

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass='row-dragging sort-row'
      style={{flexDirection: rtl? 'row-reverse': 'row'}}
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = dataSource.findIndex(
      (x) => x.index === restProps["data-row-key"]
    );
    return <SortableItem  index={index} {...restProps} />;
  };

  // on food inside the category finisgh sorting;
  const setFoodsSortOnOriginalCategory = ({ foods, categoryId }) => {
    const newFoods = foods.map((food) => {
      let newFood = { ...food };
      delete newFood.key;
      delete newFood.showName;
      delete newFood.index;
      return newFood;
    });
    const cat = categories.find((c) => c.categoryId === categoryId);
    const newCategory = { ...cat, foods: newFoods };
    const newCategories = arrUpdateByKeyValue({
      arr: categories,
      key: "categoryId",
      value: newCategory.categoryId,
      newElement: newCategory,
    });
    dispatch(setCategoriesAction(newCategories));
    setDidSortChange(true);
  };


  // save the sort to backend;
  const saveSortCategory = async () => {
    setLoading(true);
    const foods = [];
    const customCategories = dataSource.map((cat, i) => {
      if (cat?.foods?.length) {
        cat.foods.forEach((f, i) => {
          foods.push({
            id: f.foodId,
            order: i,
          });
        });
      }
      return {
        id: cat.categoryId,
        order: i,
      };
    });

    const data = {
      categories: customCategories,
      foods,
    };

    try {
      const res = await postCategorySort(data);
      if (res.data.status === "success") {
        setLoading(false);
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
        setDidSortChange(false);
      }
    } catch (error) {
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>

      <Space
        direction='vertical'
        style={{ width: isBigScreen ? "70%" : "100%" }}
      >
        <Title>{intl.formatMessage({ id: "sort" })}</Title>
        <Text className='my-font' style={{ marginBottom: 0, marginTop: 0 }}>
          {intl.formatMessage({ id: "categoryDes1" })}
          {" , "}
          {intl.formatMessage({ id: "categoryDes4" })}{" "}
          <Text
            style={{ marginBottom: 0, marginTop: 0 }}
            strong
            className='my-font'
          >
            {intl.formatMessage({ id: "categoryDes5" })}
          </Text>
          <Text
            style={{ marginBottom: 0, marginTop: 0 }}
            strong
            className='my-font'
          >
            {intl.formatMessage({ id: "clickAndHold" })}
          </Text>
          <MenuOutlined
            style={{ cursor: "grab", color: "#999" }}
            className='mx-2'
          />
        </Text>
      </Space>
      <div style={{ width: "100%", textAlign: "center" }} className='my-3'>
        {didSortChange ? (
          <CustomButton
            text={intl.formatMessage({ id: "saveSort" })}
            type='primary'
            className={`btnRegister btn-text border-8 px-5 `}
            loading={loading}
            disabled={loading}
            onClick={saveSortCategory}
          />
        ) : null}
      </div>
      {/* Sort */}
      <div>
        <Table
          className='components-table-demo-nested light-shadow'
          pagination={false}
          dataSource={dataSource}
          columns={columns}
          expandable={{
            // sort the food inside the category;
            expandedRowRender: (data, index, _, expanded) =>
              expanded ? (
                <FoodsSort
                  categoryData={data}
                  index={index}
                  setFoodsSortOnOriginalCategory={
                    setFoodsSortOnOriginalCategory
                  }
                />
              ) : null,
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <MinusCircleTwoTone onClick={(e) => onExpand(record, e)} />
              ) : (
                <PlusCircleTwoTone onClick={(e) => onExpand(record, e)} />
              ),
          }}
          rowKey='index'
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
        />
      </div>
    </div>
  );
};

export default Sort;
