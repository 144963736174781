import React from "react";
import {
  DELIVERY_ORDERS_STATUS,
  TAG_COLORS_BY_STATUS_ALL_TYPES,
} from "../../../Types";
import { Space, Tag } from "antd";
import { useIntl } from "react-intl";
import { toggleAlert } from "../../NotificationModal/AlertSound";
const DeliveryTag = ({ status, changeStatus }) => {
  const intl = useIntl();
  return (
    <Space direction='horizontal' size={"large"}>
      {status === DELIVERY_ORDERS_STATUS.PENDING ? (
        <>
          <Tag
            onClick={() => {
              toggleAlert({ status: "stop" });
              changeStatus(status, false);
            }}
            color={"green"}
            className='my-font px-5 py-1'
          >
            {intl.formatMessage({ id: "accept" })}
          </Tag>
          <Tag
            onClick={() => {
              toggleAlert({ status: "stop" });
              changeStatus(status, true);
            }}
            color={"red"}
            className='my-font px-5 py-1'
          >
            {intl.formatMessage({ id: "reject" })}
          </Tag>
        </>
      ) : null}
      {status === DELIVERY_ORDERS_STATUS.PENDING ? null : (
        <Tag
          onClick={() => {
            if (changeStatus) {
              changeStatus(status, status === DELIVERY_ORDERS_STATUS.PENDING);
              if (status === DELIVERY_ORDERS_STATUS.PENDING) {
                toggleAlert({ status: "stop" });
              }
            }
          }}
          color={
            status === DELIVERY_ORDERS_STATUS.OPEN
              ? TAG_COLORS_BY_STATUS_ALL_TYPES.inKitchen
              : status === DELIVERY_ORDERS_STATUS.IN_KITCHEN
              ? TAG_COLORS_BY_STATUS_ALL_TYPES.ready
              : status === DELIVERY_ORDERS_STATUS.READY
              ? TAG_COLORS_BY_STATUS_ALL_TYPES.inDelivery
              : status === DELIVERY_ORDERS_STATUS.IN_DELIVERY
              ? ""
              : "red"
          }
          className={"my-font px-5 py-1"}
        >
          {status === DELIVERY_ORDERS_STATUS.OPEN
            ? intl.formatMessage({ id: "inKitchenButtonText" })
            : status === DELIVERY_ORDERS_STATUS.IN_KITCHEN
            ? intl.formatMessage({ id: "readyForDeliver" })
            : status === DELIVERY_ORDERS_STATUS.READY
            ? intl.formatMessage({ id: "inDeliveryButtonText" })
            : status === DELIVERY_ORDERS_STATUS.IN_DELIVERY
            ? intl.formatMessage({ id: "closeAction" })
            : intl.formatMessage({ id: "openButtonText" })}
        </Tag>
      )}
    </Space>
    // <Tag
    //   onClick={() => changeStatus(status)}
    //   color={
    //     status === DELIVERY_ORDERS_STATUS.OPEN
    //       ? TAG_COLORS_BY_STATUS_DELIVERY.inKitchen
    //       : status === DELIVERY_ORDERS_STATUS.IN_KITCHEN
    //       ? TAG_COLORS_BY_STATUS_DELIVERY.ready
    //       : status === DELIVERY_ORDERS_STATUS.READY
    //       ? TAG_COLORS_BY_STATUS_DELIVERY.inDelivery
    //       : status === DELIVERY_ORDERS_STATUS.IN_DELIVERY
    //       ? ''
    //       : "red"
    //   }
    //   className='my-font px-5 py-1'
    // >

    // </Tag>
  );
};

export default DeliveryTag;
