import React, { useState, useEffect } from "react";
import {
  Form,
  Space,
  Divider,
  Typography,
  Select,
  Row,
  Col,
  Spin,
  Radio,
} from "antd";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../Components/CustomButton";
import COLORS from "../../Style/colors";
import { useNavigate } from "react-router-dom";
import {
  addFoodicsInformation,
  getBranchData,
  getFoodicsInformation,
  getRestaurantData,
} from "../../API/fetch";
import openNotification from "../../Components/Notifications";
import { logOutAction } from "../../redux/reducers/authReducer";
import { setRestaurantAction } from "../../redux/reducers/restaurantReducer";
import { setMenuAction } from "../../redux/reducers/menuReducer";
import {
  setBranchAction,
  setBranchesAction,
} from "../../redux/reducers/branchReducer";
import { setTablesAction } from "../../redux/reducers/tableReducer";
import {
  setDeliveryOrdersAction,
  setDineInOrdersAction,
  setIwaiterAppOrdersAction,
  setPickupOrdersAction,
} from "../../redux/reducers/orderReducer";

const { Title,  } = Typography;
const { Option } = Select;

const NewFoodicsView = () => {
  const intl = useIntl();
  const history = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { waiter,  } = useSelector((state) => state.auth);
  const { rtl } = useSelector((state) => state.settings);
  const { foods } = useSelector((state) => state.menu);
  const { allBranches } = useSelector((state) => state.branch);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [foodicsBranches, setFoodicsBranches] = useState([]);
  const [tablesLength, setTablesLength] = useState(0);

  useEffect(() => {
    getFoodicsData();
  }, []);

  const getFoodicsData = async () => {
    try {
      const res = await getFoodicsInformation();
      setFoodicsBranches(res.data.branches);
      setInitialLoading(false);
    } catch (error) {
      setInitialLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    setInitialLoading(true);
    try {
      const newTables = [];
      let foodicsTables = [];
      const selected = foodicsBranches?.find(
        (b) => b.foodicsBranchId === values.branch
      );
      if (selected && selected?.foodicsBranchSections?.length) {
        selected.foodicsBranchSections.forEach((sec) => {
          if (sec?.tables?.length > 0) {
            let zone = sec.sectionName;
            sec.tables.forEach((t) => {
              const oneTable = {
                name: t.name,
                name_en: t.name,
                zone,
                owner: allBranches[0].branch._id,
              };
              newTables.push(oneTable);
              foodicsTables.push({
                name: t.name,
                foodicsId: t.id,
                sectionId: sec.sectionId,
              });
            });
          }
        });
      }

      const data = {
        ...values,
        foodicsBranch: values.branch,
        newTables,
        localBranch: allBranches[0].branch._id,
        foodicsTables,
      };

      const res = await addFoodicsInformation(data);

      if (res.data.status === "success") {
        getInitialData();
      } else {
        dispatch(logOutAction());
      }
    } catch (error) {
      setLoading(false);
      setInitialLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const getInitialData = async () => {
    try {
      const restaurantRes = await getRestaurantData();
      const branchRes = await getBranchData(waiter.code);
      dispatch(setRestaurantAction(restaurantRes.data.restaurant));
      dispatch(setMenuAction(restaurantRes.data.menu));
      dispatch(setBranchesAction(branchRes.data.branches));
      if (branchRes.data.isSuper === false) {
        dispatch(setBranchAction(branchRes.data.branches[0]));
      }
      dispatch(setTablesAction(branchRes.data.tables));
      dispatch(setDineInOrdersAction(branchRes.data.orders));
      dispatch(setPickupOrdersAction(branchRes.data.pickupOrders));
      dispatch(setDeliveryOrdersAction(branchRes.data.deliveryOrders));
      dispatch(setIwaiterAppOrdersAction(branchRes.data.iwaiterAppOrders));
      setLoading(false);
      setInitialLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      history('/integration')
    } catch (error) {
      console.log("error", error);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
      dispatch(logOutAction());
      setInitialLoading(false);
      setLoading(false);
    }
  };

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      {/* Modals */}

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <Title>{intl.formatMessage({ id: "foodics" })}</Title>
      </div>

      <div
        style={{
          backgroundColor: COLORS.white,
          marginTop: 10,
          width: "92%",
          marginRight: "auto",
          marginLeft: "auto",
          borderRadius: 10,
        }}
        className='light-shadow'
      >
        <div className='px-5 py-5'>
          <Spin spinning={initialLoading}>
            <Form
              name='new-foodics'
              form={form}
              preserve={false}
              className='form-container'
              layout='vertical'
              onFinish={onFinish}
              initialValues={{
                branch: "",
                addTables: "TRUE",
                addFoodicsMenu: "TRUE",
              }}
            >
              {() => {
                return (
                  <>
                    <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                      {/* Menu engineer */}
                      <Col md={12} sm={24} xs={24}>
                        <Title className='my-font' level={4}>
                          {intl.formatMessage({ id: "foodicsInfo" })}
                        </Title>
                      </Col>
                    </Row>
                    <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                      <Col md={12} sm={12} xs={12}>
                        <Form.Item
                          label={intl.formatMessage({ id: "foodicsBranch" })}
                          className='mb-1'
                          name='branch'
                          rules={[
                            {
                              required: true,
                            }
                          ]}
                        >
                          <Select
                            className='my-font'
                            onChange={(id) => {
                              const bra = foodicsBranches.find(
                                (b) => b.foodicsBranchId === id
                              );
                              let tableLength = 0;
                              if (bra && bra?.foodicsBranchSections?.length) {
                                bra?.foodicsBranchSections.forEach(
                                  (section) =>
                                    (tableLength += section.tables.length)
                                );
                              }
                              setTablesLength(tableLength);
                            }}
                          >
                            <Option className='my-font' value=''>
                              {intl.formatMessage({ id: "choose" })}
                            </Option>
                            {foodicsBranches?.map((branch) => {
                              return (
                                <Option
                                  key={branch.foodicsBranchId}
                                  className='my-font'
                                  value={branch.foodicsBranchId}
                                >
                                  {branch.foodicsBranchName} -{" "}
                                  {branch.foodicsBranchReference}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col md={12} sm={12} xs={12}>
                        <Form.Item
                          name='addTables'
                          label={`${intl.formatMessage({
                            id: "youHaveTable",
                          })} - ${
                            tablesLength ? tablesLength : "x"
                          } ${intl.formatMessage({
                            id: "table",
                          })} ${intl.formatMessage({
                            id: "deleteAllTableAndAddFoodics",
                          })}`}
                          disabled={form.getFieldValue("branch") === ""}
                        >
                          <Radio.Group>
                            <Radio value='TRUE' disabled={tablesLength === 0}>
                              {intl.formatMessage({ id: "yes" })}
                            </Radio>
                            <Radio value='FALSE' disabled={tablesLength === 0}>
                              {intl.formatMessage({ id: "no" })}
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                      <Col md={12} sm={12} xs={12}>
                        <Form.Item
                          name='addFoodicsMenu'
                          label={
                            foods?.length
                              ? `${intl.formatMessage({
                                  id: "youHaveFood",
                                })}  ${foods.length} ${intl.formatMessage({
                                  id: "category",
                                })} ${intl.formatMessage({
                                  id: "deleteAllFoodsAndAddFoodicsMenu",
                                })}`
                              : intl.formatMessage({ id: "addFoodicsMenu" })
                          }
                          disabled={form.getFieldValue("branch") === ""}
                        >
                          <Radio.Group>
                            <Radio value='TRUE'>
                              {intl.formatMessage({ id: "yes" })}
                            </Radio>
                            <Radio value='FALSE'>
                              {intl.formatMessage({ id: "no" })}
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Divider />
                    <Space direction='horizontal'>
                      <Form.Item>
                        <CustomButton
                          htmlType='submit'
                          text={intl.formatMessage({ id: "save" })}
                          className={`btnRegister btn-text border-8`}
                          loading={loading}
                          disabled={loading}
                          type='primary'
                        />
                      </Form.Item>

                      <Form.Item>
                        <CustomButton
                          type='text'
                          text={intl.formatMessage({ id: "cancel" })}
                          disabled={loading}
                          onClick={() => {
                            history("/integration");
                          }}
                        />
                      </Form.Item>
                    </Space>
                  </>
                );
              }}
            </Form>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default NewFoodicsView;
