import React, { useState, useEffect, useRef } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import { getAddressText } from "../../../API/fetch";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Modal } from "antd";
const containerStyle = {
  width: "90%",
  height: "40vh",
};

function MapModal({
  location,
  setLocation,
  urlLocation,
  setUrlLocation,
  setCityName,
  show,
  hide,
  disabled = false,
}) {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  const searchBoxRef = useRef(null);
  // const [loading, setLoading] = useState(false);
  const [center, setCenter] = useState({
    lat: location.lat ? location.lat : 24.761654,
    lng: location.lng ? location.lng : 46.657367,
  });

  useEffect(() => {
    if (location.lat && location.lng) {
      setCenter({
        lat: location.lat,
        lng: location.lng,
      });
    }
  }, [location]);

  return (
    <Modal
      title={intl.formatMessage({ id: "chooseLocation" })}
      centered
      visible={show}
      destroyOnClose={true}
      mask={true}
      maskClosable={true}
      onCancel={() => hide()}
      disabled={disabled}
      okText={intl.formatMessage({ id: disabled ? "back" : "save" })}
      cancelText={intl.formatMessage({ id: "cancel" })}
      onOk={async () => {
        if (disabled) {
          hide();
          return;
        }
        // setLoading(true);
        const { lat, lng } = center;
        let formateAddress = "";
        try {
          const res = await getAddressText(lat, lng);
          formateAddress = res?.data?.results?.[0]?.formatted_address;
          let city = "";
          if (res.data?.results?.length) {
            res.data.results.forEach((r) => {
              if (r.types?.includes("locality")) {
                if (r.address_components?.length) {
                  r.address_components.forEach((c) => {
                    if (c.types?.includes("locality")) {
                      city = c.long_name;
                    }
                  });
                }
              }
            });
          }
          setLocation({ ...location, lat, lng, formateAddress });
          setCityName(city);
          // setLoading(false);
          hide();
        } catch (error) {
          // setLoading(false);
          console.log(error);
        }
      }}
    >
      <LoadScript
        googleMapsApiKey='AIzaSyDR59W-TaZEyeJY7aNcXZfULvShQO3RzM0'
        language={rtl ? "ar" : "en"}
        libraries={["places"]}
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          clickableIcons={false}
          center={center}
          zoom={18}
          onClick={(e) => {
            if (disabled) return;
            setCenter({ lat: e.latLng.lat(), lng: e.latLng.lng() });
            setUrlLocation("");
          }}
        >
          <StandaloneSearchBox
            onLoad={(ref) => (searchBoxRef.current = ref)}
            onPlacesChanged={() => {
              if (searchBoxRef.current) {
                const places = searchBoxRef.current.getPlaces();
                if (places.length === 0) {
                  return;
                }
                let lat = places[0].geometry.location.lat();
                let lng = places[0].geometry.location.lng();
                setCenter({ lat, lng });
                if (places[0]?.url) {
                  setUrlLocation(places[0].url);
                }
              }
            }}
          >
            <input
              type='text'
              placeholder='Search for an address .. ابحث عن مطعمك'
              style={{
                boxSizing: `border-box`,
                border: `1px solid #cccccc`,
                width: `95%`,
                marginRight: "auto",
                marginLeft: "auto",
                marginTop: 2,
                height: `32px`,
                padding: `0 12px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                textOverflow: `ellipses`,
                position: "absolute",
              }}
            />
          </StandaloneSearchBox>
          <Marker position={{ lat: center.lat, lng: center.lng }}></Marker>
        </GoogleMap>
      </LoadScript>
    </Modal>
  );
}

export default MapModal;
