import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18.461}
    height={14.716}
    {...props}
  >
    <g data-name="Group 6" fill={props?.fill || "#0a2240"}>
      <path d="M8.536 14.176C8.961 11.857 9.411 3.426.563.798l7.973 13.378M.502.781h-.01s-3.379 11.82 7.577 13.939L.503.784" />
      <path d="M9.108 13.571c-.333-2.435-.4-11.236 8.939-13.552L9.108 13.571M18.109.002h.011s2.957 12.47-8.553 14.155L18.109.004" />
    </g>
  </svg>
)

export default SvgComponent
