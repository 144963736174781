import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={11.636}
    height={22.605}
    {...props}
  >
    <path
      d="M11.636 9.156a5.629 5.629 0 0 0-5.585 4.933V9.973a5.629 5.629 0 0 0 5.585-5.628 5.629 5.629 0 0 0-5.585 4.932V7.712A5.628 5.628 0 0 0 5.818.001a5.629 5.629 0 0 0-.233 7.712v1.565A5.629 5.629 0 0 0 0 4.345a5.629 5.629 0 0 0 5.585 5.628v4.117A5.629 5.629 0 0 0 0 9.157a5.628 5.628 0 0 0 5.585 5.628v4.117A5.629 5.629 0 0 0 0 13.969a5.629 5.629 0 0 0 5.585 5.628v2.6c0 .258.1.409.233.409s.233-.151.233-.409v-2.6a5.629 5.629 0 0 0 5.585-5.628 5.629 5.629 0 0 0-5.585 4.933v-4.116a5.628 5.628 0 0 0 5.585-5.628"
      fill={props?.fill || "#0a2240"}
    />
  </svg>
)

export default SvgComponent
