import React, { useEffect, useState } from "react";
import { Row, Col, Space, Tag, Typography, Collapse, Popconfirm } from "antd";
import {
  ORDER_TYPES,
  TAG_COLORS_BY_PAYMENT_STATUS,
  TAG_COLORS_BY_STATUS,
  TAG_COLORS_BY_STATUS_PICKUP,
  TAG_COLORS_BY_STATUS_DELIVERY,
  TAG_COLORS_BY_PAYMENT_REFUND_STATUS,
} from "../../../Types";
import { useIntl } from "react-intl";
import { CaretRightOutlined } from "@ant-design/icons";
import CustomButton from "../../CustomButton";
import MapModal from "../../../Views/Branches/Components/MapModal";
import { useSelector } from "react-redux";
import COLORS from "../../../Style/colors";
import { trackOrder } from "../../../API/fetch";
import MapModalTrack from "./MapModalTrack";
import PLACEHOLDER from "./../../../assets/images/icon-white.png";
const { Text, Paragraph } = Typography;
const { Panel } = Collapse;

const DeliveryHeader = ({
  orderNumber,
  status,
  createdAt,
  total,
  discountAmount,
  finalTotal,
  paymentStatus,
  orderId,
  userPhone,
  userName,
  orderType,
  deliveryFees,
  location,
  addressName,
  address,
  posData,
  refundPayment,
  role,
  refundPaymentInfo,
  deliveryId,
  walletAmount,
}) => {
  const intl = useIntl();
  const [showMap, setShowMap] = useState(false);
  const { integrations, logo } = useSelector((state) => state.restaurant);
  const [loading, setLoading] = useState(false);
  const [deliveryInfo, setDeliveryInfo] = useState(null);
  useEffect(() => {
    if (deliveryId && deliveryId?.deliveryOrderId) {
      // get delivery status;
      trackDelivery(deliveryId?.deliveryOrderId, deliveryId?.pickupOrderId);
    }
  }, [deliveryId]);
  const trackDelivery = async (otherOrderId, localOrderId) => {
    try {
      setLoading(true);
      const res = await trackOrder(otherOrderId, localOrderId);
      if (res?.data) {
        // convert the strings to numbers;
        let rest = res.data.restaurant;
        rest.lat = parseFloat(rest.lat);
        rest.lng = parseFloat(rest.lng);
        let cust = res.data.customer;
        cust.lat = parseFloat(cust.lat);
        cust.lng = parseFloat(cust.lng);
        let driv = res.data.driver;
        if(driv?.lat && driv?.lng){
          driv.lat = parseFloat(driv.lat);
          driv.lng = parseFloat(driv.lng);
        }
        res.data.restaurant = rest;
        res.data.customer = cust;
        res.data.driver = driv;
      }
      setDeliveryInfo(res.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  return (
    <Row>
      <MapModalTrack
        show={showMap}
        hide={() => setShowMap(false)}
        restaurant={deliveryInfo?.restaurant}
        driver={deliveryInfo?.driver}
        customer={deliveryInfo?.customer}
        logo={logo?.[0]?.url ? logo?.[0]?.url : PLACEHOLDER}
        isTracking={deliveryInfo?.customer ? true : false}
        locationInfo={location}
      />
      <Col md={12}>
        <Space direction='vertical' size={0}>
          <Space direction='horizontal' size={"small"}>
            <Text className='my-font'>
              {`${intl.formatMessage({ id: "orderNo" })}: ${orderNumber}`}
            </Text>
            <Tag
              color={
                orderType === ORDER_TYPES.DINE_IN
                  ? TAG_COLORS_BY_STATUS[status]
                  : orderType === ORDER_TYPES.PICKUP
                  ? TAG_COLORS_BY_STATUS_PICKUP[status]
                  : TAG_COLORS_BY_STATUS_DELIVERY[status]
              }
              className='my-font'
            >
              {intl.formatMessage({
                id: status === "close" ? "closedOrder" : status,
              })}
            </Tag>
          </Space>
          <Text type='secondary' className='my-font' style={{ fontSize: 14 }}>
            {`${intl.formatMessage({ id: "time" })}: ${new Date(
              createdAt
            ).toLocaleString()}`}
          </Text>

          {userName ? (
            <Collapse
              ghost
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
            >
              <Panel header={intl.formatMessage({ id: "userInfo" })}>
                <div>
                  <Text className='my-font'>
                    {intl.formatMessage({ id: "customerName" })}
                    {" : "}
                    <Tag className='border-5'>{userName}</Tag>
                  </Text>
                  <Paragraph
                    style={{ marginBottom: 3, marginTop: 3 }}
                    copyable={{
                      tooltips: false,
                    }}
                    className='my-font'
                  >
                    {intl.formatMessage({ id: "customerPhone" })}
                    {" : "}
                    <Tag className='border-5'>
                      {" "}
                      {userPhone?.length > 4 ? userPhone : ""}
                    </Tag>
                  </Paragraph>
                  <Paragraph
                    style={{ marginTop: 0 }}
                    copyable={{
                      text: `http://maps.google.com/?q=${location?.lat},${location?.lng}`,
                      tooltips: false,
                    }}
                    className='my-font'
                  >
                    {intl.formatMessage({ id: "customerLocation" })}
                    {" : "}
                    <CustomButton
                      text={intl.formatMessage({ id: "view" })}
                      type='text'
                      style={{ marginTop: 0, marginBottom: 0 }}
                      onClick={() => setShowMap(true)}
                    />
                  </Paragraph>
                </div>
              </Panel>
            </Collapse>
          ) : null}
          {deliveryInfo?.customer && !loading ? (
            <Collapse
              ghost
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
            >
              <Panel header={intl.formatMessage({ id: "driverInfo" })}>
                <div>
                  <Text className='my-font'>
                    {intl.formatMessage({ id: "justName" })}
                    {" : "}
                    <Tag className='border-5'>
                      {deliveryInfo?.driverInfo?.name}
                    </Tag>
                  </Text>
                  <Paragraph
                    style={{ marginBottom: 3, marginTop: 3 }}
                    copyable={{
                      tooltips: false,
                      text: deliveryInfo?.driverInfo?.phone,
                    }}
                    className='my-font'
                  >
                    {intl.formatMessage({ id: "phone" })}
                    {" : "}
                    <Tag className='border-5'>
                      <a
                        href={`tel:${deliveryInfo?.driverInfo?.phone}`}
                        style={{
                          fontWeight: "bold",
                        }}
                        target='_blank'
                      >
                        {deliveryInfo?.driverInfo?.phone}
                      </a>
                    </Tag>
                  </Paragraph>

                  <CustomButton
                    text={intl.formatMessage({ id: "track" })}
                    type='secondary'
                    style={{ marginTop: 5, marginBottom: 0, borderRadius: 5 }}
                    onClick={() => setShowMap(true)}
                  />
                  {/* </Paragraph> */}
                </div>
              </Panel>
            </Collapse>
          ) : null}
        </Space>
      </Col>
      <Col md={12}>
        <Space direction='vertical' size={0}>
          <Text className='my-font'>
            {`${intl.formatMessage({ id: "total" })}: ${total.toFixed(2)}`}
          </Text>
          <Text className='my-font'>
            {`${intl.formatMessage({
              id: "discount",
            })}: ${discountAmount?.toFixed(2)}`}
          </Text>
          <Text className='my-font'>
            {`${intl.formatMessage({
              id: "delivery",
            })}: ${deliveryFees?.toFixed(2)}`}
          </Text>
          <Space direction='horizontal' size={"small"}>
            <Text className='my-font'>
              {`${intl.formatMessage({
                id: "totalAfterDiscount",
              })}: ${finalTotal}`}
            </Text>
            <Tag
              color={TAG_COLORS_BY_PAYMENT_STATUS[paymentStatus]}
              className='my-font'
            >
              {intl.formatMessage({ id: paymentStatus })}
            </Tag>
          </Space>
          {role === "super" && paymentStatus === "paid" ? (
            <Space direction='horizontal' size={"small"}>
              {refundPaymentInfo?.status === "REFUNDED" ||
              refundPaymentInfo?.status ===
                "Refunded" ? null : refundPaymentInfo?._id ? (
                <CustomButton
                  type='primary'
                  text={intl.formatMessage({ id: "refresh" })}
                  onClick={refundPayment}
                  // disabled={loading}
                  // loading={loading}
                />
              ) : (
                <Popconfirm
                  title={
                    <div
                      style={{
                        maxWidth: 300,
                      }}
                    >
                      <Text className='my-font'>
                        {intl.formatMessage({ id: "refundPaymentDes" })}
                      </Text>
                    </div>
                  }
                  okText={intl.formatMessage({ id: "refundPayment" })}
                  okButtonProps={{
                    style: { color: COLORS.danger, fontFamily: "Cairo" },
                  }}
                  okType='text'
                  cancelText={intl.formatMessage({ id: "cancel" })}
                  cancelButtonProps={{
                    style: { border: "none", fontFamily: "Cairo" },
                  }}
                  onConfirm={refundPayment}
                  // disabled if role is not super or the created At is more than 3 hours ago;
                  disabled={
                    role !== "super" ||
                    new Date(createdAt) < Date.now() - 1000 * 60 * 60 * 3||
                    walletAmount > 0
                  }
                >
                  <Text
                    disabled={
                      role !== "super" ||
                      new Date(createdAt) < Date.now() - 1000 * 60 * 60 * 3 ||
                      walletAmount > 0

                    }
                    type='danger'
                    className='pointer my-font'
                  >
                    {intl.formatMessage({
                      id: refundPaymentInfo?._id ? "refresh" : "refundPayment",
                    })}
                  </Text>
                </Popconfirm>
              )}
              {refundPaymentInfo?._id ? (
                <Tag
                  color={
                    TAG_COLORS_BY_PAYMENT_REFUND_STATUS[
                      refundPaymentInfo?.status
                    ]
                  }
                  className='my-font'
                >
                  {intl.formatMessage({ id: refundPaymentInfo?.status || "" })}
                </Tag>
              ) : null}
            </Space>
          ) : null}
          {posData && posData?.otherNu ? (
            <Text className='my-font'>
              {intl.formatMessage({ id: "orderNumberInPOS" })}{" "}
              <Text className='my-font'>
                {intl.formatMessage({
                  id: integrations?.posName ? integrations?.posName : "N/A",
                })}{" "}
                :{" "}
              </Text>
              <Tag color={"purple"} className='my-font'>
                {posData?.otherNu}
              </Tag>
            </Text>
          ) : null}
        </Space>
      </Col>
    </Row>
  );
};

export default DeliveryHeader;
