import React, { useState, useEffect } from "react";
import { Space, Popover, Popconfirm, Typography, Tag } from "antd";
import CustomButton from "../../../Components/CustomButton";
import { useIntl } from "react-intl";
import QRCode from "qrcode.react";
import { useDispatch, useSelector } from "react-redux";
import { postUpdateUser, sendWaitingWhatsapp } from "../../../API/fetch";
import { setBranchAction } from "../../../redux/reducers/branchReducer";
import openNotification from "../../../Components/Notifications";
import COLORS from "../../../Style/colors";
import { IoNotificationsOutline } from "react-icons/io5";

const { Text } = Typography;

const WaitingUserActions = ({ record, type, user }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { role } = useSelector((state) => state.auth);
  const { rtl } = useSelector((state) => state.settings);
  const { branch } = useSelector((state) => state.branch);
  const [loading, setLoading] = useState(false);
  const [canSendWhatsapp, setCanSendWhatsapp] = useState(false);
  useEffect(() => {
    if (
      type === "Active" &&
      role !== "super" &&
      user?.phone &&
      user?.name &&
      user?.phone?.length > 3 &&
      branch?.sendWhatsappData?.status === "Active" &&
      branch?.branchDialCode &&
      branch?.branchPhone
      // user?.didSent === false
    ) {
      setCanSendWhatsapp(true);
    }
    return () => setCanSendWhatsapp(false);
  }, [record, type, user, branch]);

  const handelEditUser = async ({ id, status }) => {
    try {
      setLoading(true);
      const data = {
        status,
      };
      const res = await postUpdateUser(id, data);
      const newBranch = {
        ...branch,
        waitingList: res.data.waitingList,
      };
      dispatch(setBranchAction(newBranch));
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const handleSendWhatsapp = async () => {
    try {
      setLoading(true);

      const data = {
        user,
      };
      const res = await sendWaitingWhatsapp(data);

      if (res.data.status === "success") {
        openNotification({
          title: (
            <Text className='my-font'>
              {intl.formatMessage({ id: "sentNotificationSuccessfully" })}
            </Text>
          ),
          type: "success",
          rtl,
        });
        setCanSendWhatsapp(false);
      } else {
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "errorfetch" }),
          type: "error",
          rtl,
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  return (
    <Space
      size='small'
      style={{
        minWidth: 100,
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
      }}
    >
      {canSendWhatsapp ? (
        <Popconfirm
          title={
            <Text className='my-font'>
              {intl.formatMessage({ id: "areYouSureSendWhatsapp" })}
            </Text>
          }
          okText={
            <Tag
              color={COLORS.primary}
              style={{ paddingTop: 2, paddingBottom: 2 }}
              className='pointer my-font border-5 '
            >
              {intl.formatMessage({ id: "yesSend" })}
            </Tag>
          }
          okButtonProps={{
            style: { color: COLORS.danger, fontFamily: "Cairo" },
          }}
          okType='text'
          cancelText={intl.formatMessage({ id: "cancel" })}
          cancelButtonProps={{ style: { border: "none", fontFamily: "Cairo" } }}
          onConfirm={handleSendWhatsapp}
          disabled={loading || user?.didSent || !canSendWhatsapp}
          loading={loading}
        >
          <Tag
            color={user?.didSent ? COLORS.gray : COLORS.primary}
            style={{
              paddingTop: 2,
              paddingBottom: 2,
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
            className={`my-font border-5 ${
              user?.didSent ? "disabled" : "pointer"
            }`}
          >
            {intl.formatMessage({ id: "send" })}
            <IoNotificationsOutline
              size={15}
              style={{ marginRight: rtl ? 5 : 0, marginLeft: rtl ? 0 : 5 }}
            />
          </Tag>
        </Popconfirm>
      ) : null}
      <Popover
        content={
          <QRCode
            bgColor={"#000000"}
            fgColor={"#ffffff"}
            level='H'
            style={{ width: 150 }}
            value={`tel:00${record.userObject.phone}`}
          />
        }
        title=''
        trigger='click'
      >
        <CustomButton type='text' text='QR' />
      </Popover>
      <CustomButton
        type='text'
        disabled={loading}
        loading={loading}
        style={{ color: type === "Active" ? COLORS.primary : COLORS.danger }}
        text={intl.formatMessage({ id: type === "Active" ? "done" : "undo" })}
        onClick={() =>
          handelEditUser({
            id: record.userObject._id,
            status: type === "Active" ? "served" : "Active",
          })
        }
      />
      {type === "Active" ? (
        <Popconfirm
          title={
            <Text className='my-font'>
              {intl.formatMessage({ id: "confirmDelete" })}
            </Text>
          }
          okText={intl.formatMessage({ id: "delete" })}
          okButtonProps={{
            style: { color: COLORS.danger, fontFamily: "Cairo" },
          }}
          okType='text'
          cancelText={intl.formatMessage({ id: "cancel" })}
          cancelButtonProps={{ style: { border: "none", fontFamily: "Cairo" } }}
          onConfirm={() =>
            handelEditUser({ id: record.userObject._id, status: "deleted" })
          }
          disabled={loading}
          loading={loading}
        >
          <Text type='danger' className='pointer my-font'>
            {intl.formatMessage({ id: "delete" })}
          </Text>
        </Popconfirm>
      ) : null}
    </Space>
  );
};

export default WaitingUserActions;
