import React, { useState, useEffect } from "react";
import { Result, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useIntl } from "react-intl";
import RestaurantForm from "./Components/RestaurantForm";
import IWaiterHelper from "./Components/IWaiterHelper";
import { postAddNewRestaurant } from "../../API/fetch";
import { setRestaurantAction } from "../../redux/reducers/restaurantReducer";
import { setBranchAction, setBranchesAction } from "../../redux/reducers/branchReducer";
import { setTablesAction } from "../../redux/reducers/tableReducer";
import openNotification from "../../Components/Notifications";
import RestaurantList from "./Components/RestaurantList";

const AddRestaurant = () => {
  const [screen, setScreen] = useState("IWHelper");
  const intl = useIntl();
  const history = useNavigate();
  const [result, setResult] = useState(null);
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState(null);
  const [loadingAddRestaurant, setLoadingAddRestaurant] = useState(false);
  const { rtl } = useSelector((state) => state.settings);


  useEffect(() => {
    if (!formValues?.name) {
      setScreen("RestaurantForm");
    }
  }, [formValues]);

  const addManualRestaurant = async (name, name_en, city, email) => {
    try {
      let data;
      if (name && name_en) {
        data = {
          restaurant: {
            name: name,
            name_en: name_en,
            city: city,
            email: email
          },
        };
      } else {
        data = {
          restaurant: {
            name: formValues.name,
            name_en: formValues.nameEn,
            city: formValues.city,
            email: formValues.email
          },
        };
      }
      const res = await postAddNewRestaurant(data);

      dispatch(setRestaurantAction(res.data.restaurant));
      dispatch(setBranchesAction(res.data.branches));
      dispatch(setBranchAction(res.data?.branches?.[0]?.branch));
      dispatch(setTablesAction(res.data.tables));
      setLoadingAddRestaurant(false);
      history("/");
    } catch (error) {
      console.log("Error in add manu, error", error)
      setLoadingAddRestaurant(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };




  return (
    <div>
      {screen === "RestaurantForm" ? (
        <RestaurantForm
          setResult={setResult}
          showIWHelper={() => setScreen("IWHelper")}
          loading={loadingAddRestaurant}
          setLoading={setLoadingAddRestaurant}
          addManualRestaurant={addManualRestaurant}
          setFormValues={setFormValues}
        />
      ) : screen === "IWHelper" ? (
        <IWaiterHelper
          showRestaurantList={() => setScreen("RestaurantList")}
          addManualRestaurant={addManualRestaurant}
        />
      ) : screen === "RestaurantList" ? (
        <RestaurantList result={result} loading={loadingAddRestaurant}
          setLoading={setLoadingAddRestaurant} addManualRestaurant={addManualRestaurant} />
      ) : (
        <Result
          status='404'
          title='404'
          subTitle={intl.formatMessage({ id: "pageError" })}
          extra={
            <Button type='primary' onClick={() => setScreen("SignIn")}>
              {intl.formatMessage({ id: "goBack" })}
            </Button>
          }
        />
      )}
    </div>
  );
};

export default AddRestaurant;
