import React, { useEffect, useState } from "react";
import { Space, Typography, Empty, Collapse, Table, Row, Col } from "antd";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import ModifierCard from "./ModifierCard";
import { MinusCircleTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import { IoArrowBackOutline, IoArrowForwardOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;
const { Panel } = Collapse;

function ReservationModifier() {
  const intl = useIntl();
  const history = useNavigate();
  const { rtl } = useSelector((state) => state.settings);
  const { reservationModifier } = useSelector((state) => state.restaurant);
  const [activeKey, setActiveKey] = useState("");
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    if (reservationModifier?.length) {
      setDataSource(
        reservationModifier?.map((mod, index) => {
          return {
            key: mod?._id,
            _id: mod?._id,
            name: rtl ? mod?.name : mod?.name_en,
            index: index,
            modifier: mod,
          };
        })
      );
    }
  }, [reservationModifier]);

  const columns = [
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "name",
      key: "name",
    },
  ];

  return (
    <>
      <div className='tables-card-container' style={{ position: "relative" }}>
        <div
          style={
            rtl
              ? {
                  position: "absolute",
                  zIndex: 2,
                }
              : {
                  position: "absolute",
                  zIndex: 2,
                }
          }
        >
          <Title level={3} className='pointer' onClick={() => history(-1)}>
            {rtl ? (
              <IoArrowForwardOutline className='pointer' />
            ) : (
              <IoArrowBackOutline className='pointer' />
            )}
          </Title>
        </div>
        <Row
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Col xs={24}>
            <Title level={2} style={{ textAlign: "center" }}>
              {intl.formatMessage({ id: "reservationModifier" })}
            </Title>
          </Col>
        </Row>

        {reservationModifier?.length ? (
          <div className='mb-3 mt-5'>
            <Table
              className='components-table-demo-nested light-shadow'
              pagination={false}
              dataSource={dataSource}
              columns={columns}
              expandable={{
                expandedRowRender: (data, index, _, expanded) =>
                  expanded ? <ModifierCard modifier={data?.modifier} /> : null,
                expandIcon: ({ expanded, onExpand, record }) =>
                  expanded ? (
                    <MinusCircleTwoTone onClick={(e) => onExpand(record, e)} />
                  ) : (
                    <PlusCircleTwoTone onClick={(e) => onExpand(record, e)} />
                  ),
              }}
              rowKey='index'
            />
            <Collapse
              bordered={false}
              accordion={true}
              onChange={(key) => setActiveKey(key)}
              className='border-8 light-shadow'
              destroyInactivePanel={false}
            >
              <Panel
                showArrow={false}
                style={{ marginTop: 10 }}
                header={
                  <Space direction='horizontal' align='center' size='middle'>
                    {activeKey === "New" ? (
                      <MinusCircleTwoTone />
                    ) : (
                      <PlusCircleTwoTone />
                    )}

                    <Title level={5} className='my-font'>
                      {intl.formatMessage({ id: "addNewModifier" })}
                    </Title>
                  </Space>
                }
                key={"New"}
              >
                <ModifierCard modifier={null} />
              </Panel>
            </Collapse>
          </div>
        ) : (
          <div className='mt-4'>
            <Collapse
              bordered={false}
              accordion={true}
              onChange={(key) => setActiveKey(key)}
              className='border-8 light-shadow'
              destroyInactivePanel={false}
            >
              <Panel
                showArrow={false}
                style={{ marginTop: 10 }}
                header={
                  <Space direction='horizontal' align='center' size='middle'>
                    {activeKey === "New" ? (
                      <MinusCircleTwoTone />
                    ) : (
                      <PlusCircleTwoTone />
                    )}

                    <Title level={5} className='my-font'>
                      {intl.formatMessage({ id: "addNewModifier" })}
                    </Title>
                  </Space>
                }
                key={"New"}
              >
                <ModifierCard modifier={null} />
              </Panel>
            </Collapse>
            <Empty
              image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
              imageStyle={{
                height: 250,
              }}
              description={
                <h2 className='my-font'>
                  {intl.formatMessage({ id: "noModifiers" })}
                </h2>
              }
            ></Empty>
          </div>
        )}
      </div>
    </>
  );
}

export default ReservationModifier;
