import React, { useState } from "react";
import { Col, Row, Image, Typography,  Space } from "antd";
import { useIntl } from "react-intl";
import ICON from "../../../assets/images/icon-white.png";
import CustomButton from "../../../Components/CustomButton";
import {  useSelector } from "react-redux";
import Typist from "react-typist";

const { Text,  Title } = Typography;

const IWaiterHelper = ({  addManualRestaurant,  showRestaurantList }) => {
  const intl = useIntl();
  const { user } = useSelector((state) => state.auth);
  const [showButtons, setShowButtons] = useState(false);

  return (
    <Row
      style={{ minHeight: "100vh", paddingTop: 40 }}
      className='add-restaurant-container'
      justify='center'
      align='top'
    >
      <Col
        xs={24}
        sm={20}
        type='flex'
        justify='center'
        align='center'
        direction='column'
        className={"authRightSide"}
      >
        <Row justify='center'>
          <Image
            preview={false}
            src={ICON}
            style={{ maxWidth: 50, marginBottom: 50, borderRadius: 30 }}
          />
          <Title className='mx-2'>iwaiter</Title>
        </Row>
        <Title>
          <Typist
            onTypingDone={() => setShowButtons(true)}
            startDelay={1000}
            cursor={{ show: false }}
            avgTypingDelay={20}
          >
            <h3>
              {" "}
              {intl.formatMessage({ id: "hello" })} <span>{user?.name}</span>.
            </h3>
            <Typist.Delay ms={600} />

            <h4 className=' mx-2'>{intl.formatMessage({ id: "hiMessage" })}</h4>
            <Typist.Delay ms={600} />
            <h1 className='mt-2 mx-2'>....</h1>
            <Typist.Backspace count={4} delay={600} />
            <Typist.Delay ms={600} />
            <h1 className='mt-2 mx-2'>....</h1>
            <Typist.Backspace count={4} delay={600} />
            <Typist.Delay ms={600} />
            <h3 className=''>
              {intl.formatMessage({ id: "searchForYou" })}{" "}
              <span>{intl.formatMessage({ id: "social" })}</span>,{" "}
              <span>{intl.formatMessage({ id: "google" })} </span>{" "}
              {intl.formatMessage({ id: "otherPlaces" })} .
            </h3>
            <Typist.Delay ms={700} />
            <h3 className='mt-5'>{intl.formatMessage({ id: "DoYouWant" })}</h3>
          </Typist>
        </Title>
        {showButtons ? (
          <Space align='center' direction='vertical'>
            <CustomButton
              onClick={showRestaurantList}
              className='border-8 px-5'
              type={"primary"}
              text={intl.formatMessage({ id: "yeshelp" })}
            />
            <Text>{intl.formatMessage({ id: "agreeTerms" })} .</Text>

            <CustomButton
              onClick={addManualRestaurant}
              className='border-8 px-5'
              type={"light"}
              text={intl.formatMessage({ id: "nothx" })}
            />
          </Space>
        ) : null}
      </Col>
    </Row>
  );
};

export default IWaiterHelper;
