import React, { useState } from "react";
import {
  Modal,
  Form,
  Input,
  InputNumber,
  DatePicker,
  Typography,
  Divider,
  Space,
  Row,
  Col,
  Checkbox,
  Select,
  TreeSelect,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import CustomButton from "../../../Components/CustomButton";
import openNotification from "../../../Components/Notifications";
import moment from "moment";
import { postAddNewCoupon } from "../../../API/fetch";
import { addCouponAction } from "../../../redux/reducers/restaurantReducer";

const { Text } = Typography;
const { Option } = Select;
const { SHOW_ALL } = TreeSelect;

const AddCoupon = ({ show, hide }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const { waiter } = useSelector((state) => state.auth);
  const { categories, foods } = useSelector((state) => state.menu);

  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [form] = Form.useForm();

  const addNewCoupon = async (values) => {
    try {
      let startDate = moment(values.startDate).startOf("day").toISOString();
      let endDate = moment(values.endDate).startOf("day").toISOString();
      setLoading(true);
      const data = {
        coupon: {
          ...values,
          startDate,
          endDate,
          couponCategories: selectedItems.filter(
            (val) => categories?.map((el) => el?.categoryId)?.includes(val)
          ),
          couponfoods: selectedItems.filter(
            (val) => !categories?.map((el) => el?.categoryId)?.includes(val)
          )

        },
        code: waiter.code,
      };
      const res = await postAddNewCoupon(data);
      if (res.data.coupon) {
        dispatch(addCouponAction(res.data.coupon));
      }
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setSelectedItems([])
      setLoading(false);
      hide();
    } catch (error) {
      if (error?.response?.data?.error === "duplicated") {
        setLoading(false);
        form.setFields([
          {
            name: "code",
            errors: [intl.formatMessage({ id: "duplicatedCode" })],
          },
        ]);
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "duplicatedCode" }),
          type: "error",
          rtl,
        });
      } else {
        setLoading(false);
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "errorfetch" }),
          type: "error",
          rtl,
        });
      }
    }
  };

  function disabledStartDate(current) {
    // Can not select days before today and today
    return current && current < moment().startOf("day");
  }
  function disabledEndDate(current) {
    // Can not select days before today and today
    let endDate = form.getFieldValue("startDate");
    return current && current <= moment(endDate).startOf("day");
  }

  const FoodsTree = categories.map((cat) => {
    return {
      title: rtl ? cat?.name : cat?.name_en,
      value: cat?.categoryId,
      key: cat?.categoryId,
      children: foods
        ?.filter(
          (food) =>
            food?.categoryId === cat?.categoryId
        )
        .map((food) => {
          return {
            title: rtl ? food?.name : food?.name_en,
            value: food?.foodId,
            key: food?.foodId,
          };
        }),
    };
  });
  const handleChangeFoodTrees = (newValue) => {
    setSelectedItems(newValue);
  };
  const tProps = {
    treeData: FoodsTree,
    disabled: !form.getFieldValue("isCouponForItems"),
    value: selectedItems,
    onChange: handleChangeFoodTrees,
    treeCheckable: true,
    showCheckedStrategy: SHOW_ALL,
    placeholder: intl.formatMessage({ id: "ApplyCouponForItems" }),
    style: {
      width: "100%",
    },
  }

  return (
    <Modal
      title={
        <p className='my-font'>{intl.formatMessage({ id: "addCoupon" })}</p>
      }
      centered
      visible={show}
      destroyOnClose={true}
      mask={true}
      getContainer={false}
      maskClosable={true}
      onCancel={() => {
        hide();
      }}
      footer={null}
    >
      <Form
        name='Add-Coupon'
        form={form}
        preserve={false}
        className='form-container'
        layout='vertical'
        onFinish={addNewCoupon}
        initialValues={{
          name: "",
          code: "",
          percentage: "",
          isCouponForItems: false,
          maximumAmount: 0,
          numberOfUsed: 0,
          startDate: moment().startOf("day"),
          endDate: moment().add(1, "days").startOf("day"),
        }}
      >
        {() => {
          return (
            <>
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                {/* Names */}
                <Col md={24} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "couponName" })}
                    className='mb-1'
                    tooltip={
                      <Text type='primary' className='my-font'>
                        {intl.formatMessage({ id: "explainCouponName" })}
                      </Text>
                    }
                    name='name'
                    style={{ borderRadius: 20 }}
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "errorname" }),
                      },
                    ]}
                  >
                    <Input
                      className={"input"}
                      style={{ borderRadius: 5, width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                <Col md={24} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "couponCode" })}
                    className='mb-1'
                    name='code'
                    tooltip={
                      <Text type='primary' className='my-font'>
                        {intl.formatMessage({ id: "explainCouponCode" })}
                      </Text>
                    }
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "errorname" }),
                      },
                    ]}
                  >
                    <Input className={"input"} style={{ borderRadius: 5 }} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                <Col md={24} sm={24} xs={24}>
                  <Form.Item
                    className='mb-3 mt-3'
                    name='isCouponForItems'
                    valuePropName='checked'
                  >
                    <Checkbox
                      onChange={(e) => {
                        setSelectedItems([])
                      }}
                    >
                      {intl.formatMessage({ id: "isCouponForItems" })}{" "}
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24}>
                  <TreeSelect
                    className='mb-5'
                    {...tProps}
                  />
                </Col>
              </Row>
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "percentage" })}
                    className='mb-1'
                    name='percentage'
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "errorname" }),
                      },
                    ]}
                  >
                    <InputNumber
                      className={"input"}
                      placeholder={"10%"}
                      min={0}
                      style={{ borderRadius: 5, width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "maximumAmount" })}
                    className='mb-1'
                    name='maximumAmount'
                    tooltip={
                      <Text type='primary' className='my-font'>
                        {intl.formatMessage({ id: "explainCouponMax" })}
                      </Text>
                    }
                    rules={[
                      {
                        required: !form.getFieldValue("isCouponForItems"),
                        message: intl.formatMessage({ id: "errorname" }),
                      },
                    ]}
                  >
                    <InputNumber
                      disabled={form.getFieldValue("isCouponForItems")}
                      className={"input"}
                      placeholder={"50"}
                      min={0}
                      style={{ borderRadius: 5, width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "MaxNumberOfUsed" })}
                    className='mb-1'
                    name='numberOfUsed'
                    tooltip={
                      <Text type='primary' className='my-font'>
                        {intl.formatMessage({ id: "explainCouponMaxUsed" })}
                      </Text>
                    }
                  >
                    <InputNumber
                      className={"input"}
                      placeholder={""}
                      min={0}
                      style={{ borderRadius: 5, width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}></Col>
              </Row>
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "startDate" })}
                    className='mb-1'
                    name='startDate'
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "errorname" }),
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      // disabledDate={disabledStartDate}
                      onChange={(e) => {
                        form.setFieldsValue({
                          endDate: moment(e).add(1, "days").startOf("day"),
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "endDate" })}
                    className='mb-1'
                    name='endDate'
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "errorname" }),
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      disabledDate={disabledEndDate} />
                  </Form.Item>
                </Col>
              </Row>
              <Divider />
              <Space direction='horizontal'>
                <Form.Item>
                  <CustomButton
                    htmlType='submit'
                    text={intl.formatMessage({ id: "save" })}
                    className={`btnRegister btn-text border-8`}
                    loading={loading}
                    disabled={loading}
                    type='primary'
                  />
                </Form.Item>

                <Form.Item>
                  <CustomButton
                    type='text'
                    text={intl.formatMessage({ id: "cancel" })}
                    disabled={loading}
                    onClick={() => {
                      hide();
                    }}
                  />
                </Form.Item>
              </Space>
            </>
          );
        }}
      </Form>
    </Modal>
  );
};

export default AddCoupon;
