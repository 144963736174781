export const calculateSubTotal = ({
  mark,
  foodPrice,
  extraPrice,
  promotionAmount,
  specialPromotionAmount,
  quantity,
  isWithCoupon,
  couponPercentage
}) => {
  let total = 0;
  if (mark) {
    if (mark === "+") {
      total = (
        (parseFloat(foodPrice) + extraPrice + promotionAmount) *
        parseFloat(quantity)
      ).toFixed(2);
    } else if (mark === "-") {
      total = (
        (parseFloat(foodPrice) + extraPrice - promotionAmount) *
          parseFloat(quantity) <
          0
          ? 0
          : (parseFloat(foodPrice) + extraPrice - promotionAmount) *
          parseFloat(quantity)
      ).toFixed(2);
    } else {
      total = (
        (parseFloat(promotionAmount) + extraPrice) *
        parseFloat(quantity)
      ).toFixed(2);
    }
  } else {
    total = (
      (parseFloat(foodPrice) + extraPrice) *
      parseFloat(quantity)
    ).toFixed(2);
  }

  let finalTotal
  if (isWithCoupon) {
    finalTotal = (total - specialPromotionAmount) - (((total - specialPromotionAmount) * couponPercentage) / 100);
  } else {
    finalTotal = total - specialPromotionAmount;
  }

  return finalTotal;
};
