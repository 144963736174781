import React from "react";
import { Typography } from "antd";
import { useIntl } from "react-intl";
import COLORS from "../../Style/colors";
import { useSelector } from "react-redux";
import PaymentDocument from "./Components/PaymentDocument";
import PaymentOptions from "./Components/PaymentOptions";

const { Text, Title } = Typography;
const Payment = () => {
  const intl = useIntl();
  const { restaurant } = useSelector((state) => state.restaurant);
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      {restaurant?.wallet ? (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 5,
              marginRight: 30,
              marginLeft: 30,
            }}
          >
            <Title level={5}>
              {intl.formatMessage({ id: "onlinePayment" })}
            </Title>
          </div>
          <div
            style={{
              backgroundColor: COLORS.white,
              marginTop: 5,
              width: "92%",
              marginRight: "auto",
              marginLeft: "auto",
              borderRadius: 10,
              paddingBottom: 15,
            }}
            className='light-shadow'
          >
            <PaymentOptions />
          </div>
        </>
      ) : null}

      {/* User does not have a valid wallet make the user create new or edit his information */}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          marginBottom: 5,
          marginTop: 30,
          marginRight: 30,
          marginLeft: 30,
          flexDirection: "column",
        }}
      >
        <Title level={5}>
          {intl.formatMessage({ id: "paymentInformation" })}
        </Title>
        <div
          style={{
            width: "90%",
            marginBottom: 5,
            // marginRight: "auto",
            // marginLeft: "auto",
          }}
        >
          <Text className='my-font' type='secondary'>
            *{" "}
            {intl.formatMessage(
              {
                id: "paymentPercentageFatoorah",
              },
              {
                MADA: restaurant?.percentageForIWaiterMADA || "1.9",
                VISA: restaurant?.percentageForIwaiter || "2.4",
              }
            )}
          </Text>
        </div>
      </div>
      <div
        style={{
          backgroundColor: COLORS.white,
          marginTop: 5,
          width: "92%",
          marginRight: "auto",
          marginLeft: "auto",
          borderRadius: 10,
          paddingBottom: 15,
        }}
        className='light-shadow'
      >
        <PaymentDocument />
      </div>
    </div>
  );
};

export default Payment;
