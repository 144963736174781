import React, { useState, useEffect } from "react";
import { Row, Col, Typography, Tabs, Badge } from "antd";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import COLORS from "../../Style/colors";
import ActiveUserTable from "./ActiveUserTable";
import ServedUserTable from "./ServedUserTable";
import { WAITING_LIST_USER_STATUS } from "../../Types";
import { IoArrowBackOutline, IoArrowForwardOutline } from "react-icons/io5";
import { useNavigate } from "react-router";
import { GiBarracksTent } from "react-icons/gi";
import moment from "moment";
import { getReservationUsers } from "../../API/fetch";
import openNotification from "../../Components/Notifications";
import { setBranchAction } from "../../redux/reducers/branchReducer";
import CustomButton from "../../Components/CustomButton";

const clineURL = "https://app.iwaiter.club";
const { TabPane } = Tabs;
const { Title, Text } = Typography;
const Reservation = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useNavigate();
  const searchURI = new URLSearchParams(window.location.search);
  const qDate = searchURI.get("d");
  const { rtl } = useSelector((state) => state.settings);
  const { role } = useSelector((state) => state.auth);
  const { branch } = useSelector((state) => state.branch);
  const { restaurant } = useSelector((state) => state.restaurant);
  const [activeTab, setActiveTab] = useState("");
  const [seletedReservationSchema, setSelectedReservationSchema] = useState({});
  const [date, setDate] = useState(
    qDate ? moment(qDate, "YYYY-MM-DD") : moment()
  );
  const [loading, setLoading] = useState(false);
  const [allUsers, setAllUsers] = useState(branch?.reservationList);

  useEffect(() => {
    // check if we do not have query string;
    if (!qDate) {
      // set date on the query string;
      window.history.replaceState(
        null,
        null,
        `?d=${moment().format("YYYY-MM-DD")}`
      );
      onSelectDate({ date: moment() });
    }else {
      onSelectDate({ date: moment(qDate, "YYYY-MM-DD") });
    }
  }, []);
  useEffect(() => {
    if (date) {
      // update the query string;
      window.history.replaceState(
        null,
        null,
        `?d=${moment(date).format("YYYY-MM-DD")}`
      );
    }
  }, [date]);

  useEffect(() => {
    if (branch?.reservationSchema?.length) {
      setActiveTab(branch.reservationSchema[0]._id);
      setSelectedReservationSchema(branch.reservationSchema[0]);
    }
  }, [branch, branch?.reservationSchema]);

  useEffect(() => {
    if (branch.reservationList?.length) {
      // check if the dateReservation for the first element in the array === date;
      const firstElement = branch.reservationList[0];
      const reservationDate = moment(firstElement.reservationDate).startOf(
        "day"
      );
      const dateSelected = moment(date).startOf("day");
      if (reservationDate.isSame(dateSelected, "day")) {
        // sort by reservationDate;
        const sorted = [...branch?.reservationList].sort((a, b) => {
          return new Date(a.reservationDate) - new Date(b.reservationDate);
        });

        setAllUsers(sorted);
      }
    }
  }, [branch, branch.reservationList]);

  const onSelectDate = async (values) => {
    try {
      // set Day for start of the day with format with hours and minutes;
      setLoading(true);
      let date = "";
      if (values?.allDays) {
        date = "ALL";
      } else {
        date = moment(values.date)
          .startOf("day")
          .add(12, "hours")
          .format("YYYY-MM-DD");
        setDate(values.date);
      }
      const res = await getReservationUsers(date, branch?._id);
      // sort by reservation date;
      let sorted = [...res?.data?.reservationList].sort((a, b) => {
        return new Date(a.reservationDate) - new Date(b.reservationDate);
      });

      setAllUsers(sorted);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const goToReservationModiferArea = () => {
    dispatch(setBranchAction(branch));
    history("/reservation-modifier");
  };

  return (
    <div>
      {role === "super" ? (
        <div
          style={
            rtl
              ? {
                  position: "absolute",
                  zIndex: 2,
                }
              : {
                  position: "absolute",
                  zIndex: 2,
                }
          }
        >
          <Title level={3} className='pointer' onClick={() => history(-1)}>
            {rtl ? (
              <IoArrowForwardOutline className='pointer' />
            ) : (
              <IoArrowBackOutline className='pointer' />
            )}
          </Title>
        </div>
      ) : null}
      <Row
        style={{
          position: "relative",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <Col span={24}>
          <Title level={2} style={{ textAlign: "center" }}>
            {intl.formatMessage({ id: "reservation" })}
          </Title>
        </Col>
        <Col
          className='mb-3'
          span={24}
          type='flex'
          justify='center'
          align='middle'
        >
          <Text className='my-font'>
            {intl.formatMessage({ id: "linkReservation" })}
          </Text>
          {" : "}
          <Text copyable style={{ textAlign: "center" }}>
            {restaurant?.domain == "iWaiter"
              ? `${clineURL}/${restaurant?.slug}/reservations`
              : `${restaurant?.domain}/reservations`}
          </Text>
        </Col>
      </Row>
      {role === "super" ? (
        <Row
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <CustomButton
            type='primary'
            text={intl.formatMessage({ id: "reservationModifier" })}
            className={`btnRegister btn-text border-8 px-5 mt-2`}
            onClick={goToReservationModiferArea}
          />
        </Row>
      ) : null}
      <Tabs
        centered
        activeKey={activeTab}
        onTabClick={(e) => {
          setActiveTab(e);
          setSelectedReservationSchema(
            branch.reservationSchema.filter((res) => res._id === e)[0]
          );
        }}
        type='card'
        style={{ minHeight: "75vh" }}
      >
        {branch?.reservationSchema?.map((area) => {
          let activeUsers = allUsers?.filter((u) => {
            return u?.reservationTypeId === area?._id;
            // && u?.status === WAITING_LIST_USER_STATUS.ACTIVE
          });

          return (
            <TabPane
              key={area?._id}
              style={{
                minHeight: "75vh",
              }}
              tab={
                <Badge
                  count={activeUsers?.length}
                  style={{ backgroundColor: COLORS.primary }}
                >
                  <Text
                    type={area.status === "disabled" ? "danger" : "primary"}
                    className='px-3 my-font'
                  >
                    {rtl ? area?.name : area?.name_en}
                  </Text>
                </Badge>
              }
            >
              <div>
                <ActiveUserTable
                  users={activeUsers}
                  status={area?.status}
                  area={area}
                  selectedArea={activeTab}
                  setActiveTab={setActiveTab}
                  onSelectDate={onSelectDate}
                  loading={loading}
                  date={date}
                  seletedReservationSchema={seletedReservationSchema}
                />
              </div>
            </TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};

export default Reservation;
