import React from 'react'
import { Col, Row, Tag, Typography } from "antd";
import { useIntl } from 'react-intl';
import { getReportTotal } from '../../../helpers';
import { TAG_COLOR_BY_USER_TYPE } from '../../../Types';

// 9i6b85p5h8

const { Text } = Typography;
const boxStyle = {
    display: "flex",
    justifyContent: "space-around",
    backgroundColor: "white",
    padding: "8px",
    boxShadow: "1px 2px 3px lightgray"
}
function ReportSummary({ amounts, filter }) {
    const intl = useIntl()
    return (
        <Row style={{ backgroundColor: "#fafafa", padding: "10px 20px" }} gutter={16}>
            <Col className="gutter-row" span={5}>
                <div style={boxStyle}>
                    <Text className='my-font'>
                        {intl.formatMessage({ id: "reportsType" })}
                    </Text>
                    <Text className='my-font'>
                        <Tag color={TAG_COLOR_BY_USER_TYPE[filter]} >
                            {intl.formatMessage({ id: filter })}
                        </Tag>

                    </Text>
                </div>
            </Col>
            <Col className="gutter-row" span={5}>
                <div style={boxStyle}>
                    <Text className='my-font'>
                        {intl.formatMessage({ id: "totalOrders" })}
                    </Text>
                    <Text className='my-font'>
                        {amounts?.length}
                    </Text>
                </div>
            </Col>
            <Col className="gutter-row" span={5}>
                <div style={boxStyle}>
                    <Text className='my-font'>
                        {intl.formatMessage({ id: "totalAmounts" })}
                    </Text>
                    <Text className='my-font'>
                        {getReportTotal(amounts)?.toFixed(2)}
                    </Text>

                </div>
            </Col>
        </Row>
    )
}

export default ReportSummary