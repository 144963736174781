import { createSlice, current } from "@reduxjs/toolkit";
import {
  arrAdd,
  arrFilter,
  arrRemoveByKeyValue,
  arrUpdateByKeyValue,
  concatArr,
} from "../../../helpers/array";

const initialState = {
  tables: [],
  allTables: [],
  zones: [],
  positions: {},
};

export const tableSlice = createSlice({
  name: "table",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setTables: (state, action) => {
      let zon = [];
      let tablesWithoutZones = [];
      if (!action.payload.length) {
        state.tables = [];
        state.zones = [{
           name: "General", id: "0", numberOfActiveTables: 0 
        }];
        return state;
      }
      action.payload.forEach((element, i) => {
        if (zon.findIndex((z) => z.name === element.zone) === -1) {
          if (element.zone) {
            zon.push({
              name: element.zone,
              id: `${i}`,
              numberOfActiveTables: 0,
            });
          } else {
            tablesWithoutZones.push(element);
          }
        }
      });
      if (zon.length === 0) {
        zon.push({ name: "General", id: "0", numberOfActiveTables: 0 });
        state.tables = action.payload.map((t) => {
          if (!t.zone) {
            return {
              ...t,
              zone: "General",
            };
          } else {
            return {
              ...t,
            };
          }
        });
        state.zones = zon;
      } else {
        // check if user has zones but he also have tables without zones;
        if (tablesWithoutZones.length > 0) {
          zon.unshift({ name: "General", id: `0`, numberOfActiveTables: 0 });
          let generalTables = tablesWithoutZones.map((t) => {
            return {
              ...t,
              zone: "General",
            };
          });
          state.tables = [...generalTables, ...action.payload];
          state.zones = zon;
        } else {
          state.tables = action.payload;
          state.zones = zon;
        }
      }
    },
    setTablePosition: (state, action) => {
      const copy = { ...current(state.positions) };
      copy[action.payload.tableId] = {};
      copy[action.payload.tableId]["x"] = action.payload.position.x;
      copy[action.payload.tableId]["y"] = action.payload.position.y;
      state.positions = copy;
    },
    setDefaultPosition: (state, action) => {
      state.positions = {};
    },
    editTable: (state, action) => {
      if (!action?.payload?.zone) {
        action.payload.zone = "General";
      }
      const newTables = arrUpdateByKeyValue({
        arr: current(state.tables),
        key: "_id",
        value: action.payload._id,
        newElement: action.payload,
      });
      state.tables = newTables;
      // update zones;
      state.zones = current(state.zones).filter((zone) => {
        return state.tables.some(
          (t) => t.zone === zone.name || zone.name === "General"
        );
      });
    },
    deleteTable: (state, action) => {
      const newTables = arrRemoveByKeyValue({
        arr: current(state.tables),
        key: "_id",
        value: action.payload,
      });
      state.tables = newTables;
      // update zones;
      state.zones = state.zones.filter((zone) => {
        return state.tables.some(
          (t) => t.zone === zone.name || zone.name === "General"
        );
      });
    },
    deleteTables: (state, action) => {
      const newTables = arrFilter({
        arr: current(state.tables),
        callback: (t) => !action?.payload?.includes(t?._id),
      });
      state.tables = newTables;
      // update zones;
      state.zones = state.zones.filter((zone) => {
        return state.tables.some(
          (t) => t.zone === zone.name || zone.name === "General"
        );
      });
    },
    addNewTable: (state, action) => {
      const newTables = arrAdd({
        arr: current(state.tables),
        newElement: action.payload,
      });
      state.tables = newTables;
      if (state.zones.findIndex((z) => z.name === action.payload.zone) === -1) {
        const newZones = arrAdd({
          arr: current(state.zones),
          newElement: {
            name: action.payload.zone,
            id: `${state.zones.length + 1}`,
            numberOfActiveTables: 0,
          },
        });
        state.zones = newZones;
      }
    },
    addNewTables: (state, action) => {
      const newTables = concatArr({
        arr: current(state.tables),
        newArr: action.payload,
      });
      state.tables = newTables;
    },
    addNewZone: (state, action) => {
      const newZones = arrAdd({
        arr: current(state.zones),
        newElement: {
          name: action.payload,
          id: `${state.zones.length + 1}`,
          numberOfActiveTables: 0,
        },
      });
      state.zones = newZones;
    },
    filterZones: (state, action) => {
      state.zones = current(state.zones).filter((zone) => {
        return state.tables.some(
          (t) => t.zone === zone.name || zone.name === "General"
        );
      });
    },
    editMultiTables: (state, action) => {
      let tables = action.payload.tables;
      let data = action.payload.data;
      let type = action.payload.type;
      if (type === "EDIT_FEES") {
        let nweTables = state.tables.map((t) => {
          if (tables.includes(t._id)) {
            return {
              ...t,
              tableFees: data.tableFees,
              tableMinimumOrder: data?.tableMinimumOrder,
            };
          } else {
            return t;
          }
        });
        state.tables = nweTables;
        let newAllTables = state.allTables.map((t) => {
          if (tables.includes(t._id)) {
            return {
              ...t,
              tableFees: data.tableFees,
              tableMinimumOrder: data?.tableMinimumOrder,
            };
          } else {
            return t;
          }
        }
        );
        state.allTables = newAllTables;
      } else if(type==="MOVE_MULTI_TABLES"){
        let nweTables = state.tables.map((t) => {
          if (tables.includes(t._id)) {
            return {
              ...t,
              zone: data.zone,
            };
          } else {
            return t;
          }
        });
        state.tables = nweTables;
        let newAllTables = state.allTables.map((t) => {
          if (tables.includes(t._id)) {
            return {
              ...t,
              zone: data.zone,
            };
          } else {
            return t;
          }
        }
        );
        state.allTables = newAllTables;
      }
    },
  },
});

export const {
  setTables: setTablesAction,
  setTablePosition: setTablePositionAction,
  setDefaultPosition: setDefaultPositionAction,
  editTable: editTableAction,
  deleteTable: deleteTableAction,
  deleteTables: deleteTablesAction,
  addNewTables: addNewTablesAction,
  addNewTable: addNewTableAction,
  addNewZone: addNewZoneAction,
  filterZones: filterZonesAction,
  editMultiTables: editMultiTablesAction,
} = tableSlice.actions;

export default tableSlice.reducer;
