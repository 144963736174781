import { InboxOutlined } from '@ant-design/icons';
import { Col, Row, Spin, Typography, Upload, } from 'antd';
import React from 'react'
import ImageAddRow from './ImageAddRow';
import MainPagePreview from './MainPagePreview';
import COLORS from "../../../Style/colors";
import { useIntl } from 'react-intl';

const { Text } = Typography;
const { Dragger } = Upload;

function MenuAdForm({ actionLink, token, fileList,
    handleChangeImages, beforeUpload
    , deleteImage, handleEditImage,
    imagesToDisplay, loading,
    form, isBigScreen, sectionName,
    errorSize
}) {
    const intl = useIntl()
    return (
        <div>
            <Row type='flex'>
                <Col md={12} sm={24} xs={24}>
                    <Row style={{ width: "90%" }}>
                        <Text className='my-font'>
                            {intl.formatMessage({ id: "custumAdsDesc1" })} "{sectionName}" {intl.formatMessage({ id: "custumAdsDesc2" })}.
                        </Text>
                    </Row>
                    <Row style={{ width: "90%" }}>
                        <Text className='my-font'>
                            {intl.formatMessage({ id: "addsSize" })} .
                        </Text>
                    </Row>
                    <Row className='mt-5' style={{ width: "95%", position: "relative" }}>
                        <div style={{ position: "absolute", top: -25, display: "flex", justifyContent: "flex-start", width: "100%" }}>
                            {errorSize ? <Text className='my-font' style={{ color: COLORS.danger }}>
                                {intl.formatMessage({ id: "limit2Mb" })}
                            </Text> : null}
                        </div>
                        <Spin spinning={loading} style={{ width: "100%" }}>

                            <Dragger
                                action={actionLink}
                                headers={token}
                                name='picture'
                                listType='picture'
                                fileList={fileList}
                                onChange={handleChangeImages}
                                // onPreview={onPreview}
                                beforeUpload={beforeUpload}
                                multiple={false}
                                accept='image/jpg,image/jpeg,image/png'
                                onRemove={deleteImage}
                                itemRender={(defaultEle, file, fileList, fun) => {
                                    return (
                                        <ImageAddRow
                                            fun={fun}
                                            file={file}
                                            handleEditImage={handleEditImage}
                                        />
                                    );
                                }}
                            >
                                {fileList.length < 4 ? (
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: "100%",
                                        }}
                                    >
                                        <InboxOutlined
                                            style={{
                                                color: errorSize ? COLORS.danger : COLORS.primary,
                                                fontSize: "2rem",
                                            }}
                                            className='mx-3'
                                        />
                                        <Text
                                            style={{
                                                fontSize: "1rem",
                                                color: errorSize ? COLORS.danger : COLORS.primary,
                                            }}
                                            className='my-font'
                                        >
                                            {intl.formatMessage({ id: "addPhoto" })}
                                        </Text>
                                    </div>
                                ) : (
                                    <Text className='my-font'>
                                        {intl.formatMessage({ id: "max4Images" })}
                                    </Text>
                                )}
                            </Dragger>
                        </Spin>
                    </Row>
                </Col>
                <Col
                    style={{ height: isBigScreen ? 750 : 650 }}
                    md={12}
                    sm={24}
                    xs={24}
                >
                    <MainPagePreview
                        form={form}
                        showAdds={true}
                        images={imagesToDisplay}
                    />
                </Col>
            </Row>
        </div>
    )
}

export default MenuAdForm