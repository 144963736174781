import React, {useState, useEffect} from "react";
import { Tabs } from "antd";
import { useSelector } from "react-redux";
import COLORS from "../../../Style/colors";
import TableDetails from "./TableDetails";

const {TabPane} = Tabs;
const CategoriesClasses = ({ details }) => {
    const {rtl} = useSelector((state) => state.settings);
    const {categories} = useSelector((state) => state.menu);
    
    const [activeTab, setActiveTab] = useState('')
    useEffect(() => {
        if (details.length && activeTab === "") {
          setActiveTab(details[0]._id);
        }
      }, [categories, activeTab]);


  

  return (
    <div>
        <Tabs
        centered
        activeKey={activeTab}
        onTabClick={(e) => setActiveTab(e)}
        type='card'
        style={{ minHeight: "75vh" }}
      >
        {details?.map((category, index) => {
          return (
            <TabPane
              key={category._id}
              style={{
                backgroundColor: COLORS.white,
                minHeight: "75vh",
              }}
              tab={
                <span className='px-3 my-font'>
                  {category?.category_name_en
                    ? rtl
                      ? category?.category
                      : category.category_category_en
                    : category.name}
                </span>
              }
            >
             <div className='mx-3 mt-3'>
              <TableDetails foods={category?.foods} category={category} />
             </div>
            </TabPane>
          );
        })}
      </Tabs>
    
    </div>
  );
};

export default CategoriesClasses;
