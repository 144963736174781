import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12.033}
    height={21.703}
    {...props}
  >
    <g data-name="Group 7">
      <path
        d="M11.618 13.486q-.092-.234-.2-.458a10.64 10.64 0 0 1-.894-3.709c0-1.469.14-3.277.2-4.03.01-.085.027-.356.027-.356v-.192A4.741 4.741 0 0 0 6.01.001a4.741 4.741 0 0 0-4.74 4.74 11.222 11.222 0 0 0 .243 1.5 13.8 13.8 0 0 1 .281 3.239A13.455 13.455 0 0 1 .67 12.913a6.051 6.051 0 0 0-.254.554 6 6 0 0 0-.422 2.219 6.016 6.016 0 0 0 6.017 6.016 6.017 6.017 0 0 0 6.016-6.016 6 6 0 0 0-.415-2.2"
        fill={props?.fill || "#0a2240"}
      />
      <path
        d="M4.498 17.489c.317.688.189 1.423-.286 1.642s-1.117-.162-1.434-.85-.189-1.423.286-1.642 1.117.162 1.434.849M3.889 14.479c.189.411.113.849-.171.979s-.666-.1-.855-.507-.112-.848.171-.979.666.1.855.506M5.872 15.913c.209.453.124.937-.189 1.081s-.736-.106-.944-.56-.124-.937.189-1.081.736.106.944.559"
        fill="#fff"
      />
    </g>
  </svg>
)

export default SvgComponent
