import Corn from "./Corn";
import Egg from "./Egg";
import Fish from "./Fish";
import Gluten from "./Gluten";
import Lacatues from "./Lacatues";
import Nuts from "./Nuts";
import GMO from "./OMG";
import Peanuts from "./Peanuts";
import SHELLFISH from "./Seelfish";
import Soya from "./Soya";
import Sulept from "./sulept";
import Vigan from "./vigan";

export const FoodAllergyTYPES = {
  CORN: "CORN",
  EGG: "EGG",
  FISH: "FISH",
  GLUTEN: "GLUTEN",
  MILK: "MILK",
  NUTS: "NUTS",
  GMO: "GMO",
  PEANUTS: "PEANUTS",
  SHELLFISH: "SHELLFISH",
  SOYA: "SOYA",
  SULPHITES: "SULPHITES",
  VEGAN: "VEGAN",
};

export const getFoodAllergyIcon = (name, fill) => {
  switch (name) {
    case FoodAllergyTYPES.CORN:
      return <Corn fill={fill} />;
    case FoodAllergyTYPES.EGG:
      return <Egg fill={fill} />;
    case FoodAllergyTYPES.FISH:
      return <Fish fill={fill} />;
    case FoodAllergyTYPES.GLUTEN:
      return <Gluten fill={fill} />;
    case FoodAllergyTYPES.MILK:
      return <Lacatues fill={fill} />;
    case FoodAllergyTYPES.NUTS:
      return <Nuts fill={fill} />;
    case FoodAllergyTYPES.GMO:
      return <GMO fill={fill} />;
    case FoodAllergyTYPES.PEANUTS:
      return <Peanuts fill={fill} />;
    case FoodAllergyTYPES.SHELLFISH:
      return <SHELLFISH fill={fill} />;
    case FoodAllergyTYPES.SOYA:
      return <Soya fill={fill} />;
    case FoodAllergyTYPES.SULPHITES:
      return <Sulept fill={fill} />;
    case FoodAllergyTYPES.VEGAN:
      return <Vigan fill={fill} />;
    default:
      return <Corn fill={fill} />;
  }
};
