

const DEFAULT_DAYS = [
  {
    name: "السبت",
    id: 0,
    name_en: "Saturday",
    hours: [{
      from: "28800",
      to: "84600",
    }],

    isClose: false,
  },
  {
    name: "الأحد",
    id: 1,
    name_en: "Sunday",
    hours: [{
      from: "28800",
      to: "84600",
    }],
    isClose: false,
  },
  {
    name: "الاثنين",
    id: 2,
    name_en: "Monday",
    hours: [{
      from: "28800",
      to: "84600",
    }],

    isClose: false,
  },
  {
    name: "الثلاثاء",
    id: 3,
    name_en: "Tuesday",
    hours: [{
      from: "28800",
      to: "84600",
    }],


    isClose: false,
  },
  {
    name: "الأربعاء",
    id: 4,
    name_en: "Wednesday",
    hours: [{
      from: "28800",
      to: "84600",
    }
    ],

    isClose: false,
  },
  {
    name: "الخميس",
    id: 5,
    name_en: "Thursday",
    hours: [{
      from: "28800",
      to: "84600",
    },
    ],

    isClose: false,
  },
  {
    name: "الجمعة",
    id: 6,
    name_en: "Friday",
    hours: [{
      from: "28800",
      to: "84600",
    },
    ],
    isClose: false,
  },
  {
    name: "يومياً",
    id: 7,
    name_en: "everyDay",
    hours: [{
      from: "28800",
      to: "84600",
    },
    ],
    isClose: false,
  },
];
export default DEFAULT_DAYS;