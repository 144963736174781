import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Table, Typography, Tag, Space, Switch, Col, Row, Spin } from "antd";
import CustomButton from "../../Components/CustomButton";
import COLORS from "../../Style/colors";
import { EyeTwoTone } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { postChangeFeedbackStatus, getFeedbacks } from "../../API/fetch";
import { setFeedbackAction } from "../../redux/reducers/restaurantReducer";
import openNotification from "../../Components/Notifications";
import { IoArrowBackOutline, IoArrowForwardOutline } from "react-icons/io5";

const { Title } = Typography;

const Feedback = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const { feedbackForms, restaurant } = useSelector(
    (state) => state.restaurant
  );
  const [formattedForms, setFormattedForms] = useState([]);
  const [loadingActivations, setLoadingActivations] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useNavigate();

  useEffect(() => {
    getAllFeedbackForms();
  }, []);
  const getAllFeedbackForms = async () => {
    try {
      setLoading(true);
      const res = await getFeedbacks();
      if (res.data.feedbackForms) {
        dispatch(setFeedbackAction(res.data.feedbackForms));
        openNotification({
          title: intl.formatMessage({ id: "success" }),
          description: intl.formatMessage({ id: "success" }),
          type: "success",
          rtl,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  useEffect(() => {
    if (feedbackForms.length) {
      const format = feedbackForms.map((form) => {
        return {
          name: form.name,
          status: form.status,
          key: form._id,
          answersCount: form?.answers?.length,
          feedbackInfo: { ...form },
        };
      });

      setFormattedForms(format);
    } else {
      setFormattedForms([]);
    }
  }, [feedbackForms]);

  const activateForm = async (id, value) => {
    try {
      setLoadingActivations(true);
      const data = {
        active: id,
        owner: restaurant._id,
      };
      const res = await postChangeFeedbackStatus(data);
      if (res.data.status === "success") {
        dispatch(setFeedbackAction(res.data.formattedForms));
        setLoadingActivations(false);
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
      }
    } catch (error) {
      setLoadingActivations(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const columns = [
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "name",
      key: "name",
    },
    {
      title: intl.formatMessage({ id: "status" }),
      dataIndex: "status",
      key: "status",
      render: (status, record) => {
        return (
          <Space direction='horizontal'>
            <Tag color={status === "Active" ? "green" : "red"}>
              {intl.formatMessage({ id: `${status}` })}
            </Tag>
            <Switch
              checked={status === "Active"}
              loading={loadingActivations}
              disabled={loadingActivations}
              onChange={(checked) => {
                if (checked) {
                  activateForm(record.feedbackInfo._id, checked);
                } else {
                  activateForm(null, checked);
                }
              }}
            />
          </Space>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "answers" }),
      dataIndex: "answersCount",
      key: "answersCount",
    },
    {
      title: intl.formatMessage({ id: "view" }),
      dataIndex: "view",
      render: (status, record) => {
        return (
          <EyeTwoTone
            onClick={() => {
              history(`/feedback/${record.feedbackInfo._id}`);
            }}
            className={"pointer"}
          />
        );
      },
    },
  ];

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      {/* <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <Title>{intl.formatMessage({ id: "feedbackForms" })}</Title>
        <CustomButton
          text={intl.formatMessage({
            id: "addform",
          })}
          type='primary'
          className='border-8'
          onClick={() => history(`/feedback/new`)}
        />
      </div> */}
      <div
        style={{
          marginTop: 20,
          width: "92%",
          marginRight: "auto",
          marginLeft: "auto",
        }}
      >
        <Row>
          <Col
            span={24}
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Title
              level={3}
              className='pointer'
              onClick={() => history("/restaurant")}
              style={{ margin: 0 }}
            >
              {rtl ? (
                <IoArrowForwardOutline className='pointer' />
              ) : (
                <IoArrowBackOutline className='pointer' />
              )}
            </Title>
            <Title level={3} style={{ margin: 0 }}>
              {intl.formatMessage({ id: "feedbackForms" })}
            </Title>
            <CustomButton
              text={intl.formatMessage({
                id: "addform",
              })}
              type='primary'
              className='border-8'
              onClick={() => history(`/feedback/new`)}
            />
          </Col>
        </Row>
      </div>
      <Spin spinning={loading}>
        <div
          style={{
            backgroundColor: COLORS.white,
            marginTop: 40,
            width: "92%",
            marginRight: "auto",
            marginLeft: "auto",
            borderRadius: 10,
          }}
          className='light-shadow'
        >
          <Table
            columns={columns}
            style={{ borderRadius: 10 }}
            dataSource={formattedForms}
            scroll={{ x: 500 }}
            pagination={false}
          />
        </div>
      </Spin>
    </div>
  );
};

export default Feedback;
