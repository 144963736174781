import React, { useState } from "react";
import {
  Form,
  Divider,
  Space,
  Typography,
  Row,
  Col,
  Checkbox,
  Spin,
  Upload,
  InputNumber,
  Switch,

} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import CustomButton from "../../../Components/CustomButton";
import openNotification from "../../../Components/Notifications";
import { postAddReservationArea } from "../../../API/fetch";
import {
  setBranchAction,
  setBranchesAction,
} from "../../../redux/reducers/branchReducer";
import moment from "moment";
import { InboxOutlined } from "@ant-design/icons";
import COLORS from "../../../Style/colors";
//components
import ReservationGeneral from "./reservationForm/ReservationGeneral";
import ReservationPrices from "./reservationForm/ReservationPrices";
import ReservationReasons from "./reservationForm/ReservationReasons";
import ReservationTerms from "./reservationForm/ReservationTerms";
import ReservationWorkDays from "./reservationForm/ReservationWorkDays";
import ReservationModifier from "./reservationForm/ReservationModifier";

const { Text, Title } = Typography;
const { Dragger } = Upload;


const AddReservationSchemaForm = ({ hide }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { branch, allBranches } = useSelector((state) => state.branch);
  const { waiter } = useSelector((state) => state.auth);
  const { tables } = useSelector((state) => state.table);
  const { rtl } = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(false);
  const [disabledValue, setDisabledValue] = useState("reservationPrice");
  const [reasons] = useState([
    { name: "عمل", name_en: "Business", status: true },
    { name: "حفل", name_en: "Party", status: true },
    { name: "عيد الميلاد", name_en: "Birthday", status: true }
  ])
  const [reasonsLength, setReasonsLength] = useState(reasons?.length);
  const [form] = Form.useForm();


  //Add Area function
  const addArea = async (values) => {
    try {
      setLoading(true);
      let status = "Active";
      if (!values?.status) {
        status = "disabled";
      }
      let reservationFeeStandAlone = !values?.reservationFeeStandAlone
      let start = moment(values.startTime).format("HH:mm");
      let end = moment(values.endTime).format("HH:mm");
      let endInNextDay = false;
      // check if end less than start;
      if (moment(end, "HH:mm").isBefore(moment(start, "HH:mm"))) {
        endInNextDay = true;
      }
      let disabled = [];
      if (values.disabledDays.length > 0) {
        disabled = values.disabledDays.map((day) => {
          return {
            value: day,
            status: "Disabled",
          };
        });
      }
      let newReasons
      if (values.isWithReasons) {
        newReasons = values.reasons
      } else {
        newReasons = reasons
      }

      let tableIds = []
      if (values.isLinkTables) {
        const selectedZones = values.linkedZones
        tableIds = tables.filter(t =>
          selectedZones.includes(t.zone)).map(tab =>
            tab._id)
      }
      const data = {
        ...values,
        status,
        owner: branch._id,
        code: waiter.code,
        startTime: start,
        endTime: end,
        endInNextDay,
        disabledDays: disabled,
        reasons: newReasons,
        linkedTables: tableIds,
        reservationFeeStandAlone
      };
      const res = await postAddReservationArea(branch._id, data);
      dispatch(setBranchAction(res.data.branch));
      // copy all Branches and update the one that we edited;
      const allBranchesCopy = [...allBranches];
      const index = allBranchesCopy.findIndex(
        (b) => b.branch._id === res.data.branch._id
      );
      if (index !== -1) {
        allBranchesCopy[index] = {
          ...allBranchesCopy[index],
          branch: res.data.branch,
        };
      }
      // update  all Branchs in the store;
      dispatch(setBranchesAction(allBranchesCopy));
      // dispatch(addNewAreaAction(res.data.area));
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setLoading(false);
      hide();
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  return (
    <div
      style={{
        borderRadius: 6,
        marginTop: 10,
        width: "92%",
        marginRight: "auto",
        marginLeft: "auto",
        padding: 15,
      }}
    >
      <Form
        name='Add-reservation'
        form={form}
        preserve={false}
        className='form-container'
        layout='vertical'
        onFinish={addArea}
        scrollToFirstError={{ behavior: "smooth", block: "center" }}
        initialValues={{
          name: "",
          name_en: "",
          maxChairs: 12,
          maxUsers: 4,
          startTime: moment().set({
            hour: '00:00'.split(":")[0],
            minutes: "00:00".split(":")[1],
          }),
          endTime: moment().set({
            hour: '23:59'.split(":")[0],
            minutes: "23:59".split(":")[1],
          }),
          openSeatsEvery: "15",
          blockReservations: "0",
          status: "Active",
          pricePerUser: 0,
          isWithReasons: false,
          reasons: reasons,
          sendNotification: false,
          isReservationFree: true,
          reservationFeeStandAlone: 0,
          isPriceForReservation: false,
          reservationPrice: 0,
          isCountPerPerson: true,
          isCountPerReservation: false,
          maxReservations: 0,
          order: 0,
          terms: "",
          terms_en: "",
          whatsappMessage: "",
          description: "",
          description_en: "",
          reservationEndDate: moment().add(1, 'days'),
          reservationStartDate: moment(),
          disabledDays: [],
          pricePerChild: 0,
          isPricePerChild: false,
          canPreOrder: false,
          onlyOneReservationADay: false,
          minUsers: 1,
          enableNote: false,
          isLinkTables: false,
          linkedZones: [],
          isHourSelection: false,
          hourPrice: 0
        }}
      >
        {() => (
          <>
            {/* General Settings Section */}
            <div
              style={{
                backgroundColor: COLORS.white,
                padding: "10px 20px 20px",
                borderRadius: 7,
              }}
              className='light-shadow'
            >
              <ReservationGeneral
                form={form}
                rtl={rtl}
              />
            </div>
            {/* Work Days Section */}
            <Divider />
            <div
              style={{
                backgroundColor: COLORS.white,
                padding: "10px 20px 20px",
                borderRadius: 7,
              }}
              className='light-shadow'
            >
              <ReservationWorkDays
                form={form}
                rtl={rtl}
              />
            </div>
            {/* Price Section */}
            <Divider />
            <div
              style={{
                backgroundColor: COLORS.white,
                padding: "10px 20px 20px",
                borderRadius: 7,
              }}
              className='light-shadow'
            >
              <ReservationPrices
                form={form}
                rtl={rtl}
                setDisabledValue={setDisabledValue}
                disabledValue={disabledValue}
              />
            </div>
            {/* Additional Settings Section */}
            <Divider />
            <div
              style={{
                backgroundColor: COLORS.white,
                padding: "10px 20px 20px",
                borderRadius: 7,
              }}
              className='light-shadow'
            >
              <Divider orientation={rtl ? "right" : "left"}>
                <Title className='my-font' level={5}>
                  {intl.formatMessage({ id: "AdditionalSettings" })}
                </Title>
              </Divider>
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "sortReservation" })}
                    className='mb-1'
                    name='order'
                  >
                    <InputNumber
                      style={{
                        width: "100%",
                      }}
                      className={"input"}
                      min={0}
                    />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}></Col>
                <Divider />
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    className='mb-1'
                    name='makeChooseTableRequired'
                    valuePropName='checked'
                  >
                    <Checkbox>
                      <Text className='my-font'>
                        {intl.formatMessage({ id: "makeChooseTableRequired" })}
                      </Text>
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    className='mb-1'
                    name='canPreOrder'
                    valuePropName='checked'
                  >
                    <Checkbox>
                      <Text className='my-font'>
                        {intl.formatMessage({ id: "canPreOrder" })}
                      </Text>
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                    className='mb-1'
                    valuePropName='checked'
                    name='sendNotification'
                  >
                    <Checkbox>
                      <Text className='my-font'>
                        {intl.formatMessage({
                          id: "sendNotificationReservation",
                        })}
                      </Text>
                    </Checkbox>

                  </Form.Item>

                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    className='mb-1'
                    name='enableNote'
                    valuePropName='checked'
                  >
                    <Checkbox>
                      <Text className='my-font'>
                        {intl.formatMessage({ id: "enableNote" })}
                      </Text>
                    </Checkbox>
                  </Form.Item>
                </Col>

              </Row>
              <Divider />
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                <Col md={6} sm={24} xs={24}>
                  <ReservationReasons
                    rtl={rtl}
                    form={form}
                    reasons={reasons}
                    isWithReasons={false}
                    setReasonsLength={setReasonsLength}
                    reasonsLength={reasonsLength}
                  />
                </Col>
                <Col md={8} sm={24} xs={24}>
                  <ReservationTerms />
                </Col>
                <Col md={8} sm={24} xs={24}>
                  <ReservationModifier
                    rtl={rtl}
                    form={form}
                    reservation={null}
                    type="ADD"
                  />
                </Col>
              </Row>
            </div>
            {/* Picture Section */}
            <Divider />
            <div
              style={{
                backgroundColor: COLORS.white,
                padding: "10px 20px 20px",
                borderRadius: 7,
              }}
              className='light-shadow'
            >
              <Spin spinning={false}>
                <Title className='my-font' level={4}>
                  {intl.formatMessage({ id: "pictures" })}
                </Title>
                <Dragger
                  name='picture'
                  listType='picture'
                  fileList={[]}
                  disabled={true}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <InboxOutlined
                      style={{ color: COLORS.primary, fontSize: "2rem" }}
                      className='mx-3'
                    />
                    <Text
                      style={{ fontSize: "1rem", color: COLORS.primary }}
                      className='my-font'
                    >
                      {intl.formatMessage({ id: "youCanAddImagesAfterSaving" })}
                    </Text>
                  </div>
                </Dragger>
              </Spin>
            </div>
            <Divider />
            <div
              style={{
                backgroundColor: COLORS.white,
                padding: "10px 20px 20px",
                borderRadius: 7,
              }}
              className='light-shadow'
            >
              <Row type='flex' justify='space-between'>
                <Col md={11} sm={24} xs={24} >
                  <Form.Item
                    label={`${intl.formatMessage({
                      id: "ReservationStatus",
                    })}`}
                    className='mb-1'
                    name='status'
                    valuePropName='checked'
                  >
                    <Switch
                      checkedChildren={
                        <p>
                          {intl.formatMessage({
                            id: "active",
                          })}
                        </p>
                      }
                      unCheckedChildren={
                        <p>
                          {intl.formatMessage({
                            id: "disabled",
                          })}
                        </p>
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <Divider />
            {/* Submit Button Section */}
            <Space direction='horizontal'>
              <Form.Item>
                <CustomButton
                  htmlType='submit'
                  text={intl.formatMessage({ id: "save" })}
                  className={`btnRegister btn-text border-8`}
                  loading={loading}
                  disabled={loading}
                  type='primary'
                />
              </Form.Item>
              <Form.Item>
                <CustomButton
                  type='text'
                  text={intl.formatMessage({ id: "cancel" })}
                  disabled={loading}
                  onClick={() => {
                    hide();
                  }}
                />
              </Form.Item>
            </Space>
          </>
        )}
      </Form>
    </div>
  );
};

export default AddReservationSchemaForm;
