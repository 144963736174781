import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Popconfirm,
  Typography,
  Divider,
  Space,
  Row,
  Col,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import COLORS from "../../../Style/colors";
import CustomButton from "../../../Components/CustomButton";

import openNotification from "../../../Components/Notifications";

import { updateWaitingArea } from "../../../API/fetch";
import { setBranchAction } from "../../../redux/reducers/branchReducer";

const { Option } = Select;
const { Text } = Typography;

const EditWaitingAreaModal = ({
  show,
  hide,
  area,
  canDelete,
  setActiveTab,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { branch } = useSelector((state) => state.branch);
  const { rtl } = useSelector((state) => state.settings);
  const [isNew, setIsNew] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showAddZone, setShowAddZone] = useState(false);
  const [newZoneName, setNewZoneName] = useState("");
  const [form] = Form.useForm();

  useEffect(() => {
    if (area?._id) {
      setIsNew(false);
    }
  });

  const editArea = async (values) => {
    try {
      setLoading(true);
      const data = {
        ...values,
        _id: area._id,
      };
      const res = await updateWaitingArea(branch._id, data);
      dispatch(setBranchAction(res.data.branch));
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setLoading(false);
      hide();
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };
  const addArea = async (values) => {
    try {
      // setLoading(true);
      // const data = {
      //   area: { ...values, owner: waiter.branchId },
      //   code: waiter.code,
      // };
      // const res = await postAddArea(data);
      // dispatch(addNewAreaAction(res.data.area));
      // openNotification({
      //   title: intl.formatMessage({ id: "savedSuccessfully" }),
      //   type: "success",
      //   rtl,
      // });
      // setLoading(false);
      // hide();
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const handleDeleteArea = async () => {
    try {
      setLoading(true);
      if (branch?.waitingArea.length === 1) {
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "canNotDeleteLastOne" }),
          type: "error",
          rtl,
        });

        return;
      }
      const data = {
        status: "deleted",
        _id: area._id,
      };
      const res = await updateWaitingArea(branch._id, data);
      dispatch(setBranchAction(res.data.branch));
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setLoading(false);
      setActiveTab(res.data.branch.waitingArea[0]._id);
      hide();
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  return (
    <Modal
      title={
        <p className='my-font'>{intl.formatMessage({ id: "waitingArea" })}</p>
      }
      centered
      visible={show}
      destroyOnClose={true}
      mask={true}
      maskClosable={true}
      onCancel={() => {
        hide();
        setIsNew(true);
      }}
      footer={null}
    >
      {/* Zone Modal */}
      <Modal
        title={intl.formatMessage({ id: "addZone" })}
        centered
        visible={showAddZone}
        destroyOnClose={true}
        mask={true}
        maskClosable={true}
        onCancel={() => setShowAddZone(false)}
        onOk={() => {
          // dispatch(addNewZoneAction(newZoneName));
          // setShowAddZone(false);
          // form.setFieldsValue({ zone: newZoneName });
        }}
        okText={intl.formatMessage({ id: "save" })}
        cancelText={intl.formatMessage({ id: "cancel" })}
        cancelButtonProps={{
          type: "text",
          style: { border: "none", fontFamily: "Cairo" },
        }}
        okButtonProps={{
          style: { borderRadius: 5, padding: "3px 30px", fontFamily: "Cairo" },
        }}
      >
        <Input
          value={newZoneName}
          onChange={(e) => setNewZoneName(e.target.value)}
        />
      </Modal>
      <Form
        name='editArea'
        form={form}
        preserve={false}
        className='form-container'
        layout='vertical'
        onFinish={isNew ? addArea : editArea}
        initialValues={
          isNew
            ? {
                name: "",
                name_en: "",
                maxChairs: 12,
                waitingTimePerUser: 0,
                maxUsers: 4,
                status: "Active",
                waitingNote: "",
                waitingNote_en: "",
              }
            : {
                name: area?.name,
                name_en: area?.name_en,
                maxChairs: area?.maxChairs,
                maxUsers: area?.maxUsers,
                status: area?.status,
                waitingTimePerUser: area?.waitingTimePerUser,
                waitingNote: area?.waitingNote,
                waitingNote_en: area?.waitingNote_en,
              }
        }
      >
        <Form.Item
          label={intl.formatMessage({ id: "nameAr" })}
          className='mb-1'
          name='name'
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: "errorname" }),
            },
          ]}
        >
          <Input className={"input"} />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: "nameEn" })}
          className='mb-1'
          name='name_en'
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: "errorname" }),
            },
          ]}
        >
          <Input className={"input"} />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: "maxUsers" })}
          className='mb-1'
          name='maxUsers'
        >
          <InputNumber
            style={{
              width: "100%",
            }}
            className={"input"}
            min={0}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: "maxChairs" })}
          className='mb-1'
          name='maxChairs'
        >
          <InputNumber
            style={{
              width: "100%",
            }}
            className={"input"}
            min={0}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: "waitingTimePerUser" })}
          tooltip={<Text className="my-font">{intl.formatMessage({ id: "waitingTimePerUserExplain"})}</Text>}
          className='mb-1'
          name='waitingTimePerUser'
        >
          <InputNumber
            style={{
              width: "100%",
            }}
            className={"input"}
            min={0}
          />
        </Form.Item>
        <Row gutter={{ xs: 16, sm: 16, md: 32, lg: 40 }}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Form.Item
              label={intl.formatMessage({ id: "noteAr" })}
              className='mb-1'
              name='waitingNote'
              tooltip={
                <Text className='my-font'>
                  {intl.formatMessage({ id: "waitingNote" })}
                </Text>
              }
            >
              <Input className={"input"} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Form.Item
              label={intl.formatMessage({ id: "noteEn" })}
              className='mb-1'
              name='waitingNote_en'
              tooltip={
                <Text className='my-font'>
                  {intl.formatMessage({ id: "waitingNote" })}
                </Text>
              }
            >
              <Input className={"input"} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label={intl.formatMessage({ id: "status" })}
          className='mb-1'
          name='status'
        >
          <Select className="my-font">
            <Option value='Active' className='my-font'>
              {intl.formatMessage({ id: "Active" })}
            </Option>
            <Option value='availableTable' className='my-font'>
              {intl.formatMessage({ id: "availableTable" })}
            </Option>
            <Option value='disabled' className='my-font'>
              {intl.formatMessage({ id: "disabled" })}
            </Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Popconfirm
            title={
              <Text className='my-font'>
                {intl.formatMessage({ id: "confirmDeleteChoiceTitle" })}
              </Text>
            }
            okText={intl.formatMessage({ id: "delete" })}
            okButtonProps={{
              style: { color: COLORS.danger, fontFamily: "Cairo" },
            }}
            okType='text'
            cancelText={intl.formatMessage({ id: "cancel" })}
            cancelButtonProps={{
              style: { border: "none", fontFamily: "Cairo" },
            }}
            onConfirm={handleDeleteArea}
            disabled={!canDelete}
          >
            <Text
              type='danger'
              disabled={!canDelete}
              className='pointer my-font'
            >
              {intl.formatMessage({ id: "delete" })}
            </Text>
          </Popconfirm>
        </Form.Item>
        <Divider />
        <Space direction='horizontal'>
          <Form.Item>
            <CustomButton
              htmlType='submit'
              text={intl.formatMessage({ id: "save" })}
              className={`btnRegister btn-text border-8`}
              loading={loading}
              disabled={loading}
              type='primary'
            />
          </Form.Item>

          <Form.Item>
            <CustomButton
              type='text'
              text={intl.formatMessage({ id: "cancel" })}
              disabled={loading}
              onClick={() => {
                hide();
              }}
            />
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  );
};

export default EditWaitingAreaModal;
