import React, { useState, useEffect } from "react";
import { Row, Col, Typography, Image, Avatar } from "antd";
import QRCode from "qrcode.react";
import { useSelector } from "react-redux";
import LOGO from "../../../assets/images/icon-iwaiter.png";
const { Text, Title } = Typography;

const QRPreview = ({
  backgroundColor,
  textColor,
  qrBackground,
  qrColor,
  title,
  titleEn,
  subTitle,
  subTitleEn,
  footer,
  footerEn,
  branch,
  tables,
  size,
  name,
  nameEn,
  imageURL,
  slug,
  domain,
  showLogoInQR,
  allTables,
}) => {
  const { rtl } = useSelector((state) => state.settings);
  const {services}= useSelector((state) => state.auth);
  const clineURL = "https://app.iwaiter.club";
  const [urlForTable, setUrlForTable] = useState("");
  const [selectedTable, setSelectedTable] = useState(allTables[0]);
  useEffect(() => {
    let selected = allTables?.[0];
    if (tables?.length > 0) {
      selected = tables[0];
    }
    let url = `${clineURL}/${slug}`;
    if(services?.includes("ORDER_A") ||
    services?.includes("ORDER_B") ){
      url = clineURL + "/" + slug + "/" + branch + "/" + selected?._id;
      if (domain !== "iWaiter") {
        url = domain + "/" + branch + "/" + selected?._id;
      }
    }
    setUrlForTable(url);
    setSelectedTable(selected);
  }, [domain, branch, tables, services]);


  return (
    <div
      style={{
        backgroundColor,
        paddingTop: 8,
        paddingBottom: 8,
      }}
    >
      <Row
        style={{
          width: "100%",
        }}
        direction='horizontal'
      >
        <Col
          span={12}
          style={{
            borderLeft: rtl ? `3px solid ${textColor}` : "none",
            borderRight: rtl ? "none" : `3px solid ${textColor}`,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              style={{
                paddingLeft: 20,
              }}
              src={imageURL}
              preview={false}
              height={80}
            />
          </div>
        </Col>
        <Col span={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Title
              style={{
                marginBottom: 5,
                paddingBottom: 0,
                color: textColor,
              }}
              level={4}
            >
              {rtl ? name : nameEn}
            </Title>
            <Title
              style={{ marginTop: 0, paddingTop: 0, color: textColor }}
              level={4}
            >
              # {rtl ? selectedTable?.name : selectedTable?.name_en}
            </Title>
          </div>
        </Col>
      </Row>
      <Row
        style={{ width: "100%", justifyContent: "center", marginTop: "1.5cm" }}
      >
        <Title level={4} style={{ color: textColor }}>
          {rtl ? title : nameEn}
        </Title>
      </Row>
      <Row
        style={{ width: "100%", justifyContent: "center", marginTop: "0.5cm" }}
      >
        <Title level={3} style={{ color: textColor, textAlign: "center" }}>
          {rtl ? subTitle : subTitleEn}
        </Title>
      </Row>
      <Row
        style={{ width: "100%", justifyContent: "center", marginTop: "0.5cm" }}
      >
        <div
          style={{
            display: "flex",
            backgroundColor: qrBackground,
            padding: 10,
            borderRadius: 10,
          }}
        >
          <QRCode
            bgColor={qrBackground}
            fgColor={qrColor}
            level='Q'
            style={{ width: "4cm" }}
            value={urlForTable}
            imageSettings={
              showLogoInQR
                ? {
                    src: imageURL,
                    width: 30,
                    height: 30,
                  }
                : null
            }
          />
        </div>
      </Row>
      <Row
        style={{ width: "100%", justifyContent: "center", marginTop: "1cm" }}
      >
        <Title level={5} style={{ color: textColor }}>
          {rtl ? footer : footerEn}
        </Title>
      </Row>
      <Row
        style={{
          width: "100%",
          justifyContent: "center",
          marginTop: "1cm",
          alignItems: "center",
        }}
      >
        <Col>
          <Text className='my-font' style={{ color: textColor }} level={5}>
            {"iwaiter.net"}
          </Text>
        </Col>
        <Col className='mx-1'>
          <Avatar shape={"square"} src={LOGO} size={25} />
        </Col>
      </Row>
    </div>
  );
};

export default QRPreview;
