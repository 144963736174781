import React, { useState, useEffect } from "react";
import "chart.js/auto";
import { Chart, } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import COLORS from "../../../Style/colors";
import { Typography, Space, Spin } from "antd";
import { useIntl } from "react-intl";
import MyTimePicker from "./MyTimePicker";
import {
  getAnalyticsRetentionRateByDate,
} from "../../../API/fetch";
import { setGoogleDataAction } from "../../../redux/reducers/analyticsReducer";

const { Title } = Typography;

const RetentionRate = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { googleData } = useSelector((state) => state.analytics);
  const { restaurant } = useSelector((state) => state.restaurant);
  const { rtl } = useSelector((state) => state.settings);
  const [labels, setLabels] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalViews, setTotalViews] = useState(0);

  useEffect(() => {
    if (googleData?.usersType?.length > 0) {
      let labels = [];
      let data = [];
      // get total views;
      let totalViews = 0;
      googleData.usersType.forEach((user) => {
        totalViews += user.views;
      });
      googleData.usersType.forEach(one => {
       let text = one.type === "new" ?  "newCustomer" : "returnCustomer";
          const percentage =(parseInt(one.views) /totalViews) * 100;
          labels.push(
          `${intl.formatMessage({ id: text })} ${percentage?.toFixed()}%`
        );
        data.push(one.views)
      })
      setLabels(labels);
      setData(data);
      setTotalViews(totalViews);
    }
  }, [googleData, rtl]);

  const getDataForTime = async ({ start, end }) => {
    try {
      setLoading(true);
      const data = {
        start,
        end,
        id: restaurant?._id,
      };
      const res = await getAnalyticsRetentionRateByDate(data);
      if (res.data?.status === 'success') {
        const copy = {
          ...googleData,
          usersType: res.data.usersType,
        };
        dispatch(setGoogleDataAction(copy));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };


  return (
    <div>
      <Spin spinning={loading}>
        <Space
          direction='horizontal'
          style={{ width: "100%", justifyContent: "space-between" }}
        >
          <div>
            <Title style={{ marginBottom: 0, paddingBottom: 0 }} level={4}>
              {intl.formatMessage({ id: "newOldUsers" })}
            </Title>
            <Title
              style={{
                marginBottom: 0,
                paddingBottom: 0,
                marginTop: 0,
                paddingTop: 0,
              }}
              level={5}
            >
               
              {totalViews > 0
                ? new Intl.NumberFormat().format(totalViews)
                : null}{" "}
              - {intl.formatMessage({ id: "visit" })}
            </Title>
          </div>
          <MyTimePicker initialTime="last30days" loading={loading} getData={getDataForTime} />
        </Space>
        <Chart
          type='bar'
          data={{
            labels,
            datasets: [
              {
                label: "",
                data: data,
                backgroundColor: [
                  "rgba(127,127,127, 0.6)",
                  COLORS.primary,
                  COLORS.lightBlue,
                ],
                hoverBackgroundColor: [
                  "rgba(127,127,127, 0.6)",
                  COLORS.primary,
                  COLORS.lightBlue,
                ],
              },
            ],
          }}
          options={{
            responsive: true,
            maintainAspectRatio: true,
            plugins: {
              subtitle: {
                display: false,
              },
              legend: {
                display: false,
              },
            },
          }}
        />
      </Spin>
    </div>
  );
};

export default RetentionRate;
