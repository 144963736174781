export const formatModifiers = ({modifierIds, rtl, note='', noteTitle=''}) => {
  let modifiers = [];
  let extraPrice = 0;
  modifierIds.length > 0 &&
    modifierIds.forEach(({ options, modId }) => {
      options.length > 0 &&
        options.forEach((o, i) => {
          const selectedOption = modId?.options?.find((mod) => mod._id === o);
          if (
            options.length > modId?.numberOfFreeChoices &&
            i + 1 > modId?.numberOfFreeChoices
          ) {
            extraPrice += parseFloat(selectedOption?.price);
          }
          selectedOption?.name_en
            ? rtl
              ? modifiers.push({
                  groupName: modId?.name,
                  selected: selectedOption?.name,
                })
              : modifiers.push({
                  groupName: modId?.name_en,
                  selected: selectedOption?.name_en,
                })
            : modifiers.push({
                groupName: modId?.name,
                selected: selectedOption?.name,
              });
        });
    });
  let formattedModifiers = [];
  if (modifiers.length > 0) {
    modifiers.forEach((modifier,) => {
      const i = formattedModifiers.findIndex(
        (m) => m.groupName === modifier.groupName
      );
      if (i === -1) {
        // new one
        formattedModifiers.push({
          groupName: modifier.groupName,
          selected: [modifier.selected],
        });
      } else {
        // old one
        formattedModifiers[i].selected.push(modifier.selected);
      }
    });
  }

  // add Notes;
  if(note){
    formattedModifiers.push({
      groupName: noteTitle,
      selected: [note]
    })
  }

  return {
    modifiersResult: formattedModifiers,
    addedPrice: extraPrice,
  };
};
export const getTotalReservationModifiers = (options) => {
  const total = Object.values(options)
    .map((el) =>
      el?.options
        .map((el) => el?.price * el?.initialQuantity)
        .reduce((a, b) => a + b, 0)
    )
    .reduce((a, b) => a + b, 0);
  return total;
};

export const getModifiersOptions = (options) => {
  let newOptions = [];
  Object.values(options).map((el) => {
    let note = "";
    if (el?.note) {
      note = el?.note;
    }
    el?.options.map((op) =>
      newOptions.push({
        name_en: op?.name_en,
        name: op?.name,
        qty: op?.initialQuantity,
        modifierId: op?.modifierId,
        note,
      })
    );
  });
  return newOptions;
};