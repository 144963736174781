import React, { useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Spin,
  Divider,
  Switch,
  Select,
  Typography,
  Input,
  Upload,
  Modal,
  message,
  Space,
} from "antd";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../Components/CustomButton";
import COLORS from "../../Style/colors";
import openNotification from "../../Components/Notifications";
import apiUrl from "../../API/config";
import { InboxOutlined } from "@ant-design/icons";
import { setDesignAction, setRestaurantAction } from "../../redux/reducers/restaurantReducer";
import { createWalletRestaurant, editDesign, postUpdateRestaurant } from "../../API/fetch";
import { checkIfValidPhone } from "../../helpers/index";
import phoneCodes from '../Auth/PhoneCods.json';
import { IoPaperPlaneOutline, IoShareSocialOutline ,IoReader} from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const { Option } = Select;
const { Title, Text } = Typography;
const acceptedTypes = ["image/png", "image/jpg", "image/jpeg"];
const { Dragger } = Upload;

const prefixSelector = (
  <Form.Item name='prefix' noStyle>
    <Select
      style={{
        width: 150,
      }}
    >
      {phoneCodes.map((info) => {
        return (
          <Option
            key={`${info.dial_code}-${info.name}`}
            value={info.dial_code}
          >
            {info.dial_code} {info.name}
          </Option>
        );
      })}
    </Select>
  </Form.Item>
);

const Restaurant = () => {
  const intl = useIntl();
  const navigate = useNavigate()
  const [form] = Form.useForm();
  const { rtl } = useSelector((state) => state.settings);
  const { user, waiter, subscriptionEnd } = useSelector((state) => state.auth);
  const { restaurant, design } = useSelector((state) => state.restaurant);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [logos, setLogos] = useState([]);
  const [coverImages, setCoverImages] = useState([]);
  const [errorSizeImage, setErrorSizeImage] = useState(false)
  const [errorSizeCover, setErrorSizeCover] = useState(false)

  useEffect(() => {
    if (restaurant?._id && restaurant?.logo?.length) {
      let log = restaurant?.logo?.[0];
      setLogos([
        {
          uid: log._id,
          status: "done",
          url: log.url,
          name: `${restaurant.name}`,
        },
      ]);
    }
    if (restaurant?._id && restaurant?.coverImage?.url) {
      let cover = restaurant?.coverImage;
      setCoverImages([
        {
          uid: cover._id,
          status: "done",
          url: cover.url,
          name: `${restaurant.name}`,
        },
      ]);
    }
  }, [restaurant]);

  const onFinish = async (values) => {
    try {
      let vatNumber = "NON";
      if (values.vatNumber) {
        vatNumber = values.vatNumber;
      }
      const fullPhone = `${values.prefix.substring(1)}${values.phone}`;

      setLoading(true);
      const data = {
        restaurant: {
          ...values,
          vatNumber,
          phone: fullPhone,
        },
        code: waiter.code,
      };
      const res = await postUpdateRestaurant(restaurant._id, data);
      const newRest = {
        ...restaurant,
        ...values,
      };
      dispatch(setRestaurantAction(newRest));
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
    } catch (error) {
      console.log(error);
      setLoading(false); // hide loading;
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };
  //when remove the cover image : we set the "showCoverImageInMenu" to default value (false)
  const updateShowCoverImageInMenu = async () => {
    try {
      const data = {
        restaurant: {
          design: {
            ...design,
            showCoverImageInMenu: false,
          },
        },
      };
      const res = await editDesign(data, restaurant._id);
      if (res.data?.result?.newColors) {
        dispatch(setDesignAction(res.data.result.newColors));
      }
    } catch (error) {
      console.log(error)
    }
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const beforeUpload = (file) => {
    if (!acceptedTypes.includes(file?.type) || !file) {
      message.error("You can only upload JPG/PNG file!");
      return false;
    }
    if (file.type?.includes("image")) {
      if (file.size / 1000000 > 2.2) {
        message.error(intl.formatMessage({ id: "limit2Mb" }));
        return false;
      }
    }

    return true;
  };

  // handle image upload;
  const handleChangeImages = ({ fileList, file }, type) => {
    let newFileList = [...fileList];
    setErrorSizeCover(false)
    setErrorSizeImage(false)
    let errors = []
    newFileList.forEach(el => {
      if (el.size > 2000000) { //check if the image size is bigger than 2Mo
        errors.push(el?.uid)
      }
    })
    if (errors.length > 0) { //return error if there is images bigger than 2Mo
      if (type === "cover") {
        setErrorSizeCover(true)
      } else {
        setErrorSizeImage(true)
      }
      return
    }
    if (file.status === "uploading") {
      setLoading(true); // show loading;
    } else if (file.status === "done") {
      // upload success;
      setLoading(false); // hide loading;
      if (file.response?.restaurant?._id) {
        if (file?.response.restaurant?._id) {
          const newRest = {
            ...restaurant,
            logo: file.response.restaurant.logo,
            coverImage: file.response.restaurant.coverImage,
          };
          dispatch(setRestaurantAction(newRest));
          openNotification({
            title: intl.formatMessage({ id: "savedSuccessfully" }),
            type: "success",
            rtl,
          });
        }
      }
    } else if (file.status === "error") {
      setLoading(false); // hide loading;
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
    if (type === "logo") {
      setLogos([file]);
    } else {
      setCoverImages([file]);
    }
  };

  // handle delete image;
  const deleteImage = async (e, type) => {
    if (type === "logo") {
      // can not delete logo;
      openNotification({
        title: (
          <Text className='my-font'>{intl.formatMessage({ id: "error" })}</Text>
        ),
        description: (
          <Text className='my-font'>
            {intl.formatMessage({ id: "canNotDeleteLogo" })}
          </Text>
        ),
        type: "error",
        rtl,
      });
      return;
    }
    try {
      setLoading(true); // show loading;
      const data = {
        restaurant: {
          coverImage: null,
        },
        code: waiter.code,
        deleteCover: true,
        coverURL: restaurant?.coverImage?.url,
      };
      const res = await postUpdateRestaurant(restaurant._id, data);
      const newRest = {
        ...restaurant,
        coverImage: null,
      };
      dispatch(setRestaurantAction(newRest));
      setTimeout(() => {
        setCoverImages([])
      }, 500)
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
    } catch (error) {
      console.log(error);
      setLoading(false); // hide loading;
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  // handle create wallet;
  const createWallet = async () => {
    if (restaurant?.wallet) return;

    try {
      setLoading(true);
      const res = await createWalletRestaurant();
      const newRest = {
        ...restaurant,
        wallet: res?.data?.wallet,
      };
      dispatch(setRestaurantAction(newRest));
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
    } catch (error) {
      console.log(error);
      setLoading(false); // hide loading;
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  return (
    <div>
      {/* Image preview */}
      <Modal
        visible={previewVisible}
        title={rtl ? restaurant?.name : restaurant?.name_en}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
      >
        <img
          alt={restaurant?.name}
          style={{ width: "100%" }}
          src={previewImage}
        />
      </Modal>
      <div
        style={{
          borderRadius: 6,
          marginTop: 40,
          width: "92%",
          marginRight: "auto",
          marginLeft: "auto",
          padding: 15,
        }}
      >
        <Spin spinning={loading}>
          <Form
            name='food-form'
            form={form}
            className='form-container'
            layout='vertical'
            onFinish={onFinish}
            initialValues={{
              name: restaurant?.name,
              name_en: restaurant?.name_en,
              description: restaurant?.description,
              description_en: restaurant?.description_en,
              vatNumber: restaurant?.vatNumber,
              email: restaurant?.email,
              phone: restaurant?.phone?.substring(3),
              // payOnlineDineIn: restaurant?.payOnlineDineIn,
              // payOnlinePickup: restaurant?.payOnlinePickup,
              // payOnlineDelivery: restaurant?.payOnlineDelivery,
              // payCashDineIn: restaurant?.payCashDineIn,
              // payCashPickup: restaurant?.payCashPickup,
              // payCashDelivery: restaurant?.payCashDelivery,
              // splitPaymentChoice: restaurant?.splitPaymentChoice,
              providePhoneAndNameForDineIn:
                restaurant?.providePhoneAndNameForDineIn,
              prefix: restaurant?.phone ? phoneCodes.find(c => c.dial_code.substring(1) === restaurant?.phone?.substring(0, 3))?.dial_code : '',
            }}
          >

            {() => (
              <div style={{ backgroundColor: COLORS.gray }}>
                <div
                  style={{
                    backgroundColor: COLORS.white,
                    padding: "10px 20px 20px",
                    borderRadius: 7,
                  }}
                  className='light-shadow'
                >
                  <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                    {/* Title */}
                    <Col md={12} sm={24} xs={24}>
                      <Title className='my-font' level={5}>
                        {intl.formatMessage({ id: "basicInfo" })}
                      </Title>
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                    {/* Names */}
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        label={intl.formatMessage({ id: "nameAr" })}
                        className='mb-1'
                        name='name'
                        style={{ borderRadius: 20 }}
                        rules={[
                          {
                            required: true,
                            message: intl.formatMessage({ id: "errorname" }),
                          },
                        ]}
                      >
                        <Input
                          className={"input"}
                          style={{ borderRadius: 5, width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        label={intl.formatMessage({ id: "nameEn" })}
                        className='mb-1'
                        name='name_en'
                        rules={[
                          {
                            required: true,
                            message: intl.formatMessage({ id: "errorname" }),
                          },
                        ]}
                      >
                        <Input className={"input"} style={{ borderRadius: 5 }} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className='mt-4' gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                    {/* Descriptions */}
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        label={intl.formatMessage({ id: "descriptionArRest" })}
                        className='mb-1'
                        name='description'
                        style={{ borderRadius: 20 }}
                      >
                        <Input.TextArea
                          className={"input"}
                          placeholder={intl.formatMessage({ id: "descriptionAr" })}
                          style={{ borderRadius: 5 }}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        label={intl.formatMessage({ id: "descriptionEnRest" })}
                        className='mb-1'
                        name='description_en'
                      >
                        <Input.TextArea
                          className={"input"}
                          placeholder={intl.formatMessage({ id: "descriptionEn" })}
                          style={{ borderRadius: 5 }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className='mt-4' gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                    {/* Vat and email */}
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        label={intl.formatMessage({ id: "vatNumber" })}
                        className='mb-1'
                        name='vatNumber'
                        style={{ borderRadius: 20 }}
                      >
                        <Input
                          className={"input"}
                          style={{ borderRadius: 5, width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        label={intl.formatMessage({ id: "email" })}
                        className='mb-1'
                        name='email'
                      >
                        <Input
                          className={"input"}
                          style={{ borderRadius: 5 }}
                          disabled={restaurant?.email ? true : false}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className='mt-4' gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                    {/* Phone*/}
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        name='phone'
                        label={intl.formatMessage({ id: "restaurantPhone" })}
                        rules={[
                          {
                            required: true,
                            message: intl.formatMessage({ id: "errorphone" }),
                          },
                          {
                            validator: (_, value) => {
                              const prefix = form.getFieldValue("prefix");
                              if (!prefix) {
                                return Promise.reject(
                                  intl.formatMessage({ id: "errorphone" })
                                );
                              }
                              let valid = checkIfValidPhone(`${prefix}${value}`);
                              if (valid) {
                                return Promise.resolve();
                              } else {
                                return Promise.reject(
                                  intl.formatMessage({ id: "errorphone" })
                                );
                              }
                            },
                          },
                        ]}
                      >
                        <Input
                          addonBefore={rtl ? null : prefixSelector}
                          addonAfter={rtl ? prefixSelector : null}
                          style={{
                            width: "100%",
                          }}
                        />
                      </Form.Item>

                    </Col>

                  </Row>
                </div>
                <Divider />
                {/* <Divider /> */}
                {/* <Row className='mb-5' gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
              <Col md={24} sm={24} xs={24}>
                <Title className='my-font' level={4}>
                  {intl.formatMessage({ id: "paymentOptions" })}
                </Title>
                {!restaurant?.wallet ? (
                  <>
                    <Space direction='vertical'>
                      <CustomButton
                        text={intl.formatMessage({ id: "addPayment" })}
                        className={`btnRegister btn-text border-8 px-5 `}
                        loading={loading}
                        disabled={loading}
                        type='primary'
                        onClick={createWallet}
                      />
                      <Text className='my-font' type='secondary'>
                        {intl.formatMessage({ id: "paymentOptionsDes" })}
                      </Text>
                    </Space>
                    <br />
                  </>
                ) : null}

                <Text className='my-font' type='secondary'>
                  * {intl.formatMessage({ id: "paymentPercentage" })}
                </Text>
              </Col>
            </Row>
            <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
              <Col span={8}>
                <Text></Text>
              </Col>
              <Col span={8}>
                <Title level={5} className='my-font'>
                  {" "}
                  {intl.formatMessage({ id: "onlinePayment" })}
                </Title>
              </Col>
              <Col span={8}>
                <Title level={5} className='my-font'>
                  {" "}
                  {intl.formatMessage({ id: "cashPayment" })}
                </Title>
              </Col>
            </Row>
            <Row
              style={{ borderBottom: "2px solid #f0f2f5" }}
              gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
            >
              <Col span={8}>
                <Title level={5} className='my-font'>
                  {intl.formatMessage({ id: "dineIn" })}
                </Title>
              </Col>
              <Col span={8}>
                <Form.Item
                  className='mb-1'
                  name='payOnlineDineIn'
                  valuePropName='checked'
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Switch
                    className='mb-1 mx-2'
                    disabled={!restaurant?.wallet}
                    onChange={(e) => {
                      if (!e) {
                        form.setFieldsValue({
                          payCashDineIn: true,
                        });
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className='mb-1'
                  name='payCashDineIn'
                  valuePropName='checked'
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Switch
                    className='mb-1 mx-2'
                    onChange={(e) => {
                      if (!e && restaurant?.wallet) {
                        form.setFieldsValue({
                          payOnlineDineIn: true,
                        });
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row
              style={{ borderBottom: "2px solid #f0f2f5" }}
              gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
            >
              <Col span={8}>
                <Title level={5} className='my-font'>
                  {intl.formatMessage({ id: "pickupOrder" })}
                </Title>
              </Col>
              <Col span={8}>
                <Form.Item
                  className='mb-1'
                  name='payOnlinePickup'
                  valuePropName='checked'
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Switch
                    className='mb-1 mx-2'
                    disabled={!restaurant?.wallet}
                    onChange={(e) => {
                      if (!e) {
                        form.setFieldsValue({
                          payCashPickup: true,
                        });
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className='mb-1'
                  name='payCashPickup'
                  valuePropName='checked'
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Switch
                    className='mb-1 mx-2'
                    onChange={(e) => {
                      if (!e && restaurant?.wallet) {
                        form.setFieldsValue({
                          payOnlinePickup: true,
                        });
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row
              style={{ borderBottom: "2px solid #f0f2f5" }}
              gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
            >
              <Col span={8}>
                <Title level={5} className='my-font'>
                  {intl.formatMessage({ id: "delivery" })}
                </Title>
              </Col>
              <Col span={8}>
                <Form.Item
                  className='mb-1'
                  name='payOnlineDelivery'
                  valuePropName='checked'
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Switch
                    className='mb-1 mx-2'
                    disabled={!restaurant?.wallet}
                    onChange={(e) => {
                      if (!e && restaurant?.wallet) {
                        form.setFieldsValue({
                          payCashDelivery: true,
                        });
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className='mb-1'
                  name='payCashDelivery'
                  valuePropName='checked'
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Switch
                    className='mb-1 mx-2'
                    onChange={(e) => {
                      if (!e) {
                        form.setFieldsValue({
                          payOnlineDelivery: true,
                        });
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            </Row> */}

                {/* <Divider /> */}
                {/* <Row
              style={{ borderBottom: "2px solid #f0f2f5" }}
              gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
            >
              <Col span={8}>
                <Title level={5} className='my-font'>
                  {intl.formatMessage({ id: "splitPayments" })}
                </Title>
              </Col>
            <Col span={8}>
                <Form.Item
                  className='mb-1'
                  name='splitPaymentChoice'
                  valuePropName='checked'
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Switch
                    className='mb-1 mx-2'
                    disabled={!restaurant?.wallet}
                    
                  />
                </Form.Item>
              </Col>
              </Row> */}
                <div
                  style={{
                    backgroundColor: COLORS.white,
                    padding: "10px 20px 20px",
                    borderRadius: 7,
                  }}
                  className='light-shadow'
                >
                  <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                    {/* Title */}
                    <Col md={12} sm={24} xs={24}>
                      <Title className='my-font' level={5}>
                        {intl.formatMessage({ id: "AdditionalSettings" })}
                      </Title>
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                    <Col md={8} sm={24} xs={24}>
                      <CustomButton
                        type='primary'
                        icon={<IoShareSocialOutline style={{ marginLeft: 5, marginRight: 5 }} />}
                        text={intl.formatMessage({ id: "socialIcons" })}
                        className={`btnRegister btn-text border-8 px-5 mt-2`}
                        onClick={() => navigate("/socialIcons")}
                        disabled={subscriptionEnd}
                      />
                    </Col>
                    <Col md={8} sm={24} xs={24}>
                      <CustomButton
                        icon={<IoPaperPlaneOutline style={{ marginLeft: 5, marginRight: 5 }} />}
                        type='primary'
                        text={intl.formatMessage({ id: "feedback" })}
                        className={`btnRegister btn-text border-8 px-5 mt-2`}
                        onClick={() => navigate("/feedback")}
                        disabled={subscriptionEnd}
                      />
                    </Col>
                    <Col md={8} sm={24} xs={24}>
                      <CustomButton
                        icon={<IoReader style={{ marginLeft: 5, marginRight: 5 }} />}
                        type='primary'
                        text={intl.formatMessage({ id: "menuButtonSettings" })}
                        className={`btnRegister btn-text border-8 px-5 mt-2`}
                        onClick={() => navigate("/buttonsMenu")}
                        disabled={subscriptionEnd}
                      />
                    </Col>
                  </Row>
                </div>
                <Divider />
                <div
                  style={{
                    backgroundColor: COLORS.white,
                    padding: "10px 20px 20px",
                    borderRadius: 7,
                  }}
                  className='light-shadow'
                >
                  <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                    {/* Customer information */}
                    <Col md={12} sm={24} xs={24}>
                      <Title className='my-font' level={5}>
                        {intl.formatMessage({ id: "dineinOrderData" })}
                      </Title>
                    </Col>
                  </Row>
                  <Form.Item
                    className='mb-1 my-font'
                    name='providePhoneAndNameForDineIn'
                    style={{ borderRadius: 20 }}
                  >
                    <Select className='my-font'>
                      <Option value='NON' className='my-font'>
                        {intl.formatMessage({ id: "doNotAsk" })}
                      </Option>
                      <Option value='OPTIONAL' className='my-font'>
                        {intl.formatMessage({ id: "OPTIONAL" })}
                      </Option>
                      <Option value='MUST' className='my-font'>
                        {intl.formatMessage({ id: "MUST" })}
                      </Option>
                    </Select>
                  </Form.Item>
                </div>
                <Divider />
                <div
                  style={{
                    backgroundColor: COLORS.white,
                    padding: "10px 20px 20px",
                    borderRadius: 7,
                  }}
                  className='light-shadow'
                >
                  <Row className='mt-4' gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    {/* Logo */}
                    <Col md={12} sm={24} xs={24}>
                      <Spin spinning={loading}>
                        <Title className='my-font' level={5}>
                          {intl.formatMessage({ id: "logo" })}
                        </Title>
                        <div style={{ position: "absolute", top: 15, display: "flex", justifyContent: "center", width: "100%" }}>
                          {errorSizeImage ? <Text className='my-font' style={{ color: COLORS.danger }}>
                            {intl.formatMessage({ id: "limit2Mb" })}
                          </Text> : null}
                        </div>
                        <Dragger
                          action={`${apiUrl}/restaurant-upload-logo/${restaurant?._id}?slug=${restaurant?.slug}&logo=logo`}
                          headers={{ authorization: "Bearer " + user.token }}
                          name='picture'
                          fileList={logos}
                          onRemove={(e) => deleteImage(e, "logo")}
                          listType='picture'
                          onChange={(e) => handleChangeImages(e, "logo")}
                          onPreview={onPreview}
                          beforeUpload={beforeUpload}
                          multiple={false}
                          maxCount={0}
                          accept='image/jpg,image/jpeg,image/png,video/mp4'
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <InboxOutlined
                              style={{ color: errorSizeImage ? COLORS.danger : COLORS.primary, fontSize: "2rem" }}
                              className='mx-3'
                            />
                            <Text
                              style={{ fontSize: "1rem", color: errorSizeImage ? COLORS.danger : COLORS.primary }}
                              className='my-font'
                            >
                              {intl.formatMessage({
                                id: logos?.length === 0 ? "addPhoto" : "change",
                              })}
                            </Text>
                          </div>
                        </Dragger>
                      </Spin>
                    </Col>
                    {/* Logo */}
                    <Col md={12} sm={24} xs={24}>
                      <Spin spinning={loading}>
                        <Title className='my-font' level={4}>
                          {intl.formatMessage({ id: "coverPhoto" })}
                        </Title>
                        <div style={{ position: "absolute", top: 15, display: "flex", justifyContent: "center", width: "100%" }}>
                          {errorSizeCover ? <Text className='my-font' style={{ color: COLORS.danger }}>
                            {intl.formatMessage({ id: "limit2Mb" })}
                          </Text> : null}
                        </div>
                        <Dragger
                          action={`${apiUrl}/restaurant-upload-logo/${restaurant?._id}?slug=${restaurant?.slug}&logo=cover`}
                          headers={{ authorization: "Bearer " + user.token }}
                          name='picture'
                          fileList={coverImages}
                          onRemove={(e) => { deleteImage(e, "cover"); updateShowCoverImageInMenu() }}
                          listType='picture'
                          onChange={(e) => handleChangeImages(e, "cover")}
                          onPreview={onPreview}
                          beforeUpload={beforeUpload}
                          multiple={false}
                          maxCount={0}
                          accept='image/jpg,image/jpeg,image/png,video/mp4'
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <InboxOutlined
                              style={{ color: errorSizeCover ? COLORS.danger : COLORS.primary, fontSize: "2rem" }}
                              className='mx-3'
                            />
                            <Text
                              style={{ fontSize: "1rem", color: errorSizeCover ? COLORS.danger : COLORS.primary }}
                              className='my-font'
                            >
                              {intl.formatMessage({
                                id: logos?.length === 0 ? "addPhoto" : "change",
                              })}
                            </Text>
                          </div>
                        </Dragger>
                      </Spin>
                    </Col>
                  </Row>
                </div>
                <Divider />

                <Row justify='start' className='mt-5' align='middle'>
                  <Form.Item>
                    <CustomButton
                      htmlType='submit'
                      text={intl.formatMessage({ id: "save" })}
                      className={`btnRegister btn-text border-8 px-5 `}
                      loading={loading}
                      disabled={loading}
                      type='primary'
                    />
                  </Form.Item>
                  <Form.Item>
                    <CustomButton
                      type='secondary'
                      text={intl.formatMessage({ id: "back" })}
                      className={` border-8 mx-5 px-5`}
                      loading={loading}
                      disabled={loading}
                    />
                  </Form.Item>
                </Row>
              </div>
            )}
          </Form>
        </Spin>
      </div>
    </div>
  );
};

export default Restaurant;
