import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Typography, Statistic } from "antd";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";
import { groupByMonth, month, monthsAR } from "../../../helpers/feedback";
import { useIntl } from "react-intl";
import COLORS from "../../../Style/colors";
import { BsHash } from "react-icons/bs";
import { IoStarHalfOutline } from "react-icons/io5";
import { FiSmile } from "react-icons/fi";
const { Text } = Typography;

const ChartData = ({ data, answers, allAnswers }) => {
  const { rtl } = useSelector((state) => state.settings);
  const intl = useIntl();

  const [allAnswersFormat, setAllAnswersFormat] = useState({});

  useEffect(() => {
    if (allAnswers?.length > 0) {
      let labels = month;
      if (rtl) {
        labels = monthsAR;
      }
      const count = groupByMonth(allAnswers);
      let allAnswersData = labels.map((label, index) => {
        let value = 0;
        if (count[index]) {
          value = count[index];
        }
        return value;
      });
      setAllAnswersFormat({
        labels,
        data: allAnswersData,
      });
    }
  }, [rtl, allAnswers]);
 

  const getResultAnswers = (array) => {
    const result = {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
    };
    array.forEach(({ answer }) => {
      result[answer] += 1;
    });
    let allLabels = ["✩", "✩✩", "✩✩✩", "✩✩✩✩", "✩✩✩✩✩"];
    if (array?.[0]?.answerType === "faces") {
      allLabels = [
        "\u{1F620}",
        "\u{1F61E}",
        "\u{1F610}",
        "\u{1F603}",
        "\u{1F60D}",
      ];
    }
    return {
      labels: allLabels,
      result: Object.values(result),
    };
  };

  return (
    <div style={{ width: "100%" }}>
      <Row justify='space-between' align='middle' type='flex'>
        <Col
          className='light-shadow px-2 py-2 border-8 mb-3'
          md={11}
          sm={24}
          xs={24}
        >
          <Statistic
            title={
              <Text className='my-font' type='secondary'>
                {intl.formatMessage({ id: "allAnswers" })}
              </Text>
            }
            value={allAnswers?.length}
            precision={0}
            prefix={<BsHash style={{ position: "relative", top: 3 }} />}
            suffix={
              <Text
                style={{ fontSize: 14 }}
                className='my-font'
                type='secondary'
              >
                {intl.formatMessage({ id: "survey" })}
              </Text>
            }
          />

          <Row type='flex' justify='center' align='middle'>
            <Chart
              type='line'
              data={{
                labels: allAnswersFormat?.labels,
                datasets: [
                  {
                    label: "",
                    data: allAnswersFormat?.data,
                    borderWidth: 2,
                    tension: 0.6,
                    borderColor: COLORS.lightBlue,
                    pointBorderWidth: 7,
                    pointStyle: "circle",
                    pointBorderColor: COLORS.primary,
                  },
                ],
              }}
              options={{
                plugins: {
                  subtitle: {
                    display: true,
                  },
                  legend: {
                    display: false,
                  },
                },
              }}
            />
            {/* </Col> */}
          </Row>
        </Col>
        {Object.keys(answers)?.length
          ? Object.keys(answers).map((key) => {
              const question = data.find((q) => q._id === key);
              let icon = (
                <IoStarHalfOutline style={{ position: "relative", top: 3 }} />
              );
              if (question?.answerType === "faces") {
                icon = <FiSmile style={{ position: "relative", top: 3 }} />;
              }
              const { labels, result } = getResultAnswers(answers[key]);
              return (
                <Col
                  key={key}
                  className='light-shadow px-2 py-2 border-8 mb-3'
                  md={11}
                  sm={24}
                  xs={24}
                >
                  <Statistic
                    title={
                      <Text className='my-font' type='secondary'>
                        {rtl ? question?.question : question?.question_en}
                      </Text>
                    }
                    value={answers[key]?.length}
                    precision={0}
                    prefix={icon}
                    suffix={
                      <Text
                        style={{ fontSize: 14 }}
                        className='my-font'
                        type='secondary'
                      >
                        {intl.formatMessage({ id: "survey" })}
                      </Text>
                    }
                  />

                  <Row type='flex' justify='center' align='middle'>
                    <Chart
                      type='bar'
                      data={{
                        labels: labels,
                        datasets: [
                          {
                            label: "",
                            data: result,
                            borderWidth: 0,
                            backgroundColor: COLORS.primary,
                            tension: 0.6,
                          },
                        ],
                      }}
                      options={{
                        plugins: {
                          subtitle: {
                            display: true,
                          },
                          legend: {
                            display: false,
                          },
                        },
                      }}
                    />
                  </Row>
                </Col>
              );
            })
          : null}
      </Row>
    </div>
  );
};

export default ChartData;
