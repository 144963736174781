import { createSlice, current } from "@reduxjs/toolkit";
import { arrAdd, arrRemoveByKeyValue, arrUpdateByKeyValue } from "../../../helpers/array";
import { IWAITER_APP_ORDERS_STATUS, ORDER_TYPES } from "../../../Types";

const initialState = {
  dineInOrders: [],
  pickupOrders: [],
  iwaiterAppOrders: [],
  deliveryOrders: [],
  customOrders : [],
  orderById:null
};

export const orderSlice = createSlice({
  name: "order",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setDineInOrders: (state, action) => {
      state.dineInOrders = action.payload;
    },
    setPickupOrders: (state, action) => {
      state.pickupOrders = action.payload;
    },
    setCustomOrders: (state , action) => {
      state.customOrders = action.payload
    },
    setIwaiterAppOrders: (state, action) => {
      state.iwaiterAppOrders = action.payload;
    },
    setDeliveryOrders: (state, action) => {
      state.deliveryOrders = action.payload;
    },
    addDineInOrder: (state, action) => {
      const newOrders = arrAdd({
        arr: current(state.dineInOrders),
        newElement: action.payload,
      });
      const sorted = newOrders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      state.dineInOrders = sorted;
    },
    addPickupOrder: (state, action) => {
      let newOrders = arrAdd({
        arr: current(state.pickupOrders),
        newElement: action.payload,
      });
      const sorted = newOrders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      state.pickupOrders = sorted;
    },
    addCustomOrder: (state, action) => {
      let newOrders = arrAdd({
        arr: current(state.customOrders),
        newElement: action.payload,
      });
      const sorted = newOrders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      state.customOrders = sorted;
    },
    addIwaiterAppOrder: (state, action) => {
      let newOrders = arrAdd({
        arr: current(state.iwaiterAppOrders),
        newElement: action.payload,
      });
      const sorted = newOrders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      state.iwaiterAppOrders = sorted;
    },
    addDeliveryOrder: (state, action) => {
      const newOrders = arrAdd({
        arr: current(state.deliveryOrders),
        newElement: action.payload,
      });
      const sorted = newOrders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      state.deliveryOrders = sorted;
    },
    updateOrders: (state, action) => {
      if (action.payload.type === ORDER_TYPES.DINE_IN) {
        const newOrders = arrUpdateByKeyValue({ arr: current(state.dineInOrders), key: "_id", value: action.payload.payload._id, newElement: action.payload.payload });
        state.dineInOrders = newOrders;
      } else if (action.payload.type === ORDER_TYPES.PICKUP) {
        const newOrders = arrUpdateByKeyValue({ arr: current(state.pickupOrders), key: "_id", value: action.payload.payload._id, newElement: action.payload.payload });
        state.pickupOrders = newOrders;
      } else if (action.payload.type === ORDER_TYPES.DELIVERY) {
        const newOrders = arrUpdateByKeyValue({ arr: current(state.deliveryOrders), key: "_id", value: action.payload.payload._id, newElement: action.payload.payload });
        state.deliveryOrders = newOrders;
      } else if (action.payload.type === ORDER_TYPES.IWAITER_APP) {
        const newOrders = arrUpdateByKeyValue({ arr: current(state.iwaiterAppOrders), key: "_id", value: action.payload.payload._id, newElement: action.payload.payload });
        state.iwaiterAppOrders = newOrders;
      }else if (action.payload.type === ORDER_TYPES.CUSTOM_ORDER){
        const newOrders = arrUpdateByKeyValue({ arr: current(state.customOrders), key: "_id", value: action.payload.payload._id, newElement: action.payload.payload });
        state.customOrders = newOrders;
      }
    },
    deleteOrder: (state, action) => {
      if (action.payload.type === ORDER_TYPES.DINE_IN) {
        const newOrders = arrRemoveByKeyValue({ arr: current(state.dineInOrders), key: "_id", value: action.payload.payload });
        state.dineInOrders = newOrders;
      } else if (action.payload.type === ORDER_TYPES.PICKUP) {
        const newOrders = arrRemoveByKeyValue({ arr: current(state.pickupOrders), key: "_id", value: action.payload.payload });
        state.pickupOrders = newOrders;
      } else if (action.payload.type === ORDER_TYPES.DELIVERY) {
        const newOrders = arrRemoveByKeyValue({ arr: current(state.deliveryOrders), key: "_id", value: action.payload.payload });
        state.deliveryOrders = newOrders;
      } else if (action.payload.type === ORDER_TYPES.IWAITER_APP) {
        const newOrders = arrRemoveByKeyValue({ arr: current(state.iwaiterAppOrders), key: "_id", value: action.payload.payload });
        state.iwaiterAppOrders = newOrders;
      }
      else if (action.payload.type === ORDER_TYPES.CUSTOM_ORDER) {
        const newOrders = arrRemoveByKeyValue({ arr: current(state.customOrders), key: "_id", value: action.payload.payload });
        state.customOrders = newOrders;
      }
    },
    setOrderById : (state , action) => {
      state.orderById = action.payload;
    }
  },
});

export const {
  setDineInOrders: setDineInOrdersAction,
  setPickupOrders: setPickupOrdersAction,
  setCustomOrders :setCustomOrdersAction ,
  setIwaiterAppOrders: setIwaiterAppOrdersAction,
  addIwaiterAppOrder: addIwaiterAppOrderAction,
  setDeliveryOrders: setDeliveryOrdersAction,
  addDineInOrder: addDineInOrderAction,
  addPickupOrder: addPickupOrderAction,
  addCustomOrder : addCustomOrderAction,
  addDeliveryOrder: addDeliveryOrderAction,
  updateOrders: updateOrdersAction,
  deleteOrder: deleteOrderAction,
  setOrderById :setOrderByIdAction

} = orderSlice.actions;

export default orderSlice.reducer;
