import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Tag, Table } from "antd";
import { useSelector } from "react-redux";
import COLORS from "../../Style/colors";
import CustomButton from "../../Components/CustomButton";
import { PlusOutlined, SettingOutlined } from "@ant-design/icons";
import EditWaitingAreaModal from "./Components/EditWaitingAreaModal";
import AddUserManual from "./Components/AddUserManual";

import WaitingUserActions from "./Components/WaitingUserActions";
import AddWaitingAreaModal from "./Components/AddWaitingAreaModal";

const ActiveUserTable = ({
  users,
  status,
  area,
  selectedArea,
  setActiveTab,
}) => {
  const intl = useIntl();

  const { role } = useSelector((state) => state.auth);
  const { rtl } = useSelector((state) => state.settings);
  const [formattedUsers, setFormattedUsers] = useState([]);
  const [showEditWaitingArea, setShowEditWaitingArea] = useState(false);
  const [showAddUserManual, setShowAddUserManual] = useState(false);
  const [showAddWaitingArea, setShowAddWaitingArea] = useState(false);
  useEffect(() => {
    if (users?.length) {
      const active = users.map((user, i) => {
        return {
          number: i + 1,
          name: user.name,
          phone: user?.phone,
          time: new Date(user?.createdAt).toLocaleTimeString(),
          personsCount: user?.personsCount,
          note: user?.note,
          key: user?._id,
          didSent: user?.didSent,
          userObject: user,
        };
      });
      setFormattedUsers(active);
    } else {
      setFormattedUsers([]);
    }
  }, [users]);

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
    },
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "name",
      key: "name",
    },
    {
      title: intl.formatMessage({ id: "phone" }),
      dataIndex: "phone",
      key: "phone",
      render: (phone) => {
        return <a href={`tel:00${phone}`}>{phone}</a>;
      },
    },
    {
      title: intl.formatMessage({ id: "time" }),
      dataIndex: "time",
      key: "time",
      sorter: (a, b) =>
        new Date(a.userObject?.createdAt) - new Date(b.userObject?.createdAt),
      showSorterTooltip: false,
    },
    {
      title: intl.formatMessage({ id: "persons" }),
      dataIndex: "personsCount",
      key: "personsCount",
      sorter: (a, b) => a.personsCount - b.personsCount,
      showSorterTooltip: false,
    },
    {
      title: intl.formatMessage({ id: "notes" }),
      dataIndex: "note",
      key: "note",
      render: (note) => {
        return <Tag>{note}</Tag>;
      },
    },
    {
      title: intl.formatMessage({ id: "choices" }),
      dataIndex: "action",
      key: "action",
      render: (__, record) => {
        return (
          <WaitingUserActions
            user={record.userObject}
            record={record}
            type='Active'
          />
        );
      },
    },
  ];

  return (
    <div
      style={{
        marginTop: "-17px",
        paddingTop: 15,
        paddingBottom: 30,
        backgroundColor: COLORS.white,
      }}
      className='light-shadow border-8'
    >
      {/* Add waiting area for super user only */}
      <AddWaitingAreaModal
        show={showAddWaitingArea}
        hide={() => setShowAddWaitingArea(false)}
        setActiveTab={setActiveTab}
      />
      {/* Edit waiting area modal */}
      <EditWaitingAreaModal
        show={showEditWaitingArea}
        hide={() => setShowEditWaitingArea(false)}
        area={area?._id === selectedArea ? area : null}
        canDelete={users?.length === 0}
        setActiveTab={setActiveTab}
      />
      {/* Edit waiting area modal */}
      <AddUserManual
        show={showAddUserManual}
        hide={() => setShowAddUserManual(false)}
        area={area?._id === selectedArea ? area : null}
      />
      <div
        style={{
          paddingRight: 30,
          paddingLeft: 30,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {role === "super" ? (
          <CustomButton
            text={intl.formatMessage({ id: "addWaitingArea" })}
            icon={<PlusOutlined />}
            type='primary'
            className='px-3 border-5 mb-3'
            disabled={status === "disabled"}
            onClick={() => setShowAddWaitingArea(true)}
          />
        ) : (
          <CustomButton
            text={intl.formatMessage({ id: "addUser" })}
            type='primary'
            className='px-3 border-5 mb-3'
            disabled={status === "disabled"}
            onClick={() => setShowAddUserManual(true)}
          />
        )}
        <CustomButton
          text={intl.formatMessage({
            id: "editWithArea",
          })}
          type={"light"}
          icon={<SettingOutlined />}
          className='px-3 border-5 mb-3'
          onClick={() => setShowEditWaitingArea(true)}
        />
      </div>
      <Table
        columns={columns}
        pagination={{ hideOnSinglePage: true }}
        dataSource={formattedUsers}
        scroll={{ x: 500 }}
      />
    </div>
  );
};

export default ActiveUserTable;
