import { Modal, Space, Typography } from "antd";
import React from "react";
import { IoLogoYoutube } from "react-icons/io5";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

const { Title, Link } = Typography;
const ShowHelpModal = ({ showHelp, setShowHelp }) => {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  return (
    <Modal
      title={
        <Title level={4} className='my-font'>
          {intl.formatMessage({ id: "help" })}
        </Title>
      }
      centered
      visible={showHelp}
      destroyOnClose={true}
      mask={true}
      maskClosable={true}
      onCancel={() => setShowHelp(false)}
      footer={null}
    >
      <Space direction='vertical'>
        <Link
          href={
            rtl
              ? "https://iwaiter.net/user-guid/ar.html"
              : "https://iwaiter.net/user-guid/en.html"
          }
          target='_blank'
          className='my-font'
        >
          {intl.formatMessage({ id: "userGuid" })}
        </Link>
        <Link
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
          href={"https://www.youtube.com/channel/UCEGvNkUkdIuDXFBW_NXOG2g"}
          target='_blank'
          className='my-font'
        >
          {intl.formatMessage({ id: "userGuidVideos" })}
          <IoLogoYoutube className='mx-3' size={22} />
        </Link>
      </Space>
    </Modal>
  );
};

export default ShowHelpModal;
