import React, { useState } from "react";
import {
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Divider,
  Space,
} from "antd";
import { useSelector,  } from "react-redux";
import { useIntl } from "react-intl";
import CustomButton from "../../../Components/CustomButton";
import { postAddManualUser } from "../../../API/fetch";
import openNotification from "../../../Components/Notifications";

import phoneCodes from "../../Auth/PhoneCods.json";
import { checkIfValidPhone } from "../../../helpers";

const { Option } = Select;

const AddUserManual = ({ show, hide, area }) => {
  const intl = useIntl();
  const { branch } = useSelector((state) => state.branch);
  const { rtl } = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const addUser = async (values) => {
    try {
      setLoading(true);
      const phone = `${values.prefix.substring(1)}${values.phone}`;
      const data = {
        waitingUser: { ...values, phone },
      };
       await postAddManualUser(data, branch._id);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setLoading(false);
      hide();
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const prefixSelector = (
    <Form.Item name='prefix' noStyle>
      <Select
        style={{
          width: 150,
        }}
      >
        {phoneCodes.map((info) => {
          return (
            <Option
              key={`${info.dial_code}-${info.name}`}
              value={info.dial_code}
            >
              {info.dial_code} {info.name}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );

  return (
    <Modal
      title={
        <p className='my-font'>{intl.formatMessage({ id: "waitingArea" })}</p>
      }
      centered
      visible={show}
      destroyOnClose={true}
      mask={true}
      maskClosable={true}
      onCancel={() => {
        hide();
      }}
      footer={null}
    >
      <Form
        name='editArea'
        form={form}
        preserve={false}
        className='form-container'
        layout='vertical'
        onFinish={addUser}
        initialValues={{
          name: "",
          phone: "",
          prefix: phoneCodes[0].dial_code,
          personsCount: 2,
          note: "",
          zone: area?.name_en,
        }}
      >
        <Form.Item
          label={intl.formatMessage({ id: "justName" })}
          className='mb-1'
          name='name'
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: "errorname" }),
            },
          ]}
        >
          <Input className={"input"} />
        </Form.Item>
        <Form.Item
          name='phone'
          label={intl.formatMessage({ id: "phone" })}
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: "errorphone" }),
            },
            {
              validator: (_, value) => {
                const prefix = form.getFieldValue("prefix");
                if (!prefix) {
                  return Promise.reject(
                    intl.formatMessage({ id: "errorphone" })
                  );
                }
                let valid = checkIfValidPhone(`${prefix}${value}`);
                if (valid) {
                  return Promise.resolve();
                } else {
                  return Promise.reject(
                    intl.formatMessage({ id: "errorphone" })
                  );
                }
              },
            },
          ]}
        >
          <Input
            addonBefore={rtl ? null : prefixSelector}
            addonAfter={rtl ? prefixSelector : null}
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: "persons" })}
          className='mb-1'
          name='personsCount'
        >
          <InputNumber
            style={{
              width: "100%",
            }}
            className={"input"}
            min={0}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: "note" })}
          className='mb-1'
          name='note'
        >
          <Input className={"input"} />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: "zone" })}
          className='mb-1'
          name='zone'
        >
          <Select>
            {branch?.waitingArea?.map((one) => {
              return (
                <Option value={one.name_en} key={one._id}>
                  {one?.name_en ? (rtl ? one?.name : one?.name_en) : one?.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item></Form.Item>
        <Divider />
        <Space direction='horizontal'>
          <Form.Item>
            <CustomButton
              htmlType='submit'
              text={intl.formatMessage({ id: "save" })}
              className={`btnRegister btn-text border-8`}
              loading={loading}
              disabled={loading}
              type='primary'
            />
          </Form.Item>

          <Form.Item>
            <CustomButton
              type='text'
              text={intl.formatMessage({ id: "cancel" })}
              disabled={loading}
              onClick={() => {
                hide();
              }}
            />
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  );
};

export default AddUserManual;
