import React, { useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Switch,
  Divider,
  Typography,
  Input,
  InputNumber,
  Checkbox,
  Space,
  Button,
  Spin,
  Popconfirm,
  TreeSelect,
} from "antd";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../../Components/CustomButton";
import COLORS from "../../../../Style/colors";
import {
  postAddModifier,
  postDeleteModifier,
  postEditModifier,
  postUpdateFood,
  postUpdateMultiFoods,
} from "../../../../API/fetch";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import openNotification from "../../../../Components/Notifications";
import {
  addModifierAction,
  deleteModifierAction,
  editCategoryAction,
  editFoodAction,
  editModifierAction,
  setCategoriesAndFoodsAction,
} from "../../../../redux/reducers/menuReducer";
import { arrUpdateByKeyValue } from "../../../../helpers/array";
import { sortByOrder } from "../../../../helpers";

const { Title, Text } = Typography;
const { SHOW_ALL } = TreeSelect;

const ModifierCard = ({ modifier, order, isDisabled = false }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const { rtl } = useSelector((state) => state.settings);
  const { restaurant } = useSelector((state) => state.restaurant);
  const { foods, categories } = useSelector((state) => state.menu);
  const [foodsForModifier, setFoodsForModifier] = useState([]);
  const [loading, setLoading] = useState(false);
  const [optionsLength, setOptionsLength] = useState(modifier?.options?.length);
  const [isNew, setIsNew] = useState(true);
  const [seletedFoodIds, setSelectedFoodIds] = useState([]);
  //order option
  const [orderOption, setOrderOption] = useState([]);

  useEffect(() => {
    if (modifier && modifier?._id) {
      form.resetFields();
      setIsNew(false);
      const foodsForModifier = foods.filter((fo) => {
        return (
          fo?.modifiers?.findIndex((mod) => mod?._id === modifier?._id) !== -1
        );
      });
      setFoodsForModifier(foodsForModifier);
    }
  }, [modifier, foods]);

  useEffect(() => {
    if (modifier?.options) {
      const tab = [];
      modifier?.options?.forEach((el, i) => tab.push(el?.order));
      setOrderOption(tab);
    }
  }, [modifier?.options]);

  // edit modifiers;
  const editModifier = async (values) => {
    try {
      setLoading(true);
      // get new options that does not have _id;
      const newOptions = values.options
        .filter((option) => {
          return !option._id;
        })
        ?.map((option) => {
          let price = 0;
          let calories = 0;
          if (option.price) {
            price = option.price;
          }
          if (option.calories) {
            calories = option.calories;
          }
          return {
            ...option,
            price,
            calories,
          };
        });
      // get all options and edit the status;
      const modifiedOptions = modifier?.options?.map((oldOption) => {
        let status = "Active";
        let currentOption = { ...oldOption };
        const newOption = values.options.find(
          (option) => option?._id === oldOption?._id
        );
        if (newOption?._id) {
          currentOption = { ...newOption };
        }
        // if we don't find the option that means user deleted it;
        if (!newOption) {
          status = "deleted";
        } else if (currentOption?.isActive === false) {
          // if the value.isActive === false then we set the status to notActive;
          status = "notActive";
        }
        return {
          ...currentOption,
          status,
        };
      });

      // check multiSelection;
      let multiSelection = false;
      if (values.maxSelection !== 1 || values?.minSelection !== 1) {
        multiSelection = true;
      }

      const data = {
        modifier: {
          ...values,
          multiSelection,
          options: sortByOrder([...modifiedOptions, ...newOptions]),
          order: order,
        },
      };

      const res = await postEditModifier(modifier._id, data);
      // update the modifier array;
      dispatch(editModifierAction(res.data.modifier));

      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      form.resetFields();
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  // add new modifiers;
  const addNewModifier = async (values) => {
    try {
      setLoading(true);
      let multiSelection = false;
      if (values.maxSelection !== 1 || values?.minSelection !== 1) {
        multiSelection = true;
      }
      const options = values.options.map((option, index) => {
        let price = 0;
        let calories = 0;
        let status = "Active";
        if (option.price) {
          price = option.price;
        }
        if (option.calories) {
          calories = option.calories;
        }

        if (option.isActive === false) {
          status = "notActive";
        }

        return {
          ...option,
          price,
          calories,
          status,
        };
      });

      const data = {
        modifier: {
          ...values,
          multiSelection,
          options: sortByOrder(options),
          restaurantId: restaurant?._id,
          order: order,
        },
      };

      const res = await postAddModifier(data);
      // update the modifier array;
      dispatch(addModifierAction(res.data.modifier));

      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      form.resetFields();
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const deleteModifier = async () => {
    if (!modifier?._id) {
      return;
    }
    const foodIds = foods
      .filter((food) => {
        const modiIds = food.modifiers?.map((m) => m._id);
        return modiIds?.includes(modifier?._id);
      })
      ?.map((f) => f.foodId);
    const data = {
      foodIds: foodIds,
    };
    setLoading(true);
    try {
      const res = await postDeleteModifier(modifier?._id, data);
      if (res.data?.status === "success") {
        dispatch(deleteModifierAction(modifier?._id));
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const removeModifierFromFood = async (food) => {
    try {
      setLoading(true);
      // get new options that does not have _id;
      const data = {
        food: {},
      };
      data.food.modifierIds = food.modifiers
        .filter((mod) => mod._id !== modifier?._id)
        .map((mod) => mod._id);

      const res = await postUpdateFood(food.foodId, data);
      if (res.data?.status === "success") {
        const newModifiers = food.modifiers.filter((mod) =>
          data.food.modifierIds.includes(mod._id)
        );
        const newFood = {
          ...food,
          modifiers: newModifiers,
        };
        const category = categories.find(
          (c) => c.categoryId === newFood.categoryId
        );

        dispatch(editFoodAction(newFood));
        // edit the food  item inside the foods array inside the category;
        const newFoods = arrUpdateByKeyValue({
          arr: category?.foods,
          key: "foodId",
          value: food.foodId,
          newElement: newFood,
        });
        const newCategory = {
          ...category,
          foods: newFoods,
        };
        dispatch(editCategoryAction(newCategory));
      }

      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      form.resetFields();
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  //update multifood to apply modifier Test

  const FoodsTree = categories.map((cat) => {
    return {
      title: rtl ? cat?.name : cat?.name_en,
      value: cat?.categoryId,
      key: cat?.categoryId,
      children: foods
        ?.filter(
          (food) =>
            food?.categoryId === cat?.categoryId &&
            !foodsForModifier.map((el) => el?.foodId).includes(food?.foodId)
        )
        .map((food) => {
          return {
            title: rtl ? food?.name : food?.name_en,
            value: food?.foodId,
            key: food?.foodId,
          };
        }),
    };
  });

  const handleChangeFoodTrees = (newValue) => {
    setSelectedFoodIds(newValue);
  };
  const tProps = {
    treeData: FoodsTree,
    value: seletedFoodIds,
    onChange: handleChangeFoodTrees,
    treeCheckable: true,
    showCheckedStrategy: SHOW_ALL,
    placeholder: intl.formatMessage({ id: "ApplyModifierToFoodMessage" }),
    style: {
      width: "100%",
    },
  };

  const applyModifiertoMultiFood = async (foodIds) => {
    try {
      setLoading(true);
      const newFoodIds = foodIds.filter(
        (val) => !categories?.map((el) => el?.categoryId)?.includes(val)
      );
      const editFoods = newFoodIds?.map((id) => {
        const selectedFood = foods?.find((f) => f?.foodId === id);
        const modifierIds = [];
        selectedFood.modifiers.map((el) => modifierIds?.push(el?._id));
        modifierIds.push(modifier?._id);
        return {
          _id: id,
          modifierIds: modifierIds,
        };
      });
      const data = {
        foods: editFoods,
      };
      const res = await postUpdateMultiFoods(data);
      if (res?.data?.categories) {
        dispatch(setCategoriesAndFoodsAction(res.data.categories));
      }
      setSelectedFoodIds([]);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
    } catch (error) {
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  return (
    <Form
      name='modifiers-form'
      form={form}
      className='form-container'
      layout='vertical'
      onFinish={isNew ? addNewModifier : editModifier}
      initialValues={{
        name: modifier?.name || "",
        privateName: modifier?.privateName || "",
        name_en: modifier?.name_en || "",
        numberOfFreeChoices: modifier?.numberOfFreeChoices || "",
        multiSelection: modifier?.multiSelection || false,
        mustChooseOne:
          modifier?.minSelection === 1 && modifier?.maxSelection === 1,
        minSelection: modifier?.minSelection || "",
        maxSelection: modifier?.maxSelection || "",
        options:
          modifier?.options?.map((option) => {
            return {
              ...option,
              isActive: option.status === "Active",
            };
          }) || [],
      }}
    >
      <Spin spinning={loading}>
        <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
          {/* Title */}
          <Col md={12} sm={24} xs={24}>
            <Form.Item
              label={intl.formatMessage({ id: "privateName" })}
              className='mb-1'
              name='privateName'
              style={{ borderRadius: 20 }}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: "errorname" }),
                },
              ]}
            >
              <Input
                className={"input"}
                style={{ borderRadius: 5, width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
          {/* Names */}
          <Col md={12} sm={24} xs={24}>
            <Form.Item
              label={intl.formatMessage({ id: "nameAr" })}
              className='mb-1'
              name='name'
              style={{ borderRadius: 20 }}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: "errorname" }),
                },
              ]}
            >
              <Input
                className={"input"}
                style={{ borderRadius: 5, width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col md={12} sm={24} xs={24}>
            <Form.Item
              label={intl.formatMessage({ id: "nameEn" })}
              className='mb-1'
              name='name_en'
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: "errorname" }),
                },
              ]}
            >
              <Input className={"input"} style={{ borderRadius: 5 }} />
            </Form.Item>
          </Col>
        </Row>
        <Row className='mt-4' gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
          {/* Can choose multiple */}
          <Col md={12} sm={24} xs={24}>
            <Form.Item
              className='mb-1'
              name='multiSelection'
              valuePropName='checked'
              style={{ borderRadius: 20 }}
              onChange={(e) => {
                if (e.target.checked) {
                  form.setFieldsValue({
                    mustChooseOne: false,
                  });
                }
              }}
            >
              <Checkbox>
                {intl.formatMessage({ id: "canChooseMulti" })}{" "}
              </Checkbox>
            </Form.Item>
          </Col>
          <Col md={12} sm={24} xs={24}>
            <Form.Item
              className='mb-1'
              name='mustChooseOne'
              valuePropName='checked'
              style={{ borderRadius: 20 }}
              onChange={(e) => {
                if (e.target.checked) {
                  form.setFieldsValue({
                    minSelection: 1,
                    maxSelection: 1,
                    multiSelection: false,
                  });
                }
              }}
            >
              <Checkbox>
                {intl.formatMessage({ id: "mustChooseOne" })}{" "}
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Row className='mt-4' gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          {/* Prices */}
          <Col md={6} sm={24} xs={24}>
            <Form.Item
              label={intl.formatMessage({ id: "maxSelection" })}
              className='mb-1'
              name='maxSelection'
              style={{ borderRadius: 20 }}
              dependencies={["mustChooseOne"]}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: "maxError" }),
                },
              ]}
            >
              <InputNumber
                onChange={(e) => {
                  if (e !== 1 && form.getFieldValue("mustChooseOne") === true) {
                    form.setFieldsValue({
                      mustChooseOne: false,
                    });
                  }
                }}
                className={"input"}
                placeholder={"1"}
                min={1}
                max={optionsLength}
                style={{ borderRadius: 5, width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col md={6} sm={24} xs={24}>
            <Form.Item
              label={intl.formatMessage({ id: "minSelection" })}
              className='mb-1'
              name='minSelection'
            >
              <InputNumber
                className={"input"}
                onChange={(e) => {
                  if (e !== 1 && form.getFieldValue("mustChooseOne") === true) {
                    form.setFieldsValue({
                      mustChooseOne: false,
                    });
                  }
                }}
                placeholder='0'
                min={0}
                max={optionsLength}
                style={{ borderRadius: 5, width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col md={6} sm={24} xs={24}>
            <Form.Item
              label={intl.formatMessage({ id: "freeSelection" })}
              className='mb-1'
              name='numberOfFreeChoices'
            >
              <InputNumber
                className={"input"}
                onChange={(e) => {
                  if (e !== 1 && form.getFieldValue("mustChooseOne") === true) {
                    form.setFieldsValue({
                      mustChooseOne: false,
                    });
                  }
                }}
                placeholder='0'
                min={0}
                max={optionsLength}
                style={{ borderRadius: 5, width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
          {/* Title */}
          <Col md={12} sm={24} xs={24}>
            <Title className='my-font' level={4}>
              {intl.formatMessage({ id: "options" })}
            </Title>
            {form.getFieldError("options")?.length ? (
              <Text>{form.getFieldError("options")[0]}</Text>
            ) : null}
          </Col>
        </Row>
        <Form.List hasFeedback name='options' rules={[{ required: true }]}>
          {(fields, { add, remove }, { errors }) => {
            let errorNoOptions = errors?.length > 0;
            return (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => {
                  return (
                    <Space
                      key={key}
                      style={{ display: "flex", marginBottom: 8 }}
                      align='center'
                    >
                      <Form.Item
                        {...restField}
                        label={intl.formatMessage({ id: "sort" })}
                        name={[name, "order"]}
                        fieldKey={[fieldKey, "order"]}
                      >
                        <Input
                          placeholder={intl.formatMessage({ id: "sort" })}
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        label={intl.formatMessage({ id: "nameAr" })}
                        name={[name, "name"]}
                        fieldKey={[fieldKey, "name"]}
                        rules={[
                          {
                            required: true,
                            message: intl.formatMessage({ id: "errorname" }),
                          },
                        ]}
                      >
                        <Input
                          placeholder={intl.formatMessage({ id: "nameAr" })}
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        label={intl.formatMessage({ id: "nameEn" })}
                        name={[name, "name_en"]}
                        fieldKey={[fieldKey, "name_en"]}
                        rules={[
                          {
                            required: true,
                            message: intl.formatMessage({ id: "errorname" }),
                          },
                        ]}
                      >
                        <Input
                          placeholder={intl.formatMessage({ id: "nameEn" })}
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        label={intl.formatMessage({ id: "price" })}
                        name={[name, "price"]}
                        fieldKey={[fieldKey, "price"]}
                      >
                        <InputNumber placeholder='0' min={0} />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        label={intl.formatMessage({ id: "calories" })}
                        name={[name, "calories"]}
                        fieldKey={[fieldKey, "calories"]}
                      >
                        <InputNumber placeholder='0' min={0} />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        label={intl.formatMessage({ id: "status" })}
                        name={[name, "isActive"]}
                        fieldKey={[fieldKey, "isActive"]}
                        valuePropName='checked'
                      >
                        <Switch
                          style={{ marginBottom: 0 }}
                          checkedChildren={
                            <p>
                              {intl.formatMessage({
                                id: "active",
                              })}
                            </p>
                          }
                          unCheckedChildren={
                            <p>
                              {intl.formatMessage({
                                id: "disabled",
                              })}
                            </p>
                          }
                        />
                      </Form.Item>
                      <Popconfirm
                        title={
                          <Text className='my-font'>
                            {intl.formatMessage({
                              id: "confirmDeleteChoiceTitle",
                            })}
                          </Text>
                        }
                        okText={intl.formatMessage({ id: "delete" })}
                        okButtonProps={{
                          style: { color: COLORS.danger, fontFamily: "Cairo" },
                        }}
                        okType='text'
                        cancelText={intl.formatMessage({ id: "cancel" })}
                        cancelButtonProps={{
                          style: { border: "none", fontFamily: "Cairo" },
                        }}
                        onConfirm={() => {
                          setOptionsLength(optionsLength - 1);
                          const order = form.getFieldValue([
                            "options",
                            name,
                            "order",
                          ]);
                          setOrderOption(
                            orderOption.filter((el) => el !== order)
                          );
                          remove(name);
                        }}
                      >
                        <DeleteOutlined
                          style={{ color: COLORS.danger, fontSize: 15 }}
                          className='mx-3'
                        />
                      </Popconfirm>
                    </Space>
                  );
                })}

                <Form.Item>
                  <Button
                    className='my-font'
                    type='dashed'
                    onClick={() => {
                      add({
                        order:
                          orderOption?.length > 0
                            ? orderOption[orderOption?.length - 1] + 1
                            : 1,
                      });
                      setOptionsLength(optionsLength + 1);
                      setOrderOption([
                        ...orderOption,
                        orderOption?.length > 0
                          ? orderOption[orderOption?.length - 1] + 1
                          : 1,
                      ]);
                    }}
                    block
                    style={{
                      color: errorNoOptions ? COLORS?.danger : COLORS?.primary,
                      borderColor: errorNoOptions ? COLORS?.danger : "#d9d9d9",
                    }}
                    icon={<PlusOutlined />}
                  >
                    {intl.formatMessage({ id: "addOption" })}
                  </Button>
                </Form.Item>
              </>
            );
          }}
        </Form.List>
        <Divider />
        <Title level={5}>
          {foodsForModifier.length > 0
            ? intl.formatMessage({ id: "modifierInFood" })
            : intl.formatMessage({
                id: "noFoodWithThisModifier",
              })}
        </Title>
        {isNew ? null : (
          <Row style={{ marginBottom: "1rem" }}>
            <Col xs={18}>
              <TreeSelect {...tProps} />
            </Col>
            <Col xs={5} style={{ display: "flex", justifyContent: "center" }}>
              <CustomButton
                text={intl.formatMessage({ id: "addAction" })}
                className={`btnRegister btn-text border-8 px-5 `}
                loading={loading}
                disabled={loading || isDisabled}
                onClick={() => applyModifiertoMultiFood(seletedFoodIds)}
                type='primary'
              />
            </Col>
          </Row>
        )}

        {foodsForModifier.length > 0 ? (
          <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
            {foodsForModifier.map((food) => {
              return (
                <Col md={6} sm={12} xs={24} key={food?.foodId} className='mt-2'>
                  <Text className='my-font mx-2'>
                    {rtl ? food?.name : food?.name_en}
                  </Text>
                  <Popconfirm
                    title={
                      <Text className='my-font'>
                        {intl.formatMessage({ id: "confirmDeleteModifier" })}
                      </Text>
                    }
                    okText={intl.formatMessage({ id: "delete" })}
                    okButtonProps={{
                      style: { color: COLORS.danger, fontFamily: "Cairo" },
                    }}
                    okType='text'
                    cancelText={intl.formatMessage({ id: "cancel" })}
                    cancelButtonProps={{
                      style: { border: "none", fontFamily: "Cairo" },
                    }}
                    onConfirm={() => {
                      removeModifierFromFood(food);
                    }}
                    disabled={isDisabled}
                  >
                    <Text
                      disabled={isDisabled}
                      type='danger'
                      className='pointer my-font'
                    >
                      {intl.formatMessage({ id: "delete" })}
                    </Text>
                  </Popconfirm>
                  {/* <Text type='danger' className='my-font mx-2'>
                    {intl.formatMessage({ id: "delete" })}
                  </Text> */}
                </Col>
              );
            })}
          </Row>
        ) : null}
        <Divider />
        <Row justify='start' className='mt-5' align='middle'>
          <Form.Item>
            <CustomButton
              htmlType='submit'
              text={intl.formatMessage({ id: "save" })}
              className={`btnRegister btn-text border-8 px-5 `}
              loading={loading}
              disabled={loading}
              type='primary'
            />
          </Form.Item>
          <Form.Item className='mx-3'>
            <Popconfirm
              title={
                <Text className='my-font'>
                  {intl.formatMessage({ id: "confirmDeleteModifierTitle" })}
                </Text>
              }
              okText={intl.formatMessage({ id: "delete" })}
              okButtonProps={{
                style: { color: COLORS.danger, fontFamily: "Cairo" },
              }}
              okType='text'
              cancelText={intl.formatMessage({ id: "cancel" })}
              cancelButtonProps={{
                style: { border: "none", fontFamily: "Cairo" },
              }}
              onConfirm={deleteModifier}
              disabled={!modifier?._id || isDisabled}
            >
              <Text
                disabled={!modifier?._id || isDisabled}
                type='danger'
                className='pointer my-font'
              >
                {intl.formatMessage({ id: "delete" })}
              </Text>
            </Popconfirm>
          </Form.Item>
        </Row>
      </Spin>
    </Form>
  );
};

export default ModifierCard;
