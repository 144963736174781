export const carColors = [
  {
    name_en: "Red",
    name_ar: "أحمر",
    key: "#FF0000",
  },
  {
    name_en: "Blue",
    name_ar: "أزرق",
    key: "#0000FF",
  },
  {
    name_en: "Green",
    name_ar: "أخضر",
    key: "#008000",
  },
  {
    name_en: "Black",
    name_ar: "أسود",
    key: "#000000",
  },
  {
    name_en: "White",
    name_ar: "أبيض",
    key: "#FFFFFF",
  },
  {
    name_en: "Yellow",
    name_ar: "أصفر",
    key: "#FFFF00",
  },
  {
    name_en: "Orange",
    name_ar: "برتقالي",
    key: "#FFA500",
  },
  {
    name_en: "Purple",
    name_ar: "أرجواني",
    key: "#800080",
  },
  {
    name_en: "Pink",
    name_ar: "وردي",
    key: "#FFC0CB",
  },
  {
    name_en: "Brown",
    name_ar: "بني",
    key: "#A52A2A",
  },
  {
    name_en: "Gray",
    name_ar: "رمادي",
    key: "#808080",
  },
  {
    name_en: "Silver",
    name_ar: "فضي",
    key: "#C0C0C0",
  },
  {
    name_en: "Gold",
    name_ar: "ذهبي",
    key: "#FFD700",
  },
  {
    name_en: "Turquoise",
    name_ar: "تركواز",
    key: "#40E0D0",
  },
  {
    name_en: "Lime",
    name_ar: "ليموني",
    key: "#00FF00",
  },
  {
    name_en: "Teal",
    name_ar: "أزرق ملكي",
    key: "#008080",
  },
  {
    name_en: "Magenta",
    name_ar: "أحمر قرمزي",
    key: "#FF00FF",
  },
  {
    name_en: "Cyan",
    name_ar: "سماوي",
    key: "#00FFFF",
  },
  {
    name_en: "Maroon",
    name_ar: "خمري",
    key: "#800000",
  },
  {
    name_en: "Olive",
    name_ar: "زيتوني",
    key: "#808000",
  },
  {
    name_en: "Indigo",
    name_ar: "نيلي",
    key: "#4B0082",
  },
  {
    name_en: "Beige",
    name_ar: "بيج",
    key: "#F5F5DC",
  },
  {
    name_en: "Crimson",
    name_ar: "قرمزي",
    key: "#DC143C",
  },
  {
    name_en: "Navy",
    name_ar: "بحري",
    key: "#000080",
  },
  {
    name_en: "Salmon",
    name_ar: "سالموني",
    key: "#FA8072",
  },
  {
    name_en: "Violet",
    name_ar: "بنفسجي",
    key: "#8A2BE2",
  },
  {
    name_en: "Khaki",
    name_ar: "كاكي",
    key: "#F0E68C",
  },
  {
    name_en: "Mauve",
    name_ar: "بنفسجي فاتح",
    key: "#E0B0FF",
  },
  {
    name_en: "Lavender",
    name_ar: "لافندر",
    key: "#E6E6FA",
  },
  {
    name_en: "Apricot",
    name_ar: "مشمشي",
    key: "#FFDAB9",
  },
  {
    name_en: "Pearl",
    name_ar: "لؤلؤي",
    key: "#EAE0C8",
  },
  {
    name_en: "Ruby",
    name_ar: "عقيقي",
    key: "#E0115F",
  },
  {
    name_en: "Emerald",
    name_ar: "زمردي",
    key: "#50C878",
  },
  {
    name_en: "Sapphire",
    name_ar: "ياقوتي",
    key: "#0F52BA",
  },
  {
    name_en: "Amethyst",
    name_ar: "بنفسجي قاتم",
    key: "#9966CC",
  },
  {
    name_en: "Topaz",
    name_ar: "توباز",
    key: "#FFC87D",
  },
  {
    name_en: "Peridot",
    name_ar: "زبرجد",
    key: "#E6E200",
  },
  {
    name_en: "Aquamarine",
    name_ar: "زبرجد مائي",
    key: "#7FFFD4",
  },
];

