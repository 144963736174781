import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  TimePicker,
  Popconfirm,
  Typography,
  Divider,
  Space,
  Spin,
  Upload,
  message,
  Select,
  Row,
  Col,
  Switch,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import COLORS from "../../Style/colors";
import CustomButton from "../../Components/CustomButton";
import { InboxOutlined } from "@ant-design/icons";
import moment from "moment";
import openNotification from "../../Components/Notifications";
import { IoCloseCircleOutline } from "react-icons/io5";
import {
  postDeleteCustomCategory,
  postNewCustomCategory,
  postUpdateCustomCategory,
} from "../../API/fetch";
import {
  addCustomCategoryAction,
  deleteCustomCategoryAction,
  editCustomCategoryAction,
} from "../../redux/reducers/menuReducer";
import apiUrl from "../../API/config";
// import { categoriesTypesTag } from "../../Types";

const { Text, Title } = Typography;
const { Dragger } = Upload;
const acceptedTypes = ["image/png", "image/jpg", "image/jpeg"];

const EditCustomCategoryModal = ({ show, hide, category, index }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const { categories } = useSelector((state) => state.menu);
  const { user, role } = useSelector((state) => state.auth);
  const { restaurant } = useSelector((state) => state.restaurant);
  const { allBranches, branch } = useSelector((state) => state.branch);
  const [isNew, setIsNew] = useState(true);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [logos, setLogos] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [errorSize, setErrorSize] = useState(false);

  useEffect(() => {
    if (category?._id) {
      setIsNew(false);
      let cover = category?.cover?.url;
      if (cover) {
        setLogos([
          {
            uid: category._id,
            status: "done",
            url: category?.cover?.url,
            name: `${rtl ? category.name : category?.name_en}`,
          },
        ]);
      } else {
        setLogos([]);
      }
      // set form values;
      form.setFieldsValue({
        name: category?.name,
        name_en: category?.name_en,
        types: category?.types ? category?.types : ["ALL"],
        showCustomTime: category?.showCustomTime ? true : false,
        categoriesIds: category?.categoriesIds || [],
      });
      if (category?.startTime > 0 || category?.endTime > 0) {
        form.setFieldsValue({
          time: [
            {
              start: moment().startOf("day").add(category.startTime, "seconds"),
              end: moment().startOf("day").add(category.endTime, "seconds"),
            },
          ],
        });
      }
      let branchIds = [];
      if (role === "super") {
        branchIds = allBranches?.map((b) => b?.branch?._id);
      } else {
        branchIds = allBranches?.map((b) => b?._id);
      }
      let isHide = branchIds.every((id) =>
        category?.notAvailableIn?.includes(id)
      );
      form.setFieldsValue({
        isActive: !isHide,
      });
    } else {
      // reset form;
      form.resetFields();
    }
  }, [show, hide]);
  const editAddCategory = async (values) => {
    try {
      let startTime = 0;
      let endTime = 0;
      let hasCustomTime = false;
      // convert time to seconds;
      // if (values?.time?.[0]?.start > 0 || values?.time?.[0]?.end > 0) {
      //   // start time;
      //   const secondsStart = values.time[0].start;
      //   const midNightStart = secondsStart.clone().startOf("day");
      //   startTime = secondsStart.diff(midNightStart, "seconds");
      //   // end time;
      //   const secondsEnd = values.time[0].end;
      //   const midNightEnd = secondsEnd.clone().startOf("day");
      //   endTime = secondsEnd.diff(midNightEnd, "seconds");

      //   hasCustomTime = true;
      // }
      // check is hidden;
      let branchIds = [];
      if (role === "super") {
        if (values.isActive === false) {
          branchIds = allBranches.map((b) => b?.branch?._id);
        } else {
          branchIds = [];
        }
      } else {
        if (category?.notAvailableIn?.length > 0) {
          branchIds = [...category.notAvailableIn];
        }
        if (values.isActive === false) {
          //   // Add to array
          branchIds.push(branch._id);
        } else {
          // remove form array
          const i = branchIds.indexOf(branch._id);
          if (i !== -1) {
            branchIds.splice(i, 1);
          }
        }
      }
      let showCustomTime = false;
      // if (values?.showCustomTime === true) {
      //   showCustomTime = true;
      // }
      const data = {
        customCategory: {
          name: values.name,
          name_en: values.name_en,
          types: values.types,
          order: index || 0,
          hasCustomTime,
          startTime,
          endTime,
          notAvailableIn: branchIds,
          showCustomTime: showCustomTime,
          types: ["ALL"],
          categoriesIds: values.categoriesIds,
        },
      };

      // if (!data.category.types) {
      //   data.category.types = ["ALL"];
      // }
      setLoading(true);

      // check if edit category or add category;
      if (isNew) {
        const res = await postNewCustomCategory(data);
        dispatch(addCustomCategoryAction(res.data.customCategory));
      } else {
        const res = await postUpdateCustomCategory(category._id, data);
        if (res.data?.status === "success") {
          const newCategory = {
            ...category,
            ...data.customCategory,
          };
          dispatch(editCustomCategoryAction(newCategory));
        }
      }

      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      hide();
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const handleDeleteCategory = async () => {
    try {
      setLoading(true);
      const res = await postDeleteCustomCategory(category._id);
      if (res.data.status && res.data.status === "success") {
        dispatch(deleteCustomCategoryAction(category._id));
      }
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      hide();
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const beforeUpload = (file) => {
    if (!acceptedTypes.includes(file?.type) || !file) {
      message.error("You can only upload JPG/PNG file!");
      return false;
    }
    if (file.type?.includes("image")) {
      if (file.size / 1000000 > 2.2) {
        message.error(intl.formatMessage({ id: "limit2Mb" }));
        return false;
      }
    }

    return true;
  };

  // handle image upload;
  const handleChangeImages = ({ fileList, file }) => {
    let newFileList = [...fileList];
    setErrorSize(false);
    let errors = [];
    newFileList.forEach((el) => {
      if (el.size > 2000000) {
        //check if the image size is bigger than 2Mo
        errors.push(el?.uid);
      }
    });
    if (errors.length > 0) {
      //return error if there is images bigger than 2Mo
      setErrorSize(true);
      return;
    }

    if (file.status === "uploading") {
      setLoading(true); // show loading;
    } else if (file.status === "done") {
      // upload success;
      setLoading(false); // hide loading;
      if (file.response?.category?._id) {
        if (file?.response.category?._id) {
          openNotification({
            title: intl.formatMessage({ id: "savedSuccessfully" }),
            type: "success",
            rtl,
          });
        }
      }
    } else if (file.status === "error") {
      setLoading(false); // hide loading;
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
    setLogos([file]);
  };

  // handle delete image;
  return (
    <Modal
      title={
        <p className='my-font'>
          {intl.formatMessage({ id: "customCategory" })}
        </p>
      }
      centered
      visible={show}
      destroyOnClose={false}
      mask={true}
      maskClosable={true}
      onCancel={() => {
        hide();
        setIsNew(true);
      }}
      footer={null}
    >
      <Modal
        visible={previewVisible}
        title={rtl ? restaurant?.name : restaurant?.name_en}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
      >
        <img
          alt={restaurant?.name}
          style={{ width: "100%" }}
          src={previewImage}
        />
      </Modal>
      <Form
        name='editCategory'
        form={form}
        preserve={false}
        className='form-container'
        layout='vertical'
        onFinish={editAddCategory}
        initialValues={
          isNew
            ? {
                name: "",
                name_en: "",
                time: [],
                types: ["ALL"],
                isActive: true,
                showCustomTime: false,
                categoriesIds: [],
              }
            : {
                name: category?.name,
                name_en: category?.name_en,
                types: category?.types || ["ALL"],
                time:
                  category?.startTime > 0 || category?.endTime > 0
                    ? [
                        {
                          start: moment()
                            .startOf("day")
                            .add(category.startTime, "seconds"),
                          end: moment()
                            .startOf("day")
                            .add(category.endTime, "seconds"),
                        },
                      ]
                    : [],
                isActive: false,
                showCustomTime: category?.showCustomTime ? true : false,
                categoriesIds: category?.categoriesIds || [],
              }
        }
      >
        <Form.Item
          label={intl.formatMessage({ id: "nameAr" })}
          className='mb-1'
          name='name'
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: "errorname" }),
            },
          ]}
        >
          <Input
            className={"input"}
            disabled={!isNew && category?.name === "أخرى"}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: "nameEn" })}
          className='mb-1'
          name='name_en'
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: "errorname" }),
            },
          ]}
        >
          <Input
            className={"input"}
            disabled={!isNew && category?.name === "أخرى"}
          />
        </Form.Item>
        <Row
          style={{
            justifyContent: "space-between",
          }}
        >
          <Col span={12}>
            <Form.Item
              label={intl.formatMessage({ id: "likedCategories" })}
              className='mb-1'
              name='categoriesIds'
            >
              <Select className='my-font' mode='multiple'>
                {categories.map((cat) => (
                  <Select.Option key={cat.categoryId} value={cat.categoryId}>
                    {rtl ? cat.name : cat?.name_en}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {/* <Col
            span={10}
            style={{
              display: "flex",
              marginRight: 10,
              marginLeft: 10,
              // justifyContent: "center",
            }}
          >
            <Form.Item
              label={intl.formatMessage({ id: "status" })}
              className='mb-1'
              name='isActive'
              style={{ borderRadius: 20 }}
              valuePropName='checked'
            >
              <Switch
                checkedChildren={
                  <p>
                    {intl.formatMessage({
                      id: "active",
                    })}
                  </p>
                }
                unCheckedChildren={
                  <p>
                    {intl.formatMessage({
                      id: "disabled",
                    })}
                  </p>
                }
              />
            </Form.Item>
          </Col> */}
        </Row>
        <Spin spinning={false}>
          <Title className='my-font' level={4}>
            {intl.formatMessage({ id: "pictures" })}
          </Title>
          <div
            style={{
              position: "absolute",
              top: 15,
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            {errorSize ? (
              <Text className='my-font' style={{ color: COLORS.danger }}>
                {intl.formatMessage({ id: "limit2Mb" })}
              </Text>
            ) : null}
          </div>
          <Dragger
            action={`${apiUrl}/category-upload-cover/${category?._id}?slug=${restaurant?.slug}&categoryID=${category?._id}&isCustom=true`}
            headers={{ authorization: "Bearer " + user.token }}
            name='picture'
            fileList={logos}
            listType='picture'
            onChange={handleChangeImages}
            onPreview={onPreview}
            beforeUpload={beforeUpload}
            multiple={false}
            maxCount={0}
            accept='image/jpg,image/jpeg,image/png,video/mp4'
            disabled={loading || isNew || !category?._id}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <InboxOutlined
                style={{
                  color: errorSize ? COLORS.danger : COLORS.primary,
                  fontSize: "2rem",
                }}
                className='mx-3'
              />
              <Text
                style={{
                  fontSize: "1rem",
                  color: errorSize ? COLORS.danger : COLORS.primary,
                }}
                className='my-font'
              >
                {intl.formatMessage({
                  id: isNew
                    ? "youCanAddImagesAfterSaving"
                    : logos?.length === 0
                    ? "addPhoto"
                    : "change",
                })}
              </Text>
            </div>
          </Dragger>
        </Spin>
        <div style={{ minHeight: 20 }}></div>
        <Form.List name={"time"}>
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 8, marginTop: 8 }}
                    align='center'
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "start"]}
                      fieldKey={[fieldKey, "start"]}
                      label={intl.formatMessage({ id: "from" })}
                    >
                      <TimePicker
                        placeholder={intl.formatMessage({ id: "from" })}
                        format={"HH:mm"}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "end"]}
                      fieldKey={[fieldKey, "end"]}
                      label={intl.formatMessage({ id: "to" })}
                    >
                      <TimePicker
                        placeholder={intl.formatMessage({ id: "to" })}
                        format={"HH:mm"}
                      />
                    </Form.Item>
                    <IoCloseCircleOutline
                      size={20}
                      style={{ color: COLORS.danger, marginTop: 10 }}
                      onClick={() => {
                        remove(name);
                      }}
                    />
                  </Space>
                ))}
              </>
            );
          }}
        </Form.List>

        <Form.Item>
          <Popconfirm
            title={
              <Space direction='vertical'>
                <Text className='my-font'>
                  {intl.formatMessage({ id: "confirmDeleteCategoryTitle" })}
                </Text>
              </Space>
            }
            description={intl.formatMessage({
              id: "confirmDelete",
            })}
            okText={intl.formatMessage({ id: "delete" })}
            okButtonProps={{
              style: { color: COLORS.danger, fontFamily: "Cairo" },
            }}
            okType='text'
            cancelText={intl.formatMessage({ id: "cancel" })}
            cancelButtonProps={{
              style: { border: "none", fontFamily: "Cairo" },
            }}
            onConfirm={handleDeleteCategory}
            disabled={category?.name === "أخرى" || isNew}
          >
            <Text
              disabled={category?.name === "أخرى" || isNew}
              type={"danger"}
              className='pointer my-font'
            >
              {intl.formatMessage({ id: "delete" })}
            </Text>
          </Popconfirm>
        </Form.Item>
        <Divider />
        <Space direction='horizontal'>
          <Form.Item>
            <CustomButton
              htmlType='submit'
              text={intl.formatMessage({ id: "save" })}
              className={`btnRegister btn-text border-8`}
              loading={loading}
              disabled={loading}
              type='primary'
            />
          </Form.Item>

          <Form.Item>
            <CustomButton
              type='text'
              text={intl.formatMessage({ id: "cancel" })}
              disabled={loading}
              onClick={() => {
                hide();
              }}
            />
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  );
};

export default EditCustomCategoryModal;
