import moment from 'moment';

export const getStartEndDate = (value) => {

    switch (value) {
        case "today":
            return {
                start: moment().startOf('day').format('YYYY-MM-DD'),
                end: moment().endOf('day').format('YYYY-MM-DD'),
            }
        case "yesterday":
            return {
                start: moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD'),
                end: moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD'),
            }
        case "last7days":
            return {
                start: moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD'),
                end: moment().endOf('day').format('YYYY-MM-DD'),
            }
        case "last14days":
            return {
                start: moment().subtract(14, 'days').startOf('day').format('YYYY-MM-DD'),
                end: moment().endOf('day').format('YYYY-MM-DD'),
            }
        case "last30days":
            return {
                start: moment().subtract(30, 'days').startOf('day').format('YYYY-MM-DD'),
                end: moment().endOf('day').format('YYYY-MM-DD'),
            }
        case "thisMonth":
            return {
                start: moment().startOf('month').format('YYYY-MM-DD'),
                end: moment().endOf('month').format('YYYY-MM-DD'),
            }
        case "lastMonth":
            return {
                start: moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
                end: moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
            }
        default:
            return {
                start: moment().subtract(14, 'days').startOf('day').format('YYYY-MM-DD'),
                end: moment().endOf('day').format('YYYY-MM-DD'),
            }

    }

}