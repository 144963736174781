import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={11.224}
    height={25.721}
    {...props}
  >
    <g data-name="Group 10">
      <path
        d="m.341 9.773.006-.024.007-.024 2.015-6.9a1.689 1.689 0 0 1 .078-.238 1.34 1.34 0 0 1-.841-1.244 1.34 1.34 0 0 1 1.34-1.34h5.32a1.34 1.34 0 0 1 1.34 1.34 1.34 1.34 0 0 1-.841 1.243 1.675 1.675 0 0 1 .078.239l2.015 6.9.007.024.006.024a14.7 14.7 0 0 1 .334 4.089c0 .131-.008.255-.011.37v7.607a3.887 3.887 0 0 1-3.883 3.882H3.9a3.887 3.887 0 0 1-3.883-3.882v-7.974a15.181 15.181 0 0 1 .326-4.092"
        fill={props?.fill || "#0a2240"}
      />
      <path
        d="m.902 12.515.078.025c.086.03.622.176.639.18a5.715 5.715 0 0 0 1.978.081 16.005 16.005 0 0 0 4.218-1.811 4.085 4.085 0 0 1 1.871-.474 4.339 4.339 0 0 1 .436.06 16.061 16.061 0 0 1 .177 3.641v7.621a2.987 2.987 0 0 1-2.987 2.986H3.901a2.987 2.987 0 0 1-2.987-2.986v-7.9c-.011-.432-.025-.906-.012-1.427"
        fill="#fff"
      />
    </g>
  </svg>
)

export default SvgComponent
