import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import {
  Form,
  Spin,
  Typography,
  Row,
  Col,
  Input,
  InputNumber,
  Select,
  Divider,
  Button,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import openNotification from "../../../Components/Notifications";
import COLORS from "../../../Style/colors";
import {
  IoArrowBackOutline,
  IoArrowForwardOutline,
} from "react-icons/io5";
import { PlusOutlined } from "@ant-design/icons";
import ChartData from "./ChartData";
import CustomButton from "../../../Components/CustomButton";
import { postAddFeedback, postUpdateForm } from "../../../API/fetch";
import {
  addFeedbackAction,
  editFeedbackAction,
} from "../../../redux/reducers/restaurantReducer";
import AnswersDetails from "./AnswersDetails";

const { Text, Title } = Typography;
const { Option } = Select;

const OneFeedBack = () => {
  const intl = useIntl();
  const params = useParams();
  const history = useNavigate();
  const [form] = Form.useForm();
  const { rtl } = useSelector((state) => state.settings);
  const { feedbackForms, restaurant } = useSelector(
    (state) => state.restaurant
  );
  const dispatch = useDispatch();
  const [feedbackForm, setFeedbackForm] = useState(null);
  const [isNew, setIsNew] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const [questionsLength, setQuestionsLength] = useState(0);
  const [chartData, setChartData] = useState([]);
  const [filteredAnswers, setFilteredAnswers] = useState({});

  // Selected food;
  useEffect(() => {
    if (params?.id) {
      const selected = feedbackForms.find((t) => t._id === params.id);
      if (selected) {
        setFeedbackForm(selected);
        setQuestionsLength(selected.questions.length);
        setInitialLoading(false);
        const chart = selected.questions.filter(
          (q) => q.answerType === "faces" || q.answerType === "stars"
        );
        const questionsWithAnswers = {};
        const allAnswers = selected?.answers?.flatMap((answers) =>
          answers?.userAnswers?.map((a) => a)
        );
        
        allAnswers?.forEach((answer) => {
          const question = chart?.find(
            (q) => q._id === answer.questionId
          );
          if (question) {
            const answers = questionsWithAnswers[question._id] || [];
            answers.push(answer);
            questionsWithAnswers[question._id] = answers;
          }
        });
        setFilteredAnswers(questionsWithAnswers);
        setChartData(chart);
      }
      if (params?.id === "new") {
        setIsNew(true);
        setInitialLoading(false);
      }
    }
  }, [params, feedbackForms]);

  useEffect(() => {
    // set the values;
    if (feedbackForm && feedbackForm?._id) {
      form.resetFields();
    }
  }, [feedbackForm]);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      setInitialLoading(true);
      const data = {
        feedback: { ...values, owner: restaurant._id },
      };
      if (isNew) {
        const res = await postAddFeedback(data);
        dispatch(addFeedbackAction(res.data.feedback));
      } else {
        const res = await postUpdateForm(feedbackForm?._id, data);

        dispatch(editFeedbackAction(res.data.feedback));
      }

      setLoading(false);
      setInitialLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
    } catch (error) {
      setLoading(false);
      setInitialLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        flexDirection: "column",
      }}
    >
       <Row
          style={{
            position: "relative",
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <Title
            level={3}
            className='pointer'
            onClick={() => history(`/feedback`)}
            style={rtl ? { position: "absolute", zIndex: 0, right: 25, top: 10, }: { position: "absolute", zIndex: 0, left: 25, top: 10, }}
          >
            {rtl ? (
              <IoArrowForwardOutline className='pointer' />
            ) : (
              <IoArrowBackOutline className='pointer' />
            )}
          </Title>
        </Row>
       {/* Answers overview */}
       {chartData?.length ? (
        <div
          style={{
            backgroundColor: COLORS.white,
            // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            borderRadius: 6,
            marginTop: 40,
            width: "92%",
            marginRight: "auto",
            marginLeft: "auto",
            padding: 15,
          }}
          className='light-shadow'
        >
          <Spin spinning={initialLoading}>
            <Title className='my-font' level={4}>
              {intl.formatMessage({ id: "answersOverview" })}
            </Title>
            <Row>
              <ChartData data={chartData} answers={filteredAnswers} allAnswers={feedbackForm?.answers} />
            </Row>
          </Spin>
        </div>
      ) : null}
      {/* Answers Details */}
      {isNew ? null : feedbackForm?.answers?.length ? (
        <div
          style={{
            backgroundColor: COLORS.white,
            // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            borderRadius: 6,
            marginTop: 40,
            width: "92%",
            marginRight: "auto",
            marginLeft: "auto",
            padding: 15,
          }}
          className='light-shadow'
        >
          <Spin spinning={initialLoading}>
            <Title className='my-font' level={4}>
              {intl.formatMessage({ id: "details" })}
            </Title>
            <AnswersDetails
              answers={feedbackForm?.answers}
              questions={feedbackForm?.questions}
            />
          </Spin>
        </div>
      ) : null}
      {/* Form */}
      <div
        style={{
          backgroundColor: COLORS.white,
          // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          borderRadius: 6,
          marginTop: 50,
          width: "92%",
          marginRight: "auto",
          marginLeft: "auto",
          padding: 15,
        }}
        className='light-shadow'
      >
       
        <Spin spinning={initialLoading}>
          <Form
            name='feedback-form'
            form={form}
            className='form-container'
            layout='vertical'
            onFinish={onFinish}
            initialValues={{
              ...feedbackForm,
              //   questionsCount: feedbackForm?.questions?.length,
              questions: feedbackForm?.questions?.map((question) => ({
                ...question,
                key: question._id,
              })),
            }}
          >
            <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
              {/* Title */}
              <Col md={12} sm={24} xs={24}>
                <Title className='my-font' level={4}>
                  {intl.formatMessage({ id: "basicInfo" })}
                </Title>
              </Col>
            </Row>
            <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
              {/* Names */}
              <Col md={12} sm={24} xs={24}>
                <Form.Item
                  label={intl.formatMessage({ id: "formName" })}
                  tooltip={
                    <Text type='primary' className='my-font'>
                      {intl.formatMessage({ id: "formNameDes" })}
                    </Text>
                  }
                  className='mb-1'
                  name='name'
                  style={{ borderRadius: 20 }}
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: "errorname" }),
                    },
                  ]}
                >
                  <Input
                    className={"input"}
                    style={{ borderRadius: 5, width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col md={12} sm={24} xs={24}>
                <Form.Item
                  label={intl.formatMessage({ id: "questionsCount" })}
                  className='mb-1'
                  disabled={true}
                >
                  <InputNumber
                    value={questionsLength}
                    className={"input"}
                    disabled={true}
                    style={{ borderRadius: 5, width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
              {/* Title */}
              <Col md={12} sm={24} xs={24}>
                <Title className='my-font' level={4}>
                  {intl.formatMessage({ id: "questions" })}
                </Title>
              </Col>
            </Row>
            <Row className='mt-3' gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
              {/* <Col md={24} sm={24} xs={24}> */}
              <Form.List name={"questions"}>
                {(fields, { add, remove }) => {
                  return (
                    <>
                      {fields.map(({ key, name, fieldKey, ...restField }) => {
                        return (
                          <React.Fragment key={key}>
                            <Col md={9} sm={24} xs={24}>
                              <Form.Item
                                {...restField}
                                name={[name, "question"]}
                                fieldKey={[fieldKey, "question"]}
                                label={intl.formatMessage({ id: "questionAr" })}
                                rules={[
                                  {
                                    required: true,
                                    message: intl.formatMessage({
                                      id: "errorname",
                                    }),
                                  },
                                ]}
                              >
                                <Input
                                  className={"input"}
                                  style={{
                                    borderRadius: 5,
                                    width: "100%",
                                    direction: "rtl",
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col md={9} sm={24} xs={24}>
                              <Form.Item
                                {...restField}
                                name={[name, "question_en"]}
                                fieldKey={[fieldKey, "question_en"]}
                                label={intl.formatMessage({ id: "questionEn" })}
                                rules={[
                                  {
                                    required: true,
                                    message: intl.formatMessage({
                                      id: "errorname",
                                    }),
                                  },
                                ]}
                              >
                                <Input
                                  className={"input"}
                                  style={{
                                    borderRadius: 5,
                                    width: "100%",
                                    direction: "ltr",
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col md={6} sm={24} xs={24}>
                              <Form.Item
                                {...restField}
                                name={[name, "answerType"]}
                                fieldKey={[fieldKey, "answerType"]}
                                label={intl.formatMessage({ id: "answerType" })}
                                rules={[
                                  {
                                    required: true,
                                    message: intl.formatMessage({
                                      id: "errorname",
                                    }),
                                  },
                                ]}
                              >
                                <Select className='my-font'>
                                  <Option className='my-font' value='stars'>
                                    {intl.formatMessage({ id: "stars" })} ⭐️{" "}
                                  </Option>
                                  <Option className='my-font' value='faces'>
                                    {intl.formatMessage({ id: "faces" })} 😁{" "}
                                  </Option>
                                  <Option className='my-font' value='text'>
                                    {intl.formatMessage({ id: "text" })} 🧷{" "}
                                  </Option>
                                </Select>
                              </Form.Item>
                            </Col>
                          </React.Fragment>
                        );
                      })}

                      <Col md={24} sm={24} xs={24}>
                        <Form.Item>
                          <Button
                            className='my-font'
                            type='dashed'
                            onClick={() => {
                              add();
                              setQuestionsLength(questionsLength + 1);
                            }}
                            block
                            icon={<PlusOutlined />}
                          >
                            {intl.formatMessage({ id: "addQuestion" })}
                          </Button>
                        </Form.Item>
                      </Col>
                    </>
                  );
                }}
              </Form.List>
              {/* </Col> */}
            </Row>
            <Divider />
            <Row justify='start' className='mt-5' align='middle'>
              <Form.Item>
                <CustomButton
                  htmlType='submit'
                  text={intl.formatMessage({ id: "save" })}
                  className={`btnRegister btn-text border-8 px-5 `}
                  loading={loading}
                  disabled={loading}
                  type='primary'
                />
              </Form.Item>
              <Form.Item>
                <CustomButton
                  type='secondary'
                  text={intl.formatMessage({ id: "back" })}
                  className={` border-8 mx-5 px-5`}
                  loading={loading}
                  disabled={loading}
                  onClick={() => history(-1)}
                />
              </Form.Item>
            </Row>
          </Form>
        </Spin>
      </div>

     
    </div>
  );
};

export default OneFeedBack;
