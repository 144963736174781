import React from 'react'
import { Col, Row , Typography } from "antd";
import { useIntl } from 'react-intl';
import { getTotalPersons , getReportTotal} from '../../../helpers';

const { Text } = Typography;
const boxStyle = {
  display: "flex",
  justifyContent: "space-around",
  backgroundColor: "white",
  padding: "8px",
  boxShadow: "1px 2px 3px lightgray"
}

function ReportSummary({ amounts}) {

  const intl = useIntl()
  return (
    <Row style={{ backgroundColor: "#fafafa", padding: "10px 20px" }} gutter={16}>
    
      <Col className="gutter-row" span={5}>
        <div style={boxStyle}>
          <Text className='my-font'>
            {intl.formatMessage({ id: "totalReservations" })}
          </Text>
          <Text className='my-font'>
            {amounts?.length}
          </Text>
        </div>
      </Col>
      <Col className="gutter-row" span={5}>
        <div style={boxStyle}>
          <Text className='my-font'>
            {intl.formatMessage({ id: "totalPersons" })}
          </Text>
          <Text className='my-font'>
           {getTotalPersons(amounts)}

          </Text>
        </div>
      </Col>
      <Col className="gutter-row" span={5}>
        <div style={boxStyle}>
          <Text className='my-font'>
            {intl.formatMessage({ id: "totalAmounts" })}
          </Text>
          <Text className='my-font'>
            {getReportTotal(amounts)?.toFixed(2)}
          </Text>

        </div>
      </Col>
    </Row>
  )
}

export default ReportSummary