import React, { useState, useEffect } from "react";
import {
  Image,
  Typography,
  Collapse,
  Switch,
  Space,
  Popconfirm,
} from "antd";

import COLORS from "../../../Style/colors";
import { useIntl } from "react-intl";
import { SketchPicker } from "react-color";
import CustomButton from "../../../Components/CustomButton";

const { Paragraph, Text } = Typography;
const { Panel } = Collapse;
const ImageAddRow = ({ fun, file,  handleEditImage,  }) => {
  const intl = useIntl();
  const [title, setTitle] = useState("");
  const [color, setColor] = useState("#fafafa");
  const [displayStatus, setDisplayStatus] = useState("Active");

  useEffect(() => {
    if (file?.title) {
      setTitle(file.title);
    }
    setColor(file?.color);
    setDisplayStatus(file?.displayStatus);
  }, [file]);

  return (
    <div
      style={{
        border: "1px solid #d9d9d9",
        width: 300,
        padding: "8px",
        borderRadius: "2px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      className='mt-2'
    >
      <Image width={150} src={file.url} />

      <Paragraph
        className='mt-2'
        editable={{
          onChange: setTitle,
          tooltip: <Text>{intl.formatMessage({ id: "edit" })}</Text>,
        }}
      >
        {title}
      </Paragraph>
      {/* <div>
      </div> */}
      <Collapse accordion ghost>
        <Panel
          key='color'
          header={
            <Text className='my-font'>
              {intl.formatMessage({ id: "colorTitle" })}
            </Text>
          }
        >
          <SketchPicker
            color={color}
            onChangeComplete={(color) => {
              setColor(color.hex);
            }}
          />
        </Panel>
      </Collapse>

      <Switch
        className='mx-3 mb-4'
        checkedChildren={
            <p>
              {intl.formatMessage({
                id: "active",
              })}
            </p>
          }
          unCheckedChildren={
            <p>
              {intl.formatMessage({
                id: "disabled",
              })}
            </p>
          }
        checked={displayStatus === "Active"}
        onChange={(v) => setDisplayStatus(v ? "Active" : "notActive")}
      />
      <Space direction='horizontal'>
        <CustomButton
          onClick={() => handleEditImage({ ...file, title, color, status: displayStatus })}
          text={intl.formatMessage({ id: "save" })}
          type='primary'
          className='px-5 border-8 mt-3'
        />
        <Popconfirm
          title={
            <Text className='my-font'>
              {intl.formatMessage({ id: "confirmDelete" })}
            </Text>
          }
          okText={intl.formatMessage({ id: "delete" })}
          okButtonProps={{
            style: { color: COLORS.danger, fontFamily: "Cairo" },
          }}
          okType='text'
          cancelText={intl.formatMessage({ id: "cancel" })}
          cancelButtonProps={{
            style: { border: "none", fontFamily: "Cairo" },
          }}
          onConfirm={() => fun.remove()}
        >
          <Text type='danger' className='pointer my-font  px-5 border-8 mt-3'>
            {intl.formatMessage({ id: "delete" })}
          </Text>
        </Popconfirm>
      </Space>
    </div>
  );
};

export default ImageAddRow;
