import React, { useState, useEffect } from "react";
import {
  Space,
  Typography,
  Row,
  Col,
  Avatar,
  Image,
  Badge,
  Carousel,
} from "antd";
import { FaBars, FaShoppingBasket } from "react-icons/fa";
import { useSelector } from "react-redux";
import imagePlaceholder from "../../../assets/images/empty-foods.jpg";
import { IoSearchOutline } from "react-icons/io5";
import LOGO from "../../../assets/images/icon-iwaiter.png";
import { DARK_DESIGN, DEFAULT_DESIGN, SAUDI_DESIGN } from "./DEFAULT_DESIGN";
import { cutText } from "../../../helpers";
import COLORS from "../../../Style/colors";

const { Text, Title } = Typography;
const MainPagePreview = ({ form, showAdds, images = [] }) => {
  const { categories } = useSelector((state) => state.menu);
  const { logo } = useSelector((state) => state.restaurant);
  const { rtl, isBigScreen } = useSelector((state) => state.settings);
  const [DEFAULT_COLORS, setDEFAULT_COLORS] = useState(DEFAULT_DESIGN);
  const [theme, setTheme] = useState("LIGHT");
  useEffect(() => {
    if (form?.getFieldValue("theme") === "DARK") {
      setTheme("DARK");
      setDEFAULT_COLORS(DARK_DESIGN);
    } else if (form?.getFieldValue("theme") === "CUSTOM") {
      setTheme("CUSTOM");
    } else if (form?.getFieldValue("theme") === "LIGHT") {
      setTheme("LIGHT");
      setDEFAULT_COLORS(DEFAULT_DESIGN);
    }else if(form?.getFieldValue("theme") === "SAUDI"){
      setTheme("SAUDI");
      setDEFAULT_COLORS(SAUDI_DESIGN);
    }
  });

  return (
    <div style={{ height: "100%" }}>
      <div
        className='iframe-content'
        style={{
          backgroundColor:
            theme === "CUSTOM"
              ? form?.getFieldValue("backgroundColor")
              : DEFAULT_COLORS.backgroundColor,
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "space-between",
        }}
      >
        {/* Heder */}
        <Space
          direction='horizontal'
          style={{
            width: "100%",
            justifyContent: "space-between",
            paddingTop: 30,
            flexGrow: 1,
          }}
          className='px-3'
        >
          <div
            className='icon-bg'
            style={{
              borderRadius: "50%",
              width: "40px",
              height: "40px",
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              backgroundColor:
                theme === "CUSTOM"
                  ? form?.getFieldValue("backgroundCircleBG")
                  : DEFAULT_COLORS.backgroundCircleBG,
            }}
          >
            <IoSearchOutline
              color={
                theme === "CUSTOM"
                  ? form?.getFieldValue("backgroundCircleText")
                  : DEFAULT_COLORS.backgroundCircleText
              }
              size={20}
            />
          </div>
          <Avatar size={50} src={logo?.[0]?.url ? logo[0].url : LOGO} />
          <div
            className='icon-bg'
            style={{
              borderRadius: "50%",
              width: "40px",
              height: "40px",
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              backgroundColor:
                theme === "CUSTOM"
                  ? form?.getFieldValue("backgroundCircleBG")
                  : DEFAULT_COLORS.backgroundCircleBG,
            }}
          >
            <FaBars
              size={18}
              color={
                theme === "CUSTOM"
                  ? form?.getFieldValue("backgroundCircleText")
                  : DEFAULT_COLORS.backgroundCircleText
              }
            />
          </div>
        </Space>
        {/* Categories */}
        <Row className={"mb-2"}>
          <Title
            level={5}
            style={{
              color:
                theme === "CUSTOM"
                  ? form.getFieldValue("titleColor")
                  : DEFAULT_COLORS.titleColor,
            }}
          >
            Table 1
          </Title>
        </Row>

        <Row style={{ flexGrow: 1 }}>
          <div
            className='category-overflow'
            style={{
              overflowX: "scroll",
              overflowY: "hidden",
              display: "flex",
              alignItems: "center",
            }}
          >
            {categories?.length
              ? categories?.map((cat, i) => {
                  let isSelected = i === 0;
                  return (
                    <Text
                      key={cat?.categoryId}
                      className='my-font mx-2'
                      style={{
                        color: isSelected
                          ? form.getFieldValue("primaryButtonText")
                          : form?.getFieldValue("backgroundCircleText") || "#000",
                        backgroundColor: isSelected
                          ? form.getFieldValue("primaryButtonBG")
                          : form?.getFieldValue("backgroundBox") || "#fff",
                        paddingRight: 5,
                        paddingLeft: 5,
                        borderRadius: 7,
                        height: 50,
                        minWidth: 80,
                        textAlign: "center",
                      }}
                    >
                      {rtl ? cat?.name : cat?.name_en}
                    </Text>
                  );
                })
              : [
                  "Burger",
                  "Pizza",
                  "Sandwich",
                  "Drink",
                  "Dessert",
                  "IceCream",
                ].map((cat, i) => {
                  let isSelected = i === 0;
                  return (
                    <Text
                      key={cat}
                      className='my-font mx-2'
                      style={{
                        color: isSelected
                          ? form.getFieldValue("primaryButtonText")
                          : form?.getFieldValue("textColor") || "#000",
                        backgroundColor: isSelected
                          ? form.getFieldValue("primaryButtonBG")
                          : form?.getFieldValue("backgroundBox") || "#fff",
                        paddingRight: 5,
                        paddingLeft: 5,
                        borderRadius: 7,
                        height: 50,
                        minWidth: 80,
                        textAlign: "center",
                      }}
                    >
                      {cat}
                    </Text>
                  );
                })}
          </div>
        </Row>

        {/* Adds */}
        {showAdds ? (
          <Carousel autoplay>
            {images?.length ? (
              images?.map((imag) => {
                return (
                  <div key={imag._id}>
                    <Title
                      level={5}
                      style={{
                        position: "absolute",
                        zIndex: 99,
                        top: 5,
                        marginRight: isBigScreen ? 150 : 70,
                        marginLeft: isBigScreen ? 150 : 70,
                        color: imag.color,
                      }}
                    >
                      {imag.title}
                    </Title>
                    <Image
                      preview={false}
                      placeholder={
                        <Image preview={false} src={imagePlaceholder} />
                      }
                      src={imag.url || imagePlaceholder}
                      fallback={imagePlaceholder}
                    />
                  </div>
                );
              })
            ) : (
              <div>
                <Title
                  style={{
                    backgroundColor: COLORS.lightBlue,
                    color: COLORS.primary,
                    position: "absolute",
                    zIndex: 99,
                    top: 5,
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                  level={2}
                >
                  Best Burger
                </Title>
                <Image
                  style={{
                    objectFit: "cover",
                  }}
                  preview={false}
                  src={imagePlaceholder}
                />
              </div>
            )}
          </Carousel>
        ) : null}

        {/* Foods */}
        <Row
          style={{ flexGrow: 10 }}
          type='flex'
          justify='center'
          align='middle'
        >
          {categories[0]?.foods?.length
            ? categories[0]?.foods?.map((food, i) => {
                if (i > 3) return null;
                if (showAdds && i > 1) return null;
                const isGrid = form?.getFieldValue("display") === "grid";
                const isOneCard = form?.getFieldValue("display") === "oneCard";
                if (isOneCard && i > 0) return null;
                return (
                  <Col
                    style={{ overflow: "hidden", borderRadius: 6 }}
                    key={food?.foodId}
                    span={isGrid ? 11 : 22}
                    className='mx-1 my-2 light-shadow'
                  >
                    <div
                      style={{
                        backgroundColor:
                          theme === "CUSTOM"
                            ? form?.getFieldValue("backgroundBox")
                            : DEFAULT_COLORS?.backgroundBox,
                        display: "flex",
                        flexDirection: isGrid || isOneCard ? "column" : "row",
                        minHeight: isOneCard ? 250 : 100,
                        alignItems: "center",
                      }}
                    >
                      <Image
                        style={{
                          height: isGrid ? 130 : isOneCard ? 180 : 100,
                          width: isGrid || isOneCard ? "100%" : 100,
                          objectFit: "cover",
                        }}
                        preview={false}
                        placeholder={
                          <Image
                            preview={false}
                            src={imagePlaceholder}
                            style={{ height: 130, objectFit: "cover" }}
                          />
                        }
                        src={food?.images?.[0]?.url || imagePlaceholder}
                        fallback={imagePlaceholder}
                      />
                      <Space direction='vertical'>
                        <Text
                          className='my-font'
                          style={{
                            color:
                              theme === "CUSTOM"
                                ? form?.getFieldValue("backgroundBoxText")
                                : DEFAULT_COLORS.backgroundBoxText,
                          }}
                        >
                          {food?.name}
                        </Text>
                        <Text
                          className='my-font'
                          style={{
                            color:
                              theme === "CUSTOM"
                                ? form?.getFieldValue(
                                    "backgroundBoxDescription"
                                  )
                                : DEFAULT_COLORS.backgroundBoxDescription,
                          }}
                        >
                          {cutText(food?.description, 30)}
                        </Text>
                        <Text
                          className='my-font'
                          style={{
                            color:
                              theme === "CUSTOM"
                                ? form?.getFieldValue("backgroundBoxText")
                                : DEFAULT_COLORS.backgroundBoxText,
                          }}
                        >
                          {food?.price}
                        </Text>
                      </Space>
                    </div>
                  </Col>
                );
              })
            : categories[1]?.foods?.length
            ? categories[1]?.foods?.map((food, i) => {
                if (i > 3) return null;
                if (showAdds && i > 1) return null;
                const isGrid = form?.getFieldValue("display") === "grid";
                const isOneCard = form?.getFieldValue("display") === "oneCard";
                if (isOneCard && i > 0) return null;

                return (
                  <Col
                    style={{ overflow: "hidden" }}
                    key={food?.foodId}
                    span={isGrid ? 11 : 22}
                    className='mx-1 my-2'
                  >
                    <div
                      style={{
                        backgroundColor:
                          theme === "CUSTOM"
                            ? form?.getFieldValue("backgroundBox")
                            : DEFAULT_COLORS?.backgroundBox,
                        display: "flex",
                        flexDirection: isGrid || isOneCard ? "column" : "row",
                        minHeight: isOneCard ? 250 : 100,
                        alignItems: "center",
                      }}
                    >
                      <Image
                        style={{
                          height: isGrid ? 130 : isOneCard ? 180 : 100,
                          width: isGrid || isOneCard ? "100%" : 100,
                          objectFit: "cover",
                        }}
                        preview={false}
                        placeholder={
                          <Image
                            preview={false}
                            src={imagePlaceholder}
                            style={{ height: 130, objectFit: "cover" }}
                          />
                        }
                        src={food?.images?.[0]?.url || imagePlaceholder}
                        fallback={imagePlaceholder}
                      />
                      <Space direction='vertical'>
                        <Text
                          className='my-font'
                          style={{
                            color:
                              theme === "CUSTOM"
                                ? form?.getFieldValue("backgroundBoxText")
                                : DEFAULT_COLORS.backgroundBoxText,
                          }}
                        >
                          {food?.name}
                        </Text>
                        <Text
                          className='my-font'
                          style={{
                            color:
                              theme === "CUSTOM"
                                ? form?.getFieldValue(
                                    "backgroundBoxDescription"
                                  )
                                : DEFAULT_COLORS.backgroundBoxDescription,
                          }}
                        >
                          {cutText(food?.description, 30)}
                        </Text>
                        <Text
                          className='my-font'
                          style={{
                            color:
                              theme === "CUSTOM"
                                ? form?.getFieldValue("backgroundBoxText")
                                : DEFAULT_COLORS.backgroundBoxText,
                          }}
                        >
                          {food?.price}
                        </Text>
                      </Space>
                    </div>
                  </Col>
                );
              })
            : [
                "Burger",
                "Pizza",
                "Sandwich",
                "Drink",
                "Dessert",
                "IceCream",
              ].map((food, i) => {
                if (i > 3) return null;
                if (showAdds && i > 1) return null;
                const isGrid = form?.getFieldValue("display") === "grid";
                const isOneCard = form?.getFieldValue("display") === "oneCard";
                if (isOneCard && i > 0) return null;
                return (
                  <Col
                    style={{ overflow: "hidden" }}
                    key={food}
                    span={isGrid ? 11 : 22}
                    className='mx-1 my-2'
                  >
                    <div
                      style={{
                        backgroundColor:
                          theme === "CUSTOM"
                            ? form?.getFieldValue("backgroundBox")
                            : DEFAULT_COLORS?.backgroundBox,
                        display: "flex",
                        flexDirection: isGrid || isOneCard ? "column" : "row",
                        minHeight: isOneCard ? 250 : 100,
                        alignItems: "center",
                      }}
                    >
                      <Image
                        style={{
                          height: isGrid ? 130 : isOneCard ? 180 : 100,
                          width: isGrid || isOneCard ? "100%" : 100,
                          objectFit: "cover",
                        }}
                        preview={false}
                        src={imagePlaceholder}
                      />
                      <Space direction='vertical'>
                        <Text
                          className='my-font'
                          style={{
                            color:
                              theme === "CUSTOM"
                                ? form?.getFieldValue("backgroundBoxText")
                                : DEFAULT_COLORS.backgroundBoxText,
                          }}
                        >
                          {food}
                        </Text>
                        <Text
                          className='my-font'
                          style={{
                            color:
                              theme === "CUSTOM"
                                ? form?.getFieldValue(
                                    "backgroundBoxDescription"
                                  )
                                : DEFAULT_COLORS.backgroundBoxDescription,
                          }}
                        >
                          {"This is description"}
                        </Text>
                        <Text
                          className='my-font'
                          style={{
                            color:
                              theme === "CUSTOM"
                                ? form?.getFieldValue("backgroundBoxText")
                                : DEFAULT_COLORS.backgroundBoxText,
                          }}
                        >
                          19.19
                        </Text>
                      </Space>
                    </div>
                  </Col>
                );
              })}
        </Row>
        {/* Footer */}
        <Row
          style={{
            flexGrow: 1,
            backgroundColor:
              theme === "CUSTOM"
                ? form?.getFieldValue("backgroundBox")
                : DEFAULT_COLORS.backgroundBox,
            minHeight: 60,
            borderRadius: "1.5rem",
            width: "100%",
          }}
          type='flex'
          justify='space-between'
          align='middle'
        >
          {categories[0]?.foods?.length
            ? categories[0]?.foods?.map((food, i) => {
                if (i > 3) return null;
                return (
                  <Col key={food?.foodId}>
                    <Badge
                      count={3}
                      style={{
                        color:
                          theme === "CUSTOM"
                            ? form?.getFieldValue("backgroundTextFooter")
                            : DEFAULT_COLORS.backgroundTextFooter,
                        borderColor:
                          theme === "CUSTOM"
                            ? form?.getFieldValue("backgroundColor")
                            : DEFAULT_COLORS.backgroundColor,
                        backgroundColor:
                          theme === "CUSTOM"
                            ? form?.getFieldValue("backgroundColor")
                            : DEFAULT_COLORS.backgroundColor,
                      }}
                    >
                      <Avatar
                        size='small'
                        icon={
                          <Image
                            placeholder={
                              <Image
                                preview={false}
                                src={imagePlaceholder}
                                style={{ height: 130, objectFit: "cover" }}
                              />
                            }
                            src={food?.images?.[0]?.url || imagePlaceholder}
                            fallback={imagePlaceholder}
                          />
                        }
                      />
                    </Badge>
                  </Col>
                );
              })
            : categories[1]?.foods?.length
            ? categories[1]?.foods?.map((food, i) => {
                if (i > 3) return null;

                return (
                  <Col key={food?.foodId}>
                    <Badge
                      count={3}
                      style={{
                        color:
                          theme === "CUSTOM"
                            ? form?.getFieldValue("backgroundTextFooter")
                            : DEFAULT_COLORS.backgroundTextFooter,
                        borderColor:
                          theme === "CUSTOM"
                            ? form?.getFieldValue("backgroundColor")
                            : DEFAULT_COLORS.backgroundColor,
                        backgroundColor:
                          theme === "CUSTOM"
                            ? form?.getFieldValue("backgroundColor")
                            : DEFAULT_COLORS.backgroundColor,
                      }}
                    >
                      <Avatar
                        size='small'
                        icon={
                          <Image
                            placeholder={
                              <Image
                                preview={false}
                                src={imagePlaceholder}
                                style={{ height: 130, objectFit: "cover" }}
                              />
                            }
                            src={food?.images?.[0]?.url || imagePlaceholder}
                            fallback={imagePlaceholder}
                          />
                        }
                      />
                    </Badge>
                  </Col>
                );
              })
            : [
                "Burger",
                "Pizza",
                "Sandwich",
                "Drink",
                "Dessert",
                "IceCream",
              ].map((food, i) => {
                if (i > 3) return null;
                return (
                  <Col key={food}>
                    <Badge
                      count={3}
                      style={{
                        color:
                          theme === "CUSTOM"
                            ? form?.getFieldValue("backgroundTextFooter")
                            : DEFAULT_COLORS.backgroundTextFooter,
                        borderColor:
                          theme === "CUSTOM"
                            ? form?.getFieldValue("backgroundColor")
                            : DEFAULT_COLORS.backgroundColor,
                        backgroundColor:
                          theme === "CUSTOM"
                            ? form?.getFieldValue("backgroundColor")
                            : DEFAULT_COLORS.backgroundColor,
                      }}
                    >
                      <Avatar
                        size='small'
                        icon={<Image src={imagePlaceholder} />}
                      />
                    </Badge>
                  </Col>
                );
              })}
          <Col>
            <FaShoppingBasket
              size={25}
              color={
                theme === "CUSTOM"
                  ? form?.getFieldValue("textColor")
                  : DEFAULT_COLORS.textColor
              }
              className='mx-1'
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default MainPagePreview;
