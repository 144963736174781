import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Table, Tag, Space, Spin } from "antd";
import CustomButton from "../../Components/CustomButton";
import { useIntl } from "react-intl";
import COLORS from "../../Style/colors";
import { EditTwoTone } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import EditCustomCategoryModal from "./EditCustomCategoryModal";
import openNotification from "../../Components/Notifications";
import { getCustomCategories } from "../../API/fetch";
import { setCustomCategoriesAction } from "../../redux/reducers/menuReducer";

const { Title, Text } = Typography;

const CustomCategories = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { rtl } = useSelector((state) => state.settings);
  const { role } = useSelector((state) => state.auth);
  const { allBranches } = useSelector((state) => state.branch);
  const { customCategories } = useSelector((state) => state.menu);
  const [formattedCategories, setFormattedCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllCustomCategories();
  }, []);

  const getAllCustomCategories = async () => {
    try {
      setLoading(true);
      const res = await getCustomCategories();
      if (res?.data?.customCategories) {
        dispatch(setCustomCategoriesAction(res?.data?.customCategories));
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  useEffect(() => {
    if (customCategories.length) {
      const format = customCategories.map((category) => {
        let types = ["ALL"];
        if (category.types && category.types.length > 0) {
          types = category.types;
        }
        let status = "Active";
        // check if the category is hidden;
        let branchIds = [];
        if (role === "super") {
          branchIds = allBranches?.map((b) => b?.branch?._id);
        } else {
          branchIds = allBranches?.map((b) => b?._id);
        }
        let isHide = branchIds.every((id) =>
          category?.notAvailableIn?.includes(id)
        );
        if (isHide) {
          status = "disabled";
        }
        return {
          ...category,
          key: category._id,

          displayName: rtl ? category?.name : category?.name_en,
          foodsCount: category?.categoriesIds?.length || 0,
          types,
          index: category.order,
          status,
        };
      });
      setFormattedCategories(format);
    } else {
      setFormattedCategories([]);
    }
  }, [customCategories, rtl]);

  const columns = [
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "displayName",
      key: "displayName",
      render: (name, record) => {
        return (
          <Space
            direction='vertical'
            className={"pointer"}
            onClick={() => {
              navigate(`/foods?category=${record.categoryId}`);
            }}
          >
            <Text>{name}</Text>
            <Text type='secondary' underline>
              {record?.foodsCount} {intl.formatMessage({ id: "categories" })}
            </Text>
          </Space>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "status" }),
      dataIndex: "status",
      key: "status",
      render: (status, record) => {
        return (
          <Tag color={status === "Active" ? "green" : "red"}>
            {intl.formatMessage({ id: status })}
          </Tag>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "edit" }),
      dataIndex: "edit",
      render: (status, record) => {
        return (
          <EditTwoTone
            onClick={() => {
              setSelectedCategory(record);
              setShowCategoryModal(true);
            }}
            className={"pointer"}
          />
        );
      },
    },
  ];

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      {/* Modals */}

      <EditCustomCategoryModal
        category={selectedCategory}
        show={showCategoryModal}
        hide={() => {
          setSelectedCategory(null);
          setShowCategoryModal(false);
        }}
        index={selectedCategory?.index}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <Title>{intl.formatMessage({ id: "customCategories" })}</Title>
        <CustomButton
          text={intl.formatMessage({
            id: "addNew",
          })}
          type='primary'
          className='border-8'
          onClick={() => setShowCategoryModal(true)}
        />
      </div>
      <Spin spinning={loading}>
        <div
          style={{
            backgroundColor: COLORS.white,
            marginTop: 40,
            width: "92%",
            marginRight: "auto",
            marginLeft: "auto",
            borderRadius: 10,
          }}
          className='light-shadow'
        >
          <Table
            columns={columns}
            style={{ borderRadius: 10 }}
            dataSource={formattedCategories}
            scroll={{ x: 500 }}
            pagination={false}
          />
        </div>
      </Spin>
    </div>
  );
};

export default CustomCategories;
