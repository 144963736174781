import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Table, Tag, Select, Popconfirm } from "antd";
import CustomButton from "../../Components/CustomButton";
import { useIntl } from "react-intl";
import COLORS from "../../Style/colors";
import { DeleteOutlined, EditOutlined, EditTwoTone } from "@ant-design/icons";
import PromotionModal from "./Components/PromotionModal";
import moment from "moment";
import { PROMOTIONS_TYPES } from "../../Types";
import AddPromotionModal from "./Components/AddPromotionModal";
import { editPromotion } from "../../API/fetch";
import { editPromotionAction } from "../../redux/reducers/restaurantReducer";
import openNotification from "../../Components/Notifications";
import {
  getPromotionsByType,
  getPromotionTypesValues,
} from "../../helpers/promotions";
const { Title, Text } = Typography;

const Promotions = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { promotions } = useSelector((state) => state.restaurant);
  const { rtl } = useSelector((state) => state.settings);
  const { waiter } = useSelector((state) => state.auth);
  const [formattedPromotions, setFormattedPromotions] = useState([]);
  const [showAddPromotion, setShowAddPromotion] = useState(false);
  const [selectedPromotion, setSelectedPromotion] = useState(null);
  const [showAddNewPromotion, setShowAddNewPromotion] = useState(false);
  const [loading, setLoading] = useState(false);
  const [promotionStatus, setPromotionStatus] = useState("Active");
  const [promotionType, setPromotionType] = useState("ALL");

  useEffect(() => {
    if (promotions.length) {
      let newPromotions = getPromotionsByType(promotions, promotionType);
      const format = newPromotions.map((promotion) => {
        return {
          name: rtl ? promotion.name : promotion?.name_en,
          status:
            promotion.status === "Deleted"
              ? "deleted"
              : new Date() > new Date(moment(promotion.endDate))
              ? "expiredCoupon"
              : "Active",
          key: promotion._id,
          priority: promotion.priority,
          promotionType: intl.formatMessage({ id: promotion.promotionType }),
          amount: promotion.promotionType?.includes("PERCENTAGE")
            ? `${promotion.percentage} %`
            : promotion.fixedPrice,
          startDate: moment(promotion.startDate).format("DD/MM/YYYY"),
          endDate: moment(promotion.endDate).format("DD/MM/YYYY"),
          promotionInfo: { ...promotion },
        };
      });
      const filteredFormat = format?.filter(
        (promotion) => promotion?.status === promotionStatus
      );
      setFormattedPromotions(filteredFormat);
    } else {
      setFormattedPromotions([]);
    }
  }, [promotions, promotionStatus, promotionType, rtl]);

  const columns = [
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: 100,
    },
    {
      title: intl.formatMessage({ id: "status" }),
      dataIndex: "status",
      key: "status",
      fixed: "left",
      width: 100,
      render: (status, record) => {
        const today = new Date();
        let statusString = "active";
        let isActive = true;
        if (status === "canceled") {
          statusString = "canceled";
          isActive = false;
        } else if (status === "deleted") {
          statusString = "Deleted";
          isActive = false;
        } else if (today < new Date(record.promotionInfo.startDate)) {
          statusString = "notStarted";
          isActive = false;
        } else if (today > new Date(record.promotionInfo.endDate)) {
          statusString = "expired";
          isActive = false;
        }
        return (
          <Tag
            color={
              isActive
                ? "green"
                : statusString === "notStarted"
                ? "orange"
                : "red"
            }
          >
            {intl.formatMessage({ id: `${statusString}Coupon` })}
          </Tag>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "priority" }),
      dataIndex: "priority",
      key: "priority",
      sorter: (a, b) => a.priority - b.priority,
      showSorterTooltip: false,
      sortDirections: ["descend"],
    },
    {
      title: intl.formatMessage({ id: "promotionType" }),
      dataIndex: "promotionType",
      key: "promotionType",
    },
    {
      title: intl.formatMessage({ id: "amount" }),
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: intl.formatMessage({ id: "startDate" }),
      dataIndex: "startDate",
      key: "startDate",
      sorter: (a, b) =>
        new Date(a.promotionInfo?.startDate) -
        new Date(b.promotionInfo?.startDate),
      showSorterTooltip: false,
    },
    {
      title: intl.formatMessage({ id: "endDate" }),
      dataIndex: "endDate",
      key: "endDate",
      sorter: (a, b) =>
        new Date(a.promotionInfo?.endDate) - new Date(b.promotionInfo?.endDate),
      showSorterTooltip: false,
    },
    {
      title: intl.formatMessage({ id: "edit" }),
      dataIndex: "edit",
      render: (status, record) => {
        let isActive = true;
        if (record.status === "deleted") {
          isActive = false;
        }
        return (
          <>
            {isActive ? (
              <EditTwoTone
                onClick={() => {
                  setSelectedPromotion(record.promotionInfo);
                  setShowAddPromotion(true);
                }}
                className={"pointer"}
              />
            ) : (
              <EditOutlined
                style={{
                  color: "#dddddd",
                  fontSize: 16,
                }}
              />
            )}
          </>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "delete" }),
      dataIndex: "delete",
      render: (status, record) => {
        let isActive = true;
        if (record.status === "deleted") {
          isActive = false;
        }
        return (
          <Popconfirm
            title={
              <Text className='my-font'>
                {intl.formatMessage({
                  id: "confirmDeleteCouponTitle",
                })}
              </Text>
            }
            disabled={!isActive}
            okText={intl.formatMessage({ id: "delete" })}
            okButtonProps={{
              style: { color: COLORS.danger, fontFamily: "Cairo" },
            }}
            okType='text'
            cancelText={intl.formatMessage({ id: "cancel" })}
            cancelButtonProps={{
              style: { border: "none", fontFamily: "Cairo" },
            }}
            onConfirm={() => {
              deletePromotion(record?.promotionInfo);
            }}
          >
            <DeleteOutlined
              style={{
                color: isActive ? COLORS.danger : "#dddddd",
                fontSize: 15,
              }}
              className='mx-3'
              disabled={!isActive}
            />
          </Popconfirm>
        );
      },
    },
  ];

  const deletePromotion = async (promotion) => {
    try {
      let endDate = moment().subtract(1, "days").startOf("day").toISOString();
      setLoading(true);
      const data = {
        promotion: {
          endDate: endDate,
          status: "Deleted",
        },
        code: waiter.code,
      };

      const res = await editPromotion(data, promotion?._id);
      dispatch(editPromotionAction(res.data.promotion));

      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      {/* Modals */}

      <PromotionModal
        show={showAddPromotion}
        hide={() => {
          setShowAddPromotion(false);
          setSelectedPromotion(null);
        }}
        promotion={selectedPromotion}
      />
      <AddPromotionModal
        show={showAddNewPromotion}
        hide={() => {
          setShowAddNewPromotion(false);
        }}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <Title>{intl.formatMessage({ id: "promotions" })}</Title>
        <CustomButton
          text={intl.formatMessage({
            id: "addPromotion",
          })}
          type='primary'
          className='border-8'
          onClick={() => setShowAddNewPromotion(true)}
        />
      </div>
      <div
        style={{ maxWidth: "100%", marginRight: "auto", marginLeft: "auto" }}
      >
        <Text className='my-font'>
          {intl.formatMessage({ id: "promoDes" })}
        </Text>
      </div>
      <div
        style={{
          backgroundColor: COLORS.white,
          marginTop: 40,
          width: "92%",
          marginRight: "auto",
          marginLeft: "auto",
          borderRadius: 10,
          padding: "1rem",
        }}
        className='light-shadow'
      >
        <div
          style={{
            margin: "1rem",
          }}
        >
          <Text className='my-font'>
            {intl.formatMessage({ id: "coupouStatus" })}
          </Text>
          <Select
            style={{ width: "150px", margin: "0 1rem" }}
            placeholder={intl.formatMessage({ id: "coupouStatus" })}
            defaultValue={promotionStatus}
            className='my-font'
            onChange={(e) => setPromotionStatus(e)}
            options={[
              {
                value: "Active",
                label: `${intl.formatMessage({
                  id: "active",
                })} / ${intl.formatMessage({ id: "notStarted" })}`,
              },
              {
                value: "expiredCoupon",
                label: intl.formatMessage({ id: "expiredCoupon" }),
              },
              {
                value: "deleted",
                label: intl.formatMessage({ id: "deleted_Reservation" }),
              },
            ]}
          />
          <Text className='my-font'>
            {intl.formatMessage({ id: "promotionType" })}
          </Text>
          <Select
            style={{ width: "150px", margin: "0 1rem" }}
            placeholder={intl.formatMessage({ id: "couponType" })}
            className='my-font'
            defaultValue={promotionType}
            onChange={(e) => setPromotionType(e)}
            options={getPromotionTypesValues(PROMOTIONS_TYPES, intl)}
          />
        </div>
        <Table
          columns={columns}
          style={{ borderRadius: 10 }}
          dataSource={formattedPromotions}
          scroll={{ x: 1100 }}
          loading={loading}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default Promotions;
