import { Col, Image, Row, Typography, Layout } from "antd";
import React from "react";
import { IoLanguageOutline, IoLogOut } from "react-icons/io5";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signOutServer } from "../../../API/fetch";
import { logOutAction } from "../../../redux/reducers/authReducer";
import LOGO from "../../../assets/images/main-logo.png";
import { changeLanguageAction } from "../../../redux/reducers/settingsReducer";
const { Header } = Layout;
const { Text } = Typography;

function TopHeader() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { superUser } = useSelector((state) => state.auth);
  const { rtl } = useSelector((state) => state.settings);
  return (
    <Header
      style={{
        display: "flex",
        justifyContent: "space-between",
        minHeight: "10vh",
      }}
    >
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Col span={10} style={{ display: "flex", alignItems: "center" }}>
          <Image
            src={LOGO}
            preview={false}
            style={{
              marginLeft: rtl ? 0 : 10,
              marginRight: rtl ? 10 : 0,
              maxWidth: 140,
              objectFit: "contain",
            }}
          />
        </Col>
        <Col span={11} style={{ display: "flex", justifyContent: "flex-end" }}>
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            className='mx-3'
            onClick={() => {
              dispatch(changeLanguageAction());
            }}
          >
            <IoLanguageOutline size={20} />
            {window.innerWidth > 500 ? (
              <Text className='my-font mx-2'>
                {intl.formatMessage({ id: "oppLanguage" })}
              </Text>
            ) : null}
          </div>
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={async () => {
              try {
                await signOutServer();
                dispatch(logOutAction());
                navigate("/");
              } catch (error) {
                dispatch(logOutAction());
                navigate("/");
              }
            }}
          >
            <IoLogOut size={20} />
            {window.innerWidth > 500 ? (
              <Text className='my-font mx-2'>
                {intl.formatMessage({ id: "signout" })}
              </Text>
            ) : null}
          </div>
        </Col>
      </Row>
    </Header>
  );
}

export default TopHeader;
