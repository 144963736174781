import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Tag, Typography, Form, DatePicker, Row, Col, Checkbox, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import COLORS from "../../Style/colors";
import CustomButton from "../../Components/CustomButton";
import {
  DeleteFilled,
  FileExcelOutlined,
  LoadingOutlined,
  PlusOutlined,
  SearchOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { IoRemoveOutline } from 'react-icons/io5';
import moment from "moment";
import ReservationUserActions from "./Components/ReservationUserActions";
import {
  TAG_COLORS_BY_PAYMENT_STATUS,
} from "../../Types";
import { Table } from "ant-table-extensions";
import { useNavigate } from "react-router-dom";
import EditReservationModal from "./Components/EditReservationModal";
import { customerExport } from "../../helpers";
import Timer from "../../Components/Timer";
import { clearTableCustomer, postUpdateUserReservation } from "../../API/fetch";
import openNotification from "../../Components/Notifications";
import { editTableAction } from "../../redux/reducers/tableReducer";
import { setBranchAction } from "../../redux/reducers/branchReducer";
const { Text } = Typography;
const ActiveUserTable = ({
  users,
  status,
  area,
  // selectedArea,
  // setActiveTab,
  seletedReservationSchema,
  onSelectDate,
  loading,
  date,
}) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const dispatch = useDispatch()
  const [form] = Form.useForm();
  const { role } = useSelector((state) => state.auth);
  const { rtl } = useSelector((state) => state.settings);
  const { tables } = useSelector((state) => state.table);
  const { branch } = useSelector((state) => state.branch);
  const [formattedUsers, setFormattedUsers] = useState([]);
  // const [showEditWaitingArea, setShowEditWaitingArea] = useState(false);
  // const [showAddUserManual, setShowAddUserManual] = useState(false);
  // // const [showAddWaitingArea, setShowAddWaitingArea] = useState(false);
  const [canEdit, setCanEdit] = useState(true);
  const [showEditReservation, setShowEditReservation] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [loadingTable, setLoadingTable] = useState(false)

  useEffect(() => {
    if (users?.length) {
      const active = users.map((user, i) => {
        return {
          reservationNumber: user?._id
            ?.substring(user?._id.length - 5)
            ?.toUpperCase(),
          name: user?.customerId?.name,
          _id: user?._id,
          phone: rtl
            ? `${user?.customerId?.phone}+`
            : `+${user?.customerId?.phone}`,
          personsCount: user?.personsCount,
          childCount: user?.childCount,
          paymentMethod: user?.paymentStatus,
          reason: rtl ? user?.reason?.name : user?.reason?.name_en,
          orders: user?.orders,
          total: user?.total,
          modifiers: user?.modifiers,
          note: user?.note,
          numberOfHours: user?.numberOfHours,
          isWithhours: user?.isWithHours,
          isDone: user?.isDone,
          key: user?._id,
          tableId: user?.tableId ? user?.tableId : null,
          totalHoursAddsOn: user?.totalHoursAddsOn,
          hoursAddsOn: user?.hourAddsOn,
          canSiteFor: user?.tableId && user?.tableId?.customerCanSetForMinutes ? user?.tableId?.customerCanSetForMinutes : null,
          startSitTime: user?.tableId && user?.tableId?.startSeTime ? user?.tableId?.startSeTime : null,
          table: user?.tableId ? rtl ? user?.tableId?.name : user?.tableId.name_en : null,
          reservationDate: user?.reservationDate,
          time: moment(user?.reservationDate).format("YYYY-MM-DD hh:mm A"),
          userObject: user,
        };
      });
      setFormattedUsers(active);
    } else {
      setFormattedUsers([]);
    }
  }, [users]);

  let columns = [
    {
      title: intl.formatMessage({ id: "reservationNumber" }),
      dataIndex: "reservationNumber",
      key: "reservationNumber",
      render: (text, record) => {
        // make text clickable;
        return (
          <Text
            style={{ cursor: "pointer", textDecoration: "underline" }}
            onClick={() => {
              setSelectedReservation(record.userObject);
              setShowEditReservation(true);
            }}
          >
            {text}
          </Text>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        // make text clickable;
        return (
          <Text
            style={{ cursor: "pointer", textDecoration: "underline" }}
            onClick={() => {
              setSelectedReservation(record.userObject);
              setShowEditReservation(true);
            }}
          >
            {text}
          </Text>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "phone" }),
      dataIndex: "phone",
      key: "phone",
      render: (phone) => {
        return <a href={`tel:00${phone}`}>{phone}</a>;
      },
    },

    {
      title: intl.formatMessage({ id: "persons" }),
      dataIndex: "personsCount",
      key: "personsCount",
      render: (personsCount, record) => {
        return (
          <div>
            {personsCount}{" "}
            {record.childCount > 0 && (
              <span>
                {intl.formatMessage({ id: "person" })} {" / "}{" "}
                {record.childCount} {intl.formatMessage({ id: "child" })}
              </span>
            )}
          </div>
        );
      },
    },

    {
      title: intl.formatMessage({ id: "totalReservation" }),
      dataIndex: "total",
      key: "total",
    },
    {
      title: intl.formatMessage({ id: "modifiers" }),
      dataIndex: "modifiers",
      key: "modifiers",
      render: (text, record) => {
        return (<>
          {record?.modifiers && record.modifiers.length > 0 ?
            record?.modifiers.map(mod =>
              <Tag key={mod?._id}>
                {rtl ? mod?.name : mod?.name_en} - {mod.qty}
              </Tag>
            )
            :
            <Space
              direction='horizontal'
              style={{ alignItems: "center" }}
            >
              <div                                   >
                <IoRemoveOutline
                  size={15}
                />
              </div>
            </Space>
          }
        </>)
      }
    },
    {
      title: intl.formatMessage({ id: "paymentMethod" }),
      dataIndex: "paymentMethod",
      key: "paymentMethod",
      render: (paymentStatus) => {
        return (
          <Tag
            color={TAG_COLORS_BY_PAYMENT_STATUS[paymentStatus]}
            key={paymentStatus}
          >
            {intl.formatMessage({ id: paymentStatus })}
          </Tag>
        );
      },
    },

    {
      title: intl.formatMessage({ id: "date" }),
      dataIndex: "time",
      key: "time",
      // sort by date;
      sorter: (a, b) => {
        return new Date(a.reservationDate) - new Date(b.reservationDate);
      },
    },

    {
      title: intl.formatMessage({ id: "table" }),
      dataIndex: "table",
      key: "table",
      render: (text) => {
        return <>{text ? <Text className="my-font">
          {text}
        </Text> : <Space
          direction='horizontal'
          style={{ alignItems: "center" }}
        >
          <div>
            <IoRemoveOutline
              size={15}
            />
          </div>
        </Space>}
        </>;
      },
    },
    {
      title: intl.formatMessage({ id: "hoursCount" }),
      dataIndex: "numberOfHours",
      key: "numberOfHours",
      render: (text, record) => {
        return <>{record?.isWithhours ? <Text className="my-font">
          {text}
        </Text> : <Space
          direction='horizontal'
          style={{ alignItems: "center" }}
        >
          <div                                   >
            <IoRemoveOutline
              size={15}
            />
          </div>
        </Space>}</>;
      },
    },
    {
      title: intl.formatMessage({ id: "hoursAddsOn" }),
      dataIndex: "hoursAddsOn",
      key: "hoursAddsOn",
      render: (text, record) => {
        return <>{record?.isWithhours && text * record?.totalHoursAddsOn > 0 ? <Text className="my-font">
          {text} (+{record?.totalHoursAddsOn})
        </Text> : <Space
          direction='horizontal'
          style={{ alignItems: "center" }}
        >
          <div                                   >
            <IoRemoveOutline
              size={15}
            />
          </div>
        </Space>}</>;
      },
    },
    {
      title: intl.formatMessage({ id: "sittingPeriod" }),
      dataIndex: "canSiteFor",
      key: "canSiteFor",
      render: (text, record) => {
        let startTime = moment(record?.startSitTime);
        let endTime = moment(startTime).add(text, "minutes");
        let now = moment();
        let diff = endTime.diff(now, "minutes");
        // get the remaining time;
        let isTimeAboutToEnd = diff <= 10 && diff > 0;
        let isTimeEnded = diff <= 0;
        let isTimeNotStarted = startTime.isAfter(now);
        return <>
          {record?.isDone ?
            <Tag color="red">
              {intl.formatMessage({ id: "reservationIsDone" })}
            </Tag> :
            text && record?.startSitTime ?
              <div style={{ display: "flex", flexWrap: "nowrap" }}>
                <Tag color={isTimeAboutToEnd ? "orange" : isTimeEnded ? "red" : "green"}>
                  {isTimeNotStarted ? intl.formatMessage({ id: "notStarted" }) :
                    <Timer callQueuedTime={startTime} />
                  }
                </Tag>

                <Tag
                  color={loadingTable ? COLORS.gray : COLORS.primary}
                  className='px-3 my-font pointer'
                  style={{ borderRadius: 4, }}
                  onClick={() => clearCustomerId(record?.tableId, record?._id)}
                  icon={loadingTable ? <LoadingOutlined /> : <DeleteFilled />}
                >
                  {intl.formatMessage({ id: "remove" })}
                </Tag>
              </div> : <Tag color="green">
                {intl.formatMessage({ id: "notStarted" })}
              </Tag>
          }
        </>
      },
    },

    {
      title: intl.formatMessage({ id: "choices" }),
      dataIndex: "action",
      key: "action",
      render: (__, record) => {
        let mustChooseTable = false;
        if (area && area?.makeChooseTableRequired && !record?.userObject?.tableId?._id) {
          mustChooseTable = true;
        }
        return (
          <ReservationUserActions
            record={record}
            type='Active'
            date={date}
            canEdit={canEdit}
            mustChooseTable={mustChooseTable}
          />
        );
      },
    },
  ];

  if (area?.canPreOrder) {
    // push a new column at index 3;
    columns.splice(3, 0, {
      title: intl.formatMessage({ id: "order" }),
      dataIndex: "orders",
      key: "orders",
      render: (orders) => {
        return (
          <Tag
            className={orders?.length ? "my-font pointer" : "my-font"}
            onClick={() => {
              if (orders?.length) {
                // get the order that have timeForPickup is equal to the reservation date;
                const order = orders.find((order) => {
                  return moment(order?.timeForPickup).isSame(
                    moment(selectedReservation?.reservationDate),
                    "day"
                  );
                });
                if (order?._id) {
                  navigate(`/scheduleOrder/${order?._id}`);
                }
              }
            }}
            color={orders?.length ? "green" : "red"}>
            {orders?.length ? intl.formatMessage({ id: 'preOrder' }) : "N/A"}
          </Tag>
        );
      },
    });
  }

  const clearCustomerId = async (table, reservationId) => {
    try {
      setLoadingTable(true);
      const dateFormat = moment(date);
      const data = {
        reservation: {
          isDone: true,
          date: dateFormat,
        }
      }
      const res = await clearTableCustomer(table._id);
      //update the reservation 
      const result = await postUpdateUserReservation(reservationId, data)
      const newBranch = {
        ...branch,
        reservationList: result.data.reservationList,
      };
      if (res?.data?.table) {
        const oldTable = tables.find(
          (table) => table._id === res?.data?.table?._id
        );
        if (oldTable) {
          const newTable = {
            ...oldTable,
            reservationId: null,
            customerId: null,
            customerCanSetForMinutes: 0,
          };
          dispatch(editTableAction(newTable));
        }
      }
      dispatch(setBranchAction(newBranch));
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setLoadingTable(false);
    } catch (error) {
      setLoadingTable(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        type: "error",
        rtl,
      });
      console.log(error);
    }
  };

  return (
    <div
      style={{
        marginTop: "-17px",
        paddingTop: 15,
        paddingBottom: 30,
        backgroundColor: COLORS.white,
      }}
      className='light-shadow border-8'
    >
      {/* Edit waiting area modal */}
      {/* <AddUserReservationManual
        show={showAddUserManual}
        hide={() => setShowAddUserManual(false)}
        area={area?._id === selectedArea ? area : null}
      /> */}
      {/* Link Reservation With Table modal */}
      <EditReservationModal
        show={showEditReservation}
        hide={() => {
          setShowEditReservation(false);
          setSelectedReservation(null);
        }}
        reservation={selectedReservation}
        allUsers={users}
        seletedReservationSchema={seletedReservationSchema}
        onSelectDate={onSelectDate}
        date={date}
        allDays={form.getFieldValue("allDays")}
        isReader={false}
        handleUpdateLocal={(data) => {
          console.log(data);
        }}
      />
      <div
        style={{
          paddingRight: 30,
          paddingLeft: 30,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {role === "super" ? (
          <CustomButton
            text={intl.formatMessage({ id: "addNewReservation" })}
            icon={<PlusOutlined />}
            type='primary'
            className='px-3 border-5 mb-3'
            disabled={status === "disabled"}
            onClick={() => {
              navigate(`/edit-reservation/newArea?canDelete=false`);
            }}
          />
        ) : (
          <CustomButton
            text={intl.formatMessage({ id: "addReservation" })}
            type='primary'
            className='px-3 border-5 mb-3'
            disabled={status === "disabled"}
            onClick={() => navigate("/reservation/add")}
          />
        )}
        <Form
          name='date-reservation-form'
          form={form}
          className='form-container'
          layout='inline'
          onFinish={onSelectDate}
          initialValues={{
            date: date,
            allDays: false,
          }}
        >
          {() => {
            return (
              <div>
                <Row>
                  <Col>
                    <Form.Item
                      name='date'
                      label={intl.formatMessage({ id: "date" })}
                      rules={[
                        {
                          required: true,
                          message: "Date is required",
                        },
                      ]}
                    >
                      <DatePicker disabled={form.getFieldValue("allDays")} />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      className='mb-1'
                      name='allDays'
                      valuePropName='checked'
                      style={{ borderRadius: 20 }}
                      onChange={(e) => {
                        setCanEdit(!e.target.checked);
                      }}
                    >
                      <Checkbox>
                        {intl.formatMessage({ id: "allDays" })}
                      </Checkbox>
                    </Form.Item>
                  </Col>

                  <Form.Item>
                    <CustomButton
                      htmlType='submit'
                      className={`btnRegister btn-text border-8`}
                      loading={loading}
                      type='primary'
                      text={intl.formatMessage({ id: "search" })}
                    />
                  </Form.Item>
                </Row>
              </div>
            );
          }}
        </Form>
        {role === "super" ?
          <CustomButton
            text={
              intl.formatMessage({
                id: "editInformation",
              }) +
              " " +
              `${rtl ? area?.name : area?.name_en}`
            }
            type={"light"}
            icon={<SettingOutlined />}
            className='px-3 border-5 mb-3'
            // onClick={() => setShowEditWaitingArea(true)}
            onClick={() => {
              if ((!users || users?.length === 0) && role === "super") {
                navigate(`/edit-reservation/${area?._id}?canDelete=true`);
              } else {
                navigate(`/edit-reservation/${area?._id}?canDelete=false`);
              }
            }}
          /> : null}
      </div>

      <Table
        exportable={role === "super" ? true : false}
        searchable
        searchableProps={{
          fuzzySearch: true,
          debounce: true,
          inputProps: {
            placeholder: intl.formatMessage({ id: "searchPlaceholder" }),
            prefix: <SearchOutlined />,
            disabled: loading || formattedUsers?.length === 0,
            style: { maxWidth: 400, margin: 4 },
          },
        }}
        exportableProps={role == "super" ? {
          showColumnPicker: true,
          disabled: loading || formattedUsers?.length === 0,
          btnProps: {
            icon: <FileExcelOutlined />,
            type: "primary",
            style: { borderRadius: 7 },
            onClick: () =>
              customerExport({
                data: formattedUsers,
                columns: columns,
                fileName: "Users Report",
                loadingSetter: () => true,
              }),
          },
          children: (
            <Text
              className='my-font'
              style={{
                color: formattedUsers?.length === 0 ? "#dddddd" : COLORS.white,
              }}
            >
              {intl.formatMessage({ id: "export" })}
            </Text>
          ),
        } : null}
        className='light-shadow'
        loading={loading}
        columns={columns}
        pagination={{
          hideOnSinglePage: true,
          pageSize: 20,
        }}
        // pagination={{ hideOnSinglePage: true }}
        dataSource={formattedUsers}
        scroll={{ x: 300 }}
      />
    </div>
  );
};

export default ActiveUserTable;
