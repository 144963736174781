import { createSlice } from "@reduxjs/toolkit";
// import { SUBSCRIPTION_TYPES } from "../../../Types";
// import store from '../../store'
const initialState = {
  superUser : null,
  isLoggedIn: false,
  token: "",
  user: null,
  waiter: null,
  userHasLevel: false,
  role: "",
  subscriptionEnd: false,
  // subscriptionType: SUBSCRIPTION_TYPES.MENU,
  services: [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setWaiter: (state, action) => {
      state.waiter = action.payload.waiter;
      state.userHasLevel = true;
      state.role = action.payload.waiter.role;
    },
    login: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
      state.token = action.payload.user.token;
      // const subscriptionsArr = Object.keys(SUBSCRIPTION_TYPES);
      // if(action.payload?.user?.activeSubscription?.subscriptionType){
      //    state.subscriptionType = subscriptionsArr[action.payload.user.activeSubscription.subscriptionType];
      //   }else {
      //    state.subscriptionType = subscriptionsArr[3];
      //   }
    },
    signup: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
      state.token = action.payload.user.token;
      state.waiter = action.payload.waiter;
      state.role = action.payload.waiter.role;
      state.services = action.payload.services;
      state.userHasLevel = true;
      // const subscriptionsArr = Object.keys(SUBSCRIPTION_TYPES);
      // state.subscriptionType = subscriptionsArr[action.payload.user.activeSubscription.subscriptionType];
    },
    setSuperUser :(state , action) => {
      state.isLoggedIn = true; 
      state.superUser = action.payload.user; 
      state.userHasLevel = true;
    },
    setChangeRestaurant : (state, action)=> {
      state.user = null;
      state.waiter = null;
      state.token = "";
      state.role = "";
    },
    logOut: async (state) => {
      state.isLoggedIn = false;
      state.user = null;
      state.waiter = null;
      state.token = "";
      state.userHasLevel = false;
      state.role = "";
      state.subscriptionEnd = false;
      state.superUser= null ;
      window.location.reload();
    },
    changeUser: (state, action) => {
      state.waiter = null;
      state.userHasLevel = false;
    },
    setSubscriptionEnd: (state, action) => {
      state.subscriptionEnd = action.payload;
    },
    setServices: (state, action) => {
      state.services = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    }
  },
});

export const {
  setWaiter: setWaiterAction,
  logOut: logOutAction,
  login: loginAction,
  signup: signupAction,
  changeUser: changeUserAction,
  setSubscriptionEnd: setSubscriptionEndAction,
  setServices: setServicesAction,
  setUser: setUserAction, 
  setSuperUser : setSuperUserAction
} = authSlice.actions;

export default authSlice.reducer;
