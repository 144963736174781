import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Divider,
  Space,
  Tag,
  Row,
  Col,
  Checkbox,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { TABLE_SHAPES } from "../../../Types";
import CustomButton from "../../../Components/CustomButton";
import {
  addMultiTables,
  postDeleteMultiTables,
  editMultiTables,
} from "../../../API/fetch";
import openNotification from "../../../Components/Notifications";
import {
  addNewTablesAction,
  addNewZoneAction,
  deleteTablesAction,
  editMultiTablesAction,
  filterZonesAction,
} from "../../../redux/reducers/tableReducer";

const { Option } = Select;
const { Text, Title } = Typography;
const EditTablesModal = ({ show, hide }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { zones, tables } = useSelector((state) => state.table);
  const { branch } = useSelector((state) => state.branch);
  const { services } = useSelector((state) => state.auth);
  const { rtl } = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(false);
  const [showAddZone, setShowAddZone] = useState(false);
  const [newZoneName, setNewZoneName] = useState("");
  const [canNotAddNew, setCanNotAddNew] = useState(false);
  const [form] = Form.useForm();


  // useEffect(() => {
  //   let tablesCount = tables?.length;
  // //  if(services?.includes('ORDER_A') && !services?.includes('ORDER_B')){
  // //    if(tablesCount >= 20){
  // //     setCanNotAddNew(true)
  // //    }
  // //  }
  //  return () => {
  //     setCanNotAddNew(false)
  //   }
  // },[isNew, tables, form , form.getFieldValue('addMulti')])
  console.log()

  const handelEdit = async (values) => {
    try {
      if (values?.addMulti) {
        // Add multiple tables;
        let tablesData = [];
        for (
          let i = values?.startNumber;
          i < values?.startNumber + values?.countOfTables;
          i++
        ) {
          tablesData.push({
            name: `${values?.nameOfTables_ar} ${i}`,
            name_en: `${values?.nameOfTables_en} ${i}`,
            owner: branch._id,
            zone: values?.zone,
            shape: values?.shape,
            numberOfChairs: values?.numberOfChairs,
          });
        }
        const data = {
          tables: tablesData,
        };
        const res = await addMultiTables(data);
        dispatch(addNewTablesAction(res.data.tables));
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
        setLoading(false);
        hide();
      } else if (values?.deleteMulti) {
        // delete multiple tables
        setLoading(true);
        if (values?.tables?.length) {
          const data = {
            tables: values?.tables,
            branchId: branch._id
          };
          const res = await postDeleteMultiTables(data);
          if (res.data.status === "success") {
            dispatch(deleteTablesAction(values?.tables));
          }
          openNotification({
            title: intl.formatMessage({ id: "savedSuccessfully" }),
            type: "success",
            rtl,
          });
          setLoading(false);
          hide();
        }
      } else if (values.editMulti) {
        // edit multiple tables
        setLoading(true);
        if (values?.tables?.length) {
          const data = {
            tables: values?.tables,
            info: {
              tableFees: values.tableFees,
              tableMinimumOrder: values?.tableMinimumOrder,
            }
          };
          const res = await editMultiTables(data);
          if (res.data.status === "success") {
            dispatch(
              editMultiTablesAction({
                tables: values.tables,
                data: {
                  tableFees: values.tableFees,
                  tableMinimumOrder: values?.tableMinimumOrder
                },
                type: "EDIT_FEES",
              })
            );
          }
          openNotification({
            title: intl.formatMessage({ id: "savedSuccessfully" }),
            type: "success",
            rtl,
          });
          setLoading(false);
          hide();
        }
      } else if (values.moveMulti) {
        setLoading(true);
        if (values?.tablesToMove?.length) {
          const data = {
            tables: values?.tablesToMove,
            info: {
              zone: values.zoneToMove,
            },
            branchId: branch._id
          };
          const res = await editMultiTables(data);
          if (res.data.status === "success") {
            dispatch(
              editMultiTablesAction({
                tables: values.tablesToMove,
                data: {
                  zone: values.zoneToMove,
                },
                type: "MOVE_MULTI_TABLES",
              })
            );
          }
          openNotification({
            title: intl.formatMessage({ id: "savedSuccessfully" }),
            type: "success",
            rtl,
          });
          setLoading(false);
          hide();
        }
      }
    } catch (error) {
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  return (
    <Modal
      title={
        <Title level={4} className='my-font'>
          {intl.formatMessage({ id: "editTables" })}
        </Title>
      }
      centered
      visible={show}
      destroyOnClose={true}
      mask={true}
      maskClosable={true}
      onCancel={() => hide()}
      footer={null}
    >
      {/* Zone Modal */}
      <Modal
        title={
          <Title level={5} className='my-font'>
            {intl.formatMessage({ id: "addZone" })}
          </Title>
        }
        centered
        visible={showAddZone}
        destroyOnClose={true}
        mask={true}
        maskClosable={true}
        onCancel={() => setShowAddZone(false)}
        onOk={() => {
          dispatch(addNewZoneAction(newZoneName));
          setShowAddZone(false);
          form.setFieldsValue({ zone: newZoneName });
        }}
        okText={intl.formatMessage({ id: "save" })}
        cancelText={intl.formatMessage({ id: "cancel" })}
        cancelButtonProps={{
          type: "text",
          style: { border: "none", fontFamily: "Cairo" },
        }}
        okButtonProps={{
          style: { borderRadius: 5, padding: "3px 30px", fontFamily: "Cairo" },
        }}
      >
        <Input
          value={newZoneName}
          onChange={(e) => setNewZoneName(e.target.value)}
        />
      </Modal>
      <Form
        name='editTables'
        form={form}
        preserve={false}
        className='form-container'
        layout='vertical'
        onFinish={handelEdit}
        initialValues={{
          countOfTables: 1,
          startNumber: 2,
          nameOfTables_ar: "طاولة",
          nameOfTables_en: "Table",
          numberOfChairs: 4,
          addMulti: true,
          deleteMulti: false,
          editMulti: false,
          moveMulti: false,
          shape: TABLE_SHAPES.SQUARE,
          tables: [],
          tablesToMove: [],
          tableFees: 0,
          tableMinimumOrder: 0,
        }}
      >
        {() => (
          <>
            <Row type='flex' justify='space-between' align='middle'>
              <Col xs={12}>
                <Form.Item
                  className='mb-1'
                  name='addMulti'
                  valuePropName='checked'
                  style={{ borderRadius: 20 }}
                  onChange={(e) => {
                    if (e.target.checked) {
                      form.setFieldsValue({
                        deleteMulti: false,
                        editMulti: false,
                        moveMulti: false
                      });
                    } else {
                      form.setFieldsValue({
                        deleteMulti: true,
                      });
                    }
                  }}
                >
                  <Checkbox disabled={!tables?.length}>
                    {intl.formatMessage({ id: "addTables" })}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item
                  className='mb-1'
                  name='editMulti'
                  valuePropName='checked'
                  style={{ borderRadius: 20 }}
                  onChange={(e) => {
                    if (e.target.checked) {
                      form.setFieldsValue({
                        addMulti: false,
                        deleteMulti: false,
                        moveMulti: false
                      });
                    } else {
                      form.setFieldsValue({
                        addMulti: true,
                      });
                    }
                  }}
                >
                  <Checkbox disabled={!tables?.length}>
                    {intl.formatMessage({ id: "AddFeesMultiTables" })}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col xs={12} >
                <Form.Item
                  className='mb-1'
                  name='moveMulti'
                  valuePropName='checked'
                  style={{ borderRadius: 20 }}
                  onChange={(e) => {
                    if (e.target.checked) {
                      form.setFieldsValue({
                        addMulti: false,
                        deleteMulti: false,
                        editMulti: false
                      });
                    } else {
                      form.setFieldsValue({
                        moveMulti: true,
                      });
                    }
                  }}
                >
                  <Checkbox disabled={!tables?.length}>
                    {intl.formatMessage({ id: "moveMultiTables" })}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  className='mb-1'
                  name='deleteMulti'
                  valuePropName='checked'
                  style={{ borderRadius: 20 }}
                  onChange={(e) => {
                    if (e.target.checked) {
                      form.setFieldsValue({
                        addMulti: false,
                        editMulti: false,
                        moveMulti: false
                      });
                    } else {
                      form.setFieldsValue({
                        addMulti: true,
                      });
                    }
                  }}
                >
                  <Checkbox disabled={!tables?.length}>
                    <Text type='danger'>
                      {" "}
                      {intl.formatMessage({ id: "removeMultiTables" })}
                    </Text>
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Divider className='my-font'>
              {intl.formatMessage({ id: "data" })}
            </Divider>





            {form?.getFieldValue("addMulti") === true ? (
              <>
                <Row type='flex' justify='space-between' align='middle'>
                  <Col span={10}>
                    <Form.Item
                      label={intl.formatMessage({ id: "countOfTables" })}
                      className='mb-1'
                      name='countOfTables'
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        className={"input"}
                        min={1}
                        max={
                          services?.includes('ORDER_A') && !services?.includes('ORDER_B') ? 20 - tables?.length : 100
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      label={intl.formatMessage({ id: "startNumberTable" })}
                      className='mb-1'
                      name='startNumber'
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        className={"input"}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row type='flex' justify='space-between' align='center'>
                  <Col span={10}>
                    <Form.Item
                      label={intl.formatMessage({ id: "justName" })}
                      className='mb-1'
                      name='nameOfTables_ar'
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input className={"input"} />
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      label={intl.formatMessage({ id: "nameEn" })}
                      className='mb-1'
                      name='nameOfTables_en'
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input className={"input"} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  {form?.getFieldValue("countOfTables") ? Array?.from(
                    Array(parseInt(form?.getFieldValue("countOfTables")))?.keys()
                  )?.map((_, i) => {
                    return (
                      <Text key={`${i}`} disabled={true} className='my-font'>
                        {" "}
                        - {form?.getFieldValue("nameOfTables_ar")}{" "}
                        {parseInt(form?.getFieldValue("startNumber")) + i}
                      </Text>
                    );
                  }) : null}
                </Row>
                <Row type='flex' justify='space-between' align='middle'>
                  <Col span={10}>
                <Form.Item
                  name='shape'
                  label={intl.formatMessage({ id: "tableShape" })}
                >
                  <Select className='my-font'>
                    {Object.values(TABLE_SHAPES).map((shape) => {
                      return (
                        <Option
                          className='my-font'
                          key={`${shape}`}
                          value={shape}
                        >
                          {intl.formatMessage({ id: `${shape}` })}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                </Col>
                <Col span={10}>
                <Form.Item
                      label={intl.formatMessage({ id: "numberOfChairs" })}
                      className='mb-1'
                      name='numberOfChairs'
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        className={"input"}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  name='zone'
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  label={
                    <Space direction='horizontal'>
                      <Text className='my-font'>
                        {intl.formatMessage({ id: "zone" })}
                      </Text>
                      <Tag
                        onClick={() => setShowAddZone(true)}
                        color='green'
                        className='pointer'
                      >{`${intl.formatMessage({
                        id: "addZone",
                      })} +`}</Tag>
                    </Space>
                  }
                >
                  <Select className='my-font'>
                    {zones.map((zone) => {
                      return (
                        <Option
                          className='my-font'
                          key={`${zone.name}`}
                          value={zone.name}
                        >
                          {zone.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </>
            ) : form?.getFieldValue("editMulti") ? (
              <>
                <Form.Item
                  label={intl.formatMessage({ id: "selectTablesToAddFees" })}
                  className='mb-1'
                  name='tables'
                >
                  <Select
                    size='large'
                    className='my-font'
                    allowClear
                    mode='multiple'
                  >
                    {tables?.map((table) => {
                      return (
                        <Option
                          key={table?._id}
                          value={table?._id}
                          className='my-font'
                        >
                          {rtl ? `${table?.name} - ${table?.zone}` : `${table?.name_en} - ${table?.zone}`}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Row type='flex' justify='space-between' align='bottom'>
                  <Col md={11} sm={24}>
                    <Form.Item
                      label={intl.formatMessage({
                        id:  "tableFees" 
                      })}
                      className='mb-1'
                      name='tableFees'
                      tooltip={
                        <Text className='my-font'>
                          {intl.formatMessage({ id: "tableFeesDes" })}
                        </Text>
                      }
                    >
                      <InputNumber
                        style={{
                          width: "100%",
                        }}
                        className={"input"}
                        min={0}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={11} sm={24}>
                      <Form.Item
                      label={intl.formatMessage({
                        id:  "minimumOrder" 
                      })}
                      className='mb-1'
                      name='tableMinimumOrder'
                      
                    >
                      <InputNumber
                        style={{
                          width: "100%",
                        }}
                        className={"input"}
                        min={0}
                      />
                    </Form.Item>
                     
                  </Col>
                </Row>
              </>
            ) : form?.getFieldValue("moveMulti") ? (
              <>
                <Form.Item
                  label={intl.formatMessage({ id: "selectTablesToMove" })}
                  className='mb-1'
                  name='tablesToMove'
                >
                  <Select
                    size='large'
                    className='my-font'
                    allowClear
                    mode='multiple'
                  >
                    {tables?.map((table) => {
                      return (
                        <Option
                          key={table?._id}
                          value={table?._id}
                          className='my-font'
                        >
                          {rtl ? `${table?.name} - ${table?.zone}` : `${table?.name_en} - ${table?.zone}`}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  name='zoneToMove'
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  label={
                    <Space direction='horizontal'>
                      <Text className='my-font'>
                        {intl.formatMessage({ id: "zone" })}
                      </Text>
                      <Tag
                        onClick={() => setShowAddZone(true)}
                        color='green'
                        className='pointer'
                      >{`${intl.formatMessage({
                        id: "addZone",
                      })} +`}</Tag>
                    </Space>
                  }
                  className="mt-5"
                >
                  <Select className='my-font'>
                    {zones.map((zone) => {
                      return (
                        <Option
                          className='my-font'
                          key={`${zone.name}`}
                          value={zone.name}
                        >
                          {zone.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>

              </>
            ) :
              (
                <>
                  <Form.Item
                    label={intl.formatMessage({ id: "selectTablesToDelete" })}
                    className='mb-1'
                    name='tables'
                  >
                    <Select
                      size='large'
                      className='my-font'
                      allowClear
                      mode='multiple'
                    >
                      {tables?.map((table) => {
                        return (
                          <Option
                            key={table?._id}
                            value={table?._id}
                            className='my-font'
                          >
                            {rtl ? `${table?.name} - ${table?.zone}` : `${table?.name_en} - ${table?.zone}`}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Text type='danger' className='my-font'>
                    {intl.formatMessage({ id: "removeMultiTablesWarning" })}
                  </Text>
                </>
              )}





            <Divider />
            <Space direction='horizontal'>
              <Form.Item>
                <CustomButton
                  htmlType='submit'
                  text={intl.formatMessage({
                    id:
                      form?.getFieldValue("deleteMulti") === true
                        ? "delete"
                        : "save",
                  })}
                  className={`btnRegister btn-text border-8`}
                  loading={loading}
                  disabled={
                    loading ||
                    ((form?.getFieldValue("deleteMulti") === true ||
                      // form?.getFieldValue("moveMulti") === true ||
                      form?.getFieldValue("editMulti") === true) &&
                      form?.getFieldValue("tables")?.length === 0)
                  }
                  type={
                    form?.getFieldValue("deleteMulti") === true
                      ? "danger"
                      : "primary"
                  }
                />
              </Form.Item>

              <Form.Item>
                <CustomButton
                  type='text'
                  text={intl.formatMessage({ id: "cancel" })}
                  disabled={loading}
                  onClick={() => {
                    dispatch(filterZonesAction());
                    hide();
                  }}
                />
              </Form.Item>
            </Space>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default EditTablesModal;
