import React from "react";
import { Col, Form, Row, Select, Switch, Typography } from "antd";
import { DefaultEditor } from "react-simple-wysiwyg";

const { Text } = Typography;
const { Option } = Select;
const OrderTerms = ({ intl, form, setShowTerms, showTerms }) => {
  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }} className='mb-3'>
        <Text className='text-2xl font-bold'>
          {intl.formatMessage({ id: "isOrderWithTerms" })}
        </Text>
        <Form.Item
          className='mb-1 mx-3'
          name='isOrderWithTerms'
          style={{ borderRadius: 20 }}
          valuePropName='checked'
        >
          <Switch
            onChange={(e) => setShowTerms(e)}
            checkedChildren={
              <p>
                {intl.formatMessage({
                  id: "active",
                })}
              </p>
            }
            unCheckedChildren={
              <p>
                {intl.formatMessage({
                  id: "disabled",
                })}
              </p>
            }
          />
        </Form.Item>
      </div>
      {showTerms && (
        <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
          <Col md={12} sm={24} xs={24} className='mb-3'>
            <Form.Item
              className='mb-1'
              label={intl.formatMessage({ id: "applyOrderTerms" })}
              name='applyOrderTerms'
              style={{ borderRadius: 20 }}
              rules={[
                {
                  required: showTerms,
                  message: intl.formatMessage({
                    id: "applyOrder_error",
                  }),
                },
              ]}
            >
              <Select
                className='my-font'
                mode='multiple'
                onChange={(e) => {
                  console.log(e);
                  if (
                    e.includes("PICKUP") &&
                    e.includes("DINE_IN") &&
                    e.includes("DILIVERY")
                  ) {
                    form.setFieldsValue({
                      applyOrderTerms: ["All"],
                    });
                  } else if (e?.includes("All") && e[0] !== "All") {
                    form.setFieldsValue({
                      applyOrderTerms: ["All"],
                    });
                  } else if (e.length > 1) {
                    form.setFieldsValue({
                      applyOrderTerms: e?.filter((el) => el !== "All"),
                    });
                  }
                }}
              >
                <Option value='All'>{intl.formatMessage({ id: "all" })}</Option>
                <Option value='PICKUP'>
                  {intl.formatMessage({ id: "pickup" })}
                </Option>
                <Option value='DILIVERY'>
                  {intl.formatMessage({ id: "delivery" })}
                </Option>
                <Option value='DINE_IN'>
                  {intl.formatMessage({ id: "dineIn" })}
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col sm={24} xs={24}>
            <Form.Item
              label={intl.formatMessage({ id: "terms" })}
              className='mb-1'
              name='orderTerms'
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: "termsError" }),
                },
              ]}
            >
              <DefaultEditor />
            </Form.Item>
          </Col>
          <Col sm={24} xs={24}>
            <Form.Item
              label={intl.formatMessage({ id: "terms_en" })}
              className='mb-1'
              name='orderTerms_en'
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: "termsError_en" }),
                },
              ]}
            >
              <DefaultEditor />
            </Form.Item>
          </Col>
        </Row>
      )}
      {/* End Add Terms and conditions for order */}
    </>
  );
};

export default OrderTerms;
