import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Popconfirm,
  Typography,
  Divider,
  Space,
  Tag,
  Row,
  Col,
  Checkbox,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { TABLE_SHAPES } from "../../../../../Types";
import COLORS from "../../../../../Style/colors";
import CustomButton from "../../../../../Components/CustomButton";
import {
  postAddTable,
  postDeleteTable,
  postUpdateTable,
} from "../../../../../API/fetch";
import openNotification from "../../../../../Components/Notifications";
import {
  addNewTableAction,
  addNewZoneAction,
  deleteTableAction,
  editTableAction,
  filterZonesAction,
} from "../../../../../redux/reducers/tableReducer";
import { useNavigate } from "react-router";
const { Option } = Select;
const { Text, Title } = Typography;
const EditTableModal = ({ show, hide, table }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useNavigate();
  const { zones, tables } = useSelector((state) => state.table);
  const { branch } = useSelector((state) => state.branch);
  const { waiter, role, services } = useSelector((state) => state.auth);
  const { rtl } = useSelector((state) => state.settings);
  const [isNew, setIsNew] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showAddZone, setShowAddZone] = useState(false);
  const [newZoneName, setNewZoneName] = useState("");
  const [canNotAddNew, setCanNotAddNew] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (table?._id) {
      setIsNew(false);
    }
  });
  useEffect(() => {
    let tablesCount = tables?.length;
   if(services?.includes('ORDER_A') && !services?.includes('ORDER_B')){
     if(tablesCount >= 20){
      setCanNotAddNew(true)
     }
   }
   return () => {
      setCanNotAddNew(false)
    }
  },[isNew, tables])

  const editTable = async (values) => {
    try {
      setLoading(true);
      const data = { table: values };
      const res = await postUpdateTable(table._id, data);

      dispatch(editTableAction(res.data.table));
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setLoading(false);
      hide();
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };
  const addTable = async (values) => {
    try {
      let data;
      setLoading(true);
      if (role === "waiter") {
        data = {
          table: { ...values, owner: waiter.branchId },
          code: waiter.code,
        };
      } else if (role === "super") {
        data = {
          table: { ...values, owner: branch?._id },
          code: waiter.code,
        };
      } else {
        return;
      }
      // check packgaes;

      const res = await postAddTable(data);
      dispatch(addNewTableAction(res.data.table));
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setLoading(false);
      hide();
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const handleDeleteTable = async () => {
    try {
      setLoading(true);
      const res = await postDeleteTable(table._id);
      if (res.data.status === "success") {
        dispatch(deleteTableAction(table._id));
      }
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setLoading(false);
      hide();
      history(-1);
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  return (
    <Modal
      title={
        <Title level={4} className='my-font'>
          {intl.formatMessage({ id: isNew ? "addTable" : "edit" })}
        </Title>
      }
      centered
      visible={show}
      destroyOnClose={true}
      mask={true}
      maskClosable={true}
      onCancel={() => hide()}
      footer={null}
    >
      {/* Zone Modal */}
      <Modal
        title={
          <Title level={5} className='my-font'>
            {intl.formatMessage({ id: "addZone" })}
          </Title>
        }
        centered
        visible={showAddZone}
        destroyOnClose={true}
        mask={true}
        maskClosable={true}
        onCancel={() => setShowAddZone(false)}
        onOk={() => {
          dispatch(addNewZoneAction(newZoneName));
          setShowAddZone(false);
          form.setFieldsValue({ zone: newZoneName });
        }}
        okText={intl.formatMessage({ id: "save" })}
        cancelText={intl.formatMessage({ id: "cancel" })}
        cancelButtonProps={{
          type: "text",
          style: { border: "none", fontFamily: "Cairo" },
        }}
        okButtonProps={{
          style: { borderRadius: 5, padding: "3px 30px", fontFamily: "Cairo" },
        }}
      >
        <Input
          value={newZoneName}
          onChange={(e) => setNewZoneName(e.target.value)}
        />
      </Modal>
      <Form
        name='editTable'
        form={form}
        preserve={false}
        className='form-container'
        layout='vertical'
        onFinish={isNew ? addTable : editTable}
        initialValues={
          isNew
            ? {
                name: "",
                name_en: "",
                zone: zones?.[0]?.name,
                numberOfChairs: 4,
                shape: TABLE_SHAPES.SQUARE,
                tableFees: 0,
                tableMinimumOrder: 0,
              }
            : {
                name: table?.name,
                name_en: table?.name_en,
                zone: table?.zone,
                numberOfChairs: table?.numberOfChairs,
                shape: table?.shape,
                tableFees: table?.tableFees,
                tableMinimumOrder: table?.tableMinimumOrder,
              }
        }
      >
        {() => (
          <>
            {/* Names */}
            <Row type='flex' justify='space-between'>
              <Col md={11} sm={24}>
                <Form.Item
                  label={intl.formatMessage({ id: "nameAr" })}
                  className='mb-1'
                  name='name'
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: "errorname" }),
                    },
                  ]}
                >
                  <Input className={"input"} />
                </Form.Item>
              </Col>
              <Col md={11} sm={24}>
                <Form.Item
                  label={intl.formatMessage({ id: "nameEn" })}
                  className='mb-1'
                  name='name_en'
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: "errorname" }),
                    },
                  ]}
                >
                  <Input className={"input"} />
                </Form.Item>
              </Col>
            </Row>
            {/* Chars */}
            <Row type='flex' justify='space-between'>
              <Col md={11} sm={24}>
                <Form.Item
                  label={intl.formatMessage({ id: "numberOfChairs" })}
                  className='mb-1'
                  name='numberOfChairs'
                >
                  <InputNumber
                    style={{
                      width: "100%",
                    }}
                    className={"input"}
                    min={0}
                  />
                </Form.Item>
              </Col>
              <Col md={11} sm={24} style={{ width: "100%" }}>
                <Form.Item
                  name='shape'
                  label={intl.formatMessage({ id: "tableShape" })}
                >
                  <Select className='my-font' style={{ width: "100%" }}>
                    {Object.values(TABLE_SHAPES).map((shape) => {
                      return (
                        <Option
                          className='my-font'
                          key={`${shape}`}
                          value={shape}
                        >
                          {intl.formatMessage({ id: `${shape}` })}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {/* Fees */}
            <Row type='flex' justify='space-between' align='bottom'>
              <Col md={11} sm={24}>
                <Form.Item
                  label={intl.formatMessage({
                    id: "tableFees"
                      
                  })}
                  className='mb-1'
                  name='tableFees'
                  tooltip={
                    <Text className='my-font'>
                      {intl.formatMessage({ id: "tableFeesDes" })}
                    </Text>
                  }
                >
                  <InputNumber
                    style={{
                      width: "100%",
                    }}
                    className={"input"}
                    min={0}
                  />
                </Form.Item>
              </Col> 
              <Col md={11} sm={24}>
              <Form.Item
                  label={intl.formatMessage({id: "minimumOrder",
                  })}
                  className='mb-1'
                  name='tableMinimumOrder'
                  
                >
                  <InputNumber
                    style={{
                      width: "100%",
                    }}
                    className={"input"}
                    min={0}
                  />
                </Form.Item>
              </Col>
            </Row>
            {/* Zone */}
            <Divider />
            <Row type='flex' justify='space-between'>
              <Col md={11} sm={24}>
                <Form.Item
                  name='zone'
                  label={
                    <Space direction='horizontal'>
                      <Text className='my-font'>
                        {intl.formatMessage({ id: "zone" })}
                      </Text>
                      <Tag
                        onClick={() => setShowAddZone(true)}
                        color='green'
                        className='pointer'
                      >{`${intl.formatMessage({
                        id: "addZone",
                      })} +`}</Tag>
                    </Space>
                  }
                >
                  <Select className='my-font'>
                    {zones.map((zone) => {
                      return (
                        <Option
                          className='my-font'
                          key={`${zone.name}`}
                          value={zone.name}
                        >
                          {zone.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {isNew ? null : (
              <Popconfirm
                title={
                  <Text className='my-font'>
                    {intl.formatMessage({ id: "confirmDeleteTableTitle" })}
                  </Text>
                }
                okText={intl.formatMessage({ id: "delete" })}
                okButtonProps={{
                  style: { color: COLORS.danger, fontFamily: "Cairo" },
                }}
                okType='text'
                cancelText={intl.formatMessage({ id: "cancel" })}
                cancelButtonProps={{
                  style: { border: "none", fontFamily: "Cairo" },
                }}
                onConfirm={handleDeleteTable}
                disabled={table?.orders?.length > 0}
              >
                <Text
                  type='danger'
                  disabled={table?.orders?.length > 0}
                  className='pointer my-font'
                >
                  {intl.formatMessage({ id: "deleteTable" })}
                </Text>
              </Popconfirm>
            )}
            <Divider />
            
            <Space direction='horizontal'>
              <Form.Item>
                <CustomButton
                  htmlType='submit'
                  text={intl.formatMessage({ id: "save" })}
                  className={`btnRegister btn-text border-8`}
                  loading={loading}
                  disabled={loading || canNotAddNew}
                  type='primary'
                />
              </Form.Item>

              <Form.Item>
                <CustomButton
                  type='text'
                  text={intl.formatMessage({ id: "cancel" })}
                  disabled={loading}
                  onClick={() => {
                    dispatch(filterZonesAction());
                    hide();
                  }}
                />
              </Form.Item>
            </Space>
            {canNotAddNew ? 
            <div>
              <Text type="danger" className="my-font">* {intl.formatMessage({ id: "youReachLimit" })}</Text>
            </div>
            : null}
          </>
        )}
      </Form>
    </Modal>
  );
};

export default EditTableModal;
