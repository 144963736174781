import React, { useState, useEffect } from "react";
import { Space, Spin, Tag, Typography, Form, Select, DatePicker } from "antd";
import { useIntl } from "react-intl";
import COLORS from "../../Style/colors";
import {
  getRestaurantPaymentBalance,
  getRestaurantPaymentRefund,
  refundPaymentOrder,
  refundPaymentReservation,
} from "../../API/fetch";
import { useDispatch, useSelector } from "react-redux";
import {
  DEFAULT_WALLET_NAME,
  ORDER_TYPES,
  TAG_COLORS_BY_PAYMENT_REFUND_STATUS,
} from "../../Types";
import moment from "moment";
import { Table } from "ant-table-extensions";
import { FileExcelOutlined } from "@ant-design/icons";
import { IoRefreshOutline } from "react-icons/io5";
import CustomButton from "../../Components/CustomButton";
import { customerExport } from "../../helpers";
import { updateOrdersAction } from "../../redux/reducers/orderReducer";
import openNotification from "../../Components/Notifications";
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Title, Text } = Typography;
const PaymentRefund = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const { restaurant } = useSelector((state) => state.restaurant);
  const [transactions, setTransactions] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // set the default date to be from 5 days ago to today
    const startDate = moment().startOf("day").subtract(5, "days");
    const endDate = moment().endOf("day");
    form.setFieldsValue({
      date: [startDate, endDate],
    });
    getPaymentBalance({ date: [startDate, endDate] });
  }, []);

  const getPaymentBalance = async (values) => {
    try {
      setLoading(true);
      setTransactions([]);
      const [start, end] = values.date;
      const startDate = moment(start).startOf("day").toISOString();
      const endDate = moment(end).endOf("day").toISOString();
      let data = {
        start: startDate,
        end: endDate,
      };
      const res = await getRestaurantPaymentRefund(data);
      if (res.data?.refund?.length) {
        let formatted = formatTransaction(res.data.refund);
        setTransactions(formatted);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const refundPayment = async (order) => {
    try {
      let type = order?.type;
      if (type === ORDER_TYPES.RESERVATION) {
        const data = {
          reservationId: order?._id,
        };
        const res = await refundPaymentReservation(data);
      } else {
        const data = {
          orderId: order?._id,
          orderType: order.type,
        };
        const res = await refundPaymentOrder(data);
        if (res?.data?.refund) {
          // update order with refund payment id;
          let updatedOrder = {
            ...order,
            refundPaymentId: res.data.refund,
          };
          if (type === ORDER_TYPES.DINE_IN) {
            dispatch(
              updateOrdersAction({
                type: ORDER_TYPES.DINE_IN,
                payload: updatedOrder,
              })
            );
          } else if (type === ORDER_TYPES.PICKUP) {
            dispatch(
              updateOrdersAction({
                type: ORDER_TYPES.PICKUP,
                payload: updatedOrder,
              })
            );
          } else if (type === ORDER_TYPES.DELIVERY) {
            dispatch(
              updateOrdersAction({
                type: ORDER_TYPES.DELIVERY,
                payload: updatedOrder,
              })
            );
          } else if (type === ORDER_TYPES.IWAITER_APP) {
            dispatch(
              updateOrdersAction({
                type: ORDER_TYPES.IWAITER_APP,
                payload: updatedOrder,
              })
            );
          }
        }
      }
      setLoading(true);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setLoading(false);
      getPaymentBalance(form.getFieldValue());
    } catch (error) {
      console.log(error);
      let message = intl.formatMessage({ id: "errorfetch" });
      if (error?.response?.data?.error) {
        message = error.response.data.error;
      }
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: message,
        type: "error",
        rtl,
      });
    }
  };

  const formatTransaction = (transactions) => {
    let formatted = [];
    if (transactions?.length) {
      transactions.forEach((transaction, i) => {
        let orderNumber = transaction?.orderNumber;
        if (transaction?.type === ORDER_TYPES.RESERVATION) {
          // get last 4 digits of orderNumber and make it uppercase
          orderNumber = orderNumber.slice(-5)?.toUpperCase();
        }
        formatted.push({
          key: transaction?._id,
          _id: transaction?._id,
          orderNumber: orderNumber,
          total: transaction?.total,
          status: transaction?.status,
          type: transaction?.type,
          date: moment(transaction?.createdAt).format("DD/MM/YYYY"),
          customerName: transaction?.customerName,
          customerPhone: transaction?.customerPhone,
          transactionObject: { ...transaction },
        });
      });
    }
    return formatted;
  };

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: intl.formatMessage({ id: "orderNumber" }),
      dataIndex: "orderNumber",
      key: "orderNumber",
      render: (text, record) => (
        <Space direction="vertical" size={0}>
          <span>{text} - {intl.formatMessage({ id: ORDER_TYPES[record.type] })}</span>
          <Tag color={TAG_COLORS_BY_PAYMENT_REFUND_STATUS[record?.status]}>{record?.customerName}</Tag>
          <Tag>0{record?.customerPhone?.slice(3)}</Tag>
        </Space>
      ),
    },
    {
      title: intl.formatMessage({ id: "amount" }),
      dataIndex: "total",
      key: "total",
    },
    {
      title: intl.formatMessage({ id: "status" }),
      dataIndex: "status",
      key: "status",
      render: (status, record) => {
        return (
          <>
            <Tag color={TAG_COLORS_BY_PAYMENT_REFUND_STATUS[status]}>
              {intl.formatMessage({ id: status })}
            </Tag>
            <CustomButton
              type='text'
              text={intl.formatMessage({ id: "refresh" })}
              onClick={() => {
                refundPayment(record.transactionObject);
              }}
              icon={<IoRefreshOutline />}
              loading={loading}
              disabled={
                status === "REFUNDED" ||
                status === "refunded" ||
                status === "Refunded"
              }
            />
          </>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "date" }),
      dataIndex: "date",
      key: "date",
      sorter: (a, b) =>
        new Date(a.transactionObject?.createdAt) -
        new Date(b.transactionObject?.createdAt),
      showSorterTooltip: false,
    },
  ];
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <Title level={3}>{intl.formatMessage({ id: "paymentRefund" })}</Title>
      </div>
      <div>
        <div style={{ backgroundColor: COLORS.gray }} className='py-4 px-3'>
          <Form
            name='food-form'
            form={form}
            className='form-container'
            layout='inline'
            onFinish={getPaymentBalance}
            initialValues={{ type: "all" }}
          >
            <Form.Item
              rules={[{ required: true, message: "Please select Date" }]}
              name='date'
              label={intl.formatMessage({ id: "date" })}
            >
              <RangePicker />
            </Form.Item>
            <Form.Item>
              <CustomButton
                htmlType='submit'
                text={intl.formatMessage({ id: "search" })}
                type='primary'
                style={{ borderRadius: 7 }}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
      <Spin spinning={loading}>
        <div
          style={{
            backgroundColor: COLORS.white,
            marginTop: 40,
            width: "92%",
            marginRight: "auto",
            marginLeft: "auto",
            borderRadius: 10,
            padding: 15,
          }}
          className='light-shadow'
        >
          <Table
            exportable
            exportableProps={{
              showColumnPicker: true,
              btnProps: {
                icon: <FileExcelOutlined />,
                type: "primary",
                style: { borderRadius: 7 },
                onClick: () =>
                  customerExport({
                    data: transactions,
                    columns: columns,
                    fileName: "Transactions Report",
                    loadingSetter: setLoading,
                  }),
              },
              children: (
                <Text
                  className='my-font'
                  style={{
                    color: COLORS.white,
                  }}
                >
                  {intl.formatMessage({ id: "export" })}
                </Text>
              ),
            }}
            pagination={{
              hideOnSinglePage: true,
            }}
            dataSource={transactions}
            scroll={{ x: 500 }}
            columns={columns}
            style={{ borderRadius: 10 }}
          />
        </div>
      </Spin>
    </div>
  );
};

export default PaymentRefund;
