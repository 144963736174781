import React from "react";
import { DINE_IN_ORDERS_STATUS, TAG_COLORS_BY_STATUS } from "../../../Types";
import { Tag } from "antd";
import { useIntl } from "react-intl";
const DineInTag = ({ status, changeStatus }) => {
  const intl = useIntl();
  return (
    <Tag
      onClick={() => changeStatus(status)}
      color={
        status === DINE_IN_ORDERS_STATUS.OPEN
          ? TAG_COLORS_BY_STATUS.inService
          : status === DINE_IN_ORDERS_STATUS.IN_SERVER
          ? TAG_COLORS_BY_STATUS.served
          : status === DINE_IN_ORDERS_STATUS.SERVED
          ? TAG_COLORS_BY_STATUS.open
          : ""
      }
      className='my-font px-5 py-1'
    >
      {status === DINE_IN_ORDERS_STATUS.OPEN
        ? intl.formatMessage({ id: "inServiceAction" })
        : status === DINE_IN_ORDERS_STATUS.IN_SERVER
        ? intl.formatMessage({ id: "servedAction" })
        : status === DINE_IN_ORDERS_STATUS.SERVED
        ? intl.formatMessage({ id: "closeAction" })
        : intl.formatMessage({ id: "openAction" })}
    </Tag>
  );
};

export default DineInTag;
