import React, { useState, useEffect } from "react";
import { Row, Col, Typography, Form, Spin, Upload, message, Tabs } from "antd";
import COLORS from "../../Style/colors";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { editAds } from "../../API/fetch";
import { setAddsAction } from "../../redux/reducers/restaurantReducer";
import openNotification from "../../Components/Notifications";
import MainPagePreview from "./Components/MainPagePreview";
import { InboxOutlined } from "@ant-design/icons";
import apiUrl from "../../API/config";
import ImageAddRow from "./Components/ImageAddRow";
import MenuAdForm from "./Components/MenuAdForm";
import CustomButton from "../../Components/CustomButton";
import { useNavigate } from "react-router";

const { Title, Text } = Typography;

const { Dragger } = Upload;
const acceptedTypes = ["image/png", "image/jpg", "image/jpeg"];

const { TabPane } = Tabs;

const MenuAds = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { design, restaurant, adds } = useSelector((state) => state.restaurant);
  const { isBigScreen, rtl } = useSelector((state) => state.settings);
  const { categories } = useSelector((state) => state.menu);
  const { user } = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  const [categoryId, setCategoryId] = useState("");
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [imagesToDisplay, setImagesToDisplay] = useState([]);
  const [errorSize, setErrorSize] = useState(false);

  useEffect(() => {
    form.resetFields();
    if (adds?.images?.length) {
      if (categoryId?.trim().length) {
        setFileList(
          adds?.images
            ?.filter((el) => el.categoryId == categoryId)
            ?.map((image, i) => {
              return {
                uid: image._id,
                status: "done",
                url: image.url,
                title: `${image?.title}`,
                color: image.color,
                displayStatus: image.status,
              };
            })
        );
        setImagesToDisplay(
          adds?.images?.filter(
            (im) => im.status === "Active" && im.categoryId == categoryId
          )
        );
      } else {
        setFileList(
          adds?.images
            ?.filter((img) => !img.categoryId)
            ?.map((image, i) => {
              return {
                uid: image._id,
                status: "done",
                url: image.url,
                title: `${image?.title}`,
                color: image.color,
                displayStatus: image.status,
              };
            })
        );
        setImagesToDisplay(
          adds?.images?.filter((im) => im.status === "Active")
        );
      }
    }
  }, [design, adds, categoryId]);

  // handle image upload;
  const handleChangeImages = ({ fileList, file }) => {
    let newFileList = [...fileList];
    setErrorSize(false);
    let errors = [];
    newFileList.forEach((el) => {
      if (el.size > 2000000) {
        //check if the image size is bigger than 2Mo
        errors.push(el?.uid);
      }
    });
    if (errors.length > 0) {
      //return error if there is images bigger than 2Mo
      setErrorSize(true);
      return;
    }
    if (file.status === "uploading") {
      setLoading(true); // show loading;
    } else if (file.status === "done") {
      // upload success;
      setLoading(false); // hide loading;
      if (file.response?.add?._id) {
        newFileList = file.response.add.images.map((image, i) => {
          return {
            uid: image._id,
            status: "done",
            url: image.url,
            title: `${image?.title}`,
            color: image.color,
            displayStatus: image.status,
            categoryId: image.categoryId,
          };
        });
        dispatch(setAddsAction(file.response.add));
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
      }
    } else if (file.status === "error") {
      setLoading(false); // hide loading;
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
    if (fileList.length <= 4) {
      setFileList(newFileList);
    } else {
      message.error(intl.formatMessage({ id: "max4Images" }));
    }
  };

  const beforeUpload = (file) => {
    if (!acceptedTypes.includes(file?.type) || !file) {
      message.error("You can only upload JPG/PNG file!");
      return false;
    }
    if (file.type?.includes("image")) {
      if (file.size / 1000000 > 2.2) {
        message.error(intl.formatMessage({ id: "limit2Mb" }));
        return false;
      }
    }
    if (fileList.length >= 4) {
      return false;
    }
    return true;
  };

  // Delete image;
  const deleteImage = async (e) => {
    setLoading(true);
    try {
      let newImage = adds.images.filter((image) => image._id !== e.uid);
      const id = adds._id;
      const data = {
        add: {
          ...adds,
          images: newImage,
        },
      };
      const res = await editAds(id, data);
      dispatch(setAddsAction(res.data.add));

      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
    } catch (error) {
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const handleEditImage = async (values) => {
    setLoading(true);
    try {
      let newImage = adds.images.map((image) => {
        if (image._id === values.uid) {
          return {
            ...image,
            ...values,
          };
        } else {
          return {
            ...image,
          };
        }
      });
      const id = adds._id;
      const data = {
        add: {
          ...adds,
          images: newImage,
        },
      };
      const res = await editAds(id, data);
      dispatch(setAddsAction(res.data.add));

      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
    } catch (error) {
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <Title level={4}>{intl.formatMessage({ id: "adds" })}</Title>
        <CustomButton
          text={intl.formatMessage({ id: "textAds" })}
          type='primary'
          onClick={() => {
            navigate("/textAds");
          }}
          style={{
            borderRadius: 6,
          }}
        />
      </div>

      <div
        style={{
          backgroundColor: COLORS.white,
          borderRadius: 6,
          width: "92%",
          marginRight: "auto",
          marginLeft: "auto",
          padding: 15,
        }}
        className='light-shadow'
      >
        <Tabs defaultActiveKey='1'>
          <TabPane
            key='1'
            tab={
              <span
                className='px-3 my-font'
                onClick={() => {
                  setCategoryId("");
                  setErrorSize(false);
                }}
              >
                {intl.formatMessage({ id: "mainAds" })}
              </span>
            }
          >
            <Form
              name='viewCart-form'
              form={form}
              className='form-container'
              layout='vertical'
              initialValues={{
                ...design,
              }}
            >
              {() => {
                return (
                  <MenuAdForm
                    form={form}
                    loading={loading}
                    sectionName={intl.formatMessage({ id: "mainAds" })}
                    actionLink={`${apiUrl}/ads-upload-image?restaurantId=${restaurant._id}&slug=${restaurant.slug}&card=true&id=${adds?._id}`}
                    token={{ authorization: "Bearer " + user.token }}
                    fileList={fileList}
                    handleChangeImages={handleChangeImages}
                    beforeUpload={beforeUpload}
                    deleteImage={deleteImage}
                    handleEditImage={handleEditImage}
                    imagesToDisplay={imagesToDisplay}
                    isBigScreen={isBigScreen}
                    errorSize={errorSize}
                  />
                );
              }}
            </Form>
          </TabPane>
          {categories?.map((category) => {
            return (
              <TabPane
                key={category.categoryId}
                style={{
                  backgroundColor: COLORS.white,
                  minHeight: "75vh",
                }}
                tab={
                  <span
                    className='px-3 my-font'
                    onClick={() => {
                      setCategoryId(category.categoryId);
                      setErrorSize(false);
                    }}
                  >
                    {category?.name_en
                      ? rtl
                        ? category?.name
                        : category.name_en
                      : category.name}
                  </span>
                }
              >
                <Form
                  name='viewCart-form'
                  form={form}
                  className='form-container'
                  layout='vertical'
                  initialValues={{
                    ...design,
                  }}
                >
                  {() => {
                    return (
                      <MenuAdForm
                        form={form}
                        loading={loading}
                        sectionName={rtl ? category?.name : category.name_en}
                        actionLink={`${apiUrl}/ads-upload-image?restaurantId=${restaurant._id}&slug=${restaurant.slug}&card=true&id=${adds?._id}&categoryId=${categoryId}`}
                        token={{ authorization: "Bearer " + user.token }}
                        fileList={fileList}
                        handleChangeImages={handleChangeImages}
                        beforeUpload={beforeUpload}
                        deleteImage={deleteImage}
                        handleEditImage={handleEditImage}
                        imagesToDisplay={imagesToDisplay}
                        isBigScreen={isBigScreen}
                        errorSize={errorSize}
                      />
                    );
                  }}
                </Form>
              </TabPane>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
};

export default MenuAds;
