import React, { useState } from "react";
import { Space, Popover, Popconfirm, Typography } from "antd";
import CustomButton from "../../../Components/CustomButton";
import { useIntl } from "react-intl";
import  QRCode  from "qrcode.react";
import { useDispatch, useSelector } from "react-redux";
import { postUpdateUserReservation } from "../../../API/fetch";
import { setBranchAction } from "../../../redux/reducers/branchReducer";
import openNotification from "../../../Components/Notifications";
import COLORS from "../../../Style/colors";
import moment from "moment";

const { Text } = Typography;

const ReservationUserActions = ({ record, type, date, canEdit, mustChooseTable }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const { branch } = useSelector((state) => state.branch);
  const [loading, setLoading] = useState(false);
  
  const handelEditUser = async ({ id, status }) => {
    try {
      setLoading(true);
      const dateFormat = moment(date);
      const data = {
        reservation: {
          status,
          date: dateFormat,
        }, table: {},
      };
      const res = await postUpdateUserReservation(id, data);
      const newBranch = {
        ...branch,
        reservationList: res.data.reservationList,
      };
      dispatch(setBranchAction(newBranch));
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  return (
    <Space
      size='small'
      style={{
        minWidth: 100,
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
      }}
    >
     
      {/* <CustomButton
        type='text'
        disabled={loading || canEdit === false || mustChooseTable}
        loading={loading}
        style={{ color: canEdit === false || mustChooseTable ? COLORS.darkGray :   type === "Active" ? COLORS.primary : COLORS.danger }}
        text={intl.formatMessage({ id: type === "Active" ? "done" : "undo" })}
        onClick={() =>
          handelEditUser({
            id: record.userObject._id,
            status: type === "Active" ? "served" : "Active",
          })
        }
      /> */}
      {type === "Active" ? (
        <Popconfirm
          title={
            <Text className='my-font'>
              {intl.formatMessage({ id: "confirmDelete" })}
            </Text>
          }
          okText={intl.formatMessage({ id: "delete" })}
          okButtonProps={{ style: { color: COLORS.danger,  fontFamily: 'Cairo'  } }}
          okType='text'
          cancelText={intl.formatMessage({ id: "cancel" })}
          cancelButtonProps={{ style: { border: "none",  fontFamily: 'Cairo'  } }}
          onConfirm={() =>
            handelEditUser({ id: record.userObject._id, status: "deleted" })
          }
          disabled={loading || record.userObject?.paymentStatus === 'paid' || canEdit === false}
          loading={loading}
        >
          <Text type='danger' className='pointer my-font' disabled={loading || record.userObject?.paymentStatus === 'paid' || canEdit === false}>
            {intl.formatMessage({ id: "delete" })}
          </Text>
        </Popconfirm>
      ) : null}
    </Space>
  );
};

export default ReservationUserActions;
