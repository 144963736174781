import React, { useState, useEffect } from "react";
import { Badge, Menu, Typography } from "antd";

import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import {
  changeUserAction,
  logOutAction,
} from "../../redux/reducers/authReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  IoAnalyticsOutline,
  IoBagHandleOutline,
  IoBanOutline,
  IoBookmarkOutline,
  IoCardOutline,
  IoCartOutline,
  IoChatbubblesOutline,
  IoCheckboxOutline,
  IoDesktopOutline,
  IoFastFoodOutline,
  IoGridOutline,
  IoHomeOutline,
  IoIdCardOutline,
  IoImagesOutline,
  IoLanguageOutline,
  IoLibraryOutline,
  IoLinkOutline,
  IoListOutline,
  IoLogoBuffer,
  IoLogOut,
  IoLogOutOutline,
  IoNewspaperOutline,
  IoPaperPlaneOutline,
  IoPeopleCircleOutline,
  IoPhonePortraitOutline,
  IoQrCodeOutline,
  IoReaderOutline,
  IoSettingsOutline,
  IoShareSocialOutline,
  IoTimeOutline,
  IoTimerOutline,
  IoToggleOutline,
  IoTrailSignOutline,
} from "react-icons/io5";
import { changeLanguageAction } from "../../redux/reducers/settingsReducer";
import { BiErrorAlt, BiSortAlt2 } from "react-icons/bi";
import COLORS from "../../Style/colors";
import { AiOutlineCar } from "react-icons/ai";
import {
  GiReceiveMoney,
  GiRoundTable,
  GiTakeMyMoney,
  GiTruce,
} from "react-icons/gi";
import {
  DEFAULT_WALLET_NAME,
  DELIVERY_ORDERS_STATUS,
  DINE_IN_ORDERS_STATUS,
  IWAITER_APP_ORDERS_STATUS,
  PICKUP_ORDERS_STATUS,
  SUBSCRIPTION_TYPES,
} from "../../Types";
import { Mixpanel } from "../../Mixpanel";
import { BsPercent } from "react-icons/bs";
import { signOutServer } from "../../API/fetch";
import { BankOutlined, BookOutlined, TabletOutlined } from "@ant-design/icons";
import { FiGift } from "react-icons/fi";
import { FaSms } from "react-icons/fa";

const OLD_FATOORAH = ["Burger-County8j19rq"];

const { SubMenu } = Menu;
const { Text } = Typography;
const SuperUserSide = ({ toggleSideBar }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const { comments } = useSelector((state) => state.menu);
  const { restaurant } = useSelector((state) => state.restaurant);
  const { allBranches } = useSelector((state) => state.branch);
  const { subscriptionEnd, services, superUser } = useSelector(
    (state) => state.auth
  );
  const {
    dineInOrders,
    pickupOrders,
    deliveryOrders,
    iwaiterAppOrders,
    customOrders,
  } = useSelector((state) => state.order);
  const [doesCustomOrderActive, setDoesCustomOrderActive] = useState(false);
  const [numberOfOpenOrders, setNumberOfOpenOrders] = useState(0);
  const [numberOfOpenPickupOrders, setNumberOfOpenPickupOrders] = useState(0);
  const [numberOfPendingIwaiterAppOrders, setNumberOfPendingIwaiterAppOrders] =
    useState(0);
  const [numberOfScheduleOrder, setNumberOfScheduleOrder] = useState(0);
  const [numberOfCustomOrders, setNumberOfCustomOrders] = useState(0);
  const [numberOfOpenDeliveryOrders, setNumberOfOpenDeliveryOrders] =
    useState(0);

  useEffect(() => {
    let customOrder = false;
    allBranches.map((el) => {
      if (el?.branch?.DoesCustomOrderActive === true) {
        customOrder = true;
      }
    });
    setDoesCustomOrderActive(customOrder);
  }, [allBranches]);

  useEffect(() => {
    if (dineInOrders?.length) {
      const openOrders = dineInOrders?.filter((o) => {
        return o.status !== DINE_IN_ORDERS_STATUS.CLOSE;
      })?.length;
      setNumberOfOpenOrders(openOrders);
    }
    if (pickupOrders?.length) {
      const openOrders = pickupOrders?.filter((o) => {
        return o.status !== PICKUP_ORDERS_STATUS.CLOSE && !o.timeForPickup;
      })?.length;
      const openOrdersSchedule = pickupOrders?.filter((o) => {
        return o.status !== PICKUP_ORDERS_STATUS.CLOSE && o.timeForPickup;
      })?.length;
      setNumberOfOpenPickupOrders(openOrders);
      setNumberOfScheduleOrder(openOrdersSchedule);
    }
    if (deliveryOrders?.length) {
      const openOrders = deliveryOrders?.filter((o) => {
        return o.status !== DELIVERY_ORDERS_STATUS.CLOSE;
      })?.length;
      setNumberOfOpenDeliveryOrders(openOrders);
    }
    if (customOrders?.length) {
      const openOrders = customOrders?.filter((o) => {
        return o?.status !== DINE_IN_ORDERS_STATUS.CLOSE;
      })?.length;
      setNumberOfCustomOrders(openOrders);
    }
    if (iwaiterAppOrders?.length) {
      const pendingOrders = iwaiterAppOrders?.filter((o) => {
        return (
          o.status !== IWAITER_APP_ORDERS_STATUS.CLOSE &&
          o.status !== IWAITER_APP_ORDERS_STATUS.REJECTED &&
          o.status !== IWAITER_APP_ORDERS_STATUS.TIMEOUT
        );
      })?.length;
      setNumberOfPendingIwaiterAppOrders(pendingOrders);
    }
  }, [dineInOrders, pickupOrders, deliveryOrders, iwaiterAppOrders]);

  return (
    <Menu theme='light' mode='inline' defaultSelectedKeys={["1"]}>
      <Menu.Item
        disabled={subscriptionEnd || !services?.length}
        key='home'
        icon={<IoHomeOutline size={20} />}
        onClick={() => {
          Mixpanel.track("Home Screen");
          toggleSideBar();
        }}
      >
        <Link to='/'>{intl.formatMessage({ id: "home" })}</Link>
      </Menu.Item>
      <SubMenu
        key='settings'
        icon={<IoSettingsOutline size={20} />}
        title={intl.formatMessage({ id: "settings" })}
      >
        <Menu.Item
          disabled={subscriptionEnd || !services?.length}
          onClick={() => {
            Mixpanel.track("Restaurant settings Screen");
            toggleSideBar();
          }}
          key='restaurant'
          icon={<IoReaderOutline size={20} />}
        >
          <Link to='/restaurant'>{intl.formatMessage({ id: "general" })}</Link>
        </Menu.Item>
        <Menu.Item
          disabled={subscriptionEnd || !services?.length}
          onClick={() => {
            Mixpanel.track("Branches Screen");
            toggleSideBar();
          }}
          key='branches'
          icon={<IoTrailSignOutline size={20} />}
        >
          <Link to='/branches'>{intl.formatMessage({ id: "branches" })}</Link>
        </Menu.Item>
        <Menu.Item
          disabled={subscriptionEnd || !services?.length}
          onClick={() => {
            Mixpanel.track("Payment Screen");
            toggleSideBar();
          }}
          key='payment'
          icon={<IoCardOutline size={20} />}
        >
          <Link to='/payment'>
            {intl.formatMessage({ id: "onlinePayment" })}
          </Link>
        </Menu.Item>
        <Menu.Item
          disabled={subscriptionEnd || !services?.length}
          onClick={() => {
            Mixpanel.track("Waiters Screen");
            toggleSideBar();
          }}
          key='waiters'
          icon={<IoPeopleCircleOutline size={20} />}
        >
          <Link to='/waiters'>{intl.formatMessage({ id: "waiters" })}</Link>
        </Menu.Item>
        <Menu.Item
          disabled={subscriptionEnd || !services?.length}
          onClick={() => {
            Mixpanel.track("Integration Screen");
            toggleSideBar();
          }}
          key='integration'
          icon={<IoLinkOutline size={20} />}
        >
          <Link to='/integration'>
            {intl.formatMessage({ id: "integration" })}
          </Link>
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            Mixpanel.track("Subscription Screen");
            toggleSideBar();
          }}
          key='subscription'
          icon={<IoToggleOutline size={20} />}
        >
          <Link to='/subscription'>
            {intl.formatMessage({ id: "subscription" })}
          </Link>
        </Menu.Item>
      </SubMenu>

      <SubMenu
        key='allReports'
        icon={<IoLibraryOutline size={20} />}
        title={intl.formatMessage({ id: "reports" })}
      >
        <Menu.Item
          disabled={
            subscriptionEnd ||
            !services?.length ||
            (!services?.includes("ORDER_A") &&
              !services?.includes("ORDER_B") &&
              !services?.includes("PICKUP"))
          }
          onClick={() => {
            Mixpanel.track("Reports Screen");
            toggleSideBar();
          }}
          key='reports'
          icon={<IoNewspaperOutline size={20} />}
        >
          <Link to='/reports'>{intl.formatMessage({ id: "general" })}</Link>
        </Menu.Item>
        <Menu.Item
          disabled={subscriptionEnd || !services?.length}
          onClick={() => {
            Mixpanel.track("All users Screen");
            toggleSideBar();
          }}
          key='allUsers'
          icon={<IoPeopleCircleOutline size={20} />}
        >
          <Link to='/allUsers'>{intl.formatMessage({ id: "users" })}</Link>
        </Menu.Item>
        <Menu.Item
          disabled={
            subscriptionEnd ||
            !services?.length ||
            !services?.includes("RESERVATION")
          }
          onClick={() => {
            Mixpanel.track("Reservation Screen");
            toggleSideBar();
          }}
          key='ReservationReports'
          icon={<IoBookmarkOutline size={20} />}
        >
          <Link to='/reservations-reports'>
            {intl.formatMessage({ id: "reservations" })}
          </Link>
        </Menu.Item>
        <Menu.Item
          disabled={
            subscriptionEnd ||
            !services?.length ||
            (!services?.includes("PICKUP") &&
              !services?.includes("ORDER_A") &&
              !services?.includes("ORDER_B"))
          }
          onClick={() => {
            Mixpanel.track("Order live cycle Screen");
            toggleSideBar();
          }}
          key='order-live-cycle'
          icon={<IoTimerOutline size={20} />}
        >
          <Link to='/order-live-cycle'>
            {intl.formatMessage({ id: "timeReports" })}
          </Link>
        </Menu.Item>
        {/* {restaurant?.wallet?.type === DEFAULT_WALLET_NAME.IWAITER_WALLET ? (
          <Menu.Item
            onClick={() => {
              Mixpanel.track("Payment record  Screen");
              toggleSideBar();
            }}
            key='payment-records'
            icon={<IoCardOutline size={20} />}
          >
            <Link to='/paymentRecords'>
              {intl.formatMessage({ id: "paymentRecords" })}
            </Link>
          </Menu.Item>
        ) : null} */}

        {restaurant?.wallet?.type === DEFAULT_WALLET_NAME.IWAITER_WALLET &&
        restaurant?.wallet?.provider === "MY_FATOORAH" ? (
          <Menu.Item
            onClick={() => {
              Mixpanel.track("Payment my fatoorah  Screen");
              toggleSideBar();
            }}
            key='my-fatoorah-records'
            icon={<IoCardOutline size={20} />}
          >
            <Link to='/payments-data'>
              {intl.formatMessage({ id: "paymentRecords" })}
            </Link>
          </Menu.Item>
        ) : restaurant?.wallet?.type === DEFAULT_WALLET_NAME.IWAITER_WALLET ? (
          <Menu.Item
            onClick={() => {
              Mixpanel.track("Payment record  Screen");
              toggleSideBar();
            }}
            key='payment-records'
            icon={<IoCardOutline size={20} />}
          >
            <Link to='/paymentRecords'>
              {intl.formatMessage({ id: "paymentRecords" })}
            </Link>
          </Menu.Item>
        ) : null}

        <Menu.Item
          onClick={() => {
            Mixpanel.track("Payment tips  Screen");
            toggleSideBar();
          }}
          key='tip-records'
          icon={<GiReceiveMoney size={20} />}
        >
          <Link
            to={
              restaurant?.wallet?.provider === "MY_FATOORAH"
                ? "/tip-reports"
                : "/tips"
            }
          >
            {intl.formatMessage({ id: "tipsRecords" })}
          </Link>
        </Menu.Item>

        {restaurant?.wallet?.type === DEFAULT_WALLET_NAME.IWAITER_WALLET ? (
          <Menu.Item
            onClick={() => {
              Mixpanel.track("Payment refund  Screen");
              toggleSideBar();
            }}
            key='payment-refund'
            icon={<IoBanOutline size={20} />}
          >
            <Link to='/paymentRefund'>
              {intl.formatMessage({ id: "paymentRefund" })}
            </Link>
          </Menu.Item>
        ) : null}
      </SubMenu>

      <SubMenu
        disabled={subscriptionEnd || !services?.length}
        key='design'
        icon={<IoPhonePortraitOutline size={20} />}
        title={intl.formatMessage({ id: "design" })}
      >
        <Menu.Item
          disabled={subscriptionEnd}
          onClick={() => {
            Mixpanel.track("Design main Screen");
            toggleSideBar();
          }}
          key='mainPage'
          icon={<IoIdCardOutline size={20} />}
        >
          <Link to='/mainPage'>
            {intl.formatMessage({ id: "colorsAndDesign" })}
          </Link>
        </Menu.Item>
        <Menu.Item
          disabled={subscriptionEnd}
          onClick={() => {
            Mixpanel.track("Design View food Screen");
            toggleSideBar();
          }}
          key='viewFood'
          icon={<IoDesktopOutline size={20} />}
        >
          {/* <Link to='/viewFood'>{intl.formatMessage({ id: "viewFood" })}</Link>
        </Menu.Item>
        <Menu.Item
        disabled={subscriptionEnd}
          onClick={() => {
            Mixpanel.track("Design cart Screen");
            toggleSideBar();
          }}
          key='viewCart'
          icon={<IoCartOutline size={20} />}
        >
          <Link to='/viewCart'>{intl.formatMessage({ id: "cartView" })}</Link>
        </Menu.Item>
        <Menu.Item
        disabled={subscriptionEnd}
          onClick={() => {
            Mixpanel.track("Design conformation Screen");
            toggleSideBar();
          }}
          key='viewConfirm'
          icon={<IoCheckboxOutline size={20} />}
        >
          <Link to='/viewConfirm'>
            {intl.formatMessage({ id: "confirmView" })}
          </Link>
        </Menu.Item>
        <Menu.Item
        disabled={subscriptionEnd}
          onClick={() => {
            Mixpanel.track("Design Adds Screen");
            toggleSideBar();
          }}
          key='menuAds'
          icon={<IoImagesOutline size={20} />}
        > */}
          <Link to='/menuAds'>{intl.formatMessage({ id: "adds" })}</Link>
        </Menu.Item>
      </SubMenu>
      {/* <Menu.Item
        disabled={subscriptionEnd}
        onClick={() => {
          Mixpanel.track("Design social Screen");
          toggleSideBar();
        }}
        key='socialIcons'
        icon={<IoShareSocialOutline size={20} />}
      >
        <Link to='/socialIcons'>
          {intl.formatMessage({ id: "socialIcons" })}
        </Link>
      </Menu.Item> */}
      <Menu.Item
        disabled={subscriptionEnd}
        onClick={() => {
          Mixpanel.track("Design QR Screen");
          toggleSideBar();
        }}
        key='qrs'
        icon={<IoQrCodeOutline size={20} />}
      >
        <Link to='/qrs'>{intl.formatMessage({ id: "qrs" })}</Link>
      </Menu.Item>
      {/* <Menu.Item
        disabled={subscriptionEnd}
        onClick={() => {
          Mixpanel.track("Feedback Screen");
          toggleSideBar();
        }}
        key='feedback'
        icon={<IoPaperPlaneOutline size={20} />}
      >
        <Link to='/feedback'>{intl.formatMessage({ id: "feedback" })}</Link>
      </Menu.Item> */}
      {/* <Menu.Item
        disabled={subscriptionEnd || !services?.length}
        onClick={() => {
          Mixpanel.track("Comment Screen");
          toggleSideBar();
        }}
        key='comments'
        icon={<IoChatbubblesOutline size={20} />}
      >
        <Link to='/comments'>
          <Badge
            count={comments?.filter((c) => c?.status === "Pending")?.length}
            style={{ backgroundColor: COLORS.primary }}
            offset={rtl ? [-20, 7] : [14, 7]}
          >
            {intl.formatMessage({ id: "comments" })}
          </Badge>
        </Link>
      </Menu.Item> */}

      <SubMenu
        disabled={
          subscriptionEnd ||
          !services?.length ||
          (!services?.includes("PICKUP") &&
            !services?.includes("ORDER_A") &&
            !services?.includes("ORDER_B"))
        }
        key='couponCodes'
        icon={<BsPercent size={20} />}
        title={intl.formatMessage({ id: "couponAndPromotion" })}
      >
        <Menu.Item
          disabled={subscriptionEnd}
          onClick={() => {
            Mixpanel.track("Coupons Screen");
            toggleSideBar();
          }}
          key='promoCode'
          icon={<IoFastFoodOutline size={20} />}
        >
          <Link to='/promoCode'>
            {intl.formatMessage({ id: "couponCodes" })}
          </Link>
        </Menu.Item>
        <Menu.Item
          disabled={subscriptionEnd}
          onClick={() => {
            Mixpanel.track("Promotion Screen");
            toggleSideBar();
          }}
          key='promotions'
          icon={<BiSortAlt2 size={20} />}
        >
          <Link to='/promotions'>
            {intl.formatMessage({ id: "promotions" })}
          </Link>
        </Menu.Item>
        <Menu.Item
          disabled={subscriptionEnd}
          onClick={() => {
            Mixpanel.track("marketing Screen");
            toggleSideBar();
          }}
          key='marketing'
          icon={<FiGift size={20} />}
        >
          <Link to='/marketing'>{intl.formatMessage({ id: "prizes" })}</Link>
        </Menu.Item>
        <Menu.Item
          disabled={subscriptionEnd}
          onClick={() => {
            Mixpanel.track("marketingSMS Screen");
            toggleSideBar();
          }}
          key='marketingSMS'
          icon={<FaSms size={20} />}
        >
          <Link to='/campaigns'>{intl.formatMessage({ id: "marketing" })}</Link>
        </Menu.Item>
      </SubMenu>

      <SubMenu
        disabled={subscriptionEnd || !services?.length}
        key='allOrders'
        icon={<IoListOutline size={20} />}
        title={intl.formatMessage({ id: "orders" })}
      >
        <Menu.Item
          disabled={
            subscriptionEnd ||
            !services?.length ||
            (!services?.includes("ORDER_A") && !services?.includes("ORDER_B"))
          }
          onClick={() => {
            Mixpanel.track("Orders Screen");
            toggleSideBar();
          }}
          key='orders'
          icon={<GiRoundTable size={20} />}
        >
          <Link to='/orders'>
            <Badge
              count={numberOfOpenOrders}
              style={{ backgroundColor: COLORS.primary }}
              offset={rtl ? [-20, 7] : [14, 7]}
            >
              {intl.formatMessage({ id: "inHouse" })}
            </Badge>
          </Link>
        </Menu.Item>
        <Menu.Item
          disabled={
            subscriptionEnd ||
            !services?.length ||
            !services?.includes("PICKUP")
          }
          onClick={() => {
            Mixpanel.track("Pickup Screen");
            toggleSideBar();
          }}
          key='pickup'
          icon={<IoBagHandleOutline size={20} />}
        >
          <Link to='/pickup'>
            <Badge
              count={numberOfOpenPickupOrders}
              style={{ backgroundColor: COLORS.primary }}
              offset={rtl ? [-20, 7] : [14, 7]}
            >
              {intl.formatMessage({ id: "pickup" })}
            </Badge>
          </Link>
        </Menu.Item>
        <Menu.Item
          disabled={
            subscriptionEnd ||
            !services?.length ||
            !services?.includes("PICKUP")
          }
          onClick={() => {
            Mixpanel.track("ScheduleOrder Screen");
            toggleSideBar();
          }}
          key='scheduleOrder'
          icon={<IoTimeOutline size={20} />}
        >
          <Link to='/scheduleOrder'>
            <Badge
              count={numberOfScheduleOrder}
              style={{ backgroundColor: COLORS.primary }}
              offset={rtl ? [-20, 7] : [14, 7]}
            >
              {intl.formatMessage({ id: "scheduleOrder" })}
            </Badge>
          </Link>
        </Menu.Item>
        <Menu.Item
          disabled={
            subscriptionEnd ||
            !services?.length ||
            !services?.includes("PICKUP")
          }
          onClick={() => {
            Mixpanel.track("iwaiterApp Screen");
            toggleSideBar();
          }}
          key='iwaiterApp'
          icon={<TabletOutlined size={20} />}
        >
          <Link to='/iwaiterApp'>
            <Badge
              count={numberOfPendingIwaiterAppOrders}
              style={{ backgroundColor: COLORS.primary }}
              offset={rtl ? [-20, 7] : [14, 7]}
            >
              {intl.formatMessage({ id: "app" })}
            </Badge>
          </Link>
        </Menu.Item>
        <Menu.Item
          disabled={
            subscriptionEnd ||
            !services?.length ||
            !services?.includes("PICKUP")
          }
          onClick={() => {
            Mixpanel.track("Delivery Screen");
            toggleSideBar();
          }}
          key='delivery'
          icon={<AiOutlineCar size={20} />}
        >
          <Link to='/delivery'>
            <Badge
              count={numberOfOpenDeliveryOrders}
              style={{ backgroundColor: COLORS.primary }}
              offset={rtl ? [-20, 7] : [14, 7]}
            >
              {intl.formatMessage({ id: "delivery" })}
            </Badge>
          </Link>
        </Menu.Item>
        {doesCustomOrderActive ? (
          <Menu.Item
            disabled={
              subscriptionEnd ||
              !services?.length ||
              !services?.includes("PICKUP")
            }
            onClick={() => {
              Mixpanel.track("Custom Order");
              toggleSideBar();
            }}
            key='customOrders'
            icon={<BookOutlined size={20} />}
          >
            <Link to='/customOrders'>
              <Badge
                count={numberOfCustomOrders}
                style={{ backgroundColor: COLORS.primary }}
                offset={rtl ? [-20, 7] : [14, 7]}
              >
                {intl.formatMessage({ id: "customOrders" })}
              </Badge>
            </Link>
          </Menu.Item>
        ) : null}
        <Menu.Item
          disabled={subscriptionEnd || !services?.length}
          onClick={() => {
            Mixpanel.track("Pending Order");
            toggleSideBar();
          }}
          key='pendingPayments'
          icon={<BiErrorAlt size={20} />}
        >
          <Link to='/pendingPayments'>
            <Text
              className='my-font'
              style={{
                fontSize: 13,
              }}
            >
              {intl.formatMessage({ id: "pendingPayments" })}
            </Text>
          </Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        disabled={subscriptionEnd || !services?.length}
        key='Menu'
        icon={<IoFastFoodOutline size={20} />}
        title={intl.formatMessage({ id: "menu" })}
      >
        <Menu.Item
          disabled={subscriptionEnd || !services?.length}
          onClick={() => {
            Mixpanel.track("categories Screen");
            toggleSideBar();
          }}
          key='categories'
          icon={<IoGridOutline size={20} />}
        >
          <Link to='/categories'>
            {intl.formatMessage({ id: "categories" })}
          </Link>
        </Menu.Item>
        <Menu.Item
          disabled={subscriptionEnd || !services?.length}
          onClick={() => {
            Mixpanel.track("Foods Screen");
            toggleSideBar();
          }}
          key='foods'
          icon={<IoFastFoodOutline size={20} />}
        >
          <Link to='/foods'>{intl.formatMessage({ id: "foods" })}</Link>
        </Menu.Item>
        <Menu.Item
          disabled={subscriptionEnd || !services?.length}
          onClick={() => {
            Mixpanel.track("Sort Screen");
            toggleSideBar();
          }}
          key='sort'
          icon={<BiSortAlt2 size={20} />}
        >
          <Link to='/sort'>{intl.formatMessage({ id: "sort" })}</Link>
        </Menu.Item>
        <Menu.Item
          disabled={subscriptionEnd || !services?.length}
          onClick={() => {
            Mixpanel.track("Modifiers Screen");
            toggleSideBar();
          }}
          key='modifiers'
          icon={<IoLogoBuffer size={20} />}
        >
          <Link to='/modifiers'>{intl.formatMessage({ id: "modifiers" })}</Link>
        </Menu.Item>

        <Menu.Item
          disabled={subscriptionEnd || !services?.length}
          onClick={() => {
            Mixpanel.track("Comment Screen");
            toggleSideBar();
          }}
          key='comments'
          icon={<IoChatbubblesOutline size={20} />}
        >
          <Link to='/comments'>
            <Badge
              count={comments?.filter((c) => c?.status === "Pending")?.length}
              style={{ backgroundColor: COLORS.primary }}
              offset={rtl ? [-20, 7] : [14, 7]}
            >
              {intl.formatMessage({ id: "comments" })}
            </Badge>
          </Link>
        </Menu.Item>

        <Menu.Item
          disabled={
            subscriptionEnd ||
            !services?.length ||
            (!services?.includes("PICKUP") &&
              !services?.includes("ORDER_A") &&
              !services?.includes("ORDER_B"))
          }
          onClick={() => {
            Mixpanel.track("Menu engineer Screen");
            toggleSideBar();
          }}
          key='menu-engineer'
          icon={<IoAnalyticsOutline size={20} />}
        >
          <Link to='/menu-engineer'>
            {intl.formatMessage({ id: "menuAnalytics" })}
          </Link>
        </Menu.Item>
      </SubMenu>

      {/* <Menu.Item
        onClick={() => {
          dispatch(changeLanguageAction());
          toggleSideBar();
        }}
        key='language'
        icon={<IoLanguageOutline size={20} />}
      >
        <Text className='my-font'>
          {intl.formatMessage({ id: "oppLanguage" })}
        </Text>
      </Menu.Item> */}
      <Menu.Item
        disabled={subscriptionEnd}
        key='changeUser'
        onClick={() => {
          dispatch(changeUserAction());
          toggleSideBar();
        }}
        icon={<IoLogOutOutline size={20} />}
      >
        <Text disabled={subscriptionEnd} className='my-font'>
          {intl.formatMessage({ id: "changeUser" })}
        </Text>
      </Menu.Item>

      {superUser ? (
        <Menu.Item
          key='changeRestaurant'
          onClick={() => {
            toggleSideBar();
          }}
          icon={<BankOutlined size={20} />}
        >
          <Link to='/all-restaurants'>
            {intl.formatMessage({ id: "changeRestaurant" })}
          </Link>
        </Menu.Item>
      ) : null}
      <Menu.Item
        key='signOut'
        onClick={async () => {
          try {
            await signOutServer();
            Mixpanel.track("Sign out");
            dispatch(logOutAction());
            toggleSideBar();
          } catch (error) {
            dispatch(logOutAction());

            toggleSideBar();
          }
        }}
        icon={<IoLogOut size={20} />}
      >
        <Text className='my-font'>{intl.formatMessage({ id: "signout" })}</Text>
      </Menu.Item>
    </Menu>
  );
};

export default SuperUserSide;
