import React, { useState, useEffect } from "react";
import { Row, Col, Typography, Tabs, Badge } from "antd";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import COLORS from "../../Style/colors";
import ActiveUserTable from "./ActiveUserTable";
import ServedUserTable from "./ServedUserTable";
import { WAITING_LIST_USER_STATUS } from "../../Types";
import { IoArrowBackOutline, IoArrowForwardOutline } from "react-icons/io5";
import { useNavigate } from "react-router";

const { TabPane } = Tabs;
const { Title, Text } = Typography;
const WaitingList = () => {
  const intl = useIntl();
  const history = useNavigate();
  const { rtl } = useSelector((state) => state.settings);
  const { role } = useSelector((state) => state.auth);
  const { branch } = useSelector((state) => state.branch);

  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    if (branch?.waitingArea?.length && activeTab === "") {
      setActiveTab(branch.waitingArea[0]._id);
    }
  }, [branch, branch?.waitingArea]);
  
  return (
    <div>
      {role === "super" ? (
        <div
          style={
            rtl
              ? {
                  position: "absolute",
                  zIndex: 2,
                }
              : {
                  position: "absolute",
                  zIndex: 2,
                }
          }
        >
          <Title level={3} className='pointer' onClick={() => history(-1)}>
            {rtl ? (
              <IoArrowForwardOutline className='pointer' />
            ) : (
              <IoArrowBackOutline className='pointer' />
            )}
          </Title>
        </div>
      ) : null}
      <Row
        style={{
          position: "relative",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <Col span={24}>
          <Title level={2} style={{ textAlign: "center" }}>
            {intl.formatMessage({ id: "waitingList" })}
          </Title>
        </Col>
      </Row>
      <Tabs
        centered
        activeKey={activeTab}
        onTabClick={(e) => {
          setActiveTab(e);
        }}
        type='card'
        style={{ minHeight: "75vh" }}
      >
        {branch?.waitingArea?.map((area) => {
          let activeUsers = branch?.waitingList?.filter(
            (u) =>
              u?.zone === area?.name_en &&
              u?.status === WAITING_LIST_USER_STATUS.ACTIVE
          );
          let servedUsers = branch?.waitingList
            ?.filter(
              (u) =>
                u?.zone === area?.name_en &&
                u?.status === WAITING_LIST_USER_STATUS.SERVED
            )
            .reverse();

          return (
            <TabPane
              key={area?._id}
              style={{
                minHeight: "75vh",
              }}
              tab={
                <Badge
                  count={activeUsers?.length}
                  style={{ backgroundColor: COLORS.primary }}
                >
                  <Text
                    type={area.status === "disabled" ? "danger" : "primary"}
                    className='px-3 my-font'
                  >
                    {area?.name}
                  </Text>
                </Badge>
              }
            >
              <div>
                <ActiveUserTable
                  users={activeUsers}
                  status={area?.status}
                  area={area}
                  selectedArea={activeTab}
                  setActiveTab={setActiveTab}
                />
                <ServedUserTable
                  users={servedUsers}
                  startNumber={activeUsers?.length}
                />
              </div>
            </TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};

export default WaitingList;
