import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import {
    Button, Checkbox, Col,
    Divider, Form, Input,
    Modal,
    Popconfirm, Row, Space,
    Switch, Typography
} from 'antd';
import React, { useState } from 'react'
import { useIntl } from 'react-intl';
import CustomButton from '../../../../Components/CustomButton';
import openNotification from '../../../../Components/Notifications';
import COLORS from '../../../../Style/colors';

const { Text, Title } = Typography
function ReservationReasons({ setReasonsLength, reasonsLength, form, rtl }) {
    const intl = useIntl()
    const [showReservionReasons, setShowReservationReasons] = useState(false);

    const handleConfirm = () => {
        let isWithReasons = form.getFieldValue("isWithReasons")
        let reasons = form.getFieldValue("reasons")
        let error = false
        if (isWithReasons && reasons.length > 0) {
            reasons = reasons.map((reason) => {
                if (!reason.name || !reason.name_en) {
                    error = true
                }
            })
        }
        if (error) {
            openNotification({
                title: intl.formatMessage({ id: "error" }),
                description: intl.formatMessage({ id: "errorOptions" }),
                type: "error",
                rtl,
            });
        } else {
            setShowReservationReasons(false)
        }
    }


    return (
        <div>
            <CustomButton
                type="primary"
                className='mx-2 px-5 border-8 mt-2'
                text={intl.formatMessage({ id: "reasons" })}
                onClick={() =>
                    setShowReservationReasons(true)
                }
            />

            <Modal
                open={showReservionReasons}
                onOk={handleConfirm}
                onCancel={handleConfirm}
                centered
                visible={showReservionReasons}
                footer={[
                    <Button
                      onClick={handleConfirm}
                    >
                      {intl.formatMessage({ id: "save" })}
                    </Button>,
                  ]}
            >
                <Title className='my-font' level={4}>
                    {intl.formatMessage({ id: "reasons" })}
                </Title>
                <Divider />
                <Row type='flex' justify='space-between' align='bottom' style={{ marginBottom: "20px" }}>
                    <Col md={11} sm={24}>
                        <Form.Item
                            style={{
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                            className='mb-1'
                            valuePropName='checked'
                            name='isWithReasons'
                        >
                            <Checkbox>
                                <Text className='my-font'>
                                    {intl.formatMessage({
                                        id: "ReservationReason",
                                    })}
                                </Text>
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col md={11} sm={24}></Col>
                </Row>

                <Row type='flex' justify='space-between' align='bottom'>
                    <Col>
                        <Form.List hasFeedback name='reasons' rules={[{ required: form.getFieldValue("isWithReasons") }]}>
                            {(fields, { add, remove }, { errors }) => {
                                let errorNoOptions = errors?.length > 0;
                                return (
                                    <>
                                        {fields.map(({ key, name, fieldKey, ...restField }) => {
                                            return (
                                                <Space
                                                    key={key}
                                                    style={{ display: "flex", marginBottom: 8, }}
                                                    align='center'
                                                >
                                                    <Form.Item
                                                        {...restField}
                                                        label={intl.formatMessage({ id: "nameAr" })}
                                                        name={[name, "name"]}
                                                        fieldKey={[fieldKey, "name"]}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: intl.formatMessage({ id: "errorname" }),
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            disabled={!form.getFieldValue("isWithReasons")}
                                                            placeholder={intl.formatMessage({ id: "nameAr" })}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        label={intl.formatMessage({ id: "nameEn" })}
                                                        name={[name, "name_en"]}
                                                        fieldKey={[fieldKey, "name_en"]}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: intl.formatMessage({ id: "errorname" }),
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            disabled={!form.getFieldValue("isWithReasons")}
                                                            placeholder={intl.formatMessage({ id: "nameEn" })}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        label={intl.formatMessage({ id: "status" })}
                                                        name={[name, "status"]}
                                                        fieldKey={[fieldKey, "status"]}
                                                        valuePropName='checked'
                                                    >
                                                        <Switch
                                                            disabled={!form.getFieldValue("isWithReasons")}
                                                            style={{ marginBottom: 0 }}
                                                            checkedChildren={
                                                                <p>
                                                                    {intl.formatMessage({
                                                                        id: "active",
                                                                    })}
                                                                </p>
                                                            }
                                                            unCheckedChildren={
                                                                <p>
                                                                    {intl.formatMessage({
                                                                        id: "disabled",
                                                                    })}
                                                                </p>
                                                            }
                                                        />
                                                    </Form.Item>
                                                    <Popconfirm
                                                        disabled={!form.getFieldValue("isWithReasons")}
                                                        title={
                                                            <Text className='my-font'>
                                                                {intl.formatMessage({
                                                                    id: "confirmDeleteChoiceTitle",
                                                                })}
                                                            </Text>
                                                        }
                                                        okText={intl.formatMessage({ id: "delete" })}
                                                        okButtonProps={{
                                                            style: { color: COLORS.danger, fontFamily: "Cairo" },
                                                        }}
                                                        okType='text'
                                                        cancelText={intl.formatMessage({ id: "cancel" })}
                                                        cancelButtonProps={{
                                                            style: { border: "none", fontFamily: "Cairo" },
                                                        }}
                                                        onConfirm={() => {
                                                            setReasonsLength(reasonsLength - 1);
                                                            remove(name);
                                                        }}
                                                    >
                                                        <DeleteOutlined
                                                            style={{ color: COLORS.danger, fontSize: 15 }}
                                                            className='mx-3'
                                                        />
                                                    </Popconfirm>
                                                </Space>
                                            );
                                        })}

                                        <Form.Item>
                                            <Button
                                                disabled={!form.getFieldValue("isWithReasons")}
                                                className='my-font'
                                                type='dashed'
                                                onClick={() => {
                                                    add({});
                                                    setReasonsLength(reasonsLength + 1);

                                                }}
                                                block
                                                style={{
                                                    color: errorNoOptions ? COLORS?.danger : COLORS?.primary,
                                                    borderColor: errorNoOptions ? COLORS?.danger : "#d9d9d9",
                                                }}
                                                icon={<PlusOutlined />}
                                            >
                                                {intl.formatMessage({ id: "add" })}
                                            </Button>
                                        </Form.Item>
                                    </>
                                );
                            }}
                        </Form.List>
                    </Col>
                </Row>
            </Modal>
        </div>
    )
}

export default ReservationReasons