import React from "react";
import { Tag } from "antd";
import { useIntl } from "react-intl";
import { CUSTOM_ORDERS_STATUS, TAG_COLORS_BY_STATUS_CUSTOM } from "../../../Types";

function CustomOrderTag({ status, changeStatus }) {
    const intl = useIntl();
    return (
        <Tag
            onClick={() => changeStatus(status)}
            color={
                status === CUSTOM_ORDERS_STATUS.OPEN
                    ? TAG_COLORS_BY_STATUS_CUSTOM.inKitchen
                    : status === CUSTOM_ORDERS_STATUS.IN_KITCHEN
                        ? TAG_COLORS_BY_STATUS_CUSTOM.ready
                        : status === CUSTOM_ORDERS_STATUS.READY
                            ? TAG_COLORS_BY_STATUS_CUSTOM.open
                            : status === CUSTOM_ORDERS_STATUS.CANCELLED ?
                                TAG_COLORS_BY_STATUS_CUSTOM.cancelled :
                                status === CUSTOM_ORDERS_STATUS.PENDING ?
                                    TAG_COLORS_BY_STATUS_CUSTOM.cancelled
                                    :
                                    ""
            }
            className='my-font px-5 py-1'
        >
            {status === CUSTOM_ORDERS_STATUS.OPEN
                ? intl.formatMessage({ id: "inServiceAction" })
                : status === CUSTOM_ORDERS_STATUS.IN_KITCHEN
                    ? intl.formatMessage({ id: "readyButtonText" })
                    : status === CUSTOM_ORDERS_STATUS.READY
                        ? intl.formatMessage({ id: "closeAction" })
                        : status === CUSTOM_ORDERS_STATUS.PENDING ?
                            intl.formatMessage({ id: "cancel" })
                            : intl.formatMessage({ id: "openAction" })}
        </Tag>
    )
}

export default CustomOrderTag