import {persistCombineReducers} from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import settingsReducer from './settingsReducer';
import authReducer from './authReducer';
import restaurantReducer from './restaurantReducer';
import tableReducer from './tableReducer';
import branchReducer from './branchReducer';
import menuReducer from './menuReducer';
import orderReducer from './orderReducer';
import analyticsReducer from './analyticsReducer';
import campaignReducer from './campaignReducer';


const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['settings', 'auth',  'table', 'branch',  'order', 'analytics', 'campaign' ], 
  blacklist: ['menu', 'order', 'restaurant', 'menu'],
  transforms: [], // TODO: secure some data?
};
const reducers = persistCombineReducers(persistConfig, {
    settings: settingsReducer,
    auth: authReducer,
    restaurant: restaurantReducer,
    table: tableReducer,
    branch: branchReducer,
    menu: menuReducer,
    order: orderReducer,
    analytics: analyticsReducer,
    campaign: campaignReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/logOut') { // check for action type 
    state = {}; // reset state
    storage.removeItem('persist:root');
  }
  return reducers(state, action);
};


export default rootReducer;
