import { Badge, Empty, Result, Tabs, Tag } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import OrderCard from "../../../../Components/Orders/OrderCard";
import { Typography, Row, Col } from "antd";
import { IoArrowBackOutline, IoArrowForwardOutline } from "react-icons/io5";
import {
  DINE_IN_ORDERS_STATUS,
  ORDER_TYPES,
  SUBSCRIPTION_TYPES,
} from "../../../../Types/index";
import COLORS from "../../../../Style/colors";
import CustomButton from "../../../../Components/CustomButton";
import EditTableModal from "./Components/EditTableModal";
import Timer from "../../../../Components/Timer";
import {
  DeleteColumnOutlined,
  DeleteFilled,
  LoadingOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import openNotification from "../../../../Components/Notifications";
import { editTableAction } from "../../../../redux/reducers/tableReducer";
import { clearTableCustomer, postUpdateUserReservation } from "../../../../API/fetch";
import AddCustomerToTableModal from "./Components/AddCustomerToTableModal";
import moment from "moment";
import { setBranchAction } from "../../../../redux/reducers/branchReducer";
import { filterReservationsForTable } from "../../../../helpers/reservations";
const clineURL = "https://app.iwaiter.club";
// const clineURL = "http://localhost:3001";

const { Title, Text, Link } = Typography;
const { TabPane } = Tabs;

const OneTableView = () => {
  const intl = useIntl();
  const params = useParams();
  const history = useNavigate();
  const dispatch = useDispatch();
  const { rtl, isBigScreen } = useSelector((state) => state.settings);
  const { tables } = useSelector((state) => state.table);
  const { restaurant } = useSelector((state) => state.restaurant);
  const { branch } = useSelector((state) => state.branch);
  const { role, waiter, token, services } = useSelector((state) => state.auth);
  const [table, setTable] = useState(null);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [activeTab, setActiveTab] = useState("all");
  const [showEditTable, setShowEditTable] = useState(false);
  const [editToken, setEditToken] = useState("");
  const [showAddCustomer, setShowAddCustomer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allReservations, setAllReservations] = useState([]);
  // Selected table;
  useEffect(() => {
    if (params?.id) {
      const selected = tables.find((t) => t._id === params.id);
      if (selected) {
        setTable(selected);
      }
    }
  }, [params, tables]);

  useEffect(() => {
    if (branch.reservationList?.length) {
      // check if the dateReservation for the first element in the array === date;
      const firstElement = branch.reservationList[0];
      const reservationDate = moment(firstElement.reservationDate).startOf(
        "day"
      );
      const dateSelected = moment().startOf("day");
      if (reservationDate.isSame(dateSelected, "day")) {
        // sort by reservationDate;
        const sorted = [...branch?.reservationList].sort((a, b) => {
          return new Date(a?.reservationDate) - new Date(b?.reservationDate);
        }).filter(res => !res?.isDone);
        // console.log(branch.reservationList)
        // check for app reservation and customer reservation;
        // let usersWithAppUsers = sorted.map((user) => {
        //   if (user?.appUserId) {
        //     return {
        //       ...user,
        //       customerId: user.appUserId,
        //     };
        //   }
        //   return user;
        // });
        const result = sorted.filter(reservation => {
          if (reservation?.bookedTableId) {
            return table?.numberOfChairs >= (reservation?.childCount + reservation?.personsCount) &&
              table?.zone === tables?.find(el => el?._id === reservation?.bookedTableId)?.zone;
          } else {
            return true;
          }
        });

        setAllReservations(result);
      }
    }
  }, [branch, table]);

  useEffect(() => {
    if (token) {
      let edit = token.slice(0, 7) + "SnM" + token.slice(7);
      setEditToken(edit);
    }
  }, [token]);

  // Check all orders;
  useEffect(() => {
    if (table?.orders?.length) {
      if (activeTab === "all") {
        setFilteredOrders(table.orders);
      } else {
        // setActiveTab(activeTab);
        editOrdersFiltered(activeTab, table);
      }
    }
  }, [table]);
  // on filter change
  const editOrdersFiltered = useCallback(
    (status, tableObject) => {
      if (status === "all") {
        setFilteredOrders(tableObject?.orders);
      } else {
        setFilteredOrders(
          tableObject?.orders?.filter((order) => order.status === status)
        );
      }
    },
    [activeTab]
  );


  const clearCustomerId = async () => {
    try {
      // setLoading(true);
      const res = await clearTableCustomer(table._id);
      if (table?.reservationId) {
        const date = new Date()
        const dateFormat = moment(date);
        const data = {
          reservation: {
            isDone: true,
            date: dateFormat,
          }
        }
        const result = await postUpdateUserReservation(table?.reservationId, data)
        const newBranch = {
          ...branch,
          reservationList: result.data.reservationList,
        };
        dispatch(setBranchAction(newBranch));
      }
      if (res?.data?.table) {
        const oldTable = tables.find(
          (table) => table._id === res?.data?.table?._id
        );
        if (oldTable) {
          const newTable = {
            ...oldTable,
            reservationId: null,
            customerId: null,
            customerCanSetForMinutes: 0,
          };
          dispatch(editTableAction(newTable));
        }
      }
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setLoading(false);
      history(-1);
    } catch (error) {
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        type: "error",
        rtl,
      });
      console.log(error);
    }
  };
  return (
    <div>
      {/* Modals */}
      <EditTableModal
        show={showEditTable}
        hide={() => {
          setShowEditTable(false);
        }}
        table={table}
      />
      <AddCustomerToTableModal
        show={showAddCustomer}
        hide={() => {
          setShowAddCustomer(false);
        }}
        table={table}
        reservations={allReservations}
      />
      <Row
        style={{
          position: "relative",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <Title
          level={3}
          className='pointer'
          onClick={() => history(-1)}
          style={{ position: "absolute", zIndex: 1 }}
        >
          {rtl ? (
            <IoArrowForwardOutline className='pointer' />
          ) : (
            <IoArrowBackOutline className='pointer' />
          )}
        </Title>

        <Col span={24}>
          <Title level={2} style={{ textAlign: "center" }}>
            {rtl ? table?.name : table?.name_en}
          </Title>
        </Col>
        <CustomButton
          type='link'
          text={intl.formatMessage({
            id: isBigScreen ? "editTableInfo" : "edit",
          })}
          disabled={!waiter?.canEditTables && role !== "super"}
          onClick={() => setShowEditTable(true)}
          style={
            rtl
              ? { position: "absolute", zIndex: 1, left: 0, top: 5 }
              : { position: "absolute", zIndex: 1, right: 0, top: 5 }
          }
        />
      </Row>
      {role === "super" ? null : !services?.includes("ORDER_A") &&
        !services?.includes("ORDER_B") ? null : (
        <Row
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 10,
          }}
        >
          <Link
            href={
              restaurant?.domain == "iWaiter"
                ? `${clineURL}/${restaurant?.slug}/${branch?._id}/${table?._id}?is_W=true`
                : `${restaurant?.domain}/${branch?._id}/${table?._id}?is_W=true`
            }
          >
            <Text className='my-font'>
              {intl.formatMessage({
                id: "makeOrder",
              })}
            </Text>
          </Link>
        </Row>
      )}
      {table?.customerId?._id || table?.customerCanSetForMinutes > 0 ? (
        <Row
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 10,
          }}
        >
          {table?.customerId?._id ? (
            <Text className='my-font'>
              {intl.formatMessage({ id: "customerName" })}:
              <Tag className='mx-1' style={{ borderRadius: 4 }}>
                {table?.customerId?.name}
              </Tag>
            </Text>
          ) : null}
          <Text className='my-font'>
            {intl.formatMessage({ id: "sittingTime" })}:
            <Tag className='mx-1' style={{ borderRadius: 4 }}>
              <Timer
                className='mt-4'
                callQueuedTime={table?.startSeTime ?? new Date()}
              />
            </Tag>
          </Text>
          <Tag
            color={loading ? COLORS.gray : COLORS.primary}
            className='px-3 my-font pointer'
            style={{ borderRadius: 4 }}
            onClick={loading ? true : clearCustomerId}
            icon={loading ? <LoadingOutlined /> : <DeleteFilled />}
          >
            {intl.formatMessage({ id: "remove" })}
          </Tag>
        </Row>
      ) : (
        // Add customer Timer;
        <Row
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 10,
          }}
        >
          <Text className='my-font'>
            {intl.formatMessage({ id: "addTime" })}:
          </Text>

          <Tag
            color={loading ? COLORS.gray : COLORS.primary}
            className='px-3 my-font pointer mx-1'
            style={{ borderRadius: 4 }}
            onClick={loading ? () => true : () => setShowAddCustomer(true)}
            icon={loading ? <LoadingOutlined /> : <PlusCircleOutlined />}
          >
            {intl.formatMessage({ id: "addAction" })}
          </Tag>
        </Row>
      )}
      {table?.orders?.length ? (
        <Tabs
          activeKey={activeTab}
          centered
          type='card'
          onTabClick={(e) => {
            setActiveTab(e);
            editOrdersFiltered(e, table);
          }}
          style={{ minHeight: "75vh" }}
        >
          {Object.values(DINE_IN_ORDERS_STATUS)
            .filter((s) => s !== "close")
            .map((status, i) => {
              let count = 0;
              if (status === DINE_IN_ORDERS_STATUS.ALL) {
                count = table?.orders?.length;
              } else {
                count = table?.orders?.filter(
                  (order) => order.status === status
                )?.length;
              }
              return (
                <TabPane
                  animated={true}
                  key={`${status}`}
                  style={{
                    minHeight: "75vh",
                    marginTop: "-16px",
                  }}
                  tab={
                    <Badge
                      count={count}
                      style={{ backgroundColor: COLORS.primary }}
                    >
                      <span className='px-3'>
                        {intl.formatMessage({ id: status })}
                      </span>
                    </Badge>
                  }
                >
                  {filteredOrders?.length ? (
                    filteredOrders.map((order) => {
                      return (
                        <OrderCard
                          key={order._id}
                          order={order}
                          type={ORDER_TYPES.DINE_IN}
                        />
                      );
                    })
                  ) : (
                    //  no orders in this status
                    <Row
                      style={{
                        minHeight: "50vh",
                        backgroundColor: COLORS.white,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Badge.Ribbon
                        style={{ marginTop: 0 }}
                        text={
                          <span>
                            {intl.formatMessage({
                              id: "noOrdersAtAll",
                            })}{" "}
                            <span
                              style={{
                                backgroundColor: COLORS.lightBlue,
                                color: COLORS.primary,
                                borderRadius: 3,
                              }}
                              className='px-3'
                            >
                              {" "}
                              {intl.formatMessage({ id: status })}
                            </span>
                          </span>
                        }
                      >
                        <Empty
                          image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
                          imageStyle={{
                            height: 250,
                          }}
                          description={<span />}
                        />
                      </Badge.Ribbon>
                    </Row>
                  )}
                </TabPane>
              );
            })}
        </Tabs>
      ) : (
        <Result
          status='404'
          className='my-font'
          title={
            role === "super"
              ? intl.formatMessage({ id: "singInAsWaiter" })
              : intl.formatMessage({ id: "noOrdersAtAll" })
          }
          subTitle={
            role === "super" ? "" : intl.formatMessage({ id: "noOrders" })
          }
        />
      )}
    </div>
  );
};

export default OneTableView;
