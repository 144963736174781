import React from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Typography, Checkbox, Table, Space, Input } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import { IoAddOutline, IoRemoveOutline } from "react-icons/io5";
import openNotification from "../../../../Components/Notifications";
import { arrUpdateByKeyValue } from "../../../../helpers/array";
import { getTotalReservationModifiers } from "../../../../helpers/modifiers";
import COLORS from "../../../../Style/colors";

const { Text } = Typography;
const { TextArea } = Input;
function ReservationModifiers({
    modifier,
    selectedOptions, setSelectedOptions,
    setTotalModifiers
}) {
    const { multiSelection, maxSelection, name, name_en, options, _id } =
        modifier;

    const intl = useIntl();
    const { rtl } = useSelector((state) => state.settings);
    const { restaurant } = useSelector((state) => state.restaurant);
    const [data, setData] = useState([]);

    useEffect(() => {
        const allCelles = document.querySelectorAll("thead > tr >.ant-table-cell");
        // change the background color of the table cells;
        if (allCelles?.length) {
            setTimeout(() => {
                allCelles.forEach((cell) => {
                    cell.style.border = "none";
                    cell.style.borderBottom = `2px ${COLORS?.gray} solid`;
                    cell.style.fontSize = `16px`;
                    cell.style.fontWeight = `600`;
                });
            }, 0);
        }
    }, [COLORS]);

    useEffect(() => {
        setData(
            options?.map((option) => {
                let selected = false;
                let initialQuantity = 1;
                if (selectedOptions[modifier?._id]) {
                    let foundOption = selectedOptions[modifier?._id]?.options?.find(
                        (item) => item?._id === option?._id
                    );
                    if (foundOption) {
                        selected = true;
                        initialQuantity = foundOption?.initialQuantity;
                    }
                }
                return {
                    key: option?._id,
                    _id: option?._id,
                    name: option?.name,
                    name_en: option?.name_en,
                    price: option?.price,
                    initialQuantity,
                    quantity: option?.quantity,
                    enableQuantity: option?.enableQuantity,
                    enableNote: option?.enableNote,
                    noteIsRequired: option?.noteIsRequired,
                    selected,
                    modifierId: _id,
                };
            })
        );
        // change the background color for the th inside the table header;
        const th = document.querySelectorAll(".ant-table-thead > tr > th");
        th.forEach((item) => {
            item.style.backgroundColor = "#fff";
            item.style.borderColor = "#ccc";
        });
    }, [modifier]);

    //Get the final total of modifiers
    useEffect(() => {
        setTotalModifiers(getTotalReservationModifiers(selectedOptions));
    }, [selectedOptions]);

    //update the selected options in case of handleQuantity ( we use it inside handle quantity)
    const updateSelectedOptions = (newRecord, record) => {
        const selected = selectedOptions?.[_id];
        const updateSelectedOptions = arrUpdateByKeyValue({
            arr: selected?.options,
            key: "key",
            value: record?.key,
            newElement: newRecord,
        });
        setSelectedOptions({
            ...selectedOptions,
            [_id]: {
                selected: selected?.selected,
                options: updateSelectedOptions,
            },
        });
    };
    //update the quantity of selected options + / -
    const handleQuantity = (record, type) => {
        if (type === "ADD") {
            if (record?.initialQuantity < record?.quantity) {
                //update record
                const newRecord = {
                    ...record,
                    initialQuantity: record?.initialQuantity + 1,
                };
                const updateRecord = arrUpdateByKeyValue({
                    arr: data,
                    key: "key",
                    value: record?.key,
                    newElement: newRecord,
                });
                setData(updateRecord);
                //update selectedOptions
                updateSelectedOptions(newRecord, record);
            }
        } else {
            if (record?.initialQuantity > 1) {
                const newRecord = {
                    ...record,
                    initialQuantity: record?.initialQuantity - 1,
                };
                const updateRecord = arrUpdateByKeyValue({
                    arr: data,
                    key: "key",
                    value: record?.key,
                    newElement: newRecord,
                });
                setData(updateRecord);
                //update selectedOptions
                updateSelectedOptions(newRecord, record);
            }
        }
        return;
    };
    //update the status of checked option (we use it inside handleClick)
    const updateCheck = (option, status, isNotMulti) => {
        //update data with the key : selected become from true to false
        const newRecord = { ...option, selected: status };
        let newData = data;
        if (isNotMulti) {
            newData = data.map((data) => {
                return {
                    ...data,
                    selected: false,
                };
            });
        }
        const updateRecord = arrUpdateByKeyValue({
            arr: newData,
            key: "key",
            value: option?.key,
            newElement: newRecord,
        });
        setData(updateRecord);
    };
    //handle click on checked option
    const handleClick = (e, optionId, option) => {
        const { checked } = e?.target;
        if (checked) {
            //add to selected
            // check if selectedOption has a key of the modifier id;
            if (!selectedOptions?.[_id]) {
                // if not, create a new key for the modifier id;
                setSelectedOptions({
                    ...selectedOptions,
                    [_id]: {
                        selected: [optionId],
                        options: [option],
                    },
                });
                updateCheck(option, checked, false);
                return;
            }
            // add the option id to the selected array;
            // check if multiSelection is true;
            if (!multiSelection) {
                // if not, clear the selected array;
                setSelectedOptions({
                    ...selectedOptions,
                    [_id]: {
                        selected: [optionId],
                        options: [option],
                    },
                });
                updateCheck(option, checked, true);
                return;
            }
            if (selectedOptions?.[_id]?.selected?.length < maxSelection) {
                // if not, add the option id to the selected array;
                setSelectedOptions({
                    ...selectedOptions,
                    [_id]: {
                        selected: [...selectedOptions?.[_id]?.selected, optionId],
                        options: [...selectedOptions?.[_id]?.options, option],
                    },
                });
                updateCheck(option, checked, false);
            } else {
                // show notification max selection error;
                openNotification({
                    type: "error",
                    description: `${intl.formatMessage({
                        id: "errorMax",
                    })} ${maxSelection ? maxSelection : "1"} ${intl.formatMessage({
                        id: "choice",
                    })}`,
                    rtl,
                });
            }
        } else {
            if (!multiSelection) {
                // if not, clear the selected array;
                const copy = JSON.parse(JSON.stringify(selectedOptions));
                delete copy[_id];
                setSelectedOptions(copy);
                updateCheck(option, checked, false);
                return;
            }
            const newSelected = selectedOptions?.[_id]?.selected?.filter(
                (item) => item !== optionId
            );
            const newOption = selectedOptions?.[_id]?.options?.filter(
                (item) => item?._id !== optionId
            );
            if (newSelected?.length) {
                setSelectedOptions({
                    ...selectedOptions,
                    [_id]: {
                        selected: newSelected,
                        options: newOption,
                    },
                });
                updateCheck(option, checked, false);
            } else {
                // delete the key if the selected array is empty;
                const copy = JSON.parse(JSON.stringify(selectedOptions));
                delete copy[_id];
                setSelectedOptions(copy);
                updateCheck(option, checked, false);
            }
        }
    };

    let columns = [
        {
            title: <Text className='my-font'>{rtl ? name : name_en}</Text>,
            dataIndex: "name",
            render: (text, record) => {
                return {
                    props: {
                        style: {
                            borderColor: COLORS.gray,
                            borderBottom: `0.1px ${COLORS.gray} solid`,
                        },
                    },
                    children: (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                            }}
                        >
                            <div>
                                <Checkbox
                                    className='mx-1'
                                    checked={record?.selected}
                                    onChange={(e) => {
                                        handleClick(e, record?._id, record);
                                    }}
                                />
                            </div>
                            <div>
                                <Text
                                    className='my-font-light'
                                >
                                    {rtl ? text : record?.name_en}{" "}
                                </Text>
                            </div>
                        </div>
                    ),
                };
            },
            width: "50%",
        },
        {
            title: (
                <Text className='my-font'>
                    {intl.formatMessage({ id: "quantity" })}
                </Text>
            ),
            dataIndex: "name",
            render: (text, record) => {
                return {
                    props: {
                        style: {
                            borderColor: COLORS.gray,
                            borderBottom: `0.1px ${COLORS.gray} solid`,
                        },
                    },
                    children: (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                            }}
                        >
                            {record?.enableQuantity ? (
                                <Space direction='horizontal' style={{ alignItems: "center" }}>
                                    <div
                                        className='circle-bg'
                                        style={{
                                            backgroundColor:
                                                record?.initialQuantity === record?.quantity
                                                    ? COLORS?.danger
                                                    : COLORS?.primary,
                                            display: "flex",
                                            cursor: "pointer"
                                        }}
                                    >
                                        <IoAddOutline
                                            size={15}
                                            color={COLORS?.white}
                                            onClick={() => {
                                                if (record?.selected) handleQuantity(record, "ADD");
                                            }}
                                        />
                                    </div>
                                    <Text
                                        style={{
                                            fontSize: "1.2rem",
                                            fontWeight: "300",
                                            color: COLORS?.primary,
                                            position: "relative",
                                            top: -2,
                                        }}
                                        className='my-font'
                                    >
                                        {record?.initialQuantity}
                                    </Text>
                                    <div
                                        className='circle-bg'
                                        style={{
                                            backgroundColor:
                                                record?.initialQuantity === 1
                                                    ? COLORS.danger
                                                    : COLORS?.lightBlue,
                                            display: "flex",
                                            cursor: "pointer"
                                        }}
                                    >
                                        <IoRemoveOutline
                                            color={COLORS.white}
                                            size={15}
                                            onClick={() => {
                                                if (record?.selected) handleQuantity(record, "REMOVE");
                                            }}
                                        />
                                    </div>
                                </Space>
                            ) : (
                                <Space direction='horizontal' style={{ alignItems: "center" }}>
                                    <div>
                                        <IoRemoveOutline
                                            color={COLORS?.primary}
                                            size={15}
                                        />
                                    </div>
                                </Space>
                            )}
                        </div>
                    ),
                };
            },
        },
        {
            title: (
                <Text className='my-font'>
                    {multiSelection
                        ? `${intl.formatMessage({ id: "choose" })} ${maxSelection ? maxSelection : ""
                        }`
                        : intl.formatMessage({ id: "selectOne" })}
                </Text>
            ),
            align: "left",
            dataIndex: "price",
            render: (text, record) => {
                return {
                    props: {
                        style: {
                            borderColor: COLORS.gray,
                            borderBottom: `0.1x ${COLORS.gray} solid`,
                        },
                    },
                    children: (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                            }}
                        >
                            <div>
                                <Text
                                    className='my-font-light'
                                >
                                    {parseFloat(record?.price) === 0
                                        ? " "
                                        : `+ ${parseFloat(
                                            record?.price * record?.initialQuantity
                                        ).toFixed(2)} ${restaurant?.currency}`}
                                </Text>
                            </div>
                        </div>
                    ),
                };
                // return ;
            },
        },
    ];
    return (
        <div style={{ marginBottom: 25 }}>
            <Table
                pagination={false}
                bordered={false}
                columns={columns}
                dataSource={data}
            />
            {/* Note for options */}
            {selectedOptions?.[_id]?.options?.length &&
                selectedOptions?.[_id]?.options?.some((o) => o?.enableNote === true) ? (
                <div style={{ marginTop: 20 }}>
                    <Text className='my-font'


                    >
                        {selectedOptions?.[_id]?.options?.some(
                            (o) => o?.noteIsRequired === true
                        ) ? (
                            <span style={{ color: COLORS?.danger }}>*</span>
                        ) : null}{" "}
                        {intl.formatMessage({ id: "note" })} -{" "}
                        {
                            selectedOptions?.[_id]?.options?.find(
                                (o) => o?.enableNote === true
                            )?.name
                        }
                    </Text>
                    <TextArea
                        placeholder={intl.formatMessage({ id: "note" })}
                        value={selectedOptions?.[_id]?.note}
                        onChange={(e) => {
                            const copy = JSON.parse(JSON.stringify(selectedOptions));
                            copy[_id].note = e.target.value;
                            setSelectedOptions(copy);
                        }}
                        style={{
                            width: "100%",
                            marginTop: 5,
                            borderRadius: 5,
                            border: `1px solid ${selectedOptions?.[_id]?.options?.some(
                                (o) => o?.noteIsRequired === true
                            )
                                ? COLORS?.danger
                                : COLORS.text
                                }`,
                        }}

                    />
                </div>
            ) : null}
        </div>
    );
}

export default ReservationModifiers;
