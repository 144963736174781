import React, { useEffect, useState } from 'react';
import {Typography} from 'antd'
import moment from 'moment'
const {Text} = Typography;
const Timer = ({ callQueuedTime, type="", className='', setCustomerInfo, table, customerInfo }) => {
  // const [time, setTime] = useState(() => new Date().getTime());
  const [text, setText] = useState('')
  useEffect(() => {
    const queuedTime = new Date(callQueuedTime).getTime();
    const intervalId = setInterval(function () {
      // setTime(new Date().getTime() - queuedTime);
      setText(millisToMinutesAndSeconds(new Date().getTime() - queuedTime))
      if(customerInfo?._id){
        let name = table?.customerId?.name;
        let phone = table?.customerId?.phone;
        // check how many time that customer has been here;
        let startTime = moment(table?.startSeTime);
        //customerCanSetForMinutes
        let endTime = moment(startTime).add(
          table?.customerCanSetForMinutes,
          "minutes"
        );
        let now = moment();
        let diff = endTime.diff(now, "minutes");
        // get the remaining time;
        let isTimeAboutToEnd = diff <= 10 && diff > 0;
        let isTimeEnded = diff <= 0;
        let isTimeNotStarted = startTime.isAfter(now);
        setCustomerInfo({
          name,
          phone,
          isTimeEnded,
          isTimeAboutToEnd,
          startTime,
          isTimeNotStarted,
          _id: 'customerInfo',
        });
      }
    }, 1000);
    return ()=>{
      clearInterval(intervalId);
    }
  }, [callQueuedTime]);
  const millisToMinutesAndSeconds =(millis) => {
    let minutes = Math.floor(millis / 60000);
    let seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
  }
  return <Text type={type} className={className}>{text ? text : ''}</Text>;
};

export default Timer;