import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import COLORS from "../../Style/colors";
import {
  Alert,
  Col,
  Divider,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import CustomButton from "../../Components/CustomButton";
import { useNavigate } from "react-router-dom";
import { postUpdateBranch } from "../../API/fetch";
import {
  editBranchInAllBranchAction,
  setBranchAction,
} from "../../redux/reducers/branchReducer";
import openNotification from "../../Components/Notifications";

const { Title, Text } = Typography;
function TextAds() {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const { allBranches } = useSelector((state) => state.branch);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(null);

  const onFinish = async (values, isDelete) => {
    try {
      setLoading(true);
      const data = {
        branch: {
          adMessage: {
            ar: values?.text,
            en: values?.text_en,
            adType: values?.adType,
          },
        },
      };
      if (isDelete) {
        data.branch.adMessage = {
          ar: "",
          en: "",
        };
      }

      const res = await postUpdateBranch(selectedBranch._id, data);
      const newBrach = {
        ...selectedBranch,
        ...data.branch,
      };
      dispatch(setBranchAction(newBrach));
      dispatch(editBranchInAllBranchAction(newBrach));
      if (isDelete) {
        form.resetFields();
      }
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
    } catch (e) {
      console.log(e);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    } finally {
      setLoading(false);
    }
  };

  const deleteMessage = async () => {
    return onFinish(null, true);
  };

  return (
    <div>
      <div
        style={{
          marginBottom: 20,
        }}
      >
        <Space direction='vertical' size='small'>
          <Title level={4}>{intl.formatMessage({ id: "textAds" })}</Title>
          <CustomButton
            text={intl.formatMessage({ id: "goBack" })}
            type='text'
            onClick={() => {
              navigate(-1);
            }}
            style={{
              borderRadius: 6,
            }}
          />
        </Space>
      </div>

      <div
        style={{
          backgroundColor: COLORS.white,
          borderRadius: 6,
          width: "92%",
          marginRight: "auto",
          marginLeft: "auto",
          padding: 15,
        }}
        className='light-shadow'
      >
        <Form
          form={form}
          layout='vertical'
          onFinish={onFinish}
          initialValues={{
            branchId: "",
            adType: "success",
          }}
        >
          {() => {
            return (
              <>
                <Title
                  level={5}
                  style={{
                    marginBottom: 30,
                  }}
                >
                  * {intl.formatMessage({ id: "textAdsDes" })}.
                </Title>
                <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "branchName" })}
                      name='branchId'
                      rules={[
                        {
                          required: true,
                          message: intl.formatMessage({ id: "required" }),
                        },
                      ]}
                    >
                      <Select
                        className='my-font'
                        onChange={(value) => {
                          const s = allBranches.find(
                            ({ branch }) => branch._id === value
                          );
                          if (s?.branch?._id) {
                            setSelectedBranch(s?.branch);
                            if (s?.branch?.adMessage) {
                              form.setFieldsValue({
                                text: s?.branch?.adMessage?.ar,
                                text_en: s?.branch?.adMessage?.en,
                              });
                            } else {
                              form.setFieldsValue({
                                text: "",
                                text_en: "",
                              });
                            }
                          } else {
                            setSelectedBranch(null);
                          }
                        }}
                      >
                        {allBranches.map(({ branch }) => (
                          <Select.Option
                            value={branch._id}
                            key={branch._id}
                            className='my-font'
                          >
                            {rtl ? branch?.name : branch?.name_en}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "adType" })}
                      name='adType'
                      rules={[
                        {
                          required: true,
                          message: intl.formatMessage({ id: "required" }),
                        },
                      ]}
                    >
                      <Select
                        className='my-font'
                        onChange={(value) => {
                          console.log(value);
                        }}
                      >
                        {["success", "info", "warning", "error"].map((type) => (
                          <Select.Option
                            value={type}
                            key={type}
                            className='my-font'
                          >
                            {intl.formatMessage({ id: type })}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  className='mt-3'
                  gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
                >
                  {/* Cart messgate AR */}
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "textMessage" })}
                      className='mb-1'
                      name='text'
                      style={{ borderRadius: 20 }}
                      rules={[
                        {
                          required: true,
                          message: intl.formatMessage({ id: "required" }),
                        },
                      ]}
                    >
                      <Input maxLength={200} />
                    </Form.Item>
                  </Col>
                  {/* time to block users */}
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "textMessage_en" })}
                      className='mb-1'
                      name='text_en'
                      style={{ borderRadius: 20 }}
                      rules={[
                        {
                          required: true,
                          message: intl.formatMessage({ id: "required" }),
                        },
                      ]}
                    >
                      <Input maxLength={200} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  style={{
                    justifyContent: "center",
                    marginTop: 20,
                  }}
                >
                  <Col span={12}>
                    <Alert
                      type={form.getFieldValue("adType")}
                      message={form.getFieldValue("text")}
                      className='my-font'
                    />
                  </Col>
                </Row>
                <Divider />
                <Form.Item>
                  <Popconfirm
                    title={
                      <Space direction='vertical'>
                        <Text className='my-font'>
                          {intl.formatMessage({
                            id: "confirmDelete",
                          })}
                        </Text>
                      </Space>
                    }
                    description={intl.formatMessage({
                      id: "confirmDelete",
                    })}
                    okText={intl.formatMessage({ id: "delete" })}
                    okButtonProps={{
                      style: { color: COLORS.danger, fontFamily: "Cairo" },
                    }}
                    okType='text'
                    cancelText={intl.formatMessage({ id: "cancel" })}
                    cancelButtonProps={{
                      style: { border: "none", fontFamily: "Cairo" },
                    }}
                    onConfirm={deleteMessage}
                    disabled={!selectedBranch?.adMessage?.ar || loading}
                  >
                    <Text
                      disabled={!selectedBranch?.adMessage?.ar || loading}
                      type={"danger"}
                      className='pointer my-font'
                    >
                      {intl.formatMessage({ id: "delete" })}
                    </Text>
                  </Popconfirm>
                </Form.Item>
                <Row>
                  <Col>
                    <CustomButton
                      text={intl.formatMessage({ id: "save" })}
                      loading={loading}
                      disabled={loading}
                      htmlType='submit'
                      type='primary'
                      style={{
                        backgroundColor: COLORS.purple,
                        color: COLORS.white,
                        borderRadius: 6,
                        width: 100,
                      }}
                    />
                  </Col>
                </Row>
              </>
            );
          }}
        </Form>
      </div>
    </div>
  );
}

export default TextAds;
