import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19.624}
    height={8.913}
    {...props}
  >
    <g data-name="Group 5">
      <path
        d="M11.342 0a9.933 9.933 0 0 0-7.959 4A14.311 14.311 0 0 0 0 .68c.228 1.224 1.171 2.486 1.171 3.776S.228 7.008 0 8.232a14.292 14.292 0 0 0 3.383-3.32 9.934 9.934 0 0 0 7.959 4 9.946 9.946 0 0 0 8.282-4.457 9.947 9.947 0 0 0-8.282-4.456"
        fill={props?.fill || "#0a2240"}
      />
      <path
        d="M16.537 3.532a.771.771 0 0 1-.771.771.771.771 0 0 1-.771-.771.771.771 0 0 1 .771-.771.771.771 0 0 1 .771.771"
        fill="#fff"
      />
    </g>
  </svg>
)

export default SvgComponent
