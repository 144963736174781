import { Descriptions, Divider, Spin, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import CustomButton from "../../../Components/CustomButton";
import openNotification from "../../../Components/Notifications";
import { GoSell } from "@tap-payments/gosell";
import { useDispatch, useSelector } from "react-redux";
import apiUrl from "../../../API/config";
import { verifyCampaignPayment, sendCampaign } from "../../../API/fetch";
import { updateCampaignAction } from "../../../redux/reducers/campaignReducer";

const { Title, Text } = Typography;
function EditCampaigns({}) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { rtl } = useSelector((state) => state.settings);
  const { campaigns } = useSelector((state) => state.campaign);
  const { user } = useSelector((state) => state.auth);
  const { restaurant } = useSelector((state) => state.restaurant);
  const [loading, setLoading] = useState(false);
  const [campaign, setCampaign] = useState(null);
  const [showPayment, setShowPayment] = useState(false);
  // http://localhost:3000/campaigns/653e52483a5bdb2e565b9b1b?token=653f8f15ef57b91c5d7964bf&mode=popup&tap_id=chg_TS05A2920231410Tr453010595
  const tap_id = searchParams.get("tap_id");
  console.log("tap_id", tap_id);
  // leten for the tap_id;
  useEffect(() => {
    if (tap_id) {
      setLoading(true);
      verifyPayment(tap_id);
    }
  }, [tap_id]);
  useEffect(() => {
    const campaign = campaigns.find((c) => c._id === id);
    if (campaign) {
      setCampaign(campaign);
    }
  }, [campaigns, id]);
  const send = async () => {
    try {
      setLoading(true);
      // try to get the payment;
      if (campaign?.status === "pending") {
        setTimeout(() => {
          setShowPayment(true);
          GoSell.openLightBox();
        }, 1000);
      } else if (campaign?.status === "ready") {
        const res = await sendCampaign(campaign._id);
        if (res?.data?.status === "success") {
          dispatch(updateCampaignAction(res?.data?.campaign));
          openNotification({
            type: "success",
            title: intl.formatMessage({ id: "savedSuccessfully" }),
            rtl,
          });
        }
        setLoading(false);
      }
    } catch (error) {
      openNotification({
        type: "error",
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
      });
      setLoading(false);
    }
  };

  const getVatAmount = (amount) => {
    if (typeof amount !== "number") {
      parseFloat(amount);
    }
    if (isNaN(amount)) {
      return 0;
    }
    return amount * 0.15;
  };

  const callbackFunc = (response) => {
    console.log("response", response);
    if (response?.callback?.status === "CAPTURED") {
      verifyPayment(response.callback.id);
    }
  };

  const verifyPayment = async (paymentId) => {
    setLoading(true);
    try {
      const res = await verifyCampaignPayment(paymentId, restaurant._id);
      if (res?.data?.status) {
        dispatch(updateCampaignAction(res?.data?.campaign));

        openNotification({
          type: "success",
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          rtl,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      openNotification({
        type: "error",
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        rtl: rtl,
      });
    }
  };

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Spin spinning={loading && !showPayment}>
        <div
          style={{
            width: "100%",
            marginBottom: 10,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Title level={3}>{campaign?.name}</Title>
          <CustomButton
            text={intl.formatMessage({ id: "goBack" })}
            className={`btnRegister btn-text border-8  mx-5`}
            onClick={() => {
              navigate("/campaigns");
            }}
            type='secondary'
          />
        </div>

        <div
          style={{
            backgroundColor: "white",
            padding: "10px 20px 20px",
            borderRadius: 7,
            marginBottom: 20,
          }}
          className='light-shadow'
        >
          <Descriptions
            title={intl.formatMessage({ id: "campaignDetails" })}
            bordered
            className='my-font'
            column={4}
          >
            <Descriptions.Item
              label={intl.formatMessage({ id: "campaignsName" })}
              span={2}
            >
              {campaign?.name}
            </Descriptions.Item>
            <Descriptions.Item
              label={intl.formatMessage({ id: "message" })}
              span={2}
            >
              {campaign?.message}
            </Descriptions.Item>
            <Descriptions.Item
              label={intl.formatMessage({ id: "status" })}
              span={2}
            >
              <Tag
                style={{
                  borderRadius: 7,
                }}
                color={campaign?.status === "pending" ? "orange" : "green"}
              >
                {" "}
                {intl.formatMessage({ id: campaign?.status ? campaign.status === "sent" ?"done" : campaign?.status :  "N/A" })}
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item
              label={intl.formatMessage({ id: "senderName" })}
              span={2}
            >
              {campaign?.senderName}
            </Descriptions.Item>

            <Descriptions.Item
              label={intl.formatMessage({ id: "smsCount" })}
              span={2}
            >
              {campaign?.countOfMessages}
            </Descriptions.Item>
            <Descriptions.Item
              label={intl.formatMessage({ id: "users" })}
              span={2}
            >
              {campaign?.numbers?.length}
            </Descriptions.Item>
            <Descriptions.Item
              label={intl.formatMessage({ id: "cost" })}
              span={2}
            >
              {campaign?.cost} SAR
            </Descriptions.Item>
            <Descriptions.Item
              label={intl.formatMessage({ id: "vat" })}
              span={2}
            >
              {getVatAmount(campaign?.cost)} SAR
            </Descriptions.Item>
            <Descriptions.Item
              label={intl.formatMessage({ id: "total" })}
              span={4}
              labelStyle={{
                fontWeight: "bold",
              }}
            >
              <Text className='my-font' style={{ fontWeight: "bold" }}>
                {" "}
                {getVatAmount(campaign?.cost) + campaign?.cost} SAR
              </Text>
            </Descriptions.Item>
          </Descriptions>
          <Divider />
          {campaign?.status !== 'sent' ?
          <CustomButton
            text={
              campaign?.status === "pending"
                ? intl.formatMessage({ id: "pay" })
                : `${intl.formatMessage({ id: "send" })}`
            }
            className={`btnRegister btn-text border-8  mx-5`}
            onClick={send}
            type='primary'
            loading={loading}
          /> : <>
            {/* show numbers */}
            
            <Descriptions title={intl.formatMessage({ id: "users" })} bordered className='my-font' column={1}>
              {campaign?.numbers?.map((number, index) => (
                <Descriptions.Item
                  label={index + 1}
                  span={1}
                  key={index}
                >
                  {number}
                </Descriptions.Item>
              ))}
            </Descriptions>
          </>}
          {showPayment ? (
            <GoSell
              gateway={{
                publicKey: "pk_test_LKFviPZCJUSlNQwnDRO2rVMB",
                merchantId: "5721647",
                language: rtl ? "ar" : "en",
                contactInfo: false,
                supportedCurrencies: "all",
                supportedPaymentMethods: [
                  "MADA",
                  "VISA",
                  "MASTERCARD",
                  "APPLE_PAY",
                ],
                saveCardOption: true,
                customerCards: false,
                notifications: "standard",
                backgroundImg: {
                  url: "https://iwaiter.net/assets/iw-Logo.png",
                  opacity: "0.5",
                },
                callback: callbackFunc,
                onClose: (e) => {
                  setLoading(false);

                  console.log("onClose", e);
                  setShowPayment(false);
                },
                labels: {
                  cardNumber: intl.formatMessage({ id: "cardNumber" }),
                  expirationDate: "MM/YY",
                  cvv: "CVV",
                  cardHolder: intl.formatMessage({ id: "cardName" }),
                  actionButton: intl.formatMessage({ id: "pay" }),
                },
              }}
              customer={{
                first_name: user && user.name ? user.name : "No name",
                //  middle_name: new Date().toLocaleTimeString(),
                //  last_name: "Last Name",
                email: restaurant?.email
                  ? restaurant.email
                  : "ana.meen.game@gmail.com",
                phone: {
                  country_code: "966",
                  number:
                    user && user.phone ? user.phone.substring(3) : "500937188",
                },
              }}
              order={{
                amount: campaign.cost + getVatAmount(campaign.cost),
                currency: "SAR",
                items: [
                  {
                    id: `SMS Marketing for ${restaurant.name}`,
                    name: campaign.name,
                    description: `Send ${campaign.countOfMessages} SMS to ${campaign.numbers.length} users`,
                    quantity: 1,
                    amount_per_unit: campaign.cost,
                    total_amount: campaign.cost,
                  },
                ],
                shipping: null,
                taxes: null,
              }}
              transaction={{
                mode: "charge",
                charge: {
                  saveCard: false,
                  threeDSecure: true,
                  description: `Payment online for SMS Marketing for ${restaurant.name}`,
                  metadata: {
                    userId: user._id,
                    userPhone: user.phone,
                    campaignId: campaign._id,
                    restaurantId: restaurant._id,
                  },
                  receipt: {
                    email: false,
                    sms: true,
                  },
                  redirect: `${window.location.origin}${pathname}`,
                  // post: `${apiUrl}/campaigns-payment/${campaign._id}`,
                  post: `https://api.iwaiter.club/api/v1/campaigns-payment/${campaign._id}`,
                },
              }}
            />
          ) : null}
        </div>
      </Spin>
    </div>
  );
}

export default EditCampaigns;
