import {
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { openSeatsTileTypes } from "../EditReservationSchemaForm";
import { getZonesForLinkToTable } from "../../../../helpers/reservations";

const { Text, Title } = Typography;
const { Option } = Select;

function ReservationGeneral({ form, rtl, area }) {
  const intl = useIntl();
  const { zones } = useSelector((state) => state.table);
  const { branch } = useSelector((state) => state.branch);
  const [availableZones, setAvailableZone] = useState([]);

  useEffect(() => {
    if (area) {
      setAvailableZone(
        getZonesForLinkToTable(
          branch?.reservationSchema?.filter((res) => res._id !== area._id),
          zones
        )
      );
    } else {
      setAvailableZone(
        getZonesForLinkToTable(branch?.reservationSchema, zones)
      );
    }
  }, [branch, zones, area]);

  return (
    <>
      <Divider orientation={rtl ? "right" : "left"}>
        <Title className='my-font' level={5}>
          {intl.formatMessage({ id: "basicInfo" })}
        </Title>
      </Divider>
      <Row type='flex' justify='space-between'>
        <Col md={11} sm={24}>
          <Form.Item
            label={intl.formatMessage({ id: "nameAr" })}
            className='mb-1'
            name='name'
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: "errorname" }),
              },
            ]}
          >
            <Input className={"input"} />
          </Form.Item>
        </Col>
        <Col md={11} sm={24}>
          <Form.Item
            label={intl.formatMessage({ id: "nameEn" })}
            className='mb-1'
            name='name_en'
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: "errorname" }),
              },
            ]}
          >
            <Input className={"input"} />
          </Form.Item>
        </Col>
      </Row>
      <Row type='flex' justify='space-between'>
        <Col md={11} sm={24}></Col>
      </Row>
      <Row type='flex' justify='space-between'>
        <Col md={11} sm={24}>
          <Form.Item
            label={intl.formatMessage({ id: "openSeatsEvery" })}
            rules={[
              { required: true, message: "Please add numbers of minutes" },
              // only numbers
              {
                pattern: /^[0-9]*$/,
                message: "0-9 only",
              },
              // make sure it is bigger than 0 and less that 1441;
              ({ getFieldValue }) => ({
                validator(_, value) {
                  // console.log(form.getFieldValue("isHourSelection"))
                  if (form.getFieldValue("isHourSelection")) {
                    // make sure the value is multiple of 60;
                    if (
                      parseInt(value) > 0 &&
                      parseInt(value) < 1441 &&
                      parseInt(value) % 60 === 0
                    ) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        new Error(
                          intl.formatMessage({
                            id: "openSeatsEveryLinkTableError",
                          })
                        )
                      );
                    }
                  } else if (parseInt(value) > 0 && parseInt(value) < 1441) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Please enter a number between 1 and 1440")
                  );
                },
              }),
            ]}
            className='mb-1'
            name='openSeatsEvery'
            tooltip={
              <Text className='my-font'>
                {intl.formatMessage({ id: "openSeatsEveryTooltip" })}
              </Text>
            }
          >
            <Input
              type='number'
              addonAfter={intl.formatMessage({ id: "minute" })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row type='flex' justify='space-between'>
        <Col md={11} sm={24}></Col>
      </Row>
      <Row type='flex' justify='space-between'>
        <Col md={11} sm={24}>
          <Form.Item
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
            className='mb-1 mt-3'
            valuePropName='checked'
            name='isCountPerPerson'
            onChange={(e) => {
              if (e.target.checked) {
                form.setFieldsValue({
                  maxUsers: 1,
                  maxReservations: 0,
                  isLinkTables: false,
                  isCountPerReservation: false,
                  maxChairs: 12,
                  linkedZones: [],
                  isHourSelection: false,
                  hourPrice: 0,
                });
              } else {
                form.setFieldsValue({
                  maxReservations: 0,
                  maxUsers: 1,
                  onlyOneReservationADay: false,
                  isCountPerReservation: true,
                });
              }
            }}
          >
            <Checkbox>
              <Text className='my-font'>
                {intl.formatMessage({ id: "EnabeleIsCountPerPerson" })}
              </Text>
            </Checkbox>
          </Form.Item>
        </Col>
        <Col md={11} sm={24}>
          <Form.Item
            label={intl.formatMessage({ id: "maxUsersForDay" })}
            className='mb-1'
            name='maxUsers'
            tooltip={
              <Text className='my-font'>
                {form.getFieldValue("maxUsers")}
                {intl.formatMessage({ id: "oneReservation" })}{" "}
                {intl.formatMessage({ id: "every" })}{" "}
                {form.getFieldValue("openSeatsEvery")}
                {intl.formatMessage({ id: "minute" })}
              </Text>
            }
          >
            <InputNumber
              style={{
                width: "100%",
              }}
              className={"input"}
              min={0}
              disabled={
                form.getFieldValue("isCountPerReservation") ||
                form.getFieldValue("isLinkTables")
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row type='flex' justify='space-between'>
        <Col md={11} sm={24}>
          <Form.Item
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
            className='mb-1 mt-3'
            valuePropName='checked'
            name='isCountPerReservation'
            onChange={(e) => {
              if (e.target.checked) {
                form.setFieldsValue({
                  maxUsers: 0,
                  maxReservations: 1,
                  isLinkTables: false,
                  isCountPerPerson: false,
                  maxChairs: 12,
                  linkedZones: [],
                  isHourSelection: false,
                  hourPrice: 0,
                });
              } else {
                form.setFieldsValue({
                  maxReservations: 0,
                  maxUsers: 1,
                  onlyOneReservationADay: false,
                  isCountPerPerson: true,
                });
              }
            }}
          >
            <Checkbox>
              <Text className='my-font'>
                {intl.formatMessage({ id: "EnabeleIsCountPerReservation" })}
              </Text>
            </Checkbox>
          </Form.Item>
        </Col>
        <Col md={11} sm={24}>
          <Form.Item
            label={intl.formatMessage({ id: "maxReservationForDay" })}
            className='mb-1'
            name='maxReservations'
            tooltip={
              <Text className='my-font'>
                {form.getFieldValue("maxReservations")}{" "}
                {intl.formatMessage({ id: "reservations" })}{" "}
                {intl.formatMessage({ id: "every" })}{" "}
                {form.getFieldValue("openSeatsEvery")}
                {intl.formatMessage({ id: "minute" })}
              </Text>
            }
          >
            <InputNumber
              style={{
                width: "100%",
              }}
              className={"input"}
              min={0}
              disabled={
                !form.getFieldValue("isCountPerReservation") ||
                form.getFieldValue("isLinkTables")
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row type='flex' justify='space-between' align='bottom'>
        <Col md={11} sm={24}>
          <Form.Item
            label={intl.formatMessage({ id: "maxChairs" })}
            tooltip={
              <Text className='my-font'>
                {intl.formatMessage({ id: "maxChairsTooltip" })}
              </Text>
            }
            className='mb-1'
            name='maxChairs'
          >
            <InputNumber
              style={{
                width: "100%",
              }}
              className={"input"}
              min={0}
              disabled={form.getFieldValue("isLinkTables")}
            />
          </Form.Item>
        </Col>
        <Col md={11} sm={24}>
          <Form.Item
            label={intl.formatMessage({ id: "minChairs" })}
            tooltip={
              <Text className='my-font'>
                {intl.formatMessage({ id: "minChairsTooltip" })}
              </Text>
            }
            className='mb-1'
            name='minUsers'
          >
            <InputNumber
              style={{
                width: "100%",
              }}
              className={"input"}
              min={1}
            />
          </Form.Item>
        </Col>
      </Row>
      {/* Links Tables PARAMS */}
      <Row type='flex' justify='space-between' align='center'>
        <Col md={11} sm={24}>
          <Form.Item
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
            className='mb-1 mt-3'
            valuePropName='checked'
            name='isLinkTables'
            onChange={(e) => {
              if (e.target.checked) {
                form.setFieldsValue({
                  isCountPerReservation: false,
                  isCountPerPerson: false,
                  minUsers: 1,
                  maxReservations: 0,
                  isCountPerReservation: 0,
                  maxUsers: 0,
                  maxChairs: 0,
                });
              } else {
                form.setFieldsValue({
                  isCountPerReservation: false,
                  isCountPerPerson: true,
                  minUsers: 1,
                  isCountPerReservation: 0,
                  maxUsers: 1,
                  linkedZones: [],
                  maxChairs: 12,
                  isHourSelection: false,
                });
              }
            }}
          >
            <Checkbox disabled={!availableZones?.length > 0}>
              <Tooltip
                title={
                  availableZones?.length > 0
                    ? ""
                    : intl.formatMessage({ id: "linkTableUnavailable" })
                }
                placement={"bottom"}
                mouseEnterDelay={0.5}
              >
                <Text className='my-font'>
                  {intl.formatMessage({ id: "linkToTables" })}
                </Text>
              </Tooltip>
            </Checkbox>
          </Form.Item>
        </Col>
        <Col md={11} sm={24}>
          <Form.Item
            label={intl.formatMessage({ id: "applyToZones" })}
            className='mb-1'
            rules={[
              {
                required: form.getFieldValue("isLinkTables"),
                message: intl.formatMessage({ id: "errorLinkTables" }),
              },
            ]}
            name='linkedZones'
            tooltip={
              <Text className='my-font'>
                {intl.formatMessage({ id: "applyToZonesToolTip" })}
              </Text>
            }
          >
            <Select
              className='my-font'
              mode='multiple'
              disabled={!form.getFieldValue("isLinkTables")}
            >
              {availableZones?.map((zone) => (
                <Option key={zone?.name} value={zone?.name} className='my-font'>
                  {zone?.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {/* End link Tables */}
    </>
  );
}

export default ReservationGeneral;
