import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Table,
  Popconfirm,
  Tag,
  Select,
  Button,
  Space,
  Input,
} from "antd";
import CustomButton from "../../Components/CustomButton";
import { useIntl } from "react-intl";
import COLORS from "../../Style/colors";
import openNotification from "../../Components/Notifications";
import { getPDFCouponFile, postStopCoupon, postStopMultiCoupons } from "../../API/fetch";
import {
  DeleteOutlined,
  EditTwoTone,
  EditOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import AddCoupon from "./Components/AddCoupon";
import moment from "moment";
import { editCouponAction } from "../../redux/reducers/restaurantReducer";
import EditCoupon from "./Components/EditCoupon";
import AddRandomCoupons from "./Components/AddRandomCoupons";
import EditGroupCoupons from "./Components/EditGroupCoupons";
import PrintCodsModal from "./Components/PrintCodsModal";

const { Title, Text } = Typography;
const { Search } = Input;
const PromoCods = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const { waiter } = useSelector((state) => state.auth);
  const { coupons, restaurant, design } = useSelector(
    (state) => state.restaurant
  );
  const [formattedCoupons, setFormattedCoupon] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAddCoupon, setShowAddCoupon] = useState(false);
  const [showEditCoupon, setShowEditCoupon] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [couponStatus, setCouponStatus] = useState("Active");
  const [couponType, setCouponType] = useState("couponCode");
  const [showAddRandomCoupons, setShowAddRandomCoupons] = useState(false);
  const [showEditGroup, setShowEditGroup] = useState(false);
  const [showPrintCodes, setShowPrintCodes] = useState(false);
  const [allCouponTypes, setAllCouponTypes] = useState([
    {
      value: "couponCode",
      label: `${intl.formatMessage({ id: "justCouponCode" })}`,
    },
    {
      value: "reservation",
      label: `${intl.formatMessage({ id: "reservation" })}`,
    },
  ]);

  useEffect(() => {
    if (coupons.length) {
      let newCoupons = [];
      if (couponType === "couponCode") {
        newCoupons = coupons.filter(
          (coupon) =>
            !coupon?.name?.includes("Coupon for reservation") &&
            !coupon?.groupId
        );
      } else if (couponType === "reservation") {
        newCoupons = coupons.filter(
          (coupon) =>
            coupon?.name?.includes("Coupon for reservation") && !coupon?.groupId
        );
      } else {
        // filter group id;
        newCoupons = coupons?.filter(
          (coupon) =>
            coupon?.groupId
              ?.substring(coupon.groupId?.length - 5)
              ?.toUpperCase() === couponType
        );
      }

      const format = newCoupons?.map((coupon) => {
        let status = coupon.status === "deleted"
          ? "deleted"
          : coupon.status === "canceled"
            ? "canceled"
            : new Date() > new Date(moment(coupon.endDate))
              ? "expiredCoupon"
              : "Active";
        if (status === 'Active' && coupon?.totalUsed >= coupon?.numberOfUsed && coupon?.totalUsed > 0 && coupon?.numberOfUsed !== 0) {
          status = "used"
        }
        return {
          name: coupon.name,
          status: status,
          key: coupon._id,
          code: coupon.code,
          percentage: coupon.percentage,
          maximumAmount: coupon.maximumAmount,
          startDate: moment(coupon.startDate).format("DD/MM/YYYY"),
          endDate: moment(coupon.endDate).format("DD/MM/YYYY"),
          totalUsed: coupon.totalUsed,
          numberOfUsed: coupon.numberOfUsed ?? "-",
          couponInfo: { ...coupon },
        };
      });
      const filteredFormat = format?.filter(
        (coupon) => coupon?.status === couponStatus
      );
      // get all coupons that have group id;
      const allGroupId = coupons
        ?.filter((coupon) => coupon?.groupId)
        ?.map((coupon) =>
          coupon?.groupId?.substring(coupon.groupId?.length - 5)?.toUpperCase()
        );
      // add allGroupId to allCouponTypes;
      const newAllCouponTypes = [...allCouponTypes];
      if (allGroupId?.length) {
        allGroupId?.forEach((groupId) => {
          if (!newAllCouponTypes?.find((type) => type?.value === groupId)) {
            const groupName = coupons?.find(
              (coupon) =>
                coupon?.groupId
                  ?.substring(coupon.groupId?.length - 5)
                  ?.toUpperCase() === groupId
            )?.name;

            newAllCouponTypes.push({
              value: groupId,
              label: `${intl.formatMessage({ id: "group" })} ${groupName}`,
            });
          }
        });
      }
      setAllCouponTypes(newAllCouponTypes);

      setFormattedCoupon(filteredFormat);
    } else {
      setFormattedCoupon([]);
    }
  }, [coupons, couponStatus, couponType]);

  // Cancel or delete coupon;
  const cancelCoupon = async (id, type) => {
    setLoading(true);
    const data = {
      coupon: {
        status: type === "DELETE" ? "deleted" : "canceled",
      },
      code: waiter.code,
    };
    try {
      const res = await postStopCoupon(id, data);
      if (res.data.status === "success") {
        dispatch(editCouponAction(res.data.coupon));
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };
  // qr code;
  const getPdfCoupon = async ({ terms, showPrice }) => {
    try {
      setLoading(true);

      const data = {
        codes: formattedCoupons?.map((coupon) => coupon?.code),
        name: restaurant?.name,
        imageURL: restaurant?.logo?.[0]?.url,
        backgroundColor: design?.primaryButtonBG,
        terms,
        showPrice,
        price: formattedCoupons?.[0]?.maximumAmount,
      };
      const res = await getPDFCouponFile(data);
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${restaurant?.name_en}-qr.pdf`);
      document.body.appendChild(link);
      link.click();
      setLoading(false);
      setShowPrintCodes(false);
    } catch (error) {
      console.log(error);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
      setLoading(false);
    }
  };

  const onSearch = (value) => {
    // search by coupon name;
    // or search by group id;
    // search by coupon code;
    const newCoupons = coupons?.filter(
      (coupon) =>
        coupon?.name?.toLowerCase()?.includes(value?.toLowerCase()) ||
        coupon?.groupId
          ?.substring(coupon.groupId?.length - 5)
          ?.toUpperCase()
          ?.includes(value?.toUpperCase()) ||
        coupon?.code?.toLowerCase()?.includes(value?.toLowerCase())
    );
    setFormattedCoupon(
      newCoupons?.map((coupon) => {
        let status = coupon.status === "deleted"
          ? "deleted"
          : coupon.status === "canceled"
            ? "canceled"
            : new Date() > new Date(moment(coupon.endDate))
              ? "expiredCoupon"
              : "Active";
        if (status === 'Active' && coupon?.totalUsed >= coupon?.numberOfUsed && coupon?.totalUsed > 0 && coupon?.numberOfUsed !== 0) {
          status = "used"
        }
        return {
          name: coupon.name,
          status: status,
          key: coupon._id,
          code: coupon.code,
          percentage: coupon.percentage,
          maximumAmount: coupon.maximumAmount,
          startDate: moment(coupon.startDate).format("DD/MM/YYYY"),
          endDate: moment(coupon.endDate).format("DD/MM/YYYY"),
          totalUsed: coupon.totalUsed,
          numberOfUsed: coupon.numberOfUsed ?? "-",
          couponInfo: { ...coupon },
        };
      })
    );
  }

  //disable multicoupon 
  const disabledMultiCoupons = async () => {
    setLoading(true);
    try {
      const couponIds = formattedCoupons.map(el => el.key)
      const data = {
        ids: couponIds,
        code: waiter.code
      }
      const res = await postStopMultiCoupons(data)
      if (res.data.status === "success") {
        const coupons = res?.data?.coupons
        for (let i = 0; i < coupons.length; i++) {
          dispatch(editCouponAction(coupons[i]))
        }
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  }

  const columns = [
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: 100,
      render: (name, record) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Text>{name}</Text>
            {record?.couponInfo?.groupId ? (
              <Tag>
                {record?.couponInfo?.groupId
                  ?.substring(record?.couponInfo?.groupId?.length - 5)
                  ?.toUpperCase()}
              </Tag>
            ) : null}
          </div>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "status" }),
      dataIndex: "status",
      key: "status",
      fixed: "left",
      width: 100,
      render: (status, record) => {
        let statusString = record.status;
        const today = new Date();
        if (
          status === "Active" &&
          today < new Date(record.couponInfo.startDate)
        ) {
          statusString = "notStarted";
        }
        if (
          record?.totalUsed >= record?.numberOfUsed &&
          record?.totalUsed !== 0
          && record?.numberOfUsed !== 0
        ) {
          statusString = "used";
        }
        return (
          <Tag color={statusString === "Active" ? "green" : "red"}>
            {intl.formatMessage({ id: `${statusString}` })}
          </Tag>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "justCode" }),
      dataIndex: "code",
      key: "code",
    },
    {
      title: intl.formatMessage({ id: "percentage" }),
      dataIndex: "percentage",
      key: "percentage",
    },
    {
      title: intl.formatMessage({ id: "maximumAmount" }),
      dataIndex: "maximumAmount",
      key: "maximumAmount",
    },
    {
      title: intl.formatMessage({ id: "startDate" }),
      dataIndex: "startDate",
      key: "startDate",
    },
    {
      title: intl.formatMessage({ id: "endDate" }),
      dataIndex: "endDate",
      key: "endDate",
    },
    {
      title: intl.formatMessage({ id: "totalUsed" }),
      dataIndex: "totalUsed",
      key: "totalUsed",
    },
    {
      title: intl.formatMessage({ id: "MaxNumberOfUsed" }),
      dataIndex: "numberOfUsed",
      key: "numberOfUsed",
    },
    {
      title: intl.formatMessage({ id: "edit" }),
      dataIndex: "edit",
      render: (status, record) => {
        let isActive = true;
        if (record.status === "deleted") {
          isActive = false;
        }
        return (
          <>
            {isActive ? (
              <EditTwoTone
                onClick={() => {
                  setSelectedCoupon(record?.couponInfo);
                  setShowEditCoupon(true);
                  setTimeout(() => {
                    setShowEditCoupon(true);
                  }, 200);
                }}
                className={"pointer"}
              />
            ) : (
              <EditOutlined
                style={{
                  color: "#dddddd",
                  fontSize: 16,
                }}
              />
            )}
          </>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "delete" }),
      dataIndex: "delete",
      render: (status, record) => {
        let isActive = true;
        if (record.status === "deleted") {
          isActive = false;
        }
        return (
          <Popconfirm
            title={
              <Text className='my-font'>
                {intl.formatMessage({
                  id: "confirmDeleteCouponTitle",
                })}
              </Text>
            }
            disabled={!isActive}
            okText={intl.formatMessage({ id: "delete" })}
            okButtonProps={{
              style: { color: COLORS.danger, fontFamily: "Cairo" },
            }}
            okType='text'
            cancelText={intl.formatMessage({ id: "cancel" })}
            cancelButtonProps={{
              style: { border: "none", fontFamily: "Cairo" },
            }}
            onConfirm={() => {
              cancelCoupon(record.couponInfo._id, "DELETE");
            }}
          >
            <DeleteOutlined
              style={{
                color: isActive ? COLORS.danger : "#dddddd",
                fontSize: 15,
              }}
              className='mx-3'
              disabled={!isActive}
            />
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      {/* Modals */}
      <PrintCodsModal
        show={showPrintCodes}
        hide={() => {
          setShowPrintCodes(false);
        }}
        coupon={formattedCoupons[0]}
        print={getPdfCoupon}
        loading={loading}
      />
      <AddCoupon
        show={showAddCoupon}
        hide={() => {
          setShowAddCoupon(false);
        }}
      />
      <AddRandomCoupons
        show={showAddRandomCoupons}
        hide={() => {
          setShowAddRandomCoupons(false);
        }}
      />
      <EditCoupon
        show={showEditCoupon}
        coupon={selectedCoupon}
        hide={() => {
          setShowEditCoupon(false);
          setSelectedCoupon(null);
        }}
      />
      {/* Edit group codes */}
      <EditGroupCoupons
        show={showEditGroup}
        hide={() => {
          setShowEditGroup(false);
          // setSelectedGroup(null);
        }}
        couponType={couponType}
        allCoupons={formattedCoupons}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <Title>{intl.formatMessage({ id: "couponCodes" })}</Title>
        <Space size='middle'>
          <CustomButton
            text={intl.formatMessage({
              id: "addCoupon",
            })}
            type='primary'
            className='border-8'
            onClick={() => setShowAddCoupon(true)}
          />
          <CustomButton
            text={intl.formatMessage({
              id: "addRandomCoupons",
            })}
            type='primary'
            className='border-8'
            onClick={() => {
              setShowAddRandomCoupons(true);
            }}
          />
        </Space>
      </div>
      <div
        style={{ maxWidth: "100%", marginRight: "auto", marginLeft: "auto" }}
      >
        <Text className='my-font'>
          {intl.formatMessage({ id: "couponCodesDes" })}
        </Text>
      </div>
      <div
        style={{
          backgroundColor: COLORS.white,
          marginTop: 40,
          width: "92%",
          marginRight: "auto",
          marginLeft: "auto",
          borderRadius: 10,
          padding: "20px 15px",
        }}
        className='light-shadow'
      >
        <div
          style={{
            marginBottom: "1rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Space className="mt-2">
            <Text className='my-font'>
              {intl.formatMessage({ id: "coupouStatus" })}
            </Text>
            <Select
              // style={{ width: "150px", margin: "0 1rem" }}
              placeholder={intl.formatMessage({ id: "coupouStatus" })}
              defaultValue={couponStatus}
              onChange={(e) => setCouponStatus(e)}
              className='my-font'
              options={[
                {
                  value: "Active",
                  label: `${intl.formatMessage({
                    id: "active",
                  })} / ${intl.formatMessage({ id: "notStarted" })}`,
                },
                {
                  value: "expiredCoupon",
                  label: intl.formatMessage({ id: "expiredCoupon" }),
                },
                {
                  value: "canceled",
                  label: intl.formatMessage({ id: "CANCELLED" }),
                },
                {
                  value: "deleted",
                  label: intl.formatMessage({ id: "deleted_Reservation" }),
                },
                {
                  value: "used",
                  label: intl.formatMessage({ id: "used" }),
                },
              ]}
            />
          </Space>
          <Space className="mt-2">
            <Text className='my-font mx-3'>
              {intl.formatMessage({ id: "couponType" })}
            </Text>
            <Select
              // style={{ width: "150px" }}
              className='my-font'
              placeholder={intl.formatMessage({ id: "couponType" })}
              defaultValue={couponType}
              onChange={(e) => setCouponType(e)}
              options={allCouponTypes}
            />
          </Space>
          {/* Add search coupon */}
          <Space className="mt-2">
            <Search
              placeholder={intl.formatMessage({ id: "search" })}
              onSearch={onSearch}
            />
          </Space>

          <Space className="mt-2">
            {couponType !== "reservation" && couponType !== "couponCode" ? (
              <>
                <Popconfirm
                  title={
                    <Text className='my-font'>
                      {intl.formatMessage({ id: "confirmDeleteMultiCoupon" })}
                    </Text>
                  }
                  okText={intl.formatMessage({ id: "disable" })}
                  okButtonProps={{
                    style: { color: COLORS.danger, fontFamily: "Cairo" },
                  }}
                  okType='text'
                  cancelText={intl.formatMessage({ id: "cancel" })}
                  cancelButtonProps={{
                    style: { border: "none", fontFamily: "Cairo" },
                  }}
                  onConfirm={disabledMultiCoupons}
                  loading={loading}

                >
                  <Text
                    type='danger'
                    className='pointer my-font'
                    disabled={loading || couponStatus !== "Active" || !formattedCoupons.length ? true : false}
                  >
                    {`${intl.formatMessage({
                      id: "disable",
                    })} ${intl.formatMessage({ id: "group" })}`}
                  </Text>
                </Popconfirm>
                <CustomButton
                  type='secondary'
                  className={"mx-2"}
                  text={`${intl.formatMessage({
                    id: "edit",
                  })} ${intl.formatMessage({ id: "group" })}`}
                  onClick={() => setShowEditGroup(true)}
                />
                <CustomButton
                  type='secondary'
                  className={"border-8"}
                  text={`${intl.formatMessage({ id: "download" })}`}
                  icon={<DownloadOutlined />}
                  disabled={formattedCoupons.length === 0}
                  onClick={() => {
                    setShowPrintCodes(true);
                  }}
                />
              </>
            ) : null}
          </Space>
        </div>
        <Table
          columns={columns}
          style={{ borderRadius: 10 }}
          loading={loading}
          dataSource={formattedCoupons}
          scroll={{ x: 1100 }}
          pagination={{
            position: ["bottomLeft"],
            pageSizeOptions: [10, 25, 50, 100, 200, 500],
          }}
        />
      </div>
    </div>
  );
};

export default PromoCods;
