import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  InputNumber,
  DatePicker,
  Typography,
  Divider,
  Space,
  Row,
  Col,
  Switch,
  Table,
  Checkbox,
  Select,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import CustomButton from "../../../Components/CustomButton";
import openNotification from "../../../Components/Notifications";
import moment from "moment";
import { getOrderCoupon, patchCoupon } from "../../../API/fetch";
import { editCouponAction } from "../../../redux/reducers/restaurantReducer";
import { ORDER_TYPES } from "../../../Types";

const { Text } = Typography;
const { Option } = Select;

const EditCoupon = ({ show, hide, coupon }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const { waiter } = useSelector((state) => state.auth);
  const { categories, foods } = useSelector((state) => state.menu);

  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        form.resetFields();
      }, 200);
      getAllOrdersWithThisCoupon();
    }
  }, [show]);

  const getAllOrdersWithThisCoupon = async () => {
    try {
      setLoading(true);
      const res = await getOrderCoupon(coupon._id);
      if (res.data.orders) {
        let formattedOrders = res.data.orders.map((order) => {
          let orderNumber = order.orderNumber;
          if (order?.type === ORDER_TYPES.DINE_IN) {
            orderNumber = `H ${order.orderNumber}`
          } else if (order?.type === ORDER_TYPES.PICKUP) {
            orderNumber = `P ${order.orderNumber}`
          } else if (order?.type === ORDER_TYPES.DELIVERY) {
            orderNumber = `D ${order.orderNumber}`
          }
          let status = 'failed';
          if (order?.paymentStatus === 'paid') {
            status = 'paid'
          } else if (order?.paymentStatus === 'notPaid') {
            status = 'notPaid'
          }
          return {
            key: order._id,
            orderNumber: status === 'failed' ? '--' : orderNumber,
            orderDate: moment(new Date(order.createdAt)).format("DD/MM/YYYY"),
            total: order?.total?.toFixed(2),
            paymentStatus: intl.formatMessage({ id: status }),
          };
        });
        setOrders(formattedOrders);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
      setLoading(false);
    }
  };

  const editCoupon = async (values) => {
    try {
      let endDate = moment(values.endDate).endOf("day").toISOString();
      let status = "Active";
      if (!values?.status) {
        status = "canceled";
      }
      setLoading(true);
      const data = {
        coupon: { ...values, endDate, status },
        code: waiter.code,
      };
      const res = await patchCoupon(coupon._id, data);
      if (res.data.coupon) {
        dispatch(editCouponAction(res.data.coupon));
      }
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setLoading(false);
      form.setFieldsValue({});
      hide();
    } catch (error) {
      if (error?.response?.data?.error === "duplicated") {
        setLoading(false);
        form.setFields([
          {
            name: "code",
            errors: [intl.formatMessage({ id: "duplicatedCode" })],
          },
        ]);
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "duplicatedCode" }),
          type: "error",
          rtl,
        });
      } else {
        setLoading(false);
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "errorfetch" }),
          type: "error",
          rtl,
        });
      }
    }
  };

  function disabledStartDate(current) {
    // Can not select days before today and today
    return current && current < moment().startOf("day");
  }
  function disabledEndDate(current) {
    // Can not select days before today and today
    let endDate = form.getFieldValue("startDate");
    return current && current <= moment(endDate).startOf("day");
  }

  const columns = [
    {
      title: intl.formatMessage({ id: "orderNumber" }),
      dataIndex: "orderNumber",
      key: "orderNumber",
    },
    {
      title: intl.formatMessage({ id: "date" }),
      dataIndex: "orderDate",
      key: "orderDate",
    },
    {
      title: intl.formatMessage({ id: "total" }),
      dataIndex: "total",
      key: "total",
    },
    {
      title: intl.formatMessage({ id: "paymentStatus" }),
      dataIndex: "paymentStatus",
      key: "paymentStatus",
    },
  ];

  return (
    <Modal
      title={
        <p className='my-font'>{intl.formatMessage({ id: "editCoupon" })}</p>
      }
      centered
      visible={show}
      destroyOnClose={true}
      mask={true}
      getContainer={false}
      maskClosable={true}
      onCancel={() => {
        hide();
        form.setFieldsValue({});
      }}
      footer={null}
    >
      <Form
        name='Edit-Coupon'
        form={form}
        preserve={false}
        className='form-container'
        layout='vertical'
        onFinish={editCoupon}
        initialValues={
          coupon
            ? {
              ...coupon,
              startDate: moment(coupon?.startDate),
              endDate: moment(coupon?.endDate),
              status:
                coupon.status === "canceled" ||
                  coupon.status === "deleted" ||
                  new Date() > new Date(moment(coupon.endDate))
                  ? false
                  : true,
            }
            : {}
        }
      >
        {() => {
          return (
            <>
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                {/* Names */}
                <Col md={24} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "couponName" })}
                    className='mb-1'
                    tooltip={
                      <Text type='primary' className='my-font'>
                        {intl.formatMessage({ id: "explainCouponName" })}
                      </Text>
                    }
                    name='name'
                    style={{ borderRadius: 20 }}
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "errorname" }),
                      },
                    ]}
                  >
                    <Input
                      className={"input"}
                      style={{ borderRadius: 5, width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                <Col md={24} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "couponCode" })}
                    className='mb-1'
                    name='code'
                    tooltip={
                      <Text type='primary' className='my-font'>
                        {intl.formatMessage({ id: "explainCouponCode" })}
                      </Text>
                    }
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "errorname" }),
                      },
                    ]}
                  >
                    <Input
                      disabled={true}
                      className={"input"}
                      style={{ borderRadius: 5 }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                <Col md={24} sm={24} xs={24}>
                  <Form.Item
                    className='mb-3 mt-3'
                    name='isCouponForItems'
                    valuePropName='checked'
                  >
                    <Checkbox
                      disabled={true}
                      onChange={(e) => {
                        form.setFieldsValue({
                          foods: [],
                          categories: []
                        });
                      }}
                    >
                      {intl.formatMessage({ id: "isCouponForItems" })}{" "}
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                <Col md={24} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "applyForCategories" })}
                    className='mb-1'
                    name='couponCategories'
                  >
                    <Select
                      disabled={true}
                      filterOption={(input, option) => {
                        return (
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                      mode='multiple' className='my-font'>
                      {categories.map((category, i) => {
                        return (
                          <Option
                            className='my-font'
                            key={`${category.categoryId}${i}`}
                            value={category.categoryId}
                          >
                            {rtl ? category.name : category.name_en}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={24} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "applyForFood" })}
                    className='mb-1'
                    name='couponfoods'
                  >
                    <Select
                      disabled={true}
                      filterOption={(input, option) => {
                        return (
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                      mode='multiple'
                      className='my-font'
                    >
                      {foods.map((food, i) => {
                        return (
                          <Option
                            className='my-font'
                            key={`${food.foodId}${i}`}
                            value={food.foodId}
                          >
                            {rtl ? food.name : food.name_en}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "percentage" })}
                    className='mb-1'
                    name='percentage'
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "errorname" }),
                      },
                    ]}
                  >
                    <InputNumber
                      disabled={true}
                      className={"input"}
                      placeholder={"10%"}
                      min={0}
                      style={{ borderRadius: 5, width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "maximumAmount" })}
                    className='mb-1'
                    name='maximumAmount'
                    tooltip={
                      <Text type='primary' className='my-font'>
                        {intl.formatMessage({ id: "explainCouponMax" })}
                      </Text>
                    }
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "errorname" }),
                      },
                    ]}
                  >
                    <InputNumber
                      disabled={true}
                      className={"input"}
                      placeholder={"50"}
                      min={0}
                      style={{ borderRadius: 5, width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "MaxNumberOfUsed" })}
                    className='mb-1'
                    name='numberOfUsed'
                    tooltip={
                      <Text type='primary' className='my-font'>
                        {intl.formatMessage({ id: "explainCouponMaxUsed" })}
                      </Text>
                    }
                  >
                    <InputNumber
                      className={"input"}
                      placeholder={""}
                      min={coupon?.numberOfUsed ? coupon.numberOfUsed : 0}
                      style={{ borderRadius: 5, width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}></Col>
              </Row>
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "startDate" })}
                    className='mb-1'
                    name='startDate'
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "errorname" }),
                      },
                    ]}
                  >
                    <DatePicker
                      disabled={true}
                      style={{ width: "100%" }}
                      disabledDate={disabledStartDate}
                      onChange={(e) => {
                        form.setFieldsValue({
                          endDate: moment(e).add(1, "days").startOf("day"),
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "endDate" })}
                    className='mb-1'
                    name='endDate'
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "errorname" }),
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      disabledDate={disabledEndDate}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label={intl.formatMessage({ id: "status" })}
                className='mb-1'
                name='status'
                style={{ borderRadius: 20 }}
                valuePropName='checked'
              >
                <Switch
                  checkedChildren={
                    <p>
                      {intl.formatMessage({
                        id: "active",
                      })}
                    </p>
                  }
                  unCheckedChildren={
                    <p>
                      {intl.formatMessage({
                        id: "disabled",
                      })}
                    </p>
                  }
                />
              </Form.Item>
              <Divider orientation={rtl ? "right" : "left"}>
                <Text type='primary' className='my-font'>
                  {intl.formatMessage({ id: "information" })}
                </Text>
              </Divider>
              <Text className="my-font">
                {intl.formatMessage({ id: "totalUsed" })}: {coupon?.totalUsed}
              </Text>
              <Table
                columns={columns}
                dataSource={orders}
                pagination={false}
                loading={loading}
              />
              <Divider />
              <Space direction='horizontal'>
                <Form.Item>
                  <CustomButton
                    htmlType='submit'
                    text={intl.formatMessage({ id: "save" })}
                    className={`btnRegister btn-text border-8`}
                    loading={loading}
                    disabled={loading}
                    type='primary'
                  />
                </Form.Item>

                <Form.Item>
                  <CustomButton
                    type='text'
                    text={intl.formatMessage({ id: "cancel" })}
                    disabled={loading}
                    onClick={() => {
                      hide();
                      form.setFieldsValue({});
                    }}
                  />
                </Form.Item>
              </Space>
            </>
          );
        }}
      </Form>


    </Modal>
  );
};

export default EditCoupon;
