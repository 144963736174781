import { QuestionCircleOutlined } from '@ant-design/icons';
import { Checkbox, Col, Divider, Form, InputNumber, Row, Tooltip, Typography } from 'antd';
import React from 'react'
import { useIntl } from 'react-intl';

const { Text, Title } = Typography

function ReservationPrices({ form,
    rtl,
    setDisabledValue,
    disabledValue,
}) {
    const intl = useIntl()
    return (
        <>
            <Divider orientation={rtl ? "right" : "left"}>
                <Title className='my-font' level={5}>
                    {intl.formatMessage({ id: "prices" })}
                </Title>
            </Divider>
            <Row
                type='flex'
                justify='space-between'
                align='bottom'
                style={{
                    borderBottom: "1px solid #efefef",
                    paddingBottom: "10px",
                    marginBottom: "10px",
                }}
            >
                <Col md={11} sm={24}>
                    <Form.Item
                        style={{
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                        className='mb-1'
                        valuePropName='checked'
                        name='isReservationFree'
                        onChange={(e) => {
                            if (e.target.checked) {
                                setDisabledValue("pricePerUser");
                                setDisabledValue("reservationPrice");
                                form.setFieldsValue({
                                    pricePerUser: 0,
                                    reservationPrice: 0,
                                    pricePerChild: 0,
                                    hourPrice: 0,
                                    isPriceForReservation: false,
                                    isPricePerUser: false,
                                    isPricePerChild: false,
                                    isHourSelection: false,
                                    reservationFeeStandAlone:false
                                });
                            } else {
                                form.setFieldsValue({
                                    pricePerUser: 0,
                                    isPricePerUser: true,
                                });
                            }
                        }}
                    >
                        <Checkbox>
                            <Text className='my-font'>
                                {intl.formatMessage({ id: "isReservationFree" })}
                            </Text>
                        </Checkbox>
                    </Form.Item>
                </Col>
                <Col md={11} sm={24}>
                    <Form.Item
                        style={{
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                        className='mb-1'
                        valuePropName='checked'
                        name='reservationFeeStandAlone'
                    >
                        <Checkbox className='mx-3' disabled={form.getFieldValue("isReservationFree")}>
                            <Text className='my-font'>
                                {intl.formatMessage({ id: "reservationFeeStandAlone" })}
                                <Tooltip
                                    title={
                                        <Text className='my-font'>
                                            {intl.formatMessage({
                                                id: "reservationFeeStandAloneExplain",
                                            })}
                                        </Text>
                                    }
                                >
                                    <QuestionCircleOutlined
                                        className='mx-2'
                                        style={{ color: "rgba(0,0,0,0.45)" }}
                                    />
                                </Tooltip>
                            </Text>
                        </Checkbox>
                    </Form.Item>
                </Col>
            </Row>

            <Row type='flex' justify='space-between' align='bottom'>
                <Col md={11} sm={24}>
                    <Form.Item
                        style={{
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                        className='mb-1'
                        valuePropName='checked'
                        name='isPricePerUser'
                        onChange={(e) => {
                            if (e.target.checked) {
                                setDisabledValue("reservationPrice");
                                form.setFieldsValue({
                                    reservationPrice: 0,
                                    pricePerUser: 0,
                                    hourPrice: 0,
                                    isPriceForReservation: false,
                                    isReservationFree: false,
                                    isHourSelection: false

                                });
                            } else {
                                setDisabledValue("reservationPrice");
                                form.setFieldsValue({
                                    pricePerUser: 0,
                                    isReservationFree: true,
                                    reservationFeeStandAlone:false,
                                    isPricePerChild: false
                                });
                            }
                        }}
                    >
                        <Checkbox>
                            <Text className='my-font'>
                                {intl.formatMessage({ id: "isPricePerUser" })}
                            </Text>
                        </Checkbox>
                    </Form.Item>
                    <Form.Item
                        label={intl.formatMessage({ id: "pricePerUser" })}
                        rules={[
                            {
                                validator: (_, value) => {
                                    if (disabledValue === "pricePerUser") {
                                        return Promise.resolve();
                                    } else if (value >= 0) {
                                        return Promise.resolve();
                                    } else {
                                        return Promise.reject(
                                            intl.formatMessage({ id: "minimumPrice1" })
                                        );
                                    }
                                },
                            },
                        ]}
                        tooltip={
                            <Text className='my-font'>
                                {intl.formatMessage({ id: "pricePerUserTooltip" })}
                            </Text>
                        }
                        className='mb-1'
                        name='pricePerUser'
                    >
                        <InputNumber
                            style={{
                                width: "100%",
                            }}
                            className={"input"}
                            min={0}
                            disabled={
                                disabledValue === "pricePerUser" ||
                                form.getFieldValue("isReservationFree") ||
                                form.getFieldValue("isHourSelection")
                            }
                        />
                    </Form.Item>
                </Col>
                <Col md={11} sm={24}>
                    <Form.Item
                        style={{
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                        className='mb-1'
                        valuePropName='checked'
                        name='isPricePerChild'
                        onChange={(e) => {
                            if (e.target.checked) {
                                setDisabledValue("reservationPrice");
                                form.setFieldsValue({
                                    isPriceForReservation: false,
                                    isReservationFree: false,
                                    pricePerChild: 0,
                                    hourPrice: 0,
                                    isPricePerUser: true,
                                    isHourSelection: false
                                });
                            } else {
                                form.setFieldsValue({
                                    pricePerChild: 0,
                                });
                            }
                        }}
                    >
                        <Checkbox>
                            <Text className='my-font'>
                                {intl.formatMessage({ id: "isPricePerChild" })}
                            </Text>
                        </Checkbox>
                    </Form.Item>
                    <Form.Item
                        label={intl.formatMessage({ id: "pricePerChild" })}
                        rules={[
                            {
                                validator: (_, value) => {
                                    if (value >= 0) {
                                        return Promise.resolve();
                                    } else {
                                        return Promise.reject(
                                            intl.formatMessage({ id: "minimumPrice1" })
                                        );
                                    }
                                },
                            },
                        ]}
                        tooltip={
                            <Text className='my-font'>
                                {intl.formatMessage({ id: "pricePerChildTooltip" })}
                            </Text>
                        }
                        className='mb-1'
                        name='pricePerChild'
                    >
                        <InputNumber
                            style={{
                                width: "100%",
                            }}
                            className={"input"}
                            min={0}
                            disabled={
                                form.getFieldValue("isReservationFree") ||
                                form.getFieldValue("isPriceForReservation") || !form.getFieldValue('isPricePerChild')
                            }
                        />
                    </Form.Item>
                </Col>
                {/* <Divider  type="vertical"  /> */}

            </Row>

            <Row type='flex' justify='space-between' align='bottom'>
                <Col md={11} sm={24}>
                    <Form.Item
                        style={{
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                        className='mb-1'
                        valuePropName='checked'
                        name='isPriceForReservation'
                        onChange={(e) => {
                            if (e.target.checked) {
                                setDisabledValue("pricePerUser");
                                form.setFieldsValue({
                                    pricePerUser: 0,
                                    isPricePerUser: false,
                                    isReservationFree: false,
                                    pricePerChild: 0,
                                    isPricePerChild: false,
                                    isHourSelection: false,
                                });
                            } else {
                                setDisabledValue("reservationPrice");
                                form.setFieldsValue({
                                    reservationPrice: 0,
                                    pricePerUser: 0,
                                    isReservationFree: true,
                                    reservationFeeStandAlone:false
                                });
                            }
                        }}
                    >
                        <Checkbox>
                            <Text className='my-font'>
                                {intl.formatMessage({ id: "isPriceForReservation" })}
                            </Text>
                        </Checkbox>
                    </Form.Item>
                    <Form.Item
                        label={intl.formatMessage({ id: "pricePerReservation" })}
                        rules={[
                            {
                                validator: (_, value) => {
                                    if (disabledValue === "reservationPrice") {
                                        return Promise.resolve();
                                    } else if (value >= 1) {
                                        return Promise.resolve();
                                    } else {
                                        return Promise.reject(
                                            intl.formatMessage({ id: "minimumPrice1" })
                                        );
                                    }
                                },
                            },
                        ]}
                        tooltip={
                            <Text className='my-font'>
                                {intl.formatMessage({ id: "pricePerReservationTooltip" })}
                            </Text>
                        }
                        className='mb-1'
                        name='reservationPrice'
                    >
                        <InputNumber
                            style={{
                                width: "100%",
                            }}
                            className={"input"}
                            min={0}
                            disabled={
                                disabledValue === "reservationPrice" ||
                                form.getFieldValue("isReservationFree") ||
                                form.getFieldValue("isHourSelection")
                            }
                        />
                    </Form.Item>

                </Col>
                <Col md={11} sm={24}>
                    <Form.Item
                        style={{
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                        className='mb-1'
                        valuePropName='checked'
                        name='isHourSelection'
                        onChange={(e) => {
                            if (e.target.checked) {
                                setDisabledValue("pricePerUser");
                                setDisabledValue("reservationPrice");
                                form.setFieldsValue({
                                    pricePerUser: 0,
                                    pricePerChild: 0,
                                    reservationPrice: 0,
                                    isPricePerUser: false,
                                    isPricePerChild: false,
                                    isPriceForReservation: false,
                                    isReservationFree: false,
                                    openSeatsEvery: "60"
                                });
                                
                                // if (form.getFieldValue("openSeatsEvery") === "15" ||
                                //     form.getFieldValue("openSeatsEvery") === "30" ||
                                //     form.getFieldValue("openSeatsEvery") === "45") {
                                //     form.setFieldsValue({
                                //         openSeatsEvery: "60"
                                //     });
                                // }
                            } else {
                                form.setFieldsValue({
                                    hourPrice: 0,
                                    isReservationFree: true,
                                    reservationFeeStandAlone:false
                                });

                            }
                        }}
                    >
                        <Checkbox disabled={!form.getFieldValue("isLinkTables")}>
                            <Text className='my-font'>
                                {intl.formatMessage({ id: "isHourSelection" })}
                            </Text>
                        </Checkbox>
                    </Form.Item>
                    <Form.Item
                        label={intl.formatMessage({ id: "hourPrice" })}
                        rules={[
                            {
                                validator: (_, value) => {
                                    if (!form.getFieldValue("isHourSelection")) {
                                        return Promise.resolve();
                                    } else {
                                        if ((disabledValue === "hourPrice" || value >= 1)) {
                                            return Promise.resolve();
                                        } else {
                                            return Promise.reject(
                                                intl.formatMessage({ id: "minimumPrice1" })
                                            );
                                        }
                                    }

                                },
                            },
                        ]}
                        tooltip={
                            <Text className='my-font'>
                                {intl.formatMessage({ id: "pricePerReservationTooltip" })}
                            </Text>
                        }
                        className='mb-1'
                        name='hourPrice'
                    >
                        <InputNumber
                            style={{
                                width: "100%",
                            }}
                            className={"input"}
                            min={0}
                            disabled={
                                !form.getFieldValue("isHourSelection")
                            }
                        />
                    </Form.Item>

                </Col>
            </Row>
        </>
    )
}

export default ReservationPrices