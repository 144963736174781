export const periods = [
  {
    period: 1,
    ar: "شهر",
    en: "Month",
  },
  // {
  //     period: 2,
  //     ar: 'شهرين',
  //     en: 'Two months',
  // },
  {
    period: 3,
    ar: "ثلاثة أشهر",
    en: "Three months",
  },
  {
    period: 6,
    ar: "ستة أشهر",
    en: "Six months",
  },
  {
    period: 12,
    ar: "سنة",
    en: "Year",
  },
];

export const SERVICES_PRICE = {
  RESERVATION: 249,
  ORDER_B: 249,
  WAITING: 99,
  ORDER_A: 199,
  PICKUP: 99,
  SILVER: 290,
  GOLD: 458,
  BASIC: 166,
};
export const SERVICES_INFO = [
  {
    Name: "ORDER_A",
    price: 199,
  },
  {
    Name: "ORDER_B",
    price: 249,
  },
  {
    Name: "WAITING",
    price: 99,
  },
  {
    Name: "RESERVATION",
    price: 249,
  },
  {
    Name: "PICKUP",
    price: 99,
  },
];

export const SERVICES_DURATION = [
    {
        ar: 'اختر المدة',
        en: 'Choose duration',
        period: 0,
    },
    {
        ar: 'شهر',
        en: 'Month',
        period: 1,
    },
    {
        ar: 'ثلاث أشهر',
        en: 'Three months',
        period: 3,
    },
    {
        ar: 'ستة أشهر',
        en: 'Six months',
        period: 6,
    },
    {
        ar: 'سنة',
        en: 'Year',
        period: 12,
    },

]