import React, { useState } from "react";
import {
    Modal,
    Form,
    Input,
    InputNumber,
    Divider,
    Space,
    Row,
    Col,
    Select,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import phoneCodes from "../../Auth/PhoneCods.json";
import { useIntl } from "react-intl";
import CustomButton from "../../../Components/CustomButton";
import openNotification from "../../../Components/Notifications";
import TextArea from "antd/lib/input/TextArea";
import { checkIfValidPhone } from "../../../helpers";
import { postAddNewCustomOrder } from "../../../API/fetch";
import { addCustomOrderAction } from "../../../redux/reducers/orderReducer";

const { Option } = Select


const clientURL = "https://app.iwaiter.club";

function AddCustomOrder({ show, hide }) {
    const dispatch = useDispatch()
    const intl = useIntl();
    const { rtl } = useSelector((state) => state.settings);
    const { waiter } = useSelector((state) => state.auth);
    const { branch } = useSelector((state) => state.branch);
    const { restaurant } = useSelector((state) => state.restaurant);
    const {
        slug,
        domain
    } = restaurant;
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();


    const AddNewCustomOder = async (values) => {
        try {
            setLoading(true);
            const fullNumber = `${values.prefix.substring(1)}${values.phone}`;
            const data = {
                order: {
                    ...values,
                    phone: fullNumber,
                    number: values.phone,
                    branchId: branch?._id,
                    total: values.totalAmount,
                    type: "customOrder"
                },
                redirect: domain == "iWaiter"
                    ? `${clientURL}/${slug}/customOrders`
                    : `${domain}/customOrders`,
                code: waiter.code,
            };
            const res = await postAddNewCustomOrder(data);
            dispatch(addCustomOrderAction(res?.data?.order))
            openNotification({
                title: intl.formatMessage({ id: "savedSuccessfully" }),
                type: "success",
                rtl,
            });
            setLoading(false);
            hide();
        } catch (error) {
            console.log(error)
        }
    };

    const prefixSelector = (
        <Form.Item name='prefix' noStyle>
            <Select
                style={{
                    width: 150,
                }}
            >
                {phoneCodes.map((info) => {
                    return (
                        <Option
                            key={`${info.dial_code}-${info.name}`}
                            value={info.dial_code}
                        >
                            {info.dial_code} {info.name}
                        </Option>
                    );
                })}
            </Select>
        </Form.Item>
    );

    return (
        <Modal
            title={
                <p className='my-font'>{intl.formatMessage({ id: "addCustomOrder" })}</p>
            }
            centered
            visible={show}
            destroyOnClose={true}
            mask={true}
            getContainer={false}
            maskClosable={true}
            onCancel={() => {
                hide();
            }}
            footer={null}
        >
            <Form
                name='Add-Coupon'
                form={form}
                preserve={false}
                className='form-container'
                layout='vertical'
                onFinish={AddNewCustomOder}
                initialValues={{
                    name: "",
                    code: "",
                    orderDetails: "",
                    prefix: phoneCodes[0].dial_code,
                    totalAmount: 0

                }}
            >
                <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                    {/* Names */}
                    <Col md={24} sm={24} xs={24}>
                        <Form.Item
                            label={intl.formatMessage({ id: "customerName" })}
                            className='mb-1'
                            name='name'
                            style={{ borderRadius: 20 }}
                            rules={[
                                {
                                    required: true,
                                    message: intl.formatMessage({ id: "errorname" }),
                                },
                            ]}
                        >
                            <Input
                                className={"input"}
                                style={{ borderRadius: 5, width: "100%" }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                    <Col md={24} sm={24} xs={24}>
                        <Form.Item
                            name='phone'
                            label={intl.formatMessage({ id: "phone" })}
                            rules={[
                                {
                                    required: true,
                                    message: intl.formatMessage({ id: "errorphone" }),
                                },
                                {
                                    validator: (_, value) => {
                                        const prefix = form.getFieldValue("prefix");
                                        if (!prefix) {
                                            return Promise.reject(
                                                intl.formatMessage({ id: "errorphone" })
                                            );
                                        }
                                        let valid = checkIfValidPhone(`${prefix}${value}`);
                                        if (valid) {
                                            return Promise.resolve();
                                        } else {
                                            return Promise.reject(
                                                intl.formatMessage({ id: "errorphone" })
                                            );
                                        }
                                    },
                                },
                            ]}
                        >
                            <Input
                                addonBefore={rtl ? null : prefixSelector}
                                addonAfter={rtl ? prefixSelector : null}
                                style={{
                                    width: "100%",
                                }}
                            />
                        </Form.Item>


                    </Col>
                </Row>
                <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                    <Col md={24} sm={24} xs={24}>
                        <Form.Item
                            label={intl.formatMessage({ id: "orderDetails" })}
                            className='mb-1'
                            name='orderDetails'
                            rules={[
                                {
                                    required: true,
                                    message: intl.formatMessage({ id: "errorCustomOrderDetails" }),
                                },
                            ]}
                        >

                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                    <Col md={24} sm={24} xs={24}>
                        <Form.Item
                            label={intl.formatMessage({ id: "totalAmount" })}
                            className='mb-1'
                            name='totalAmount'
                            rules={[
                                {
                                    required: true,
                                    message: intl.formatMessage({ id: "errorTotalAmount" }),
                                },
                                // Make sure the total amount is more than 10
                                {
                                    validator: (_, value) => {
                                        if (value > 9) {
                                            return Promise.resolve();
                                        } else {
                                            return Promise.reject(
                                                intl.formatMessage({ id: "errorTotalAmount" })
                                            );
                                        }
                                    },
                                },
                            ]}
                        >
                            <InputNumber
                                className={"input"}
                                placeholder={""}
                                min={1}
                                style={{ borderRadius: 5, width: "100%" }}
                            />
                        </Form.Item>
                    </Col>
                </Row>


                <Divider />
                <Space direction='horizontal'>
                    <Form.Item>
                        <CustomButton
                            htmlType='submit'
                            text={intl.formatMessage({ id: "save" })}
                            className={`btnRegister btn-text border-8`}
                            loading={loading}
                            disabled={loading}
                            type='primary'
                        />
                    </Form.Item>

                    <Form.Item>
                        <CustomButton
                            type='text'
                            text={intl.formatMessage({ id: "cancel" })}
                            disabled={loading}
                            onClick={() => {
                                hide();
                            }}
                        />
                    </Form.Item>
                </Space>
            </Form>
        </Modal>
    )
}

export default AddCustomOrder