import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Typography,
  Form,
  Input,
} from "antd";
import COLORS from "../../Style/colors";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../Components/CustomButton";
import { editDesign, postUpdateRestaurant } from "../../API/fetch";
import { setRestaurantAction, setSocialLinkAction } from "../../redux/reducers/restaurantReducer";
import openNotification from "../../Components/Notifications";
import { useNavigate } from "react-router-dom";
import { IoArrowBackOutline, IoArrowForwardOutline } from "react-icons/io5";

const { Title, Text } = Typography;


const MenuButtons = () => {
  const intl = useIntl();
  const history = useNavigate();
  const dispatch = useDispatch();
  const { restaurant } = useSelector((state) => state.restaurant);
  const { waiter } = useSelector((state) => state.auth);
  const { rtl } = useSelector((state) => state.settings);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);



  useEffect(() => {
    form.resetFields();
  }, [restaurant]);

  const onFinish = async (values) => {
    setLoading(true);
    try {

      const data = {
        restaurant: {
          menuButtons: values.options
        },
        code: waiter.code,

      }
      const res = await postUpdateRestaurant(restaurant._id, data);
      const newRest = {
        ...restaurant,
        menuButtons: values.options
      };
      dispatch(setRestaurantAction(newRest));
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
    } catch (error) {
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };


  return (
    <div>
      <div
        style={{
          marginTop: 20,
          width: "92%",
          marginRight: "auto",
          marginLeft: "auto",
        }}
      >
        <Row>
          <Col
            span={24}
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "space-between",
              width: "100%"
            }}>
            <Title level={3} className='pointer' onClick={() => history("/restaurant")} style={{ margin: 0 }}>
              {rtl ? (
                <IoArrowForwardOutline className='pointer' />
              ) : (
                <IoArrowBackOutline className='pointer' />
              )}
            </Title>
            <Title level={3} style={{ margin: 0 }}>{intl.formatMessage({ id: "menuButtonSettings" })}</Title>
            <div></div>
          </Col>
        </Row>
      </div>
      <div
        style={{
          backgroundColor: COLORS.white,
          borderRadius: 6,
          width: "92%",
          marginRight: "auto",
          marginLeft: "auto",
          marginTop: 40,
          padding: 15,
        }}
        className='light-shadow'
      >
        <Form
          name='viewCart-form'
          form={form}
          className='form-container'
          layout='vertical'
          onFinish={onFinish}
          initialValues={{
            options: restaurant?.menuButtons
          }}
        >
          {() => {
            return (
              <div>
                <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                  {/* Title */}
                  <Col md={12} sm={24} xs={24}>
                    <Title className='my-font' level={4}>
                      {intl.formatMessage({ id: "options" })}
                    </Title>
                    {form.getFieldError("options")?.length ? (
                      <Text>{form.getFieldError("options")[0]}</Text>
                    ) : null}
                  </Col>
                </Row>
                <Form.List hasFeedback name='options' rules={[{ required: true }]}>
                  {(fields, { errors }) => {
                    return (
                      <>
                        {fields.map(({ key, name, fieldKey, ...restField }) => {
                          return (
                            <Row gutter={8} key={key}>
                              <Col md={6} sm={24} xs={24} style={{ display: "flex", alignItems: "center" }}>
                                <Title level={5}>
                                  {intl.formatMessage({ id: `${form.getFieldValue(['options', name, 'name'])}` })}
                                </Title>
                              </Col>
                              <Col md={8} sm={24} xs={24}>
                                <Form.Item
                                  {...restField}
                                  label={intl.formatMessage({ id: "nameAr" })}
                                  name={[name, "valueAr"]}
                                  fieldKey={[fieldKey, "valuAr"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: intl.formatMessage({ id: "errorname" }),
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder={intl.formatMessage({ id: "nameAr" })}
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={8} sm={24} xs={24}>
                                <Form.Item
                                  {...restField}
                                  label={intl.formatMessage({ id: "nameEn" })}
                                  name={[name, "valueEn"]}
                                  fieldKey={[fieldKey, "valueEn"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: intl.formatMessage({ id: "errorname" }),
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder={intl.formatMessage({ id: "nameEn" })}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          );
                        })}
                      </>
                    );
                  }}
                </Form.List>
                <Row justify='start' className='mt-5' align='middle'>
                  <Form.Item>
                    <CustomButton
                      htmlType='submit'
                      text={intl.formatMessage({ id: "save" })}
                      className={`btnRegister btn-text border-8 px-5 `}
                      loading={loading}
                      disabled={loading}
                      type='primary'
                    />
                  </Form.Item>
                </Row>
              </div>
            );
          }}

        </Form>
      </div>
    </div>
  );
};

export default MenuButtons;
