import React, { useState, useEffect } from "react";
import { Space, Spin, Tag, Typography } from "antd";
import { useIntl } from "react-intl";
import COLORS from "../../Style/colors";
import {
  getRestaurantPaymentBalance,
  getWalletAppBalance,
} from "../../API/fetch";
import { useSelector } from "react-redux";
import { DEFAULT_WALLET_NAME } from "../../Types";
import moment from "moment";
import { Table } from "ant-table-extensions";
import { FileExcelOutlined } from "@ant-design/icons";
import { customerExport } from "../../helpers";
import CustomButton from "../../Components/CustomButton";
import { useNavigate } from "react-router-dom";

const { Title, Text, Link } = Typography;
const PaymentRecords = () => {
  const intl = useIntl();
  const { restaurant } = useSelector((state) => state.restaurant);
  const [initialLoading, setInitialLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [amountToTransfer, setAmountToTransfer] = useState(0);
  const [serviceData, setServiceData] = useState(null);
  const [walletInfo, setWalletInfo] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (restaurant?.wallet?.type === DEFAULT_WALLET_NAME.IWAITER_WALLET) {
      getPaymentBalance();
    }
  }, []);

  const getPaymentBalance = async () => {
    try {
      let walletRes = null;
      const res = await getRestaurantPaymentBalance();
      console.log(res.data);
      if (restaurant?.isWithApp) {
        // walletRes = await getWalletAppBalance();
      }
      let total = res?.data?.amountToTransfer;
      if (walletRes?.data?.amountToTransfer) {
        total += walletRes?.data?.amountToTransfer;
      }
      // console.log(res.data);
      setAmountToTransfer(total);
      let trans = res.data.transactions;
      if (walletRes?.data?.transactions) {
        trans = trans.concat(walletRes?.data?.transactions);
      }
      let formatted = formatTransaction(trans);
      setTransactions(formatted);
      setServiceData({
        serviceCount: res?.data?.serviceCount,
        servicePaid: res?.data?.servicePaid,
        serviceTotal: res?.data?.serviceTotal,
        serviceRemain: res?.data?.serviceRemain,
      });
      if (res?.data?.wallet) {
        setWalletInfo(res?.data?.wallet);
      }
      setInitialLoading(false);
    } catch (error) {
      console.log(error);
      setInitialLoading(false);
    }
  };

  const formatTransaction = (transactions) => {
    let formatted = [];
    if (transactions?.length) {
      transactions.forEach((transaction, i) => {
        let amountPaidForServices = 0;
        if (transaction?.amountPaidForServices > 0) {
          amountPaidForServices =
            transaction?.amountPaidForServices?.toFixed(2);
        }
        let total = transaction?.amount - amountPaidForServices;
        let forOrdersBetween = `${moment(transaction?.startDate).format(
          "YYYY/MM/DD hh:mm"
        )} - ${moment(transaction?.endDate).format("YYYY/MM/DD hh:mm")}`;
        formatted.push({
          key: transaction?._id,
          number: i + 1,
          _id: transaction?._id,
          amount: transaction?.amount?.toFixed(2),
          total: total?.toFixed(2),
          transactionNumber: transaction?.transactionNumber,
          status: transaction?.status,
          invoiceUrl: transaction?.invoiceUrl,
          transferTime: moment(transaction?.createdAt).format("DD/MM/YYYY"),
          forOrdersBetween: transaction?.isNewTransaction
            ? transaction?.transactionNumbers
            : forOrdersBetween,
          amountPaidForServices,
          transactionObject: { ...transaction },
          isNewTransaction: transaction?.isNewTransaction,
        });
      });
    }
    let sorted = formatted.sort((a, b) => {
      return (
        new Date(b.transactionObject?.createdAt) -
        new Date(a.transactionObject?.createdAt)
      );
    });
    return sorted;
  };

  const getNextTransfer = () => {
    // get next Sunday or Thursday date from now;

    const now = moment();
    // get this Sunday;
    const Sunday = moment(moment().day("Sunday"));
    const Thursday = moment(moment().day("Thursday"));
    // check if Sunday in the past;
    if (Sunday.isBefore(now)) {
      // if yes, check if Thursday in the past;
      if (Thursday.isBefore(now)) {
        // if yes, get the nextSunday & nextThursday;
        const nextSunday = moment(Sunday.add(7, "days"));
        const nextThursday = moment(Thursday.add(7, "days"));
        // check if nextSunday is in the future;
        if (nextSunday.isAfter(now)) {
          // if yes, return nextSunday;
          return nextSunday.format("YYYY-MM-DD");
        } else {
          // if not, return nextThursday;
          return nextThursday.format("YYYY-MM-DD");
        }
      } else {
        // if not, return nextThursday;
        return Thursday.format("YYYY-MM-DD");
      }
    } else {
      // if not, return Sunday;
      return Sunday.format("YYYY-MM-DD");
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
    },
    {
      title: intl.formatMessage({ id: "transferNumber" }),
      dataIndex: "transactionNumber",
      key: "transactionNumber",
    },
    {
      title: intl.formatMessage({ id: "total" }),
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: intl.formatMessage({ id: "status" }),
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return <Tag color={"green"}>{status}</Tag>;
      },
    },
    {
      title: intl.formatMessage({ id: "transferTime" }),
      dataIndex: "transferTime",
      key: "transferTime",
      sorter: (a, b) =>
        new Date(a.transactionObject?.createdAt) -
        new Date(b.transactionObject?.createdAt),
      showSorterTooltip: false,
    },
    {
      title: intl.formatMessage({ id: "forOrdersBetween" }),
      dataIndex: "forOrdersBetween",
      key: "forOrdersBetween",
      render: (forOrdersBetween, record) => {
        if (record?.isNewTransaction) {
          return forOrdersBetween.map((order, i) => (
            <div key={i}>
              <Tag>{order}</Tag>
            </div>
          ));
        }
        return <Text>{forOrdersBetween}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: "view" }),
      dataIndex: "orderId",
      key: "orderId",
      render: (text, record, index) => {
        return (
          <CustomButton
            type='text'
            text={intl.formatMessage({ id: "view" })}
            onClick={() => {
              let path = "/reports";
              if (record?.isNewTransaction) {
                path = "/paymentRecords/" + record?._id;
              }
              navigate(path);
            }}
          />
        );
      },
    },
    {
      title: intl.formatMessage({ id: "invoice" }),
      dataIndex: "invoiceUrl",
      key: "invoiceUrl",
      render: (invoiceUrl) => {
        return (
          <a
            style={{
              color: invoiceUrl ? "#1890ff" : "gray",
              textDecoration: invoiceUrl ? "underline" : "none",
              cursor: invoiceUrl ? "pointer" : "not-allowed",
            }}
            href={invoiceUrl}
            target='_blank'
          >
            {intl.formatMessage({ id: "invoice" })}
          </a>
        );
      },
    },
  ];
  const columnsWithCut = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
    },
    {
      title: intl.formatMessage({ id: "transferNumber" }),
      dataIndex: "transactionNumber",
      key: "transactionNumber",
    },
    {
      title: intl.formatMessage({ id: "total" }),
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: intl.formatMessage({ id: "deduction" }),
      dataIndex: "amountPaidForServices",
      key: "amountPaidForServices",
    },
    {
      title: intl.formatMessage({ id: "net" }),
      dataIndex: "total",
      key: "total",
    },
    {
      title: intl.formatMessage({ id: "status" }),
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return <Tag color={"green"}>{status}</Tag>;
      },
    },
    {
      title: intl.formatMessage({ id: "transferTime" }),
      dataIndex: "transferTime",
      key: "transferTime",
      sorter: (a, b) =>
        new Date(a.transactionObject?.createdAt) -
        new Date(b.transactionObject?.createdAt),
      showSorterTooltip: false,
    },
    {
      title: intl.formatMessage({ id: "forOrdersBetween" }),
      dataIndex: "forOrdersBetween",
      key: "forOrdersBetween",
    },
    {
      title: intl.formatMessage({ id: "invoice" }),
      dataIndex: "invoiceUrl",
      key: "invoiceUrl",
      render: (invoiceUrl) => {
        return (
          <a
            style={{
              color: invoiceUrl ? "#1890ff" : "gray",
              textDecoration: invoiceUrl ? "underline" : "none",
              cursor: invoiceUrl ? "pointer" : "not-allowed",
            }}
            href={invoiceUrl}
            target='_blank'
          >
            {intl.formatMessage({ id: "invoice" })}
          </a>
        );
      },
    },
  ];
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <Title level={3}>{intl.formatMessage({ id: "allPayments" })}</Title>
      </div>
      <Spin spinning={initialLoading}>
        <Space
          direction='vertical'
          justify='center'
          align='center'
          style={{ width: "100%" }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "column",
              // marginBottom: 10,
            }}
          >
            {walletInfo?.tapWalletInfo?.status === "ACTIVE" &&
            walletInfo?.tapWalletInfo?.startActivationDate ? (
              <Space direction='vertical' size={2}>
                <Space direction='horizontal' justify='center' align='start'>
                  <Title level={5} className='my-font'>
                    {intl.formatMessage({ id: "fromThisDate" })}
                  </Title>
                  <Tag color={"green"}>
                    {moment(
                      walletInfo?.tapWalletInfo?.startActivationDate
                    ).format("YYYY-MM-DD")}
                  </Tag>
                  <Title level={5} className='my-font'>
                    {intl.formatMessage({
                      id: "youCanCheckYourPaymentWithTap",
                    })}
                  </Title>
                </Space>
                <Title level={5} className='my-font'>
                  {intl.formatMessage({ id: "loginWay" })}:
                </Title>
                <ul
                  style={{
                    backgroundColor: COLORS.white,
                  }}
                  className='py-2 border-8'
                >
                  <li>
                    {intl.formatMessage({ id: "useThisEmail" })}:{" "}
                    <Text copyable>{restaurant?.email}</Text>
                  </li>
                  <li>
                    {intl.formatMessage({ id: "goToThisLink" })}:{" "}
                    <Link href='https://businesses.tap.company' target='_blank'>
                      businesses.tap.company
                    </Link>
                  </li>
                  <li>{intl.formatMessage({ id: "clickOnForgetPassword" })}</li>
                  <li>
                    {intl.formatMessage({
                      id: "checkYourEmailAndSetNewPassword",
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: "youCanNowSeeAllYourPaymentsAndSettlements",
                    })}
                  </li>
                </ul>
              </Space>
            ) : (
              <div style={{ width: "100%" }}>
                <Space direction='horizontal' justify='center' align='start'>
                  <Title level={5} className='my-font'>
                    {intl.formatMessage({ id: "yourBalance" })}
                  </Title>
                  <Tag color={"green"}>{amountToTransfer?.toFixed(2)}</Tag>
                </Space>
                <Space style={{ width: "100%" }} direction='vertical'>
                  <Text className='my-font'>
                    {intl.formatMessage({
                      id: "pleaseUpdateYourBankDetailsForFastTransfer",
                    })}
                  </Text>
                  <CustomButton
                    type='secondary'
                    text={intl.formatMessage({ id: "updateBankDetails" })}
                    onClick={() => navigate("/payment")}
                    style={{ borderRadius: 10 }}
                  />
                </Space>
              </div>
            )}
          </div>
          {/* Check if we have service data that need to be cut from payment */}
          {serviceData?.serviceCount > 0 ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              {/* serviceCount
servicePaid
serviceTotal
serviceRemain */}
              <Space direction='horizontal' justify='center' align='start'>
                <Title level={5} className='my-font'>
                  {intl.formatMessage({ id: "yourTotalFeesIs" })}
                </Title>
                <Tag>{serviceData?.serviceTotal?.toFixed(2)}</Tag>
              </Space>
              <Space direction='horizontal' justify='center' align='start'>
                <Title level={5} className='my-font'>
                  {intl.formatMessage({ id: "yourPaidAmountIs" })}
                </Title>
                <Tag color='green'>{serviceData?.servicePaid?.toFixed(2)}</Tag>
              </Space>
              <Space direction='horizontal' justify='center' align='start'>
                <Title level={5} className='my-font'>
                  {intl.formatMessage({ id: "yourRemainingAmountIs" })}
                </Title>
                <Tag color='orange'>
                  {serviceData?.serviceRemain?.toFixed(2)}
                </Tag>
              </Space>
            </div>
          ) : null}
        </Space>
        <div
          style={{
            backgroundColor: COLORS.white,
            marginTop: 40,
            width: "92%",
            marginRight: "auto",
            marginLeft: "auto",
            borderRadius: 10,
            padding: 15,
          }}
          className='light-shadow'
        >
          <Table
            exportable
            exportableProps={{
              showColumnPicker: true,
              btnProps: {
                icon: <FileExcelOutlined />,
                type: "primary",
                style: { borderRadius: 7 },
                onClick: () =>
                  customerExport({
                    data: transactions,
                    columns,
                    fileName: "Transactions Report",
                    loadingSetter: setInitialLoading,
                  }),
              },
              children: (
                <Text
                  className='my-font'
                  style={{
                    color: COLORS.white,
                  }}
                >
                  {intl.formatMessage({ id: "export" })}
                </Text>
              ),
            }}
            pagination={{
              hideOnSinglePage: true,
            }}
            dataSource={transactions}
            scroll={{ x: 500 }}
            columns={serviceData?.serviceCount > 0 ? columnsWithCut : columns}
            style={{ borderRadius: 10 }}
          />
        </div>
      </Spin>
    </div>
  );
};

export default PaymentRecords;
