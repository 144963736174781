import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Typography,
  Form,
  Collapse,
  Radio,
  Divider,
  Tag,
  Image,
  Input,
  Switch,
} from "antd";
import COLORS from "../../Style/colors";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SketchPicker } from "react-color";
import CustomButton from "../../Components/CustomButton";
import GRID from "../../assets/images/Grid.png";
import LIST from "../../assets/images/List.png";
import CARD from "../../assets/images/Cards.png";
import MainPagePreview from "./Components/MainPagePreview";
import { editDesign } from "../../API/fetch";
import { setDesignAction } from "../../redux/reducers/restaurantReducer";
import openNotification from "../../Components/Notifications";
import { getContrastYIQ } from "../../helpers";
const { Title, Text } = Typography;
const { Panel } = Collapse;

const CUSTOMKeys = {
  backgroundColor: "backgroundColor",
  titleColor: "titleColor",
  textColor: "textColor",
  backgroundCircleBG: "backgroundCircleBG",
  backgroundCircleText: "backgroundCircleText",
  backgroundBox: "backgroundBox",
  backgroundBoxText: "backgroundBoxText",
  backgroundBoxDescription: "backgroundBoxDescription",
  successLabel: "successLabel",
  danger: "danger",
  dangerText: "dangerText",
  trackLabel: "trackLabel",
  primaryButtonBG: "primaryButtonBG",
  primaryButtonText: "primaryButtonText",
  secondaryButtonBG: "secondaryButtonBG",
  secondaryButtonText: "secondaryButtonText",
  backgroundTextFooter: "backgroundTextFooter",
  promotionBG: "promotionBG",
  promotionText: "promotionText",
};
const THEMEKeys = {
  primaryButtonBG: "primaryButtonBG",
  primaryButtonText: "primaryButtonText",
  secondaryButtonBG: "secondaryButtonBG",
  secondaryButtonText: "secondaryButtonText",
};

const MainPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { design, restaurant } = useSelector((state) => state.restaurant);
  const { isBigScreen, rtl } = useSelector((state) => state.settings);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.resetFields();
  }, [design]);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const data = {
        restaurant: {
          design: {
            ...design,
            ...values,
          },
        },
      };
      const res = await editDesign(data, restaurant._id);
      if (res.data?.result?.newColors) {
        dispatch(setDesignAction(res.data.result.newColors));
      }
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
    } catch (error) {
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };
  return (
    <div>
      <div>
        <Title level={4}>{intl.formatMessage({ id: "colorsAndDesign" })}</Title>
      </div>
      <div
        style={{
          backgroundColor: COLORS.white,
          borderRadius: 6,
          width: "92%",
          marginRight: "auto",
          marginLeft: "auto",
          padding: 15,
        }}
        className='light-shadow'
      >
        <Form
          name='mainPage-form'
          form={form}
          className='form-container'
          layout='vertical'
          onFinish={onFinish}
          initialValues={{
            ...design,
          }}
        >
          {() => {
            return (
              <div>
                <Row type='flex'>
                  <Col md={12} sm={24} xs={24}>
                    <Collapse ghost>
                      <Panel
                        header={
                          <Text className='my-font'>
                            {intl.formatMessage({
                              id: "menuDesign",
                            })}
                          </Text>
                        }
                        key='display'
                      >
                        <Form.Item
                          tooltip={
                            <Text type='primary' className='my-font'>
                              {intl.formatMessage({
                                id: "menuDesign",
                              })}
                            </Text>
                          }
                          className='mb-1'
                          name={"display"}
                          style={{ borderRadius: 20 }}
                        >
                          <Radio.Group>
                            <Radio value='grid'>
                              <Image preview={false} width={50} src={GRID} />
                            </Radio>
                            <Radio className='mx-4' value='list'>
                              <Image preview={false} width={50} src={LIST} />
                            </Radio>
                            <Radio className='mx-4' value='oneCard'>
                              <Image preview={false} width={50} src={CARD} />
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Panel>
                    </Collapse>
                    <Collapse ghost>
                      <Panel
                        header={
                          <Text className='my-font'>
                            {intl.formatMessage({
                              id: "theme",
                            })}
                          </Text>
                        }
                        key='theme'
                      >
                        <Form.Item
                          tooltip={
                            <Text type='primary' className='my-font'>
                              {intl.formatMessage({
                                id: "theme",
                              })}
                            </Text>
                          }
                          className='mb-1'
                          name={"theme"}
                          style={{ borderRadius: 20 }}
                        >
                          <Radio.Group>
                            <Radio value='SAUDI'>
                              <Text className='my-font'>
                                {intl.formatMessage({ id: "SAUDIDAY" })} 🇸🇦
                              </Text>
                            </Radio>
                            <Radio value='LIGHT'>
                              <Text className='my-font'>
                                {intl.formatMessage({ id: "LIGHT" })}
                              </Text>
                            </Radio>
                            <Radio className='mx-4' value='DARK'>
                              <Text className='my-font'>
                                {intl.formatMessage({ id: "DARK" })}
                              </Text>
                            </Radio>
                            <Radio className='mx-4' value='CUSTOM'>
                              <Text className='my-font'>
                                {intl.formatMessage({ id: "CUSTOM" })}
                              </Text>
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Panel>
                    </Collapse>

                    {form.getFieldValue("theme") === "CUSTOM"
                      ? Object.keys(CUSTOMKeys).map((key) => {
                          return (
                            <Collapse ghost key={key}>
                              <Panel
                                header={
                                  <>
                                    <Text className='my-font'>
                                      {intl.formatMessage({
                                        id: key,
                                      })}
                                    </Text>
                                    {"  "}
                                    <Tag
                                      color={form.getFieldValue(key)}
                                      style={{
                                        color: getContrastYIQ(
                                          form?.getFieldValue(key)
                                        ),
                                      }}
                                    >
                                      {form.getFieldValue(key)}
                                    </Tag>
                                  </>
                                }
                                key={key}
                              >
                                <Form.Item
                                  tooltip={
                                    <Text type='primary' className='my-font'>
                                      {intl.formatMessage({
                                        id: key,
                                      })}
                                    </Text>
                                  }
                                  className='mb-1'
                                  name={key}
                                  style={{ borderRadius: 20 }}
                                  valuePropName='color'
                                  getValueFromEvent={(color) => color.hex}
                                >
                                  <SketchPicker />
                                </Form.Item>
                              </Panel>
                            </Collapse>
                          );
                        })
                      : Object.keys(THEMEKeys).map((key) => {
                          return (
                            <Collapse ghost key={key}>
                              <Panel
                                header={
                                  <>
                                    <Text className='my-font'>
                                      {intl.formatMessage({
                                        id: key,
                                      })}
                                    </Text>
                                    {"  "}
                                    <Tag
                                      color={form.getFieldValue(key)}
                                      style={{
                                        color: getContrastYIQ(
                                          form?.getFieldValue(key)
                                        ),
                                      }}
                                    >
                                      {form.getFieldValue(key)}
                                    </Tag>
                                  </>
                                }
                                key={key}
                              >
                                <Form.Item
                                  tooltip={
                                    <Text type='primary' className='my-font'>
                                      {intl.formatMessage({
                                        id: key,
                                      })}
                                    </Text>
                                  }
                                  className='mb-1'
                                  name={key}
                                  style={{ borderRadius: 20 }}
                                  valuePropName='color'
                                  getValueFromEvent={(color) => color.hex}
                                >
                                  <SketchPicker />
                                </Form.Item>
                              </Panel>
                            </Collapse>
                          );
                        })}
                    <div style={{ marginTop: 20, marginBottom: 10 }}>
                      <Text className='my-font mb-1'>
                        {intl.formatMessage({ id: "thankMessage" })}:
                      </Text>
                    </div>
                    <Form.Item
                      label={intl.formatMessage({ id: "thanksMessageAR" })}
                      className='mb-1'
                      name='thanksMessageAR'
                      style={{ borderRadius: 20 }}
                    >
                      <Input
                        className={"input"}
                        style={{ borderRadius: 5, width: "80%" }}
                      />
                    </Form.Item>
                    <Form.Item
                      label={intl.formatMessage({ id: "thanksMessageEN" })}
                      className='mb-1'
                      name='thanksMessageEN'
                      style={{ borderRadius: 20 }}
                    >
                      <Input
                        className={"input"}
                        style={{ borderRadius: 5, width: "80%" }}
                      />
                    </Form.Item>

                    <Form.Item
                      label={intl.formatMessage({
                        id: "showCategorySection",
                      })}
                      valuePropName='checked'
                      name='showCategorySection'
                      style={{ marginTop: 20 }}
                    >
                      <Switch
                        style={{ marginBottom: 0 }}
                        checkedChildren={
                          <p>
                            {intl.formatMessage({
                              id: "yes",
                            })}
                          </p>
                        }
                        unCheckedChildren={
                          <p>
                            {intl.formatMessage({
                              id: "no",
                            })}
                          </p>
                        }
                      />
                    </Form.Item>

                    <Form.Item
                      label={intl.formatMessage({
                        id: "limitCategoryInOnePage",
                      })}
                      valuePropName='checked'
                      name='limitCategoryInOnePage'
                      style={{ marginTop: 20 }}
                    >
                      <Switch
                        style={{ marginBottom: 0 }}
                        checkedChildren={
                          <p>
                            {intl.formatMessage({
                              id: "yes",
                            })}
                          </p>
                        }
                        unCheckedChildren={
                          <p>
                            {intl.formatMessage({
                              id: "no",
                            })}
                          </p>
                        }
                      />
                    </Form.Item>

                    {restaurant?.coverImage && (
                      <Form.Item
                        label={intl.formatMessage({
                          id: "showCoverImageInMenu",
                        })}
                        valuePropName='checked'
                        name='showCoverImageInMenu'
                        style={{ marginTop: 20 }}
                      >
                        <Switch
                          style={{ marginBottom: 0 }}
                          checkedChildren={
                            <p>
                              {intl.formatMessage({
                                id: "yes",
                              })}
                            </p>
                          }
                          unCheckedChildren={
                            <p>
                              {intl.formatMessage({
                                id: "no",
                              })}
                            </p>
                          }
                        />
                      </Form.Item>
                    )}

                    <Divider />
                    <Row justify='start' className='mt-5' align='middle'>
                      <Form.Item>
                        <CustomButton
                          htmlType='submit'
                          text={intl.formatMessage({ id: "save" })}
                          className={`btnRegister btn-text border-8 px-5 `}
                          loading={loading}
                          disabled={loading}
                          type='primary'
                        />
                      </Form.Item>
                    </Row>
                  </Col>
                  <Col
                    style={{ height: isBigScreen ? 750 : 650 }}
                    md={12}
                    sm={24}
                    xs={24}
                  >
                    <MainPagePreview form={form} showAdds={false} images={[]} />
                  </Col>
                </Row>
              </div>
            );
          }}
        </Form>
      </div>
    </div>
  );
};

export default MainPage;
