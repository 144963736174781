import {
  Modal,
  Row,
  Form,
  TimePicker,
  Input,
  Col,
  Space,
  Divider,
  Select,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import { Typography } from "antd";
import { useIntl } from "react-intl";
import moment from "moment";
import { useSelector } from "react-redux";
import CustomButton from "../../../../../Components/CustomButton";
import openNotification from "../../../../../Components/Notifications";
import { postUpdateTableStatus, postUpdateUserReservation } from "../../../../../API/fetch";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { editTableAction, setTablesAction } from "../../../../../redux/reducers/tableReducer";
import { setBranchAction } from "../../../../../redux/reducers/branchReducer";


const { Title, Text } = Typography;

const { Option } = Select;
const AddCustomerToTableModal = ({ show, hide, table, reservations }) => {
  const intl = useIntl();
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { rtl } = useSelector((state) => state.settings);
  const { tables } = useSelector((state) => state.table);
  const { branch } = useSelector((state) => state.branch);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState(null)

  const editTableTime = async (values) => {
    //check if the waiter has selected a reservation
    let customerCanSetForMinutes
    if (selectedReservation && selectedReservation?.isWithHours) {
      const reservationDate = moment(selectedReservation?.reservationDate)
      const reservationAttendance = moment()
      const lateMinutes = reservationAttendance?.diff(reservationDate, 'minutes');
      //check if reservation attendance is beofre the reservation date 
      //isBefore = sitting time is reservation duration
      //otherwise = substract lateTimes from reservation duration
      if (reservationAttendance.isBefore(reservationDate)) {
        customerCanSetForMinutes = 60 * values.hoursCount
      } else {
        customerCanSetForMinutes = (60 * values.hoursCount) - lateMinutes
      }
    } else {
      customerCanSetForMinutes = values?.customerCanSetForMinutes
    }
    setLoading(true);
    try {
      const data = {
        table: {
          startSeTime: values?.startSeTime.toDate(),
          customerCanSetForMinutes: customerCanSetForMinutes,
          modifyByAdmin: true,
        }
      };
      // check if there is a reservationId;
      if (values?.reservationId) {
        let customerId = reservations?.find(
          (res) => res?._id === values?.reservationId
        )?.customerId?._id;
        const newData = {
          reservation: {
            date: moment(),
            tableId: table?._id
          },
          table: {
            _id: table?._id,
            startSeTime: values?.startSeTime.toDate(),
            customerCanSetForMinutes: customerCanSetForMinutes,
            reservationId: values?.reservationId,
            customerId: customerId,
            modifyByAdmin: true,
          },
        };
        const res = await postUpdateUserReservation(values?.reservationId, newData);
        const newBranch = {
          ...branch,
          reservationList: res.data.reservationList,
        };
        dispatch(editTableAction(res?.data?.table));
        dispatch(setBranchAction(newBranch));
      } else {
        const res = await postUpdateTableStatus(table?._id, data)
        if (values?.reservationId) {
          let newTables = tables.map(t => {
            if (t?.reservationId === values?.reservationId) {
              return {
                ...t,
                reservationId: null,
                customerId: null,
                startSeTime: null,
                customerCanSetForMinutes: null,
              }
            }
            return t
          })
          dispatch(setTablesAction(newTables))
        }
      }
      hide();
      navigate(-1)
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };


  useEffect(() => {
    if (selectedReservation && selectedReservation?.isWithHours) {
      form.setFieldsValue({
        hoursCount: selectedReservation.numberOfHours,
        customerCanSetForMinutes: 0
      })
    } else {
      form.setFieldsValue({
        customerCanSetForMinutes: 60
      })
    }
  }, [selectedReservation, show])

  return (
    <Modal
      title={
        <Title level={4} className='my-font'>
          {intl.formatMessage({ id: "edit" })}
        </Title>
      }
      centered
      visible={show}
      destroyOnClose={true}
      mask={true}
      maskClosable={true}
      onCancel={() => hide()}
      footer={null}
    >
      <Form
        name='editTable-customer'
        form={form}
        preserve={false}
        className='form-container'
        layout='vertical'
        onFinish={editTableTime}
        initialValues={{
          startSeTime: moment(),
          customerCanSetForMinutes: table?.customerCanSetForMinutes || 60,
          reservationId: table?.reservationId,
          hoursCount: 0
        }}
      >
        {() => (
          <>
            <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
              <Col md={12} sm={24} xs={24}>
                <Form.Item
                  label={intl.formatMessage({ id: "sittingStart" })}
                  className='mb-1'
                  name='startSeTime'
                >
                  <TimePicker format={"hh:mm"} style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col md={12} sm={24} xs={24}>
                {/*  */}
                <Form.Item
                  label={intl.formatMessage({
                    id: "customerCanSetForMinutes",
                  })}
                  className='mb-1'
                  name='customerCanSetForMinutes'
                >
                  <Input
                    disabled={selectedReservation && selectedReservation?.isWithHours}
                    addonBefore={
                      rtl ? null : (
                        <Text className='my-font'>
                          {intl.formatMessage({ id: "minutes" })}
                        </Text>
                      )
                    }
                    type='number'
                    addonAfter={
                      rtl ? (
                        <Text className='my-font'>
                          {intl.formatMessage({ id: "minutes" })}
                        </Text>
                      ) : null
                    }
                    style={{ width: "100%" }}
                    min={0}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
              {reservations?.length ? (
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "reservation" })}
                    className='mb-1'
                    name='reservationId'
                  >
                    <Select
                      showSearch
                      allowClear
                      style={{ width: "100%" }}
                      className='my-font'
                      filterOption={(input, option) => {
                        return (
                          option.children.filter(el => el)?.filter(el => el?.toLowerCase()?.includes(input?.toLocaleLowerCase()))?.length > 0
                        );
                      }}
                      onChange={(e) => setSelectedReservation(reservations?.find(res => res?._id === e))}
                    >
                      {reservations?.map((reservation) => (
                        <Option
                          key={reservation?._id}
                          value={reservation?._id}
                          className='my-font'
                        >
                          {reservation?.customerId?.phone} {" "}- {moment(reservation?.reservationDate)?.format("HH:mm")}
                          {/* {reservation?.tableId?._id ? (
                            <Tag className='mx-2' color='success'>
                              {rtl
                                ? reservation?.tableId?.name
                                : reservation?.tableId?.name_en}
                            </Tag>
                          ) : null} */}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              ) : null}
              {selectedReservation && selectedReservation?.isWithHours ?
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "hoursCount" })}
                    className='mb-1'
                    name='hoursCount'
                  >
                    <Input
                      disabled={selectedReservation && selectedReservation?.isWithHours}
                      addonBefore={
                        rtl ? null : (
                          <Text className='my-font'>
                            {intl.formatMessage({ id: "hours" })}
                          </Text>
                        )
                      }
                      type='number'
                      addonAfter={
                        rtl ? (
                          <Text className='my-font'>
                            {intl.formatMessage({ id: "hours" })}
                          </Text>
                        ) : null
                      }
                      style={{ width: "100%" }}
                      min={0}
                    />
                  </Form.Item>
                </Col>

                : null}
            </Row>
            <Divider />
            <Space direction='horizontal'>
              <Form.Item>
                <CustomButton
                  htmlType='submit'
                  text={intl.formatMessage({ id: "save" })}
                  className={`btnRegister btn-text border-8`}
                  loading={loading}
                  disabled={loading}
                  type='primary'
                />
              </Form.Item>

              <Form.Item>
                <CustomButton
                  type='text'
                  text={intl.formatMessage({ id: "cancel" })}
                  disabled={loading}
                  onClick={() => {
                    hide();
                  }}
                />
              </Form.Item>
            </Space>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default AddCustomerToTableModal;
