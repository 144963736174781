import { createSlice,  } from "@reduxjs/toolkit";

const initialState = {
  analyticsData: {},
  googleData: {}
  
};


export const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    
    setAnalyticsData: (state, action) => {
      state.analyticsData = action.payload;
    },
    setGoogleData: (state, action) => {
      state.googleData = action.payload;
    }
   
  },
});

export const {
  setAnalyticsData: setAnalyticsDataAction,
  setGoogleData: setGoogleDataAction  
} = analyticsSlice.actions;

export default analyticsSlice.reducer;
