export const DINE_IN_ORDERS_STATUS = {
    OPEN: "open",
    IN_SERVER: "inService",
    SERVED: "served",
    CLOSE: "close",
    ALL: 'all',
};
export const PICKUP_ORDERS_STATUS = {
    PENDING: "pending",
    OPEN: "open",
    IN_KITCHEN: "inKitchen",
    READY: "ready",
    CLOSE: "close",
    REJECTED: 'rejected',
    TIMEOUT: 'timeout',
    ALL: 'all',
};


export const CUSTOM_ORDERS_STATUS = {
    PENDING: "pending",
    OPEN: "open",
    IN_KITCHEN: "inKitchen",
    READY: "ready",
    CLOSE: "close",
    CANCELLED: 'cancelled',
    ALL: 'all',
};


export const PICKUP_ORDERS_STATUS_NO_PENDING = {
    OPEN: "open",
    IN_KITCHEN: "inKitchen",
    READY: "ready",
    CLOSE: "close",
    ALL: 'all',
};
export const DELIVERY_ORDERS_STATUS = {
    PENDING: "pending",
    OPEN: "open",
    IN_KITCHEN: "inKitchen",
    READY: "ready",
    IN_DELIVERY: "inDelivery",
    CLOSE: "close",
    REJECTED: 'rejected',
    TIMEOUT: 'timeout',
    ALL: 'all',
};
export const DELIVERY_ORDERS_STATUS_NO_PENDING = {
    OPEN: "open",
    IN_KITCHEN: "inKitchen",
    READY: "ready",
    IN_DELIVERY: "inDelivery",
    CLOSE: "close",
    ALL: 'all',
};
export const IWAITER_APP_ORDERS_STATUS = {
    PENDING: "pending",
    OPEN: "open",
    IN_KITCHEN: "inKitchen",
    READY: "ready",
    CLOSE: "close",
    REJECTED: 'rejected',
    TIMEOUT: 'timeout',
    ALL: 'all',
};
export const IWAITER_APP_ORDERS_STATUS_NO_PENDING = {
    OPEN: "open",
    IN_KITCHEN: "inKitchen",
    READY: "ready",
    CLOSE: "close",
    ALL: 'all',
};


export const ORDER_TYPES = {
    DELIVERY: 'delivery',
    PICKUP: 'pickup',
    DINE_IN: 'inHouse',
    IWAITER_APP: "IWAITER_APP",
    RESERVATION: "RESERVATION",
    CUSTOM_ORDER: "CUSTOM_ORDER"
};

export const CUSTOMER_TYPE = {
    BRONZE: "BRONZE_BAGDE",
    SILVER: "SILVER_BAGDE",
    GOLD: "GOLD_BAGDE",
    PLATINUM: "PLATINUM_BAGDE"
}

export const TAG_COLORS_BY_STATUS = {
    "open": 'green',
    "inService": 'orange',
    "served": 'cyan'
}

export const TAG_COLORS_BY_STATUS_CUSTOM = {
    "open": 'green',
    "inKitchen": 'orange',
    "ready": 'cyan',
    "cancelled": "red"
}


export const TAG_COLORS_BY_STATUS_PICKUP = {
    "open": 'green',
    "inKitchen": 'orange',
    "ready": 'cyan'
}
export const TAG_COLORS_BY_STATUS_IWAITER_APP = {
    pending: 'orange',
    "open": 'green',
    "inKitchen": 'lightBlue',
    "ready": 'cyan',
    rejected: 'red',
    timeout: 'red',
}
export const TAG_COLORS_BY_STATUS_DELIVERY = {
    "open": 'green',
    "inKitchen": 'orange',
    "ready": 'cyan',
    'inDelivery': 'purple'
}
export const TAG_COLORS_BY_PAYMENT_STATUS = {
    "paid": 'green',
    "notPaid": 'red',
    "inPayment": 'blue',
    "failed": 'red'
}
export const TAG_COLORS_BY_PAYMENT_REFUND_STATUS = {
    "PENDING": 'orange',
    "IN_PROGRESS": 'orange',
    "REFUNDED": 'green',
    "Refunded": 'green',
    "FAILED": 'red',
    "CANCELLED": 'red'
}
export const TAG_COLORS_BY_USER_TYPE = {
    "customer": 'gray',
    "vip": 'blue',
    "v-vip": 'green',
}

export const PROMOTIONS_TYPES = {
    FIXED_PRICE: 'FIXED_PRICE',
    FIXED_DISCOUNT: 'FIXED_DISCOUNT',
    PERCENTAGE_DISCOUNT: 'PERCENTAGE_DISCOUNT',
    FIXED_INCREASE: 'FIXED_INCREASE',
    PERCENTAGE_INCREASE: 'PERCENTAGE_INCREASE',
    SPECIAL_OFFER: 'SPECIAL_OFFER'
}

export const FREE_OFFER_TYPES = {
    FIXED_DISCOUNT: "FIXED_DISCOUNT",
    PERCENTAGE_DISCOUNT: "PERCENTAGE_DISCOUNT",
    FREE_ITEMS: "FREE_ITEM",
}

export const SPECIAL_OFFER_TYPES = {
    PRICE_RELATED: "PRICE_RELATED",
    FOOD_RELATED: "FOOD_RELATED"
}

export const TABLE_SHAPES = {
    CIRCLE: 'CIRCLE',
    SQUARE: 'SQUARE',
    RECTANGLE: 'RECTANGLE',
}

export const WAITING_LIST_USER_STATUS = {
    ACTIVE: 'Active',
    SERVED: 'served',
    DELETED: 'deleted',
}

export const ANSWER_TYPES = {
    stars: 'stars',
    faces: 'faces',
    text: 'text',
}

export const TAG_COLOR_BY_USER_TYPE = {
    waiting: 'green',
    pickup: 'orange',
    delivery: 'purple',
    inHouse: 'blue',
    reservation: 'yellow',
    IWAITER_APP: '#0a2240',
    CUSTOM_ORDER: "magenta"
}

export const SUBSCRIPTION_TYPES = {
    MENU: 'MENU',
    STANDER: 'STANDER',
    SILVER: 'SILVER',
    GOLD: 'GOLD',
    PAY_AS_YOU_GO: 'GOLD',
}


export const FoodAllergyTYPES = {
    CORN: 'CORN',
    EGG: 'EGG',
    FISH: 'FISH',
    GLUTEN: 'GLUTEN',
    MILK: 'MILK',
    NUTS: 'NUTS',
    GMO: 'GMO',
    PEANUTS: 'PEANUTS',
    SHELLFISH: 'SHELLFISH',
    SOYA: 'SOYA',
    SULPHITES: 'SULPHITES',
    VEGAN: 'VEGAN',

}

export const DEFAULT_WALLET_NAME = {
    IWAITER_WALLET: 'IWAITER_WALLET',
}

export const SEND_NOTIFICATION_SCOPES = {
    WAITING_SERVED: 'WAITING_SERVED',
    WAITING_DELETED: 'WAITING_DELETED',
    ORDER_PICKUP_READY: 'ORDER_PICKUP_READY',
    ORDER_DINE_IN_READY: 'ORDER_DINE_IN_READY',
}

export const ALL_DAYS = [
    {
        value: 'SUNDAY',
    },
    {
        value: 'MONDAY',
    },
    {
        value: 'TUESDAY',
    },
    {
        value: 'WEDNESDAY',
    },
    {
        value: 'THURSDAY',
    },
    {
        value: 'FRIDAY',
    },
    {
        value: 'SATURDAY',
    },
]

export const DEFAULT_WALET_INFO = {
    Iban: 'SA4720000002303364629940',
    BankAccountHolderName: 'شركة رؤيا الذكاء التجارية',
    BankId: '48',
}


export const TAG_COLORS_BY_STATUS_ALL_TYPES = {
    "open": 'green',
    "inKitchen": 'orange',
    "ready": 'cyan',
    'inDelivery': 'purple',
    "inService": 'orange',
    "served": 'cyan',
    "pending": 'orange',
    "rejected": 'red',
    "timeout": 'red',
}


export const COVERED_CITIES = [
    // Riyadh partially and fully in jedda, Buraidah, Unizah, Medina, Yanbu,Mecca, Taif, Dammam, Khobar, Dhahran,Abha, Al AHasa 
    'Jeddah',
    'Buraidah',
    'Unayzah',
    'Madinah',
    'Yanbu',
    'Makkah',
    'At Taif',
    'Dammam',
    'Al Khobar',
    'Dhahran',
    'Abha',
    'Al Ahsa',
    'Riyadh'
];


export const categoriesTypesTag = {
    "ALL": {
        color: 'green',
        text: 'all'
    },
    "DINEIN": {
        color: 'green',
        text: 'inHouse'
    },
    "PICKUP": {
        color: 'orange',
        text: 'pickup'
    },
    "DELIVERY": {
        color: 'orange',
        text: 'delivery'
    },
    
}


export const CATEGORIES_TYPES = {
    ALL: "ALL",
    DINEIN: "DINEIN",
    PICKUP: "PICKUP",
    DELIVERY: "DELIVERY",
  };