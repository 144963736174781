import React, { useState, useEffect } from "react";
import { Table } from "ant-table-extensions";
import { Typography, Divider } from "antd";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { FileExcelOutlined } from "@ant-design/icons";
import COLORS from "../../../Style/colors";
import { customerExport } from "../../../helpers";

const { Text, Title } = Typography;

const TableDetails = ({ foods, category }) => {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);

  const [formattedFoods, setFormattedFoods] = useState([]);
  const [formattedCat, setFormattedCat] = useState([]);
  useEffect(() => {
    if (foods?.length) {
      setFormattedFoods(
        foods.map((food) => {
          return {
            key: food?._id,
            justName: rtl ? food.name : food.name_en,
            NumberSold: food.totalSoled,
            popularity: `${food.itemPopularity?.toFixed()}%`,
            itemCost: food.cost,
            itemPrice: food.price,
            itemProfit: food.itemProfit?.toFixed(2),
            totalCost: food.totalCost,
            totalRevenue: food.totalRevenue?.toFixed(2),
            totalProfit: food.totalProfit?.toFixed(2),
            profitCategory: food.profitCategory,
            popularityCategory: food.popularityCategory,
            menuItemClass: food.foodClass,
          };
        })
      );
    }
    if (category) {
      const format = {
        totalCategorySold: category.totalCategorySold?.toFixed(2),
        totalCategoryCost: category.totalCategoryCost?.toFixed(2),
        totalCategoryProfit: category.totalCategoryProfit?.toFixed(2),
        totalCategoryRevenue: category.totalCategoryRevenue?.toFixed(2),
        averageFoodCost: category.averageFoodCost?.toFixed(2),
        averageFoodProfit: category.averageFoodProfit?.toFixed(2),
        menuPopularityFactor: category.menuPopularityFactor?.toFixed(2),
      };
      setFormattedCat([format]);
    }
  }, [foods, category]);

  const columns = [
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "justName",
      key: "justName",
    },
    {
      title: intl.formatMessage({ id: "NumberSold" }),
      dataIndex: "NumberSold",
      key: "NumberSold",
    },
    {
      title: intl.formatMessage({ id: "popularity" }),
      dataIndex: "popularity",
      key: "popularity",
    },
    {
      title: intl.formatMessage({ id: "itemCost" }),
      dataIndex: "itemCost",
      key: "itemCost",
    },
    {
      title: intl.formatMessage({ id: "itemPrice" }),
      dataIndex: "itemPrice",
      key: "itemPrice",
    },
    {
      title: intl.formatMessage({ id: "itemProfit" }),
      dataIndex: "itemProfit",
      key: "itemProfit",
    },
    {
      title: intl.formatMessage({ id: "totalCost" }),
      dataIndex: "totalCost",
      key: "totalCost",
    },
    {
      title: intl.formatMessage({ id: "totalRevenue" }),
      dataIndex: "totalRevenue",
      key: "totalRevenue",
    },
    {
      title: intl.formatMessage({ id: "totalProfit" }),
      dataIndex: "totalProfit",
      key: "totalProfit",
    },
    {
      title: intl.formatMessage({ id: "profitCategory" }),
      dataIndex: "profitCategory",
      key: "profitCategory",
    },
    {
      title: intl.formatMessage({ id: "popularityCategory" }),
      dataIndex: "popularityCategory",
      key: "popularityCategory",
    },
    {
      title: intl.formatMessage({ id: "menuItemClass" }),
      dataIndex: "menuItemClass",
      key: "menuItemClass",
    },
  ];

  const CategoryColumn = [
    {
      title: intl.formatMessage({ id: "totalCategorySold" }),
      dataIndex: "totalCategorySold",
      key: "totalCategorySold",
    },
    {
      title: intl.formatMessage({ id: "totalCategoryCost" }),
      dataIndex: "totalCategoryCost",
      key: "totalCategoryCost",
    },
    {
      title: intl.formatMessage({ id: "totalCategoryProfit" }),
      dataIndex: "totalCategoryProfit",
      key: "totalCategoryProfit",
    },
    {
      title: intl.formatMessage({ id: "totalCategoryRevenue" }),
      dataIndex: "totalCategoryRevenue",
      key: "totalCategoryRevenue",
    },
    {
      title: intl.formatMessage({ id: "averageFoodCost" }),
      dataIndex: "averageFoodCost",
      key: "averageFoodCost",
    },
    {
      title: intl.formatMessage({ id: "averageFoodProfit" }),
      dataIndex: "averageFoodProfit",
      key: "averageFoodProfit",
    },
    {
      title: intl.formatMessage({ id: "menuPopularityFactor" }),
      dataIndex: "menuPopularityFactor",
      key: "menuPopularityFactor",
    },
  ];

  return (
    <div className='pb-5'>
      <Table
        exportable
        exportableProps={{
          showColumnPicker: true,
          btnProps: {
            icon: <FileExcelOutlined />,
            type: "primary",
            style: { borderRadius: 7 },
            onClick: () =>
                customerExport({
                  data: formattedFoods,
                  columns,
                  fileName: "Food Report",
                  loadingSetter: () => true,
                }),
          },
          children: (
            <Text
              className='my-font'
              style={{
                color: COLORS.white,
              }}
            >
              {intl.formatMessage({ id: "export" })}
            </Text>
          ),
        }}
        columns={columns}
        pagination={{
          hideOnSinglePage: true,
        }}
        dataSource={formattedFoods}
        scroll={{ x: 1200 }}
      />
      <Divider />
      <Title level={3}>{intl.formatMessage({ id: "classesInfo" })}</Title>
      <Table
        exportable
        exportableProps={{
          showColumnPicker: true,
          btnProps: {
            icon: <FileExcelOutlined />,
            type: "primary",
            style: { borderRadius: 7 },
            onClick: () =>
                customerExport({
                  data: formattedCat,
                  columns: CategoryColumn,
                  fileName: "Category Report",
                  loadingSetter: () => true,
                }),
          },
          children: (
            <Text
              className='my-font'
              style={{
                color: COLORS.white,
              }}
            >
              {intl.formatMessage({ id: "export" })}
            </Text>
          ),
        }}
        columns={CategoryColumn}
        pagination={{
          hideOnSinglePage: true,
        }}
        dataSource={formattedCat}
        scroll={{ x: 1200 }}
      />
    </div>
  );
};

export default TableDetails;
