import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import UseQuery from "../../../Hooks/UseQuery";
import COLORS from "../../../Style/colors";
import { Result, Typography, Spin } from "antd";
import CustomButton from "../../../Components/CustomButton";
import { postUpdateIntegrations } from "../../../API/fetch";
import { setIntegrationsAction } from "../../../redux/reducers/restaurantReducer";
import openNotification from "../../../Components/Notifications";
const { Title } = Typography;

function Foodics() {
  const intl = useIntl();
  const query = UseQuery();
  const { rtl } = useSelector((state) => state.settings);
  const history = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const code = query.get("code");
  const state = query.get("state");

  useEffect(() => {
    const storeSlug = localStorage.getItem("slug");
    const isNewRestaurant = localStorage.getItem("onBoardingWithFoodics");
    if (isNewRestaurant && isNewRestaurant === storeSlug) {
      // this is a new restaurant
      if (state === storeSlug && code) {
        getFoodicsToken({ isNew: true });
        localStorage.removeItem("slug");
        localStorage.removeItem("onBoardingWithFoodics");
      } else {
        setError(true);
        localStorage.removeItem("slug");
        localStorage.removeItem("onBoardingWithFoodics");
        setLoading(false);
      }
    } else {
      // old restaurant want to connect to Foodics;
      if (state === storeSlug && code) {
        getFoodicsToken({ isNew: false });
        localStorage.removeItem("slug");
        localStorage.removeItem("onBoardingWithFoodics");
      } else {
        setError(true);
        localStorage.removeItem("slug");
        localStorage.removeItem("onBoardingWithFoodics");
        setLoading(false);
      }
    }
  }, [code, state]);

  const getFoodicsToken = async ({ isNew }) => {
    const data = {
      type: "POS",
      isNewRestaurant: isNew ? "NewRestaurant" : "OldRestaurant",
      integration: {
        code,
        name: "foodics",
      },
    };
    try {
      const res = await postUpdateIntegrations(data);
      if (res?.data?.integration) {
        dispatch(setIntegrationsAction(res.data.integration));
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
        
        history("/new-foodics");
        setLoading(false);
      } else {
        setLoading(false);
        setError(true);
      }
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  return (
    <div
      className='light-shadow border-8'
      style={{
        minHeight: "80vh",
        backgroundColor: COLORS.white,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {loading ? (
        <Spin size='large' />
      ) : error ? (
        <Result
          status='error'
          title={
            <Title level={3}>
              {intl.formatMessage({ id: "errorFoodics" })}
            </Title>
          }
          extra={[
            <CustomButton
              key='back'
              text={intl.formatMessage({ id: "goBack" })}
              type='primary'
              className='px-5 border-8'
              onClick={() => history(-1)}
            />,
          ]}
        />
      ) : (
        <Result
          status='error'
          title={
            <Title level={3}>{intl.formatMessage({ id: "errorfetch" })}</Title>
          }
          extra={[
            <CustomButton
              key='back'
              text={intl.formatMessage({ id: "goBack" })}
              type='primary'
              className='px-5 border-8'
              onClick={() => history(-1)}
            />,
          ]}
        />
      )}
    </div>
  );
}

export default Foodics;
