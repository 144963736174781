import React, { useState, useRef, useEffect } from "react";
import { Layout, Menu, Image, Typography } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import LOGO from "../../assets/images/main-logo.png";
import ICON from "../../assets/images/icon-iwaiter.png";
import { IoLanguageOutline, IoNotificationsOutline } from "react-icons/io5";
import WaiterSide from "./WaiterSide";
import SuperUserSide from "./SuperUserSide";
import buildNumber from "../../buildNumber";
import ShowHelpModal from "../ShowHelpModal";
import { useIntl } from "react-intl";
import { changeLanguageAction } from "../../redux/reducers/settingsReducer";


const { Header, Content, Footer, Sider } = Layout;
const { Text } = Typography;
const Sidebar = ({ children, component }) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { rtl, isBigScreen } = useSelector((state) => state.settings);
  const { role, waiter } = useSelector((state) => state.auth);
  const { branch } = useSelector((state) => state.branch);
  const sideRef = useRef();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [sideBarWidth, setSideBarWidth] = useState(200);
  const [branchName, setBranchName] = useState('')
  const [showHelp, setShowHelp] = useState(false);
  useEffect(() => {
    if (window?.Olvy) {
      new window.Olvy('iwaiter')
    }
  }, [])

  useEffect(() => {
    if (role !== 'super') {
      setBranchName(rtl ? branch?.name : branch?.name_en)
    }
  }, [waiter, rtl, role])

  useEffect(() => {
    if (sideRef.current) {
      setSideBarWidth(sideRef.current.offsetWidth);
    }
  }, [sideRef?.current?.offsetWidth]);

  useEffect(() => {
    if (isCollapsed) {
      if (isBigScreen) {
        setSideBarWidth(60);
      } else {
        setSideBarWidth(0);
      }
    } else {
      setSideBarWidth(230);
    }
  }, [isCollapsed, isBigScreen]);
  // console.log(waiter)

  const toggleSideBar = () => {
    if (!isCollapsed && !isBigScreen) {
      setIsCollapsed(true);
    }
  }

  return (
    <Layout>
      <Header
        style={{
          paddingLeft: rtl ? 5 : sideBarWidth,
          paddingRight: rtl ? sideBarWidth : 5,
          position: "absolute",
          width: "100vw",
        }}
      >
        <ShowHelpModal showHelp={showHelp} setShowHelp={setShowHelp} />
        <Menu
          theme='light'
          mode='horizontal'
          style={{
            justifyContent: "flex-end",
            width: '98%'
          }}
          defaultSelectedKeys={["1"]}
        >

          <Menu.Item key="waiterName" disabled={true} >
            <Text>{waiter?.name}{branchName ? ` - ${branchName}` : ''}</Text>
          </Menu.Item>
          <div
            onClick={() => {
              dispatch(changeLanguageAction());
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer"
            }}
            className="mx-5"
          >
            {/* <IoLanguageOutline size={20} style={{ margin: 3 }} /> */}
            <Text className='my-font'>
              {intl.formatMessage({ id: "oppLanguage" })}
            </Text>
          </div>

          <div
            id="olvy-whats-new"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "50px",
              cursor: "pointer"
            }}
          >
            <IoNotificationsOutline style={{ fontSize: 20 }} />
          </div>
        </Menu>
      </Header>
      <Sider
        breakpoint='lg'
        collapsedWidth={isBigScreen ? "60" : "0"}
        className='light-shadow'
        collapsible
        width={sideBarWidth}
        trigger={null}
        collapsed={isCollapsed}
        onBreakpoint={(collapsed) => {
          setIsCollapsed(collapsed);
        }}
        style={{
          zIndex: 99,
        }}
      >
        {sideBarWidth > 100 ? (
          <div style={{ display: 'flex', flexDirection: "column" }}>
            <Image
              src={LOGO}
              preview={false}
              width={180}
              style={{ marginLeft: rtl ? 0 : 10, marginRight: rtl ? 10 : 0 }}
            />
            <Text className='my-font' style={{ textAlign: 'center', }}>{`V${buildNumber.buildMajor}.${buildNumber.buildMinor}.${buildNumber.buildRevision}`}</Text>
          </div>
        ) : (
          <div style={{ display: 'flex', flexDirection: "column" }}>
            <Image
              src={ICON}
              preview={false}
              width={50}
              style={{ marginLeft: rtl ? 0 : 5, marginRight: rtl ? 5 : 0 }}
            />
            <Text className='my-font' style={{ textAlign: "center" }}>{`V${buildNumber.buildMajor}.${buildNumber.buildMinor}.${buildNumber.buildRevision}`}</Text>
          </div>
        )}
        {role === "super" ? <SuperUserSide toggleSideBar={toggleSideBar} /> : <WaiterSide toggleSideBar={toggleSideBar} />}
      </Sider>
      <Layout
        className='site-layout'
        style={{
          marginLeft: rtl ? 0 : sideBarWidth,
          marginRight: rtl ? sideBarWidth : 0,
          marginTop: 60,
          minHeight: "95vh",
        }}
      >
        <div className='toggle-bar'>
          {isCollapsed ? rtl ? (
            <MenuFoldOutlined
              style={{ fontSize: 20 }}
              onClick={() => setIsCollapsed(!isCollapsed)}
            />

          ) : (<MenuUnfoldOutlined
            style={{ fontSize: 20 }}
            onClick={() => setIsCollapsed(!isCollapsed)}
          />) : rtl ? (
            <MenuUnfoldOutlined
              style={{ fontSize: 20 }}
              onClick={() => setIsCollapsed(!isCollapsed)}
            />
          ) : <MenuFoldOutlined
            style={{ fontSize: 20 }}
            onClick={() => setIsCollapsed(!isCollapsed)}
          />}
        </div>
        <Content
          style={{
            margin: "16px 16px 0",
            overflow: "initial",
          }}
        >
          {component}
        </Content>
        <Footer style={{ textAlign: "center" }}>
          Made by iwaiter team ©{new Date().getFullYear()}
        </Footer>
      </Layout>
    </Layout>
  );
};

export default Sidebar;
