import React, { useState, useEffect } from "react";
import { Table, Typography } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { arrayMoveImmutable } from "array-move";
import COLORS from "../../../../Style/colors";
import { IoHeartSharp, IoHeartOutline } from "react-icons/io5";
const { Text } = Typography;

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
));

const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

const FoodsSort = ({ categoryData, index, setFoodsSortOnOriginalCategory }) => {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  const [dataSource, setDataSource] = useState([]);

  // set the data;
  useEffect(() => {
    if (categoryData?.foods?.length) {
      const data = categoryData.foods?.map((food) => {
        return {
          ...food,
          key: food.foodId,
          showName: rtl ? food.name : food.name_en,
          index: index,
        };
      });
      setDataSource(data);
    }
  }, [categoryData]);

  // set the columns;
  const columns = [
    {
      title: intl.formatMessage({ id: "sort" }),
      dataIndex: "sort",
      width: 30,
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "showName",
      key: "showName",
      render: (text, record) => {
        return (
          <Text
            style={{
              display: "flex",
            }}
            className='my-font'
          >
            {text}
            <Text
              style={{
                display: "flex",
                marginRight: rtl ? 10 : 0,
                marginLeft: rtl ? 0 : 10,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" - "}
              {record?.likes?.length}
              {record?.likes?.length > 0 ? (
                <IoHeartSharp
                  size={18}
                  color={COLORS.primary}
                  style={{
                    marginRight: rtl ? 1 : 0,
                    marginLeft: rtl ? 0 : 1,
                  }}
                />
              ) : (
                <IoHeartOutline
                  size={18}
                  color={COLORS.primary}
                  style={{
                    marginRight: rtl ? 5 : 0,
                    marginLeft: rtl ? 0 : 5,
                  }}
                />
              )}
            </Text>
          </Text>
        );
      },
    },
  ];

  // on food sort end change the foods arr inside the category object in the parent;
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        [].concat(dataSource),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      setDataSource(newData);
      setFoodsSortOnOriginalCategory({
        foods: newData,
        categoryId: categoryData?.categoryId,
      });
    }
  };

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass='row-dragging sort-row'
      style={{ flexDirection: rtl ? "row-reverse" : "row" }}
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = dataSource.findIndex(
      (x) => x.foodId === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      className='components-table-demo-nested'
      showHeader={false}
      rowKey='foodId'
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow,
        },
      }}
    />
  );
};

export default FoodsSort;
