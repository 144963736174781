import React, { useState, useEffect } from "react";
import { Spin, Tag, Typography } from "antd";
import { useIntl } from "react-intl";
import COLORS from "../../Style/colors";
import { getIwaiterDetailsDeposit } from "../../API/fetch";
import { Table } from "ant-table-extensions";
import tableExport from "antd-table-export";
import { useSelector } from "react-redux";
import { TAG_COLOR_BY_USER_TYPE } from "../../Types";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import CustomButton from "../../Components/CustomButton";
import moment from "moment";

const { Title, Text } = Typography;
const OneIwaiterDeposit = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { rtl } = useSelector((state) => state.settings);
  const { currency, restaurant } = useSelector((state) => state.restaurant);

  const [initialLoading, setInitialLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paymentTransaction, setPaymentTransaction] = useState(null);
  // get the id from the url;
  const params = useParams();

  useEffect(() => {
    if (params?.id) {
      console.log("params?.id", params?.id);
      getOnePaymentData();
    }
  }, []);

  const getIwaiterFess = (orderTotal, paymentCardType) => {
    let visaPercentage = 2.4;
    let madaPercentage = 1.9;
    let tabbyPercentage = 9;
    // update percentage for iwaiter
    if (restaurant?.percentageForIwaiter) {
      visaPercentage = restaurant?.percentageForIwaiter;
    }
    if (restaurant?.percentageForIWaiterMADA) {
      madaPercentage = restaurant?.percentageForIWaiterMADA;
    }
    if (restaurant?.percentageForIWaiterTabby) {
      tabbyPercentage = restaurant?.percentageForIWaiterTabby;
    }
    let percentage = visaPercentage;
    if (paymentCardType?.toLowerCase() === "mada") {
      percentage = madaPercentage;
    } else if (paymentCardType === "TABBY_INSTALLMENT") {
      percentage = tabbyPercentage;
    }
    let fees = orderTotal * (percentage / 100) * 1.15;
    return fees;
  };
  const getOnePaymentData = async () => {
    try {
      const res = await getIwaiterDetailsDeposit(params?.id);
      if (res?.data?.paymentTransaction) {
        setPaymentTransaction(res?.data?.paymentTransaction);
      }
      let formatted = res.data?.orders?.map((one) => {
        let orderTotal = parseFloat(one?.total);
        let iwaiterTotalFees = getIwaiterFess(orderTotal, one.paymentCardType);
        let netDeposit = orderTotal - iwaiterTotalFees;
        return {
          key: one?.orderId,
          orderNumber: one?.orderNumber,
          orderTotal: orderTotal?.toFixed(2),
          paymentFees: iwaiterTotalFees?.toFixed(2),
          netDeposit: netDeposit?.toFixed(2),
          type: one?.type,
          createdAt: one?.createdAt,
          orderId: one?.orderId,
        };
      });
      setOrders(formatted);
      setInitialLoading(false);
    } catch (error) {
      console.log(error);
      setInitialLoading(false);
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: "orderNumber" }),
      dataIndex: "orderNumber",
      key: "orderNumber",
    },
    {
      title: intl.formatMessage({ id: "orderTotal" }),
      dataIndex: "orderTotal",
      key: "orderTotal",
      render: (text, record, index) => {
        return (
          <Text>
            {text} {currency || "SAR"}
          </Text>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "paymentFees" }),
      dataIndex: "paymentFees",
      key: "paymentFees",
      render: (text, record, index) => {
        return (
          <Text>
            {text} {currency || "SAR"}
          </Text>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "netDeposit" }),
      dataIndex: "netDeposit",
      key: "netDeposit",
      render: (text, record, index) => {
        return (
          <Text strong>
            {text} {currency || "SAR"}
          </Text>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "type" }),
      dataIndex: "type",
      key: "type",
      render: (text, record, index) => {
        return (
          <Tag color={TAG_COLOR_BY_USER_TYPE[text]}>
            {intl.formatMessage({ id: text ? text : "N/A" })}
          </Tag>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "date" }),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record, index) => {
        return (
          <Text type='secondary'>
            {moment(text).format("DD/MM/YYYY  hh:mm A")}{" "}
          </Text>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "view" }),
      dataIndex: "orderId",
      key: "orderId",
      render: (orderId, record, index) => {
        return (
          <Link to={getLink(record?.type, orderId)} target='_blank'>
            {intl.formatMessage({ id: "view" })}
          </Link>
        );
      },
    },
  ];

  const getLink = (type, orderId) => {
    let route = "orders";
    if (type === "reservation") route = "reservations";
    if (type === "delivery") route = "delivery";
    if (type === "pickup") route = "pickup";
    return `/${route}/${orderId}`;
  };

  const customerExport = () => {
    const fileName = `Deposit Report ${params?.id}`;
    setLoading(true);
    let formattedData = orders.map((one) => {
      let orderTotal = parseFloat(one?.orderTotal);
      let netDeposit = parseFloat(one?.netDeposit);
      let paymentFees = orderTotal - netDeposit;
      return {
        orderNumber: one.orderNumber,
        orderTotal,
        paymentFees,
        netDeposit,
        createdAt: moment(one.createdAt).format("DD/MM/YYYY  hh:mm A"),
        type: one.type,
        orderId: `${window.location.origin}${getLink(one?.type, one?.orderId)}`,
      };
    });
    const exportInstance = new tableExport(formattedData, columns);
    exportInstance.download(fileName, "xlsx");
    setLoading(false);
  };

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          marginBottom: 10,
          flexDirection: "column",
        }}
      >
        <CustomButton
          icon={rtl ? <ArrowRightOutlined /> : <ArrowLeftOutlined />}
          text={intl.formatMessage({ id: "goBack" })}
          style={{
            borderRadius: 10,
            padding: "0px 20px",
            marginBottom: 10,
          }}
          onClick={() => {
            navigate("/paymentRecords");
          }}
        />
      </div>
      <Spin spinning={initialLoading}>
        <div
          style={{
            backgroundColor: COLORS.white,
            marginTop: 10,
            width: "92%",
            marginRight: "auto",
            marginLeft: "auto",
            borderRadius: 10,
            padding: 15,
            textAlign: "center",
          }}
          className='light-shadow'
        >
          <Title level={5}>
            {intl.formatMessage({ id: "depositDetailsNumber" })}{" "}
            <Tag
              style={{
                marginRight: 10,
                marginLeft: 10,
                fontSize: 16,
                borderRadius: 5,
                padding: "3px 10px",
              }}
            >
              {paymentTransaction?.transactionNumber}
            </Tag>
          </Title>
          {orders?.length ? (
            <Table
              exportable
              exportableProps={{
                showColumnPicker: true,
                disabled: loading || orders?.length === 0,
                loading: loading,
                btnProps: {
                  icon: <FileExcelOutlined />,
                  type: "primary",
                  style: { borderRadius: 7 },
                  onClick: () => customerExport(),
                },
                children: (
                  <Text
                    className='my-font'
                    style={{
                      color: orders?.length === 0 ? "#dddddd" : COLORS.white,
                    }}
                  >
                    {intl.formatMessage({ id: "export" })}
                  </Text>
                ),
              }}
              scroll={{ x: 1000 }}
              pagination={{
                hideOnSinglePage: true,
                pageSizeOptions: [10, 20, 50, 100, 500],
              }}
              columns={columns}
              dataSource={orders}
            />
          ) : (
            <Text>{intl.formatMessage({ id: "noData" })}</Text>
          )}
        </div>
      </Spin>
    </div>
  );
};

export default OneIwaiterDeposit;
