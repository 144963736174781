import React, { useState, useEffect } from "react";
import QRPreview from "./Components/QRPreview";
import {
  Row,
  Col,
  Typography,
  Form,
  Collapse,
  Divider,
  Tag,
  Input,
  Select,
} from "antd";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import COLORS from "../../Style/colors";
import openNotification from "../../Components/Notifications";
import { SketchPicker } from "react-color";
import CustomButton from "../../Components/CustomButton";
import { editDesign, getPDFFile } from "../../API/fetch";
import { setQrAction } from "../../redux/reducers/restaurantReducer";
import { DownloadOutlined } from "@ant-design/icons";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { getContrastYIQ } from "../../helpers";
import URLSTables from "./Components/URLSTables";

const { Title, Text } = Typography;
const { Panel } = Collapse;
const { Option } = Select;

const mainPageKeys = {
  backgroundColor: "backgroundColor",
  textColor: "textColor",
  qrBackground: "qrBackground",
  qrColor: "qrColor",
};

const QRView = () => {
  const intl = useIntl();
  // const refComponent = useRef(null);
  const dispatch = useDispatch();
  const { restaurant, qr } = useSelector((state) => state.restaurant);
  const { isBigScreen, rtl } = useSelector((state) => state.settings);
  const { allBranches } = useSelector((state) => state.branch);
  const { services } = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState("A5");

  useEffect(() => {
    setTimeout(() => {
      form.resetFields();
    }, 300);
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const data = {
        restaurant: {
          qr: {
            ...qr,
            ...values,
          },
        },
      };
      const res = await editDesign(data, restaurant._id, "type=qr");
      if (res.data?.result?.qr) {
        dispatch(setQrAction(res.data.result.qr));
      }
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
    } catch (error) {
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const getQrPdf = async () => {
    try {
      setLoading(true);
      let formatTables = form.getFieldValue("tables");
      if (!services?.includes("ORDER_A") && !services?.includes("ORDER_B")) {
        formatTables = [];
      }
      const data = {
        backgroundColor: form.getFieldValue("backgroundColor"),
        textColor: form.getFieldValue("textColor"),
        qrBackground: form.getFieldValue("qrBackground"),
        qrColor: form.getFieldValue("qrColor"),
        title: form.getFieldValue("title"),
        titleEn: form.getFieldValue("titleEn"),
        subTitle: form.getFieldValue("subTitle"),
        subTitleEn: form.getFieldValue("subTitleEn"),
        footer: form.getFieldValue("footer"),
        footerEn: form.getFieldValue("footerEn"),
        branch: form.getFieldValue("branch"),
        tables: formatTables,
        showLogoInQR: form.getFieldValue("includeLogoInQR"),
        size,
        name: restaurant?.name,
        nameEn: restaurant?.name_en,
        imageURL: restaurant?.logo?.[0]?.url,
        slug: restaurant?.slug,
        domain: restaurant?.domain,
        services,
        CLINT_URL: "https://app.iwaiter.club",
        language: form.getFieldValue("language"),
      };
      const res = await getPDFFile(data);
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${restaurant?.name_en}-qr.pdf`);
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    } catch (error) {
      console.log(error);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
      setLoading(false);
    }
  };

  return (
    <div>
      <div>
        <Title level={4}>{intl.formatMessage({ id: "qrs" })}</Title>
      </div>
      <div
        style={{
          backgroundColor: COLORS.white,
          borderRadius: 6,
          width: "92%",
          marginRight: "auto",
          marginLeft: "auto",
          padding: 15,
        }}
        className='light-shadow'
      >
        <Form
          name='viewCart-form'
          form={form}
          className='form-container'
          layout='vertical'
          onFinish={onFinish}
          initialValues={{
            ...qr,
            branch: allBranches?.[0]?.branch?._id,
            tables: allBranches?.[0]?.tables,
            tableIds: [],
            includeLogoInQR: true,
            language: "AR",
          }}
        >
          {() => {
            return (
              <div>
                <Row type='flex'>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "titleInArabic" })}
                      className='mb-1'
                      name='title'
                      style={{ borderRadius: 20 }}
                    >
                      <Input
                        className={"input"}
                        style={{ borderRadius: 5, width: "80%" }}
                      />
                    </Form.Item>
                    <Form.Item
                      label={intl.formatMessage({ id: "titleInEnglish" })}
                      className='mb-1'
                      name='titleEn'
                      style={{ borderRadius: 20 }}
                    >
                      <Input
                        className={"input"}
                        style={{ borderRadius: 5, width: "80%" }}
                      />
                    </Form.Item>
                    <Form.Item
                      label={intl.formatMessage({ id: "subTitleInArabic" })}
                      className='mb-1'
                      name='subTitle'
                      style={{ borderRadius: 20 }}
                    >
                      <Input
                        className={"input"}
                        style={{ borderRadius: 5, width: "80%" }}
                      />
                    </Form.Item>
                    <Form.Item
                      label={intl.formatMessage({ id: "subTitleInEnglish" })}
                      className='mb-1'
                      name='subTitleEn'
                      style={{ borderRadius: 20 }}
                    >
                      <Input
                        className={"input"}
                        style={{ borderRadius: 5, width: "80%" }}
                      />
                    </Form.Item>
                    <Form.Item
                      label={intl.formatMessage({ id: "footerInArabic" })}
                      className='mb-1'
                      name='footer'
                      style={{ borderRadius: 20 }}
                    >
                      <Input
                        className={"input"}
                        style={{ borderRadius: 5, width: "80%" }}
                      />
                    </Form.Item>
                    <Form.Item
                      label={intl.formatMessage({ id: "footerInEnglish" })}
                      className='mb-1'
                      name='footerEn'
                      style={{ borderRadius: 20 }}
                    >
                      <Input
                        className={"input"}
                        style={{ borderRadius: 5, width: "80%" }}
                      />
                    </Form.Item>
                    {Object.keys(mainPageKeys).map((key) => {
                      return (
                        <Collapse ghost key={key}>
                          <Panel
                            header={
                              <>
                                <Text className='my-font'>
                                  {intl.formatMessage({
                                    id: key,
                                  })}
                                </Text>
                                {"  "}
                                <Tag
                                  color={form.getFieldValue(key)}
                                  style={{
                                    color: getContrastYIQ(
                                      form?.getFieldValue(key)
                                    ),
                                  }}
                                >
                                  {form.getFieldValue(key)}
                                </Tag>
                              </>
                            }
                            key={key}
                          >
                            <Form.Item
                              tooltip={
                                <Text type='primary' className='my-font'>
                                  {intl.formatMessage({
                                    id: key,
                                  })}
                                </Text>
                              }
                              className='mb-1'
                              name={key}
                              style={{ borderRadius: 20 }}
                              valuePropName='color'
                              getValueFromEvent={(color) => color.hex}
                            >
                              <SketchPicker />
                            </Form.Item>
                          </Panel>
                        </Collapse>
                      );
                    })}
                    <Divider />
                    <Text className='my-font' type='secondary'>
                      * {intl.formatMessage({ id: "useChrome" })}
                    </Text>
                    <Row justify='start' className='mt-5' align='middle'>
                      <Col md={12} sm={24} xs={24}>
                        <Form.Item>
                          <CustomButton
                            htmlType='submit'
                            text={intl.formatMessage({ id: "save" })}
                            className={`btnRegister btn-text border-8 px-5 `}
                            loading={loading}
                            disabled={loading}
                            type='primary'
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} sm={24} xs={24}>
                        <Form.Item>
                          <CustomButton
                            text={intl.formatMessage({ id: "download" })}
                            className={`btnRegister btn-text border-8 px-3 `}
                            loading={loading}
                            disabled={loading}
                            icon={<DownloadOutlined />}
                            type='primary'
                            onClick={getQrPdf}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} sm={24} xs={24}>
                        <URLSTables
                          loading={loading}
                          tables={form?.getFieldValue("tables")}
                          branch={form?.getFieldValue("branch")}
                          slug={restaurant?.slug}
                          domain={restaurant?.domain}
                          services={services}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    style={{ height: isBigScreen ? 750 : 650 }}
                    md={12}
                    sm={24}
                    xs={24}
                  >
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label={intl.formatMessage({ id: "chooseQRSize" })}
                        >
                          <Select
                            onChange={(e) => setSize(e)}
                            className='my-font'
                            value={size}
                          >
                            <Option className='my-font' value='A5'>
                              A5
                            </Option>
                            <Option className='my-font' value='A6'>
                              A6
                            </Option>
                            <Option className='my-font' value='A7'>
                              A7
                            </Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label={intl.formatMessage({ id: "chooseBranch" })}
                          name='branch'
                        >
                          <Select
                            onChange={(e) => {
                              form.setFieldsValue({ tables: [], tableIds: [] });
                            }}
                            className='my-font'
                          >
                            {allBranches?.length
                              ? allBranches?.map((b) => {
                                  return (
                                    <Option
                                      className='my-font'
                                      key={b?.branch?._id}
                                      value={b?.branch?._id}
                                    >
                                      {rtl
                                        ? b?.branch?.name
                                        : b?.branch?.name_en}
                                    </Option>
                                  );
                                })
                              : null}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label={intl.formatMessage({ id: "chooseQRLanguage" })}
                          name='language'
                        >
                          <Select className="my-font">
                            <Option className='my-font' value='AR'>
                              {intl.formatMessage({ id: "arabic" })}
                            </Option>
                            <Option className='my-font' value='EN'>
                              {intl.formatMessage({ id: "english" })}
                            </Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={11}>
                        <Form.Item
                          label={intl.formatMessage({ id: "selectTables" })}
                          name='tableIds'
                          tooltip={
                            <Text>{intl.formatMessage({ id: "maxIs70" })}</Text>
                          }
                        >
                          <Select
                            mode='multiple'
                            maxTagCount={2}
                            className='my-font'
                            onChange={(e) => {
                              const selected = allBranches?.find(
                                (b) =>
                                  b?.branch?._id ===
                                  form?.getFieldValue("branch")
                              );
                              const tables = selected?.tables?.filter((t) =>
                                e.includes(t._id)
                              );
                              form.setFieldsValue({ tables });
                            }}
                          >
                            {allBranches
                              ?.find(
                                (b) =>
                                  b?.branch?._id ===
                                  form?.getFieldValue("branch")
                              )
                              ?.tables?.map((t) => {
                                return (
                                  <Option
                                    disabled={
                                      form?.getFieldValue("tableIds")?.length >
                                      69
                                    }
                                    className='my-font'
                                    key={t?._id}
                                    value={t?._id}
                                  >
                                    {rtl ? t?.name : t?.name_en} - {t?.zone}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    
                    <Row>
                      <Col
                        span={24}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Form.Item
                          label={intl.formatMessage({ id: "showLogoInQR" })}
                          valuePropName='checked'
                          name='includeLogoInQR'
                          style={{
                            display: "flex",
                            flexDirection: "row-reverse",
                            alignItems: "flex-end",
                          }}
                        >
                          <Checkbox
                            style={{ display: "flex" }}
                            className='mx-3'
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <QRPreview
                      backgroundColor={form.getFieldValue("backgroundColor")}
                      textColor={form.getFieldValue("textColor")}
                      qrBackground={form.getFieldValue("qrBackground")}
                      qrColor={form.getFieldValue("qrColor")}
                      title={form.getFieldValue("title")}
                      titleEn={form.getFieldValue("titleEn")}
                      subTitle={form.getFieldValue("subTitle")}
                      subTitleEn={form.getFieldValue("subTitleEn")}
                      footer={form.getFieldValue("footer")}
                      footerEn={form.getFieldValue("footerEn")}
                      branch={form.getFieldValue("branch")}
                      tables={form.getFieldValue("tables")}
                      allTables={
                        allBranches?.find(
                          (b) =>
                            b?.branch?._id === form?.getFieldValue("branch")
                        )?.tables
                      }
                      showLogoInQR={form.getFieldValue("includeLogoInQR")}
                      size={size}
                      name={restaurant.name}
                      nameEn={restaurant.name_en}
                      imageURL={restaurant.logo?.[0]?.url}
                      slug={restaurant.slug}
                      domain={restaurant.domain}
                    />
                  </Col>
                </Row>
                {isBigScreen ? null : (
                  <>
                    <Divider />
                    <Divider />
                    <Divider />
                  </>
                )}
              </div>
            );
          }}
        </Form>
      </div>
    </div>
  );
};

export default QRView;
