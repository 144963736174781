import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Image,
  Space,
  Typography,
  Card,
  Avatar,
  Spin,
  Result,
} from "antd";
import { useIntl } from "react-intl";
import ICON from "../../../assets/images/icon-white.png";
import CustomButton from "../../../Components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import  QRCode from "qrcode.react";
import { addAutoRestaurant } from "../../../API/fetch";
import { useNavigate } from "react-router-dom";
import openNotification from "../../../Components/Notifications/index";
import { setRestaurantAction } from "../../../redux/reducers/restaurantReducer";
import { setBranchAction, setBranchesAction } from "../../../redux/reducers/branchReducer";
import { setTablesAction } from "../../../redux/reducers/tableReducer";
import { CheckCircleFilled,  } from "@ant-design/icons";
const { Text, Title } = Typography;
const { Meta } = Card;

const RestaurantList = ({ result, loading, addManualRestaurant }) => {
  const intl = useIntl();
  const history = useNavigate();
  const dispatch = useDispatch();
  const [info, setInfo] = useState(null);
  const [loadingGetRestInfo, setLoadingGetRestInfo] = useState(false);
  const [showRestaurant, setShowRestaurant] = useState(false);
  const [menuData, setMenuData] = useState(null);
  const [showBarCode, setShowBarCode] = useState(false);

  const { rtl } = useSelector((state) => state.settings);
  const [fakeLoading, setFakeLoading] = useState(true);
  const { count, restaurants } = result;

  useEffect(() => {
    setTimeout(() => {
      if (fakeLoading) {
        setFakeLoading(false);
      }
    }, 4500);
  }, []);

  useEffect(() => {
    if (info?.restaurant) {
      let data = {};
      data.categoriesLength = "";
      data.longestCategoryFoods = "";
      data.countOfLongestCategoryFoods = "";
      data.foodsCount = "";
      data.higherPrice = -1;
      data.lowestPrice = -1;
      data.branch = "";
      data.table = "";
      data.slug = "";
      if (Object.keys(info).length > 0) {
        const { restaurant } = info;
        data.branch = restaurant.branch._id;
        data.table = restaurant.tables[0]._id;
        data.slug = restaurant.restaurant.slug;
        data.categoriesLength = restaurant.menu.length;
        data.longestCategoryFoods = restaurant.menu[0].name;
        data.countOfLongestCategoryFoods = restaurant.menu[0].foods.length;
        data.foodsCount = 0;

        restaurant.menu.forEach((item) => {
          if (item.foods.length > data.countOfLongestCategoryFoods) {
            data.countOfLongestCategoryFoods = item.foods.length;
            data.longestCategoryFoods = item.name;
          }
          data.foodsCount += item.foods.length;
          item.foods.forEach((food) => {
            if (data.higherPrice === -1) {
              if (food && food.price && food.price) {
                data.higherPrice = food.price;
                data.lowestPrice = food.price;
              }
            } else {
              if (food && food.price && food.price > data.higherPrice) {
                data.higherPrice = food.price;
              }
              if (food && food.price && food.price < data.lowestPrice) {
                data.lowestPrice = food.price;
              }
            }
          });
        });
      } else {
        console.log("No Info ");
      }
      setMenuData(data);
    }
  }, [info]);

  const selectRestaurant = async (info) => {
    const data = {
      info,
    };
    try {
      setLoadingGetRestInfo(true);
      const res = await addAutoRestaurant(data);
      if (Object.keys(res.data).includes("errors")) {
        // Mixpanel.track("error in auto restaurant", {
        //   restaurantName: info.name
        // })
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "errorfetch" }),
          type: "error",
          rtl,
        });
        setLoadingGetRestInfo(false);
        history("/");
        return;
      }
      setInfo(res.data);
      dispatch(setRestaurantAction(res.data.restaurant.restaurant));
      dispatch(setBranchesAction(res.data.restaurant.branches));
      dispatch(setBranchAction(res.data?.restaurant.branch));
      dispatch(setTablesAction(res.data.restaurant.tables));

      setTimeout(() => {
        setLoadingGetRestInfo(false);
        setShowRestaurant(true);
      }, 2000);
    } catch (error) {
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
      setLoadingGetRestInfo(false);
      history("/");
    }
  };

  return (
    <Row
      style={{ minHeight: "100vh", paddingTop: 40 }}
      className='add-restaurant-container'
      justify='center'
      align='top'
    >
      <Col
        xs={24}
        sm={20}
        type='flex'
        justify='center'
        align='center'
        direction='column'
        className={"authRightSide"}
        style={{ paddingBottom: 50 }}
      >
        <Row justify='center'>
          <Image
            preview={false}
            src={ICON}
            style={{ maxWidth: 50, marginBottom: 15, borderRadius: 30 }}
          />
          <Title className='mx-2'>iwaiter</Title>
        </Row>
        {loadingGetRestInfo ? (
          <Row
            type='flex'
            justify='center'
            align='middle'
            style={{ minHeight: "60vh", flexDirection: "column" }}
          >
            <Title className='my-1'>
              {intl.formatMessage({ id: "searching" })} . . .
            </Title>
            <Title level={3}>{intl.formatMessage({ id: "loadingLong" })}</Title>
            <Spin size='large' className='my-5' />
          </Row>
        ) : showRestaurant ? (
          <Row
            type='flex'
            justify='center'
            align='middle'
            style={{ minHeight: "60vh", flexDirection: "column" }}
          >
            <Result
              icon={<CheckCircleFilled />}
              title={
                <Title level={2} className='my-font'>
                  {intl.formatMessage({ id: "wefound" })}
                </Title>
              }
              extra={[
                <ul
                  className={`info`}
                  style={{ textAlign: rtl ? "right" : "left" }}
                >
                  <li>
                    {menuData?.foodsCount}{" "}
                    {intl.formatMessage({ id: "foodsCount" })}
                  </li>
                  <li>
                    {menuData?.categoriesLength}{" "}
                    {intl.formatMessage({ id: "category" })}
                  </li>
                  <li>
                    {menuData?.longestCategoryFoods}{" "}
                    {intl.formatMessage({ id: "longestCategory" })}{" "}
                    {intl.formatMessage({ id: "with" })}{" "}
                    {menuData?.countOfLongestCategoryFoods}{" "}
                    {intl.formatMessage({ id: "foodsCount" })}
                  </li>
                  {typeof menuData?.higherPrice === "number" &&
                    menuData?.higherPrice > -1 && (
                      <li>
                        {" "}
                        {intl.formatMessage({ id: "higherPrice" })}{" "}
                        {menuData?.higherPrice} SR
                      </li>
                    )}
                  {typeof menuData?.lowestPrice === "number" &&
                    menuData?.lowestPrice > -1 && (
                      <li>
                        {" "}
                        {intl.formatMessage({ id: "lowestPrice" })}{" "}
                        {menuData?.lowestPrice} SR
                      </li>
                    )}
                </ul>,
                <CustomButton
                  text={intl.formatMessage({ id: "next" })}
                  className='border-8 px-5'
                  style={{ width: "100%", height: 50 }}
                  type={"primary"}
                  onClick={() => {
                    setShowBarCode(true);
                    setShowRestaurant(false);
                  }}
                />,
              ]}
            />
          </Row>
        ) : showBarCode ? (
          <Row
            type='flex'
            justify='center'
            align='middle'
            style={{ flexDirection: "column" }}
          >
            <Title className='pt-5' level={2}>
              {intl.formatMessage({ id: "scanCode" })}
            </Title>
            <QRCode
              bgColor='#afe2e3'
              fgColor='#000000'
              level='Q'
              style={{ width: 200, height: 200 }}
              value={`https://app.iwaiter.club/${menuData?.slug}/${menuData?.branch}/${menuData?.table}`}
            />
            <CustomButton
              text={intl.formatMessage({ id: "next" })}
              className='border-8 px-5'
              style={{ width: "100%", height: 50, marginTop: 50 }}
              type={"primary"}
              onClick={() => {
                history("/");
              }}
            />
          </Row>
        ) : (
          <Row>
            <Space direction='vertical' style={{ width: "100%" }}>
              <Title className='my-1'>
                {intl.formatMessage({ id: "resultTitle" })}
              </Title>
              <Text type='primary' className='my-1'>
                {intl.formatMessage({ id: "desResult" })}{" "}
                <span>{count ? count : ""} </span>{" "}
                {intl.formatMessage({ id: "restaurant" })}{" "}
              </Text>
              <CustomButton
                onClick={addManualRestaurant}
                className='border-8 px-5'
                type={"primary"}
                loading={loading}
                text={intl.formatMessage({ id: "myRestaurantNotHere" })}
              />
            </Space>
            <Row>
              {restaurants.map((restaurant) => {
                return (
                  <Col sm={24} md={12} key={restaurant?.info?.restaurantId}>
                    <Card
                      onClick={() => selectRestaurant(restaurant.info)}
                      className='pointer'
                      style={{ width: 300, marginTop: 16, borderRadius: 10 }}
                      loading={fakeLoading}
                    >
                      <Meta
                        avatar={<Avatar src={restaurant.info?.logo} />}
                        title={restaurant?.info?.name}
                        description={restaurant?.info?.name_en}
                      />
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default RestaurantList;
