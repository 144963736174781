let apiUrl
const expressPort = 3003;

const CLIENT_KEY_FOODICS_TEST = '927c1acc-8243-4a9c-bdcb-dcb5933a96e2';
const CLIENT_KEY_FOODICS_LIVE = '92c2aea9-b6ff-478e-8bbe-a110e36761ed';
const URL_FOODICS_TEST = 'https://console-sandbox.foodics.com';
const URL_FOODICS_LIVE = 'https://console.foodics.com';

let foodicsClientKey, foodicsURL; 

foodicsClientKey = CLIENT_KEY_FOODICS_TEST;
foodicsURL = URL_FOODICS_TEST;

export const streamUrl = 'https://stream.iwaiter.club/v1/stream'
// export const streamUrl = 'http://localhost:4004/v1/stream'
const apiUrls = {
  production: 'https://api.iwaiter.club/api/v1',
  development: `http://localhost:${expressPort}/api/v1`
  // development: `http://192.168.100.36:${expressPort}/api/v1`
  // development: `http://192.168.68.115:${expressPort}/api/v1` // MAMA
  // development: `http://192.168.43.211:${expressPort}/api/v1` // Phon
  // development: `http://192.168.100.7:${expressPort}/api/v1` // Phon
}

if (window.location.hostname === 'localhost' || window.location.hostname.includes('192')) {
  apiUrl = apiUrls.development
} else {
  apiUrl = apiUrls.production
}
// const staticUrl = `http://localhost:${expressPort}`
// const staticUrl = `http://192.168.100.36:${expressPort}`
// const staticUrl = `http://192.168.68.118:${expressPort}`
// const staticUrl = `http://192.168.43.211:${expressPort}`

// console.log(apiUrl)

// console.log(apiUrl, "DDDD")
export {foodicsClientKey, foodicsURL}
export default  apiUrl